// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiEvent/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiEvent/getRows', payload).then(getData);

export const getRow = (rowID,payload={}) => http.post(`apiEvent/getRow&ID=${rowID}`,payload).then(getData);

export const create = (row) => http.post('apiEvent/createEvent', row).then(getData);

export const update = (row) => http.post('apiEvent/editEvent&ID=' + row.ID, row).then(getData);

export const remove = (row) => http.post('apiEvent/deleteEvent', row).then(getData);

export const getEventsByMonth = (date) => http.get(`apiEvent/getEventsByMonth&Date=${date}`).then(getData);

export const getEventsByRange = (startDate, endDate) => http.get(`apiEvent/getEventsByRange&StartDate=${startDate}&EndDate=${endDate}`).then(getData);

export const getUpcomingEvents = () => http.get(`apiEvent/getUpcomingEvents`).then(getData);

export const getUpcomingEventsNum=() => http.get(`apiEvent/getUpcomingEventsNum`).then(getData);

export const getLinkedForms = (eventID) =>http.get(`apiEventForms/getFormsForEvent&eventID=${eventID}`).then(getData);

export const getCalendarSettings = () => http.post(`apiEvent/getCalendarSettings`).then(getData);