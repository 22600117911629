// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiClient/getTableSettingsFamily', payload).then(getData);

export const get = (payload = null) => http.post('apiClient/getFamilyMembersGrid', payload).then(getData);


export const create = (row) => http.post('apiClient/addFamilyMember', row).then(getData);

