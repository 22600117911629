// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';
import { prepareFormData } from '@/utils/s3ResourceUtils';

const CNTLR_NAME = "apiUserAudio";

export const getTableSettings = (payload = null) => http.post(`${CNTLR_NAME}/getTableSettings`, payload).then(getData);

export const get = (payload = null) => http.post(`${CNTLR_NAME}/getRows`, payload).then(getData);

export const create = (row) => http.post(`${CNTLR_NAME}/create`, prepareFormData(row)).then(getData);

export const update = (row) => http.put(`${CNTLR_NAME}/edit&ID=${row.ID}`, prepareFormData(row)).then(getData);

export const remove = (row) => http.post(`${CNTLR_NAME}/delete`,row).then(getData);

export const getRow = (rowID) => http.get(`${CNTLR_NAME}/getRow&ID=${rowID}`).then(getData);

export const getResourceURL = (key) => http.get(`${CNTLR_NAME}/getResourceURL&Key=${key}`).then(getData);
