<template>
  <div>
    <label :style="labelStyle" :class="['labelttip__text', labelCssClass]" :id="labelID"><span v-if="required" style="color: red"><b>*</b></span>{{ label }} <i :id="iconID" v-show="showTooltip" class="fas fa-question-circle" style="color: #007bff;"></i></label>
    <b-tooltip v-show="showTooltip" :target="iconID">{{ tooltipText }}</b-tooltip>
  </div>
</template>
<script>
export default {
  name: 'LabelTooltip',
  props: {
    iconID: { type: String },
    labelID: { type: String, default: '' },
    label: { type: String, default: '' },
    showTooltip: { type: Boolean },
    tooltipText: { type: String, default: '' },
    required: { type: Boolean, default: false },
    labelStyle: { type: String, default: ''}, // do no use this. Styling should be made via css classes
    labelCssClass: { type: String, default: '' },
  }
}
</script>
<style lang="scss" scoped>
  .labelttip__text {
    font-weight: 500;
    font-size: 0.82rem;
  }
</style>