// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';
import { isObject } from '@/utils/langUtils';

const CNTLR_NAME = 'apiAssessmentTag';

export const getTableSettings = (payload = null) => http.post(`${CNTLR_NAME}/getTableSettings`, payload).then(getData);

export const get = (payload = null) => http.post(`${CNTLR_NAME}/getRows`, appendGroupParam(payload)).then(getData);

export const create = row => http.post(`${CNTLR_NAME}/create`, appendGroupParam(row)).then(getData);

export const update = row => http.put(`${CNTLR_NAME}/edit&ID=${row.ID}`, row).then(getData);

export const remove = row => http.post(`${CNTLR_NAME}/delete`, row).then(getData);

export const getRow = rowID => http.get(`${CNTLR_NAME}/getRow&ID=${rowID}`).then(getData);

const appendGroupParam = function(payload) {
  const param = { taxonomyGroup: 'Assessment' };
  if (isObject(payload)) {
    return { ...payload, ...param };
  }
  return param;
};
