import auth from '@/router/middleware/auth';
const AdministrationDashboard = r => require.ensure([], () => r(require('./AdministrationDashboard')), 'administrationDashboard-bundle');
export default [
  {
    path: '/administrationDashboard',
    name: 'administrationDashboard',
    component: AdministrationDashboard,
    meta: {
      middleware: [auth]
    }
  },
];