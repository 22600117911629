import auth from '@/router/middleware/auth';

const FileManager = r => require.ensure([], () => r(require('./index')), 'file-manager-bundle');

export default [
  {
    path: '/file-manager',
    name: 'file-manager',
    component: FileManager,
    meta: {
      middleware: [auth]
    }
  }
];
