import auth from '@/router/middleware/auth';

const Form = r => require.ensure([], () => r(require('./form')), 'custom-form-bundle');

export default [
  {
    path: '/custom-form/:formID',
    name: 'custom-form',
    component: Form,
    meta: {
      middleware: [auth]
    }
  },
];
