// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiLocationEHR/getOccupancyTable', payload,{silent:true}).then(getData);

export const get = (payload = null) => http.post('apiLocationEHR/getOccupancyGroups', payload,{silent:true}).then(getData);
export const getRow = (RowID,payload = null) => http.post('apiLocationEHR/getOccupancyGroup', {rowID:RowID,params:payload},{silent:true}).then(getData);


export const create = (row) => http.post('apiLocationEHR/createGroup', row).then(getData);

export const update = (data) => http.post('apiLocationEHR/editGroup', data).then(getData);

export const remove = (row) => http.post('apiLocationEHR/deleteGroup', row).then(getData);

export const getFormConfig = (rowID,action,params) => http.post('apiLocationEHR/getOccupancyGroupForm',{RowID:rowID,Action:action,...params},{silent:true}).then(getData);

