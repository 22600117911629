<template>
    <div >

      <b-card 
        no-body 
        class="mb-1" 
        >


        
        <b-card-body>
          
       
          <div v-show="fields.length>0" >
            <div v-for="(field,index) in fields" :key="'field_'+index" class="border-bottom pt-1 pb-2">
                   <b-row>
                   <b-col cols="11"></b-col>
                  <b-col cols="1">
                    <b-button @click="removeMapping(index)" size="sm" variant="danger" >{{ $t("general.remove") }}</b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    {{ $t("ApprovalOverdueCriteriaCard.tool") }}
                  </b-col>
                  <b-col>
                    {{ $t("ApprovalOverdueCriteriaCard.field") }}
                  </b-col>
                 
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-select
                        :key="questionsLoaded[index]"
                        :options="formModels[index]"
                        v-model="fields[index].Model"
                        @change="modelChanged(index)"

                    ></b-form-select>
                  </b-col>
                  <b-col>
                    <div v-if="showResponses(index)">

                      <b-form-select
                             :id="'fields_'+fields[index].Model+'_'+index"
                            :options="formFields[index]"
                            v-model="fields[index].Field"
                            @input="fieldSelected($event,index)"
                         ></b-form-select>

                    </div>
              
                  </b-col>
                  
                </b-row>
           
            </div>
            
          </div>
           <b-row class='border-top'>
                  <b-col cols="10"></b-col>
                  <b-col cols="2">
                    <div style="margin-top:10px" class='float-right'>
                      <b-button size="sm" variant="success" @click="addRow">{{ $t("ApprovalOverdueCriteriaCard.add") }}</b-button>
                    </div>
                  </b-col>
                </b-row>

         
         
        </b-card-body>
      </b-card>
    </div>
</template>
<script>
import * as collectionSeriesService from '@/services/cseries';
import * as approvalService from '@/services/approvalSettings';
import lodash from 'lodash';
import { deepCopy, isObject } from '@/utils/langUtils';

export default {
  name: 'ApprovalCriteriaCard',
  components:{ChildInputFactory:()=>import('@/components/InputFactory.vue')},
  data() {
    return {
      fields:[],
      formModels:[],
      formFields:[],
      refreshFields:null,
      questionsLoaded:[],
    
    };
  },
  computed:{
   
   
  },
  created(){

  },
  mounted(){



      this.fields=this.value;

     
      this.rowData['Overdue fields']=this.fields;

      
       




      if(!this.fields || (this.fields && this.fields.length==0)){
        if(!this.fields){
          this.fields=new Array;
        }
        this.fields[0]={Model:null,Field:null};
              approvalService.getOverdueModels(this.rowData)
              .then(response =>{


                this.formModels[0]=response;

                this.$forceUpdate();

                
              });

       

          
            
      }else{
        let vm=this;


                approvalService.getOverdueModels(this.rowData)
              .then(response =>{


                    let vm=this;
                    let allModels=response;

                   $.each(vm.fields,function(index,field){
                      vm.formModels[index]=allModels;
                        vm.$forceUpdate();

                         approvalService.getOverdueFields({Model:field.Model,rowData:this.rowData})
                         .then(response =>{


                                vm.formFields[index]=response;

                                 vm.$forceUpdate();



                         });

                   });



                
              });

            


             

             



      
    }

      
   
      


  
  },
  methods: {

    removeMapping(index){
        this.fields.splice(index,1);

              this.$forceUpdate();





    },
    addRow(){
      this.fields.push({Model:null,Field:[]});

     
          approvalService.getOverdueModels(this.rowData)
            .then(response =>{
              this.formModels[this.fields.length-1]=response;

              this.$forceUpdate();

              
            });

        
    },
    rowLoaded(index){
      var date=new Date().getTime();

      return this.questionsLoaded[index]?'question_'+index+'_'+date:null;
    },
    showResponses(index){

        if(this.fields[index].Model && this.formFields[index] && Array.isArray(this.formFields[index])){
          return true;
        }else{
          return false;
        }

    },
    updateKey(fieldID,index){
      var time=new Date().getTime();
      return fieldID+'_'+index+'_'+time;

    },
    modelChanged(index){


        this.fields[index].Field=[];
        this.updateRowData();



        approvalService.getOverdueFields({Model:this.fields[index].Model,rowData:this.rowData})
        .then(response =>{
            this.formFields[index]=response;




            this.$forceUpdate();


        });
    },
    fieldSelected(data,index){
            this.updateRowData();

      this.$forceUpdate();

    },

  
    updateRowData(){
            this.rowData['Overdue fields']=this.fields;

    }


      
  },/*
  mounted() {
    permissionsService
    .get()
    .then(response => {
        this.permissions = response;
    })
    .catch(error => {
        this.permissions = {};
    });
  },
  */
  props: {
    rowData:{type:Object,default:function(){return {}}},
    value: {type: Array,default:function(){return []}},
    rowID:{type:String,default:null}


  }
}
</script>
<style>
  .form__card {
    margin-bottom: 2rem;
    background: transparent;
  }
</style>