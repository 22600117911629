import auth from '@/router/middleware/auth';

const Files = r => require.ensure([], () => r(require('./index')), 'files-bundle');

export default [
  {
    path: '/files',
    name: 'files',
    component: Files,
    meta: {
      middleware: [auth]
    }
  }
];
