<template>
   <div :id="id">
	   	  <div>
				<Page v-bind:headerText="headerText" v-bind:breadcrumbs="breadcrumbs">
				<!--
				<template v-slot:pageActions>
					<PageActions>
					
					</PageActions>
				</template>
				-->

				<template v-slot:content>
					<EcenterTable 
					id="discussionBoardGridTable"
					v-bind:serviceName="serviceName" 
					v-bind:addButtonLabel="addButtonLabel"
					:sortOptions="sortOptions"
					v-bind:allowAdd="inSearch?false:true"
					v-bind:customServerParams="{linkedOnly:inSearch?false:true,searchMode:inSearch}"
					:key="reloadTime"
					@tableLoaded="gridLoaded"
					v-bind:refreshAfter="900"
					:filterRowTitle="filterRowTitle"
					customFieldSetName="Discussion Board Information"
					>
					<template v-slot:addRowForm="{ addRowData, customFieldCategories, errorMessage }">
						<!-- <AddBoard v-bind:rowData="addRowData"></AddBoard> -->
						<AddEditForm
              formType="Add"
              :rowData="addRowData"
              :customFieldCategories="customFieldCategories"
              :errorMessage="errorMessage"
            ></AddEditForm>
					</template>
					<template v-slot:editRowForm="{ editRowData, customFieldCategories, errorMessage }">
          	<AddEditForm
              formType="Edit"
              :rowData="editRowData"
              :customFieldCategories="customFieldCategories"
              :errorMessage="errorMessage"
            ></AddEditForm>
          </template>

					<template v-slot:customTableAction>
							<b-button size="sm" v-if="hasNewPosts" @click="openNewPosts" :variant="$store.state.themeDefaults.buttonVariant" class="mr-1">
								{{ $t("discussion-board.DiscussionBoardGrid.new") }} <b-icon icon="newspaper"></b-icon>
							</b-button>
					</template>


					<template v-slot:searchDialogContent v-if="inSearch==false">
							<discussion-board-grid filterRowTitle="" v-bind:inSearch="true"></discussion-board-grid>							

					</template>

					<template v-slot:rowContent="{ row }">
					  <div v-if="row">
					  	<b-row>	
							<div  :style="{display:'inline-block',paddingRight:'0',paddingLeft:'20px',width:'60%'}">
							
							<h5 class="subjectLink" style="text-decoration:underline;cursor:pointer;color:#c25a23" @click="openDiscussionBoard(row.ID)" >{{ row.title }}</h5>
								<div v-html="$t('discussion-board.created', {Created:row.Created,CreatedUser:row.CreatedUser})"></div>
								<div v-html="$t('discussion-board.DiscussionBoardGrid.last', {LastUpdated:row.LastUpdated,LastUser:row.LastUser})"></div>
								<div v-if="row.Groups" v-html="$t('discussion-board.DiscussionBoardGrid.group', {Groups:row.Groups})"></div>
								<div  v-if="row.isLocked"><h6><b-badge variant="danger"><b-icon icon="lock"></b-icon> {{ $t("discussion-board.DiscussionBoardGrid.locked") }} </b-badge></h6></div>
							</div>
							
							<div class='text-right'  :style="{display:'inline-block',paddingRight:'0px !important',width:'35%'}">
								
								<b-row  >

									<div class='text-right' v-if="row.CreatedByUser" :style="{width:'97%'}" >
										<!-- <b-dropdown  text="Actions"  >
												<b-dropdown-item  v-if="row.isLocked" @click="toggleBoardLock(row,false)"><b-icon icon="unlock" ></b-icon>Unlock Board</b-dropdown-item>
												<b-dropdown-item  @click="toggleBoardLock(row,true)" v-else><b-icon icon="lock" ></b-icon>Lock Board</b-dropdown-item>
												<b-dropdown-item @click="deleteBoard(row.ID)"><b-icon icon="trash" variant="danger"></b-icon>Delete</b-dropdown-item>
								
										</b-dropdown> -->
									</div>
									<div class='text-right' :style="{width:'97%',marginRight:'10px !important'}" v-else>
											<b-button @click="linkBoard(row)" size="sm" variant='success' pill v-if="row.isFollowing==false">{{ $t("discussion-board.DiscussionBoardGrid.add") }} <b-icon icon="plus"></b-icon></b-button>
											<b-button @click="unlinkBoard(row)" size="sm" variant="danger" pill v-if="row.isFollowing==true">{{ $t("discussion-board.DiscussionBoardGrid.remove") }} <b-icon icon="dash-circle"></b-icon></b-button>
								
									</div>
								</b-row>
								<b-row>
									<div  class="text-right" :style="{width:'98%',marginTop:'10px'}" >
									<div style='width:100%;margin-right:0'>
												<b-list-group horizontal style="font-size:16px;width:100%;display:block" >
													<b-list-group-item class='text-left' style="padding:5px 5px 10px 5px;width:33%;display:inline-block"><b-badge >{{ row.Votes }}</b-badge> {{ $t("discussion-board.votes") }}</b-list-group-item>
													<b-list-group-item class='text-left' style="padding:5px 5px 10px 5px;width:33%;display:inline-block"><b-badge  variant="primary">{{ row.TotalComments }}</b-badge> {{ $t("discussion-board.DiscussionBoardGrid.posts") }}</b-list-group-item>
													<b-list-group-item class='text-left' style="padding:5px 5px 10px 5px;width:33%;display:inline-block" v-if="row.isFollowing"><b-badge variant="success" >{{ row.NewComments }}</b-badge> {{ $t("discussion-board.DiscussionBoardGrid.new") }}</b-list-group-item>

												</b-list-group>
										</div>
									</div>
								

								</b-row>

								

							</div>
						</b-row>
						   <div style="margin-bottom:20px">
			  
			  
			   <b-row>
			
				  <b-col md="5">
					<div v-if="row.MostVoted" style="margin-top:20px;margin-bottom:10px;padding-left:5px">
						<h6>{{ $t("discussion-board.DiscussionBoardGrid.response") }}</h6>
						<b-jumbotron style="padding-top:0;padding-bottom:5px">
							<p>{{ row.MostVoted }}</p>
						</b-jumbotron>
					</div>
				  </b-col>
			   </b-row>
			   

			   <vue-good-table
			   			v-if="row.Threads && row.Threads.length>0"
						style="width:97%;margin-top:10px"
						
						
                            :columns="detailFields"
                            :rows="row.Threads"
                            
                            >
                              <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field=='post'">
										<p class="subjectLink" style="text-decoration:underline;cursor:pointer"  @click="openDiscussionBoardThread(props.row.ID)">{{ props.row.post }}</p>
										 <b-modal @close="closePostThread(props.row.ID)" :id="'bv-modal-post-thread-'+props.row.ID" size="xl" no-close-on-esc no-close-on-backdrop hide-footer no-enforce-focus>
												<DiscussionBoardPosts :discussionBoardSubject="row.title" :createdUser="row.CreatedUser" :createdDate="row.Created" :discussionBoardID="row.ID" :targetThread="props.row.ID" :castedVote="row.castedVote" :votes="row.Votes" ></DiscussionBoardPosts>
											</b-modal>
                                </span>
                                <span v-else-if="props.column.field=='mostVoted'">
                                    {{ props.row.mostVoted}}
                                </span>
                                <span v-else-if="props.column.field=='votes'">
                                     <div class="text-left"  style="padding-top:10px;width:100%; padding-bottom:10px;display:inline-block">

										<b-list-group horizontal :style="{fontSize:'14px', width:'100%',marginRight:'12px',marginLeft:'auto'}">
											<b-list-group-item :style="{padding:'10px 5px 10px 5px',width:(row.isFollowing?'33%':'50%')}"><b-badge>{{ props.row.votes }}</b-badge> {{ $t("discussion-board.votes") }}</b-list-group-item>
											<b-list-group-item :style="{padding:'10px 5px 10px 5px',width:(row.isFollowing?'33%':'50%')}"><b-badge variant="primary">{{ props.row.totalComments }}</b-badge> {{ $t("discussion-board.DiscussionBoardGrid.posts") }}</b-list-group-item>
											<b-list-group-item :style="{padding:'10px 5px 10px 5px',width:'33%'}" v-if="row.isFollowing"><b-badge variant="success">{{ props.row.totalNew }}</b-badge> {{ $t("discussion-board.DiscussionBoardGrid.new") }}</b-list-group-item>

										</b-list-group>



									</div>
                                </span>
                                
                            </template>
                            </vue-good-table>
			  
			   
		   </div>

		    <b-modal @close="closePosts(row.ID)" :id="'bv-modal-posts-'+row.ID" size="xl" no-close-on-esc no-close-on-backdrop hide-footer no-enforce-focus>
				<DiscussionBoardPosts :discussionBoardSubject="row.title" :discussionBoardID="row.ID" :votes="row.Votes" @deleteBoard="closePosts(row.ID)" :createdUser="row.CreatedUser" :createdDate="row.Created" :castedVote="row.castedVote" ></DiscussionBoardPosts>
			</b-modal>
  
		  </div>
      </template>

			
					</EcenterTable>

					<b-modal @close="updateBoardAccess" v-if="linkedOnly" id="bv-modal-newposts" size="xl" title="Boards With New Posts" no-close-on-esc no-close-on-backdrop hide-footer scrollable no-enforce-focus>
								<NewPostsOnly></NewPostsOnly>
					</b-modal>
				</template>
				</Page>
			</div>
	</div>
</template>
<style>

.db-modal-size-modal {
  width: 100% !important;
  max-width:100% !important;
}
.db-modal-size-dialog {
  width: 98% !important;
  max-width:98% !important;
  margin:auto !important;
}
.db-modal-size-content{
  width: 100% !important;
  max-width:100% !important;
}
.db-modal-size {
  width: 100% !important;
  max-width:100% !important;
}
#discussionBoardGridTable .subjectLink:hover{
	color:blue


}
#discussionBoardGridTable .vgt-global-search > .vgt-pull-right{
	max-width:100% !important;
	width:100% !important;
}
</style>
<script>
  import lodash from "lodash";
import EcenterTable from '@/components/ecenter-table/ecenter-table.vue';
import PageActions from '@/components/pageActions.vue';
import Page from '@/components/page.vue'
import DiscussionBoards from './DiscussionBoardGrid'
	import AddBoard from "./AddDiscussionBoard";
	import DiscussionBoardPosts from "./DiscussionBoardPosts";
	import NewPostsOnly from "./DiscussionBoardNewPosts";
  import * as discussionBoardService from "@/services/discussion-board";
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import AddEditForm from '@/app/list-view/form';
  export default {
	name:"discussion-board-grid",
	props: {
		selectRowURL: String,
		selectRowData: Object,
        linkedOnly:Boolean,
		myBoards:Boolean,
		inSearch:Boolean,
		filterRowTitle: { type: String, default: "" }
	},
    data() {
    return {
		id:null,
		reloadTime:null,
		hasNewPosts:false,
		sortOptions: [
				{label: 'Votes (Most Voted - Least Voted)', type: 'desc', field: 'Votes'},
				{label: 'Votes (Least Voted - Most Voted)', type: 'asc', field: 'Votes'},
				{label: 'Subject (A-Z)', type: 'asc', field: 'Title'},
				{label: 'Subject (Z-A)', type: 'desc', field: 'Title'},
				


			],
			 detailFields:[
        	
					{field:"post",label:"Additional Threads",sortable:false,width:"33%"},
					{field:"mostVoted",label:"Most Voted Response",sortable:false,width:"33%"},
					{field:"votes",label:"Thread Votes/Posts:",sortable:false,width:"33%",tdClass: 'text-right'},
					
					
					
				],
			serviceName: "discussion-board.js",
			headerText: this.inSearch?"Discussion Boards":"My Discussion Boards",
			addButtonLabel: "Add Discussion Board",
			breadcrumbs:this.inSearch?[]: 	
			[
				{
					text: 'Dashboard',
					to: {name: 'dashboard'}
				},
				{
					text: this.inSearch?"Discussion Boards":'My Discussion Boards',
					href: '#'
				}
			  ]
			};
	   
	},
	watch:{
		items:function(newItems){
				
		},
		
		
	},
	created(){
		if(this.myBoards){
			this.id="discussionboard-table-myboards";
		}else if(this.linkedOnly){
			this.id="discussionboard-table-linked-boards";
		}else{
			this.id="discusssionboard-table";
		}

	},
	computed:{

		  emptyHeight(){
			  var heightCalc=24*1;

			 return heightCalc+"px";
		  },
		  filterMyBoards(){
				  return this.filterType=="myboards"?"visible":'hidden';
				  
		  },
		  filterFollowing(){
			  return this.filterType=="following"?"visible":"hidden";
		  }

		

	 
	},
	beforeDestroy(){
		if(this.timer){
			clearInterval(this.timer);
		}

	},
	mounted(){
			
			var observer = new MutationObserver(this.newSpanAdded);

			
			var targetNode = null;

		
		
				targetNode=$('#'+this.id)[0];

			

			observer.observe(targetNode, { childList: true, subtree: true });

		
	},
	methods:{
			
				deleteBoard(boardID){
			this.$bvModal.msgBoxConfirm('Are you sure you want to delete this discussion board this cannot be undone?', {
				hideHeaderClose: true,
				centered: true,
				noCloseOnBackdrop: true,
				noCloseOnEsc: true
			})
			.then(value => {
					if (value) {
						discussionBoardService.remove({DiscussionBoardID:boardID})
						.then(() => 
								this.reloadTime=new Date().getTime()


						);
					}
			});
						
		},
		toggleBoardLock(board,locked){

				discussionBoardService.updateBoardLock({
					lock:locked,
					DiscussionBoardID:board.ID
				})
				.then(response => {
					board.isLocked=locked;
				});	
		},
		resetAll(){
			this.isFiltered=false;
	Object.keys(this.fields).forEach(key=>{
								this.fields[key].optionsSelected=false;

						});
			$("#"+this.id).find(".sorting").removeClass("sorting");
			$("#"+this.id).find(".sorting-asc").removeClass("sorting-asc");
			$("#"+this.id).find(".sorting-desc").removeClass("sorting-desc");

				$("#"+this.id).find("input").val('');

			
			this.serverParams.sort=[];
			this.serverParams.columnFilters={};
			this.getBoards();

															

		},
		 hasFilter(field){
			  return this.serverParams.columnFilters[field] && this.serverParams.columnFilters[field].length>0?true:false;
		  },
		  hasSort(column,field){
			  	if(column.optionsSelected){
					  return true;
				}else{
					if(this.serverParams.sort.length>0){
						var field = this.serverParams.sort.find(element => element.field == field);
						if (field) {
							return true;
						}else{
							return false;
						}
					}else{
						return false;
					}
				}
		  },
		multiSelectHover(columnModel,show){
					columnModel.messageVisible=show;
		},
		  multipleHeaderClicked(field){
			   this.$bvModal.show('bv-modal-sort-'+field);
		  },
		  sortChange(sortDirectionChange){
		  			if(!sortDirectionChange){
						if(this.serverParams.sort[0].field.indexOf("Tag")>-1){
							this.serverParams.sort[0].type="DESC";
						}else{
							this.serverParams.sort[0].type="ASC";
						}
					}

					this.getBoards();
      		    
	  },
		
		expandRow(index){

			this.$refs.vuetable.toggleExpand(index);

		},
		newSpanAdded(records){
			var vm=this;
			records.forEach(function (record) {
				var list = record.addedNodes;
				
				$.each(list,function(key,value){
					    $(value).find(".colspan").closest("td").nextAll("td").remove();
						$(value).find(".colspan").closest("td").attr("colspan",3);
						$(value).find(".triangle").hide();
						$(value).find(".hideRow").closest("tr").hide();
						$(value).find(".clearFilter").closest("tr").show();
						$(value).find('.vgt-row-header').css('background-color','#ffffff');
						$(value).find('.vgt-row-header').css('font-weight','normal');
						$(value).find('th.filter-th').css('vertical-align',"top");

						$(value).find('.detailLink').mouseenter(function(e){
							$(e.target).css('textDecoration','underline');
						});

						$(value).find('.detailLink').mouseleave(function(e){
							$(e.target).css('textDecoration','none');
						});




				});

				$('#'+vm.id).find('th.filter-th').css('vertical-align',"top");
			});
		},
		clearFilterAndSort(field){
			
		

		},
		getBoards(){

			if(this.timer){
				clearInterval(this.timer);
			}
			// discussionBoardService.getBoards({
			// 			perPage:this.serverParams.pageSize,
			// 			currentPage:this.serverParams.currentPage,
			// 			sortField:this.serverParams.sort,
			// 			searchFilters:this.serverParams.columnFilters,
			// 			linkedOnly:this.linkedOnly,
			// 			myBoardsOnly:this.myBoards,
			// 			filterBoards:this.filterType

			// })
			// .then(response =>{
					
			// 	this.items=response.items;

			// 	var date = new Date();

			// 	this.refreshTime=date.getTime();

			// 	this.hasNewPosts=response.hasNewPosts;

			// 	this.totalRecords=response.totalItems;
			// 	var vm=this;
			// 		$('#'+this.id).find('th.filter-th').each(function(){
			// 				//test if actually has filter control meaning filter is enabled 
			// 				if($(this).find('input, select').length>0){
			// 					//get index of column - note hidden columns are not counted 
			// 					var index=$(this).index();

			// 					//get field from header
			// 					var field=$(this).closest('table').find("tr").first().find("th").eq(index).find("span").attr('data-field');

			// 						//get column def
			// 						var columnObj = vm.fields.find(element => element.field == field);
			// 						if (columnObj) {
			// 							//insert control if column is filtered or sorted
			// 							if(vm.hasFilter(columnObj.field) || vm.hasSort(columnObj,columnObj.field)){
			// 									if($(this).find(".clearControl").length==0){
			// 											$(this).append("<button id='clear-discussion-board-"+field+"' class='clearControl btn btn-primary btn-sm' data-field='"+field+"'>Clear filter and sort</button>");
			// 											$("#clear-discussion-board-"+field).click(function(){

			// 												if($(this).parent().find('.vs__selected').length>0){
																	
			// 														$(this).parent().find('.vs__selected').find("button").each(function(){
			// 															$(this).trigger("click");

			// 														});
																	

			// 												}else{
			// 													$(this).parent().find('input').val(null);
																

			// 												}

			// 												$(this).parent().find('input').attr("placeholder",columnObj.filterOptions.placeholder);
															
			// 												$(this).parent().closest('table').find('tr').first().find('th').eq($(this).parent().index()).removeClass('sorting').removeClass('sorting-asc').removeClass('sorting-desc');



			// 												vm.clearFilterAndSort($(this).data('field'));

			// 											});
			// 									}

			// 							}else{
			// 									$(this).find(".clearControl").remove();
			// 							}
			// 						}

								
								



			// 				}
			// 		});
				
			// 		var vm=this;

					
			// 		this.timer=setInterval(function(){
			// 				vm.refreshBoards();
			// 		},this.refreshInterval);


			// });

			
		},
		refreshBoards(){
			if(this.timer){
				clearInterval(this.timer);
			}
			discussionBoardService.refreshBoards({
						perPage:this.serverParams.pageSize,
						currentPage:this.serverParams.currentPage,
						sortField:this.serverParams.sort,
						searchFilters:this.serverParams.columnFilters,
						linkedOnly:this.linkedOnly,
						myBoardsOnly:this.myBoards,
						filterBoards:this.filterType,
						

			})
			.then(response =>{
					
				this.items=response.items;


				this.hasNewPosts=response.hasNewPosts;

				this.totalRecords=response.totalItems;
			
				 this.$forceUpdate();				
				
					var vm=this;
					this.timer=setInterval(function(){
							vm.refreshBoards();
					},this.refreshInterval);


			});
		},
		updateParams (newProps) {
     		 this.serverParams = Object.assign({}, this.serverParams, newProps);
    	},

		onPageChange (params) {
			this.updateParams({ currentPage: params.currentPage });
			this.getBoards();
		},

		onPerPageChange (params) {
			this.paginationOptions.currentPage=1;
			this.updateParams({ pageSize: params.currentPerPage,currentPage:1 });
			this.getBoards();
		},

		

		onColumnFilter (params) {

					this.updateParams(params);

					var filterHasValue=false;
					Object.keys(params['columnFilters']).forEach(key=>{
						
							if(params['columnFilters'][key].length>0){
								filterHasValue=true;
							}
						
					});

					this.isFiltered=filterHasValue

			
				this.getBoards();
			
		},

		selectionChanged() {
			this.$http.post(API_URL + 'apiDiscussionBoard/' + this.selectRowURL, this.selectRowData).then(response => {
				//this.$bvModal.hide('bv-modal-please-wait');
			}).catch(error => {
				//this.$bvModal.hide('bv-modal-please-wait');
				this.submitError(error);
			});
		},
		   translateTagName(value){
      		var returnString="";
      		Object.keys(this.searchTags).forEach(key=>{
           		   if(this.searchTags[key].value==value){
           		   		returnString=this.searchTags[key].text;
           		   		
           		   		return;
           		   		
           		   } 			
           		    			
           		    				
           });
           
           return returnString;
      },
      translateRating(value){
  			
  			var returnString=null;
  			Object.keys(this.ratings).forEach(key=>{
           		   if(this.ratings[key].value==value){
           		   		returnString=this.ratings[key].text;
           		   		
           		   		return;
           		   		
           		   } 			
           		    			
           		    				
           });
           
           
           return returnString;
           
  		},
  		
  		translateRatingColor(value){
  			
  			
  			var returnString=null;
  			Object.keys(this.ratings).forEach(key=>{
           		   if(this.ratings[key].value==value){
           		   		returnString=this.ratings[key].color;
           		   		
           		   		return;
           		   		
           		   } 			
           		    			
           		    				
           });
           
           
           return returnString;
           
		  },
		   linkBoard:function(row){
		 			 discussionBoardService.linkUser({
						DiscussionBoardID:row.ID,
						link:true
					})
           		    .then(response => {
           		    
           		    
							   row.isFollowing=true;
							   row.NewComments=0;
           		    		
           		    
               				
            		 })
            		 .catch(error => {
               				 console.log(error);
            		 });
	  },
	  unlinkBoard:function(row){
		   			 discussionBoardService.linkUser({
						DiscussionBoardID:row.ID,
						link:false
					})
           		    .then(response => {
           		    
           		    
           		    		row.isFollowing=false;
           		    		
							if(this.linkedOnly){
								this.reloadTime=new Date().getTime();
							}
               				
            		 })
            		 .catch(error => {
               				 console.log(error);
            		 });
	  },
	  closeMutiSort(sortParams,optionsSelected,field,isCanceled=false){


		
		  if(!isCanceled){
				var vm=this;
				Object.keys(sortParams).forEach(key=>{
					var found=this.serverParams.sort.some(function(current_sort,index){
							if(current_sort.field==sortParams[key].field){
									if(sortParams[key].selected==1){
										vm.serverParams.sort[index].type=sortParams[key].type;

									}else{
									vm.serverParams.sort.splice(index,1);


									}

									return true;

							
							}else{
								return false;
							}


					});	

						/*
					Object.keys(this.serverParams.sort).forEach(key2=>{
							if(this.serverParams.sort[key2].field==sortParams[key].field){
								if(sortParams[key].selected==1){
								this.serverParams.sort[key2].type=sortParams[key].type;
								
								}else{
									this.serverParams.sort.splice(key2,1);
								}

								found=true;
								return;
							}
					});
					*/

					if(!found){

						if(sortParams[key].selected==1){
							this.serverParams.sort.push(sortParams[key]);
						}


					}
				});

		  }

				var vm=this;
			 	this.fields.some(function(current_field,index){
					 	if(current_field.field==field){
							 		 if(optionsSelected){
										  vm.fields[index].optionsSelected=true;
									  }else{
											vm.fields[index].optionsSelected=false;

									  }

									  return true;
							
						 }else{
							 return false;
						 }
				 });
		 
		 this.$bvModal.hide('bv-modal-sort-'+field);
		  
		 this.getBoards();
	  },
	  processAdd(params){
				  let formData = new FormData();
				  
				  formData.append('DiscussionBoardID',this.DiscussionBoardID);
				  formData.append('Subject',params.Subject);
				  formData.append('InitialPost',params.InitialPost);


			  discussionBoardService.create(formData)
			  .then(response=>{
				  if(response.success){
					  refreshTime=new Date().getTime();
				  }
			  });
		  	
	  },
	   addBoard(){
		   if(this.timer){
			   clearInterval(this.timer);
		   }
  						this.$bvModal.show('bv-modal-addBoard');

	  },
	   openNewPosts(){
  						this.$bvModal.show('bv-modal-newposts');

	  },
	 closeModal(){
		  this.$bvModal.hide('bv-modal-addBoard');
		   window.scrollTo(0,0);
 
		  this.getBoards();
	  },
	  closeEditModal(boardID){
		this.$bvModal.hide('bv-modal-editBoard-'+boardID);
		this.loadGrid();
	  },
	  editProvider(providerID){
		  this.$bvModal.show('bv-modal-editBoard-'+boardID);
	  },
	  openDiscussionBoard(boardID){
		  if(this.timer){
			  clearInterval(this.timer);
		  }
		  this.$bvModal.show("bv-modal-posts-"+boardID);
	  },
	  openDiscussionBoardThread(threadID){
		  if(this.timer){
			  clearInterval(this.timer);
		  }
		  this.$bvModal.show("bv-modal-post-thread-"+threadID);
	  },
	  closePosts(boardID){
		  this.$bvModal.hide("bv-modal-posts-"+boardID);

		  this.getBoards();

	  },
	   closePostThread(threadID){
		  this.$bvModal.hide("bv-modal-post-thread-"+threadID);

		  this.getBoards();

	  },
	  filterBoards(){
		 
		  this.getBoards();

	  },
	  gridLoaded(params){
		  this.hasNewPosts=params.hasNewPosts;


	  },
	  updateBoardAccess(){
		  discussionBoardService.updateAccessTime().then(response => {
			  this.reloadTime=new Date().getTime();
		  });
	  }
           
	},
	 components: {
		EcenterTable, AddBoard,DiscussionBoardPosts, NewPostsOnly,Page, PageActions,VueGoodTable, 'discussion-board-grid':DiscussionBoards, AddEditForm
	}
  
  }
</script>