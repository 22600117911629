// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';
import { copyAndMerge } from '@/utils/langUtils';
import { getPropsFromFiles } from '@/utils/s3ResourceUtils';

const CNTLR_NAME = 'apiLocationToolCollaboration';
export const getFormHeaderUrl = `${CNTLR_NAME}/getFormHeader`;

export const getTableSettings = (payload = null) =>
  http.post(`${CNTLR_NAME}/getTableSettings`, payload).then(getData);

export const get = (payload = null) =>
  http.post(`${CNTLR_NAME}/getRows`, payload).then(getData);

export const create = row =>
  http
    .post(`${CNTLR_NAME}/submitForm`, copyAndMerge(row, getPropsFromFiles(row)))
    .then(getData);

export const update = row =>
  http
    .post(`${CNTLR_NAME}/submitForm`, copyAndMerge(row, getPropsFromFiles(row)))
    .then(getData);

export const remove = row =>
  http.post(`${CNTLR_NAME}/delete`, row).then(getData);

export const getForm = payload =>
  http.post(`${CNTLR_NAME}/getForm`, payload).then(getData);

export const getFormAnswers = payload =>
  http.post(`${CNTLR_NAME}/getFormAnswers`, payload).then(getData);

export const submitForm = payload =>
  http
    .post(
      `${CNTLR_NAME}/submitForm`,
      copyAndMerge(payload, getPropsFromFiles(payload))
    )
    .then(getData);
