<template>
  <b-row
    no-gutters
    :class="row.cssClass"
    :style="row.style"
  >
    <b-col :style="inPicker?{padding:0}:{}"   :xl="row.rowSize && row.rowSize.xl?row.rowSize.xl:(Array.isArray(row.actionButtons) || row.usesActionButtons ? '9' : (Array.isArray(row.badges)?'9':'11'))"
      :md="Array.isArray(row.actionButtons) ? (Array.isArray(row.badges)?'6':'7') : (Array.isArray(row.badges)?'9':'11')">
      <b-row>
        <b-col :style="inPicker?{padding:0}:{}"  v-if="showSelectRowCheckbox && row.showSelectRowCheckbox" sm='1' class="d-flex justify-content-center align-items-center">        <b-form-checkbox
          v-model="row.rowSelected"
          :value="true"
          @change="$emit('onRowSelected', row)"
        >
        </b-form-checkbox>
        </b-col>
        <b-col :style="inPicker?{padding:'0 !important'}:{}" :sm="showSelectRowCheckbox && row.showSelectRowCheckbox ? '10' : row.contentSize">
      <slot name="rowContent"></slot>
        </b-col>
      </b-row>
    </b-col>

    <b-col :style="inPicker?{padding:0}:{}" v-if="Array.isArray(row.actionButtons) || row.usesActionButtons" :xl="row.actionButtonColSize" :md="row.actionButtonColSize">
      <div  :style="(row.alignActionsTop?{paddingTop:'24px',height:'auto'}:{height:'auto'})" :class="'d-flex justify-content-center '+(row.alignActionsTop?'':'align-items-center') +' flex-wrap'">
        <div
          v-for="(actionButton, i) in row.actionButtons"
          :key="`actionButton_${i}`"
          class="mr-1"
          
          >
          <b-btn
            v-if="actionButton.type === 'LINK'"
            v-b-tooltip.hover :title="actionButton.name"
            variant="light"
            style="border-radius: 50%"
            :to="actionButton.url">
            <b-icon :icon="`${actionButton.iconName}`" />
          </b-btn>
          <b-btn
            v-else-if="actionButton.type === 'SUBMIT_DATA'"
            v-b-tooltip.hover :title="actionButton.name"
            variant="light"
            style="border-radius: 50%; font-size:0.9rem"
            @click="$emit('onClickActionBtnSubmit',{ ...row, actionButtonSubmitUrl: actionButton.submitUrl, actionButtonLink: actionButton.url,dialogToOpen:actionButton.dialogToOpen })">
              <b-icon :icon="`${actionButton.iconName}`" font-scale="1" />
          </b-btn>
           <b-button
            v-else-if="actionButton.type === 'SUBMIT_DATA_WITH_TEXT'"
            v-b-tooltip.hover :title="actionButton.name"
            :variant="actionButton.variant"
            :pill="actionButton.pill"
            class="ml-2"
            style="border-radius: 5%; font-size:0.9rem;height:50px"
            @click="$emit('onClickActionBtnSubmit',{ ...row, actionButtonSubmitUrl: actionButton.submitUrl, actionButtonLink: actionButton.url,dialogToOpen:actionButton.dialogToOpen })">
              <b-icon :icon="`${actionButton.iconName}`" font-scale="1" />
              {{ actionButton.name}}
          </b-button>
        </div>
      </div>
    </b-col>

    <b-col :style="inPicker?{padding:0}:{}" :xl="Array.isArray(row.actionButtons)?'1':'2'" class="align-items-center" v-if="Array.isArray(row.badges)">
      <div  :style="(row.alignActionsTop?{paddingTop:'24px',height:'auto',paddingRight:'20px'}:{height:'auto'})" :class="'d-flex justify-content-center '+(row.alignActionsTop?'':'align-items-center') +' flex-wrap'">
           <b-badge tag="div" style="height:30px;padding-top:10px;padding-left:5px;padding-right:5px" class="align-middle" v-for="(badge,index) in row.badges" :variant="badge.variant || ''" :style="{color:badge.color || ''}" :key="'badge_'+index">{{ badge.text }}</b-badge>
      </div>
    </b-col>
    <b-col :style="inPicker?{padding:0}:{}" :xl="row.rowSize && row.rowSize.xl?(row.rowSize.xl<11 && !Array.isArray(row.actionButtons)?2:1):(row.hideActionBadge?2:1)" v-if="showRowActions || row.CreatedByUser">
      <div
        class="h-100 float-right"
        :class="row.subGrid ? 'align-items-start mt-3' : 'align-items-center'"
         v-if="!row.hideActionButton"
      >
        <b-dropdown
          class="align-middle m-6"
          size="sm"
          right
          :toggle-class="['btn-simple', 'global-primary__bg']"
          menu-class="w-max-content"
          text="Actions"
          v-if="!row.hideActionButton"
        >
          <b-dropdown-item
            v-if="allowDownload || row.download"
            :href="getDownloadURL(row.ID)"
            target="_blank"
          >
            Download
          </b-dropdown-item>
          <b-dropdown-item
            v-if="allowRename"
            @click="$emit('openEditRowDialog', row)"
          >
            Rename
          </b-dropdown-item>
          <b-dropdown-item
            v-if="allowEdit || row.CreatedByUser || row.showEdit"
            @click="$emit('openEditRowDialog', row)"
          >
            {{ editButtonLabel?editButtonLabel:'Edit' }}
          </b-dropdown-item>
         
          <b-dropdown-group v-if="Array.isArray(row.actions)">
            <span
              v-for="(action, i) in row.actions"
              :key="`${action.name}_customAction_${i}`"
            >
              <b-dropdown-item
                v-if="action.component.componentName === 'CustomDialog'"
                text-class="dd__text--btn"
                @click="
                  $emit('onOpenCustomDialog', {
                    rowID: row.ID,
                    ...action.component,
                    ...action.componentParams
                  })
                "
              >
                {{ action.name }}
              </b-dropdown-item>
              <b-dropdown-item
                v-else-if="action.component.componentName === 'ItemDetailsDialog'"
                text-class="dd__text--btn"
                @click="$emit('openItemDetailsDialog', {
                    record: row,
                    ...action.component.componentParams,
                  })">
                {{ action.name }}
              </b-dropdown-item>
              <b-dropdown-item
                v-else-if="action.component.componentName === 'CFormDialog'"
                text-class="dd__text--btn"
                @click="$emit('openCFormDialog', {
                    record: row,
                    ...action.component.componentParams,
                  })
                "
              >
                {{ action.name }}
              </b-dropdown-item>
              <b-dropdown-item
                v-else-if="action.component.componentName === 'SurveyJsFormDialog'"
                text-class="dd__text--btn"
                @click="$emit('openSurveyJsDialog', {
                    record: row,
                    ...action.component.componentParams,
                  })
                "
              >
                {{ action.name }}
              </b-dropdown-item>
               <b-dropdown-item
                v-else-if="action.component.componentName === 'CustomConfirmation'"
                text-class="dd__text--btn"
                @click="
                  $emit('onOpenCustomConfirmation', {
                    rowID: row.ID,
                    ...action.component,
                  })
                "
              >
                {{ action.name }}
              </b-dropdown-item>
                <b-dd-item
                v-else-if="action.component.routeName"
                 text-class="dd__text--btn"
                 :to="{
                    name: action.component.routeName,
                    params: {
                    rowID: row.ID,
                    ...action.component
                  }
                 }"

           
                >
                  
                    {{ action.name }}


                </b-dd-item>

              <b-dd-text
                v-else-if="action.component.componentName === 'EmitEvent'"
                text-class="dd__text--btn"
                @click="$emit('customEvent', {eventName:action.component.emitEvent.eventName,params:action.component.emitEvent.params })">
                {{ action.name }}
              </b-dd-text>
              <b-dd-item
                v-else-if="action.component.componentName === 'Link'"
                text-class="dd__text--btn"
                :target="action.component.url && action.component.url.params && action.component.url.params.newTab ? '_blank' : ''"
                :to="action.component.url">
                {{ action.name }}
              </b-dd-item>
              <b-dd-item
                v-else-if="action.component.componentName === 'DownloadLink'"
                text-class="dd__text--btn"
                :target="action.component.url && action.component.url.params && action.component.url.params.newTab ? '_blank' : ''"
                :href="getDownloadLinkUrl(action.component.url)">
                {{ action.name }}
              </b-dd-item>
              <b-dd-item
                v-else-if="action.component.componentName === 'LinkHref'"
                text-class="dd__text--btn"
                :target="action.component.linkOpenNewTab && action.component.linkOpenNewTab && action.component.linkOpenNewTab ? '_blank' : ''"
                :href="action.component.url">
                {{ action.name }}
              </b-dd-item>
              <b-dd-text
                v-else-if="action.component.componentName === 'GetResourceLink'"
                text-class="dd__text--btn dd__text--hoverable"
                @click="$emit('onClickActionGetResourceLink', action.component)">
                {{ action.name }}
              </b-dd-text>
              <b-dd-text
                v-else-if="action.component.componentName === 'SubmitData'"
                text-class="dd__text--btn"
                @click="$emit('onClickActionSubmit', { ...row, actionSubmitUrl: action.component.submitUrl, actionLink: action.component.url })">
                {{ action.name }} 
              </b-dd-text>
            
              
              <b-dd-item
                v-else-if="!action.renderAsButton"
                :to="{
                  name: $route.params.isorg?'OrgModalView':'ModalView',
                  params: {
                    rowID: row.ID,
                    ...action.component
                  },
                }"
              >
                {{ action.name }}


              </b-dd-item>
            </span>
          </b-dropdown-group>
           <b-dropdown-item
            v-if="allowDelete || row.CreatedByUser || row.showDelete"
            @click="$emit('openDeleteRowDialog', row)"
          >
          {{ $t("general.delete") }}
          </b-dropdown-item>
          <b-dd-text v-if="isRowActionEmpty()" text-class="text-muted text--sm">
            No actions available
          </b-dd-text>

          <slot name="customRowActions"></slot>
        </b-dropdown>
        <div v-if="Array.isArray(row.actions)" style="margin-top:10px">
         
            <div v-for="(action,index) in row.actions" :key="'row_action_bttn_'+index">
               <div v-if="action.renderAsButton">
                   <b-btn
                    v-if="action.button.type === 'LINK'"
                    v-b-tooltip.hover :title="action.name"
                    variant="light"
                    style="border-radius: 50%;margin-left:0 !important"
                    :to="action.button.url">
                    <b-icon :icon="`${action.button.iconName}`" />
                  </b-btn>
                  <b-btn
                    v-else-if="action.button.type === 'SUBMIT_DATA'"
                    v-b-tooltip.hover :title="action.button.name"
                    variant="light"
                    style="border-radius: 50%; font-size:0.9rem;margin-left:0 !important"
                    @click="$emit('onClickActionBtnSubmit',{ ...row, actionButtonSubmitUrl: action.button.submitUrl, actionButtonLink: action.button.url,dialogToOpen:action.button.dialogToOpen })">
                      <b-icon :icon="`${action.button.iconName}`" font-scale="1" />
                  </b-btn>
                  <b-button
                    v-else-if="action.button.type=== 'SUBMIT_DATA_WITH_TEXT'"
                    v-b-tooltip.hover :title="action.button.name"
                    :variant="action.button.variant"
                    :pill="action.button.pill"
                    class="ml-2"
                    style="border-radius: 5%; font-size:0.9rem;height:50px; margin-left:0 !important"
                    @click="$emit('onClickActionBtnSubmit',{ ...row, actionButtonSubmitUrl: action.button.submitUrl, actionButtonLink: action.button.url,dialogToOpen:action.button.dialogToOpen,params:action.buttonParams })">
                      <b-icon :icon="`${action.button.iconName}`" font-scale="1" />
                      {{ action.button.name}}
                  </b-button>
              </div>
          </div>
          

        </div>
      </div>
      <div v-else-if="row.hideActionButton && row.hideActionBadge" :xl="3">
              <b-badge tag="div" :variant="row.hideActionBadge.variant" style="height:auto;padding-top:10px;padding-left:5px;padding-right:5px;padding-bottom:8px;display:block;white-space:normal" class="align-middle" v-html="row.hideActionBadge.text"></b-badge>

      </div>
    </b-col>
  </b-row>
</template>

<script>
import { API_URL } from '@/config';

export default {
  name: 'EcenterTableRow',
  props: [
    'row',
    'showSelectRowCheckbox',
    'showRowActions',
    'allowEdit',
    'allowDelete',
    'allowRename',
    'allowDownload',
    'createdByUserCanEdit',
    'editButtonLabel',
    'gridControls',
    'inPicker',
    'gridControlUri'
  ],
  mounted(){

  },  
  methods: {
    getDownloadLinkUrl(url){

      return API_URL+url;


    },
    getDownloadURL(id) {
      if (this.row.download) {
        return `${API_URL}${this.row.download}`;
      }
      return API_URL + 'apiUserFileFetch/download&fileID=' + id;
    },
    isRowActionEmpty() {
      if (this.allowDownload || this.row.download || this.allowRename || this.allowEdit ||
      this.row.CreatedByUser || this.row.showEdit || this.allowDelete || this.row.showDelete ||
      (Array.isArray(this.row.actions) && this.row.actions.length > 0)) {
        return false;
      }
      return true;
    }
  },
};
</script>
