import vm from '@/main';


let currentLoader = null;
let requests = [];
let hideLoaderCanGo=false;

export const hideLoader = () => {
 
  

    setTimeout(() => {
      if(currentLoader){

       
          vm.$bvModal.hide(currentLoader);

        
      }
      currentLoader = null;
    }, 300);
  
};

export default (http, store) => {
  http.interceptors.request.use(function (config) {
    
    if (currentLoader == null && !config.silent) {
      vm.$bvModal.show('loading-modal');
      currentLoader="loading-modal"

    
    }

    requests.push(1);


    setTimeout(function(){
      hideLoaderCanGo=true;
    },300);

    return config;
  }, (error) => {
    setTimeout(function(){
      requests.pop();

      if(requests.length==0){
         hideLoader();
      }

    },300);
   

    return Promise.reject(error);
  });
  http.interceptors.response.use(
    response => {
      setTimeout(function(){
        requests.pop();
        var waitInterval=setInterval(function(){

          if(requests.length==0){
            clearInterval(waitInterval);

            hideLoader();
         }

        },100);
      },300);
              

            
        
       
  
     
      return Promise.resolve(response);
    }, (error) => {

      requests.pop();

      if(requests.length==0){
         hideLoader();
      }

      return Promise.reject(error);
    });
};
