<template>
  <b-modal id='form-collaboration-modal' size="xl" hide-footer  @close="closeClicked" no-close-on-backdrop visible >
    <template v-slot:modal-title>{{ $t("FormCollaborationModal.modify") }}</template>
    <ResultCollaboration :ResultSetID="ResultSetID" :ResultSetType="ResultSetType">

    </ResultCollaboration>
  </b-modal>
</template>
<script>
import * as formService from '@/services/forms'
import * as staffService from '@/services/staff'
import ResultCollaboration from '@/components/ResultCollaboration.vue'

import { API_URL } from '@/config';

export default {
  name: 'ResultsCollaborationModal',
  data() {
    return {
     
    };
  },
  components:{
    ResultCollaboration
  },
 
  methods: {
    closeClicked(){
      this.$emit('resultCollaboratorsClosed');
    }
   

  },
  props:['ResultSetID','ResultSetType']
};
</script>
