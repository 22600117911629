// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiCollectionSeries/getDeploymentResponseTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiCollectionSeries/getDeploymentResponses', payload).then(getData);

export const getRow = (rowID) => http.post('apiCollectionSeries/getDeploymentResponse', {ID:rowID}).then(getData);

export const getExport = (rowID) => http.post('apiCollectionSeries/getDeploymentResponse', {ID:rowID}).then(getData);