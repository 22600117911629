<template>
  <b-container fluid>
    <b-row class="align-items-center" v-if="headerText">
       
        <b-col v-if="isGridField" md="12">
          <div>
                <pageHeader v-bind:isGridField="isGridField" v-bind:headerText="headerText" />
            </div>
        </b-col>
       <b-col v-else-if="isChild" md="12">
          <div>
                <pageHeader v-bind:isChild="isChild" v-bind:headerText="headerText" />
            </div>
        </b-col>
    
        <b-col v-else>
            <div>
                <pageHeader v-bind:headerText="headerText" />
            </div>

            <div v-if="!$store.getters.hideToolbar && !isChild" >
                <b-breadcrumb class="bg-white p-0" style="text-transform: capitalize;" :items="breadcrumbs || []"></b-breadcrumb>
            </div>
        </b-col>
        <b-col>
            <slot name="pageActions"></slot>
        </b-col>
    </b-row>

    <slot name="content"></slot>
  </b-container>
</template>

<script>
import pageHeader from './header.vue';

export default {
  data() {
      return {
        
      }
  },
  props: ['headerText', 'breadcrumbs','isChild',"isGridField"],

  components: {pageHeader}
};
</script>
