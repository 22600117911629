// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

const CNTLR_NAME = 'apiTaxonomyGroup';

export const getTableSettings = (payload = null) =>
  http
    .post(`${CNTLR_NAME}/getTableSettings`, payload)
    .then(getData);

export const get = (payload = null) =>
  http.post(`${CNTLR_NAME}/getRows`, payload).then(getData);

export const create = row =>
  http.post(`${CNTLR_NAME}/create`, row).then(getData);

export const update = row =>
  http.put(`${CNTLR_NAME}/edit&ID=${row.ID}`, row).then(getData);

export const remove = row =>
  http.post(`${CNTLR_NAME}/delete`, row).then(getData);

export const getRow = rowID =>
  http.get(`${CNTLR_NAME}/getRow&ID=${rowID}`).then(getData);
