// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiQlikSheets/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiQlikSheets/getSheets', payload).then(getData);

export const getRow = (payload = null) => http.post('apiQlikSheets/getQlikSheet', payload).then(getData);


export const create = (row) => http.post('apiQlikSheets/createQlikSheet', row).then(getData);

export const update = (data) => http.post('apiQlikSheets/editQlikSheet', data).then(getData);

export const remove = (row) => http.post('apiQlikSheets/deleteQlikSheet', row).then(getData);

export const getFormConfig = (rowID,action) => http.post('apiQlikSheets/getFormConfig',{RowID:rowID,Action:action}).then(getData);

