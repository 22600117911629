<template>
  <div class="w-100" v-if="isValidItem()">
    <ProfileModal
      :id="item.AuthorID"
      v-if="showModal && item.AuthorID"
      @onClose="showModal = false"
    />
    <b-row>
      <b-col sm="12">
        <p class="d-block mb-0">
          <span class="font-weight-bold text--md">{{ item.Title }}</span> -
          <span class="text-muted text--sm">
            {{ item.Added }}
          </span>
          <span
            v-if="item.Author"
            class="text-muted text--sm"
            @click="showModal = true"
            role="button"
            v-html="$t('DiscussionBoardItem.by', {Author:item.Author})"></span
          >
        </p>
        <p class="mb-0">
          <span v-if="item.Groups" class="text-muted text--sm">{{
            item.Groups
          }}</span>
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <p>{{ item.Content }}</p>
      </b-col>
    </b-row>
    <b-row class="text-muted">
      <b-col xl="1" sm="2">
        <div><b-icon icon="hand-thumbs-up" /> {{ item.Votes }}</div>
      </b-col>
      <b-col xl="1" sm="2">
        <div><b-icon icon="chat" /> {{ item.Posts }}</div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ProfileModal from '@/components/ProfileModal.vue';

export default {
  name: 'DiscussionBoardItem',
  components: { ProfileModal },
  data() {
    return {
      showModal: false,
    };
  },
  props: {
    item: [Object, Array],
  },
  methods: {
    isValidItem() {
      const res = this.item && typeof this.item === 'object';
      return res;
    },
  },
  created() {},
};
</script>
