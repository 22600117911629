<template>
  <b-modal id="shareGrid-dialog" hide-footer visible size="lg">
    <template v-slot:modal-header>
       <div class="d-flex w-100">
               <h3 class="font-weight-bold mr-auto">{{ $t("general.share") }}</h3>
               <b-btn v-if="shareData.length>0 || recordDeleted" class="ml-2" variant="success" @click="saveShares"
                >{{ $t('general.save') }}</b-btn>
               <b-btn class="ml-2" variant="danger" @click="$emit('onCloseShareGrid')">{{ $t('general.close') }}</b-btn>
      </div>
 
    </template>
    <template v-slot:modal-footer>
      <div class="w-100">
      
        <b-row>
        <b-col md="9"></b-col>
        <b-col md="1">
              <div class='mb-0 font-weight-bold mr-auto'></div>
              
          </b-col>
        </b-row>
      </div>
    </template>
    <div class="container-fluid bg-white">
      <span v-if="error3">{{ this.errorMessage }}</span>
      <b-row>
          <b-col md="8">

          </b-col>
          <b-col md="3" class='text-right'>
             <b-button @click="launchAddShare" variant="success">{{ $t("ShareGridDialog.add") }}</b-button>
          </b-col>
      </b-row>
    
    </div>
    <div v-if="shareData.length>0" class="container-fluid bg-white">
      <div style="height:25px"></div>
    
      <b-row :key="refreshTable">
        <b-col md="12">
          <div>
              <b-row class="border-bottom pt-1 pb-2">
                <b-col md='5' style='font-weight:bold'>
                  {{ $t("ShareGridDialog.with") }}
                </b-col>
                <b-col md="3" style='font-weight:bold'>
                  {{ $t("ShareGridDialog.shared") }}
                </b-col>
                <b-col md="3">

                </b-col>
              </b-row>
          </div>
          <div v-for="(share, index) in shareData" :key="'ShareData_' + index">
           
            <b-row class="border-bottom pt-1 pb-2">
              <b-col md="5">
                {{ share.Email }}
              </b-col>
              <b-col md="3">
                {{ share.SharedDate }}
              </b-col>
               <b-col md="3" v-if="share.DefaultShare">
                   <div class="d-flex w-100">
                    <b-btn v-if="shareSetUri " size="sm" class="ml-2" variant="success" @click="saveShares"
                      >{{ $t("general.send") }}</b-btn>
                    
                  </div>
               </b-col>
              <b-col md="3" v-else>
                <div class="d-flex w-100">
                 <b-btn v-if="shareResendUri && shareData[index].ID" size="sm" class="ml-2" variant="primary" @click="resendLink(index)"
                  >{{ $t("ShareGridDialog.resend") }}</b-btn>
                <b-btn size="sm" class="ml-2" variant="danger" @click="onDelete(index)"
                  >{{ $t("ShareGridDialog.revoke") }}</b-btn>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
     
    </div>
    <div v-else>
      <b-alert show variant="warning">{{ $t("ShareGridDialog.no") }}</b-alert>
    </div>
     <EcenterDialog
        id="ShareStaffDialog"
        v-if="showDialog"
        cancelTitle="Cancel"
        :centeredModal="true"
        :noCloseOnBackdrop="true"
        okTitle="Add Share"
        :showOkBtn="true"
        :showCancelBtn="true"
        @onClickOk="dialogOkClicked($event)"
        @onClickCancel="dialogCancelClicked"
      >
       <template v-slot:dialogContent>
  
        <ListView
          serviceNameProp="staff"
          customFieldSetNameProp="Staff Information"
           @onRowSelected="handleRowSelected($event)"
           @applySelectAll="handleSelectAll($event)"
           @applyDeselectAll="handleDeselectAll"
           @pickerValueAdded="pickerValueAddedFunc"
           @pickerValueMultiSelect="pickerValueMultiAddedFunc"
          :selectedUsers="selectedUsers"
          :showSelectAll="true"
          :passedParams="{inShareGridPicker:true,...listViewParams}"
          ref="listViewInstance_ShareContact"
        ></ListView>
      </template>

      </EcenterDialog>

  </b-modal>
</template>

<script>
import { API_URL } from '@/config';
import EcenterDialog from './ecenterDialog.vue';
import { deepCopy, isObject } from '@/utils/langUtils';

export default {
  name: 'ShareDialog',
  components: {
    InputFactory: () => import('@/components/InputFactory.vue'),
    LabelTooltip: () => import('@/components/LabelTooltip.vue'),
    EcenterDialog: () => import('@/components/ecenterDialog.vue'),
    ListView: () => import('@/app/list-view/index.vue'),

  },
  data() {
    return {
      error1: false,
      error2: false,
      error3: false,
      errorMessage: '',
      refreshTable: null,
      row: {
        type: Object,
        default: function() {
          return { email: null, confirmEmail: null };
        },
      },
      showAdd:false,
      showConfirmation: false,
      shareData: [],
      recordDeleted:false,
      showDialog:false,
      newShareIDs:[],
      prevNewShares:[],
      selectedUsers:[],
      listViewParams:{}
    };
  },
  props: {
    // data passed to the dialog
    client: null,
    shareFilters: null,
    shareGetUri: null,
    shareSetUri: null,
    shareResendUri:null
  },
  mounted() {
    this.getShareRecords();
  },
  created() {},
  methods: {
    handleDeselectAll(){
             this.$emit('input', []);

    },
    pickerValueAddedFunc(eventData){

      /*

      const payload = { text: deepCopy(eventData.displayValue), ID: eventData.rowID };


      var newValue=[payload];
      this.$emit('input', newValue);

      this.showDialog = false;
    */



    },
    pickerValueMultiAddedFunc(eventData){


        this.newShareIDs.push({ID:null,UserID:eventData.ID});


      /*
       
      const payload = { text: deepCopy(eventData.displayValue), ID: eventData.rowID };


      var newValue=payload;

       if(this.valuesToReturn.length==0 && this.value.length>0 && !this.updated){
            this.valuesToReturn=deepCopy(this.value);
            
            this.valuesToReturn.push(newValue);
        }else{
              this.valuesToReturn.push(newValue);
        }
      
      this.refreshBttn=new Date().getTime();
      */

     // this.$emit('input',  this.valuesToReturn);
        

    },
    handleRowSelected(row) {

        this.newShareIDs.push({ID:null,UserID:row.ID});



  
     
    },
    launchAddShare(){
      this.prevNewShares=deepCopy(this.newShareIDs);
      this.showDialog=true;

    },
    dialogOkClicked(data){
        this.showDialog=false;
       
        this.getShareRecords();


    },
    dialogCancelClicked(){
      this.newShareIDs=deepCopy(this.prevNewShares);
      this.showDialog=false;

    

    },
    showAddShareClicked(){
      this.showAdd=true;
    },
    cancelAddShareClicked(){
      this.showAdd=false;
    },
    changeEmail() {
      this.showConfirmation = true;
    },
    getShareRecords() {
      // Get the emails of the people the grid is currently shared with
      if (this.shareGetUri) {
        this.$http
          .post(API_URL + '/' + this.shareGetUri, {
            client: this.client,
            newShares:this.newShareIDs
          })
          .then(response => {
            if (response.data.data.success) {
              this.shareData = response.data.data.message;
              this.selectedUsers=response.data.data.selectedUsers;

              this.listViewParams=response.data.data.params;
            } else {
              console.log(response.data.data.message);
            }
          });
      } else {
        console.log('shareGetUri was not set');
      }
    },
    onDelete(index) {
      if (this.shareSetUri) {

            let deleteMessage="Are you sure you want to remove "+this.shareData[index].Email+ " from the share list?";
              this.$bvModal.msgBoxConfirm(deleteMessage, {
                  hideHeaderClose: true,
                  centered: true,
                  noCloseOnBackdrop: true,
                  noCloseOnEsc: true,
                  okVariant: 'success',
                  okTitle: 'Delete',
                  cancelVariant: 'danger',
                  cancelTitle: 'Cancel',
              }).then(value=>{
                  if(value){
                      

                          this.$http
                                .post(API_URL + '/' + this.shareSetUri, {
                                  email: this.shareData[index],
                                  client: this.client,
                                  filters: this.shareFilters,
                                  requestType: 'Delete',
                                })
                                .then(response => {
                                    this.shareData.splice(index,1)
                                     this.recordDeleted=true;
                                 
                                    
                                    
                                });

                  }
              });

        
      } else {
        this.errorMessage = 'Something went wrong.';
        this.error3 = true;
      }
    },
    addShare() {
      if (this.validFormInfo()) {
          this.shareData.push({Email:this.row.email,SharedDate:null})

          this.showAdd=false;
      }

    },
    saveShares(){
        if (this.shareSetUri) {

        
          this.$http
            .post(API_URL + '/' + this.shareSetUri, {
              emails: this.shareData,
              client: this.client,
              filters: this.shareFilters,
              requestType: 'Save',
            })
            .then(response => {
              if (response.data.data.success) {
                 this.newShareIDs=[];

                 if(response.data.data.message && response.data.data.message.length>0){
                    this.$bvModal.msgBoxOk(response.data.data.message)
                    .then(value=>{
                      this.row = this.row.default;

                      this.getShareRecords();
                      this.refreshTable = new Date().getTime();
                      this.showAdd=false;
                      
                    }); 
                 }else{
                      this.row = this.row.default;
                       this.newShareIDs=new Array;

                      this.getShareRecords();
                      this.refreshTable = new Date().getTime();
                      this.showAdd=false;

                 }
              }
              else {
                this.errorMessage = 'Something went wrong. Please try again.';
                this.error3 = true;
                console.log(response.data.data.message);
              }
            });
        } else {
          this.errorMessage = 'Something went wrong.';
          this.error3 = true;
          console.log('shareSetUri was not set');
        }
    },
    resendLink(index){
        let message="Resend link to "+this.shareData[index].Email+"?";
          this.$bvModal.msgBoxConfirm(message, {
                  hideHeaderClose: true,
                  centered: true,
                  noCloseOnBackdrop: true,
                  noCloseOnEsc: true,
                  okVariant: 'success',
                  okTitle: 'Resend',
                  cancelVariant: 'danger',
                  cancelTitle: 'Cancel',
              }).then(value=>{
                  if(value){

                    this.$http
                          .post(API_URL + '/' + this.shareResendUri, {
                            email: this.shareData[index].Email,
                            client: this.client,
                            filters: this.shareFilters,
                            requestType: 'Save',
                          })
                          .then(response => {
                            if (response.data.data.success) {
                              
                              this.$bvModal.msgBoxOk(response.data.data.message)
                              .then(value=>{
                                this.row = this.row.default;
                                this.getShareRecords();
                                this.refreshTable = new Date().getTime();
                                this.showAdd=false;
                              }); 
                            }
                            else {
                              this.errorMessage = 'Something went wrong. Please try again.';
                              this.error3 = true;
                              console.log(response.data.data.message);
                            }
                          });
                       

                  }
              });
          

    },
    validFormInfo() {
      this.error1 = false;
      this.error2 = false;
      this.error3 = false;

      // Validate inputs and set errors to show if needed
      //if(!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))) {
      if (!/^[^\s@]+@[^\s@]+$/.test(this.row.email)) {
        this.error1 = true;
      }

      if (this.row.confirmEmail != this.row.email) {
        this.error2 = true;
      }

      if (this.error1 || this.error2) {
        this.errorMessage =
          'Could not proccess request because there is one or more errors.';
        this.error3 = true;
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
