// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiImportOrgData/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiImportOrgData/getRows', payload).then(getData);

export const getRow = (rowID) => http.get(`apiImportOrgData/getRow&RowID=${rowID}`).then(getData);

export const create = (row) => http.post('apiImportOrgData/addRow', row).then(getData);

export const remove = (row) => http.post('apiImportOrgData/deleteRow',row).then(getData);

export const update = (row) => http.post('apiImportOrgData/editRow',row).then(getData);