import auth from '@/router/middleware/auth';

const TodoList = r => require.ensure([], () => r(require('./index')), 'todo-bundle');

export default [
  {
    path: '/todolist/:model',
    name: 'todoList',
    component: TodoList,
    meta: {
      middleware: [auth]
    }
  }
];
