// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiCallLog/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiCallLog/getRows', payload).then(getData);

export const getRow = (rowID) => http.get(`apiCallLog/getRow&RowID=${rowID}`).then(getData);

export const create = (row) => http.post('apiCallLog/addCall', row).then(getData);

export const update = (row) => http.post('apiCallLog/editCall&CallID=' + row.ID, row).then(getData);

export const remove = (row) => http.post('apiCallLog/deleteCall', row).then(getData);

export const getFieldSetName = () =>http.get('apiCallLog/getCustomFieldSetName').then(getData);

export const getConfirmationMessage = () => http.get('apiCallLog/getConfirmationMessage').then(getData);

export const onlineFormLogin  = (payload) => http.post('apiAuthentication/authenticateHelplineLogin', payload).then(getData);

export const auditEditForm = (payload) => http.post('apiCallLog/checkForCallerChange',payload).then(getData)