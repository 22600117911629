<template>
  <b-modal size="xl" id="full-calendar-dialog" hide-footer visible>
    <template v-slot:modal-header>
      <div class="d-flex w-100">
        <h3 class="font-weight-bold mr-auto"></h3>
        <b-btn
          size="sm"
          class="ml-2"
          variant="danger"
          @click="$emit('onCloseCalendarDialog')"
          >{{ $t("general.close") }}</b-btn
        >
      </div>
    </template>
    <div  class="container-fluid bg-white">
     
       
        
                <Calendar :linkedModelID="linkedModelID" @close-calendar-dialog="closeDialogTriggered"></Calendar>
           
       
    </div>
  </b-modal>
</template>
<script>
import InputFactory from '@/components/InputFactory';
import LabelTooltip from '@/components/LabelTooltip';
import * as formService from '@/services/forms';
import Calendar from '@/app/calendar';
import { BCollapse } from 'bootstrap-vue';
import Label from './Label.vue';


export default {
  name: 'AddDomainDialog',
  components: {
    InputFactory,
    LabelTooltip,
    Calendar
  },
  data() {
    return {
      isEventDeployment:null
    };
  },
  props: {
    formError: { type: String, default: '' },
    assessmentID:{type: String, default:null},
    assessmentType:{type: String, default:null},
    linkedModelID:{type: String, default:null}
    
  },
  created() {},
  mounted(){
  },
  methods: {
   closeDialogTriggered(){
     this.$emit('closeCalendarDialog');
   }
  },
};
</script>
