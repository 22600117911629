<template>
  <div class="w-100" v-if="isValidItem()">
    <b-row>
      <b-col sm="5">
        <p v-if="item.Name" class="text--md mb-0">
          {{ item.Name }}
        </p>
        <p v-if="item.City || item.Province" class="text-muted text--sm">
          {{ item.City }}, {{ item.Province }}
        </p>
        <a
          v-if="item.Website"
          target="_blank"
          rel="noopener noreferrer"
          :href="item.Website"
        >
          {{ item.Website }}
        </a>
      </b-col>
      <b-col v-if="Array.isArray(item.Ratings)" sm="4">
        <div v-for="(rating, i) in item.Ratings" :key="`rating_${i}`">
          <label class="font-weight-bold">{{ rating.text }}</label>
          <b-form-rating
            inline
            size="sm"
            no-border
            variant="warning"
            :value="rating.value"
            readonly
          ></b-form-rating>
        </div>
      </b-col>
      <b-col v-if="Array.isArray(item.Services)" sm="3">
        <span
          v-for="(service, i) in item.Services"
          :key="`serviceTag_${i}`"
          :class="service.cssClass"
        >
          {{ service.text }}
        </span>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { API_URL } from '@/config';

export default {
  name: 'ProviderItem',
  data() {
    return {
      apiURL: API_URL,
      showModal: false,
    };
  },
  props: {
    item: [Object, Array],
  },
  methods: {
    isValidItem() {
      const res = this.item && typeof this.item === 'object';
      return res;
    },
  },
  created() {},
};
</script>
