export function init(Survey) {
    var testEditWidget={
        name:'star-rating',
        title:'Star Rating',
        iconName:'icon-rating',
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'star-rating';
        },
        init(){
          Survey.Serializer.addClass('star-rating',[],null,'empty');

          Survey.Serializer.addProperty("star-rating", {
            name: "starNumber:number",
            displayName:"Number of Stars",
            default:5,
            //default: true, //uncomment this line to make this property true, search functionality is on, by default.
            category: "Rating Options",
          });
        },
        htmlTemplate:"<div style='width:25%;color:#ffc107' class='star-rating-container'><i style='width:20%' data-index='0' class='far fa-star fa-lg rating-0'></i><i data-index='1' style='width:20%' class='far fa-star fa-lg rating-1'></i><i data-index='2' style='width:20%' class='far fa-star fa-lg rating-2'></i><i data-index='3' style='width:20%' class='far fa-star fa-lg rating-3'></i><i data-index='4' style='width:20%' class='far fa-star fa-lg rating-4'></i></div>",
        afterRender:function(question,el){

          $(el).css('padding-top',"10px");
     
         
          
          if(question.starNumber){
            var max=question.starNumber;
            var i=0;

            var width=100/max;
            $(el).empty();

            for(i=0;i<max;i++){

              $(el).append("<i data-index='"+i+"' style='width:"+width+"%' class='far fa-star fa-lg rating-"+i+"'></i>");

              
            }

            $(el).find('i').click(function(){
              var value=$(this).data('index');
              var starsBefore=value>0?value-1:0;
              var starsAfter=max-value;
  
              var makeSolid=false;
              if($(el).find('.rating-'+value).hasClass('fas')){
                $(el).find('.rating-'+value).removeClass('fas');
                $(el).find('.rating-'+value).addClass('far');
  
                makeSolid=false;
  
                question.value=null;
              }else{
                $(el).find('.rating-'+value).removeClass('far');
                $(el).find('.rating-'+value).addClass('fas');
  
                makeSolid=true;
  
                question.value=(value+1);
  
  
              }
  
              if(starsBefore || value==1){
                var i=0;
  
                for(i=0;i<=starsBefore;i++){
                  if(makeSolid){
                    if($(el).find('.rating-'+i).hasClass('far')){
                      $(el).find('.rating-'+i).removeClass('far');
                      $(el).find('.rating-'+i).addClass('fas');
        
                    }
                  }else{
  
                    if($(el).find('.rating-'+i).hasClass('fas')){
                      $(el).find('.rating-'+i).removeClass('fas');
                      $(el).find('.rating-'+i).addClass('far');
        
                    }
                  }
                }
              }
  
              if(starsAfter){
                var i;
  
                for(i=value+1;i<=max;i++){
                  if($(el).find('.rating-'+i).hasClass('fas')){
                    $(el).find('.rating-'+i).removeClass('fas');
                    $(el).find('.rating-'+i).addClass('far');
      
                  }
                }
              }
             
            });

            if(question.value){
              var value=(question.value-1);
              var starsBefore=value>0?value-1:0;
              var starsAfter=max-value;
  
              var makeSolid=false;
              if($(el).find('.rating-'+value).hasClass('fas')){
                $(el).find('.rating-'+value).removeClass('fas');
                $(el).find('.rating-'+value).addClass('far');
  
                makeSolid=false;
  
              }else{
                $(el).find('.rating-'+value).removeClass('far');
                $(el).find('.rating-'+value).addClass('fas');
  
                makeSolid=true;
  
  
  
              }
  
              if(starsBefore || value==1){
                var i=0;
  
                for(i=0;i<=starsBefore;i++){
                  if(makeSolid){
                    if($(el).find('.rating-'+i).hasClass('far')){
                      $(el).find('.rating-'+i).removeClass('far');
                      $(el).find('.rating-'+i).addClass('fas');
        
                    }
                  }else{
  
                    if($(el).find('.rating-'+i).hasClass('fas')){
                      $(el).find('.rating-'+i).removeClass('fas');
                      $(el).find('.rating-'+i).addClass('far');
        
                    }
                  }
                }
              }
  
              if(starsAfter){
                var i;
  
                for(i=value+1;i<=max;i++){
                  if($(el).find('.rating-'+i).hasClass('fas')){
                    $(el).find('.rating-'+i).removeClass('fas');
                    $(el).find('.rating-'+i).addClass('far');
      
                  }
                }
              }

            }


          }
          
          question.registerFunctionOnPropertyValueChanged("starNumber",
          function () {

              var max=question.starNumber;
              var i=0;
              var width=100/max;
              $(el).empty();

              for(i=0;i<max;i++){

                $(el).append("<i data-index='"+i+"' style='width:"+width+"%' class='far fa-star fa-lg rating-"+i+"'></i>");

                
              }

        
          });

        

          
           
        }
      };
    //Register our widget in singleton custom widget collection

    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('star-rating')){
        Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget, "customtype");
    }
    
}