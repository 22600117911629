import { isObject, deepCopy } from './langUtils';

export const removePropertiesFromElement = function(element, propertyKeys) {
  if (!isObject(element) || !Array.isArray(propertyKeys)) return;
  for (const key of propertyKeys) {
    if (typeof element[key] !== 'undefined') {
        delete element[key];
    }
  }
  const choices = Array.isArray(element['choices']) ? element['choices'] : [];
  for (const choice of choices) {
    removePropertiesFromElement(choice, propertyKeys);
  }
  const rows = Array.isArray(element['rows']) ? element['rows'] : [];
  for (const row of rows) {
    removePropertiesFromElement(row, propertyKeys);
  }
  const columns = Array.isArray(element['columns']) ? element['columns'] : [];
  for (const column of columns) {
    removePropertiesFromElement(column, propertyKeys);
  }
  if (element['elementType'] === 'panel') {
    const panelElements = Array.isArray(element['elements'])
      ? element['elements']
      : [];
    for (const panelElement of panelElements) {
      removePropertiesFromElement(panelElement, propertyKeys);
    }
  }
};

export const surveyShallowCopy = function(jsonSurvey) {
  const survey = deepCopy(jsonSurvey);
  if (!isObject(survey) || !Array.isArray(survey['pages'])) return {};
  for (const page of survey['pages']) {
    const elements = Array.isArray(page['elements']) ? page['elements'] : [];
    const propertyKeys = ['answerid', 'partitionid', 'questionid'];
    for (const element of elements) {
      removePropertiesFromElement(element, propertyKeys);
    }
  }
  return survey;
};
