import auth from '@/router/middleware/auth';
const Organization = r => require.ensure([], () => r(require('./Organization')), 'organization-bundle');
export default [
  {
    path: '/organization',
    name: 'organization',
    component: Organization,
    meta: {
      middleware: [auth]
    }
  },
];