import auth from '@/router/middleware/auth';
import DiscussionBoardModal from './DiscussionBoardModal';
const DiscussionBoardHome = r => require.ensure([], () => r(require('./index')), 'discussion-baord-bundle');

export default [
  {
    path: '/discussion-board',
    name: 'discussion-board',
    component: DiscussionBoardHome,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/discussion-board-modal',
    name: 'discussion-board-modal',
    component: DiscussionBoardModal,
    meta: {
      middleware: [auth]
    }
  }
];
