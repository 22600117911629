import { isEqual, isNil, isNumber, isString } from 'lodash';
import sanitizeHtml from 'sanitize-html';

export const deepCopy = obj => {
  if (isNil(obj) || isString(obj) || isNumber(obj)) return obj;
  return JSON.parse(JSON.stringify(obj));
};

/**
 * Added strict param and array check on 07/2022
 * @param {object} obj
 * @param {boolean} strict checks if obj is an array
 * @returns bool
 */
export const isObject = (obj, strict=false) => {
  let isObj = typeof obj === 'object' && obj !== null;
  if (strict) {
    isObj = isObj && !Array.isArray(obj);
  }
  return isObj;
}

export const isStringAndNotEmpty = obj => isString(obj) && obj.length > 0;

export const isArrayAndNotEmpty = obj => Array.isArray(obj) && obj.length > 0;

export const isObjectAndNotEmpty = obj =>
  isObject(obj) && Object.keys(obj).length > 0;

export const stringifyOnlyObjs = obj => {
  if (isNil(obj) || isString(obj) || isNumber(obj)) return obj;
  return JSON.stringify(obj);
};

export const isFile = obj => {
  return obj && obj instanceof File;
};

export const getFileExtension = file => {
  if (!isFile(file)) return '';
  if (file.name === undefined) return '';
  let ext = '';
  if (isStringAndNotEmpty(file.name)) {
    let fileName = file.name;
    ext =
      fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || '';
  }
  return ext;
};

export const findFile = obj => {
  if (!isObject(obj)) {
    return null;
  }
  for (const key in obj) {
    if (isFile(obj[key])) {
      return obj[key];
    }
  }
};

/**
 * @param {Array<object>} elements
 * @param {string} prop
 * @return {Set}
 */
export const createSetByProp = (elements, prop) => {
  const set = new Set();
  for (const element of elements) {
    set.add(element[prop]);
  }
  return set;
};

/**
 * @param {object} obj
 * @param {object} meta
 * @returns {object}
 */
export const copyAndMerge = (obj1, obj2) => {
  const obj1Copy = deepCopy(obj1);
  const obj2Copy = deepCopy(obj2);
  return { ...obj1Copy, ...obj2Copy };
};

export const getErrorMessage = error => {
  if (isString(error)) {
    return error;
  } else if (isObject(error, true) && error.message) {
    return error.message;
  } else if (isObject(error, true) && error.data) {
    if (isObject(error.data, true) && isString(error.data.message)) return error.data.message;
    return JSON.stringify(error.data);
  } else {
    return JSON.stringify(error);
  }
};

export const objectDiff = (a, b) => {
  const delta = [];
  for (const key in a) {
    if (!isEqual(a[key], b[key]))
      delta.push(key);
  }
  return delta;
}

export const sanitizeHtmlText = (text) => {
  if (!isStringAndNotEmpty(text)) return "";
  const clean = sanitizeHtml(text);
  return clean;
}
