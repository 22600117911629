import Vue from 'vue';
// import vuetify from './plugins/vuetify';
import CustomCss from "./assets/scss/custom.scss";
import App from './App.vue';
import {SCRIPT_URL} from '@/config';
import router from './router';
import store from './store';
import eventBus from './plugins/eventbus';
import httpPlugin from './plugins/http';
import globalMixin from './plugins/globalMixin';
//import { http } from '@/plugins/http/index';
//import { getData } from '@/utils/get';

import FileManager from 'laravel-file-manager';
import LoadingTemplate from "./components/Loading";
// import 'vue-loading-overlay/dist/vue-loading.css';
import VueMeta from 'vue-meta';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import VueI18n from 'vue-i18n';

import en from "./lang/English.json";
import fr from "./lang/French.json";



const $ = require("./vendor/jquery/jquery-1.10.2.js");
const jQuery=$;
window.$= $;
window.jQuery=jQuery;


// Use the following block to integrate the amplify backend with the front end.
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';


tinyMCE.init({
  theme:'silver',
  mode:'none'
});
Amplify.configure(aws_exports);
//

Vue.use(FileManager, { store });

Vue.use(eventBus);
Vue.use(require('vue-moment'));

const Loader={
  Template:LoadingTemplate
};

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

Vue.directive('focus', {
  inserted: function (el) {
    el.focus();
  }
});

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
// Vue.use(Vuelidate);

Vue.use(httpPlugin, { store, router });
Vue.mixin(globalMixin);

Vue.config.productionTip = false;

Vue.use(VueI18n);

function loadLocaleMessages () {
  //broken/unfinished
  //attempt to use yii translation files
  /*return new Promise((resolve, reject) => {
    http.get(`apiTranslationFile/getFile`).then(response => { 
      console.log(response.data);
  
      resolve(response.data)
    })
    .catch(function(error) {
      console.log(error);
      reject(error)
    });
})*/
  const locales = require.context('./lang', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}

  
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

//default to english 
export const i18n = new VueI18n({
  locale: navigator.language,
  fallbackLocale: 'English',
  messages: loadLocaleMessages()
})

export default new Vue({
  // vuetify,
  router,
  store,
  Loader,
  i18n,
  render: h => h(App)
}).$mount('#app');
