// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiUserFile/getFilesTableSettings&type=file', payload).then(getData);

export const get = (payload = null) => http.post('apiUserFile/getFiles&type=file', payload).then(getData);

export const create = (file) => http.post('apiUserFile/createFile&type=file', file, {
  headers: {
      'Content-Type': 'multipart/form-data'
  }
}).then(getData);

export const update = (file) => http.put('apiUserFile/editFile&FileID=' + file.ID, file).then(getData);

export const remove = (file) => http.post('apiUserFile/deleteFile',file).then(getData);

export const getTags= (payload=null) => http.post('apiUserFile/getFileUserTags',payload).then(getData);

export const download = (payload = null) => http.post('apiUserFile/downloadFile&FileID=' + file.ID, file, payload).then(getData);

export const selectRow = (payload) => http.put('apiAssessment/attachItemToResultSet', payload).then(getData);