import { API_URL } from '@/config';
import * as clientService from '@/services/client';

export function init(Survey,vm,live=false) {
    var testEditWidget={
        name:'family-list',
        title:'Client Family Members',
        iconName:'',
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'family-list';
        },
        init(){
          Survey.Serializer.addClass('family-list',[],null,'empty');

       
        },
        htmlTemplate:"<div style='padding-left:20px' class='family-list-container'><div class='responseValue'></div><div class='familyBttn btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Add</div></div>",
        afterRender:function(question,el){

          $(el).css('padding-top',"10px");
     
          if(!live && $(el).closest('#surveyContainer').length>0){
            live=true;
          }

          if(question.ButtonText){
            $(el).find('.familyBttn').html(question.ButtonText.replace("<p>","").replace("</p>",""));


          }
          
          if(vm.readOnly){
            $(el).find('.familyBttn').hide();
            
          }
          $(el).find('.familyBttn').click(function(){

            if(live){
              vm.$root.$emit('openFamilyList',{questionCode:question.code,params:{ClientID:vm.$route.params.clientID,selectedFamily:question.value}});


            }else if(question.TestClientNumber){
             
             
               vm.$root.$emit('openFamilyList',{questionCode:question.code,params:{ClientNumber:question.TestClientNumber,selectedFamily:question.value}});


            }

          });
       
          vm.$root.$on('updateResponse',(data)=>{

                  
                  
            if($(el).closest('.svd-simulator-main').length>0 || live){
               if(data.questionCode==question.code){
                var responses;
                if(!question.value){
                 responses=new Array;
                }else{

                  if(!$.isArray(question.value)){
                       responses=JSON.parse(question.value);

                  }
                }


                   if($.inArray(data.value,responses)==-1){
                     responses.push(data.value);
     
                     question.value=JSON.stringify(responses);
                       $(el).find('.responseValue').empty();

                       
                       var params=new Object;
                       
                       if(live){
                          params.ClientID=vm.$route.params.clientID;
                       }else if(question.TestClientNumber){
                         params.ClientNumber=question.TestClientNumber;


                       }

                       clientService.getFamilyList(responses,{inQuestion:true,params:params})
                       .then(response =>{
     
                             $.each(response,function(key,value){
                               $(el).find('.responseValue').append("<div id='familyDetails_"+value.ID+"' class='familyDetails' style='width:80%;display:inline-block;padding-top:20px;padding-bottom:20px'></div>");

                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).append("<table style='width:70%;border:0'></table>");
                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr><th colspan='3'>Name:</th><th rowspan='2'><div style='margin-top:10px;background-color:#dc3545;color:#ffffff' data-familyid='"+value.ID+"' class='familyBttnRemove btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Remove</div></th></tr>");
                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr><td style='padding-bottom:10px' colspan='3'>"+value.Name+"</td><td></td></tr>");

                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr></tr>");
                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<th style='width:30%'>Relationship to Youth:</th>");
                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<th style='width:30%'>Phone #:</th>");
                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<th style='width:30%'>Email:</th>");
                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<th></th>");


                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr></tr>");
                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td style='width:30%'>"+value.Relationship+"</td>");
                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td style='width:30%'>"+value.Phone+"</td>");
                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td style='width:30%'>"+value.Email+"</td>");
                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td></td>");

                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr></tr>");
                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td></td>");
                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td>"+(value.preferredContact=='Phone'?"<div style='height:30px;padding:10px' class='badge badge-success align-middle'>Preferred Contact Method</div>":'')+"</td>");
                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td>"+(value.preferredContact=='Email'?"<div style='height:30px;padding:10px' class='badge badge-success align-middle'>Preferred Contact Method</div>":'')+"</td>");
                               $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td></td>");



                             
                             });
     
     
                       });
                       
                   }
               
               }
             }

      
           
         });

          if(question.value){

            var responses=JSON.parse(question.value);


            var params=new Object;
                       
            if(live){
               params.ClientID=vm.$route.params.clientID;
            }else if(question.TestClientNumber){
              params.ClientNumber=question.TestClientNumber;


            }


            clientService.getFamilyList(responses,{inQuestion:true,params:params})
            .then(response =>{

                  $.each(response,function(key,value){
                    $(el).find('.responseValue').append("<div id='familyDetails_"+value.ID+"' class='familyDetails' style='width:80%;display:inline-block;padding-top:20px;padding-bottom:20px'></div>");

                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).append("<table style='width:90%;border:0'></table>");
                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr><th colspan='3'>Name:</th><th rowspan='2'><div style='margin-top:10px;background-color:#dc3545;color:#ffffff' data-familyid='"+value.ID+"' class='familyBttnRemove btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Remove</div></th></tr>");
                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr><td style='padding-bottom:10px' colspan='3'>"+value.Name+"</td><td></td></tr>");

                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr></tr>");
                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<th>Relationship to Youth:</th>");
                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<th>Phone #:</th>");
                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<th>Email:</th>");
                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<th></th>");


                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr></tr>");
                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td>"+value.Relationship+"</td>");
                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td>"+value.Phone+"</td>");
                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td>"+value.Email+"</td>");
                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td></td>");

                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr></tr>");
                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td></td>");
                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td>"+(value.preferredContact=='Phone'?"<div style='height:30px;padding:10px' class='badge badge-success align-middle'>Preferred Contact Method</div>":'')+"</td>");
                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td>"+(value.preferredContact=='Email'?"<div style='height:30px;padding:10px' class='badge badge-success align-middle'>Preferred Contact Method</div>":'')+"</td>");
                    $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td></td>");



                  
                  });


            });
          }

          $(el).on('click','.familyBttnRemove',function(){
            var indexToRemove=null;

            var familyID=$(this).data('familyid');
            
            var responses=JSON.parse(question.value);

            $.each(responses,function(key,value){
                  
                  if(parseInt(value)==parseInt(familyID)){


                    indexToRemove=key;
                    return;
                  }


            });

            if(indexToRemove !==null){
              responses.splice(indexToRemove,1);



              if(responses.length>0){
                var params=new Object;
                       
                  if(live){
                    params.ClientID=vm.$route.params.clientID;
                  }else if(question.TestClientNumber){
                    params.ClientNumber=question.TestClientNumber;


                  }

                  $(el).find('.responseValue').empty();

                  clientService.getFamilyList(responses,{inQuestion:true,params:params})
                  .then(response =>{

                        $.each(response,function(key,value){
                          $(el).find('.responseValue').append("<div id='familyDetails_"+value.ID+"' class='familyDetails' style='width:80%;display:inline-block;padding-top:20px;padding-bottom:20px'></div>");

                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).append("<table style='width:70%;border:0;font-size:10pt'></table>");
                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr><th colspan='3'>Name:</th><th rowspan='2'><div style='margin-top:10px;background-color:#dc3545;color:#ffffff' data-familyid='"+value.ID+"' class='familyBttnRemove btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Remove</div></th></tr>");
                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr><td style='padding-bottom:10px' colspan='3'>"+value.Name+"</td><td></td></tr>");

                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr></tr>");
                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<th>Relationship to Youth:</th>");
                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<th>Phone #:</th>");
                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<th>Email:</th>");
                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<th></th>");


                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr></tr>");
                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td>"+value.Relationship+"</td>");
                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td>"+value.Phone+"</td>");
                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td>"+value.Email+"</td>");
                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td></td>");

                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').append("<tr></tr>");
                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td></td>");
                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td>"+(value.preferredContact=='Phone'?"<div style='height:30px;padding:10px' class='badge badge-success align-middle'>Preferred Contact Method</div>":'')+"</td>");
                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td>"+(value.preferredContact=='Email'?"<div style='height:30px;padding:10px' class='badge badge-success align-middle'>Preferred Contact Method</div>":'')+"</td>");
                          $(el).find('.responseValue').find('#familyDetails_'+value.ID).find('table').find("tr").last().append("<td></td>");



                        
                        });


                  });
                }else{
                  $(el).find('.responseValue').empty();

                }

                if(responses.length>0){
                  question.value=JSON.stringify(responses);
                }else{
                  question.value=null;
                  
                }
              

            }
        });
          
          
          question.registerFunctionOnPropertyValueChanged("ButtonText",
          function () {

            $(el).find('.familyBttn').html(question.ButtonText.replace("<p>","").replace("</p>",""));
        
          });


        

          
           
        }
      };
    //Register our widget in singleton custom widget collection

    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('family-list')){
        Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget, "customtype");
    }
    
}