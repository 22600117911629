export default {
  data: function() {
    return {
      progressBarVisibility: null,
      progressBarCompleted: null,
      progressBarModalHeader: null,
    };
  },
  methods: {
    showProgressBar: function(value, msg = null) {
      this.progressBarVisibility = value;
      this.progressBarModalHeader = msg;
    },
    updateProgressBar: function(value) {
      this.progressBarCompleted = value;
    },
  },
};
