import { API_URL } from '@/config';
import * as staffService from '@/services/staff';

export function init(Survey,vm,live=false) {
    var testEditWidget2={
        name:'staff-list',
        title:'Staff List With Contact',
        iconName:'',
        responseData:{},    
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'staff-list';
        },
        init(){

          
          Survey.Serializer.addClass('staff-list',[],null,'empty');

       
        },
        htmlTemplate:"<div style='padding-left:20px' class='staff-list-container'><div class='responseValue'></div><div class='requestorBttn btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'></div></div>",
        afterRender:function(question,el){

         
          $(el).css('padding-top',"10px");
     
          if(vm.readOnly){
            $(el).find('.requestorBttn').hide();
            
          }

          if(!live && $(el).closest('#surveyContainer').length>0){
            live=true;
          }

       


          if(question.ButtonText){
            setTimeout(function(){
              $(el).find('.requestorBttn').html(question.ButtonText.replace("<p>","").replace("</p>",""));
            },1000);

          }
          
          $(el).find('.requestorBttn').click(function(){
            vm.$root.$emit('openRequestor',{questionCode:question.code,defaultRole:question.NewUserRole,staffTagCat:question.StaffTagCategory,tagUser:question.StaffTag,filterUser:question.FilterStaffTag,currentUsersSelected:[question.value],locationAuditField:question.UserAuditField});
          });
       
          vm.$root.$on('updateResponse',(data)=>{

            if(data.questionCode==question.code){
             
              if(data.unlinking){
                  question.value=null;
                  
                  $(el).find('.responseValue').empty();


              }else{
                if(question.StaffTag){
                  question.value={'value':data.value,'tag':question.StaffTag};
  
                }else{
                  question.value=data.value;
  
                }
                
              
           


              staffService.getRow(data.value,{inQuestion:true})
              .then(response =>{

                question.value={...question.value,responseData:response};

                $(el).find('.responseValue').empty();
                  $(el).find('.responseValue').append("<div><span style='font-weight:bold;color:#000000'>"+response.FirstName+" "+response.LastName+"</span></div>");
                  $(el).find('.responseValue').append("<div><span style='color:#000000'>"+response.Email+"</span></div>");
                  $(el).find('.responseValue').append("<div><span style='color:#000000'>Phone #:"+response.Phone+"</span></div>");

                  $(el).find('.responseValue').append("<div><span style='color:#000000'>"+response.LocationName+"</span></div>");
                  $(el).find('.responseValue').append("<div><span style='color:#000000'>"+response.Regions+"</span></div>");

                  testEditWidget2.responseData[question.code]=response;


                  $(el).find('.requestorBttn').css('margin-top','10px');
                  if(question.ChangeButtonText){
                    $(el).find('.requestorBttn').html(question.ChangeButtonText.replace("<p>","").replace("</p>",""));
    
                  }else{
                    $(el).find('.requestorBttn').html('Change Requestor');
    
                  }
                }

              );
             }
            }
            
          });

          if(question.value || (vm.params && vm.params.CallLogUserID && vm.params.TargetCode==question.code)){



            var lookupVal=null;

            if(question.value){
                  if(question.value.value){
                      lookupVal=question.value.value;
                  }else{
                    lookupVal=question.value;

                  }
            }else if(vm.params && vm.params.CallLogUserID ){
              lookupVal=vm.params.CallLogUserID;

            }
              staffService.getRow(lookupVal,{inQuestion:true})
                .then(response =>{


                  if(question.value){
                    question.value={...question.value,responseData:response};

                    
                  }else{
                    question.value={ 'value':lookupVal,'tag':question.StaffTag,responseData:response};

                   


                  }


                  $(el).find('.responseValue').empty();
                  $(el).find('.responseValue').append("<div><span style='font-weight:bold;color:#000000'>"+response.FirstName+" "+response.LastName+"</span></div>");
                  $(el).find('.responseValue').append("<div><span style='color:#000000'>"+response.Email+"</span></div>");
                  $(el).find('.responseValue').append("<div><span style='color:#000000'>Phone #:"+response.Phone+"</span></div>");

                  $(el).find('.responseValue').append("<div><span style='color:#000000'>"+response.LocationName+"</span></div>");
                  $(el).find('.responseValue').append("<div><span style='color:#000000'>"+response.Regions+"</span></div>");

                  $(el).find('.requestorBttn').css('margin-top','10px');


                  setTimeout(function(){

                    if(question.ChangeButtonText){
                      $(el).find('.requestorBttn').html(question.ChangeButtonText.replace("<p>","").replace("</p>",""));
      
                    }else{
                      $(el).find('.requestorBttn').html('Change Requestor');
      
                    }
                  },1000);
                  
                  testEditWidget2.responseData[question.code]=response;

                });


                if(question.StaffTag && (!question.value || !question.value.tag)){
                  
                    question.value={'value':lookupVal,'tag':question.StaffTag,responseData:{}};

                }
            
            
          }else{
           
              if(question.ButtonText){
                setTimeout(function(){
                  $(el).find('.requestorBttn').html(question.ButtonText.replace("<p>","").replace("</p>",""));
                  
                },1000);

              }
          }
          
          
          question.registerFunctionOnPropertyValueChanged("ButtonText",
          function () {

            

            $(el).find('.requestorBttn').html(question.ButtonText.replace("<p>","").replace("</p>",""));
        
          });


        

          
           
        },
        pdfQuestionType: "empty",
        pdfRender: function (survey, options) {
          if (options.question.getType() === "staff-list") {
            const loc = new Survey.LocalizableString(survey, true);




            if(options.question.value && options.question.value.responseData){
             
            let response=options.question.value.responseData;

            let displayText="<div><span style='font-weight:bold;color:#000000'>"+response.FirstName+" "+response.LastName+"</span></div>";
            displayText+="<div><span style='color:#000000'>"+response.Email+"</span></div>";
            displayText+="<div><span style='color:#000000'>Phone #:"+response.Phone+"</span></div>";

            displayText+="<div><span style='color:#000000'>"+response.LocationName+"</span></div>";
            displayText+="<div><span style='color:#000000'>"+response.Regions+"</span></div>";

             
              loc.text = displayText;



            }else{
              loc.text ="";
            }
            options.question["locHtml"] = loc;

            if (
              options.question.renderAs === "standard" ||
              options.question.renderAs === "image"
            ) {
              options.question["renderAs"] = options.question.renderAs;
            } else options.question["renderAs"] = "auto";
            const flatHtml = options.repository.create(
              survey,
              options.question,
              options.controller,
              "html"
            );
            return new Promise(function (resolve) {
              flatHtml.generateFlats(options.point).then(function (htmlBricks) {
                options.bricks = htmlBricks;
                resolve();
              });
            });
          }
        }
      };
    //Register our widget in singleton custom widget collection

    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('staff-list')){

        Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget2, "customtype");
    }
    
}