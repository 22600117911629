<template>
  <b-modal id="addDomain-dialog" hide-footer visible no-close-on-backdrop>
    <template v-slot:modal-header>
      <div class="d-flex w-100">
        <h3 class="font-weight-bold mr-auto">{{ $t("AddDomainDialog.add") }}</h3>
        <b-btn class="ml-2" variant="success" @click="saveDomain">
          {{ $t('general.save') }}
        </b-btn>
        <b-btn
          class="ml-2"
          variant="danger"
          @click="$emit('onCloseAddDomain')"
          >{{ $t('general.cancel') }}</b-btn
        >
      </div>
    </template>
    <div  class="container-fluid bg-white">
      <b-row>
        <b-col>
          <b-form-group>
            <DomainLabelTooltip
              iconID="rowPosition_tt"
              :label="$t('general.name')"
              labelID="rowPosition_label"
              :showTooltip="false"
            />
            <DomainInputFactory
              id="name"
              type="3"
              v-model="row.name"
            />
          </b-form-group>
           <b-form-group>
            <DomainLabelTooltip
              iconID="rowPosition_tt"
              :label="$t('general.description')"
              labelID="rowPosition_label"
              :showTooltip="false"
            />
            <DomainInputFactory
              id="description"
              type="4"
              v-model="row.description"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import LabelTooltip from '@/components/LabelTooltip';
import * as formService from '@/services/forms';

export default {
  name: 'AddDomainDialog',
  components: {
    DomainInputFactory:()=>import('@/components/InputFactory.vue'),
    DomainLabelTooltip:()=>import('@/components/LabelTooltip.vue'),
  },
  data() {
    return {};
  },
  props: {
    formError: { type: String, default: '' },
    row: { type: Object, default:function(){ return {name:null,description:null}}},
    questionObject:{type: Object, default:null}
  },
  created() {},
  methods: {
    saveDomain(){
      formService.addDomain(this.row)
      .then( response =>{
        this.questionObject.domain=response.new_id;
          this.$emit('onClickAddDomain',{newDomain:response.new_id});
      });
    }
  },
};
</script>
