// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiNotification/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiNotification/getRows', payload).then(getData);

export const remove = (row) => http.post('apiNotification/delete', row).then(getData);

export const checkNotifications = () => http.get('apiNotification/checkNotifications').then(getData);