// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) =>
  http.post('apiClientReferral/getTableSettings&myReferrals=1', payload).then(getData);

export const get = (payload = null) =>
  http.post('apiClientReferral/getReferrals', payload).then(getData);

export const getDeclinedReferrals = (clientID,params) =>
  http.post('apiClientReferral/getDeclinedReferralHistory',{ClientID:clientID,...params}).then(getData);


export const getReferralPrintView = (clientID) =>
  http.post('apiClientReferral/getReferralReportData',{ClientID:clientID}).then(getData);