<template>
  <span v-show="showFilter"  >
    <EcenterTableFilterSelect
      @filterChange="filterChanged"
      ref="filter"
      v-if="filter.type == 'select'"
      v-bind:filter="filter"
      v-bind:filterOptions="filterOptions"
      v-bind:defaultValue="defaultValue"

    ></EcenterTableFilterSelect>
    <EcenterTableFilterCheckboxGroup
      @filterChange="filterChanged"
      ref="filter"
      v-else-if="filter.type == 'checkboxGroup'"
      v-bind:filter="filter"
      v-bind:filterOptions="filterOptions"
    ></EcenterTableFilterCheckboxGroup>
    <EcenterTableFilterDropdown
      @filterChange="filterChanged"
      ref="filter"
      v-else-if="filter.type == 'dropdown'"
      v-bind:filter="filter"
      v-bind:filterOptions="filterOptions"
    ></EcenterTableFilterDropdown>
    <EcenterTableFilterTreeselect
      @filterChange="filterChanged"
      ref="filter"
      v-else-if="filter.type == 'treeselect'"
      v-bind:filter="filter"
      v-bind:filterOptions="filterOptions"
    ></EcenterTableFilterTreeselect>
    <EcenterTableFilterText
      @filterChange="filterChanged"
      ref="filter"
      v-else-if="filter.type == 'text'"
      v-bind:filter="filter"
    >
    </EcenterTableFilterText>
      <EcenterTableFilterDateRange
      @filterChange="filterChanged"
      ref="filter"
      v-else-if="filter.type == 'dateRange'"
      v-bind:filter="filter"
    >
    </EcenterTableFilterDateRange>
  </span>
</template>

<script>
import EcenterTableFilterSelect from './ecenter-table-filter-select.vue';
import EcenterTableFilterCheckboxGroup from './ecenter-table-filter-checkbox-group.vue';
import EcenterTableFilterDropdown from './ecenter-table-filter-dropdown.vue';
import EcenterTableFilterTreeselect from './ecenter-table-filter-treeselect.vue';
import EcenterTableFilterText from './ecenter-table-filter-text.vue'
import EcenterTableFilterDateRange from './ecenter-table-filter-date-range.vue';
export default {
  name: 'EcenterTableFilter',
  props: {
    filter: Object,
    filterOptions: Array,
    defaultValue: [String, Array]
  },
  data() {
    return {
      updateFilter:0
    };
  },
  mounted() {
     
    


  },
  computed:{
    showFilter(){
      if(this.filter.hidden){
        return false;
      }else{
        return true;
      }
    }
  },

  methods: {
  
    clearFilter() {
      return this.$refs.filter.clearFilter();
    },
    filterChanged() {
      this.$emit('applyFilter',{filterName:this.filter.name});
    },
    getSelectedFilters() {
      return this.$refs.filter.getSelectedFilters();
    },
  },
  components: {
    EcenterTableFilterSelect,
    EcenterTableFilterCheckboxGroup,
    EcenterTableFilterDropdown,
    EcenterTableFilterTreeselect,
    EcenterTableFilterText,
    EcenterTableFilterDateRange
  },
};
</script>
