<template>
  <div v-if="Array.isArray(value)">
    <b-badge
      v-for="(item, i) in value"
      :key="`lv_badge_${i}`"
      pill
      style="white-space: normal"
      variant="primary"
      :class="[
        'tag',
        tagClass,
        'animation__fadeIn--bottomLeft',
        'animation__duration--fast',
      ]"
    >
      <div class="d-flex align-items-center" >
        <span
        
          v-for="(text, j) in item.text"
          :key="`lv_badge_${i}_${j}`"
          class="tag__text"
          :class="tagTextClass"
          style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
        >
          {{ text }}
        </span>
        <div
          v-if="isEditable"
          @click="$emit('removeListViewBadgeItem', item)"
          aria-keyshortcuts="Delete"
          aria-label="Remove tag"
          class="button-remove-tag"
        >
          ×
        </div>
      </div>
    </b-badge>
  </div>
</template>
<script>
export default {
  name: 'ListViewPickerBadge',
  props: {
    value: { type: Array|String, default: null },
    tagClass: { type: String, default: null },
    tagTextClass: { type: String, default: null },
    isEditable: { type: Boolean, default: true },
  },
  methods: {},
  mounted(){
  }
};
</script>
