<template>
  <div class="container">
    <!-- Outer Row -->
      <b-alert variant="danger" :show="updateAlert>0">
        {{formError}}
    </b-alert>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ORG_ID } from '@/config';
import axios from 'axios';
axios.defaults.withCredentials = true;
export default {
  name: 'Login',
  props: ['alertText'],
  data () {
    return {
      form: {},
      formError: null,
      updateAlert:0
    };
  },
  mounted(){

      this.recursiveLogOut();
     
  },
  methods: {
    ...mapActions(['clientCollectionLogin','logout']),
     recursiveLogOut(){
      this.logout().then(response => {
       if(response){
         var vm=this;

          //recursively check to see if actually logged out due to quirk of Yii
          this.$store.dispatch('checkUserToken',true)
          .then(response =>{
              if(response !== -1){
                  this.recursiveLogOut();
              }else{
                this.clientCollectionLogin(this.$route.params)
                        .then((response) => {

                          if(response.success){
                              if(response.presentLandingPage){

                                  this.$router.push({...response.redirectPath});
                              }else{
                                this.$router.push({ path: response.redirectPath });

                              }

                          }else{
                            this.formError=response.error;
                            this.updateAlert+=1;

                              
                          }
                        }).catch((error)=>{
                          this.formError=error.error;
                                      this.updateAlert=new Date().getTime();

                        });
                  
              }
          });
      
       }

      });
    },
    submit () {
      
    }
  }
};
</script>
