<template>
  <b-modal id="addDomain-dialog" hide-footer visible no-close-on-backdrop>
    <template v-slot:modal-header>
      <div class="d-flex w-100">
        <h3 class="font-weight-bold mr-auto">
          {{ $t("general.new") }} {{ assessmentType ? assessmentType : 'Collection' }}
        </h3>
        <b-btn 
          class="ml-2"
          variant="success" 
          @click="saveAssessmentName"
        >
        {{ $t("general.save") }}
        </b-btn>
        <b-btn
          class="ml-2"
          variant="danger"
          @click="$emit('onCloseAddAssessmentTitle')"
        >
        {{ $t("general.cancel") }}
        </b-btn>
      </div>
    </template>
    <div class="container-fluid bg-white">
      <!-- Form Error -->
      <b-row v-show="formError">
        <div
          id="formErrorMessage"
          class="col text-center text-danger"
          v-html="formError"
        ></div>
      </b-row>
      <!-- Assessment Name -->
      <b-row>
        <b-col>
          <b-form-group>
            <LabelTooltip
              iconID="rowPosition_tt"
              :label="$t('general.name')"
              labelID="rowPosition_label"
              :showTooltip="false"
            />
            <InputFactory 
              id="name" 
              type="3" 
              v-model="assessmentData['Title']" 
              :required="'1'" 
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Assessment Type -->
      <b-row v-if="!assessmentType">
        <b-col>
          <b-form-group>
            <LabelTooltip
              iconID="rowPosition_tt"
              :label="$t('AddNewFormDialog.type')"
              labelID="rowPosition_label"
              :showTooltip="false"
            />
            <InputFactory
              id="type"
              type="dropdown"
              v-model="assessmentData['Type']"
              :options="assessmentTypeOptions"
              :required="'1'"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Categories & Tags -->
      <FormCard
        headerClass="global-primary__bg global-primary__bg--no-hover"
        v-for="(cat, i) in customFieldCategories"
        :key="`${cat.Name}_${i}`"
        v-show="!cat.Hidden"
      >
        <template v-slot:header>{{ cat.Name }}</template>
        <template v-slot:body>
          <b-row>
            <b-col
              sm="12"
              md="6"
              :xl="getXl(field.Type, field.BCol)"
              v-for="(field, j) in cat.customFields"
              :key="`${cat.Name}_${field.Name}${j}`"
              v-show="!field.Hidden"
              :style="field.Visibility ? { visibility: 'hidden' } : {}"
            >
              <b-form-group>
                <LabelTooltip
                  v-show="
                    field.Type != 'PermissionsCard' &&
                      field.Type != 'LinkedEventsCard' &&
                      field.Type != 'LinkedFormsCard' &&
                      field.Type != 'LinkedFamilyCard' &&
                      field.Type != 'LinkedSiblingsCard' &&
                      field.Type != 'OccupancyGroupCard'
                  "
                  :iconID="`${field.Name}_tt`"
                  :label="field.Text"
                  :labelID="`${field.Name}_label`"
                  :required="field.Required"
                  :showTooltip="field.HelpText !== ''"
                  :tooltipText="field.HelpText"
                />
                <InputFactory
                  :allowClearButton="field.AddClearDateButton"
                  :children="field.Children"
                  :cssClasses="field.CssClasses"
                  :disabled="false"
                  :disabledDates="field.disabledDates || {}"
                  :fieldID="field.FieldID"
                  :id="field.Name"
                  :lvParams="field.ListParams"
                  :numericOnly="field.NumericOnly ? '1' : '0'"
                  :options="field.Options"
                  :placeholder="field.Placeholder || ''"
                  :readOnly="field.ReadOnly"
                  :required="field.Required ? '1' : '0'"
                  :serviceName="serviceName"
                  :showCheckHeader="false"
                  :showSelectAll="false"
                  :type="field.Type"
                  tagContainerClass="border-0 p-0"
                  v-model="assessmentData[field.Name]"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </template>
      </FormCard>
    </div>
  </b-modal>
</template>

<script>
import LabelTooltip from '@/components/LabelTooltip';
import FormCard from '@/components/FormCard';
import * as formService from '@/services/forms';
import * as customFieldService from '@/services/customField';

export default {
  name: 'AddNewFormDialog',
  components: {
    InputFactory: () => import('@/components/InputFactory.vue'),
    LabelTooltip,
    FormCard,
  },
  data() {
    return {
      assessmentData: [],
      assessmentTypeOptions: [],
      customFieldCategories: null,
      formError: null,
      serviceName: 'forms'
    };
  },
  props: {
    assessmentType: { type: String, default: '' },
  },
  created() {
    formService.getFormTypes().then(response => {
      this.assessmentTypeOptions = response.types;
    });

    customFieldService
      .get('', 'Collection Information', '', 'Add')
      .then(response => {
        this.customFieldCategories = response;
      });
  },
  methods: {
    saveAssessmentName() {
      if (!this.assessmentData['Title']) {
        this.formError = 'Name is required';
      } else if (!this.assessmentType && !this.assessmentData['Type']) {
        this.formError = 'Collection type is required';
      } else {
        this.$emit('onClickSaveAssessmentName', {
          assessmentTitle: this.assessmentData['Title'],
          assessmentType: this.assessmentData['Type'],
          assessmentCategory: this.assessmentData['Category'] ? this.assessmentData['Category'] : null,
          assessmentTags: this.assessmentData['Tags'] ? this.assessmentData['Tags'] : null
        });
      }
    },
    getXl(type, bcol = null) {
      if (bcol) return bcol;
      if (type === 'MultiSelectStartEndDate' || type === 'DropdownStartEndDate')
        return '6';
      if (type === '4' || type === 'Editor') return '12';
      return '4';
    },
  },
};
</script>
