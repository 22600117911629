import * as config from '@/config';
import {API_URL} from '@/config';
import { userTokenStorageKey } from '@/config';
import * as notificationService from '@/services/notification';
import * as upcomingEventService from '@/services/upcomingEvents'
import { isObject, sanitizeHtmlText } from '@/utils/langUtils';

export default {
  computed: {
    $config () {
      return config;
    },
    isLogged () {
      return this.$store.getters.logged;
    },
    inTrainingMode(){
      return this.$store.getters.trainingMode;
    },
    criticalNotifications(){


      if(this.$route.name=='login'){
        return false;
      }else{
        return this.$store.getters.getCriticalNotificationCount>0 && (!this.$route.params.service || (this.$route.params.service && this.$route.params.service !='notification'))?true:false;

      }
    },
    isClient(){
      return this.$store.getters.getIsClient;
    },
    currentUser () {
      return this.$store.getters.currentUser;
    },
    jwtToken () {
      const storeToken = this.$store.state.auth.token;
      if (storeToken && (typeof storeToken === 'string' || storeToken instanceof String)&& storeToken !== '')
        return storeToken;
      return localStorage.getItem(userTokenStorageKey);
    }
  },
  methods: {
    clone (object) {
      return JSON.parse(JSON.stringify(object));
    },
    getMainToolbarTabs() {
      Promise.all([this.checkAccess("Organization Dashboard (VUE)"), this.checkAccess("My Dashboard (VUE)"), this.checkAccess("Clients Dashboard (VUE)")]).then(data => {
        let arr = [];
        if (data[0]) {
          arr.push({ path: '/organization', displayName: 'Organization'})
        }
        if (data[1]) {
          arr.push({ path: '/dashboard', displayName: 'My Dashboard' })
        }
        this.$store.commit('UPDATE_MAINTOOLBAR_LINKS', arr);
      })
    },
    // Removes reporting all reporting badges except for the exclude
    clearReportingBadges(exclude) {
      let searchParams = this.$store.state.searchParams;

      let keys = Object.keys(searchParams);
      if (exclude) {
        let tmp = [];
        for (let k of keys) {
          if (exclude[k] === undefined) tmp.push(k);
        }
        keys = tmp;
      }
      for (let key of keys) {
        if (Array.isArray(searchParams[key])) {
          for (let obj of searchParams[key]) {
            this.$store.commit('REMOVE_SEARCH_PARAM', {param: key, value: obj});
          }
        } else {
          this.$store.commit('REMOVE_SEARCH_PARAM', {param: key, value: searchParams[key]});
        }
      }

      let reportFilterParams = this.$store.state.reportFilter;
       keys = Object.keys(reportFilterParams);
      if (exclude) {
        let tmp = [];
        for (let k of keys) {
          if (exclude[k] === undefined) tmp.push(k);
        }
        keys = tmp;
      }
      for (let key of keys) {
        if (Array.isArray(reportFilterParams[key])) {
          for (let obj of reportFilterParams[key]) {
            this.$store.commit('REMOVE_REPORT_FILTER_PARAM', {param: key, value: obj});
          }
        } else {
          this.$store.commit('REMOVE_REPORT_FILTER_PARAM', {param: key, value: reportFilterParams[key]});
        }
      }
    },
    async checkAccess(AuthItem) {
      try {
        let returnResult = false;

        let res = await this.$http.get(API_URL + `apiGlobalFunctions/checkAccess&AuthItem=${AuthItem}`);
         if(res.status == 200){
             returnResult = res.data.access;
         }

         return returnResult;
     }
     catch (err) {
         console.error(err);
     }
    },
    getTranslatedText(string) {
      var response = globalMixinService.getTranslatedText;
      return response;
    },
    async checkNotifications() {
      try {
        const response = await notificationService.checkNotifications();
        this.$store.commit('UPDATE_HAS_NOTIFICATIONS', response.hasNotifications);

        if(response.criticalNotificationCount){
          this.$store.commit('SET_CRITICAL_NOTIFICATIONS_COUNT',response.criticalNotificationCount);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkUpcomingEvents(){
      try{
        const response = await upcomingEventService.getUpcomingEventsNum();

        this.$store.commit('UPDATE_HAS_UPCOMING_EVENTS', response);
      } catch (error) {
        console.log(error);

      }

    },
    goToHomePage() {
      if ((typeof vm.$refs.mainDashboardToolbar !== 'undefined') && (vm.$refs.mainDashboardToolbar.tabs.length > 0)) {
        vm.$router.push({path: vm.$refs.mainDashboardToolbar.tabs[0].path});
      }
      else {
        vm.$router.push({path: "/"});
      }
    },
    criticalNotificationLinkClicked(){
      this.$router.push({path:"/list/notification/Type/2"});

    },
    addGlobalToast: function(toast) {
      if (!isObject(toast, true) || toast.message === undefined) return;
      const root = this.$root;
      const h = this.$createElement;
      const msgEle = h('div', { domProps: { innerHTML: sanitizeHtmlText(toast.message) }});
      const props = {};
      if (toast.delay) props.autoHideDelay = toast.delay;
      props.id = 'globaltoast-' + Math.random().toString(16).slice(2);
      props.solid = props.solid || true;
      if (toast.title) props.title = toast.title;
      if (toast.toaster) props.toaster = toast.toaster;
      if (toast.variant) props.variant = toast.variant;
      root.$bvToast.toast([msgEle], props);
    }
  }
};
