<template>
  <b-modal id="rowPosition-dialog" hide-footer visible>
    <template v-slot:modal-header>
      <div class="d-flex w-100">
        <div class="text-danger font-weight-bold mr-auto">{{ formError }}</div>
        <b-btn class="ml-2" variant="success" @click="$emit('onClickEditRowPosition')">
          {{ $t('general.save') }}
        </b-btn>
        <b-btn
          class="ml-2"
          variant="danger"
          @click="$emit('onCloseRowPositionDialog')"
          >{{ $t('general.cancel') }}</b-btn
        >
      </div>
    </template>
    <div v-if="row && row.SortOrder !== undefined" class="container-fluid bg-white">
      <b-row>
        <b-col>
          <b-form-group>
            <LabelTooltip
              iconID="rowPosition_tt"
              :label="$t('RowPositionDialog.move')"
              labelID="rowPosition_label"
              :showTooltip="false"
            />
            <InputFactory
              id="rowPosition"
              type="Number"
              v-model="row.SortOrder"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import InputFactory from '@/components/InputFactory';
import LabelTooltip from '@/components/LabelTooltip';

export default {
  name: 'RowPositionDialog',
  components: {
    InputFactory,
    LabelTooltip,
  },
  data() {
    return {};
  },
  props: {
    formError: { type: String, default: '' },
    row: { type: Object, default: null }
  },
  created() {},
  methods: {},
};
</script>
