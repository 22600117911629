<template>
  <div>
   <b-row v-if="!allowAddEditInCard">
      <b-col md="10">

      </b-col>
       <b-col md="2">
          <b-button @click="launchManageDialog" variant="success"> {{ buttonText }} </b-button>

      </b-col>

   </b-row>
    <b-row>
     <b-col md="12" :key="updateCard">
      <div v-if="targetTags && targetTags.length>0">
        <b-row v-for="(tagID,tagIndex) in targetTags" :key="'targetTag_'+fieldID+'_'+tagIndex">
          <b-col md="12">
              <b-card >
                  <ListView
                    :serviceNameProp="GridServiceName"
                    :isChild="true"
                    :isGridField="true"
                    :hidePage="hidePage"
                    :customFieldSetNameProp="GridCustomFieldSetName"
                    @onRowSelected="handleRowSelected($event)"
                    @applySelectAll="handleSelectAll($event)"
                    @applyDeselectAll="handleDeselectAll"
                    @pickerValueAdded="pickerValueAddedFunc"
                    @pickerValueMultiSelect="pickerValueMultiAddedFunc"
                    :selectedUsers="value"
                    :showSelectAll="showSelectAll"
                    :passedParams="{...params,TaxonomyID:tagID,currentlyLinkedStaff:localValue}"
                    :ref="'listViewInstance_'+fieldID+'_'+tagID"
                  >
                    
                  </ListView>
              </b-card>
          </b-col>
        </b-row>

      </div>
      <div v-else>
         <ListView
                    :serviceNameProp="GridServiceName"
                    :isChild="true"
                    :isGridField="true"
                    :hidePage="hidePage"
                    :emptyMessage="emptyMessage"
                    :customFieldSetNameProp="GridCustomFieldSetName"
                    @onRowSelected="handleRowSelected($event)"
                    @applySelectAll="handleSelectAll($event)"
                    @applyDeselectAll="handleDeselectAll"
                    @pickerValueAdded="pickerValueAddedFunc"
                    @pickerValueMultiSelect="pickerValueMultiAddedFunc"
                    :selectedUsers="value"
                    :showSelectAll="showSelectAll"
                    :passedParams="{...params,selectedItems:localValue,showLinkedOnly:true}"
                    :ref="'listViewInstance_'+fieldID"
                  >
                    
                  </ListView>
      </div>
     </b-col>
    </b-row>



    <ecenterDialog
      :id="fieldID"
      v-if="showDialog"
      :cancelTitle="dialogCancelTitle"
      :centeredModal="dialogCentered"
      :noCloseOnBackdrop="dialogNoCloseOnBackdrop"
      :okTitle="dialogOkTitle"
      :showOkBtn="showDialogOkBtn"
      :showCancelBtn="showDialogCancelBtn"
      :size="'xl'"
      :contentClass="modalContentClass"
      :dialogClass="modalDialogClass"
      :bodyClass="modalBodyClass"
      :modalClass="modalModalClass"
      @onClickOk="onClickDialogOk"
      @onClickCancel="onClickDialogCancel"
    
    >
      <template v-slot:dialogContent>
  
        <ListView
          :key="updateDialog"
          :serviceNameProp="GridServiceName"
          :customFieldSetNameProp="GridCustomFieldSetName"
          :isChild="true"
           @onRowSelected="handleRowSelected($event)"
           @applySelectAll="handleSelectAll($event)"
           @applyDeselectAll="handleDeselectAll"
           @pickerValueAdded="pickerValueAddedFunc"
           @pickerValueMultiSelect="pickerValueMultiAddedFunc"
          :showSelectAll="showSelectAll"
          :passedParams="GridServiceName=='staff'?{...passedParams,currentlyLinkedStaff:localValue}:{...passedParams,currentlyLinkedItems:localValue,inPickerAdd:true}"
          :gridControls="gridControls"
          :ref="'listViewInstance_'+fieldID"
          @gridValueApplied="gridValueApplied($event)"
          @gridControlChangedInForm="gridValueApplied($event)"
        ></ListView>
      </template>
    </ecenterDialog>

  </div>
</template>
<script>
import lodash from 'lodash';
import { deepCopy, isObject } from '@/utils/langUtils';

export default {
  name: 'ListViewPicker',

  components: {
    ListView: () => import('@/app/list-view/index.vue'),
    ecenterDialog: () => import('@/components/ecenterDialog.vue')

  },
  
  props: {
    buttonText:{type: String, default: 'Manage'},
    dialogOkTitle: { type: String, default: 'Continue' },
    dialogCancelTitle: { type: String, default: null },
    dialogCentered: { type: Boolean, default: true },
    dialogNoCloseOnBackdrop: { type: Boolean, default: true },
    GridCustomFieldSetName: { type: String, default: null },
    GridServiceName: { type: String, default: null },
    hidePage:{type: Boolean, default:false },
    showDialogCancelBtn: { type: Boolean, default: false },
    showDialogOkBtn: { type: Boolean, default: true },
    showSelectAll:{ type:Boolean, default: false},
    value: [String, Array],
    rowData:{type:Object,default:function(){ return {} }},
    params:{type:Object, default:function(){ return {} }},
    disabled: { type: Boolean, default: false },
    fieldID:{ type: String, default:null },
    targetTags:{type: Array, default:function(){return []}},
    gridControls:{type: Array, default:function(){ return [] }},
    modalContentClass:{type: String, default: null},
    modalDialogClass:{type: String, default: null},
    modalBodyClass:{type: String, default: null},
    modalModalClass:{type: String, default: null},
    allowAddEditInCard:{type: Boolean, default:false},
    emptyMessage:{type: String, default:null}
  },

  data() {
    return {
      showDialog: false,
      passedParams:{},
      valuesToReturn:[],
      refreshBttn:0,
      updated:false,
      localValue:[],
      updateCard:null,
      updateDialog:null
    };
  },
  created(){
    this.passedParams={params:this.params};

      if(this.rowData){
        this.passedParams={...this.passedParams,rowData:this.rowData};


      }

     

        
      if(this.value && this.value.length>0){
          
          this.localValue=deepCopy(this.value);


      }


          this.updateCard=new Date().getTime();

  },
  mounted(){

      

      
      this.passedParams={params:this.params};

      if(this.rowData){

        if(this.params.hasOwnProperty("CallLogID") && this.params.CallLogID.length==0 && this.rowData.ID){
          this.params['CallLogID']=this.rowData.ID;
          

        }

        this.passedParams={...this.passedParams,rowData:this.rowData};

       
      }

        
      if(this.value && this.value.length>0){
          
          this.localValue=deepCopy(this.value);


      }

      

                this.updateCard=new Date().getTime();

    


  
  },
  computed:{
    openText(){

     if(this.allowMultipleValues && this.showCounter){
          if(this.valuesToReturn.length>0){
              return this.openPickerText + " ("+Object.keys(this.valuesToReturn).length+")";

          }else{

            if(this.value){
              return this.openPickerText + " ("+Object.keys(this.value).length+")";

            }else{
              return this.openPickerText + " (0)";
            }
          }
        }else{
          return this.openPickerText;
        }
    }
  },
  methods: {
    test(){
    },
    gridValueApplied(data){


      
      let returnValue=[];


      if(this.localValue && this.localValue.length>0){

          let userFound=false;
         for(let gridControlValue of this.localValue){
            if(gridControlValue.ID==data.ID){
             
             
              let returnObj={ID:data.ID};

              if(gridControlValue.isNew){
                     returnObj.isNew=true;
              }

              returnObj[data.controlName]=data[data.controlName];
              returnValue.push(returnObj);
              


              userFound=true;

            }else{
               returnValue.push(gridControlValue);
            }

         }



         if(!userFound){


              let returnObj={ID:data.ID};

              if(data.isNew){
                returnObj.isNew=true;
              }
              returnObj[data.controlName]=data[data.controlName];
              returnValue.push(returnObj);
          
         }



         //this.localValue=deepCopy(returnValue);

         this.$emit('input',returnValue)

      }else{


              let returnObj={ID:data.ID};

            if(data.isNew){
                returnObj.isNew=true;
              }
              returnObj[data.controlName]=data[data.controlName];
              returnValue.push(returnObj);
        returnValue.push(returnObj);

        //his.localValue=deepCopy(returnValue);

         this.$emit('input',returnValue)

      }



 
      this.localValue=deepCopy(returnValue);
      

      if(data.refreshGrid){
              this.valuesToReturn=deepCopy(this.localValue);

              this.updateDialog=new Date().getTime();
      }else{

              this.valuesToReturn=deepCopy(this.localValue);

              //this.updateCard=new Date().getTime();

              //this.$refs['listViewInstance_'+this.fieldID+].$refs['ecenterTableInstance'].updatePassedParams({...this.passedParams,currentlyLinkedStaff:this.localValue});


      }
      




      //this.updateCard=new Date().getTime();


    },
    createOpenText(){
       
     
    },
    onSelectAll(){
      
    },
    launchManageDialog(){
      this.showDialog=true;
    },
    onClickDialogOk() {
      this.showDialog = false;

    
       


         if(this.valuesToReturn.length==0 && this.value.length>0 && !this.updated){
            this.valuesToReturn=deepCopy(this.value);
            
         }
          this.refreshBttn=new Date().getTime();
         
          this.$emit('input', this.valuesToReturn);


          this.localValue=deepCopy(this.valuesToReturn);


          this.updateCard=new Date().getTime();



       

      
    },

    onClickDialogCancel() {
      this.showDialog = false;

     
    },

    onClickChoose() {
      

      this.showDialog = true;

      
    },

    pickerValueAddedFunc(eventData){


      const payload = { text: deepCopy(eventData.displayValue), ID: eventData.rowID };


      var newValue=[payload];
      this.$emit('input', newValue);

      this.showDialog = false;




    },
    pickerValueMultiAddedFunc(eventData){

       

       
      const payload = { text: deepCopy(eventData.displayValue), ID: eventData.rowID };


      var newValue=payload;

       if(this.valuesToReturn.length==0 && this.value.length>0 && !this.updated){
            this.valuesToReturn=deepCopy(this.value);
            
            this.valuesToReturn.push(newValue);
        }else{
              this.valuesToReturn.push(newValue);
        }
      
      this.refreshBttn=new Date().getTime();
      this.$emit('input',  this.valuesToReturn);
        

    },
    handleRowSelected(row) {


        
      
      
      if (!isObject(row) || row.ID === undefined) {
       


        return;
      }
      const payload = { text: deepCopy(row.displayName), ID: row.ID };
      let newValue = null;
      
       

        if (Array.isArray(this.value)) {
          if (row.rowSelected === true) {
            if (
              this.value.filter(val => lodash.isEqual(val.ID, payload.ID)).length == 0
            ) {
              newValue = [...this.value, payload];

            }
          } else if (!row.rowSelected) {
            newValue = this.value.filter(val => !lodash.isEqual(val.ID, payload.ID));

          }

          

         
        } else {
                 

          newValue = [payload];
        }



        if(row.rowSelected){

          if(newValue.length>0){
              if(this.valuesToReturn.filter(val=>lodash.isEqual(val.ID,payload.ID)).length==0){
                               this.valuesToReturn.push(payload);

              }

          }else{
               this.valuesToReturn.push(newValue[0]);

          }

        }else{
          this.valuesToReturn=this.valuesToReturn.filter(val => !lodash.isEqual(val.ID,payload.ID));


          if(Object.keys(this.valuesToReturn).length==0){
            this.valuesToReturn=[];
            this.updated=true;
          }


        }

         
       
       

  
     
    },
    compare(a,b){
      var aVal=a.text.Name?a.text.Name:a.text;
      var bVal=b.text.Name?b.text.Name:b.text;

      if(aVal>bVal){
        return 1;
      }else if(aVal<bVal){
        return -1;
      }else{
        return 0;
      }

    },
    handleDeselectAll(){
             this.$emit('input', []);

    },
    handleSelectAll(rows){

      
     var vm=this;

    var currentValue=deepCopy(this.value);

     let newValue=null;
      $.each(rows,function(index,row){
        if(row.rowSelected){
           if (
              currentValue.filter(val => lodash.isEqual(val.ID, row.ID)).length == 0
            ) {

              var newVal={text:deepCopy(row.displayName),ID:row.ID};
              currentValue = [...currentValue, newVal];



            }

        }else if (!row.rowSelected) {
            currentValue = currentValue.filter(val => !lodash.isEqual(val.ID, row.ID));
        }
      });



  
      currentValue.sort(this.compare);
  

     this.$emit('input', currentValue);

    }
  },
};
</script>

<style scoped>

</style>
