import auth from '@/router/middleware/auth';
const LocationLogo = r => require.ensure([], () => r(require('./LocationLogo')), 'location-logo-bundle');
export default [
  {
    path: '/locationLogo',
    name: 'locationLogo',
    component: LocationLogo,
    meta: {
      middleware: [auth]
    }
  }
];