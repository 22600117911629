// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiResultCollaboration/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiResultCollaboration/getRows', payload).then(getData);

export const add = (payload) => http.post('apiResultCollaboration/addCollaborator', payload).then(getData);

export const remove = (payload) => http.post('apiResultCollaboration/removeCollaborator', payload).then(getData);

export const create = (payload) => http.post('apiStaff/createUser', payload).then(getData);

export const collaborationLogin = (payload) => http.post('apiAuthentication/authenticateResultCollaborationLogin', payload).then(getData);
