<template>
  <div style="width: 260px !important;" class="bg-white">
    <div class="d-flex flex-column p-2">
      <div v-show="showMyDashboard" class="pb-3 pt-3">
        <b-btn class="btn-simple btn-to-link text-left text-dark" :to="{name: 'dashboard'}">
          <i class="fas fa-columns mr-2"></i>{{ $t("general.dashboard") }}</b-btn
        >
      </div>
      <div v-show="showOrganizationDashboard && $store.getters.getAcceptedEula && !$store.getters.getHideNav" class="pb-3 pt-3">
        <i class="fas fa-globe mr-2"></i
        ><b-btn class="btn-simple btn-to-link text-left text-dark" :to="{name: 'organization'}">{{ $t("general.organization") }}</b-btn>
      </div>
      <div v-show="showReporting && $store.getters.getAcceptedEula && !$store.getters.getHideNav" class="pb-3 pt-3">
        <b-icon icon="bar-chart-fill" class="mr-2"></b-icon
        ><b-btn class="btn-simple btn-to-link text-left text-dark" :to="{name: 'reporting'}">{{ $t("general.reporting") }}</b-btn>
      </div>
      <!-- <div class="pb-3 pt-3">
        <b-icon icon="gear" class="mr-2"></b-icon
        ><b-btn v-b-toggle.collapse-sidebar-settings class="btn-simple btn-to-link text-left text-dark">Settings</b-btn>
        <b-collapse id="collapse-sidebar-settings">
          <div class="mt-2 ml-2 p-2" style="border-left: 2px solid var(--global-primary-lighter)">
            <b-btn class="btn-simple btn-to-link text-left text-dark" to="/settings">Color Scheme</b-btn>
          </div>
        </b-collapse>
      </div> -->
      <div v-show="showDiscussionBoards && $store.getters.getAcceptedEula && !$store.getters.getHideNav" class="pb-3 pt-3">
        <b-icon icon="layout-text-sidebar-reverse" class="mr-2"></b-icon
        ><b-btn class="btn-simple btn-to-link text-left text-dark" :to="{name: 'discussion-board'}">{{ $t("sidebar.boards") }}</b-btn>
      </div>
  
    </div>
  </div>
</template>
<script>
export default {
  name: 'sidebar',
  data() {
    return {
      showMyDashboard: false,
      showOrganizationDashboard: false,
      showReporting: false,
      showDiscussionBoards: false,
      showFormService: false,
    };
  },
  created: function () {
    Promise.all([this.checkAccess("My Dashboard (VUE)"), this.checkAccess("Organization Dashboard (VUE)"), this.checkAccess("Reporting (VUE)"), this.checkAccess("Discussion Boards (VUE)")]).then(data => {
      this.showMyDashboard = data[0];
      this.showOrganizationDashboard = data[1];
      this.showReporting = data[2];
      this.showDiscussionBoards = data[3];
      this.showFormService = data[4];
    });
  }
};
</script>
