<template>
  <div v-if="data && isValidItem()">
    <b-row>
      <b-col v-if="data.Name">
        <h2>{{ data.Name }}</h2>
        <hr/>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" v-if="data.Email">
        <div><span class="font-weight-bold">{{ $t("ModalView.email") }}</span> {{ data.Email }}</div>
      </b-col>
      <b-col sm="12" v-if="data.Location">
        <div><span class="font-weight-bold">{{ $t("ModalView.community") }}</span> {{ data.Location }}</div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import * as profileService from '@/services/profile';

export default {
  name: 'NetworkingProfileView',
  data() {
    return {
      data: {},
    };
  },
  props: {
    serviceName: { type: String, default: '' },
    id: { type: String, default: null },
  },
  created() {
    this.getNetworkingProfile();
  },
  methods: {
    isValidItem() {
      const res = this.data && typeof this.data === 'object';
      return res;
    },
    async getNetworkingProfile() {
      try {
        const response = await profileService.getNetworkingProfile(this.id);
        this.data = response;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
