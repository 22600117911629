import guest from '@/router/middleware/guest';
import MobileLandingPage from './MobileLandingPage';
export default [
  {
    path: '/mobileLandingPage',
    name: 'mobileLandingPage',
    component: MobileLandingPage,
    meta: {
      middleware: [guest]
    }
  }
];