// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiCollectionSeries/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiCollectionSeries/getCollectionSeries', payload).then(getData);
export const getRow = (payload = null) => http.post('apiCollectionSeries/getSeries', payload).then(getData);


export const create = (row) => http.post('apiCollectionSeries/createSeries', row).then(getData);

export const update = (data) => http.post('apiCollectionSeries/editSeries', data).then(getData);

export const remove = (row) => http.post('apiCollectionSeries/deleteSeries', row).then(getData);

export const getFormConfig = (rowID,action) => http.post('apiCollectionSeries/getFormConfig',{RowID:rowID,Action:action}).then(getData);

export const getDeployments = (payload) => http.post('apiCollectionSeries/getDeployments',payload).then(getData);

export const createDeployment = (payload) => http.post("apiCollectionSeries/createDeployment",payload).then(getData);

export const getDeploymentConfig = (payload) => http.post('apiCollectionSeries/getDeploymentFormConfig',payload).then(getData);

export const getLinkedEvent = (payload) => http.post('apiCollectionSeries/getLinkedEvent',payload).then(getData);

export const unlinkFromEvents = (payload) => http.post('apiCollectionSeries/unlinkFromEvents',payload).then(getData);

export const updateDeployment = (payload) => http.post("apiCollectionSeries/editDeployment",payload).then(getData);

export const getMappingQuestions = (payload) => http.post("apiCollectionSeries/getMappingQuestions",payload,{silent:true}).then(getData);
export const getQuestionResponses = (payload) => http.post("apiCollectionSeries/getQuestionResponses",payload,{silent:true}).then(getData);

export const getReferralPriorityQuestions = (payload) => http.post("apiCollectionSeries/getReferralPriorityQuestions",payload).then(getData);