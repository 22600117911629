// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const update = (profile) => http.post('apiUser/updateUser', profile).then(getData);

export const getUserData = (payload=null) => http.post("apiUser/getUserData",payload).then(getData);

export const secuirtyQuestions = (payload=null) => http.get("apiUser/getSecurityQuestions",payload).then(getData);

export const setSecurityQuestions= (payload=null) => http.post("apiUser/setSecurityQuestions",payload).then(getData);

export const updatePassword = (payload=null) => http.post('apiUser/updateUserPassword',payload).then(getData);

export const checkPassword = (payload) => http.post('apiUser/checkPassword',payload).then(getData);

export const uploadAvatar = (file) => http.post('apiUser/uploadAvatar', file, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  }).then(getData);

  export const updateDisplayName = (payload) => http.post("apiUser/updateDisplayName",payload).then(getData);

  export const getUserProfile = () => http.get("apiUser/getUserProfile").then(getData);

  export const getNetworkingProfile = (ID) => http.get(`apiUser/getNetworkingProfile&ID=${ID}`, { silent: true}).then(getData);
  