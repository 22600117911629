<template>
  <div class="container">
    <!-- Outer Row -->
    <div class="row justify-content-center">
      <div class="col-xl-10 col-lg-12 col-md-9">
        <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
            <!-- Nested Row within Card Body -->
            <div class="row">
              <div class="col">
                <div class="p-5">
                  <b-form @submit.stop.prevent="submit">
                    <div class="text-center">
                      <h1 class="h4 text-gray-900 mb-4">{{ $t('auth.login-pin.confirm') }}</h1>
                    </div>

                    <div class="col text-center">
                      <p v-html="$t('auth.login-pin.instructions', {email:form.Email})"></p>
                    </div>

                    <input type="hidden" v-model="form.Email" name="Email">

                    <b-form-group id="TwoFactorAuthCode" :label="$t('auth.login-pin.pin')" label-for="TwoFactorAuthCode">
                      <b-form-input v-model="form.TwoFactorAuthCode" id="TwoFactorAuthCode" name="TwoFactorAuthCode" type="text" required :placeholder="$t('auth.login-pin.enterPin')"></b-form-input>
                    </b-form-group>

                    <div v-show="formError" class="col text-center text-danger">{{ formError }}</div>

                    <b-button variant="dark" class="btn-block" type="submit">{{ $t('auth.login-pin.login') }}</b-button>
                  </b-form>

                  <div class="row">&nbsp;</div>
                  <div class="col text-center">
                    <p>
                      {{ $t('auth.login-pin.expires') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPin',
  data () {
    return {
      form: { Email: this.$route.params.email },
      formError: ''
    };
  },
  methods: {
    submit () {
      /*
      this.$http.post(process.env.BASEURL + '?r=apiAuthentication/authenticateTemporaryPinUser', this.form).then(response => {
        if (response.data.success) {
          this.formError = '';
          this.$router.push({path:'/'});
        } else {
          this.formError = response.data.error;
        }
      }).catch(error => {
        this.formError = response.data.error;
      });
      */
    }
  }
};
</script>
