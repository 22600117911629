<template>
    <div >

      <b-card 
        no-body 
        class="mb-1" 
        >


        
        <b-card-body>
          
       
          <div v-show="criteria.length>0" >

            <div v-for="(criteriaVal,index) in criteria" :key="'criteria_'+index" class="border-bottom pt-1 pb-2">
                   <b-row>
                   <b-col cols="11"></b-col>
                  <b-col cols="1">
                    <b-button @click="removeMapping(index)" size="sm" variant="danger" >{{ $t("general.remove") }}</b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    {{ $t("ApprovalCriteriaCard.question") }}
                  </b-col>
                  <b-col>
                    {{ $t("ApprovalCriteriaCard.response") }}
                  </b-col>
                 
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-select
                        :key="questionsLoaded[index]"
                        :options="formQuestions[index]"
                        v-model="criteria[index].QuestionCode"
                        @change="questionCodeChanged(index)"

                    ></b-form-select>
                  </b-col>
                  <b-col>
                    <div v-if="showResponses(index)">

                        <ChildInputFactory  
                             :id="'responses_'+criteria[index].QuestionCode+'_'+index"
                            :type="'MultiSelect'"
                            :showCheckHeader="false"
                            :showSelectAll="false"
                            :showEmptySelect="false"
                            tagContainerClass="border-0 p-0"
                            :options="formResponses[index]"
                            :disabled="false"
                            v-model="criteria[index].QuestionResponses"
                            :required="'0'"
                            @input="answerSelected($event,index)"
                          />
                         

                    </div>
              
                  </b-col>
                  <b-col>
                     <b-form-checkbox
                            :id="'responses'+criteria[index].QuestionCode+'_CancelQuestion_'+index"
                            value="1"
                            unchecked-value="0"
                            v-model="criteria[index].CancelIfChanged"
                          >{{ $t("ApprovalCriteriaCard.cancel") }}</b-form-checkbox>
                  </b-col>
                  
                </b-row>
           
            </div>
            
          </div>
           <b-row class='border-top'>
                  <b-col cols="10"></b-col>
                  <b-col cols="2">
                    <div style="margin-top:10px" class='float-right'>
                      <b-button size="sm" variant="success" @click="addRow">{{ $t("ApprovalCriteriaCard.add") }}</b-button>
                    </div>
                  </b-col>
                </b-row>

         
         
        </b-card-body>
      </b-card>
    </div>
</template>
<script>
import * as collectionSeriesService from '@/services/cseries';
import * as locationService from '@/services/locationEHR';
import lodash from 'lodash';
import { deepCopy, isObject } from '@/utils/langUtils';

export default {
  name: 'ApprovalCriteriaCard',
  components:{ChildInputFactory:()=>import('@/components/InputFactory.vue')},
  data() {
    return {
      criteria:[],
      formQuestions:[],
      formResponses:[],
      refreshCriteria:null,
      questionsLoaded:[],
    
    };
  },
  computed:{
   
   
  },
  created(){

  },
  mounted(){



      this.criteria=this.value;

     
      this.rowData['Criteria']=this.criteria;

      
       




      if(!this.criteria || (this.criteria && this.criteria.length==0)){
        if(!this.criteria){
          this.criteria=new Array;
        }
        this.criteria[0]={QuestionCode:null,QuestionResponses:[],CancelIfChanged:0};
           if(this.rowData['Approval Collection']){
              collectionSeriesService.getMappingQuestions({AssessmentID:this.rowData['Approval Collection']})
              .then(response =>{


                this.formQuestions[0]=response;

                this.$forceUpdate();

                
              });

       

          
            }
      }else{
        let vm=this;
       if(this.rowData['Approval Collection']){


                collectionSeriesService.getMappingQuestions({AssessmentID:this.rowData['Approval Collection']})
              .then(response =>{


                    let vm=this;
                    let allQuestions=response;

                   $.each(vm.criteria,function(index,criteria){
                      vm.formQuestions[index]=allQuestions;
                        vm.$forceUpdate();

                         collectionSeriesService.getQuestionResponses({questionCode:criteria.QuestionCode,AssessmentID:vm.rowData['Approval Collection'],isCollectionSeries:true})
                         .then(response =>{


                                vm.formResponses[index]=response;

                              

                                 vm.$forceUpdate();



                         });

                   });



                
              });

            


             

             



      }
    }

      
   
      


  
  },
  methods: {

    removeMapping(index){
        this.criteria.splice(index,1);

              this.$forceUpdate();





    },
    addRow(){
      this.criteria.push({QuestionCode:null,QuestionResponses:[]});

      if(this.rowData['Approval Collection'])
          collectionSeriesService.getMappingQuestions({AssessmentID:this.rowData['Approval Collection']})
            .then(response =>{
              this.formQuestions[this.criteria.length-1]=response;

              this.$forceUpdate();

              
            });

        
    },
    rowLoaded(index){
      var date=new Date().getTime();

      return this.questionsLoaded[index]?'question_'+index+'_'+date:null;
    },
    showResponses(index){

        if(this.criteria[index].QuestionCode && this.formResponses[index] && Array.isArray(this.formResponses[index])){
          return true;
        }else{
          return false;
        }

    },
    updateKey(fieldID,index){
      var time=new Date().getTime();
      return fieldID+'_'+index+'_'+time;

    },
    questionCodeChanged(index){


        this.criteria[index].QuestionResponses=[];
        this.updateRowData();



        collectionSeriesService.getQuestionResponses({questionCode:this.criteria[index].QuestionCode,AssessmentID:this.rowData['Approval Collection'],isCollectionSeries:true})
        .then(response =>{
            this.formResponses[index]=response;




            this.$forceUpdate();


        });
    },
    answerSelected(data,index){
            this.updateRowData();

      this.$forceUpdate();

    },

    answerSelected(data,index){
      this.updateRowData();
      this.$forceUpdate();

    },
    updateRowData(){
            this.rowData['Criteria']=this.criteria;

    }


      
  },/*
  mounted() {
    permissionsService
    .get()
    .then(response => {
        this.permissions = response;
    })
    .catch(error => {
        this.permissions = {};
    });
  },
  */
  props: {
    rowData:{type:Object,default:function(){return {}}},
    value: {type: Array,default:function(){return []}},
    rowID:{type:String,default:null}


  }
}
</script>
<style>
  .form__card {
    margin-bottom: 2rem;
    background: transparent;
  }
</style>