// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

const CNTLR_NAME = "apiLocationEHR";

export const getTableSettings = (payload = null) => http.post(`${CNTLR_NAME}/getTableSettings&AsAnswerPicker=1`, payload).then(getData);

export const get = (payload = null) => http.post(`${CNTLR_NAME}/getLocations&InPicker=1`, payload).then(getData);

export const selectAll = (payload) =>http.post(`${CNTLR_NAME}/getLocations&selectAll=1`,payload).then(getData);
