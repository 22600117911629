<template>
  <component :is="htmlTag" v-html="linkified" :class="cssClass" />
</template>
<script>
import { isStringAndNotEmpty } from '@/utils/langUtils';
import * as linkifyHtml from 'linkify-html';
import sanitizeHtml from 'sanitize-html';
export default {
  name: 'LinkifyText',
  props: {
    cssClass: { type: String, default: null },
    htmlTag: { type: String, default: 'div' },
    options: {
      type: Object,
      default: function() {
        return {
          target: '_blank'
          };
      },
    },
    text: { type: String, default: '' },
  },
  computed: {
    linkified: function() {
      let result = '';
      if (isStringAndNotEmpty(this.text)) {
        const clean = sanitizeHtml(this.text);
        result = linkifyHtml(clean, this.options);
      }
      return result;
    }
  }
};
</script>