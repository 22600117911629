import auth from '@/router/middleware/auth';
import ModalView from '@/components/ModalView';

const Calendar = r =>
  require.ensure([], () => r(require('./index')), 'calendar-bundle');

export default [
  {
    path: '/calendar/:service',
    name: 'calendar',
    component: Calendar,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: ':componentName/:cservice/:rowID/',
        name: 'CalendarModalView',
        component: ModalView,
      },
      {
        path: ':componentName/:cservice/:rowID/:AssessmentType/:AssessmentID',
        name: 'CalendarLinkModalView',
        component: ModalView,
        
      },
    ],
  },
];
