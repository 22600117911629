// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';
import { copyAndMerge } from '@/utils/langUtils';
import { getPropsFromFiles } from '@/utils/s3ResourceUtils';

const CNTLR_NAME = 'apiModelRequestPub';

export const submit = payload =>
  http
    .post(
      `${CNTLR_NAME}/submit`,
      copyAndMerge(payload, getPropsFromFiles(payload))
    )
    .then(getData);
