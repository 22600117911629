// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

/**
 * Send login data and retrieve a new token
 *
 * @param payload
 * @returns {Promise<T>}
 */

 
export const getQlikReport = (payload=null) => http.post('apiQlikReporting/getQlikReport',payload).then(getData);

export const getReports = () =>  http.get('apiQlikReporting/getAvailReports').then(getData);

export const getSheets = (payload) =>  http.post('apiQlikReporting/getAvailSheets',payload).then(getData);

export const generateReport= (payload) => http.post("apiQlikReporting/test",payload).then(getData);