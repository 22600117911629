import auth from '@/router/middleware/auth';
import ModalView from '@/components/ModalView'

const ListView = r => require.ensure([], () => r(require('@/app/list-view/index')), 'list-view-bundle');

export default [
  {
    path: '/orgforms/:isorg/:service/:prefiltername/:prefilter',
    name: 'orgForms',
    component:ListView,
    meta: {
      middleware: [auth]
    },
    children:[
      {
        path: ':componentName/:cservice/:rowID/',
        name: 'OrgModalView',
        component: ModalView
      }
    ]
  },
  {
    path: '/orgforms/:isorg/:service',
    name: 'orgFormResults',
    component:ListView,
    meta: {
      middleware: [auth]
    },
    children:[
      {
        path: ':componentName/:cservice/:rowID/',
        name: 'OrgResultsModalView',
        component: ModalView
      }
    ]
  }
];