import auth from '@/router/middleware/auth';
const AcceptEula = r => require.ensure([], () => r(require('./acceptEula')), 'acceptEula-bundle');
export default [
  {
    path: '/acceptEula',
    name: 'acceptEula',
    component: AcceptEula,
    meta: {
      middleware: [auth]
    }
  },
];