import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';
import { prepareFormData } from '@/utils/s3ResourceUtils';

const CNTLR_NAME = 'apiLocationFileCcp';
const ASSESSMENT_CNTLR = 'apiAssessment';

export const getTableSettings = (payload = null) => http.post(`${CNTLR_NAME}/getTableSettings`, payload).then(getData);

export const get = (payload = null) => http.post(`${CNTLR_NAME}/getRows`, payload).then(getData);

export const create = row => http.post(`${CNTLR_NAME}/create`, prepareFormData(row)).then(getData);

export const update = row => http.post(`${CNTLR_NAME}/edit&ID=${row.ID}`, prepareFormData(row)).then(getData);

export const remove = row => http.post(`${CNTLR_NAME}/delete`, row).then(getData);

export const getRow = rowID => http.get(`${CNTLR_NAME}/getRow&ID=${rowID}`).then(getData);

export const getAttachedLocationFiles = payload => http.post(`${CNTLR_NAME}/getAttachedLocationFiles`, payload).then(getData);

export const selectRow = payload => http.post(`${ASSESSMENT_CNTLR}/attachItemToResultSet`, payload).then(getData);
