<template>
  <div >
     <div class="d-flex justify-content-center  mb-4"><b-spinner style="width: 3rem; height: 3rem;" variant="primary"></b-spinner></div>
    <div class="d-flex justify-content-center  mb-4"><h6 class="h3 mb-0 text-gray-800">{{ $t("general.pleaseWait") }}</h6></div>
  </div>
</template>

<script>
export default {
};
</script>
