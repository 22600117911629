// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData, getResData } from '@/utils/get';

export const getSharedReportsByType = (Type, GetBaseNamesOnly) =>
  http
    .get(
      `apiAssessment/GetSharedReportsByType&Type=${Type}&GetBaseNamesOnly=${GetBaseNamesOnly}`
    )
    .then(getData);

export const getMyCollections = () =>
  http
    .get(`apiAssessment/getUserCollections&TreatmentID=1&Type=Form&is360=0`)
    .then(getResData);

export const GetSharedReportsForLocation = GetBaseNamesOnly =>
  http
    .get(
      `apiAssessment/getSharedReportsForLocation&GetBaseNamesOnly=${GetBaseNamesOnly}`
    )
    .then(getData);

export const GetSurveyJSON = payload =>
  http.post('apiAssessment/getFormJSON', payload).then(getData);

export const GetAnalyticInfo = payload =>
  http.post('apiAssessment/getDeploymentAnalyticInfo', payload).then(getData);

  export const GetExport = payload =>
  http.post('apiAssessment/getDeploymentExport', payload).then(getData);

export const saveResults = (
  AssessmentID,
  CollectionType,
  model,
  modelID,
  resultSetID,
  treatmentID,
  results
) =>
  http
    .post(
      `apiAssessment/saveSurvey&CollectionType=${CollectionType}&CollectionID=${AssessmentID}&Model=${model}&ModelID=${modelID}&ResultSetID=${resultSetID}&TreatmentID=${treatmentID}&inSurveyJS=1`,
      results
    )
    .then(getData);
    export const checkForResultCollaborators = (
      AssessmentID,
      CollectionType,
      model,
      modelID,
      resultSetID,
      treatmentID,
      results
    ) =>
      http
        .post(
          `apiAssessment/checkForResultCollaborators&CollectionType=${CollectionType}&CollectionID=${AssessmentID}&Model=${model}&ModelID=${modelID}&ResultSetID=${resultSetID}&TreatmentID=${treatmentID}&inSurveyJS=1`,
          results
        )
       

export const checkIfReadOnly = payload =>
  http.post('apiAssessment/checkFormReadOnly', payload).then(getData);
