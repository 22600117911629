import { API_URL } from '@/config';
import * as clientService from '@/services/client';

export function init(Survey,vm,live =false) {
    var testEditWidget={
        name:'current-client-program',
        title:'Client\'s Current Programs/Services',
        iconName:'',
        responseData:{},
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'current-client-program';
        },
        init(){
          Survey.Serializer.addClass('current-client-program',[],null,'empty');

       
        },
        htmlTemplate:"<div style='padding-left:20px' class='current-client-program'><div class='responseValue'></div></div>",
        afterRender:function(question,el){

          $(el).css('padding-top',"10px");
     
          if(!live && $(el).closest('#surveyContainer').length>0){
            live=true;
          }

         if(live || vm.testModel){

                
              if(vm.testModel && question.TestClientNumber){
                var programIDs=new Array;
                  clientService.getClientPrograms(null,{ClientNumber:question.TestClientNumber,currentOnly:true})
                  .then(response =>{
                    testEditWidget.responseData[question.code]=response;

                    $.each(response,function(key,value){
                      $(el).find('.responseValue').append("<div class='programDetails' style='width:70%;display:inline-block'></div>");
                      $(el).find('.responseValue').append("<div class='programStart' style='display:inline-block'></div>");

                      $(el).find('.responseValue').find('.programDetails').append("<div><span style='font-weight:bold;color:#000000'>"+value.Name+"</span></div>");


                      $(el).find('.responseValue').find('.programStart').append("<div><span style='color:#000000'>"+value.StartDate+" to Present </span></div>");

                      programIDs.push(value.ID);

                    });

                  
                      question.value={value:programIDs,responseData:response};

                    
                  });

                }else if(vm.$route.params.clientID){
                  clientService.getClientPrograms(vm.$route.params.clientID,{currentOnly:true,programIDs:question.value})
                  .then(response =>{
                    testEditWidget.responseData[question.code]=response;

                    programIDs=new Array;
                    $.each(response,function(key,value){
                      $(el).find('.responseValue').append("<div class='programDetails' style='width:70%;'></div>");
                      $(el).find('.responseValue').append("<div class='programStart' style='padding-top:10px'></div>");

                      $(el).find('.responseValue').find('.programDetails').append("<div><span style='font-weight:bold;color:#000000'>"+value.Name+"</span></div>");
                
                      $(el).find('.responseValue').find('.programStart').append("<div><span style='color:#000000'>"+value.StartDate+" to Present</span></div>");

                      programIDs.push(value.ID);

                    });

                    question.value={value:programIDs,responseData:response};


                  });


                }
        }else{
          $(el).find('.responseValue').append("<div><span style='font-weight:bold;color:#000000'>TEST MODE</span></div>");
    
        }
          
          


        

          
           
        },
        pdfQuestionType: "empty",
        pdfRender: function (survey, options) {
          if (options.question.getType() === "current-client-program") {
            const loc = new Survey.LocalizableString(survey, true);




            if(options.question.value && options.question.value.responseData){
             
            let response=options.question.value.responseData;

            let displayText="";
            $.each(response,function(key,value){

              displayText+="<div class='programDetails' style='width:70%;'>";

              displayText+="<div><span style='font-weight:bold;color:#000000'>"+value.Name+"</span></div>";
        


              displayText+="</div>";

              displayText+="<div class='programStart' style='padding-top:10px'>";
              displayText+="<div><span style='color:#000000'>"+value.StartDate+" to Present</span></div>";

              displayText+="</div>";


            });
             
              loc.text = displayText;



            }else{
              loc.text ="";
            }
            options.question["locHtml"] = loc;

            if (
              options.question.renderAs === "standard" ||
              options.question.renderAs === "image"
            ) {
              options.question["renderAs"] = options.question.renderAs;
            } else options.question["renderAs"] = "auto";
            const flatHtml = options.repository.create(
              survey,
              options.question,
              options.controller,
              "html"
            );
            return new Promise(function (resolve) {
              flatHtml.generateFlats(options.point).then(function (htmlBricks) {
                options.bricks = htmlBricks;
                resolve();
              });
            });
          }
        }
      };
    //Register our widget in singleton custom widget collection

    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('current-client-program')){
        Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget, "customtype");
    }
    
}