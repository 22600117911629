<template>
  <div class="container">
    <!-- Outer Row -->
      <b-alert variant="danger" :show="updateAlert>0">
        {{formError}}
    </b-alert>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ORG_ID } from '@/config';
import axios from 'axios';
import * as locationService from '@/services/locationEHR';

axios.defaults.withCredentials = true;


export default {
  name: 'Login',
  props: ['alertText'],
  data () {
    return {
      form: {},
      formError: null,
      updateAlert:0
    };
  },
  mounted(){


      this.recursiveLogOut();
     
  },
  methods: {
    ...mapActions(['locationLogin','logout']),
     recursiveLogOut(){
      this.logout().then(response => {
       if(response){
         var vm=this;

          //recursively check to see if actually logged out due to quirk of Yii
          this.$store.dispatch('checkUserToken',true)
          .then(response =>{
              if(response !== -1){
                  this.recursiveLogOut();
              }else{
                  

                  this.$store.commit('SET_NO_HOMEPAGE',true);
                  
                
                this.locationLogin(this.$route.params)
                        .then((response) => {




                           if(response.success){
                              if(response.command=='confirmed'){
                                locationService.confirmedLocationSettings(this.$route.params)
                                .then((response)=>{
                                      if(response.success){
                                          this.$store.commit('SET_IS_ON_LOCATION_LOGOUT',true);

                                          this.$router.push({name:'locationLogout',params:{logoutMessage:response.message}});
                                      }else{
                                          this.formError=response.error;
                                          this.updateAlert=new Date().getTime();

                                      }
                                });
                              }else if(response.command=='update'){
                                    this.$router.push({name:'locationinfo',params:{locationID:this.$route.params.locationID}});

                              }else if(response.command=='optOut'){
                                    this.$router.push({name:'optOutForm',params:{LocationID:this.$route.params.locationID,email:this.$route.params.email}});

                              }
                           }else{
                               this.formError=response.error;
                              this.updateAlert=new Date().getTime();
                           }

                        }).catch((error)=>{
                          console.log(error);

                          this.formError=error.error;
                                      this.updateAlert=new Date().getTime();

                        });
                    
                  
              }
          });
      
       }

      });
    },
    submit () {
      
    }
  }
};
</script>
