import auth from '@/router/middleware/auth';

const Images = r => require.ensure([], () => r(require('./index')), 'images-bundle');

export default [
  {
    path: '/user-images',
    name: 'user-images',
    component: Images,
    meta: {
      middleware: [auth]
    }
  }
];
