<template>
  <div>
    <div v-if="component && component=='Calendar'">
          <Calendar v-bind="serverParams"></Calendar>

    </div>
    <div v-else-if="Object.keys(formFields).length==0 && isLoading()">
      {{ $t("general.loading") }}
    </div>
    <div v-else-if=" Object.keys(formFields).length==0 && isDoneLoading()">

      <div
        id="formErrorDiv"
        v-show="errorMessageComp"
        class="col text-center text-danger"
        v-html="errorMessageComp"
      ></div>
      <FormCard
        headerClass="global-primary__bg global-primary__bg--no-hover"
        v-for="(cat, i) in customFieldCategories"
        :key="`${cat.Name}_${i}`"
        v-show="showCategory(cat)"

      >
        <template v-slot:header>{{ cat.Name }}</template>
        <template v-slot:body>
      
          <b-row>
            <b-col
              sm="12"
              md="6"
              :xl="getXl(field.Type, field.Name, field.BCol,field.StartNewLine,field.FieldID)"
              v-for="(field, j) in cat.customFields"
              :key="`${cat.Name}_${field.Name}${j}`"
              v-show="showColumn(field)"
              :style="field.Visibility?{visibility:'hidden'}:{}"
            >
             
              <b-form-group :id="'form_group_'+field.FieldID">
                <LabelTooltip
                  v-show="field.Type != 'PermissionsCard' && field.Type!= 'LinkedEventsCard' && field.Type!='LinkedFormsCard' && field.Type!='LinkedFamilyCard' && field.Type!='LinkedSiblingsCard' && field.Type!='OccupancyGroupCard' && field.Type!='MultiRowField' && field.Type!='StaffManagementGrid' && field.Type!='ChildLocationsGrid' "
                  :iconID="`${field.Name}_tt`"
                  :label="field.Text"
                  :labelID="`${field.Name}_label`"
                  :required="field.Required"
                  :showTooltip="field.HelpText !== ''"
                  :tooltipText="field.HelpText"
                />
                <InputFactory
                  :key="fieldsKeys[field.Name]"
                  :rowID="rowData.ID?rowData.ID:''"
                  :fieldID="field.FieldID"
                  :childFields="field.childFields"
                  :children="field.Children"
                  :id="field.Name"
                  :type="field.Type"
                  :showCheckHeader="false"
                  :showSelectAll="false"
                  tagContainerClass="border-0 p-0"
                  :required="field.Required ? '1' : '0'"
                  :numericOnly="field.NumericOnly?'1' :'0'"
                  :options="field.Options"
                  :disabled="(formType === 'Edit' || field.Disabled)? field.DisableEdit || field.Disabled: false"
                  :isEditable="(formType === 'Edit' || field.Disabled)? !(field.DisableEdit || field.Disabled): true"
                  v-model="rowData[field.Name]"
                  :passedParams="serverParams"
                  @input="updateFormValues($event, field)"
                  :serviceName="serviceName"
                  @refresh-add-edit="refreshForm"
                  @update-row-data="updateRowValue"
                  @addDynamicRequired="updateRequiredFields"
                  :lvParams="field.ListParams"
                  :gridParams="field.GridParams"
                  :readOnly="field.ReadOnly"
                  :cssClasses="field.CssClasses"
                  :allowClearButton="field.AddClearDateButton"
                  :disabledDates="field.disabledDates || {}"
                  :placeholder="field.Placeholder || ''"
                  :rowData="rowData"
                  :buttonText="field.ButtonText"
                  
                 


                />
              </b-form-group>
            </b-col>
          </b-row>
        </template>
      </FormCard>
    </div>
    <div v-else-if="isDoneLoading() && formFields">
       <div
        id="formErrorDiv"
        v-show="errorMessageComp"
        class="col text-center text-danger"
        v-html="errorMessageComp"
      ></div>
      <FormFieldsCard
        :fields="formFields"
        :defaultTitle="defaultTitle"
        :hasCategories="formFieldsHasCategories"
        :rowID="rowData.ID?rowData.ID:''"
        :rowData="rowData"
        :formType="formType"
        :serviceName="serviceName"
        :serverParams="serverParams"
        @refresh-add-edit-form-fields="refreshForm"
        @childInputTriggered="updateFormValues"
        @inputTriggered="updateFormValues"

      >

      </FormFieldsCard>
       
    </div>
    
  </div>
</template>
<script>
import FormCard from '@/components/FormCard';
import InputFactory from '@/components/InputFactory';
import LabelTooltip from '@/components/LabelTooltip';
import FormFieldsCard from '@/components/FormFieldsCard.vue'
import lodash from 'lodash';
import Calendar from '@/app/calendar/index.vue'

export default {
  components: {
    FormCard,
    InputFactory,
    LabelTooltip,
    Calendar,
    FormFieldsCard
  },
  data() {
    return {
      deynamicFields:[],
      dynamicFieldIndex:0,
      updateDynamicCardTime:null,
      updateFormCardTime:null,
      refreshAllForm:0,
      fieldsKeys:{}



      
    };
  },
  props: {
    rowData: Object,
    customFieldCategories: Array,
    errorMessage: String,
    formType: String,
    component:{type:String,default:null},
    formFields:{type: Array,default:function(){return new Array}},
    formFieldsHasCategories:{type:Boolean,default:false},
    defaultTitle: {type:String, default:null},
    serverParams:{type:Object,default:function(){return {}}},
    componentParams:{type:Object,default:function(){return {}}},
    serviceName:{type:String,default:null}
  },
  created(){

  },

  computed:{
      errorMessageComp:function(){

        if(this.errorMessage){
            return this.errorMessage;
        }else if(this.rowData.initialErrorMessage){
            return this.rowData.initialErrorMessage

        }else{
          return null;
        }
      }
  },
  mounted(){


      var vm=this;

    
      var waitForFields=setInterval(() => {
        if((vm.customFieldCategories && vm.customFieldCategories.length>0) || (vm.formFields && vm.formFields.length>0)){
          clearInterval(waitForFields);
           $.each(vm.customFieldCategories,function(index,value){
            if(value.cardType=='DynamicRows'){
              vm.getDynamicFormRows(value.getRowsUrl,value.ID);

            }
          });
        }
        
      },100);



         
   
  },
  methods: {

    showColumn(field) {
      // if (this.formType === 'Edit' && field.Type === 'FilePicker') {
      //   return false;
      // }

      return field.Hidden && field.Hidden!=4? false : true;
    },
    isLoading() {

      return (
        this.customFieldCategories.length === 0 ||
        Object.keys(this.rowData).length === 0 
      );
    },
    isDoneLoading() {

      return (
       
        (this.customFieldCategories.length > 0 &&
        Object.keys(this.rowData).length > 0) || this.formFields
      );
    },
    getXl(type, fieldID, bcol=null,newLine=false,fieldIDValue=null) {
       if(newLine){


          let waitForField=setInterval(function(){
            if( $('#'+fieldID).closest('.form-group').parent().length>0){
              clearInterval(waitForField);
             $('#'+fieldID).closest('.form-group').parent().before("<div class='my-0 newLineSpacer' style='padding:0 !important;width:100% !important'></div>");

            }
          },100);

         
      }

      if (bcol)
        return bcol;
      if (type === 'MultiSelectStartEndDate' || type === 'DropdownStartEndDate')
        return '6';
      if (type === '4' || type === 'Editor') return '12';
      return '4';
    },
    checkDynamicNowVisible(){
       this.updateDynamicCardTime=new Date().getTime();

    },
    updateFormValues(event, field, isDynamic=false) {
      if (Array.isArray(field.Command)) {
        this.$emit('onTriggerCommand', { field, value: event });
      }


      
     

      if (Array.isArray(field.Children)) {
        for (let child of field.Children) {

          if ((child.Command === 'fetchOptions' || child.Command === 'fetchChildFormValues') && field.Type!=3) {
            
            
          const payload = { parent: field, child, parentValue: event };
            if (child.Command === 'fetchOptions') {
              this.$emit('fetchFormOptions', payload);
            } else if (child.Command === 'fetchChildFormValues') {
              this.$emit('fetchChildFormValues', payload);
            }
            
          } else if (child.Command === 'appendToDefault') {
            this.$emit('appendToDefault', {
              parent: field,
              child,
              parentValue: event,
              childValue:child.Value

            });
          } else if (child.Command === 'showSelectedField') {
            this.$emit('showSelectedField', {
              parent: field,
              child,
              parentValue: event,
              triggerValue:child.triggerValue
            });
          } else if(child.Command==='updateText'){

           this.$emit('updateFieldText', {
              parent: field,
              child,
              parentValue: event,
              triggerValue:child.triggerValue,
              targetCategory:child.targetCategory
            });
          }else if (child.Command === 'showCategory' ){
           
              
             this.$emit('showCategory', {
              parent: field,
              child,
              parentValue: event,
              triggerValue:child.triggerValue
            });
          }else if (child.Command=='hideSelectedField'){
            this.$emit('hideSelectedField',{
              parent: field,
              child,
              parentValue: event,
              triggerValue: child.triggerValue
            });


          }else if(child.Command=='refreshField'){

                  if(!this.fieldsKeys[child.Name]){
                    this.fieldsKeys[child.Name]=1;
                  }else{
                      this.fieldsKeys[child.Name]++;

                  }

                 /*
                  this.$emit('refreshField',{
                    parent: field,
                    child,
                    parentValue: event,
                  
                  });
                */

          }else if(child.Command=='makeRequired'){
                this.$emit('makeFieldRequired',{
                  parent: field,
                  child,
                  parentValue: event,
                  triggerValue: child.triggerValue
                });


          }
          else if (child.Command === 'updateFacilityStatus') {
            this.$emit('updateFacilityStatus', {
              parent: field,
              child,
              parentValue: event,
              triggerValue:child.triggerValue
            });
          }
          else if (child.Command === 'updateToggleRequired') {
            this.$emit('updateToggleRequired', {
              parent: field,
              child,
              parentValue: event,
              triggerValue:child.triggerValue
            });
          }
        }
      }



  
      this.$forceUpdate();

      

      if(field.refreshWhenAnswered){
        this.updateFormCardTime=new Date().getTime();
      }

      

      if(isDynamic){
        this.checkDynamicNowVisible();
      }
      
    },
    checkifvisible(field){
      var vm=this;

      if(field.DisplayFieldCondition){
        if(field.DisplayFieldCondition['value']){


            if(Array.isArray(field.DisplayFieldCondition['value'])){
           
            
              var hasValue=false;
              $.each(JSON.parse(JSON.stringify(field.DisplayFieldCondition['value'])),function(key,value){
                 
                  if(String(value).valueOf()==String(vm.rowData[field.DisplayFieldCondition['field']]).valueOf()){
                      hasValue=true;
                  }
                 
              });

              return hasValue;

            }else{
              return this.rowData[field.DisplayFieldCondition['field']]==field.DisplayFieldCondition['value'];
            }
        }else{

          return this.rowData[field.DisplayFieldCondition['field']];
        }
      }else{
                     

        return true;
      }
    },
    getDynamicFormRows(getRowUri,categoryID){
          var data={};

          if(this.rowData.ID){
              data['ID']=this.rowData.ID;
          }

          data['routerParams']=this.$route.params;


        this.$http
        .post(getRowUri, data)
        .then(response => {
        
          

          var vm=this;
          $.each(response.data.data.rows,function(index,row){
            $.each(row.fields,function(fieldIndex,field){
                  if(field.Required){
                            
                            vm.$root.$emit('addedDynamicFieldRequired',{fieldAdded:field,categoryID:categoryID});

                          }


                         


                          if(response.data.data.answers[field.Name]){
                              vm.rowData[field.Name]=response.data.data.answers[field.Name];
                          }else if(field.DefaultValue){
                              vm.rowData[field.Name]=field.DefaultValue;

                          }else{
                              vm.rowData[field.Name]=null;

                          }
            });

             vm.deynamicFields.push(row);
       
          });


            vm.dynamicFieldIndex=response.data.data.fieldIndex;



         
        })
        .catch(error => {
   
        });

    },
    dynamicFormOptionClicked(value,submitUri,categoryID){
      
      var data={};

      if(this.rowData.ID){
          data['ID']=this.rowData.ID;
      }

      data['optionChoosen']=value;
      data['currentIndex']=this.dynamicFieldIndex+1;
      data['routerParams']=this.$route.params;

       this.$http
        .post(submitUri, data)
        .then(response => {
          this.dynamicFieldIndex+=1;
          

          var vm=this;
          $.each(response.data.data,function(index,row){
            $.each(row.fields,function(rowIndex,field){
                if(field.Required){
                    
                    vm.$root.$emit('addedDynamicFieldRequired',{fieldAdded:field,categoryID:categoryID});

                  }



                  if(field.DefaultValue){
                      vm.rowData[field.Name]=field.DefaultValue;

                  }else{
                      vm.rowData[field.Name]=null;

                  }
            });
             vm.deynamicFields.push(row);

        
          });


         
        })
        .catch(error => {
   
        });

    },
    removeDynamicRow(index){

      var field=this.deynamicFields[index];

      if(field){
          var vm=this;
          $.each(field.fields,function(index,value){

            if(vm.rowData[value.Name]){
               delete vm.rowData[value.Name];
            }
          });

      }

      this.deynamicFields.splice(index,1);
    },
    refreshForm(){
      this.$emit('refresh-add-edit-root');
    },
    updateRowValue(data){

        if(data.data.requiredFields){

            var passedData=data.data.data;

            this.rowData[data.fieldName]=passedData;
            this.$root.$emit("addRequiredFields",{fields:data.data.requiredFields,fieldName:data.fieldName});

        }else{
            this.rowData[data.fieldName]=data.data;

        }

        

    },
    updateRequiredFields(data){
                this.$root.$emit("addRequiredFields",{fields:data.data,fieldName:data.fieldName});

    },
    showCategory(cat){
      return cat.Hidden?false:true;

    }
  },
};
</script>
