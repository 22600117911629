import Vue from 'vue';
import { VueEditor } from 'vue2-editor';

export function init(Survey,vm) {
    var testEditWidget={
        name:'editor-prop',
        title:'Property Editor',
        showInToolbox:false,
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'editor-prop';
        },
        init(){
          Survey.Serializer.addClass('editor-prop',[],null,'empty');

         
        },
        htmlTemplate:"<div class='editor-prop-container'></div>",
        afterRender: function(question,el) {
          
          var currentText = question.value;
  
          var ComponentClass = Vue.extend(VueEditor);
          var instance = new ComponentClass({
            propsData: {
              value: currentText,
              disabled:parseInt(vm.isPublished)==1?true:false,
              editorToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline'], // toggled buttons
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ color: [] }, { background: [] }],
              ],
            },
          });
  
          instance.$mount();
  
         
          instance.$on('change', data => {});
          instance.$on('input', data => {
            question.value = data;
          });
          $(el).append(instance.$el);
          
        },
        destroy: function(question, htmlElement) {
          $(htmlElement).destroy();
        },

        

          
           
        
      };
    //Register our widget in singleton custom widget collection

    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('editor-prop')){
        Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget, "customtype");
    }
    
}