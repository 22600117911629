import auth from '@/router/middleware/auth';
const AdminDashboard = r => require.ensure([], () => r(require('./AdminDashboard')), 'adminDashboard-bundle');
export default [
  {
    path: '/adminDashboard',
    name: 'adminDashboard',
    component: AdminDashboard,
    meta: {
      middleware: [auth]
    }
  },
];