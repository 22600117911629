<template>
	<b-modal @close="close" 
	modal-class="modal-size-modal" 
	dialog-class="modal-size-dialog" 
	content-class="modal-size-content"
	body-class="modal-size"
	 id="modal-search-providers" visible no-close-on-esc no-close-on-backdrop hide-footer scrollable title="Search for Providers" no-enforce-focus>
		
    	 <ProviderGrid></ProviderGrid>

    
  
	</b-modal>
</template>
<style>
.modal-size-modal {
  width: 100% !important;
  max-width:100% !important;
}
.modal-size-dialog {
  width: 98% !important;
  max-width:98% !important;
  margin:auto !important;
}
.modal-size-content{
  width: 100% !important;
  max-width:100% !important;
}
.modal-size {
  width: 100% !important;
  max-width:100% !important;
}
</style>
<script>
  import ProviderGrid from "./ProviderGrid.vue";
  
  export default {
    data() {
      return {
	  }
     
    },

    
    methods: {
	
     close(){
    	this.$router.push({path:'/mentorship-module'});
    	
   	 },
      
    
     
	  
	},
	  components:{
		  ProviderGrid
	  }

	  
    }
  
</script>