<template>
  <b-modal
    size="md"
    visible
    no-close-on-backdrop
    scrollable
    hide-header-close
    hide-header


  >
    <template v-slot:modal-header>
    
    </template>
    <div class="container-fluid bg-white" v-html="settings.body">
    </div>
                     
    <template v-slot:modal-footer>
      
 
            <b-button           
                class="ml-2 float-right"
                variant="success" 
                @click="confirmed"> {{ $t("general.yes") }} </b-button>
            <b-button           
                class="ml-2 float-right"
                variant="danger" 
                @click="cancel">{{ $t("general.no") }}</b-button>
    </template>
  </b-modal>
</template>
<script>

import { apiPost } from '@/services/generic';

export default {
  name: 'CopyToOrgModal',
 props: ['settings','serverParams'],
  data() {
    return {
      type: '',
      params: {},
      formError: '',
      form: null,
    };
  },
  created() {

  },
  mounted(){

  },
  methods: { 
    async confirmed(){

              if(this.settings.path){
        
                  this.$emit('onCloseCustomConfirmation',{path:this.settings.path,pathParams:this.settings.pathParams});
                
              }else{
               const response = await apiPost(this.settings.confirmationUri);

             
                  this.$emit('onCloseCustomConfirmation',response);
               
              }
              



    },
    cancel(){
        this.$emit('onCloseCustomConfirmation');
    }
  },
};
</script>
