<template>
  <b-avatar
    :badge="hasNotifications"
    badge-top
    variant="light"
    badge-variant="danger"
    icon="bell"
  ></b-avatar>
</template>
<script>
export default {
  name: 'Notification',
  created() {
    this.checkNotifications();
  },
  computed: {
    hasNotifications() {
      return this.$store.getters.getHasNotifications;
    },
  },
};
</script>
