import { ErrorMessages } from '@/utils/errorMessages';
import { getErrorMessage, isStringAndNotEmpty } from '@/utils/langUtils';

export default {
  data: function() {
    return {
      dialogProps: null,
    };
  },
  props: {
    formData: { type: Object, default: null },
    errorMessage: { type: String, default: null },
  },
  created: function() {
    const menuBtnsData = {
      ...this.formData.serverParams,
      url: this.formData['getFormHeaderUrl'],
    };
    this.dialogProps = { menuBtnsData };

  },
  methods: {
    onClickCancel: function() {
      this.$emit('onClickCancel');
    },
    onClickMenuBtn: function(btnAction) {
      try {
        const formData = this.getFormData();
        if (btnAction.validate && !this.validateForm()) {
          throw Error(ErrorMessages.MISSING_FIELDS);
        }
        const payload = { ...formData, action: btnAction };
        payload.answers['__btnAction'] = btnAction;
        this.$emit('onClickFormMenuBtn', payload);
        this.$emit('onError', '');
      } catch (error) {
        this.onError(error);
      }
    },
    getFormData: function() {
      const answers = this.$refs.surveyJsFormInstance.getAnswersObj();
      return { answers };
    },
    onError: function(error) {
      const message = getErrorMessage(error);
      this.$emit('onError', message);
    },
    validateForm: function() {
      return true;
    },
  },
};
