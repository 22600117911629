<template>
  <b-modal
    size="xl"
    :content-class="modalContentClass"
    :dialog-class="modalDialogClass"
    :body-class="modalBodyClass"
    :modal-class="modalModalClass"
    hide-footer
    v-if="$route.params.cservice || passedService"
    visible
    no-close-on-backdrop
    scrollable
  >
    <template v-slot:modal-header>
      <div class='mb-0 font-weight-bold mr-auto w-75'><h3  class='headerTitle'></h3></div>
 
      <div class="w-25 text-right">
        <b-btn
          class="ml-2"
          variant="danger"
          @click="$emit('onCloseModalView'); closeModalClicked()"
        >
        {{ $t("general.close") }}
        </b-btn>
      </div>
    </template>
    <div class="container-fluid bg-white">
      <div v-if="inTrainingMode==true">
         <b-alert show style="text-align:center" variant="warning">{{ $t("ModalView.training") }}</b-alert>
     </div>
      <div v-if="type === 'ListView' || type === 'ListViewLarge' ">
        <ListView v-bind="params"></ListView>
      </div>
      <div v-else-if="type === 'FormCollaboration'">
          <FormCollaboration 
            :AssessmentID="$route.params.rowID"
            :cservice="$route.params.cservice"
          ></FormCollaboration>
      </div>
      <div v-else-if="type === 'Calendar'">
          <Calendar :AssessmentID="$route.params.rowID" :AssessmentType="$route.params.componentParams?$route.params.componentParams.AssessmentType:($route.params.prefiltername=='AssessmentType'?$route.params.prefilter:null)"></Calendar>
      </div>

    </div>
  </b-modal>
</template>
<script>
import ListView from '@/app/list-view';
import Calendar from '@/app/calendar';
import FormCollaboration from '@/components/FormCollaboration.vue'
import FormCollaborationModal from './FormCollaborationModal.vue';

export default {
  name: 'ModalView',
  components: { ListView, FormCollaboration, FormCollaborationModal,Calendar},
  data() {
    return {
      type: '',
      params: {},
      formError: '',
      form: null,
      relaunchDeployment:false,
      reloadParamTime:null,
      modalDialogClass:null,
      modalContentClass:null,
      modalBodyClass:null,
      modalModalClass:null
    };
  },
  props:{
    componentName:{type: String,default:null},
    assessmentID:{type: String,default:null},
    assessmentType:{type: String,default:null},
    seriesID:{type: String,default:null},
    passedService:{type: String,default:null },
    rowID:{type: String,default:null },
    dialogClass:{type: String,default:null},
    contentClass:{type: String,default:null},
    bodyClass:{type: String,default:null},
    modalClass:{type: String,default:null}
  },
  created() {
    this.type = this.componentName?this.componentName:this.$route.params.componentName;

    if(this.$route.params.contentClass){
      this.modalContentClass=this.$route.params.contentClass;
    }else if(this.type=='ListViewLarge'){
       this.modalContentClass="fullScreenModal";



    
    }else{
      this.modalContentClass=this.contentClass;
    }

     if(this.$route.params.dialogClass){
      this.modalDialogClass=this.$route.params.dialogClass;
    }else if(this.type=='ListViewLarge'){
       this.modalDialogClass="fullScreenDialog";

    }else{
      this.modalDialogClass=this.dialogClass;
    }


   if(this.$route.params.bodyClass){
      this.modalBodyClass=this.$route.params.bodyClass;
   }else if(this.type=='ListViewLarge'){
       this.modalBodyClass="fullScreenBody";

    
    }else{
      this.modalBodyClass=this.bodyClass;
    }


    if(this.$route.params.modalClass){
      this.modalModalClass=this.$route.params.modalClass;
     }else if(this.type=='ListViewLarge'){
       this.modalModalClass="fullScreenModal"
    }else{
      this.modalModalClass=this.modalClass;

    }

    

    this.params = { isChild: true };

    if(this.assessmentID){
      this.params={...this.params,AssessmentID:this.assessmentID};

    }else if(this.$route.params.assessmentID){
      this.params={...this.params,AssessmentID:this.$route.params.assessmentID};
    }

    if(this.assessmentType){
            this.params={...this.params,AssessmentType:this.assessmentType};

    }else if(this.$route.params.assessmentType){
            this.params={...this.params,AssessmentType:this.$route.params.assessmentType};

    }

    if(this.seriesID){
      this.params={...this.params,seriesID:this.seriesID};
    }

    if(this.rowID){
      this.params={...this.params,rowID:this.rowID};
    }



    
  },
  mounted(){

  },
  methods: {
      closeModalClicked(){

        if(this.$route.params && this.$route.params.componentParams){
              if(this.$route.params.componentParams.reloadGridOnClose){
                          this.$root.$emit('reloadGridOnClose');
              }
        }
      
        if(this.type=='ListViewLarge'){
                this.$root.$emit('reloadGridOnClose');

        }
        


        if(this.$route.params.returnPath){

          this.$router.push({path:this.$route.params.returnPath,params:{returning:true}});

        }else{

          this.$router.go(-1);
        }
      }
      

   },
};
</script>
