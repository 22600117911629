<template>
  <Avatar
    v-if="showImage && fileThumbnail.Type === 'image'"
    :pathToFetchSignedURL="fileThumbnail.pathToFetchSignedURL"
    :URL="fileThumbnail.fileURL"
    rounded="sm"
    cssClass="mr-1"
    @imgError="showImage = false"
  />
  <b-icon
    v-else
    :icon="fileThumbnail.icon ? fileThumbnail.icon : 'file-earmark'"
    :font-scale="fileThumbnail.iconScale ? fileThumbnail.iconScale : '1'"
    :class="thumbnailIconCss(fileThumbnail)"
  />
</template>
<script>
import Avatar from '@/components/Avatar.vue';

export default {
  components: { Avatar },
  name: 'FileThumbnailDisplay',
  data: function() {
    return {
      showImage: true,
    };
  },
  props: {
    fileThumbnail: { type: Object, default: null },
  },
  methods: {
    thumbnailIconCss(fileThumbnail) {
      return [
        'file-thumbnail__icon',
        fileThumbnail.align === 'column'
          ? 'file-thumbnail__icon--vertical'
          : '',
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.file-thumbnail {
  &__icon {
    margin-right: 0.5rem;
    &--vertical {
      margin-bottom: 1rem;
    }
  }
}
</style>
