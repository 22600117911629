<template>
  <div>
    <!-- eslint-disable vue/no-v-text-v-html-on-component -->
    <b-alert
      fade
      id="formErrorDiv"
      v-show="errorMessage"
      variant="danger"
      show
      v-html="cleanedError"
    ></b-alert>
    <!--eslint-enable-->
    <div v-if="isDoneLoading()" :key="refreshPanels">
      <FormPanel
        v-for="(cat, i) in filterCategories(customFieldCategories)"
        :key="`${cat.Name}_${i}`"
        :panel="cat"
        :panelIndex="getPanelIndex(cat)"
        :answers="answers"
        :fieldKeys="fieldKeys"
        :formType="formType"
        @onInputFormControl="handleFormControlInput($event.event, $event.field)"
        @onClickRemoveRow="handleRemoveRow($event)"
        @onClickAddRow="handleAddRow($event)"
        @tags-reviewed-event="handleTagsReviewed($event)"
      />
    </div>
    <div v-else>
      {{ $t("general.loading") }}
    </div>
  </div>
</template>
<script>
import FormPanel from '@/components/form/FormPanel.vue';
import {
  isArrayAndNotEmpty,
  isStringAndNotEmpty,
  isObjectAndNotEmpty,
  isObject,
  copyAndMerge,
  deepCopy,
} from '@/utils/langUtils';
import { getData } from '@/utils/get';
import * as SurveyCore from 'survey-core';
import { apiPost } from '@/services/generic';
import {
  answersDif,
  findCustomFieldAnswer,
  findCustomFieldByName,
  findCustomFieldParents,
  getAllAnswers,
  getDynRowsFromAnswers,
} from '@/utils/cfUtils';
import { getPropsFromFiles } from '@/utils/s3ResourceUtils';

export default {
  name: 'CustomFieldForm',
  components: {
    FormPanel,
  },
  data: function() {
    return {
      answers: {},
      canLoad: false,
      customFieldCategories: [],
      fieldKeys: {},
      form: [],
      formService: null,
      refreshPanels: null,
      oldAnswers: null,
    };
  },
  props: {
    errorMessage: { type: String, default: null },
    formServiceName: { type: String, default: null },
    formType: { type: String, default: null },
    getFormUrl: { type: String, default: null },
    getAnswersUrl: { type: String, default: null },
    serverParams: { type: Object, default: null },
  },
  created: function() {
    if (this.formServiceName) {
      this.formService = require(`@/services/${this.formServiceName}`);
    }

    this.fetchCustomFields();
  },
  computed: {
    cleanedError: function() {
      if (this.errorMessage && this.errorMessage.substring(0, 1) == '"') {
        let cleaned = this.errorMessage.substring(1);
        cleaned = cleaned.substring(0, cleaned.length - 1);
        return cleaned;
      } else {
        return this.errorMessage;
      }
    },
  },
  methods: {
    filterCategories: function(categories) {
      const list = [];
      if (Array.isArray(categories)) {
        for (const category of categories) {
          if (category.Hidden === true) continue;
          list.push(category);
        }
      }
      return list;
    },
    isDoneLoading: function() {
      return isArrayAndNotEmpty(this.customFieldCategories) && this.canLoad;
    },
    handleAddRow: function(props) {
      const { fields, answers, panelIndex } = props;
      const categories = this.customFieldCategories;
      if (isNaN(panelIndex) || panelIndex >= categories.length) return;
      const dynRows = categories[panelIndex].dynRows;
      const panelName = categories[panelIndex].Name;
      if (Array.isArray(dynRows) && dynRows.length > 0) {
        this.$set(categories[panelIndex], 'dynRows', dynRows.concat([fields]));
      } else {
        this.$set(categories[panelIndex], 'dynRows', [fields]);
      }
      if (!isObject(this.answers['__dynPanels'], true)) {
        const ansObj = {};
        ansObj[panelName] = [answers];
        this.$set(this.answers, '__dynPanels', ansObj);
      } else if (!Array.isArray(this.answers['__dynPanels'][panelName])) {
        this.$set(this.answers['__dynPanels'], panelName, [answers]);
      } else {
        const dynAnswers = this.answers['__dynPanels'][panelName];
        this.$set(
          this.answers['__dynPanels'],
          panelName,
          dynAnswers.concat([answers])
        );
      }
    },
    handleRemoveRow: function(props) {
      const { row, rowIndex, panelIndex } = props;
      const panelName = this.customFieldCategories[panelIndex]['Name'];
      this.customFieldCategories[panelIndex]['dynRows'].splice(rowIndex, 1);
      this.answers['__dynPanels'][panelName].splice(rowIndex, 1);
    },
    handleFormControlInput: function(event, field) {
      if (Array.isArray(field.Command)) {
        this.onTriggerCommand({ field, value: event });
      }
      if (isArrayAndNotEmpty(field.Children)) {
        this.handleChildren(event, field);
      }
    },
    handleChildren: function(event, field) {
      for (let child of field.Children) {
        const cmd = child.Command;
        const payload = {
          parent: field,
          child,
          parentValue: event,
          childValue: child.Value,
          triggerValue: child.triggerValue,
        };
        if (cmd === 'runCondition') {
          this.onRunCondition(child);
        } else if (cmd === 'fetchOptions') {
          this.fetchFormOptions(payload);
        } else if (cmd === 'fetchChildFormValues') {
          this.fetchChildFormValues(payload);
        } else if (cmd === 'showSelectedField') {
          this.showSelectedField(payload);
        } else if (cmd === 'updateText') {
          this.changeFieldText(payload);
        } else if (cmd === 'clearFieldOnChange') {
          this.clearFieldValOnChage(payload);
        } else if (cmd === 'hideSelectedField') {
          this.hideSelectedField(payload);
        } else if (cmd == 'clearOnChange') {
          this.onClearOnChange(child);
        }
      }
    },
    onRunCondition: function(data) {
      let formItem = findCustomFieldByName(
        this.customFieldCategories,
        data.Name
      );
      if (!formItem) {
        formItem = this.findCustomCategory(data.Name);
      }
      if (!formItem) {
        console.error('unable to find ' + data.Name);
        return;
      }
      if (formItem && isStringAndNotEmpty(formItem.VisibleIf)) {
        const runner = new SurveyCore.ConditionRunner(formItem.VisibleIf);
        const answers = formItem['Name'].indexOf('__dynR')
          ? getAllAnswers(this.answers)
          : this.answers;
        const exprVal = runner.run(answers);
        formItem['Hidden'] = !exprVal;
      }
    },
    onClearOnChange: function(data) {
      let formItem = findCustomFieldByName(this.customFieldCategories, data.Name);
      if (!formItem) formItem = this.findCustomCategory(data.Name);
      if (!formItem) return;
      const answers = formItem.Name.indexOf('__dynR') ? getAllAnswers(this.answers) : this.answers;
      const empty = Array.isArray(answers[formItem.Name]) ? [] : '';
      this.updateFormValues({ target: data.Name, targetValue: empty });
      if (Array.isArray(formItem.Children) && formItem.Children.length > 0)
        this.handleChildren(empty, formItem);
    },
    getAnswersProp: function() {
      this.answers['__modifiedFields'] = answersDif(this.oldAnswers, this.answers);
      return this.answers;
    },
    getCustomCategoriesProp: function() {
      return this.customFieldCategories;
    },
    getPanelIndex: function(category) {
      if (!Array.isArray(this.customFieldCategories))
        return null;
      for (let i = 0; i < this.customFieldCategories.length; i++) {
        if (this.customFieldCategories[i].Name === category.Name)
          return i;
      }
      return null;
    },
    onTriggerCommand: async function(params) {
      try {
        const { field, value } = params;
        if (!Array.isArray(field.Command)) {
          return;
        }
        const payload = {};
        payload['__form'] = { ...this.answers['__form'] };
        payload[field.Name] = value;
        payload['__form']['fieldID'] = field.FieldID;
        for (const cmd of field.Command) {
          if (
            cmd.type === 'submitAnswerAndRefresh' ||
            cmd.type === 'submitAnswer'
          ) {
            let response = null;
            if (isStringAndNotEmpty(cmd.dataURI)) {
              response = await apiPost(
                cmd.dataURI,
                copyAndMerge(payload, getPropsFromFiles(payload))
              );
            } else {
              response = await this.formService.submitAnswer(payload);
            }
            if (response.error) {
              throw Error(response.message);
            } else {
              if (response['__form']) {
                for (const k in response['__form']) {
                  if (!this.answers['__form'][k]) {
                    this.answers['__form'][k] = response['__form'][k];
                  }
                }
              }
              if (response.uploadResourceURL) {
                this.$emit('onUploadResources', {
                  data: response,
                  form: { [field.Name]: value },
                });
              }
              if (response.newValue) {
                this.updateFormValues({
                  target: field.Name,
                  targetValue: response.newValue,
                });
              }
              if (cmd.type === 'submitAnswerAndRefresh') {
                await this.fetchCustomFields();
              }
            }
          }
        }
        this.$emit('onError', '');
      } catch (error) {
        this.$emit('onError', error);
      }
    },
    fetchChildFormValues: async function({ parent, child, parentValue }) {
      try {
        const childCustomFieldRef = findCustomFieldByName(
          this.customFieldCategories,
          child.Name
        );
        if (!childCustomFieldRef || !childCustomFieldRef.DataURI) return;
        const payload = {
          value: parentValue,
          parentID: parent.FieldID,
          parentName: parent.Name,
          childID: child.FieldID,
        };
        const response = await apiPost(
          `${childCustomFieldRef.DataURI.uri}`,
          payload
        );
        if (response.error) throw Error(response.message);
        const values = response;
        this.updateFormValues({ target: child.Name, targetValue: values });
      } catch (error) {
        console.log(error);
      }
    },
    updateFormValues: function(data) {
      const { target, targetValue } = data;
      if (!this.answers) return;
      if (this.answers[target] !== undefined) {
        if (Array.isArray(this.answers[target]) && targetValue === '')
          targetValue = [];
        this.answers[target] = targetValue;
        this.fieldKeys[target] = new Date().getTime();
      } else {
        const dynPanels = isObject(this.answers['__dynPanels'], true)
          ? this.answers['__dynPanels']
          : {};
        for (const panelName in dynPanels) {
          const panelAnswers = Array.isArray(dynPanels[panelName])
            ? dynPanels[panelName]
            : [];
          for (const panelAnswer of panelAnswers) {
            if (panelAnswer[target] !== undefined) {
              if (Array.isArray(panelAnswer[target]) && targetValue === '')
                targetValue = [];
              panelAnswer[target] = targetValue;
            }
          }
        }
      }
    },
    fetchFormOptions: async function({ parent, child, parentValue }) {
      const childCustomFieldRef = findCustomFieldByName(
        this.customFieldCategories,
        child.Name
      );
      if (
        !childCustomFieldRef ||
        !childCustomFieldRef.DataURI ||
        !childCustomFieldRef.DataURI.method
      )
        return;
      let response = null;
      let payload = {
        value: parentValue,
        parentID: parent.FieldID,
        parentName: parent.Name,
        parents: [],
        childID: childCustomFieldRef.FieldID,
        answers: this.answers,
      };
      const parents = findCustomFieldParents(
        childCustomFieldRef,
        this.customFieldCategories
      );
      for (const parent of parents) {
        const parentPayload = {};
        parentPayload.value = findCustomFieldAnswer(parent, this.answers);
        parentPayload.fieldID = parent.FieldID;
        payload.parents.push(parentPayload);
      }
      response = await apiPost(`${childCustomFieldRef.DataURI.uri}`, payload);
      childCustomFieldRef.Options = Array.isArray(response) ? response : [];
      // this.updateFormValues({
      //   target: childCustomFieldRef.Name,
      //   targetValue: '',
      // });
    },
    findCustomCategory: function(targetName) {
      for (let category of this.customFieldCategories) {
        if (category.Name === targetName) {
          return category;
        }
      }
      return null;
    },
    getFormAnswerData: function(payload = null) {
      if (this.formService && this.formService.getFormAnswers) {
        return this.formService.getFormAnswers(payload);
      } else if (this.getAnswersUrl) {
        return this.$http.post(this.getAnswersUrl, payload);
      } else {
        throw Error('Failed to retrieved form data.');
      }
    },
    showSelectedField({ parent, child, parentValue, triggerValue }) {
      const childCustomFieldRef = findCustomFieldByName(
        this.customFieldCategories,
        child.Name
      );

      if (!childCustomFieldRef) return;

      const parentValueObject =
        parent && Array.isArray(parent.Options)
          ? parent.Options.filter(ele => ele.value === parentValue)
          : null;

      if (parentValueObject && parentValueObject.length > 0) {
        if (triggerValue && Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Hidden = false;
          } else if (triggerValue.includes(parentValueObject[0].index)) {
            childCustomFieldRef.Hidden = false;
          } else {
            childCustomFieldRef.Hidden = true;
          }
        } else if (
          triggerValue &&
          triggerValue === parentValueObject[0].index
        ) {
          childCustomFieldRef.Hidden = false;
        } else if (
          typeof triggerValue !== 'undefined' &&
          triggerValue === parentValueObject[0].index
        ) {
          childCustomFieldRef.Hidden = false;
        } else if (childCustomFieldRef.Name === parentValueObject[0].index) {
          childCustomFieldRef.Hidden = false;
        } else if (triggerValue && triggerValue == parentValueObject[0].value) {
          childCustomFieldRef.Hidden = false;
        } else if (
          typeof triggerValue !== 'undefined' &&
          triggerValue == parentValueObject[0].value
        ) {
          childCustomFieldRef.Hidden = false;
        } else if (triggerValue && child.oper) {
          switch (child.oper) {
            case 'gte':
              if (parentValueObject[0].index >= triggerValue) {
                childCustomFieldRef.Hidden = false;
              } else {
                childCustomFieldRef.Hidden = true;
              }

              break;
            case 'lte':
              if (parentValueObject[0].index <= triggerValue) {
                childCustomFieldRef.Hidden = false;
              } else {
                childCustomFieldRef.Hidden = true;
              }
              break;
            case 'gt':
              if (parentValueObject[0].index > triggerValue) {
                childCustomFieldRef.Hidden = false;
              } else {
                childCustomFieldRef.Hidden = true;
              }
              break;
            case 'lt':
              if (parentValueObject[0].index < triggerValue) {
                childCustomFieldRef.Hidden = false;
              } else {
                childCustomFieldRef.Hidden = true;
              }
              break;
          }
        } else if (
          triggerValue &&
          triggerValue == 'any' &&
          parentValueObject[0].value
        ) {
          childCustomFieldRef.Hidden = false;
        } else {
          childCustomFieldRef.Hidden = true;
        }
      } else if (parentValue) {
        if (triggerValue && Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Hidden = false;
          } else {
            childCustomFieldRef.Hidden = true;
          }
        } else if (
          parentValue === triggerValue ||
          (isNaN(parentValue) &&
            parentValue.length > 0 &&
            triggerValue == 'any') ||
          (parentValue && triggerValue == 'any')
        ) {
          childCustomFieldRef.Hidden = false;
        } else if (triggerValue && Array.isArray(parentValue)) {
          let showField = false;

          $.each(parentValue, function(key, value) {
            let valueObject =
              value && Array.isArray(parent.Options)
                ? parent.Options.filter(ele => ele.value === value)
                : null;

            if (valueObject[0].index == triggerValue) {
              showField = true;
            }
          });

          if (showField) {
            childCustomFieldRef.Hidden = false;
          }
        } else if (triggerValue && child.oper) {
          switch (child.oper) {
            case 'gte':
              if (parentValue >= triggerValue) {
                childCustomFieldRef.Hidden = false;
              } else {
                childCustomFieldRef.Hidden = true;
              }

              break;
            case 'lte':
              if (parentValue <= triggerValue) {
                childCustomFieldRef.Hidden = false;
              } else {
                childCustomFieldRef.Hidden = true;
              }
              break;
            case 'gt':
              if (parentValue > triggerValue) {
                childCustomFieldRef.Hidden = false;
              } else {
                childCustomFieldRef.Hidden = true;
              }
              break;
            case 'lt':
              if (parentValue < triggerValue) {
                childCustomFieldRef.Hidden = false;
              } else {
                childCustomFieldRef.Hidden = true;
              }
              break;
          }
        }
      }
    },
    getFormData: async function(payload = null) {
      if (this.formService && this.formService.getForm) {
        return this.formService.getForm(payload);
      } else if (this.getFormUrl) {
        return this.$http.post(this.getFormUrl, payload).then(getData);
      } else {
        throw Error('Failed to retrieve form data');
      }
    },
    getFetchPayload: function() {
      let payload = {};

      if (this.serverParams) payload = { ...payload, ...this.serverParams };
      return payload;
    },
    fetchCustomFields: function() {
      try {
        this.canLoad = false;
        this.getFormData(this.getFetchPayload()).then(response => {
          let customFieldCategories = response;

          if (customFieldCategories.error) {
            throw Error(customFieldCategories.message);
          }

          this.customFieldCategories = customFieldCategories;
          this.getFormAnswerData(this.getFetchPayload()).then(response => {
            let answerData = {};

            if (typeof response.data !== 'undefined') {
              answerData = Object.assign({}, response.data.data);
            } else {
              answerData = response;
            }

            if (answerData.error) {
              throw Error(answerData.message);
            }

            if (this.serverParams) {
              for (const k in this.serverParams) {
                if (answerData[k])
                  answerData[k] = { ...answerData[k], ...this.serverParams[k] };
                else answerData[k] = { ...this.serverParams[k] };
              }
            }

            this.answers = JSON.parse(JSON.stringify(answerData));

            for (let category of customFieldCategories) {
              if (!Array.isArray(category.customFields))
                throw new Error('Invalid category');
              for (let customField of category.customFields) {
                if (!answerData[customField['Name']]) {
                  if (customField.DefaultValue) {
                    answerData[customField['Name']] = customField.DefaultValue;
                  }
                } else {
                  answerData[customField['Name']] =
                    answerData[customField['Name']];
                }
              }
            }
            for (const category of customFieldCategories) {
              if (category.IsDynamic === true) {
                const catDynPanel =
                  answerData &&
                  answerData['__dynPanels'] &&
                  answerData['__dynPanels'][category.Name]
                    ? answerData['__dynPanels'][category.Name]
                    : [];
                category.dynRows = getDynRowsFromAnswers(category, catDynPanel);
              }
            }
            this.customFieldCategories = customFieldCategories;

            this.answers = JSON.parse(JSON.stringify(answerData));
            for (const category of customFieldCategories) {
              for (const customField of category.customFields) {
                if (
                  isArrayAndNotEmpty(customField.Children) &&
                  !category.IsDynamic
                ) {
                  const answer = findCustomFieldAnswer(customField, answerData);
                  this.handleChildren(answer, customField);
                }
              }
              const dynRows = Array.isArray(category.dynRows)
                ? category.dynRows
                : [];
              for (const row of dynRows) {
                const customFields = Array.isArray(row) ? row : [];
                for (const customField of customFields) {
                  if (isArrayAndNotEmpty(customField.Children)) {
                    const danswer = findCustomFieldAnswer(
                      customField,
                      answerData
                    );
                    this.handleChildren(danswer, customField);
                  }
                }
              }
            }
            this.oldAnswers = deepCopy(this.answers);
            this.$emit('onError', '');

            this.canLoad = true;

            this.refreshPanels = new Date().getTime();
          });
        });
      } catch (error) {
        this.customFieldCategories = [];
        this.answers = {};
        this.$emit('onError', error);
      }
    },
    changeFieldText({
      parent,
      child,
      parentValue,
      triggerValue,
      targetCategory,
    }) {
      const childCustomFieldRef = findCustomFieldByName(
        this.customFieldCategories,
        child.Name
      );

      if (!childCustomFieldRef) return;

      const parentValueObject =
        parent && Array.isArray(parent.Options)
          ? parent.Options.filter(ele => ele.value === parentValue)
          : null;

      if (parentValueObject && parentValueObject.length > 0) {
        if (triggerValue && Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Text = this.findAlternateText(
              childCustomFieldRef,
              parent,
              parentValue
            );
          } else if (triggerValue.includes(parentValueObject[0].index)) {
            childCustomFieldRef.Text = this.findAlternateText(
              childCustomFieldRef,
              parent,
              parentValueObject[0].index
            );
          }
        } else if (
          triggerValue &&
          triggerValue === parentValueObject[0].index
        ) {
          childCustomFieldRef.Text = this.findAlternateText(
            childCustomFieldRef,
            parent,
            parentValueObject[0].index
          );
        } else if (
          typeof triggerValue !== 'undefined' &&
          triggerValue === parentValueObject[0].index
        ) {
          childCustomFieldRef.Text = this.findAlternateText(
            childCustomFieldRef,
            parent,
            parentValueObject[0].index
          );
        } else if (childCustomFieldRef.Name === parentValueObject[0].index) {
          childCustomFieldRef.Text = this.findAlternateText(
            childCustomFieldRef,
            parent,
            parentValueObject[0].index
          );
        } else if (triggerValue && triggerValue == parentValueObject[0].value) {
          childCustomFieldRef.Text = this.findAlternateText(
            childCustomFieldRef,
            parent,
            parentValueObject[0].index
          );
        } else if (
          typeof triggerValue !== 'undefined' &&
          triggerValue == parentValueObject[0].value
        ) {
          childCustomFieldRef.Text = this.findAlternateText(
            childCustomFieldRef,
            parent,
            parentValueObject[0].index
          );
        } else if (
          triggerValue &&
          triggerValue == 'any' &&
          parentValueObject[0].value
        ) {
          childCustomFieldRef.Text = this.findAlternateText(
            childCustomFieldRef,
            parent,
            parentValueObject[0].index
          );
        } else {
          childCustomFieldRef.Text = childCustomFieldRef.OrigText;
        }
      } else if (parentValue) {
        if (triggerValue && Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Text = this.findAlternateText(
              childCustomFieldRef,
              parent,
              parentValue
            );
          }
        } else if (
          parentValue === triggerValue ||
          (isNaN(parentValue) &&
            parentValue.length > 0 &&
            triggerValue == 'any') ||
          (parentValue && triggerValue == 'any')
        ) {
          childCustomFieldRef.Text = this.findAlternateText(
            childCustomFieldRef,
            parent,
            parentValue
          );
        } else if (triggerValue && Array.isArray(parentValue)) {
          childCustomFieldRef.Text = this.findAlternateText(
            childCustomFieldRef,
            parent,
            parentValue
          );
        } else {
          childCustomFieldRef.Text = childCustomFieldRef.OrigText;
        }
      }
    },
    findAlternateText(child, parent, parentValue) {
      if (child.AlternateTexts && child.AlternateTexts.length > 0) {
        let returnText = null;
        for (let AltText of child.AlternateTexts) {
          if (
            AltText.ParentFieldID == parent.FieldID &&
            AltText.TriggerValue == parentValue
          ) {
            returnText = AltText.AlternateText;
            break;
          }
        }
        if (returnText) {
          return returnText;
        } else {
          return child.Text;
        }
      } else {
        return child.Text;
      }
    },
    clearFieldValOnChage({
      parent,
      child,
      parentValue,
      triggerValue,
      targetCategory,
    }) {
      const childCustomFieldRef = findCustomFieldByName(
        this.customFieldCategories,
        child.Name
      );

      if (!childCustomFieldRef) return;
      var defaultIsArray = Array.isArray(childCustomFieldRef.DefaultValue);
      const parentValueObject =
        parent && Array.isArray(parent.Options)
          ? parent.Options.filter(ele => ele.value === parentValue)
          : null;
      if (parentValueObject && parentValueObject.length > 0) {
        if (triggerValue && Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Value = childCustomFieldRef.DefaultValue;
          } else if (triggerValue.includes(parentValueObject[0].index)) {
            childCustomFieldRef.Value = childCustomFieldRef.DefaultValue;
          }
        } else if (
          triggerValue &&
          triggerValue === parentValueObject[0].index
        ) {
          childCustomFieldRef.Value = childCustomFieldRef.DefaultValue;
        } else if (
          typeof triggerValue !== 'undefined' &&
          triggerValue === parentValueObject[0].index
        ) {
          childCustomFieldRef.Value = childCustomFieldRef.DefaultValue;
        } else if (childCustomFieldRef.Name === parentValueObject[0].index) {
          childCustomFieldRef.Value = childCustomFieldRef.DefaultValue;
        } else if (triggerValue && triggerValue == parentValueObject[0].value) {
          childCustomFieldRef.Value = childCustomFieldRef.DefaultValue;
        } else if (
          typeof triggerValue !== 'undefined' &&
          triggerValue == parentValueObject[0].value
        ) {
          childCustomFieldRef.Value = childCustomFieldRef.DefaultValue;
        } else if (
          triggerValue &&
          triggerValue == 'any' &&
          parentValueObject[0].value
        ) {
          childCustomFieldRef.Value = childCustomFieldRef.DefaultValue;
        } else {
          if (typeof childCustomFieldRef.Value !== 'undefined') {
            childCustomFieldRef.Value = childCustomFieldRef.DefaultValue;
            this.answers[childCustomFieldRef.Name] =
              childCustomFieldRef.DefaultValue;
          }
        }
      } else if (parentValue) {
        if (triggerValue && Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Value = childCustomFieldRef.DefaultValue;
          }
        } else if (
          parentValue === triggerValue ||
          (isNaN(parentValue) &&
            parentValue.length > 0 &&
            triggerValue == 'any') ||
          (parentValue && triggerValue == 'any')
        ) {
          childCustomFieldRef.Text = childCustomFieldRef.DefaultValue;
        } else if (triggerValue && Array.isArray(parentValue)) {
          childCustomFieldRef.Value = childCustomFieldRef.DefaultValue;
        } else {
          if (childCustomFieldRef.Value.length > 0) {
            childCustomFieldRef.Value = childCustomFieldRef.DefaultValue;
          }
        }
      }
    },
    hideSelectedField({ parent, child, parentValue, triggerValue }) {
      const childCustomFieldRef = findCustomFieldByName(
        this.customFieldCategories,
        child.Name
      );

      if (!childCustomFieldRef) return;
      const parentValueObject =
        parent && Array.isArray(parent.Options)
          ? parent.Options.filter(ele => ele.value === parentValue)
          : null;
      if (parentValueObject && parentValueObject.length > 0) {
        if (triggerValue && Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Hidden = true;
          }
        } else if (triggerValue && triggerValue === parentValue) {
          childCustomFieldRef.Hidden = true;
        } else if (
          triggerValue &&
          triggerValue === parentValueObject[0].index
        ) {
          childCustomFieldRef.Hidden = true;
        } else if (childCustomFieldRef.Name === parentValueObject[0].index) {
          childCustomFieldRef.Hidden = true;
        }
      } else if (parentValue) {
        if (parentValue === triggerValue) {
          childCustomFieldRef.Hidden = true;
        }
      }
    },
    handleTagsReviewed(data) {
      this.answers['tagsReviewed'] = data.event;
    },
  },
};
</script>
