// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

const CNTLR_NAME = 'apiEvent';

export const getTableSettings = (payload = null) =>
  http.post(`${CNTLR_NAME}/getEventListTableSettings`, payload).then(getData);

export const get = (payload = null) =>
  http.post(`${CNTLR_NAME}/getEventListRows`, payload).then(getData);

export const getRow = (rowID, payload = {}) =>
  http.post(`${CNTLR_NAME}/getRow&ID=${rowID}`, payload).then(getData);

export const create = row =>
  http.post(`${CNTLR_NAME}/createEvent`, row).then(getData);

export const update = row =>
  http.post(`${CNTLR_NAME}/editEvent&ID=${row.ID}`, row).then(getData);

export const remove = row =>
  http.post(`${CNTLR_NAME}/deleteEvent`, row).then(getData);

export const getLinkedForms = eventID =>
  http.get(`apiEventForms/getFormsForEvent&eventID=${eventID}`).then(getData);
