import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

/**
 * Get Users' messaging profile for messaging user creation.
 * @returns {Promise<T>}
 */
export const getMessagingProfile = () => http.get('apiMessaging/getMessagingProfile').then(getData);

/**
 * Add filter for messaging group filters
 * @returns {Promise<T>}
 */
export const addMessagingFilter = (payload) => http.post('apiMessaging/addMessagingFilter', payload).then(getData);

/**
 * Get filters for messaging groups
 * @returns {Promise<T>}
 */
export const getFilters = () => http.get('apiMessaging/getFilters').then(getData);

/**
 * 
 * @returns {Promise<T>}
 */
export const getCategoriesAndValuesByUserID = (userId) => http.get(`apiMessaging/getCategoriesAndValuesByUserID&userId=${userId}`).then(getData);

/**
 * Check user's opt-in status for messaging
 * @returns {Promise<T>}
 */
export const checkMessagingOptInStatus = () => http.get('apiMessaging/checkMessagingOptInStatus').then(getData);

/**
 * Get secret for message content encryption
 * @returns {Promise<T>}
 */
export const getMessageSecret = () => http.get('apiMessaging/getMessageSecret', { silent: true }).then(getData);

/**
 * Encrypt message
 * @returns {Promise<T>}
 */
export const encryptMessage = (payload) => http.post('apiMessaging/encryptMessage', payload, { silent: true }).then(getData);

/**
 * Decrypt message
 * @returns {Promise<T>}
 */
 export const decryptMessage = (payload) => http.post('apiMessaging/decryptMessage', payload, { silent: true }).then(getData);

