import auth from '@/router/middleware/auth';

const ReportModule = r => require.ensure([], () => r(require('./index')), 'report-module-bundle');

export default [
  {
    path: '/report-module',
    name: 'report-module',
    component: ReportModule,
    meta: {
      middleware: [auth]
    }
  }
];
