import { API_URL } from '@/config';
import * as referralService from '@/services/clientReferral';

export function init(Survey,vm,live =false) {
    var testEditWidget={
        name:'history-client-referral-denial',
        title:'Client\'s Past Referral Denials',
        iconName:'',
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'history-client-referral-denial';
        },
        init(){
          Survey.Serializer.addClass('history-client-referral-denial',[],null,'empty');

       
        },
        htmlTemplate:"<div style='padding-left:20px' class='history-client-referral-denial'><div class='responseValue'></div></div>",
        afterRender:function(question,el){

          $(el).css('padding-top',"10px");
     
          if(!live && $(el).closest('#surveyContainer').length>0){
            live=true;
          }

          $(el).find('.responseValue').append("<table style='text-align:top'></table>")

          $(el).find('.responseValue').find('table').append("<tr></tr>");

          $(el).find('.responseValue').find('table').find('tr').append("<th style='width:40%;'></th>");
          $(el).find('.responseValue').find('table').find('tr').append("<th style='width:50%;'><span style='font-weight:bold;color:#000000'>Reasons:</span></div>");
          $(el).find('.responseValue').find('table').find('tr').append("<th><span style='font-weight:bold;color:#000000'>Date Declined:</span></div>");

         if(live || vm.testModel){

                
              if(vm.testModel && question.TestClientNumber){
                

                  referralService.getDeclinedReferrals(null,{ClientNumber:question.TestClientNumber})
                  .then(response =>{
                    $.each(response,function(key,value){
                      
                      $(el).find('.responseValue').find('table').append("<tr></tr>");

     
                      $(el).find('.responseValue').find('table').find('tr').last().append("<td style='vertical-align:top'><span style='font-weight:bold;color:#000000'>"+value.LocationName+"</span></td>");
                      $(el).find('.responseValue').find('table').find('tr').last().append("<td class='reasons'></td>");
                      if(value.Reasons){
                        
                        $.each(value.Reasons,function(key2,value2){
                          $(el).find('.responseValue').find('table').find('tr').last().find('.reasons').append("<div style='margin-bottom:10px' class='tag--primary-variant text--semi-bold text--small'>"+value2+"</div>");

                        });

                      }

                      $(el).find('.responseValue').find('table').find('tr').last().append("<td style='vertical-align:top;font-size:10pt;color:#000000'>"+value.DateDeclined+"</td>");




                    });
                  });

                }else if(vm.$route.params.clientID){

                  if(!question.value){
                    question.value=new Array;
                  }

                  
             

                  referralService.getDeclinedReferrals(vm.$route.params.clientID,{referralIDs:question.value,AssessmentID:vm.assessmentID,AssessmentType:vm.assessmentType})
                  .then(response =>{
                    var historyIDs=new Array();
                    $.each(response,function(key,value){
                      $(el).find('.responseValue').find('table').append("<tr></tr>");

     
                      $(el).find('.responseValue').find('table').find('tr').last().append("<td style='vertical-align:top'><span style='font-weight:bold;color:#000000'>"+value.LocationName+"</span></td>");
                      $(el).find('.responseValue').find('table').find('tr').last().append("<td class='reasons'></td>");
                      if(value.Reasons){
                        
                        $.each(value.Reasons,function(key2,value2){
                          $(el).find('.responseValue').find('table').find('tr').last().find('.reasons').append("<div style='margin-bottom:10px' class='tag--primary-variant text--semi-bold text--small'>"+value2+"</div>");

                        });

                      }

                      $(el).find('.responseValue').find('table').find('tr').last().append("<td style='vertical-align:top;font-size:10pt;color:#000000'>"+value.DateDeclined+"</td>");

                       
                      historyIDs.push(value.ID);


                    });

                   
                      question.value={value:historyIDs,responseData:response};

                  
                  });



                }
        }else{
          $(el).find('.responseValue').append("<div><span style='font-weight:bold;color:#000000'>TEST MODE</span></div>");
    
        }
          
          


        

          
           
        }
      };
    //Register our widget in singleton custom widget collection

    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('history-client-referral-denial')){
        Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget, "customtype");
    }
    
}