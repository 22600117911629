import { API_URL } from '@/config';
import * as staffService from '@/services/staff';

export function init(Survey,vm,live=false) {
    var testEditWidget={
        name:'staff-list-multiple',
        title:'Staff List With Contact (Multiple)',
        iconName:'',
        responseData:{},
        relationshipOptions:{},
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'staff-list-multiple';
        },
        init(){
          Survey.Serializer.addClass('staff-list-multiple',[],null,'empty');

       
        },
        htmlTemplate:"<div style='padding-left:20px' class='staff-list-mul-container'><div class='responseValue'></div><div class='staffListMulBttn btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Add</div></div>",
        afterRender:function(question,el){

          var relationshipOptions=new Array;
          $(el).css('padding-top',"10px");
     
          if(!live && $(el).closest('#surveyContainer').length>0){
            live=true;
          }

          if(question.ButtonText){
            $(el).find('.staffListMulBttn').html(question.ButtonText.replace('<p>','').replace("</p>",""));



          }
          
          if(vm.readOnly){
            $(el).find('.staffListMulBttn').hide();
            
          }
          $(el).find('.staffListMulBttn').click(function(){

            if(live){
              vm.$root.$emit('openStaffListMul',{questionCode:question.code,params:{ClientID:vm.$route.params.clientID,selectedStaff:question.value,defaultRole:question.NewUserRole,tagUser:question.StaffTag,currentUsersSelected:question.value,locationAuditField:question.UserAuditField}});


            }else if(question.TestClientNumber){
             
             
               vm.$root.$emit('openStaffListMul',{questionCode:question.code,params:{ClientNumber:question.TestClientNumber,selectedStaff:question.value,defaultRole:question.NewUserRole,tagUser:question.StaffTag,currentUsersSelected:question.value,locationAuditField:question.UserAuditField}});


            }

          });



          if(question.RelationshipToClient){
            staffService.getRelationshipOptions(question.RelationshipToClient)
            .then(response =>{


              relationshipOptions=response;
              testEditWidget.relationshipOptions[question.code]=responses;


              
            });

          }
       
          vm.$root.$on('updateResponse',(data)=>{

                  
                  
            if($(el).closest('.svd-simulator-main').length>0 || live){
               if(data.questionCode==question.code){
                var responses;
                if(!question.value){
                 responses=new Array;
                }else{

                 ;
                  if(question.value.value){
                    if(!$.isArray(question.value.value)){
                      responses=question.value.value;

                    

                     }else{
                      responses=question.value.value;
                     }



                  }else{
                    if(!$.isArray(question.value)){
                      responses=JSON.parse(question.value);

                     }else{
                        responses=question.value;
                     }

                  }

         
  
  
                }


                


                   if($.inArray(data.value,responses)==-1){
                   

                      if(question.StaffTag){
                        responses.push({'value':data.value,'tag':question.StaffTag});

                      }else{
                        responses.push({'value':data.value});

                      }

                     
     
                       $(el).find('.responseValue').empty();

                       
                       var params=new Object;
                       
                       if(live){
                          params.ClientID=vm.$route.params.clientID;
                       }else if(question.TestClientNumber){
                         params.ClientNumber=question.TestClientNumber;


                       }

                       staffService.getStaffList(responses,{inQuestion:true,params:params})
                       .then(response =>{

     
                              var questionVal=new Array;

                              testEditWidget.responseData[question.code]=response;

                             $.each(response,function(key,value){

                              if(question.StaffTag){
                                  questionVal.push({'value':value.ID,"Relationship":value.Relationship,"tag":question.StaffTag});
                              }else{
                                 questionVal.push({'value':value.ID,"Relationship":value.Relationship});

                              }
                               $(el).find('.responseValue').append("<div id='staffDetails_"+value.ID+"' class='staffDetails' style='width:100%;display:inline-block;padding-top:20px;padding-bottom:20px'></div>");

                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).append("<table style='width:90%;border:0'></table>");
                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr><th colspan='2'>Name:</th><th rowspan='2'><div style='margin-top:10px;background-color:#dc3545;color:#ffffff' data-staffid='"+value.ID+"' class='staffBttnRemove btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Remove</div></th></tr>");
                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr><td style='padding-bottom:10px' colspan='2'>"+value.Name+"</td><td></td></tr>");

                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr></tr>");
                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<th style='width:30%'>Phone #:</th>");
                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<th style='width:30%'>Email:</th>");
                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<th></th>");


                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr></tr>");
                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<td style='width:30%'>"+value.Phone+"</td>");
                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<td style='width:30%'>"+value.Email+"</td>");
                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<td></td>");

                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr></tr>");
                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<th colspan='2'>Relationship To Youth:</th>");

                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr></tr>");

                               var idVal=value.ID;

                               var select = $("<select id='staffRelationshipSelect_"+key+"' class='staffRelationshipSelect form-control'></select>");

                               

                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<td colspan='2'></td>");
                               $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("td").last().append(select);


                               $.each(relationshipOptions,function(index,option){

                                                                                                                                                  
                                $(el).find('.responseValue').find('#staffDetails_'+idVal).find('table').find("td").last().find(".staffRelationshipSelect").append('<option value="'+option.value+'">'+option.text+'</option>');
                              });


                              if(value.Relationship){
                                $(el).find('.responseValue').find('#staffDetails_'+idVal).find('table').find("td").last().find(".staffRelationshipSelect").val(value.Relationship);

                              }

                                  $("#staffRelationshipSelect_"+key).change(function(){


                                       var questionValue=question.value.value;



                                        questionValue[key].Relationship=$(this).val();



                                        question.value={value:questionValue,respnseData:response,relationshipOptions:relationshipOptions};
                                        

                                        
                                  });


                             
                             });
     
                             question.value={value:questionVal,responseData:response,relationshipOptions:relationshipOptions};

     
                       });

                       


                       
                   }
               
               }
             }

      
           
         });

          if(question.value){


            var responses=[];

            
            
            if(question.value.value){
              responses=question.value.value;
            }else{
              responses=JSON.parse(question.value);
            }


            var params=new Object;
                       
            if(live){
               params.ClientID=vm.$route.params.clientID;
            }else if(question.TestClientNumber){
              params.ClientNumber=question.TestClientNumber;


            }


            


            staffService.getStaffList(responses,{inQuestion:true,params:params})
            .then(response =>{
                   testEditWidget.responseData[question.code]=response;

                  $.each(response,function(key,value){
                    $(el).find('.responseValue').append("<div id='staffDetails_"+value.ID+"' class='staffDetails' style='width:100%;display:inline-block;padding-top:20px;padding-bottom:20px'></div>");

                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).append("<table style='width:90%;border:0'></table>");
                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr><th colspan='2'>Name:</th><th rowspan='2'><div style='margin-top:10px;background-color:#dc3545;color:#ffffff' data-staffid='"+value.ID+"' class='staffBttnRemove btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Remove</div></th></tr>");
                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr><td style='padding-bottom:10px' colspan='2'>"+value.Name+"</td><td></td></tr>");

                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr></tr>");
                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<th>Phone #:</th>");
                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<th>Email:</th>");
                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<th></th>");


                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr></tr>");
                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<td>"+value.Phone+"</td>");
                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<td>"+value.Email+"</td>");
                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<td></td>");

                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr></tr>");
                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<th colspan='2'>Relationship To Youth:</th>");

                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr></tr>");


                    var idVal=value.ID;

                    var select = $("<select id='staffRelationshipSelect_"+key+"' class='staffRelationshipSelect form-control'></select>");



                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<td colspan='2'></td>");
                    $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("td").last().append(select);

                    $.each(relationshipOptions,function(index,option){

                                                                                                                                                  
                      $(el).find('.responseValue').find('#staffDetails_'+idVal).find('table').find("td").last().find(".staffRelationshipSelect").append('<option value="'+option.value+'">'+option.text+'</option>');
                    });

                    if(value.Relationship){
                      $(el).find('.responseValue').find('#staffDetails_'+idVal).find('table').find("td").last().find(".staffRelationshipSelect").val(value.Relationship);
                    }


                        $("#staffRelationshipSelect_"+key).change(function(){
                             var questionValue=JSON.parse(question.value);


                              questionValue[key].Relationship=$(this).val();

                              question.value=JSON.stringify(questionValue);

                              question.value={value:questionValue,responseData:response,relationshipOptions:relationshipOptions};

                              
                        });
                  
                  });


                  question.value={value:responses,responseData:response,relationshipOptions:relationshipOptions};





            });
          }

          $(el).on('click','.staffBttnRemove',function(){
            var indexToRemove=null;

            var staffID=$(this).data('staffid');
            
            var responses=[];
            
            if(question.value.value){
              responses=question.value.value;

            }else{
              responses=JSON.parse(question.value);


            }

            $.each(responses,function(key,value){
                  
                   if(value.value){
                    if(parseInt(value.value)==parseInt(staffID)){


                      indexToRemove=key;
                      return;
                    }
                   }else{
                    if(parseInt(value)==parseInt(staffID)){


                      indexToRemove=key;
                      return;
                    }
                   }
               


            });

            if(indexToRemove !==null){
              responses.splice(indexToRemove,1);



              if(responses.length>0){
                var params=new Object;
                       
                  if(live){
                    params.ClientID=vm.$route.params.clientID;
                  }else if(question.TestClientNumber){
                    params.ClientNumber=question.TestClientNumber;


                  }

                  $(el).find('.responseValue').empty();

                  staffService.getStaffList(responses,{inQuestion:true,params:params})
                  .then(response =>{
                        testEditWidget.responseData[question.code]=response;

                        $.each(response,function(key,value){
                          $(el).find('.responseValue').append("<div id='staffDetails_"+value.ID+"' class='staffDetails' style='width:100%;display:inline-block;padding-top:20px;padding-bottom:20px'></div>");

                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).append("<table style='width:90%;border:0;'></table>");
                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr><th colspan='2'>Name:</th><th rowspan='2'><div style='margin-top:10px;background-color:#dc3545;color:#ffffff' data-staffid='"+value.ID+"' class='staffBttnRemove btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Remove</div></th></tr>");
                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr><td style='padding-bottom:10px' colspan='2'>"+value.Name+"</td><td></td></tr>");

                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr></tr>");
                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<th>Phone #:</th>");
                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<th>Email:</th>");
                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<th></th>");


                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr></tr>");
                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<td>"+value.Phone+"</td>");
                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<td>"+value.Email+"</td>");
                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<td></td>");

                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr></tr>");
                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<th colspan='2'>Relationship To Youth:</th>");

                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').append("<tr></tr>");


                          var idVal=value.ID;

                          var select = $("<select id='staffRelationshipSelect_"+key+"' class='staffRelationshipSelect form-control'></select>");



                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("tr").last().append("<td colspan='2'></td>");
                          $(el).find('.responseValue').find('#staffDetails_'+value.ID).find('table').find("td").last().append(select);

                                $.each(relationshipOptions,function(index,option){

                                                                                                                                                              
                                  $(el).find('.responseValue').find('#staffDetails_'+idVal).find('table').find("td").last().find(".staffRelationshipSelect").append('<option value="'+option.value+'">'+option.text+'</option>');
                                });

                                if(value.Relationship){
                                  $(el).find('.responseValue').find('#staffDetails_'+idVal).find('table').find("td").last().find(".staffRelationshipSelect").val(value.Relationship);
                                }

                              $("#staffRelationshipSelect_"+key).change(function(){
                                  var questionValue=JSON.parse(question.value);


                                    questionValue[key].Relationship=$(this).val();

                                    question.value={value: questionValue,responseData:response,relationshipOptions:relationshipOptions};

                                    
                              });
                        
                        });

                        question.value={value:responses,responseData:response,relationshipOptions:relationshipOptions};



                  });
                }else{
                  $(el).find('.responseValue').empty();
                  question.value=null;
                }

              
              

            }
        });
        
          
          
          question.registerFunctionOnPropertyValueChanged("ButtonText",
          function () {

            $(el).find('.staffListMulBttn').html(question.ButtonText.replace("<p>","").replace("</p>",""));
        
          });


        

          
           
        },
        pdfQuestionType: "empty",
        pdfRender: function (survey, options) {
          if (options.question.getType() === "staff-list-multiple") {
            const loc = new Survey.LocalizableString(survey, true);





            if(options.question.value && options.question.value.responseData){
             
            let response=options.question.value.responseData;
            let relationshipOptions=options.question.value.relationshipOptions;
           // let relationshipOptions=options.question.customWidgetValue.widgetJson.relationshipOptions[options.question.code];
            let displayText="";
            $.each(response,function(key,value){
              displayText+="<div class='staffDetails' style='width:100%;display:inline-block;padding-top:20px;padding-bottom:20px;page-break-inside:auto'>";

              displayText+="<table style='width:90%;border:0;font-size:10pt'>";
              displayText+="<tr><th colspan='2' style='text-align:left'>Name:</th></tr>";
              displayText+="<tr><td style='padding-bottom:10px;text-align:left' colspan='2'>"+value.Name+"</td></tr>";

              displayText+="<tr>";
              displayText+="<th  style='text-align:left'>Phone #:</th>";
              displayText+="<th  style='text-align:left'>Email:</th>";
              displayText+="</tr>";

              displayText+="<tr>";
              displayText+="<td style='text-align:left'>"+value.Phone+"</td>";
              displayText+="<td style='text-align:left'>"+value.Email+"</td>";
              displayText+="<tr>";

              displayText+="<tr>";
              displayText+= "<th colspan='2' style='text-align:left'>Relationship To Youth:</th>";
              displayText+="</tr>";

              displayText+="<tr>";


           

              displayText+="<td colspan='2' style='text-align:left'>";


              
                    if(value.Relationship && relationshipOptions){
                          let relationText="";
                          $.each(relationshipOptions,function(key,relValue){
                              if(relValue.value==value.Relationship){
                                relationText=relValue.text;

                                return false;
                              }
                          });

                          displayText+=relationText;

                      
                    }
                    
                    displayText+="</td>";
                
                    displayText+="</tr>";

                  displayText+="</table></div>";
            
            });
             
              loc.text = displayText;



            }else{
              loc.text ="";
            }
            options.question["locHtml"] = loc;


          
              options.question["renderAs"] ='auto';

        
            const flatHtml = options.repository.create(
              survey,
              options.question,
              options.controller,
              "html"
            );
            return new Promise(function (resolve) {
              flatHtml.generateFlats(options.point).then(function (htmlBricks) {
                options.bricks = htmlBricks;
                resolve();
              });
            });
          }
        }
      };
    //Register our widget in singleton custom widget collection

    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('staff-list-multiple')){
        Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget, "customtype");
    }
    
}