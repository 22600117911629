<template>
  <li role="menuitem" v-if="hasLoaded()">
    <b-button block variant="light" v-b-toggle="'active-location-menu'">
      {{ menu.name || '' }}
      <b-icon-chevron-down scale="0.5" />
    </b-button>
    <b-collapse id="active-location-menu">
      <b-alert :show="showError" variant="danger">{{ error }}</b-alert>
      <ul
        class="active-location-menu"
        role="menu"
        v-if="Array.isArray(menu.options) && menu.options.length > 0"
      >
        <li
          v-for="(option, i) in menu.options"
          :key="`aloc-menuitem-${i}`"
          role="menuitem"
        >
          <b-button
            block
            :disabled="!!option.selected"
            :aria-disabled="!!option.selected"
            variant="light"
            @click="onClickOption(option)"
            >{{ option.text }}</b-button
          >
        </li>
      </ul>
    </b-collapse>
  </li>
</template>

<script>
import {
  getActiveLocationMenu,
  submitActiveLocation,
} from '@/services/toolbar';
import { isStringAndNotEmpty } from '@/utils/langUtils';
export default {
  name: 'ActiveLocationMenu',
  components: {},
  data() {
    return {
      error: '',
      menu: {},
      showError: false,
    };
  },
  created: function() {
    this.getMenu();
  },
  methods: {
    getMenu: async function() {
      try {
        const res = await getActiveLocationMenu();
        if (res.error) throw Error(res.message);
        if (Array.isArray(res.options) && res.options.length > 0) {
          this.menu = res;
        } else {
          this.menu = null;
        }
      } catch (error) {
        this.menu = null;
      }
      this.showError = false;
    },
    hasLoaded: function() {
      return this.menu && Array.isArray(this.menu.options);
    },
    submitOption: async function(option) {
      try {
        const res = await submitActiveLocation(option);
        if (res.error) throw Error(res.message);
        window.location.reload();
      } catch (error) {
        // if (isStringAndNotEmpty(error)) this.error = error;
        // else if (isStringAndNotEmpty(error.message)) this.error = error.message;
        // else this.error = JSON.stringify(error);
        // this.showError = true;
        this.menu = null;
      }
    },
    onClickOption: function(option) {
      this.submitOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.active-location-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
