// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('providerAPI/getProvidersTableSettings', payload).then(getData);

export const get = (payload) => http.post('providerAPI/getProviders',payload).then(getData);

export const getUserTags= (payload=null) => http.post("apiUserTag/getUserTags",payload).then(getData);

export const linkUser= (payload=null) => http.post("apiUser/linkUser",payload).then(getData);

export const getCountries= () => http.post("apiCountry/getCountries",{targetCountries:['CA']}).then(getData);

export const getProvinces = (payload=null) => http.post("apiProvince/getProvinces",payload?payload:{targetCountries:['CA']}).then(getData);

export const getProvider = (payload) => http.post("providerAPI/getProvider",payload).then(getData);

export const create= (payload) => http.post("providerAPI/addProvider",payload).then(getData);

export const update= (payload) => http.post("providerAPI/editProvider",payload).then(getData);

export const remove= (payload) => http.post("providerAPI/deleteProvider",payload).then(getData);

export const getProviderTags= (payload) => http.post("apiOrganizationTag/getOrgTags",payload).then(getData);

export const getProviderReviews = (payload) => http.post("providerAPI/getReviews",payload).then(getData);

export const addProviderReview = (payload) => http.post("providerAPI/addProviderReview",payload).then(getData);

export const editProviderReview = (payload) => http.post("providerAPI/editReview",payload).then(getData);

export const deleteProviderReview = (payload) => http.post("providerAPI/deleteProviderReview",payload).then(getData);

export const getProviderReview = (payload) => http.post("api/getProviderReview",payload).then(getData);

export const checkCanEditReviews = () => http.post("apiUser/checkPermission",{permission:'Delete Provider Review'}).then(getData)

export const getProviderGroups = () => http.get("apiNetworkingGroups/getProviderGroups").then(getData);