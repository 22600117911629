<template>
  <div :key="serviceName">
    <Page
      :headerText="!hidePage ? header : ''"
      :isGridField="isGridField"
      :isChild="isChild"
      :breadcrumbs="
        !isChild &&
          (refererPath || referer) && [
            {
              text: refererText ? refererText : referer,
              to: `/${refererPath ? refererPath : referer}`,
            },
            { text: header, to: { path: '#' } },
          ]
      "
    >
      <template v-slot:content>
        <EcenterTable
          :emptyMessage="emptyMessage"
          :inPicker="inPicker"
          :serviceName="serviceName"
          :customServerParams="customParams"
          :customFieldSetName="customFieldSetName"
          :startupCmds="startupCmds"
          :rowID="passedParams && passedParams.rowID?passedParams.rowID:rowID"
          :addDialogID="addDialogID"
          :editDialogID="editDialogID"
          :hideSave="hideSave"
          :hideCancel="hideCancel"
          :saveText="saveText"
          :cancelText="cancelText"
          :isGridField="isGridField"
          ref="ecenterTableInstance"
          :showSelectAll="showSelectAll"
          @onSetTableHeader="setTableHeader($event)"
          @onSetTableReferer="setTableReferer($event)"
          @onRowSelected="$emit('onRowSelected', $event)"
          @applySelectAll="$emit('applySelectAll', $event)"
          @applyDeselectAll="$emit('applyDeselectAll', $event)"
          :selectedUsers="selectedUsers"
          @closeListDialog="closeListDialog"
          @closeAfterAddCustomDialog="closeAfterAddCustomDialogEmitted"
          @auditForShare="shareAuditUriProvided"
          @customActionEvent="customEventEmitted"
          :selectRowServiceName="selectRowServiceName"
          :isSubgrid="isSubgrid"
          :gridControls="gridControls"
        >
          <template
            v-slot:addRowForm="{
              addRowData,
              customFieldCategories,
              formFields,
              formFieldsHasCategories,
              errorMessage,
              defaultTitle,
              refreshTime,
              serverParams,
              component,
              componentParams,
              serviceName,
              addParams
            }"
          >
            <QuestionBuilderForm
              v-if="customForm === 'QuestionBuilderForm'"
              formType="Add"
              :rowData="addRowData"
              :errorMessage="errorMessage"
            />
            <AddEditForm
              v-else
              @fetchChildFormValues="fetchChildFormValues($event)"
              @fetchFormOptions="fetchFormOptions($event)"
              @appendToDefault="appendToDefault($event)"
              @showSelectedField="showSelectedField($event)"
              @updateFacilityStatus="updateFacilityStatus($event)"
              @updateToggleRequired="updateToggleRequired($event)"
              @updateFieldText="changeFieldText($event)"
              @showCategory="showCategoryHandler"
              @refresh-add-edit-root="refreshAddEditForm('Add')"
              @hideSelectedField="hideSelectedFieldHandler($event)"
              @refreshField="refreshFieldHandler($event)"
              @makeFieldRequired="makeFieldRequired($event)"
              formType="Add"
              :serverParams="serverParams"
              :rowData="addRowData"
              :customFieldCategories="customFieldCategories"
              :errorMessage="errorMessage"
              :formFields="formFields"
              :formFieldsHasCategories="formFieldsHasCategories"
              :defaultTitle="defaultTitle"
              :key="refreshTime"
              :component="component"
              :componentParams="componentParams"
              :serviceName="serviceName"
              :addParams="addParams"
            ></AddEditForm>
          </template>

          <template
            v-slot:editRowForm="{
              editRowData,
              customFieldCategories,
              formFields,
              formFieldsHasCategories,
              errorMessage,
              defaultTitle,
              refreshTime,
              serverParams,
            }"
          >
            <QuestionBuilderForm
              v-if="customForm === 'QuestionBuilderForm'"
              formType="Edit"
              :rowData="editRowData"
              :errorMessage="errorMessage"
            />
            <AddEditForm
              v-else
              @fetchChildFormValues="fetchChildFormValues($event)"
              @fetchFormOptions="fetchFormOptions($event)"
              @appendToDefault="appendToDefault($event)"
              @showSelectedField="showSelectedField($event)"
              @updateFacilityStatus="updateFacilityStatus($event)"
              @updateToggleRequired="updateToggleRequired($event)"
              @hideSelectedField="hideSelectedFieldHandler($event)"
              @updateFieldText="changeFieldText($event)"
              @showCategory="showCategoryHandler"
              @makeFieldRequired="makeFieldRequired($event)"

              formType="Edit"
              @refresh-add-edit-root="refreshAddEditForm('Edit', editRowData)"
              :rowData="editRowData"
              :customFieldCategories="customFieldCategories"
              :errorMessage="errorMessage"
              :formFields="formFields"
              :formFieldsHasCategories="formFieldsHasCategories"
              :defaultTitle="defaultTitle"
              :key="refreshTime"
              :serviceName="serviceName"
              :serverParams="serverParams"

            ></AddEditForm>
          </template>

          <template v-slot:rowContent="{ row, listType, gridControls, gridControlUri }">
            <ListItem
              v-if="listType === 'flat' && row && row.columns"
              :item="row"
              :gridControls="gridControls"
              :gridControlUri="gridControlUri"
              @gridControlChanged="gridControlUpdated($event,gridControlUri)"
              :style="inPicker?{padding:0}:{}"

            />
            <DiscussionBoardItem
              v-else-if="listType === 'dboardItem' && row"
              :item="row"
            />
            <DiscussionBoardPostItem
              v-else-if="listType === 'dboardPostItem' && row"
              :item="row"
            />
            <ProviderItem
              v-else-if="listType === 'providerItem' && row"
              :item="row"
            />
            <ProviderReviewItem
              v-else-if="listType === 'providerReviewItem' && row"
              :item="row"
            />
            <b-row v-else-if="listType === 'section'">
              <b-col xl="12">
                <b-button
                  v-b-toggle="`${row.ID}`"
                  class="btn-simple d-flex align-items-center w-100 text-left"
                  variant="light"
                >
                  <i class="fas fa-angle-down mr-3"></i>
                  <ListItem :item="row" />
                </b-button>
                <b-collapse :id="`${row.ID}`" class="pl-3">
                  <div
                    v-for="subGrid in row.subGrid"
                    :key="subGrid.ID"
                    class="border-bottom"
                  >
                    <ListItem :item="subGrid" />
                  </div>
                </b-collapse>
              </b-col>
            </b-row>
            <p v-else>{{ $t("general.loading") }}</p>
          </template>
        </EcenterTable>
      </template>
    </Page>
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
import AddEditForm from './form';
import QuestionBuilderForm from './QuestionBuilderForm';
import Page from '@/components/page.vue';
import EcenterTable from '@/components/ecenter-table/ecenter-table.vue';
import EcenterTableRow from '@/components/ecenter-table/ecenter-table-row';
import ListItem from '@/components/ListItem';
import { getTableHeading } from '@/utils/tableUtils';
import DiscussionBoardItem from '@/components/DiscussionBoardItem.vue';
import DiscussionBoardPostItem from '@/components/DiscussionBoardPostItem.vue';
import ProviderItem from '@/components/ProviderItem.vue';
import ProviderReviewItem from '@/components/ProviderReviewItem.vue';
import { isObject, isStringAndNotEmpty } from '@/utils/langUtils';
import {API_URL} from '@/config';

export default {
  name: 'ListView',
  components: {
    EcenterTable,
    EcenterTableRow,
    AddEditForm,
    Page,
    ListItem,
    QuestionBuilderForm,
    DiscussionBoardItem,
    DiscussionBoardPostItem,
    ProviderItem,
    ProviderReviewItem,
  },
  data() {
    return {
      serviceName: null,
      header: null,
      customFieldSetName: null,
      referer: null,
      refererPath: null,
      baseService: null,
      refererText: null,
      rowIDToUse: null,
      refreshTable: null,
    };
  },
  props: {
    isChild: { type: Boolean, default: false },
    hidePage: { type: Boolean, default: false },
    serviceNameProp: { type: String, default: null },
    customFieldSetNameProp: { type: String, default: null },
    selectedUsers: [Array, String],
    passedParams: {
      type: Object,
      default: function() {
        return {};
      },
    },
    passedServiceName: { type: String, default: null },
    assessmentID: { type: String, default: null },
    assessmentType: { type: String, default: null },
    rowID: { type: String, default: null },
    seriesID: { type: String, default: null },
    addDialogID: { type: String, default: null },
    editDialogID: { type: String, default: null },
    hideSave: { type: Boolean, default: false },
    hideCancel: { type: Boolean, default: false },
    saveText: { type: String, default: 'Save' },
    cancelText: { type: String, default: 'Cancel' },
    selectRowServiceName: { type: String, default: null },
    showSelectAll: { type: Boolean, default: false },
    isSubgrid: { type: Boolean, default: false },
    gridControls: {type: Array, default:function(){return []}},
    inPicker:{type: Boolean, default: false},
    isGridField:{type: Boolean, default: false},
    emptyMessage:{ type: String,default:null},
    addParams:{type: Array,default:function(){ return []}}
  },
  created() {
    this.dataSetup();
  },
  mounted() {
  },
  updated() {
    if (this.baseService != this.$route.params.service) {
      this.dataSetup();
    }
  },
  methods: {
    makeFieldRequired(data){
        this.$refs['ecenterTableInstance'].makeFieldRequiredHandler(data);


    },
    gridSelectedInForm(data){
    },
    shareAuditUriProvided(data){
        this.$emit("shareAuditProvided",data);
    },
    gridControlUpdated(data,uri){

      if(typeof uri !=='undefined' && uri){
        this.$http
        .post(API_URL + `/`+uri, data)
        .then(response =>{
          if(response.data.data.reloadGrid){
            this.$refs['ecenterTableInstance'].reloadTableHandler();
          }

        });
     

      }else{
        this.$emit('gridValueApplied',data);

      }
    },
    dataSetup() {
      const base = this.$route.params.service;
      let serviceName = null;
      if (isStringAndNotEmpty(this.serviceNameProp)) {
        serviceName = this.serviceNameProp;
      } else {
        serviceName = this.passedServiceName
          ? this.passedServiceName
          : (this.isChild
          ? this.$route.params.cservice
          : this.$route.params.service);
      }
      const tableHeading = getTableHeading(
        serviceName,
        this.$route.params.rowID
      );
      if (isStringAndNotEmpty(this.customFieldSetNameProp)) {
        this.customFieldSetName = this.customFieldSetNameProp;
      } else {
        this.customFieldSetName = tableHeading.customFieldSetName;
      }
      this.rowIDToUse = this.rowID ? this.rowID : this.$route.params.rowID;

      this.serviceName = serviceName;
      this.header = tableHeading.header;
      this.refererPath = tableHeading.refererPath;

      if (this.$route.name == 'orgForms' && this.$route.params.service != 'formResults') {
        this.referer = 'administrationDashboard';
        this.refererText = 'Administration';
      } else {
        this.referer = tableHeading.referer;
        this.refererText = tableHeading.refererText
          ? tableHeading.refererText
          : tableHeading.referer;
      }

      this.customParams = {
        rowID: this.rowIDToUse,
        preFilter: this.$route.params.prefilter,
        isOrg: this.$route.params.isorg,
      };

      if (this.passedParams) {
        this.customParams = { ...this.customParams, ...this.passedParams };
      }

      this.customForm = tableHeading.customForm;
      this.startupCmds = this.$route.params.startupCmds || null;
      if (this.baseService != base) this.baseService = base;
    },
    closeAfterAddCustomDialogEmitted(data) {
      this.$emit('closeCustomDialog');
    },
    fetchFormOptions(data) {
      this.$refs['ecenterTableInstance'].fetchFormOptions(data);
    },
    appendToDefault(data) {
      this.$refs['ecenterTableInstance'].appendToDefault(data);
    },
    showCategoryHandler(data) {
      this.$refs['ecenterTableInstance'].showCategoryFunc(data);
    },
    refreshFieldHandler(data) {
      this.$refs['ecenterTableInstance'].refreshField(data);
    },
    hideSelectedFieldHandler(data) {
      this.$refs['ecenterTableInstance'].hideSelectedField(data);
    },
    fetchChildFormValues(data) {
      this.$refs['ecenterTableInstance'].fetchChildFormValues(data);
    },
    showSelectedField(data) {
      this.$refs['ecenterTableInstance'].showSelectedField(data);
    },
  
     changeFieldText(data){
      this.$refs['ecenterTableInstance'].changeFieldText(data);

    },
    deselectAllRows(except) {
      this.$refs['ecenterTableInstance'].deselectRows(except);
    },
    unsetSelectAll(except){
     this.$refs['ecenterTableInstance'].unsetSelectAll(except);

    },
    setTableHeader(tableHeader) {
      this.header = tableHeader ? tableHeader : this.header;
    },
    setTableReferer(tableReferer) {
      this.referer = isObject(tableReferer) && tableReferer.referer ? tableReferer.referer : this.referer;
      this.refererPath = isObject(tableReferer) && tableReferer.refererPath ? tableReferer.refererPath : this.refererPath;
    },
    closeListDialog() {
      this.$emit('closeListDialog-2');
    },
    customEventEmitted(data) {
      if (data.inCustomDialog) {
        this.$emit('customDialogEvent', data);
      } else {
        this.$emit(data.eventName, data.params);
      }
    },
    refreshAddEditForm(type, rowData) {
      if (type == 'Add') {
        this.$refs['ecenterTableInstance'].closeAddRowDialog();
        this.$refs['ecenterTableInstance'].openAddRowDialog();
      } else if (type == 'Edit') {
        this.$refs['ecenterTableInstance'].closeEditRowDialog();
        this.$refs['ecenterTableInstance'].openEditRowDialog(rowData);
      }
    },
    updateFacilityStatus(data){
      this.$refs['ecenterTableInstance'].updateFacilityStatus(data);
    },
    updateToggleRequired(data){
      this.$refs['ecenterTableInstance'].updateToggleRequired(data);
    }
  },
};
</script>
