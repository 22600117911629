<template>
  <ecenterDialog
    v-bind="dialogProps"
    size="xl"
    :showOkBtn="false"
    :noCloseOnBackdrop="true"
    @onClickOk="onClickOk()"
    @onClickCancel="onClickCancel()"
    @onClickMenuBtn="onClickMenuBtn($event)"
    @onError="$emit('onError', $event)"
  >
    <template v-slot:dialogContent>
      <CustomFieldForm
        :errorMessage="errorMessage"
        :formServiceName="formData ? formData.formServiceName : null"
        :serverParams="formData ? formData.serverParams : null"
        :getFormUrl="formData ? formData.getFormUrl : null"
        :getAnswersUrl="formData ? formData.getAnswersUrl : null"
        @onUploadResources="$emit('onUploadResources', $event)"
        @onError="$emit('onError', $event)"
        ref="formInstance"
      />
    </template>
  </ecenterDialog>
</template>
<script>
import ecenterDialog from '@/components/ecenterDialog';
import CustomFieldForm from '@/components/form/CustomFieldForm.vue';
import { validateCfAnswers } from '@/utils/cfUtils';
import cformDialogMixin from '@/mixins/cformDialog.mixin';

export default {
  name: 'CustomFieldFormModal',
  components: {
    CustomFieldForm,
    ecenterDialog,
  },
  mixins: [cformDialogMixin],

  methods: {
    getFormData() {
      const form = this.$refs.formInstance.getCustomCategoriesProp();
      const answers = this.$refs.formInstance.getAnswersProp();
      return { form, answers };
    },
    validateForm: function() {
      const formData = this.getFormData();
      const validation =validateCfAnswers(formData.answers, formData.form);
      if (validation.error) return false;
      return true;
    },
  },
};
</script>
