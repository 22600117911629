// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

const CNTLR_NAME = "apiStaff";

export const getTableSettings = (payload = null) => http.post(`${CNTLR_NAME}/getTableSettings&InWithTagPicker=1`, payload).then(getData);

export const get = (payload = null) => http.post(`${CNTLR_NAME}/getUsers&InWithTagPicker=1`, payload).then(getData);

export const create = (row) => http.post(`${CNTLR_NAME}/createUser&InWithTagPicker=1`, row).then(getData);

export const update = (row) => http.put(`${CNTLR_NAME}/editUser&UserID=` + row.ID, row).then(getData);

export const getRow = (rowID,params) => http.post(`${CNTLR_NAME}/getSelectedUser&UserID=${rowID}`,params).then(getData);

//export const selectAll = (payload) =>http.post(`${CNTLR_NAME}/getLocations&selectAll=1`,payload).then(getData);
