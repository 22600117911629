<template>
  <label :class="labelClass"><span v-if="required" style="color: red"><b>*</b></span>{{label}}</label>
</template>
<script>
export default {
  name: 'Label',
  props: {
    label: { type: String, default: '' },
    labelClass: { type: String, default: '' },
    required: { type: Boolean, default: false }
  }
}
</script>