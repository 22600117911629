import guest from '@/router/middleware/guest';

const callLogForm = r => require.ensure([], () => r(require('./callLogForm')), 'calllog-online-form-bundle');
const formSubmittedPage = r => require.ensure([], () => r(require('./formSubmitted')), 'form-submitted-bundle');
const requestResponse = r => require.ensure([], () => r(require('./RequestResponse')), 'request-response-bundle');
const pbcFile = r => require.ensure([], () => r(require('./PbcFile')), 'pbc-file-bundle');

export default [
    {
      path: '/online-form/:callLogID?',
      name: 'onlineForm',
      component: callLogForm,
      meta: {
        middleware: [guest]
      }
    },
    {
      path: '/submitted-form',
      name: 'submittedSuccessfully',
      component: formSubmittedPage,
      meta: {
        middleware: [guest]
      }
    },
    {
      path: '/req-response/',
      name: 'requestResponse',
      component: requestResponse,
      meta: {
        middleware: [guest]
      }
    },
    {
      path: '/pbc-file/',
      name: 'pbcFile',
      component: pbcFile,
      meta: {
        middleware: [guest]
      }
    },
  ];