<template>
  <div class="container">
    <!-- Outer Row -->
    <div class="row justify-content-center">
      <div class="col-xl-10 col-lg-12 col-md-9">
        <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
            <!-- Nested Row within Card Body -->
            <div class="row">
              <div class="col">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">{{ $t("auth.reset-password.reset") }}</h1>

                    <p>{{ $t("auth.reset-password.requirements") }}</p>
                  </div>
                  <b-form @submit.stop.prevent="submit">
                    <b-form-group id="example-input-group-2" :label="$t('auth.reset-password.new')" label-for="example-input-2">
                      <b-form-input v-model="form.NewPassword" id="NewPassword" name="NewPassword" type="password" required :placeholder="$t('auth.reset-password.new')"></b-form-input>
                    </b-form-group>

                    <b-form-group id="example-input-group-2" :label="$t('auth.reset-password.repeat')" label-for="example-input-2">
                      <b-form-input v-model="form.RepeatNewPassword" id="RepeatNewPassword" name="RepeatNewPassword" type="password" required :placeholder="$t('auth.reset-password.repeat')"></b-form-input>
                    </b-form-group>

                    <div v-show="formError" class="col text-center text-danger">{{ formError }}</div>

                    <b-button variant="dark" class="btn-block" type="submit">{{ $t("general.submit") }}</b-button>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as userService from '@/services/user';

export default {
  data () {
    return {
      form: { UserID: this.$route.params.UserID, ResetKey: this.$route.params.ResetKey },
      formError: ''
    };
  },
  methods: {
    submit () {
      userService.resetPassword(this.form)
        .then(response => {
          if(response.success){
            if (response.fromMobileFlag) {
              this.$router.push({name: 'mobileUserMessage', params: {titleText: 'Password Reset Successfully!', messageText: 'Please go back to the app to login.'}})
            } else {
              this.$router.push({ name: 'loginAfterPasswordReset'} );
            }
          }else{
            this.formError=response.error
          }
        }).catch(error => {
          this.formError=error.error;
        });
    }
  }
};
</script>
