var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.serviceName},[_c('Page',{attrs:{"headerText":!_vm.hidePage ? _vm.header : '',"isGridField":_vm.isGridField,"isChild":_vm.isChild,"breadcrumbs":!_vm.isChild &&
        (_vm.refererPath || _vm.referer) && [
          {
            text: _vm.refererText ? _vm.refererText : _vm.referer,
            to: ("/" + (_vm.refererPath ? _vm.refererPath : _vm.referer)),
          },
          { text: _vm.header, to: { path: '#' } } ]},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('EcenterTable',{ref:"ecenterTableInstance",attrs:{"emptyMessage":_vm.emptyMessage,"inPicker":_vm.inPicker,"serviceName":_vm.serviceName,"customServerParams":_vm.customParams,"customFieldSetName":_vm.customFieldSetName,"startupCmds":_vm.startupCmds,"rowID":_vm.passedParams && _vm.passedParams.rowID?_vm.passedParams.rowID:_vm.rowID,"addDialogID":_vm.addDialogID,"editDialogID":_vm.editDialogID,"hideSave":_vm.hideSave,"hideCancel":_vm.hideCancel,"saveText":_vm.saveText,"cancelText":_vm.cancelText,"isGridField":_vm.isGridField,"showSelectAll":_vm.showSelectAll,"selectedUsers":_vm.selectedUsers,"selectRowServiceName":_vm.selectRowServiceName,"isSubgrid":_vm.isSubgrid,"gridControls":_vm.gridControls},on:{"onSetTableHeader":function($event){return _vm.setTableHeader($event)},"onSetTableReferer":function($event){return _vm.setTableReferer($event)},"onRowSelected":function($event){return _vm.$emit('onRowSelected', $event)},"applySelectAll":function($event){return _vm.$emit('applySelectAll', $event)},"applyDeselectAll":function($event){return _vm.$emit('applyDeselectAll', $event)},"closeListDialog":_vm.closeListDialog,"closeAfterAddCustomDialog":_vm.closeAfterAddCustomDialogEmitted,"auditForShare":_vm.shareAuditUriProvided,"customActionEvent":_vm.customEventEmitted},scopedSlots:_vm._u([{key:"addRowForm",fn:function(ref){
          var addRowData = ref.addRowData;
          var customFieldCategories = ref.customFieldCategories;
          var formFields = ref.formFields;
          var formFieldsHasCategories = ref.formFieldsHasCategories;
          var errorMessage = ref.errorMessage;
          var defaultTitle = ref.defaultTitle;
          var refreshTime = ref.refreshTime;
          var serverParams = ref.serverParams;
          var component = ref.component;
          var componentParams = ref.componentParams;
          var serviceName = ref.serviceName;
          var addParams = ref.addParams;
return [(_vm.customForm === 'QuestionBuilderForm')?_c('QuestionBuilderForm',{attrs:{"formType":"Add","rowData":addRowData,"errorMessage":errorMessage}}):_c('AddEditForm',{key:refreshTime,attrs:{"formType":"Add","serverParams":serverParams,"rowData":addRowData,"customFieldCategories":customFieldCategories,"errorMessage":errorMessage,"formFields":formFields,"formFieldsHasCategories":formFieldsHasCategories,"defaultTitle":defaultTitle,"component":component,"componentParams":componentParams,"serviceName":serviceName,"addParams":addParams},on:{"fetchChildFormValues":function($event){return _vm.fetchChildFormValues($event)},"fetchFormOptions":function($event){return _vm.fetchFormOptions($event)},"appendToDefault":function($event){return _vm.appendToDefault($event)},"showSelectedField":function($event){return _vm.showSelectedField($event)},"updateFacilityStatus":function($event){return _vm.updateFacilityStatus($event)},"updateToggleRequired":function($event){return _vm.updateToggleRequired($event)},"updateFieldText":function($event){return _vm.changeFieldText($event)},"showCategory":_vm.showCategoryHandler,"refresh-add-edit-root":function($event){return _vm.refreshAddEditForm('Add')},"hideSelectedField":function($event){return _vm.hideSelectedFieldHandler($event)},"refreshField":function($event){return _vm.refreshFieldHandler($event)},"makeFieldRequired":function($event){return _vm.makeFieldRequired($event)}}})]}},{key:"editRowForm",fn:function(ref){
          var editRowData = ref.editRowData;
          var customFieldCategories = ref.customFieldCategories;
          var formFields = ref.formFields;
          var formFieldsHasCategories = ref.formFieldsHasCategories;
          var errorMessage = ref.errorMessage;
          var defaultTitle = ref.defaultTitle;
          var refreshTime = ref.refreshTime;
          var serverParams = ref.serverParams;
return [(_vm.customForm === 'QuestionBuilderForm')?_c('QuestionBuilderForm',{attrs:{"formType":"Edit","rowData":editRowData,"errorMessage":errorMessage}}):_c('AddEditForm',{key:refreshTime,attrs:{"formType":"Edit","rowData":editRowData,"customFieldCategories":customFieldCategories,"errorMessage":errorMessage,"formFields":formFields,"formFieldsHasCategories":formFieldsHasCategories,"defaultTitle":defaultTitle,"serviceName":_vm.serviceName,"serverParams":serverParams},on:{"fetchChildFormValues":function($event){return _vm.fetchChildFormValues($event)},"fetchFormOptions":function($event){return _vm.fetchFormOptions($event)},"appendToDefault":function($event){return _vm.appendToDefault($event)},"showSelectedField":function($event){return _vm.showSelectedField($event)},"updateFacilityStatus":function($event){return _vm.updateFacilityStatus($event)},"updateToggleRequired":function($event){return _vm.updateToggleRequired($event)},"hideSelectedField":function($event){return _vm.hideSelectedFieldHandler($event)},"updateFieldText":function($event){return _vm.changeFieldText($event)},"showCategory":_vm.showCategoryHandler,"makeFieldRequired":function($event){return _vm.makeFieldRequired($event)},"refresh-add-edit-root":function($event){return _vm.refreshAddEditForm('Edit', editRowData)}}})]}},{key:"rowContent",fn:function(ref){
          var row = ref.row;
          var listType = ref.listType;
          var gridControls = ref.gridControls;
          var gridControlUri = ref.gridControlUri;
return [(listType === 'flat' && row && row.columns)?_c('ListItem',{style:(_vm.inPicker?{padding:0}:{}),attrs:{"item":row,"gridControls":gridControls,"gridControlUri":gridControlUri},on:{"gridControlChanged":function($event){return _vm.gridControlUpdated($event,gridControlUri)}}}):(listType === 'dboardItem' && row)?_c('DiscussionBoardItem',{attrs:{"item":row}}):(listType === 'dboardPostItem' && row)?_c('DiscussionBoardPostItem',{attrs:{"item":row}}):(listType === 'providerItem' && row)?_c('ProviderItem',{attrs:{"item":row}}):(listType === 'providerReviewItem' && row)?_c('ProviderReviewItem',{attrs:{"item":row}}):(listType === 'section')?_c('b-row',[_c('b-col',{attrs:{"xl":"12"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("" + (row.ID))),expression:"`${row.ID}`"}],staticClass:"btn-simple d-flex align-items-center w-100 text-left",attrs:{"variant":"light"}},[_c('i',{staticClass:"fas fa-angle-down mr-3"}),_c('ListItem',{attrs:{"item":row}})],1),_c('b-collapse',{staticClass:"pl-3",attrs:{"id":("" + (row.ID))}},_vm._l((row.subGrid),function(subGrid){return _c('div',{key:subGrid.ID,staticClass:"border-bottom"},[_c('ListItem',{attrs:{"item":subGrid}})],1)}),0)],1)],1):_c('p',[_vm._v(_vm._s(_vm.$t("general.loading")))])]}}])})]},proxy:true}])}),_c('router-view',{key:_vm.$route.path})],1)}
var staticRenderFns = []

export { render, staticRenderFns }