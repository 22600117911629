var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Page',{attrs:{"headerText":_vm.header,"breadcrumbs":!_vm.isChild &&
        !_vm.linkedModelID && [
          { text: _vm.referer, to: ("/" + _vm.referer) },
          { text: _vm.serviceName, to: { path: '#' } } ]},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.errorMessage)?_c('b-container',[_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e(),_c('b-container',{staticClass:"mb-5"},[_c('CalendarActions',{attrs:{"actions":_vm.actions}})],1),_c('b-row',[_c('b-col',[_c('FullCalendar',{ref:"fullCalendar",attrs:{"options":{
              plugins: [_vm.dayGridPlugin, _vm.timeGridPlugin, _vm.interactionPlugin],
              initialView: 'dayGridMonth',
              dateClick: _vm.onDateClick,
              eventClick: _vm.onEventClick,
              height: '600px',
              events: _vm.onEvents,
              headerToolbar: {
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek',
              },
            }}})],1)],1)]},proxy:true}])}),(_vm.linkedModelID == null && _vm.AssessmentID == null)?_c('router-view',{key:_vm.$route.path,on:{"onCloseModalView":_vm.onCloseModalView}}):_vm._e(),((_vm.linkedModelID || _vm.AssessmentID) && _vm.renderModal)?_c('b-modal',{attrs:{"size":"lg","hide-footer":"","visible":"","no-close-on-backdrop":"","scrollable":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"mb-0 font-weight-bold mr-auto w-75"},[_c('h3',{staticClass:"headerTitle"})]),_c('div',{staticClass:"w-25 text-right"},[_c('b-btn',{staticClass:"ml-2",attrs:{"variant":"danger"},on:{"click":_vm.onCloseModalView}},[_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")])],1)]},proxy:true}],null,false,1123386126)},[_c('div',{staticClass:"container-fluid bg-white"},[_c('ChildListView',_vm._b({on:{"closeListDialog-2":_vm.closeListDialog}},'ChildListView',{
          isChild: true,
          addDialogID: 'add-dialog-calendar',
          editDialogID: 'edit-dialog-calendar',
          passedServiceName: 'event',
          rowID: _vm.rowID,
          passedParams: {
            linkedModelID: _vm.linkedModelID,
            rowID: _vm.rowID,
            AssessmentID: this.AssessmentID,
            AssessmentType: this.AssessmentType,
          },
        },false))],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }