// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiImportOrgDataBatchErrorRow/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiImportOrgDataBatchErrorRow/getRows', payload).then(getData);

export const getRow = (rowID) => http.get(`apiImportOrgDataBatchErrorRow/getRow&RowID=${rowID}`).then(getData);

export const getFormConfig = (rowID,action) => http.post('apiImportOrgDataBatchErrorRow/getFormConfig',{RowID:rowID,Action:action}).then(getData);

export const update = (row) => http.post('apiImportOrgDataBatchErrorRow/editRow', row).then(getData);

export const remove = (row) => http.post('apiImportOrgDataBatchErrorRow/deleteRow', row).then(getData);
