// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const get = (payload) => http.post('apiMentor/getMentorList',payload).then(getData);

export const getUserTags= (payload=null) => http.post("apiUserTag/getUserTags",payload).then(getData);

export const linkUser= (payload=null) => http.post("apiUser/linkUser",payload).then(getData);

export const downloadFile=(payload) =>http.get("apiUserFileFetch/download&fileID="+payload.fileID,{responseType: 'arraybuffer'}).then(getData);

export const getTableSettings = (payload = null) => http.post('apiMentor/getMentorGridTableSettings', payload).then(getData);
