<template>
  <div
    id="formErrorDiv"
    v-if="errorMessage"
    class="col text-center text-danger"
    v-html="errorMessage"
  ></div>
  <p v-else-if="!details">{{ $t("general.loading") }}</p>
  <LinkifyText v-else :htmlTag="'div'" :text="details.text" />
</template>
<script>
import LinkifyText from '@/components/LinkifyText.vue';
import { getErrorMessage, isObject } from '@/utils/langUtils';
import { getData } from '@/utils/get';
export default {
  name: 'ItemDetails',
  components: { LinkifyText },
  props: {
    errorMessage: { type: String, default: null },
    formServiceName: { type: String, default: null },
    getDetailsUrl: { type: String, default: null },
    serverParams: { type: Object, default: null },
  },
  data: function() {
    return {
      details: null,
      formService: null,
    };
  },
  created: function() {
    if (this.formServiceName) {
      this.formService = require(`@/services/${this.formServiceName}`);
    }
    this.fetchDetails();
  },
  methods: {
    getDetailsData: function(payload = null) {
      if (this.formService && this.formService.getItemDetails) {
        return this.formService.getItemDetails(payload);
      } else if (this.getDetailsUrl) {
        return this.$http.post(this.getDetailsUrl, payload).then(getData);
      } else {
        throw Error('Failed to retrieve form data');
      }
    },
    getFetchPayload: function() {
      let payload = {};
      if (this.serverParams) payload = { ...payload, ...this.serverParams };
      return payload;
    },
    fetchDetails: async function() {
      try {
        let details = await this.getDetailsData(this.getFetchPayload());
        if (details.error) {
          throw Error(details.message);
        }
        this.details = details;
        this.$emit('onError', '');
      } catch (error) {
        this.details = {};
        this.onError(error);
      }
    },
    onError: function(error) {
      const message = getErrorMessage(error);
      this.$emit('onError', message);
    },
  },
};
</script>
