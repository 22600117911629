import { userTokenStorageKey } from '@/config';
import * as userService from '@/services/user';
import * as clientService from '@/services/client';
import * as mobileLoginService from '@/services/mobileLandingPage';
import * as locationService from '@/services/locationEHR';
import * as referralService from '@/services/clientReferral';
import * as callLogService from '@/services/callLog'
import * as resultCollaborationService from '@/services/resultsCollaboration'

import { isEmpty } from 'lodash';
import { setToken as httpSetToken } from '@/plugins/http';
import { http } from '@/plugins/http/index';
import vm from '@/main';
import store from '.';

/**
 * @param dispatch
 * @param provider
 * @param data
 * @returns {*}
 */

export const checkForRequestAccount = ({ dispatch, commit},data)=> {
    return userService.checkForRequestAccount()
    .then((response) =>{
        return Promise.resolve(response);
    });
};

export const login = ({ dispatch, commit }, data) => {
  return userService.login(data)
    .then((response) =>{
      

       if(response.success){
           commit("setAuthorized",true);
           commit("setUser", response.user);
           commit('SET_ACCEPT_EULA',response.acceptedEula);
           commit('SetTrainingMode',response.trainingMode);

          //  dispatch('setToken', response.jwt);
          return Promise.resolve(response);
       }else{
        commit("setAuthorized",false);
           return Promise.reject(response);

       }
    });
};

/**
 * User Logout
 *
 * @param dispatch
 * @param commit
 * @returns {Promise.<*[]>}
 */
export const logout = ({ dispatch, commit }) => {
  dispatch('setToken', null);
  commit("setAuthorized",false);
  commit('SetTrainingMode',false);

  commit('logout');
  let logOut= http.get('apiUser/logout')
  .then(response => {

    commit("setAuthorized",false);
     return Promise.resolve(true);
  })
  .catch(error => {
     return Promise.reject(false);
  });


  return logOut;
};

/**
 * Set user in Vuex
 *
 * @param commit
 * @param user
 */
export const setUser = ({ commit }, user) => {
  // Commit the mutations
  commit('setUser', user);

  Promise.resolve(user); // keep promise chain
};

/**
 * Set token into storage
 *
 * @param commit
 * @param payload
 * @returns {Promise.<T>}
 */
export const setToken = ({ commit }, payload) => {
  // prevent if payload is a object
  const token = (isEmpty(payload)) ? null : payload.token || payload;

  // Commit the mutations
  commit('setToken', token);
  httpSetToken(token);
  localStorage.setItem(userTokenStorageKey, token || '');

  return Promise.resolve(token); // keep promise chain
};



/**
 * Check user token
 *
 * @param dispatch
 * @param state
 * @returns {Promise.<T>}
 */
export const checkUserToken = ({ dispatch, commit, state },loggedInCheck=false) => {
  // If the token exists then all validation has already been done
  // if (state.auth.token) {
  //   return Promise.resolve(state.auth.token);
  // }

  /**
   * Token does not exist yet
   * - Recover it from localstorage
   * - Recover also the user, validating the token also
   */
  // const token = localStorage.getItem(userTokenStorageKey);
  // if (isEmpty(token)) {
  //   return Promise.resolve(-1);
  // }
  // if token exists, put it in vuex store 
  // dispatch('setToken', token);
  // commit("setAuthorized",true);
  // return Promise.resolve(1);
  var vm = this;
  let isloggedIn = http.post('apiAuthentication/checkIfLoggedIn').then(response => {
    if(response.data.user){

      if(!loggedInCheck){
             commit("setAuthorized",true);
             commit('SetTrainingMode',response.data.user.trainingMode);

           
             if(response.data.user.hideMyAccount){
               commit('SET_HIDE_MY_ACCOUNT',true);
             }

             if(response.data.user.isClient){
                commit('SET_IS_CLIENT_FLAG',response.data.user.isClient);

             }
            
             if(response.data.user.isUsingLegacy){

              commit('SET_IS_USINGYII', true); 
            }

            if(response.data.user.onlineFormOnly){
              commit('SET_HIDE_MY_ACCOUNT',true);

              commit('HIDE_TOOLBAR', true);

              commit('SET_ONLINE_MODE_ONLY',true);
              commit('SET_HIDE_RELOAD_GRID',true);

        
            }

            if(response.data.user.isUserCollection){
              commit('SET_HIDE_MY_ACCOUNT',true);


              commit('HIDE_TOOLBAR', true);

              commit('SET_IS_USER_COLLECTION',true);
        
            }

            if(response.data.user.sharedGridOnly){
              commit('SET_HIDE_MY_ACCOUNT',true);

              commit('HIDE_TOOLBAR', true);

              commit('SET_SHARED_GRID_ONLY',true);

              commit('SET_HIDE_RELOAD_GRID',true);
        
            }


      }
        return Promise.resolve(response.data.user);

      
    } else {
      commit("setAuthorized",false);
      return Promise.resolve(-1);
    }
  }).catch(error => {
    return Promise.reject(error);
  });
  return isloggedIn;

  // }
  // Put the token in the vuex store
  // return dispatch('setToken', token) // keep promise chain
  //   .then((token) => {
  //     return dispatch('loadUser').then(() => token);
  //   });
};

/**
 * Retrieves updated user information
 * If something goes wrong, the user's token is probably invalid
 */
export const loadUser = async ({ dispatch, state }) => {
  let userData = await userService.me().catch(() => {
    dispatch('setToken', '');
    return Promise.reject(new Error('FAIL_IN_LOAD_USER'));
  });
  dispatch('setUser', userData);
  dispatch('initStore');
};

/**
 * @param commit
 * @param dispatch
 * @returns {Promise<*>}
 */
export const initStore = ({ commit, dispatch }) => {
  return commit('setInitialized');
};
export const setPrimaryStyle = ({ commit }, color) => {
  let style = `background: ${color}`
  commit('SET_PRIMARY_STYLE', style);
}
export const toggleSidebar = ({ commit }) => {
  commit('TOGGLE_SIDEBAR');
}

export const clearState = ({ commit }, states ) => {
  commit('CLEAR_STATE', states);
}

export const clientCollectionLogin = ({ dispatch, commit }, data) => {
    return clientService.collectionLogin(data)
    .then(response =>{
        if(response.success){
            commit("setAuthorized",true);
            commit("setUser", response.user);
            commit('SET_ACCEPT_EULA',true);
            commit('SET_IS_CLIENT_FLAG',true);
            commit('SET_IS_LOGGED_IN_COLLECTION',true);
            

          //  dispatch('setToken', response.jwt);
          return Promise.resolve(response);
        }else{
            commit("setAuthorized",false);
            return Promise.reject(response);

        }
    });


}
export const userCollectionLogin = ({ dispatch, commit }, data) => {
  return userService.collectionLogin(data)
  .then(response =>{
      if(response.success){
          commit("setAuthorized",true);
          commit("setUser", response.user);
          commit('SET_ACCEPT_EULA',true);
          commit('SET_IS_USER_COLLECTION',true);
          commit('HIDE_TOOLBAR', true);
          

        //  dispatch('setToken', response.jwt);
        return Promise.resolve(response);
      }else{
          commit("setAuthorized",false);
          return Promise.reject(response);

      }
  });


}

export const mobileCollectionLogin = ({ commit }, data) => {
  return mobileLoginService.validateLoginKeyForEvent(data)
    .then(response => {
//console.log('actions mobileCollectionLogin response: ', response)
      if (response.success) {
        commit('setAuthorized', true);
        commit('HIDE_NAV', true);
        commit('FROM_MOBILE', true);
        commit('SET_ACCEPT_EULA', true);

        return Promise.resolve(response);
      } else {
        commit("setAuthorized",false);
        return Promise.reject(response);
      }
    })

}
export const locationLogin = ({commit}, data) =>{
  return locationService.locationLogin(data)
  .then(response => {


    if (response.success) {
      commit('setAuthorized', true);
      commit('SET_ACCEPT_EULA', true);
      commit("setUser", response.user);
      commit('HIDE_TOOLBAR', true);
      commit('SET_HIDE_MY_ACCOUNT',true);


      


      return Promise.resolve(response);
    } else {
      commit("setAuthorized",false);
      return Promise.reject(response);
    }
  })
}

export const resultCollaborationLogin = ({commit}, data) =>{
  
  return resultCollaborationService.collaborationLogin(data)
  .then(response => {


    if (response.success) {
      commit('setAuthorized', true);
      commit('SET_ACCEPT_EULA', true);
      commit("setUser", response.user);
      commit('HIDE_TOOLBAR', true);
      commit('SET_HIDE_MY_ACCOUNT',true);



      


      return Promise.resolve(response);
    } else {
      commit("setAuthorized",false);
      return Promise.reject(response);
    }
  })
}

export const referralLogin = ({commit}, data) =>{
  return referralService.referralLogin(data)
  .then(response => {


    if (response.success) {
      commit('setAuthorized', true);
      commit('SET_ACCEPT_EULA', true);
      commit("setUser", response.user);
      commit('HIDE_TOOLBAR', true);
      commit('SET_HIDE_MY_ACCOUNT', true);
      commit('SET_SHARED_GRID_ONLY',true);

      


      return Promise.resolve(response);
    } else {
      commit("setAuthorized",false);
      return Promise.reject(response);
    }
  })
}


export const onlineFormLogin = ({commit}, data) =>{
  return callLogService.onlineFormLogin(data)
  .then(response => {


    if (response.success) {
      commit('setAuthorized', true);
      commit('SET_ACCEPT_EULA', true);
      commit("setUser", response.user);
      commit('HIDE_TOOLBAR', true);
      commit('SET_HIDE_MY_ACCOUNT', true);
      commit('SET_ONLINE_MODE_ONLY',true);
      
      


      return Promise.resolve(response);
    } else {
      commit("setAuthorized",false);
      return Promise.reject(response);
    }
  })
}