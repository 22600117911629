// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiCallLog/getUpdatesTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiCallLog/getUpdates', payload).then(getData);

export const getRow = (rowID) => http.get(`apiCallLog/getUpdate&RowID=${rowID}`).then(getData);

export const create = (row) => http.post('apiCallLog/addCallLogUpdate', row).then(getData);

export const update = (row) => http.post('apiCallLog/editCallLogUpdate&UpdateID=' + row.ID, row).then(getData);

export const remove = (row) => http.post('apiCallLog/deleteCallLogUpdate', row).then(getData);

