// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';
import { copyAndMerge } from '@/utils/langUtils';
import { getPropsFromFiles } from '@/utils/s3ResourceUtils';

const CNTLR_NAME = 'apiLocationUserGroup';
export const getFormHeaderUrl = `${CNTLR_NAME}/getFormHeader`;

export const getTableSettings = (payload = null) =>
  http.post(`${CNTLR_NAME}/getGroupUsersTableSettings`, payload).then(getData);

export const get = (payload = null) =>
  http.post(`${CNTLR_NAME}/getGroupUsers`, payload).then(getData);
