<template>
          <b-card no-body mb="4" class="shadow border-top form__card">
            <b-card-header
              class="py-3 d-flex flex-row align-items-center justify-content-between"
              :header-text-variant="headerTextVariant"
              :header-bg-variant="headerBgVariant"
              :class="headerClass"
            >
              <h6 class="m-0 font-weight-bold">
                <slot name="header"></slot>
              </h6>
              <b-button v-if="isCollapsible" v-b-toggle="toggleId" :variant="headerBgVariant" :class="['btn-simple', headerClass]">
                <b-icon-chevron-down />
              </b-button>
            </b-card-header>
            <b-collapse v-if="isCollapsible" :visible="!collapsed" :id="toggleId">
              <b-card-body>
                <slot name="body"></slot>
              </b-card-body>
            </b-collapse>
            <b-card-body v-else>
              <slot name="body"></slot>
            </b-card-body>
          </b-card>
</template>
<script>
export default {
  name: 'FormCard',
  data() {
    return {
      toggleId: '',
    }
  },
  props: {
    headerTextVariant: {type: String, default: '' },
    headerBgVariant: { type: String, default: '' },
    headerClass: { type: String, default: '' },
    cardIndex: { type: String, default: '' },
    collapsed: { type: Boolean, default: false },
    isCollapsible: { type: Boolean, default: false }
  },
  created() {
    this.generateToggleId();
  },
  methods: {
    generateToggleId() {
      let id = Math.random().toString(16).slice(2);
      if (this.cardIndex) id = this.cardIndex + id;
      if (id.length > 16) id = id.slice(0, 15);
      this.toggleId = id;
    }
  }
}
</script>
<style>
  .form__card {
    margin-bottom: 2rem;
    background: transparent;
  }
</style>