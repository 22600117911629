import guest from '@/router/middleware/guest';
import OptOutForm from './optOutOfSurvey.vue';


export default [
  {
    path: '/optOutUser/:email/:username/:collectionType/:collectionID/:loginKey(.*)',
    name: 'optOutUser',
    component: OptOutForm,
    meta: {
      middleware: [guest]
    }
  }
];
