<template>
  <ecenterDialog
    size="xl"
    :showOkBtn="false"
    :noCloseOnBackdrop="true"
    @onClickCancel="onClickCancel()"
    @onError="$emit('onError', $event)"
  >
    <template v-slot:dialogContent>
      <ItemDetails
        :errorMessage="errorMessage"
        :formServiceName="detailsData ? detailsData.formServiceName : null"
        :serverParams="detailsData ? detailsData.serverParams : null"
        :getDetailsUrl="detailsData ? detailsData.getDetailsUrl : null"
        @onError="$emit('onError', $event)"
      />
    </template>
  </ecenterDialog>
</template>
<script>
import ecenterDialog from '@/components/ecenterDialog';
import ItemDetails from './ItemDetails.vue';

export default {
  name: 'ItemDetailsDialog',
  components: {
    ecenterDialog,
    ItemDetails,
  },
  props: {
    detailsData: { type: Object, default: null },
    errorMessage: { type: String, default: null },
  },
  created: function() {
 
  },
  methods: {
    onClickCancel: function() {
      this.$emit('onClickCancel');
    },
  },
};
</script>
