<template>
  <div v-if="tabs && Array.isArray(tabs) && tabs.length > 0" class="d-flex">
    <div v-for="tab in tabs" :key="`${tab.path}_tab`">
      <router-link :to="`${tab.path}`" v-slot="{ href, navigate, isActive }">
        <div @click="handleClickTab(tab)">
        <b-link
          :active="isActive"
          :href="href"
          @click="navigate"
          :class="[
            'nav__link',
            isActive && 'nav__link--active global-primary__bg',
          ]"
          >{{ tab.name }}</b-link
        >
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { getTabs } from '@/services/toolbar';

export default {
  name: 'ToolbarTabs',
  data() {
    return {
      tabs: [],
    };
  },
  created() {
    getTabs()
      .then(data => {
        this.tabs = data && Array.isArray(data) ? data : [];
        

        if (this.tabs.length > 0 && this.$route.name === null && !this.$store.getters.hideNav) {
          this.$router.push({path: this.tabs[0].path});
        }
      })
      .catch(error => {

        this.tabs = [];
      });
  },
  methods: {
    handleClickTab(tab) {
      this.$emit('selected', tab.path);
    }
  }
};
</script>
