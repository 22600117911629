<template>
  <div class="w-100" v-if="isValidItem()">
    <b-row>
      <b-col>
        <p class="d-block mb-0">
          <span v-if="item.Author" class="font-weight-bold">{{
            item.Author
          }}</span>
          <span v-if="item.Added" class="text-muted text--sm"> - {{ item.Added }}</span>
        </p>
        <div v-if="Array.isArray(item.Services)">
          <span v-for="(service, i) in item.Services" :key="`serviceTag_${i}`" :class="service.cssClass">
            {{ service.text }}
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="Array.isArray(item.Ratings)">
        <div v-for="(rating, i) in item.Ratings" :key="`rating_${i}`">
          <label class="font-weight-bold">{{ rating.text }}</label>
          <b-form-rating
            inline
            size="sm"
            no-border
            variant="warning"
            :value="rating.value"
            readonly
          ></b-form-rating>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p>{{ item.Content }}</p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { API_URL } from '@/config';

export default {
  name: 'ProviderReviewItem',
  data() {
    return {
      apiURL: API_URL,
      showModal: false,
    };
  },
  props: {
    item: [Object, Array],
  },
  methods: {
    isValidItem() {
      const res = this.item && typeof this.item === 'object';
      return res;
    },
  },
  created() {},
};
</script>
