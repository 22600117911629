// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiUser/getUsersTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiUser/getUsers', payload).then(getData);

export const create = (contact) => http.post('apiUser/createUser', contact).then(getData);

export const update = (contact) => http.put('apiUser/editUser&UserID=' + contact.ID, contact).then(getData);

export const remove = (contact) => http.post('apiUser/deleteUser',contact).then(getData);

export const getTags= (payload=null) => http.post('apiUserTag/getContactUserTags',payload).then(getData);

export const selectRow = (payload) => http.put('apiAssessment/attachItemToResultSet', payload).then(getData);

export const getRow = (contactID) => http.get(`apiUser/getSelectedUser&UserID=${contactID}`).then(getData);