<template>
  <b-row class="w-100" v-if="isValidItem()">
    <b-col
      :sm="column.sm || '12'"
      :md="column.md || '6'"
      :xl="column.xl || Math.floor(12 / item.columns.length)"
      v-for="(column, columnIndex) in item.columns"
      :key="`list-item__col${columnIndex}`"
    >
    <div v-if="column.triggerSubgrid && columnIndex>0">
             <b-button  :id="`${columnIndex}_${column.triggerSubgrid.rowID}_collapse_trigger`" v-b-toggle="`${columnIndex}_${column.triggerSubgrid.rowID}_collapse`" 
                  variant="light"
                  class="d-flex align-items-center btn-simple"
                  @click="setExpanded"
                  style="padding-left:0"
                  >
                  <b>
                  {{ column.triggerSubgrid.buttonText }}
                  <b-icon  :icon="chevronState(`${columnIndex}_${column.triggerSubgrid.rowID}_collapse_trigger`)" font-scale="0.8" class="ml-2"
                    />
                  </b>
                </b-button>
                <b-collapse :id="`${columnIndex}_${column.triggerSubgrid.rowID}_collapse`" @show="refreshCollapse" :visible="column.triggerSubgrid.expandRow">
                       <ChildListView :key="refreshTable" v-if="isExpanded || column.triggerSubgrid.expandRow "  v-bind="{isChild:true,isSubgrid:true,hidePage:true,passedServiceName:column.triggerSubgrid.serviceName,rowID:column.triggerSubgrid.rowID}"></ChildListView>
                </b-collapse>

     </div>
     <div v-else-if="column.columnControls">

          <div v-for="(control,controlindex) in column.columnControls" :key="'control_'+columnIndex+'_'+controlindex" style='padding-bottom:20px'>

             <b-check-group
                switches
                 v-model="item.rowControlValues[control.name]"


              >


                <b-form-checkbox
                  v-for="(option, i) in control.options"
                  :key="`${option.text}__${i}`"
                  :value="option.value"
                  @change="onControlChange($event,control.name)"
                >
                  {{ option.text }}
                </b-form-checkbox>
              </b-check-group>
          </div>
          <div
              v-for="(columnLine, columnLineIndex) in column.lines"
              :key="`list-item__cloumnLine${columnLineIndex}`"
              class="m-0"
              :class="columnLine.class || ''"
            >
              
             
              
                
                    
        <span v-if="columnLine.name && columnLine.renderAsHtml" v-html="cleanHtml(columnLine.name)"></span>
        <div v-else-if="Array.isArray(columnLine.group) && columnLine.group.length > 0" :class="columnLine.groupClass">
          <HtmlTagFactory
            v-for="(member, midx) in columnLine.group"
            :key="`line-group-${midx}`"
            :item="member"
            />
        </div>  
    
        <span v-else>{{ columnLine.name || '' }} </span>

        <div style="display:inline-block;padding-left:10px" v-if="columnLine.icon"><b-icon  :icon="columnLine.icon.name" :style="columnLine.icon.style || {}"></b-icon>&nbsp;&nbsp;<span v-if="columnLine.icon.text">{{ columnLine.icon.text }}</span></div>
      </div>


     </div>
      <div v-else
        v-for="(columnLine, columnLineIndex) in column.lines"
        :key="`list-item__cloumnLine${columnLineIndex}`"
        class="m-0"
        :class="columnLine.class || ''"
      >
        <div v-if="columnLine.avatar">
          <Avatar v-if="columnLine.avatar.pathToFetchSignedURL" cssClass="mr-1" :pathToFetchSignedURL="columnLine.avatar.pathToFetchSignedURL" size="3.2rem" />
          <b-avatar v-else size="3.2rem" class="mr-1" :src="`${apiURL}${columnLine.avatar.uri}&dummy=${new Date().getTime()}`" />
          <span>{{ columnLine.name || '' }}</span>
        </div>
        <div v-else-if="columnLine.badges && columnLine.stackBadges" style="padding-bottom:10px">
             <div>{{ columnLine.name || '' }}</div>
             
             
             <div  class="h-100 align-items-center" style="padding-top:5px" v-for="(badge,index) in columnLine.badges" :key="'badge_'+index">

                <b-badge v-if='columnLine.stackBadges' tag="div"  :class="badge.classes?badge.classes+' align-middle':'align-middle' " style="height:auto!important;padding-top:10px;padding-left:5px;padding-right:5px;display:block !important;width:auto; white-space:normal !important" class="align-middle"  :variant="badge.variant || ''" >{{ badge.text }}</b-badge>

            </div>
        </div>
        <div v-else-if="columnLine.button">

            <b-button size="sm" style="padding:10px 3px 10px 3px" @click="lineButtonClicked(columnLine.button.path)" :variant="columnLine.button.variant"> {{ columnLine.button.text }} <b-icon v-if="columnLine.button.icon" :icon="columnLine.button.icon"></b-icon></b-button>
        </div>
        
           <div v-else-if="columnLine.badges" style="padding-bottom:10px">
             <div v-if="columnLine.renderAsCollapse">
                <b-button  :id="`${columnIndex}_${columnLineIndex}_${columnLine.rowID}_collapse_trigger`" v-b-toggle="`${columnIndex}_${columnLineIndex}_${columnLine.rowID}_collapse`" 
                  variant="light"
                  class="d-flex align-items-center btn-simple"
                  @click="refreshCollapse"
                  style="padding-left:0"
                  >
                  <b>
                  {{ columnLine.collapseButtonText }}
                  <b-icon  :icon="chevronState(`${columnIndex}_${columnLineIndex}_${columnLine.rowID}_collapse_trigger`)" font-scale="0.8" class="ml-2"
                    />
                  </b>
                </b-button>
                <b-collapse :id="`${columnIndex}_${columnLineIndex}_${columnLine.rowID}_collapse`" @show="refreshCollapse">
                  <b-row>
                    <b-col>

                        <div v-if="columnLine.badges" style="padding-bottom:10px">
                            <div  class="h-100" style="padding-top:5px;width:auto;max-width:100%;text-align:left">

                              <div v-for="(badge,index) in columnLine.badges" :key="'badge_'+index" :style="{paddingLeft:'5px',paddingRight:'5px',paddingBottom:'5px',marginRight:'5px',marginTop:'5px', width:badge.badgeWidth?badge.badgeWidth:'auto',maxWidth:'100%',height:'auto',whiteSpace:'normal'}">
                                   <b-badge tag="div" style="inline-block" :style="{paddingTop:'10px',paddingLeft:'5px',paddingRight:'5px',paddingBottom:'5px',marginRight:'5px',maxWidth:'100%',whiteSpace:'normal',textAlign:'left',width:'auto',whiteSpace:'normal'}"  :class="badge.classes?badge.classes+' align-middle':'align-middle' "  :variant="badge.variant || ''" >{{ badge.text }}</b-badge>

                              </div>

                          </div>
                        </div>
                        <div v-else>{{ columnLine.name || '' }}</div>
                    </b-col>
                  </b-row>
                  
                </b-collapse>
              
              
             </div> 
      
             <div v-else> 
              
                  <div>{{ columnLine.name || '' }}</div>
                  <div  class="h-100 align-items-center" style="padding-top:5px; width:100%;">
                    <b-badge  v-for="(badge,index) in columnLine.badges" :key="'badge_'+index" tag="div" :style="{height:'30px',paddingTop:'10px',paddingLeft:'5px',paddingRight:'5px',marginRight:'5px',marginTop:'5px', width:badge.badgeWidth?badge.badgeWidth:'auto'}" :class="badge.classes?badge.classes+' align-middle':'align-middle'"  :variant="badge.variant || ''" >{{ badge.text }}</b-badge>

                  </div>
             </div>
        </div>
        <FileThumbnail v-else-if="Array.isArray(columnLine.fileThumbnails) && columnLine.fileThumbnails.length > 0" :fileThumbnails="columnLine.fileThumbnails" />
        <b-link v-else-if="columnLine.path" :to="columnLine.path">{{ columnLine.name || '' }}</b-link>
        <span v-else-if="columnLine.name && columnLine.renderAsHtml" v-html="cleanHtml(columnLine.name)"></span>
        <div v-else-if="Array.isArray(columnLine.group) && columnLine.group.length > 0" :class="columnLine.groupClass">
          <HtmlTagFactory
            v-for="(member, midx) in columnLine.group"
            :key="`line-group-${midx}`"
            :item="member"
            />
        </div>
    
        <span v-else>{{ columnLine.name || '' }} </span>

        <div style="display:inline-block;padding-left:10px" v-if="columnLine.icon"><b-icon  :icon="columnLine.icon.name" :style="columnLine.icon.style || {}"></b-icon>&nbsp;&nbsp;<span v-if="columnLine.icon.text">{{ columnLine.icon.text }}</span></div>
      </div>
    </b-col>
   
  </b-row>
</template>
<script>
import {API_URL} from '@/config';
import Avatar from '@/components/Avatar.vue';
import FileThumbnail from '@/components/FileThumbnail.vue';
import HtmlTagFactory from '@/components/HtmlTagFactory.vue';
import sanitizeHtml from 'sanitize-html'

export default {
  name: 'ListItem',
  components: { Avatar, FileThumbnail, HtmlTagFactory,ChildListView:()=>import('@/app/list-view/index.vue') },
  data() {
    return {
      apiURL: API_URL,
      isExpanded:false,
      refreshTable:0,
      isUpdating:false
    }
  },
  props: {
    item: [Object, Array],
    gridControls:{type: Array, default:function(){return []}},
    inPicker:{type: Boolean, default:false},
    gridControlUri:{type: String, default:null}
  },
  mounted(){
  },
  computed:{
    chevronState(){
          return (elementID,defaultExpanded=false) =>{ 


            if($('#'+elementID).length>0 && $('#'+elementID).hasClass("not-collapsed")){
                  return 'chevron-down';

            }else{
                  return 'chevron-right';
            }
          }

    }
  },
  methods: {
    onControlChange(eventData,name,item){
        let returnObj={};
        returnObj.controlName=name;
        returnObj.ID=this.item.ID;
        returnObj[name]=eventData;

        /*
        if(!item.rowControlValues[name]){
          item.rowControlValues[name]=new Array();
        }

         item.rowControlValues[name].push(eventData);
          */

          this.$emit('gridControlChanged',returnObj)
    },
    lineButtonClicked(buttonPath){
      this.$router.push({path:buttonPath});
      

    },
    cleanHtml(html){
      return  sanitizeHtml(html,{
        allowedTags:sanitizeHtml.defaults.allowedTags,
        allowedAttributes:{...sanitizeHtml.defaults.allowedAttributes,'div':['style'],'span':['style']},
        allowedStyles:{
          'div':{
            'height':[/^[0-9]{1,}px|[0-9]{1,}%/],
            'color':[/^#[0-9a-zA-Z]{1,6}/],
            'text-align':[/^[a-zA-Z]{1,}/],
            'margin-left':[/^[0-9]{1,}px|[0-9]{1,}%/],
            'margin':[/^[0-9]{1,}px|[0-9]{1,}%| auto | [0-9]{1,}/]
          },
          'span':{
            'font-weight':[/^[a-zA-Z]{1,}/],
            'font-size':[/^[0-9]{1,}px|[0-9]{1,}pt/],
            'font-family':[/^(.*?)/]
          }

        }
      });


    
    },
    setExpanded(){

      this.isUpdating=true;
      this.isExpanded=!this.isExpanded;

      this.refreshTable++;
      this.refreshCollapse();

    },
    refreshCollapse(){
      var vm=this;
      setTimeout(function(){
        vm.$forceUpdate();


      },100);
    },
    isValidItem() {
      const res = this.item && typeof this.item === 'object' && Array.isArray(this.item.columns) && this.item.columns.length > 0;
      return res;
    },
   
  },
  mounted(){

    
    if(this.item.columns){
      this.item.columns.forEach((column,columnIndex)=>{
          if(column.triggerSubgrid){
            if(column.triggerSubgrid.expandRow){
                $(`#${columnIndex}_${column.triggerSubgrid.rowID}_collapse_trigger`).addClass('not-collapsed');
                
            }
          }

      });

    }
    
      
         
  },
  updated(){


  }
  
};
</script>
