<template>
  <b-modal
    size="md"
    hide-footer
    visible
    scrollable
    centered
    @hide="$emit('onClose')"
  >
    <template v-slot:modal-header>
      <div class="w-100 text-right">
        <b-btn class="ml-2" variant="danger" @click="$emit('onClose')">
          {{ $t("general.cancel") }}
        </b-btn>
      </div>
    </template>
    <div class="container-fluid bg-white">
      <NetworkingProfileView :serviceName="serviceName" :id="id" />
    </div>
  </b-modal>
</template>
<script>
import NetworkingProfileView from '@/components/NetworkingProfileView';

export default {
  name: 'ProfileModal',
  components: { NetworkingProfileView },
  props: {
    serviceName: { type: String, default: '' },
    id: { type: String, default: null },
  },
};
</script>
