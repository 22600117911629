<template>
  <div
    v-if="Array.isArray(actions) && actions.length > 0"
    class="d-flex justify-content-end"
  >
    <div v-for="(action, i) in actions" :key="`c_action-${i}`" class="mr-1">
      <b-btn
        v-if="action.type === 'LINK'"
        size="md"
        class="mr-1 btn-simple global-primary__bg"
        :to="action.url"
      >
        {{ action.label }}
      </b-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CalendarActions',
  props: {
    actions: null,
  },
};
</script>
