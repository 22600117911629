// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiCollectionSeries/getDeploymentTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiCollectionSeries/getDeployments', payload).then(getData);
export const getRow = (rowID) => http.post('apiCollectionSeries/getDeployment', {ID:rowID}).then(getData);



export const update = (data) => http.post('apiCollectionSeries/editDeployment', data).then(getData);

export const remove = (row) => http.post('apiCollectionSeries/deleteSeries', row).then(getData);




export const create = (payload) => http.post("apiCollectionSeries/createDeployment",payload).then(getData);

export const getFormConfig = (rowID,action,params) => http.post('apiCollectionSeries/getDeploymentFormConfig',{RowID:rowID,Action:action,Params:params}).then(getData);

export const addDynamicRow = (rowID,type,action,params) => http.post('apiCollectionSeries/addLinkedFormRow',{rowID:rowID,AssessmentType:type,Action:action,params:params}).then(getData);

export const getDynamicRows = (rowID,params) => http.post('apiCollectionSeries/getLinkedFormRows',{rowID:rowID,params:params}).then(getData);

export const getLinkedEvent = (payload) => http.post('apiCollectionSeries/getLinkedEvent',payload).then(getData);

export const unlinkFromEvent = (payload) => http.post('apiCollectionSeries/unlinkFromEvent',payload).then(getData);

export const getLinkedForms = (linkedModelID,params) => http.post('apiCollectionSeries/getLinkedForms',{RowID:linkedModelID,params:params}).then(getData);

export const getAvailableFormTypes = (params) => http.post('apiCollectionSeries/getAvailableFormTypes',params).then(getData);

export const getInitialForms = (params) =>http.post('apiCollectionSeries/getDefaultForm',params).then(getData);