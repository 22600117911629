<template>
  <b-row>
    <b-col style="padding-right:0; max-width:80%">
     <b-input-group>
          <b-form-input
            ref="globalSearch"
            v-model="searchTerm"
            @input="searchInputChange"
            debounce="700"
            :placeholder="placeHolderString"
          >
          </b-form-input>
         <b-input-group-append>
          <b-button v-if="searchTerm && searchTerm.length>0" @click="applySearch"><b-icon icon="search"></b-icon>Search</b-button>
         </b-input-group-append>
     </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'EcenterTableSearch',
  props: ['index', 'row', 'placeHolderString',"defaultValue"],
  data() {
    return {
      searchTerm: null,
      defaultCssClasses: 'mb-2',
    };
  },
  mounted() {

    if(this.defaultValue){
        this.searchTerm=this.defaultValue;


    }
  },
  watch: {
    searchTerm: function(newVal, oldVal) {

        if(newVal.length==0){
               this.$emit('ecenterSearch', { searchVal: this.searchTerm });


        }
    },
  },
  computed: {
    cssClasses: function() {
      var cssClasses = this.defaultCssClasses;

      return cssClasses;
    },
  },
  methods: {
    searchInputChange() {},
    applySearch(){
      this.$emit('ecenterSearch', { searchVal: this.searchTerm });
    },
    clearSearch() {
      this.searchTerm = null;
      
    },
  },
  components: {},
};
</script>
