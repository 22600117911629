// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiUpcomingEvent/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiUpcomingEvent/getRows&upcoming=1', payload).then(getData);

export const getEventsByMonth = (date) => http.get(`apiUpcomingEvent/getEventsByMonth&Date=${date}&upcoming=1`).then(getData);

export const getEventsByRange = (startDate, endDate) => http.get(`apiUpcomingEvent/getEventsByRange&StartDate=${startDate}&EndDate=${endDate}&upcoming=1`).then(getData);

export const getUpcomingEventsNum=() => http.get(`apiUpcomingEvent/getUpcomingEventsNum`).then(getData);

export const getLinkedForms = (eventID) =>http.get(`apiEventForms/getFormsForEvent&eventID=${eventID}`).then(getData);
