import { getData } from './get';
import { deepCopy, isObject, stringifyOnlyObjs } from './langUtils';
import { downloadObject as s3DownloadObject } from './s3ResourceUtils';

export const responseHandler = obj => {
  const data = getData(obj);
  if (isObject(data,true) && data.error) throw data.message;
  return data;
};

export const getFormData = (data, fileFields) => {
  const file_map = {};
  const formData = new FormData();
  fileFields = Array.isArray(fileFields) ? fileFields : [];
  for (const field of fileFields) {
    file_map[field] = true;
  }
  for (const key in data) {
    if (file_map[key]) {
      formData.append(`${key}`, data[key]);
    } else {
      formData.append(`${key}`, stringifyOnlyObjs(data[key]));
    }
  }
  return formData;
};

export const fileDownloader = async (data) => {
  if (!isObject(data)) {
    throw new Exception ("Invalid file data format.");
  }
  if (data.fileURL) {
    window.open(data.fileURL, '_blank');
  } else if (data.pathToFetchSignedURL) {
    await s3DownloadObject(data);
  }
}
