// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

/**
 * Send login data and retrieve a new token
 *
 * @param payload
 * @returns {Promise<T>}
 */
export const login = (payload) => http.post('apiAuthentication/authenticateUser', payload).then(getData);

/**
 * Register a new user
 *
 * @param payload
 * @returns {Promise<T>}
 */
export const register = (payload) => http.post('/auth/register', payload).then(getData);

/**
 * Forgot password
 *
 * @param payload
 * @returns {Promise<T>}
 */
export const forgotPassword = (payload) => http.post('apiAuthentication/forgotPassword', payload).then(getData);

/**
 * Reset password
 *
 * @param payload
 * @returns {Promise<T>}
 */
export const resetPassword = (payload) => http.post('apiAuthentication/resetPassword', payload).then(getData);

/**
 * Get current user
 */
export const me = () => http.get('/auth/me').then(getData);

/**
 * Revoke current token
 *
 * @returns {Promise<T>}
 */
export const revokeToken = () => http.post('/auth/token/revoke').then(getData);

/**
 * Get Users ID
 * @returns {Promise<T>}
 */
export const getUserID = () => http.get(`apiUser/getUserID`).then(getData);

/**
 * Get users first and last name
 * @returns {Promise<T>}
 */
export const getUserName = (ID) => http.get(`apiUser/getUserName&ID=${ID}`).then(getData);

/**
 * Get Users' messaging profile for messaging user creation.
 * @returns {Promise<T>}
 */
 export const getMessagingProfile = () => http.get(`apiUser/getMessagingProfile`).then(getData);

 export const checkForRequestAccount = () => http.get(`apiAuthentication/checkRequestAccount`).then(getData);
 export const collectionLogin = (payload) => http.post('apiAuthentication/authenticateUserCollectionLogin', payload).then(getData);

 export const getAlreadyLoggedOutMessage = (payload) => http.post('apiAuthentication/getAlreadyLoggedOutMessage', payload).then(getData);

