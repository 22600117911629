<template>
  <div id="surveyContainer"></div>
</template>

<script>
//import { Survey } from 'survey-vue-ui';

import * as SurveyCore  from "survey-core";

import { init as customDateWidget } from '@/components/customCollectionWidgets/customdatepicker';
import * as fService from '@/services/forms';
import { apiPost } from '@/services/generic';
import { getData } from '@/utils/get';
import { doMarkdown } from './textMarkdown';
import CustomInputFactory from '@/components/customCollectionWidgets/customInputFactory';
import CustomQuestionType from './customQuestionTypes/CustomQuestionType';
import * as surveyJsUtils from '@/utils/surveyjsUtils';
import 'survey-core/defaultV2.min.css'

SurveyCore.StylesManager.applyTheme("defaultV2");

export default {
  name: 'SurveyJsForm',
  props: {
    formServiceName: { type: String, default: null },
    getFormUrl: { type: String, default: null },
    getAnswersUrl: { type: String, default: null },
    serverParams: { type: Object, default: null },
  },
  //components: { Survey },
  data() {
    return {
      formService: null,
      domainList: null,
    };
  },
  created() {
    
    SurveyCore.CustomWidgetCollection.Instance.clear();
    SurveyCore.ComponentCollection.Instance.clear();
    customDateWidget(Survey, this);
    if (this.formServiceName) {
      this.formService = require(`@/services/${this.formServiceName}`);
    }
    fService.getDomainList().then(response => {
      this.domainList = response;
    });
    this.getForm();
    
    
  },
  methods: {
    loadCustomQuestionsTypesFromForm: function(form) {
      
      const widgets = SurveyCore.CustomWidgetCollection.Instance;
      const components = SurveyCore.ComponentCollection.Instance;
      const questions = surveyJsUtils.getAllQuestions(form);
      for (const question of questions) {
        const wProps = question.customWidgetProps;
        const cProps = question.customQuestionProps;
        if (wProps && !widgets.getCustomWidgetByName(wProps.name)) {
         widgets.addCustomWidget(CustomInputFactory(Survey, this, wProps), "customtype");
        } else if (cProps && !components.getCustomQuestionByName(cProps.name)) {
          components.add(CustomQuestionType(cProps));
        }
      }
      
    },
    getInputPropsData: function(payload) {
      
      if (this.formService && this.formService.getInputProps) {
        return this.formService.getInputProps(payload);
      } else if (this.inputPropsUrl) {
        return this.$http.post(this.inputPropsUrl, payload).then(getData);
      } else {
        return {};
      }
      
    },
    getFormAnswersData: function(payload) {
      
      if (this.formService && this.formService.getFormAnswers) {
        return this.formService.getFormAnswers(payload);
      } else if (this.getAnswersUrl) {
        return this.$http.post(this.getAnswersUrl, payload).then(getData);
      } else {
        throw Error('Failed to retrieved form data.');
      }
      
    },
    getFormData: function(payload = null) {
      
      if (this.formService && this.formService.getForm) {
        return this.formService.getForm(payload);
      } else if (this.getFormUrl) {
        return this.$http.post(this.getFormUrl, payload).then(getData);
      } else {
        throw Error('Failed to retrieve form data');
      }
      
    },
    getFormObj: function() {
      
      return this.survey;
      
    },
    getAnswersObj: function() {
      
      return this.survey.data;
      
    },
    getFetchPayload: function() {
      
      const payload = { ...this.serverParams };
      return payload;
      
    },
    getForm: async function() {
      
      try {
        const answers = await this.getFormAnswersData(this.getFetchPayload());
        if (answers.error) throw Error(answers.message);
        let form = await this.getFormData(this.getFetchPayload());
        if (form.error) throw Error(form.message);
        this.loadCustomQuestionsTypesFromForm(form);
        if (this.serverParams) answers['__form'] = this.serverParams['__form'];
        const returnedProperties = await fService.getCustomProperties();
        const propertyDefaults = {};
        for (const elementname in returnedProperties) {
          const propertiesArray = returnedProperties[elementname];
          if (propertiesArray['parent']) {
            if (propertiesArray['properties']['getChoicesUrl']) {
              propertiesArray['properties']['choices'] = (
                obj,
                choicesCallback
              ) => {
                choicesCallback(this.domainList);
              };
            }
            SurveyCore.JsonObject.metaData.addClass(
              elementname,
              [{ ...propertiesArray['properties'] }],
              null,
              'itemvalue'
            );
            const choicesProp = SurveyCore.JsonObject.metaData.findProperty(
              propertiesArray['parent'],
              propertiesArray['property']
            );
            choicesProp.className = elementname;
          } else {
            propertyDefaults[elementname] = {};
            for (const propertyValues of propertiesArray) {
              if (propertyValues['getChoicesUrl']) {
                propertyValues['choices'] = (obj, choicesCallback) => {
                  const res = [];
                  $.ajax({
                    url: propertyValues['getChoicesUrl'],
                    dataType: 'JSON',
                    async: false,
                    success: function(data) {
                      $.each(data, function(key, value) {
                        res.push(value);
                      });
                      choicesCallback(res);
                    },
                  });
                };
              }
              SurveyCore.Serializer.addProperty(elementname, propertyValues);
            }
          }
        }
        this.survey = new SurveyCore.Model(form);
        this.survey.render('surveyContainer',{model: this.survey});

        this.survey.showNavigationButtons = false;
        this.survey.data = answers;
        const matrixCss = { matrix: { root: 'table sv_q_matrix shadow' } };
        this.survey.css = matrixCss;
        this.survey.css.completedPage = 'surveyCompletedPage';
        this.survey.clearInvisibleValues = 'onHidden';
        this.survey.onValueChanged.add((sender, options) => {});
        this.survey.onComplete.add((survey, options) => {
          survey.clear(false, true); //set the state to running, keep the data and go to the first page 
        });
        this.survey.onUpdatePageCssClasses.add((sender, options) => {
          options.cssClasses.page.description += ' pageDescription';
        });
        this.survey.onUpdatePanelCssClasses.add((sender, options) => {
          options.cssClasses.panel.container =
            'card shadow border-top form__card';
          options.cssClasses.panel.title =
            'card-header py-3 d-flex flex-row align-items-center justify-content-between global-primary__bg global-primary__bg--no-hover panelTitle';
          options.cssClasses.panel.description += ' panelDescription';
        });
        this.survey.onUpdateQuestionCssClasses.add((sender, options) => {});
        this.survey.onTextMarkdown.add(doMarkdown);
       this.survey.onValidateQuestion.add(checkStaffRelationship);
        this.survey.onAfterRenderSurvey.add((sender, options) => {
        const pages = options.survey.pages;
        for (let pIdx = 0; pIdx < pages.length; pIdx++) {
             //const questions = pages.questions;
              const questions = this.survey.getAllQuestions();
              for (let qIdx = 0; qIdx < questions.length; qIdx++) {
                const question = questions[qIdx];
                if (question instanceof Survey.QuestionCustom) {
                  if (Array.isArray(question.altchoices))
                    question.questionWrapper.choices = question.altchoices;
                }
                if (question.apiEndpoint) {
                  const endpointPayload = null;
                  questions[qIdx].choices = [];
                  if (question.apiEndpointParams) {
                    const paramsCouples = question.apiEndpointParams.split(',');
                    for (const paramCouple of paramsCouples) {
                      const couplesExploded = paramCouple.split(':');
                      endpointPayload[couplesExploded[0]] = couplesExploded[1];
                    }
                  }
                  apiPost(question.apiEndpoint, endpointPayload)
                    .then(response => {
                      if (response.error) throw Error(response.message);
                      question.choices = response;
                    })
                    .catch(error => this.onError(error));
                }
              }
        }
        });
        this.$emit('onError', '');
      } catch (error) {
        console.error(error);
        this.onError(error);
      }
      
    },
    onError: function(error) {
      
      let message = '';
      if (typeof error === 'string') message = error;
      else if (error.message) message = error.message;
      else message = JSON.stringify(error);
      this.$emit('onError', message);
      
    },
    triggerCompleteLastPage: function() {
      
      return this.survey.completeLastPage();
      
    },
  },
};
</script>
