<template>
  <div id='logoutMessageContainer' v-show="message" style='width:100%'>
    <div v-if="messageVal==1" style="width:70%;margin:0 auto" class="surveyCompletedPage" v-html="message"></div>
    <div v-else style="width:60%;margin:0 auto" class="surveyCompletedPage" v-html="message"></div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import * as userService from '@/services/user';

export default {
  name: 'AlreadyLoggedOut',
  data () {
    return {
        message:null,
        messageVal:null
    };
  },
  created () {
    
        this.$store.commit('SET_IS_ON_COLLECTION_LOGOUT',true);
        
        this.messageVal=sessionStorage.getItem('userCollectionLogoutStatus');
      this.recursiveLogOut();

    
  },
  methods: {
    recursiveLogOut(){
      this.logout().then(response => {
       if(response){
         var vm=this;

          //recursively check to see if actually logged out due to quirk of Yii
          this.$store.dispatch('checkUserToken',true)
          .then(response =>{
              if(response !== -1){
                  this.recursiveLogOut();
              }else{

                 userService.getAlreadyLoggedOutMessage({logoutStatus:sessionStorage.getItem("userCollectionLogoutStatus")})
                 .then(response=>{
                    this.message=response.message;
                 });  
              }
          });
      
       }

      });
    },
    ...mapActions(['logout'])
  }
};
</script>
