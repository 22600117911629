

<template>
 <b-container fluid id='addContainer'>

	<div id="addForm">
		
				
					  <b-card no-body mb="4" class="shadow border-top my-2">
	                <!-- Card Header - Dropdown -->
	                <b-card-header class="py-3 d-flex flex-row align-items-center justify-content-between" :header-bg-variant="$store.state.themeDefaults.cardHeaderVariant" :header-text-variant="$store.state.themeDefaults.cardHeaderTextVariant">
	                    <h6 class="m-0 font-weight-bold">{{ $t("discussion-board.add") }}</h6>
	                </b-card-header>
	                <!-- Card Body -->
	                <b-card-body>
	                 <b-form @submit="submit">
	                	<div id="formErrorDiv" v-show="formError" class="col text-center text-danger">{{ formError }}</div>
	                	
	                	
	                	<b-row>
	                		<b-col md="10">
	                			<b-form-group :label="$t('discussion-board.subject')">
									<b-form-textarea @input="onTextChange" v-model="rowData.Subject" required  :state="validateState('Subject')" rows="3" max-rows="100" aria-describedby="input-1-live-feedback"></b-form-textarea>
		                    		 <b-form-invalid-feedback
									    id="input-1-live-feedback">
										{{ $t("discussion-board.required") }}
									</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
	                	</b-row>
						<b-row>
	                		<b-col md="10">
	                			<b-form-group :label="$t('discussion-board.comment')">
									<b-form-textarea @input="onTextChange" v-model="rowData.InitialPost" required rows="3" max-rows="100"></b-form-textarea>
		                    		
								</b-form-group>
							</b-col>
	                	</b-row>

	    



	     			
	    					<b-modal id="bv-modal-please-wait" hide-header hide-footer>
		   							 <div class="d-block text-center">
		      							<h3>{{ $t('general.wait') }} </h3>
		    						</div>
	   						 </b-modal>
					</b-form>	
	                </b-card-body>
	            </b-card>
				
				
	
	
	</div>
 </b-container>

</template>


<script>

import lodash from "lodash";
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import * as discussionBoardService from "@/services/discussion-board";

export default {
  props:{DiscussionBoardID:String, rowData: Object},

  data() {
      return {
      	form:{},
		formError:null,
		showButtons:false,
	

      }
  },
  mixins:[validationMixin],
  validations: {
    form: {
      Subject: {
        required
	  },
	 
    }
  },
  created() {
	

				if(this.DiscussionBoardID){
					// discussionBoardService.getBoard({
					//  	DiscussionBoardID:this.DiscussionBoardID	
					// })
          //  		    .then(response => {
           		    
					// 	    if(response.success){
					// 				this.form={};

					// 				this.form=response.formData;
								
									

					// 		}else{
					// 				this.formError=response.data.message;

					// 		}
           		    	
           		    		
           		    		
           		    
               				
          //   		 })
          //   		 .catch(error => {
          //      				 console.log(error);
          //   		 });

					}
		

			
				

					
            		 
       			 
		
  },
 
  watch:{
	  form:{
		  deep:true,
		  handler:function(newVal){
			  if(this.formError && newVal.Subject && newVal.InitialPost){
				  this.formError=null;
			  }
			  this.$forceUpdate();

		  }
	  }

  },
  computed:{


	   
      
  },
  methods:{
		validateState(name) {
			const { $dirty, $error } = this.$v.form[name];


			return $dirty ? !$error : null;
		},
  		submit(){
			this.$v.form.$touch();
			if (this.$v.form.$anyError) {
				this.formError="Please correct the errors below";
						var container = $("#addContainer").closest(".modal-body");
						container.scrollTop(0);	



				return;
			}

			if(this.DiscussionBoardID){
				// discussionBoardService.editBoard({formData:this.form,DiscussionBoardID:this.DiscussionBoardID})
				// .then(response=>{
				// 		if(response.success){
				// 			this.$emit('editClose');
				// 		}else{
				// 			this.formError=response.message;
				// 		}
				// }).catch(error=>{
				// 		this.formError="Error saving record";
				// 		console.log(error);

				// });
			}else{
				// discussionBoardService.createBoard(this.form)
				// .then(response=>{
				// 		if(response.success){
				// 			  window.scrollTo(0,0);
				// 			this.$emit('addClose');
				// 		}else{
				// 			this.formError=response.message;
				// 		}
				// }).catch(error=>{
				// 		this.formError="Error saving record";
				// 		console.log(error);

				// });

			}
			
			
  			
		},
		onTextChange(){

		},
		cancel(){

			if(this.DiscussionBoardID){
				

				this.$emit('editClose');
			}else{
				this.$emit('addClose');
			}
			

		},
		
  },
  components: {
  }
}
</script>
