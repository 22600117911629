
<template>
  <div @focusin.stop>
   
         <textarea :id="idVal" class="tinyMCEHolder" v-model="value"></textarea>
  </div>
</template>


<script>
import { BASE_URL } from '@/config';

import { SCRIPT_URL } from '@/config';
import * as fieldService from '@/services/customField.js';

import tinymce from 'tinymce';

 /* Default icons are required for TinyMCE 5.3 or above */
 import 'tinymce/icons/default';

 /* A theme is also required */
 import 'tinymce/themes/silver';

 /* Import the skin */
 import 'tinymce/skins/ui/oxide/skin.css';

 /* Import plugins */
 import 'tinymce/plugins/advlist';
 import 'tinymce/plugins/code';
 import 'tinymce/plugins/emoticons';
 import 'tinymce/plugins/emoticons/js/emojis';
 import 'tinymce/plugins/link';
 import 'tinymce/plugins/lists';
 import 'tinymce/plugins/table';

 import contentUiCss from 'tinymce/skins/ui/oxide/content.css';
 import contentCss from 'tinymce/skins/content/default/content.css';
import { isObject } from '@/utils/langUtils';


       

export default {
  name: 'TinyMCE',
  data() {
    return {
      idVal:null,
      isGenerated:false,
      refreshEditor:0,
      debounceTimer:null,
      changeOccurred:false,
      isTyping:false,
      placeHolders:{}
     
    };
  },
  props: {
    fieldID:{type: String, default:null},
    value: {type: String,default:null},
    rowID:{type: String, default:null},
    serviceName:{type: String, default:null},
    passedFilters:{type:Object, default:function(){return {}}},
    rowData:{type:Object, default:function(){return {}}}

    
  },
  created() {

$(document).on('focusin', function(e) {
        if ($(e.target).closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root, .tox-dialog").length) {
            $('.tox-dialog').css('z-index', '2003');
            e.stopImmediatePropagation();
        }
    });
      


  },
  unmounted(){
    clearInterval(this.debounceTimer);


  },
  beforeDestroy(){
    tinymce.execCommand('mceRemoveEditor', true, this.idVal);
  },
  mounted(){

      //this.idVal=Math.floor(Math.random()*1000)+1;

      let vm=this;

      let randValue=Math.floor(Math.random()*1000)+1;
      this.idVal="tinyMCE_"+this.fieldID+"_"+randValue;
      
      
   
          


     
      fieldService.getTinyMcePlaceholders(this.fieldID)
      .then(response =>{
              vm.placeHolders=response;

             tinymce.init({
                selector: '#'+this.idVal,
                plugins: 'advlist code emoticons link lists table',
                toolbar: "menuPlaceholder | undo redo | fontselect fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote",
                skin: false,
                menubar:"edit insert format tools",
                content_css: false,
                content_style:"body {font-family:Arial; font-size: 10pt;}",
                branding: false,
                setup:function(editor){
                  
                     let l = [];
                    
                    
                    
                    
                    
                    
                    $.each(vm.placeHolders, function(key, data) {
                      
                      vm.renderPlaceholdersRecursive(l, key, data, editor);
                    });
                    
                    if (isObject(vm.placeHolders, true) && Object.keys(vm.placeHolders).length > 0) {
                    editor.ui.registry.addMenuButton('menuPlaceholder', {
                            text: 'Placeholders',
                            fetch: function (callback) {
                                 callback(l);

                            }
                    });
                    }
               

                  editor.on('paste cut',function(){

                      changeOccurred=true;




                  });
                  

                   




                  editor.on('Change',function(e){

                      if(!vm.isTyping){
                         setTimeout(function(){
                              vm.contentChanged(editor.getContent());
                          },700);
                      }

                  });
         
                  
                   editor.on('Keydown', function (e) {
                        vm.isTyping=true;
                         

                    });

                     editor.on('Keyup', function (e) {
                        vm.isTyping=false;
                        
                              vm.contentChanged(editor.getContent());

                    });


                      editor.on('Remove', function (e) {
                          editor.destroy();




                      });
                   

                }
              });


                  //vm.generateEditor();


      });

   
       

     
     


  },

  updated(){

      
  },
  methods: {
    renderPlaceholdersRecursive(l, placeholderkey, placeholderdata, editor,index) {
        let vm=this;
        
        if(typeof index==='undefined'){
          index=0;
        }
        
        
        if (typeof placeholderdata=="object") {		
          var m = [];
          
          $.each(placeholderdata, function(key, data) {
            
            vm.renderPlaceholdersRecursive(m, key, data, editor,index);
            index++;
          });
          
          l.push({
                  text: placeholderkey,
                  type: 'nestedmenuitem',
                  getSubmenuItems: function () {
                      return m;

                  }

              });
          
          
          

        }
        else if(placeholderdata.indexOf("|")>-1){
          l.push({
            text:"|"
          });
        }
        else {
          
            l.push({
               type: 'menuitem',
                text: placeholderkey,
                onAction: function () {
                  var id="placeholder_"+index;
                      
                      var placeHolderAsHTML=$.parseHTML(placeholderdata);
                      $(placeHolderAsHTML).attr('id',id);
                      
                      if($(placeHolderAsHTML).is('div') || $(placeHolderAsHTML).is('span')){
                        if($(placeHolderAsHTML).hasClass('reportWidget')){
                          //$(placeHolderAsHTML).wrap("<table><tr><td></tr></td></table>");
                          
                          
                          editor.insertContent(placeHolderAsHTML[0].outerHTML);
                        }else{
                          editor.insertContent(placeHolderAsHTML[0].outerHTML);
                        }
                      
                      }else if($(placeHolderAsHTML).is('table') || $(placeHolderAsHTML).is('p') ||  $(placeHolderAsHTML).is('img') ){
                        editor.insertContent(placeHolderAsHTML[0].outerHTML);

                      }else{
                        editor.insertContent(placeHolderAsHTML[0].textContent);
                      }

                      vm.changeOccurred=true;
                      vm.contentChanged(editor.getContent());

                }
                   
                  
          });
          
          
        }
      },
    contentChanged(content){

                

                      
                            this.$emit('input',content);
                      
                        


    },

    generateEditor(){

          


        
           let vm=this;

   
/*
         $('#tinyMCEHolder').tinymce({
              //script_url :BASE_URL+'/tinymce/js/tinymce/tinymce.min.js',
              setup:function(editor){


             
                let l = [];
                    
                    
                    
                    
                    
                    
                    $.each(vm.placeHolders, function(key, data) {
                      
                      vm.renderPlaceholdersRecursive(l, key, data, editor);
                    });
                    
                    console.log(l);

                    editor.addButton('placeholders', {
                      type:'menubutton',
                            text: 'Placeholders',
                        icon: false,
                        menu: l
                        });

                
               

                  editor.on('paste cut',function(){

                      changeOccurred=true;




                  });
                  

                
                 editor.on('keyup',function(){

                     vm.isTyping=false;



                  });


                 editor.on('keydown',function(){

                      vm.changeOccurred=true;
                      vm.isTyping=true;


                      if(!vm.debounceTimer){
                        vm.debounceTimer=setInterval(function(){
                          vm.contentChanged(editor.getContent());
                        },700);
                      }





                  });
                  

              },
              init_instance_callback: function (editor) {

                
              },
              onchange_callback(editor){
              },
              theme : "modern",
              extended_valid_elements : "div[*] script[*]",
              	plugins: [
                  "save advlist autolink autosave link image lists charmap print preview hr anchor pagebreak spellchecker",
                  "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
                  "table contextmenu directionality emoticons template textcolor paste fullpage textcolor"
                ],
                toolbar1: "placeholders | undo redo | fontselect fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote",
          


           });

           

*/


        

           

          
   
  },
}}
</script>
