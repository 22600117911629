<template>
  <div>
    <Page
      :headerText="header"
      :breadcrumbs="
        !isChild &&
          !linkedModelID && [
            { text: referer, to: `/${referer}` },
            { text: serviceName, to: { path: '#' } },
          ]
      "
    >
      <template v-slot:content>
        <b-container v-if="errorMessage">
          <p class="text-danger">{{ errorMessage }}</p>
        </b-container>
        <b-container class="mb-5">
          <CalendarActions :actions="actions" />
        </b-container>
        <b-row>
          <b-col>
            <FullCalendar
              ref="fullCalendar"
              :options="{
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                dateClick: onDateClick,
                eventClick: onEventClick,
                height: '600px',
                events: onEvents,
                headerToolbar: {
                  left: 'prev,next',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek',
                },
              }"
            />
          </b-col>
        </b-row>
      </template>
    </Page>
    <router-view
      v-if="linkedModelID == null && AssessmentID == null"
      :key="$route.path"
      @onCloseModalView="onCloseModalView"
    ></router-view>
    <b-modal
      v-if="(linkedModelID || AssessmentID) && renderModal"
      size="lg"
      hide-footer
      visible
      no-close-on-backdrop
      scrollable
    >
      <template v-slot:modal-header>
        <div class="mb-0 font-weight-bold mr-auto w-75">
          <h3 class="headerTitle"></h3>
        </div>

        <div class="w-25 text-right">
          <b-btn class="ml-2" variant="danger" @click="onCloseModalView">
            {{ $t('general.cancel') }}
          </b-btn>
        </div>
      </template>
      <div class="container-fluid bg-white">
      
        <ChildListView
          v-bind="{
            isChild: true,
            addDialogID: 'add-dialog-calendar',
            editDialogID: 'edit-dialog-calendar',
            passedServiceName: 'event',
            rowID: rowID,
            passedParams: {
              linkedModelID: linkedModelID,
              rowID: rowID,
              AssessmentID: this.AssessmentID,
              AssessmentType: this.AssessmentType,
            },
          }"
          @closeListDialog-2="closeListDialog"
        ></ChildListView>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Page from '@/components/page.vue';
import CalendarActions from './CalendarActions.vue';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { getCalendarSettings, getEventsByRange } from '@/services/event';
import moment from 'moment';
import { isStringAndNotEmpty } from '@/utils/langUtils';

export default {
  name: 'Calendar',
  components: {
    CalendarActions,
    FullCalendar,
    Page,
    ChildListView: () => import('@/app/list-view/index.vue'),
  },
  data() {
    return {
      serviceName: 'event',
      header: 'Events',
      customFieldSetName: null,
      referer: 'organization',
      dayGridPlugin: dayGridPlugin,
      timeGridPlugin: timeGridPlugin,
      interactionPlugin: interactionPlugin,
      renderModal: false,
      rowID: null,
      errorMessage: '',
      actions: null,
      goToDateKey: null,
    };
  },

  methods: {
    getSettings() {
      getCalendarSettings()
        .then(res => {
          if (res.error) throw Error(res.message);
          else {
            const settings = res.data || {};
            if (settings.header) this.header = settings.header;
            if (Array.isArray(settings.actions))
              this.actions = settings.actions;
            this.errorMessage = '';
          }
        })
        .catch(error => {
          if (isStringAndNotEmpty(error)) this.errorMessage = error;
          else if (error.message) this.errorMessage = error.message;
          else this.errorMessage = JSON.stringify(error);
        });
    },
    onDateClick(date) {
      const dateStr = moment(date.date).format('YYYY-MM-DD');
      this.openTableModal(dateStr);
    },
    onEventClick(info) {
      const dateStr = moment(info.event.start).format('YYYY-MM-DD');
      this.openTableModal(dateStr);
    },
    onDateSet(dateInfo) {
      const dateStr = moment(dateInfo.start);
      this.getEvents(dateStr);
    },
    onEvents(fetchInfo, successCallback, failureCallback) {
      const startDate = moment(fetchInfo.start).format('YYYY-MM-DD');
      const endDate = moment(fetchInfo.end).format('YYYY-MM-DD');
      getEventsByRange(startDate, endDate)
        .then(response => {
          if (Array.isArray(response.rows)) {
            successCallback(response.rows);
          } else {
            failureCallback('Events parsing error');
          }
        })
        .catch(error => {
          failureCallback(error);
        });
    },
    openTableModal(dateStr) {

      
      if (this.linkedModelID || this.AssessmentID) {
        this.rowID = dateStr;
        this.renderModal = true;
      } else {
        this.$router.push({
          name: 'CalendarModalView',
          params: {
            rowID: dateStr,
            componentName: 'ListView',
            cservice: 'event',
          },
        });
      }
    },
    onCloseModalView() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.refetchEvents();
      this.renderModal = false;
    },
    closeListDialog() {
      this.renderModal = false;
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.refetchEvents();
      this.$emit('close-calendar-dialog-3');
    },
  },
  props: {
    isChild: { type: Boolean, default: false },
    linkedModelID: { type: String, default: null },
    AssessmentID: { type: String, default: null },
    AssessmentType: { type: String, default: null },
    service: { type: String, default: null },
  },
  created() {
    if (getCalendarSettings) {
      this.getSettings();
    }
  },
  mounted() {
    if (this.linkedModelID || this.AssessmentID) {
      this.header = null;
    } else {
      this.$store.commit('RELAUNCH_DEPLOYMENT', false);
    }
  },
  updated() {
    const goToDate = this.$route.params && this.$route.params.goToDate;
    const goToDateKey = this.$route.params && this.$route.params.goToDateKey;
    if (goToDate && goToDateKey && this.goToDateKey !== goToDateKey) {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.gotoDate(goToDate);
      calendarApi.refetchEvents();
      this.goToDateKey = goToDateKey;
    }
  },
};
</script>
