<template>
  <b-modal id='form-collaboration-modal' size="xl" hide-footer  @close="closeClicked" no-close-on-backdrop >
    <template v-slot:modal-title>{{ $t("FormCollaborationModal.modify") }}</template>
    <FormCollaboration :AssessmentID="AssessmentID">

    </FormCollaboration>
  </b-modal>
</template>
<script>
import * as formService from '@/services/forms'
import * as staffService from '@/services/staff'
import FormCollaboration from '@/components/FormCollaboration.vue'

import { API_URL } from '@/config';

export default {
  name: 'FormCollaborationModal',
  data() {
    return {
     
    };
  },
  components:{
    FormCollaboration
  },
 
  methods: {
    closeClicked(){
      this.$emit('collaboratorsClosed');
    }
   

  },
  props:['AssessmentID']
};
</script>
