<template>
  <b-dropdown
    variant="link"
    :toggle-class="
      'text-decoration-none font-weight-bold ' + toggleButtonTextVariant()
    "
    id="dropdown-form"
    :text="filter.label"
    ref="dropdown"
    class="my-1 filter-dropdown-button"
    menu-class="filterMenu"
  >
    <b-dropdown-group>
      <b-dropdown-text class="text-small">
        <small class="font-weight-bold">{{ filter.label }}</small>
      </b-dropdown-text>

      <b-dropdown-divider></b-dropdown-divider>

      <b-dropdown-text
        ><b-form-input
          v-model="filter.selected"
          style="width:auto"
          debounce="700"
        ></b-form-input
      ></b-dropdown-text>
    </b-dropdown-group>
  </b-dropdown>
</template>
<style>
.filterMenu {
  width: auto !important;
  max-width: auto !important;
  white-space: nowrap;
}
</style>
<script>
export default {
  name: 'EcenterTableFilterSelect',
  props: ['filter', 'filterOptions'],
  data() {
    return {};
  },
  mounted() {
    this.filter.selected=null;
  },
  computed: {},
  watch:{
    filter:{
      deep:true,
      handler(val){
        this.filterChange();
      }

    }

  },
  methods: {
    setSelectedFilter(value){
          this.filter.selected=value;
           this.$emit('filterChange');
             this.$forceUpdate();

    },
    clearFilter() {
      this.filter.selected = null;
    },
    toggleButtonTextVariant() {
      return (
        'text-' +
        this.$store.state.themeDefaults.eCenterTableFilterRowTextVariant
      );
    },
    filterChange() {
      if (this.$store.state.clearingFilters) return;
      if (this.$store.state.canEmitEvent) {
        this.$emit('filterChange');
      }
    },
    getSelectedFilters() {
      var filter = {};

      filter.name = this.filter.name;

      filter.selected = this.filter.selected;
      return filter;
    },
  },
};
</script>
