<template>
  <div>
    <Page v-bind:headerText="headerText" v-bind:breadcrumbs="breadcrumbs">
      <template v-slot:content>
        <EcenterTable 
          v-bind:serviceName="serviceName"
          v-bind:defaultRow="defaultAdd"
           v-bind:validationSettings="fieldValidations"
           :key="refreshTime"
           v-bind:createdByUserCanEdit="true"
        >

         
          <template v-slot:addRowForm="{ addRowData, errorMessage, refreshTime, validator }">
            <AddEditForm v-bind:rowData="addRowData" v-bind:errorMessage="errorMessage" v-bind:validator="validator" :key="refreshTime"></AddEditForm>
          </template>

          <template v-slot:editRowForm="{ editRowData,  errorMessage, refreshTime, validator }">
            <AddEditForm v-bind:rowData="editRowData" v-bind:errorMessage="errorMessage" v-bind:validator="validator" :key="refreshTime"></AddEditForm>
          </template>

          <template v-slot:rowContent="{ row }">
            <b-row>
              <b-col cols="3">
                <div>
                  <h5 class="font-weight-bold">{{ row.Name }}</h5>
                    <div v-if="row.Address" >{{ row.Address.StreetAddress }}</div>
                    <div v-if="row.Address">{{ row.Address.City }}, {{ row.Address.Province }} {{ row.Address.Postal }}</div>
                    <div v-if="row.Address">{{ row.Address.Country }}</div>
                    <div v-if="row.Contact && row.Contact.Phone" v-html="$t('mentorship.phone', {Phone:row.Contact.Phone})"></div>
                    <div v-if="row.Contact && row.Contact.Fax" v-html="$t('mentorship.ProviderGrid.fax', {Fax:row.Contact.Fax})"></div>
                    <div v-if="row.Contact && row.Contact.Email">{{ $t("mentorship.ProviderGridCopy.email") }} <a :href="'mailto:'+row.Contact.Email">{{ row.Contact.Email }}</a></div>
                    <div v-if="row.Contact && row.Contact.Website">{{ $t("mentorship.ProviderGridCopy.website") }} <a target="_blank" :href="row.Contact.Website">{{ row.Contact.WebsiteDisplay }}</a></div>
                    <div v-if="row.ProviderGroupNames">{{row.ProviderGroupNames}}</div>
                </div>
              </b-col>
              <b-col cols="8" class="text-left">
              <div style="width:100%;padding-bottom:20px">
                <h5>{{ $t("mentorship.ProviderGrid.ratings") }}<b-button size="md" variant="link"  @click="openReviewModel(row.ID)">{{ $t("mentorship.ProviderGrid.add") }}</b-button></h5>
                   <b-modal 
                                                                    size="xl"  
                                                                    :id="'review-dialog-'+row.ID" 
                                                                    hide-footer 
                                                                    scrollable 
                                                                    modal-class="fullHeight"
                                                                    dialog-class="fullHeight"
                                                                
                                                                >
                                                                  <template v-slot:modal-header>
                                                                    <div class="w-75 text-left" style="display:inline-block;padding-left:20px">
                                                                      <h3>{{ row.Name }} </h3>
                                                                    </div>
                                                                    
                                                                  </template>
                                                                  
                                                                  <div class="container-fluid bg-white">
                                                                    <ProviderReviews @close-add-only="closeReviewModal(row.ID)" :ProviderID="row.ID" :addOnly="true"></ProviderReviews>
                                                                  </div>
                                                                </b-modal>
               <vue-good-table v-if="row.Rating"
                
                                  :columns="averageRatingFields"
                                  :rows="[{ServicesRating:row.Rating.avgServiceRating,RespectsFN:row.Rating.avgRespectData}]"
                                  styleClass="vgt-table condensed"
                          >
                                                        
                           <template slot="table-row" slot-scope="props">
                             <div style="width:60%">
                                <b-form-rating variant="warning" :value="props.formattedRow[props.column.field]" readonly no-border></b-form-rating>
                            </div>
                           </template>
               </vue-good-table>
              </div>
               <h5 v-if="row.services && row.services.length>0">{{ $t("mentorship.ProviderGrid.services") }}</h5>
                <div v-for="service in row.services" :key="service.TagID" style="padding-bottom:10px">
                    <h5><b-badge variant="info">{{ getOptionName(services,service) }}</b-badge></h5>
                    <b-row>
                      <b-col cols="12" style='padding:0'>
                          <vue-good-table
                
                                  :columns="reviewFields"
                                  :rows="[{Summary:service.RatingBreakDown,LastReview:service.mostRecent}]"
                                  styleClass="vgt-table condensed"
                          >
                                                        
                           <template slot="table-row" slot-scope="props">
                              
                                <div v-if="props.column.field=='Summary'" style="margin-bottom:10px">
                                     <p v-html="$t('mentorship.ProviderGrid.total', {totalReviews:service.totalReviews})"></p>
                                    <div v-for="Summary in props.row.Summary" :key="Summary.Val">
                                             <div style="display:inline-block;width:15%">
                                                  <span>{{ Summary.Val}}<b-icon icon="star-fill" variant="warning"></b-icon></span>
                                             </div>
                                              <div style="display:inline-block;width:70%">
                                                <b-progress  :value="Summary.totalReviews" :max="service.totalReviews"  :key="Summary.Val" variant="warning"></b-progress>
                                              </div>
                                                 <div v-if="Summary.totalReviews>0" style="display:inline-block;text-align:center;width:15%">
                                                              
                                                              <b-button variant="link" @click="openReviewModel(row.ID,service.TagID,Summary.Val)">
                                                                {{ Summary.totalReviews }}
                                                              </b-button>
                                                                <b-modal 
                                                                    size="xl"  
                                                                    :id="'review-dialog-'+row.ID+'-'+service.TagID+'-'+Summary.Val" 
                                                                    hide-footer 
                                                                    scrollable 
                                                                    modal-class="fullHeight"
                                                                    dialog-class="fullHeight"
                                                                
                                                                >
                                                                  <template v-slot:modal-header>
                                                                    <div class="w-75 text-left" style="display:inline-block;padding-left:20px">
                                                                      <h3>{{ row.Name }} <b-badge variant="info">{{ getOptionName(services,service) }}</b-badge></h3>
                                                                    </div>
                                                                    <div class="w-25 text-right" style="display:inline-block">
                                                                      <b-button class="ml-2" variant="danger" @click="closeReviewModal(row.ID,service.TagID,Summary.Val)">{{ $t("general.close") }}</b-button>
                                                                    </div>
                                                                  </template>
                                                                  
                                                                  <div class="container-fluid bg-white">
                                                                    <ProviderReviews :ProviderID="row.ID" :ServiceTagID="service.TagID" :RatingVal="Summary.Val"></ProviderReviews>
                                                                  </div>
                                                                </b-modal>
                                                 </div>
                                                 <div v-else style="display:inline-block;text-align:center;width:15%">
                                                   {{ Summary.totalReviews }}
                                                 </div>
                                    </div>
                                    <b-button variant="link" @click="openReviewModel(row.ID,service.TagID)">
                                      {{ $t("mentorship.ProviderGrid.view") }}
                                    </b-button>
                                       <b-modal 
                                          size="xl"  
                                          :id="'review-dialog-'+row.ID+'-'+service.TagID" 
                                          hide-footer 
                                          scrollable 
                                          modal-class="fullHeight"
                                          dialog-class="fullHeight"
                                      
                                      >
                                        <template v-slot:modal-header>
                                          <div class="w-75 text-left" style="display:inline-block;padding-left:20px">
                                            <h3>{{ row.Name }} <b-badge variant="info">{{ getOptionName(services,service) }}</b-badge></h3>
                                          </div>
                                          <div class="w-25 text-right" style="display:inline-block">
                                            <b-button class="ml-2" variant="danger" @click="closeReviewModal(row.ID,service.TagID)">{{ $t("general.close") }}</b-button>
                                          </div>
                                        </template>
                                        
                                        <div class="container-fluid bg-white">
                                          <ProviderReviews :ProviderID="row.ID" :ServiceTagID="service.TagID"></ProviderReviews>
                                        </div>
                                      </b-modal>
                                </div>
                                <div v-else>
                                   <div v-if="service.mostRecent ">
                                     <p style="margin-bottom:0;font-size:14px" v-html="$t('mentorship.ProviderGrid.submit', {SubmittedBy:row.LastReview.SubmittedBy,SubmittedDate:props.row.LastReview.SubmittedDate})"></p>
                                     <table style="border:none">
                                       <tr>
                                         <td style="padding-left:0;border:none">
                                            <p style="margin-top:0;font-size:14px;margin-bottom:3px">{{ $t("mentorship.ProviderGrid.service") }}<b-form-rating style="width:5%" size="sm" inline no-border readonly variant="warning" :value="props.row.LastReview.Rating"></b-form-rating> </p>
                                         </td>
                                         <td style="border:none">
                                                <p style="margin-top:0;font-size:14px;margin-bottom:3px"> {{ $t("mentorship.ProviderGrid.works") }}<b-form-rating style="width:5%" size="sm" inline no-border readonly variant="warning" :value="props.row.LastReview.RespectRating"></b-form-rating></p>
                                         </td>
                                       </tr>
                                     </table>
                                     <hr>
                                     <div style="font-size:14px">
                                       {{ props.row.LastReview.Review }}
                                     </div>

                                   </div>
                                </div>
                           </template>
                          </vue-good-table>
                      </b-col>
                  </b-row>
                </div>
            </b-col>
               
                
                
            </b-row>
          </template>
        </EcenterTable>

          
      </template>
    </Page>
  </div>
  
</template>
<style>
  .fullHeight{
    height:100% !important;
   

  }
</style>
<script>
import AddEditForm from './AddProvider.vue';
import Page from '@/components/page.vue'
import EcenterTable from '@/components/ecenter-table/ecenter-table.vue';
import PageActions from '@/components/pageActions.vue';
import { required, email } from 'vuelidate/lib/validators';
import { helpers } from 'vuelidate/lib/validators';
import * as providerService from "@/services/Mentorship Module/providers";
import { VueGoodTable } from 'vue-good-table';
import ProviderReviews from "./ProviderReviews.vue"
const phoneMask = (value) =>{
  
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  return true;
  // var testResult=/^[1-9]-[0-9]{3}-[0-9]{3}-[0-9]{4}|(?<![0-9])(?<!-)[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value);
  // return testResult;
}


export default {
  props: {
    selectRowURL: String,
    selectRowData: Object,
    customParams: Object,
  },
  data () {
    return {
      refreshTime:new Date().getTime(),
      services:[],
      defaultAdd:{Address:{CountryID:null,ProvinceID:null},Contact:{},services:[],RespectFN:{},contacts:[],ProviderGroup:[]},
      fieldValidations:{
            Name:{
            
              required
            },
            Address:{
              // StreetAddress:{
                
              //    required
              // },
              City:{
                  required
              },
              // Postal:{
              //    required
              // }
            },
            Contact:{
              Phone:{
                phoneMask
              },
              Fax:{
                phoneMask
              },
              Email:{
                  email
              }
            },
            services:{
              required
            }

           
        
      
      },
      serviceName: "Mentorship Module/providers.js",
      headerText: "Providers",
      breadcrumbs: [
          {
            text: 'Dashboard',
            to: {name: 'dashboard'}
          },
          {
            text: 'Providers',
            href: '#'
          }

        ],
        reviewFields:[
        	
          {field:"Summary",label:"Ratings Summary",width:"30%",sortable:false},
          {field:"LastReview",label:"Most Recent Review",width:"70%",sortable:false}
   

        	
        	
        	
        ],
        averageRatingFields:[
          {field:"ServicesRating",width:"50%",label:"All Services",sortable:false},
          {field:"RespectsFN",width:"50%",label:"Works Well With First Nations",sortable:false}
        ]
    };
  },
  created(){
     providerService.getProviderTags({
						 targetCategory:"Services Provided",
						 tagDefinitionOnly:true
					 })
					 .then(response =>{
						 this.services=response.tags

					 });
  },
  methods: {
    
    openReviewModel(rowID,TagID=null,RatingVal=null){
      if(RatingVal){
          this.$bvModal.show('review-dialog-'+rowID+'-'+TagID+'-'+RatingVal);
      }else if(TagID){
          this.$bvModal.show('review-dialog-'+rowID+'-'+TagID);
      }else{
        
                  this.$bvModal.show('review-dialog-'+rowID);

      }
      
    },
    closeReviewModal(rowID,TagID=null,RatingVal=null){
      if(RatingVal){
         this.$bvModal.hide('review-dialog-'+rowID+'-'+TagID+'-'+RatingVal);
      }else if(TagID){
        this.$bvModal.hide('review-dialog-'+rowID+'-'+TagID);
      }else{
         this.$bvModal.hide('review-dialog-'+rowID);

      }
      this.refreshTime=new Date().getTime();
    },
    getOptionName (fieldOptions, answerValue) {

        

				if(answerValue.TagID){

				    var field = fieldOptions.find(element => element.value == answerValue.TagID);
					

					if (field) {
					return field.text;
					}
				}else{
							// eslint-disable-next-line eqeqeq
					var field = fieldOptions.find(element => element.value == answerValue);
					if (field) {
					return field.text;
					}
				}

			
			  
		},
    translateFormRatingColor(value){
			var variant=null;
				if(value<1 || (value>=1 && value<2)){
					variant='danger';
				}else if(value>=2 && value<3){
					variant="warning";
				}else if(value>=3 && value<4){
					variant="primary";
				}else if(value>=4 && value<=5){
					variant="success";
				}
				return variant;
    },
    validateRowData(rowData){
       var errors=new Array;

       if(typeof rowData.Name ==='undefined' || (typeof rowData.Name !=='undefined' && rowData.Name.trim().length==0)){
         errors.push("Provider/Organization name is required");
       }
       
 

      
         if(typeof rowData.Address.StreetAddress==='undefined' || (typeof rowData.Address.StreetAddress !=='undefined' && rowData.Address.StreetAddress.trim().length==0)){
           errors.push("Address is required");
         }
         if(typeof rowData.Address.City==='undefined' || (typeof rowData.Address.City !=='undefined' && rowData.Address.City.trim().length==0)){
            errors.push("City is required");

         }
         
         if(typeof rowData.Address.Postal==='undefined' || (typeof rowData.Address.Postal !=='undefined' && rowData.Address.Postal.trim().length==0)){
             errors.push("Postal/Zip is required");

         }
       

       return errors;
    }
  },
  
  components: {
    EcenterTable, AddEditForm, Page, PageActions,VueGoodTable,ProviderReviews
  }
};
</script>