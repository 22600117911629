<template>
  <div style="min-height: 75vh">
    <Page v-bind:headerText="headerText" v-bind:breadcrumbs="breadcrumbs">
      <!--
      <template v-slot:pageActions>
        <PageActions>
          
        </PageActions>
      </template>
      -->
      
      <template v-slot:content>
        <EcenterTable 
          v-bind:serviceName="serviceName"
          v-bind:customServerParams="{linkedOnly:false,inSearch:true}"
          :key="reloadTime"
        >
          
          <template v-slot:searchDialogContent >
							<MentorGrid></MentorGrid>							

					</template>
          <template v-slot:rowContent="{ row }">
			<b-row >
              <b-col  cols="1" style="vertical-align:top">
				<div class="h-100 d-flex align-items-center; vertical-align:top">
                	<b-avatar :variant="$store.state.themeDefaults.buttonVariant"></b-avatar>
				</div>
              </b-col>
              <b-col cols="3">
                <div>
					<div v-if="row.userDetails" class="font-weight-bold">{{ row.userDetails.FirstName }} {{ row.userDetails.LastName }}</div>
					<small v-if="row.userDetails">
						<div v-if="row.userDetails.Position" v-html="$t('mentorship.index.position', {Position:row.userDetails.Position})"></div>
						<div v-if="row.userDetails.LocationID" v-html="$t('mentorship.index.community', {LocationID:row.userDetails.LocationID})"></div>
						<div v-if="row.userDetails.RegionID">{{ row.userDetails.RegionID }}</div>
						<div>
							<div v-if="row.userDetails.Phone" v-html="$t('mentorship.index.phone', {Phone:row.userDetails.Phone})"></div>
							<div v-if="row.userDetails.Email" v-html="$t('mentorship.index.email', {Email:row.userDetails.Email})"></div>
						</div>
            <div v-if="row.userDetails.Groups" v-html="$t('mentorship.index.group', {Groups:row.userDetails.Groups})"></div>

						
					</small>
                <div style="padding-top:1em"> 
                  <b-button @click="linkMentor(row)" size="sm" variant='success' pill v-if="row.linked==false"><b-icon icon="plus"></b-icon>{{ $t("mentorship.MentorGrid.add") }}</b-button>
                  <b-button @click="unlinkMentor(row)" size="sm" variant="danger" pill v-if="row.linked==true"><b-icon icon="dash-circle" style="padding-right:5px"></b-icon>{{ $t("mentorship.MentorGrid.remove") }}</b-button>
                </div>
                </div>
              </b-col>
            <b-col md="8"> 
                <div v-if="row.expertise && row.expertise.length>0" >
                  <div v-for="(expertiseItem, index) in row.expertise" :key="index" style="margin-bottom:20px"> 
                    <b-row>
                      <div style=" width:auto !important;display:inline-block;padding-left:15px;padding-right:5px;font-size:16pt">
                        <span>
                            <b-badge :style="{backgroundColor:translateRatingColor(expertiseItem.Rating)}"> {{expertiseItem.Text }}</b-badge> 
                        </span>
                        
                      </div>
                      <div style=" width:auto !important;display:inline-block;font-size:16pt">
                          {{translateRating(expertiseItem.Rating)}}
                      </div>
                    </b-row>
                   <vue-good-table
						
						
						
                            :columns="detailFields"
                            :rows="[{RatingDesc:expertiseItem.RatingDesc,files:expertiseItem.files}]"
                            
                            >
                              <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field=='Text'">
                                    <b-badge pill  v-bind:style="{backgroundColor:translateRatingColor(props.row.Rating)}"> {{ props.row.Text }} </b-badge>

                                </span>
                                <span v-else-if="props.column.field=='Rating'">
                                    {{ translateRating(props.row.Rating)}}
                                </span>
                                <span v-else-if="props.column.field=='files'">
                                    	<b-link v-for="file in props.row.files" @click="downloadFile(file.ID,file.Name)" :key="file.ID" style="display:block">
                                        {{ file.Name }}
                                      </b-link>
                                </span>
                                <span v-else>
                                    {{props.formattedRow[props.column.field]}}
                                </span>
                            </template>
                            </vue-good-table>
                  </div>
                    
                </div>
                 <div v-if="row.files && row.files.length>0" >
                    <b-card no-body>
                      <b-card-header>
                        {{ $t("mentorship.MentorGrid.files") }}
                      </b-card-header>
                      <b-card-body>
                          <b-link v-for="file in row.files" @click="downloadFile(file.ID,file.Name)" :key="file.ID" style="display:block">
                                        {{ file.Name }}
                            </b-link>

                      </b-card-body>
                    </b-card>
                  </div>

            </b-col>
            
            </b-row>
          </template>
        </EcenterTable>
      </template>
    </Page>
  </div>
  
</template>

<script>
import AddEditForm from './AddProvider.vue';
import Page from '@/components/page.vue'
import EcenterTable from '@/components/ecenter-table/ecenter-table.vue';
import PageActions from '@/components/pageActions.vue';
import MentorGrid from './MentorGrid.vue';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import * as mentorService from "@/services/Mentorship Module/mentorship-module";
	import { API_URL } from '@/config';

export default {
  props: {
    selectRowURL: String,
    selectRowData: Object,
    customParams: Object,
  },
  data () {
    return {
      reloadTime:null,
      serviceName: "Mentorship Module/mentorship-module.js",
      headerText: "Mentors",
      detailFields:[
        	
          {field:"RatingDesc",label:"Description of Expertise",sortable:false,width:'40%'},
        	{field:"files",label:"Available Files",sortable:false},

        	
        	
        	
        ],
      breadcrumbs: [
          {
            text: 'Dashboard',
            to: {name: 'dashboard'}
          },
          {
            text: 'Mentors',
            href: '#'
          }
        ],
         ratings:[
        	{text:"",value:null,color:"#6c757d"},
      	 	{text:"Limited experience",value:"1",color:"#f7c705"},
      	 	{text:"Moderate Level of Experience",value:"2",color:"#089c8d"},
      	 	{text:"Great Deal of Experience",value:"3",color:"#4e73df" }
      	 ],
    };
  },
  methods: {
    downloadFile(fileRowID,fileName){
				var url= API_URL+"apiUserFileFetch/download&fileID="+fileRowID;
				location.href=url;
		},
    linkMentor:function(row){
		 			 mentorService.linkUser({
                selectedUser:row.userDetails.ID,						
                add:true
					})
           		    .then(response => {
           		    
           		    
           		    		row.linked=true;
           		    		
           		    
               				
            		 })
            		 .catch(error => {
               				 console.log(error);
            		 });
	  },
	  unlinkMentor:function(row){
		   			mentorService.linkUser({
						selectedUser:row.userDetails.ID,
						remove:true
					})
           		    .then(response => {
           		    
           		    
           		    		row.linked=false;
							   
								     this.reloadTime=new Date().getTime();
							   
           		    
               				
            		 })
            		 .catch(error => {
               				 console.log(error);
            		 });
	  },
    translateRating(value){
  			
  			var returnString=null;
  			Object.keys(this.ratings).forEach(key=>{
           		   if(this.ratings[key].value==value){
           		   		returnString=this.ratings[key].text;
           		   		
           		   		return;
           		   		
           		   } 			
           		    			
           		    				
           });
           
           
           return returnString;
           
  		},
  		
  		translateRatingColor(value){
  			
  			
  			var returnString=null;
  			Object.keys(this.ratings).forEach(key=>{
           		   if(this.ratings[key].value==value){
           		   		returnString=this.ratings[key].color;
           		   		
           		   		return;
           		   		
           		   } 			
           		    			
           		    				
           });
           
           if(returnString.length==0){
             returnString="#000000";
           }
           return returnString;
           
		  },
  },
  
  components: {
    EcenterTable, AddEditForm, Page, PageActions, MentorGrid, VueGoodTable
  }
};
</script>