<template>
  <div class="container">
    <!-- Outer Row -->
    <div class="row justify-content-center">
      <div class="col-xl-10 col-lg-12 col-md-9">
        <div class="card o-hidden border-0 shadow my-5">
          <div class="card-body p-0">
            <!-- Nested Row within Card Body -->
            <div class="row">
              <div class="col">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">{{ $t('auth.login.welcome') }}</h1>
                  </div>

                  <b-alert v-show="computedAlertText" variant="success" show>{{ computedAlertText }}</b-alert>

                  <b-form @submit.stop.prevent="submit">
                    <input type="submit" style="position: absolute; left: -9999px; width: 1px; height: 1px;" tabindex="-1" />

                    <b-form-group id="Email" :label="$t('general.email')" label-for="Email">
                      <b-form-input v-model="form.email" id="Email" name="Email" type="email" required :placeholder="$t('auth.enterEmail')"></b-form-input>
                    </b-form-group>

                    <b-form-group id="example-input-group-2" :label="$t('auth.login.password')" label-for="example-input-2">
                      <b-form-input v-model="form.password" id="Password" name="current-password" type="password" required :placeholder="$t('auth.login.enterPassword')"></b-form-input>
                    </b-form-group>

                    <div v-show="formError" class="col text-center text-danger">{{ formError }}</div>

                    <b-button variant="success" class="btn-block" type="submit">{{ $t('auth.login.login') }}</b-button>
                  </b-form>
                  <hr>
                  <div class="text-center">
                    
                    <b-button v-if="showRequestAccount" variant="primary" :to="{name: 'request-account'}">
                      <span>{{ $t('auth.login.request') }}</span>
                    </b-button>
                    
                    
                    <b-button variant="warning" :to="{name: 'forgot-password'}">
                      <span>{{ $t('auth.login.forgot') }}</span>
                    </b-button>
                  </div>

                  <!--<div class="text-center">
                    <a class="small" href="register.html">Create an Account!</a>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ORG_ID } from '@/config';
import axios from 'axios';
import { i18n } from '@/main';
axios.defaults.withCredentials = true;
export default {
  name: 'Login',
  props: ['alertText'],
  data () {
    return {
      form: {},
      formError: '',
      showRequestAccount:false
    };
  },
  computed: {
  
    computedAlertText: function () {
      if (this.$route.params.alertText) {
        return this.$route.params.alertText;
      }
      else if (this.alertText) {
        return this.alertText;
      }
      else return false;
    }
  },
  mounted(){
    this.checkForRequestAccount()
    .then(response=>{
        this.showRequestAccount=response.requestAccount;

    });
  },
  methods: {
    ...mapActions(['login','checkForRequestAccount']),
    submit () {

      let payload=this.form;

      if(this.$route.params){
        payload={...payload,...this.$route.params};

      }
      this.login(payload)
        .then((response) => {

          if(response.redirectUrl){
            this.$router.push({ path: response.redirectUrl });

          }else{

            this.$router.push({ path: response.homepage });

          }
          //updating locale to user language (english is still the fallback locale)
          this.$i18n.locale = response.user["language"]; 
        }).catch((error)=>{
           this.formError=error.error;
        });
    }
  }
};
</script>
