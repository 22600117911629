<template>
  <div>
    <div v-if="isLoading()">
      {{ $t("general.loading") }}
    </div>
    <div v-if="isDoneLoading()">
      <div
        id="formErrorDiv"
        v-show="errorMessage"
        class="col text-center text-danger"
        v-html="errorMessage"
      ></div>
      <FormCard headerClass="global-primary__bg global-primary__bg--no-hover">
        <template v-slot:header>{{ $t("list-view.questionBuilder") }}</template>
        <template v-slot:body>
          <b-row>
            <b-col sm="12" md="6" xl="8" class="animation__fadeIn--left animation__duration--fast">
              <b-form-group>
                <LabelTooltip :label="$t('list-view.question')" iconID="QuestionText_tt" />
                <InputFactory
                  id="QuestionText"
                  type="4"
                  required="1"
                  :options="[]"
                  v-model="rowData.Text"
                />
              </b-form-group>
            </b-col>

            <b-col sm="12" md="6" xl="4" class="animation__fadeIn--left animation__duration--fast">
              <b-form-group>
                <LabelTooltip :label="$t('list-view.answerType')" iconID="AnswerType_tt" />
                <InputFactory
                  id="AnswerType"
                  type="1"
                  required="1"
                  :options="answerTypeOptions"
                  v-model="rowData.AnswerType"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-if="showAnswerOptions()"
            class="ml-1 pl-2"
            style="border-left: 3px solid rgb(239, 239, 239);"
          >
            <b-col
              sm="12"
              md="6"
              xl="12"
              v-for="(answerOption, i) in rowData.answerOptions.rows"
              :key="`answerOption_${i}`"
              class="animation__fadeIn--left animation__duration--fast"
            >
              <b-form-group>
                <LabelTooltip
                  :label="$t('list-view.answerChoice')"
                  :iconID="`AnswerOption${i}_tt`"
                />
                <InputFactory
                  :id="`AnswerOption${i}`"
                  type="3"
                  required="0"
                  v-model="answerOption.text"
                />
              </b-form-group>
            </b-col>

            <b-col sm="12" md="6" xl="12">
              <b-button
                variant="outline-success"
                @click="addAnswerOption"
                class="d-flex align-items-center"
              >
                <b-icon-plus font-scale="2" />
                {{ $t("list-view.another") }}
              </b-button>
            </b-col>
          </b-row>
        </template>
      </FormCard>
    </div>
  </div>
</template>
<script>
import FormCard from '@/components/FormCard';
import InputFactory from '@/components/InputFactory';
import LabelTooltip from '@/components/LabelTooltip';

const answerTypes = [
  { text: 'Dropdown', value: '1' },
  { text: 'Multiple Choice', value: '2' },
  { text: 'Text Field', value: '3' },
  { text: 'Text Area', value: '4' },
];

export default {
  name: 'QuestionBuilderForm',
  components: {
    FormCard,
    InputFactory,
    LabelTooltip,
  },
  data() {
    return {
      answerTypeOptions: answerTypes,
    };
  },
  props: {
    rowData: Object,
    errorMessage: String,
    formType: String,
  },
  methods: {
    isLoading() {
      return Object.keys(this.rowData).length === 0;
    },
    isDoneLoading() {
      return Object.keys(this.rowData).length > 0;
    },
    addAnswerOption() {
      if (this.rowData.answerOptions.rows) {
        const rowRef = this.rowData.answerOptions.rows;
        rowRef.push({ position: rowRef.length, text: '', id: null });
      }
    },
    showAnswerOptions() {
      return (
        this.rowData &&
        this.rowData.answerOptions &&
        (this.rowData.AnswerType === '1' ||
          this.rowData.AnswerType === '2' ||
          this.rowData.AnswerType === 'MultiSelect')
      );
    },
  },
};
</script>
