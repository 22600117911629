// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

const CNTLR_NAME = 'apiTaxonomy';

export const getTableSettings = (payload = null) =>
  http
    .post(`${CNTLR_NAME}/getTableSettings&isMasterTool=1&type=tag`, payload)
    .then(getData);

export const get = (payload = null) =>
  http.post(`${CNTLR_NAME}/getRows`, appendGetData(payload)).then(getData);

export const create = row =>
  http.post(`${CNTLR_NAME}/create`, appendCreateData(row)).then(getData);

export const update = row =>
  http.put(`${CNTLR_NAME}/edit&ID=${row.ID}`, row).then(getData);

export const remove = row =>
  http.post(`${CNTLR_NAME}/delete`, row).then(getData);

export const getRow = rowID =>
  http.get(`${CNTLR_NAME}/getRow&ID=${rowID}`).then(getData);

const appendCreateData = function(payload) {
  payload['__level'] = 'org';
  payload['__type'] = 'tag';
  return payload;
};

const appendGetData = function(payload) {
  if (!payload['customServerParams']) {
    payload['customServerParams'] = {};
  }
  payload['customServerParams']['level'] = 'org';
  payload['customServerParams']['type'] = 'tag';
  return payload;
};
