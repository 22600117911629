import sanitizeHtml from 'sanitize-html';

export const doMarkdown = (survey, options) => {
  sanitizeHtml(options.text, {
    allowedTags: sanitizeHtml.defaults.allowedTags,
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      div: ['style'],
    },
    allowedStyles: {
      div: {
        height: [/^[0-9]{1,}px|[0-9]{1,}%/],
      },
    },
  });
  options.html = options.text;
};
