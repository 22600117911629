export default {
  initialized: false,
  fetching: true,
  auth: {
    isAuthorized: false,
    token: localStorage.getItem('auth.token') || null,
    user: {},
    acceptedEula:false,
    hideNav: false,
    hideToolbar: false,
    fromMobile: false,
    isClient:false,
    collectionLogin:false,
    trainingMode:false,
    isUsingYii:false,
    hideMyAccount:false,
    onlineModeOnly:false,
    sharedGridOnly:false,
    isUserCollection:false
   


  },
  onCollectionLogout:false,
  onLocationLogout:false,
  onResultCollaborationLogout:false,
  mainToolbarLinks: [],
  IMAGES_PATH: `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_PUBLIC_PATH}images`,
  isSubmitting: true,
  searchParams: {},
  selectedContacts: [],
  selectedLocations: [],
  selectedRegions: [],
  selectedCollections: [],
  reportType: '',
  selectedReports: [],
  reportFilter: {},  
  themeDefaults: {
    cardHeaderVariant: 'gradient-theme-secondary',
    cardHeaderTextVariant: 'light',
    buttonVariant: 'secondary',
    eCenterTableFilterRowVariant: 'gradient-theme-secondary',
    eCenterTableFilterRowTextVariant: 'light',
  },
  canEmitEvent: true,
  clearingFilters: false,
  globalHasNotifications: false,
  relauchDeployment:false,
  globalUpcomingEvents:0,
  totalCriticalNotifications:0,
  allowRequestAccount:false,
  defaultFilters:{},
  hideReloadTable:false,
  disableReloadGrid:false,
  noHomePage:false,
  routeDetails:{}
};
