<template>
  <span v-if="hasSortGroups">
    <b-dropdown
      right
      variant="link"
      :toggle-class="
        'text-decoration-none font-weight-bold ' + toggleButtonTextVariant()
      "
      id="dropdown-form"
      :text="label"
      ref="dropdown"
      class="my-1"
      menu-class="white-space: nowrap;"
    >
      <div style="max-height: 50vh" class="overflow-auto">
        <b-dropdown-text class="text-small">
          <small class="font-weight-bold">Sort By</small>
        </b-dropdown-text>

        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-group
          v-for="(sortOption, index) in sortOptions"
          :header="sortOption.label"
          :key="index"
        >
          <b-dropdown-item
            v-for="(sortOptionChild, childIndex) in sortOption.children"
            :key="index + '_' + childIndex"
            href="#"
            @click="sortOptionSelected(index + '_' + childIndex)"
          >
            <div style="width:100%;padding-left:8px">
              {{ sortOptionChild.label }}
            </div>
          </b-dropdown-item>
        </b-dropdown-group>
      </div>
    </b-dropdown>
  </span>
  <span v-else>
    <b-dropdown
      right
      variant="link"
      :toggle-class="
        'text-decoration-none font-weight-bold ' + toggleButtonTextVariant()
      "
      id="dropdown-form"
      :text="label"
      ref="dropdown"
      class="my-1"
      menu-class="white-space: nowrap;"
    >
      <b-dropdown-group>
        <b-dropdown-text class="text-small">
          <small class="font-weight-bold">Sort By</small>
        </b-dropdown-text>

        <b-dropdown-divider></b-dropdown-divider>

        <b-dropdown-item
          v-for="(sortOption, index) in sortOptions"
          :key="index"
          href="#"
          @click="sortOptionSelected(index)"
        >
          {{ sortOption.label }}
        </b-dropdown-item>
      </b-dropdown-group>
    </b-dropdown>
  </span>
</template>

<script>
export default {
  name: 'EcenterTableSort',
  props: {
    sortOptions: Array,
    hasSortGroups: Boolean,
  },
  data() {
    return {
      label: 'Sort',
      currentSortOptionIndex: 0,
      sortType: this.hasSortGroups
        ? this.sortOptions[0]['children'][0].type
        : this.sortOptions[0].type,
      sortField: this.hasSortGroups
        ? this.sortOptions[0]['children'][0].field
        : this.sortOptions[0].field,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    clearSort() {
      this.sortType = this.hasSortGroups
        ? this.sortOptions[0]['children'][0].type
        : this.sortOptions[0].type;
      this.sortField = this.hasSortGroups
        ? this.sortOptions[0]['children'][0].field
        : this.sortOptions[0].field;
    },
    toggleButtonTextVariant() {
      return (
        'text-' +
        this.$store.state.themeDefaults.eCenterTableFilterRowTextVariant
      );
    },
    sortOptionSelected(index) {
      if (this.hasSortGroups) {
        this.currentSortOptionIndex = index;

        var indexExploded = index.split('_');

        this.sortType = this.sortOptions[indexExploded[0]]['children'][
          indexExploded[1]
        ].type;
        this.sortField = this.sortOptions[indexExploded[0]]['children'][
          indexExploded[1]
        ].field;
      } else {
        this.currentSortOptionIndex = index;
        this.sortType = this.sortOptions[index].type;
        this.sortField = this.sortOptions[index].field;
      }

      this.$emit('sortOptionSelected');
    },
  },
};
</script>
