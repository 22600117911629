// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';


export const getTableSettings = (payload = null) => http.post('apiDiscussionBoard/getDiscussionBoardTableSettings', payload).then(getData);

export const get = (payload = null,silentQuery=false) => http.post('apiDiscussionBoard/getBoards', payload,{silent:silentQuery}).then(getData);

export const  create = (payload) => http.post('apiDiscussionBoard/createDiscussionBoard', payload).then(getData);

export const update = (payload) => http.put('apiDiscussionBoard/editBoard', payload).then(getData);

export const remove  = (payload) => http.post('apiDiscussionBoard/deleteBoard',payload).then(getData);

export const getTags= (payload=null) => http.post('apiUserTag/getContactUserTags',payload).then(getData);

export const getDiscussionBoard=(payload=null) => http.post('apiDiscussionBoard/getBoard',payload).then(getData);

export const getPosts=(payload=null) => http.post('apiDiscussionBoard/getPosts',payload).then(getData);

export const createPost=(payload) => http.post('apiDiscussionBoard/createPost',payload).then(getData);

export const editPost=(payload) => http.post('apiDiscussionBoard/editPost',payload).then(getData);

export const deletePost=(payload) => http.post('apiDiscussionBoard/deletePost',payload).then(getData);

export const updateVote=(payload) => http.post('apiDiscussionBoard/updateVotes',payload).then(getData);

export const updateBoardLock=(payload) => http.post('apiDiscussionBoard/lockBoard',payload).then(getData);

export const linkUser=(payload) => http.post('apiDiscussionBoard/linkUserToBoard',payload).then(getData);

export const refreshBoards = (payload = null) => http.post('apiDiscussionBoard/getBoards', payload,{
    silent:true
}).then(getData);

export const updateAccessTime= () => http.post('apiDiscussionBoard/updateBoardAccessTime',{

    silent:true
}).then(getData);

export const getRow = (rowID) => http.get(`apiDiscussionBoard/getRow&ID=${rowID}`).then(getData);

