<template>
  <b-dropdown
    v-show="showFilter()"
    variant="link"
    :toggle-class="
      'text-decoration-none font-weight-bold ' + toggleButtonTextVariant()
    "
    id="dropdown-form"
    :text="filter.label"
    ref="dropdown"
    class="my-1"
    menu-class="filterMenu"
    right
  >
    <b-dropdown-group>
      <b-dropdown-text>
        <small class="font-weight-bold">{{ filter.heading }}</small>
      </b-dropdown-text>

      <b-dropdown-divider></b-dropdown-divider>

      <!-- <b-dropdown-text><b-form-checkbox-group  v-model="filter.selected" :options="filter.options" stacked switches></b-form-checkbox-group></b-dropdown-text> -->
      <b-dropdown-text>
        <b-check-group
          :key="refreshFilter"
          switches
          v-on:input="onChange()"
          v-model="filter.selected"
          stacked
        >
          <b-form-checkbox
            v-for="(option, i) in filter.options"
            v-show="showCheckboxOptions(option)"
            :key="`${option.text}__${i}`"
            :value="option.value"
          >
            {{ option.text }}
          </b-form-checkbox>
        </b-check-group>
      </b-dropdown-text>
    </b-dropdown-group>
  </b-dropdown>
</template>
<style>
.filterMenu {
  width: auto !important;
  max-width: auto !important;
  white-space: nowrap;
  max-height: 40vh;
  overflow: auto;
}
</style>
<script>
import { isString } from "lodash";
export default {
  name: 'EcenterTableFilterCheckboxGroup',
  props: ['filter', 'filterOptions'],
  data() {
    return {
      refreshFilter:null
    };
  },
  created(){ },
  mounted() {
     if(!this.$store.state.clearingFilters && this.$route.params.prefilter && this.filter.name==this.$route.params.prefiltername && (typeof this.filter.selected === 'undefined' || (typeof this.filter.selected !== 'undefined' && this.filter.selected.length==0))){
            this.filter.selected=new Array;
            this.filter.selected.push(this.$route.params.prefilter);
          
            this.refreshFilter=new Date().getTime();
            //this.onChange();
      }
  },
  // watch:{
  //   filter:{
  //     deep:true,
  //     handler:function(newval,oldval){
  //                     this.$emit('filterChange');

  //     }
  //   }
  // },
  computed: {},
  methods: {
    showFilter() {
      if (this.filter['dependencies']) {
        for (let dependencyID of this.filter['dependencies']) {
          // Find parent of this node. dependencyID == parent name
          for (let filterOption of this.filterOptions) {
            if (filterOption['name'] && filterOption['name'] === dependencyID) {
              let parentValue = filterOption['selected'];
              return (
                parentValue &&
                ((Array.isArray(parentValue) && parentValue.length > 0) ||
                  typeof parentValue === 'string' ||
                    (parentValue instanceof String && parentValue !== ''))
              );
            }
          }
        }
      }
      return true;
    },
    /**
     * Determines whether to render a checkbox based on parent's selection
     * @param { Node } option
     */
    showCheckboxOptions(option) {
      if (this.filter['dependencies']) {
        for (let dependencyID of this.filter['dependencies']) {
          // Find parent. dependencyID == parent name
          for (let filterOption of this.filterOptions) {
            if (filterOption['name'] && filterOption['name'] === dependencyID) {
              let parentValue = filterOption['selected'];
              if (Array.isArray(parentValue)) {
                return (
                  parentValue.filter(e => e === option['value'][dependencyID])
                    .length > 0
                );
              } else {
                return parentValue === option['value'][dependencyID];
              }
            }
          }
        }
      }

     

      return true;
    },
    setSelectedFilter(value){

          if(Array.isArray(value)){
               this.filter.selected=value;

          }else{
                this.filter.selected=[value];

          }
           this.$emit('filterChange');
             this.$forceUpdate();

    },
    onChange() {

      
      if (this.$store.state.clearingFilters) return;
      let forceEmitEvent = false;
      // Parent filter needs to unset any selection from its children if user de-selects an option
      if (this.filter['children'] && Array.isArray(this.filter['children'])) {
        for (let childID of this.filter['children']) {
          for (let filterOption of this.filterOptions) {
            if (filterOption['name'] && filterOption['name'] === childID) {
              this.$store.commit('UPDATE_CAN_EMIT_EVENT', false);

              let childNode = filterOption;
              let parentValue = this.filter['selected'];
              let dependencyID = this.filter['name'];

              if (Array.isArray(childNode['selected'])) {
                childNode['selected'] = childNode['selected'].filter(
                  childSelection => {
                    for (let value of parentValue) {
                      if (childSelection[dependencyID] === value) {
                        return true;
                      }
                    }
                    return false;
                  }
                );
              } else {
                filterOption['selected'] = null;
              }
              forceEmitEvent = true;
            }
          }
        }
      }
      if (this.$store.state.canEmitEvent || forceEmitEvent) {
        this.$emit('filterChange');
      }
    },
    clearFilter() {
      if (
        !this.filter.selected ||
        (Array.isArray(this.filter.selected) &&
          this.filter.selected.length === 0)
      )
        return;
      this.filter.selected = isString(this.filter.selected) ? "" : [];

      this.refreshFilter=new Date().getTime();
      
    },
    toggleButtonTextVariant() {
      return (
        'text-' +
        this.$store.state.themeDefaults.eCenterTableFilterRowTextVariant
      );
    },
    getSelectedFilters() {
      var filter = {};
      filter.name = this.filter.name;
      filter.selected = this.filter.selected;

      return filter;
    },
  },
};
</script>
