<template>

    <div  class="container-fluid bg-white">



          <b-row v-if="eventTitles.length>0" class="border-bottom pt-1 pb-2">
            <b-col v-for="(eventTitle,index) in eventTitles" :md="eventTitle && eventTitle.BCol?eventTitle.BCol:''" :key="'eventDetails_title_'+index">
                {{ eventTitle && eventTitle.title?eventTitle.title:eventTitle }}
            </b-col>
          </b-row>
          <b-row  class="border-bottom pt-1 pb-2"   :key="refreshCardTime">
                  <b-col v-for="(eventDetailsElement,index) in event" :md="eventDetailsElement && eventDetailsElement.BCol?eventDetailsElement.BCol:''"  :key="'eventDetails_field_'+index">
                        {{  eventDetailsElement && eventDetailsElement.BCol? eventDetailsElement.text: eventDetailsElement }}
                  </b-col>

          </b-row>
          <b-row style='padding-top:20px'>
            <b-button v-if="isEventDeployment" size="sm" class='btn mr-1 btn-simple global-primary__bg btn-secondary btn-md' @click="changeEvent()">Change Linked Event</b-button>
            <b-button v-if="isEventDeployment" size="sm" variant="danger" @click="unlinkFromEvent()">Unlink From Event</b-button>
            <b-button v-else size="sm" class='btn mr-1 btn-simple global-primary__bg btn-secondary btn-md' @click="changeEvent()">Link To Event </b-button>

          </b-row>
  
       <FullCalendarModal v-if="renderCalendar" @onCloseCalendarDialog="closeCalendarDialog"  :linkedModelID="rowID"></FullCalendarModal>
       
    </div>
</template>
<script>

import Calendar from '@/app/calendar';
import { BCollapse } from 'bootstrap-vue';
import Label from './Label.vue';
import { ErrorMessages } from '@/utils/errorMessages.js';
import { isEmpty } from '@/utils/isEmpty.js';
import FormCard from '@/components/FormCard';
import FullCalendarModal from '@/components/FullCalendarModal.vue';



export default {
  name: 'LinkedEventsCard',
  components: {
    Calendar,
    FormCard,
    FullCalendarModal
  },
  data() {
    return {
      
      errorMessage:null,
      renderCalendar:false,
      refreshCardTime:null,
      refreshFields:null,
      event:{},
      eventTitles:[],
      isEventDeployment:false,
      service:null
      

    };
  },
  props: {
    formError: { type: String, default: '' },
    value: {type: Object},
    rowID:{type: String, default:null},
    serviceName:{type: String, default:null}
  

    
  },
  created() {},
  mounted(){

      
      this.event=this.value;

      var vm=this;

      if(this.event){
          $.each(this.event,function(property,value){
          
      
            if(value && value.BCol){
                vm.eventTitles.push({title:property,BCol:value.BCol});
            }else{
              vm.eventTitles.push(property);
            }
          })
      


          if(this.event.Start){
            this.isEventDeployment=true;

          }
      }

          this.service = require('@/services/' + this.serviceName);


     //this.getFields();

  },
  methods: {
     getEvents(){
         


                    
     },
     
    changeEvent(){
        this.renderCalendar=true;

    },
   unlinkFromEvent(){
      this.service.unlinkFromEvent({RowID:this.rowID})
            .then(response =>{
                this.event=new Object;

                this.refreshCardTime=new Date().getTime();
                this.isEventDeployment=false;
    

                 this.$emit('refreshForm');



            });
    },
    closeCalendarDialog(){
      this.renderCalendar=false;

      this.service.getLinkedEvent({RowID:this.rowID})
      .then(response =>{

        this.event=response;
        this.refreshCardTime=new Date().getTime();

                 this.$emit('refreshForm');

       
      });

      
      

    }
  },
};
</script>
