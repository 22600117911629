<template>
  <div class="container">
    <!-- Outer Row -->
      <b-alert variant="danger" :show="updateAlert>0">
        {{formError}}
    </b-alert>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ORG_ID } from '@/config';
import axios from 'axios';
import * as callLogService from '@/services/callLog';

axios.defaults.withCredentials = true;


export default {
  name: 'Login',
  props: ['alertText'],
  data () {
    return {
      form: {},
      formError: null,
      updateAlert:0
    };
  },
  created(){
      this.$store.commit('HIDE_TOOLBAR',true);

      this.$store.commit('SET_HIDE_MY_ACCOUNT',true);

      this.$store.commit('SET_NO_HOMEPAGE',true);

  },
  mounted(){
          this.$store.commit('HIDE_TOOLBAR',true);

      this.$store.commit('SET_HIDE_MY_ACCOUNT',true);
      this.recursiveLogOut();
     
  },
  methods: {
    ...mapActions(['onlineFormLogin','logout']),
     recursiveLogOut(){
      this.logout().then(response => {
       if(response){
         var vm=this;

          //recursively check to see if actually logged out due to quirk of Yii
          this.$store.dispatch('checkUserToken',true)
          .then(response =>{
              if(response !== -1){
                  this.recursiveLogOut();
              }else{

                

                this.onlineFormLogin(this.$route.params)
                        .then((response) => {

                          
                           if(response.success){
                              this.$store.commit('HIDE_TOOLBAR',true);

                             this.$store.commit('SET_HIDE_MY_ACCOUNT',true);
                             this.$router.push({name:'onlineForm',params:{callLogID:response.callLogModelID}});

                           }else{
                              // this.formError=response.error;
                              //this.updateAlert=new Date().getTime();
                           }

                        }).catch((error)=>{

                          this.formError=error.error;
                                      this.updateAlert=new Date().getTime();

                        });
                    
                  
              }
          });
      
       }

      });
    },
    submit () {
      
    }
  }
};
</script>
