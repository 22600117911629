import auth from '@/router/middleware/auth';
const Messaging = r => require.ensure([], () => r(require('./Messaging')), 'messaging-bundle');
export default [
  {
    path: '/messaging',
    name: 'messaging',
    component: Messaging,
    meta: {
      middleware: [auth]
    }
  },
];
