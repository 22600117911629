<template>
  <!-- Select -->
  <div
    v-if="
      type === '1' ||
        type === '13' ||
        type == 'CommunityParticipant' ||
        type === 'ProvinceSelect' ||
        type === '8' ||
        type === 'ProvinceID' || 
        type === 'CountryID' || 
        type === 'CountySelect' ||
        type === 'DropdownDocumentCategory' ||
        type === 'DropdownDocumentCategoryParent' ||
        type === 'DropdownPostCategory' ||
        type === 'DropdownPostCategoryParent' ||
        type === 'DropdownEventCategory' ||
        type === 'DropdownEventCategoryParent' ||
        type === 'DropdownOrgTagService' ||
        type === 'DropdownDocumentCategory' ||
        type === 'DropdownDocumentCategoryParent' ||
        type === 'DropdownUserFileCategory' ||
        type === 'DropdownLocationFileCategory' ||
        type==='dropdown' || 
        type==='Dropdown' ||
        type==='DropDown' || 
        type==='Regions'  ||
        type==='Providers' ||
        type==='DeliveryMode' ||
        type==='DeliveryMethod' ||
        type==='Willingness' ||
        type==='LocationTypeTags' || 
        type==='TrainingMode' ||
        type === 'StaffTypes' ||
        type === 'StaffRelation' ||
        type === 'CollectionYear' ||
        typeIncludes('DdTxy') ||
        typeIncludes('DdLocation') ||
        type === 'AvailableOccupancyGroups' ||
        type === 'ProviderSelect' ||
        typeIncludes('DdTxy') || type=='Capacity' ||
        type === 'AvailableSettings' ||
        type === 'Tools' || 
        type === 'LocationFields' ||
        type === 'DropdownCollectionSeries' ||
        type === 'ClientFieldsDropdown' ||
         type === 'ClientFieldsDropdownLocations' ||
         type === 'DropdownYesNo' ||
         type === 'DdTreatyResponseType' ||
         typeStartWith('Dd-') || typeStartWith('DdRestful-')
         || type==='LanguageSelect'
      "
    
  >
    <div v-if="displayOnly"  :id="id">
        {{ optionText }}

    </div>
    <b-form-select
      v-else
      :id="id"
      :options="availableOptions"
      :required="required === '1'"
      :value="value"
      :class="!validation  ? 'form__input--invalid' : ''"
      :disabled="disabled || readOnly"
      @change="$emit('input', $event)"
      @click.native.stop=""
    >
      <template v-slot:first>
        <option
          v-show="showEmptySelect && type!=='TrainingMode' && id !='Training'"
          :disabled="disableSelectFirstSlot"
          style="color: rgb(167, 167, 167);"
          :value="emptyValue"
          >{{ $t("general.select") }}
          {{
            placeholder &&
            placeholder.length > 0 &&
            placeholder[placeholder.length - 1] === ':'
              ? placeholder.substring(0, placeholder.length - 1).toLowerCase()
              : placeholder.toLowerCase()
          }}</option
        >
      </template>
    </b-form-select>
    <b-form-invalid-feedback :state="validation">
      {{ $t("general.requiredField") }}
    </b-form-invalid-feedback>
   
  </div>

  <!-- Radio -->
  <b-form-radio-group
    v-else-if="type === '2'"
    :id="id"
    :options="options"
    :checked="value"
    :stacked="stacked"
    :disabled="disabled || readOnly"
    @change="$emit('input', $event)"
    @click.native.stop=""
  ></b-form-radio-group>

  <!-- Checkbox -->
  <b-form-checkbox
    v-else-if="type === '7'"
    :id="id"
    :checked="value==1"
    :disabled="disabled || readOnly"
    @change="$emit('input', $event)"
    @click.native.stop=""
  ></b-form-checkbox>

  <!-- Date -->
  <div v-else-if="type === '6' || type === '16' || type==='date-picker' || type==='ReferralStart' || type==='AnticipatedDischargeDate'">
    <datepicker
      bootstrap-styling
      :clear-button="allowClearButton"
      :format="customDateFormatter"
      :id="id"
      :disabled="disabled || readOnly"
      input-class="bg-white"
      :placeholder="'Select a date'"
      :required="required === '1'"
      :value="value"
      @input="fixDate($event)"
      :calendar-class="cssClasses?cssClasses:''"
      :class="!validation ? 'form__input--invalid' : ''"
      @click.native.stop=""
      clear-button-icon="far fa-trash-alt"
      :disabled-dates="disabledDatesLocal"
      
    ></datepicker>
    <b-form-invalid-feedback :state="validation">
      {{ $t("general.requiredField") }}
    </b-form-invalid-feedback>
  </div>

  <!-- Text -->
  <div v-else-if="type === '3' || type === '9' || type=='text' || type=='Text' || type=='Password'">
    <b-form-input
      debounce="700"
      :disabled="disabled || readOnly"
      :id="id"
      :placeholder="placeholder"
      :required="required === '1'"
      :type="getFormInputType()"
      :value="value"
      :class="!validation || !validateIsNumeric ? 'form__input--invalid' : ''"
      @input="$emit('input', $event)"
      @click.native.stop=""
    ></b-form-input>
    <b-form-invalid-feedback :state="validation">
      {{ $t("general.requiredField") }}
    </b-form-invalid-feedback>
     <b-form-invalid-feedback :state="validateIsNumeric">
      {{ $t("InputFactory.numeric") }}
    </b-form-invalid-feedback>
  </div>
  <!-- Base Table Fields -->
  <div
    v-else-if="type == 'Username' || type == 'FirstName' || type == 'LastName'"
  >
    <b-form-input
      :id="id"
      debounce="700"
      :placeholder="placeholder"
      :required="required === '1'"
      type="text"
      :value="value"
      :class="!validation ? 'form__input--invalid' : ''"
      :disabled="disabled || readOnly"
      @input="$emit('input', $event)"
      @click.native.stop=""
    ></b-form-input>
    <b-form-invalid-feedback :state="validation">
      {{ $t("general.requiredField") }}
    </b-form-invalid-feedback>
  </div>
  <!-- TextArea -->
  <div v-else-if="type === '4'">
    <b-form-textarea
      :id="id"
      :disabled="disabled || readOnly"
      max-rows="1000"
      :placeholder="placeholder"
      type="text"
      :required="required === '1'"
      rows="2"
      :value="value"
      @input="$emit('input', $event)"
      :class="!validation ? 'form__input--invalid' : ''"
      @click.native.stop=""
    ></b-form-textarea>
    <b-form-invalid-feedback :state="validation">
      {{ $t("general.requiredField") }}
    </b-form-invalid-feedback>
  </div>

  <!-- Checkbox -->
  <div
    v-else-if="
      type === '10' ||
        type === 'MultiSelect' ||
        type === 'MultiSelectStartEndDate' ||
        type === 'DropdownStartEndDate' ||
        type === 'MultiSelectDiscussionBoardGroups' ||
        type === 'CollaborativeTools' ||
        type === 'CollaboratingLocations' ||
        type === 'MultiSelectProviderGroups' ||
        type === 'MultiSelectOrgTagService' ||
        type === 'LocationTags' ||
        type === 'DenialTags' ||
        type === 'CancellationTags' ||
        type === 'ClientTypes' ||
        type ==='CollectionSeries' ||
        type === 'StaffTypes_Mul'||
        type === 'MultiSelectCollectionYear' ||
        type === 'StaffTypeList' ||
        typeIncludes('MsLocation') ||
        typeStartWith('Ms-')


    "
    class="tag__wrapper"
  >
    <h5 v-show="showCheckHeader" :class="['tag__label', labelClass]">
      {{ label }}
    </h5>
    <div :class="['tag__container', tagContainerClass]">
      <b-badge
        v-for="(selectedValue, index) in value"
        :key="index"
        v-show="selectedValue.length>0"
        pill
        style="white-space: normal"
        variant="primary"
        :class="[
          'tag',
          tagClass,
          'animation__fadeIn--bottomLeft',
          'animation__duration--fast',
        ]"
      >
        <div class="d-flex align-items-center" >
         
          <span
            :id="`${id}_${index}_ms_${getTagText(selectedValue)}`"
            class="tag__text"
            :class="tagTextClass"
            style="overflow: hidden;text-overflow: ellipsis;"
            >{{ getTagText(selectedValue) }}
          </span>
          <b-tooltip
            v-if="
              type === 'MultiSelectStartEndDate' ||
                type === 'DropdownStartEndDate'
            "
            :target="`${id}_${index}_ms_${getTagText(selectedValue)}`"
            >{{ getTagText(selectedValue) }}</b-tooltip
          >
          <div
            v-if="
              type === 'MultiSelectStartEndDate' ||
                type === 'DropdownStartEndDate'
            "
            class="d-flex"
            style="color: #000"
          >
            <div
              v-for="(tagFormDateName,
              tagFormDateNameIndex) in tagFormDateNames"
              :key="
                `${selectedValue['ID']}_{tagFormDateName}_${tagFormDateNameIndex}`
              "
            >
              <datepicker
                bootstrap-styling
                :format="customDateFormatter"
                :input-class="
                  `form-control-sm m-1 bg-white ${!isValidInput(
                    selectedValue[tagFormDateName],
                    tagFormDateName === 'StartDate'
                  ) && 'form__input--invalid-bg'}`
                "
                :placeholder="tagFormDateName"
                :value="selectedValue[tagFormDateName]"
                @input="
                  fixDateInsideObject($event, tagFormDateName, selectedValue)
                "
              />
            </div>
          </div>
          <div
            v-if="isEditable"
            @click="removeTag($event, selectedValue)"
            aria-keyshortcuts="Delete"
            aria-label="Remove tag"
            class="button-remove-tag"
          >
            ×
          </div>
        </div>
      </b-badge>
      <b-dd
        :id="id"
        variant="light"
        class="form-control p-0"
        toggle-class="text-left"
        menu-class="scrollable-y card__border shadow"
        text="Select"
        @show="inputError = ''"
        :disabled="disabled"
        v-if="!displayOnly"
      >
        <b-dd-text text-class="text--sm text-danger" v-if="inputError">
          <b-icon-exclamation-circle-fill /> {{ inputError }}
          <hr />
        </b-dd-text>
        <b-dd-text text-class="dd__text--btn" @click="onSelectTagChange('')">
          {{ $t("general.select") }}
        </b-dd-text>
        <b-dd-text
          v-for="(option, index) in options"
          :key="index"
          :text-class="[
            'dd__text--btn',
            isSelected(option.value) ? 'font-weight-bold' : '',
          ]"
          @click="onSelectTagChange(option.value)"
        >
          <div>{{ option.text }}</div>
        </b-dd-text>
      </b-dd>
      <b-form-invalid-feedback :state="validation">
        {{ $t("general.requiredField") }}
      </b-form-invalid-feedback>
    </div>
  </div>
  <div v-else-if="type === '21' || type === 'TimePicker'">
    <b-time
      hide-header
      :id="id"
      locale="en"
      :value="value"
      @input="timeFormatter($event)"
      :class="!validation ? 'form__input--invalid' : ''"
      :disabled="disabled || readOnly"
      @click.native.stop=""
    ></b-time>
    <b-btn v-if="allowClearButton" variant="light" @click="$emit('input','')"><b-icon-trash /></b-btn>
    <b-form-invalid-feedback :state="validation">
      {{ $t("general.requiredField") }}
    </b-form-invalid-feedback>
  </div>
  
  <!-- Link -->
  <div v-else-if="type === 'Link'">
    <b-link
      @click="$emit('onClickLink')"
      :id="id"
      :href="value || '#'"
      rel="noopener noreferrer"
      target="_blank"
      style="display: block; padding: 0.375rem 0.75rem"
      >{{ $t("InputFactory.read") }} <b-icon-arrow-right
    /></b-link>
  </div>
  <!-- Editor -->
  <div v-else-if="type === 'Editor'">
    <p class="font-weight-bold text-danger" v-if="placeholder">{{ placeholder }}</p>
    <VueEditor
      :value="value"
      :editorToolbar="[
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic'], // toggled buttons
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        ['link'],
      ]"
      @input="$emit('input', $event)"
    >
    </VueEditor>
  </div>
  <div v-else-if="type === 'TinyMCEEditor'">
    <p class="font-weight-bold text-danger" v-if="placeholder">{{ placeholder }}</p>
    <Editor
      :fieldID="fieldID"
      :value="value"
      @input="$emit('input', $event)"
    >
    </Editor>
  </div>
  <div v-else-if="type === 'ImagePicker'">
    <b-img
      v-if="isString(value) && showImage"
      class="w-100"
      rounded
      :src="`${apiURL}${value}`"
      @error="showImage = false"
    />
    <b-img
      v-if="value && typeof value === 'object'"
      class="w-100"
      rounded
      :src="$refs['imagePickerRef'].imagePreview"
    />
    <div class="d-flex justify-content-between">
      <label :for="id" class="btn btn-outline-success text--semi-bold"
        >{{ $t("InputFactory.choose") }}</label
      >
      <label
        v-if="
          (value && typeof value === 'object') ||
            (isString(value) && value.length > 0)
        "
        class="btn btn-outline-danger text--semi-bold"
        @click="removeImage"
        >{{ $t("InputFactory.remove") }}</label
      >
    </div>
    <p class="text--sm text-danger" v-if="inputError">
      <b-icon-exclamation-circle-fill /> {{ inputError }}
    </p>
    <image-uploader
      :id="id"
      :key="imagePickerKey"
      :maxWidth="500"
      :quality="0.75"
      outputFormat="blob"
      :className="['d-none']"
      accept="image/png, image/jpeg"
      @input="processImage($event)"
      ref="imagePickerRef"
    ></image-uploader>
  </div>
  <div v-else-if="type === 'FilePicker' || typeIncludes('UserFilePicker')">
    <FileThumbnailAdapter
      v-if="typeIncludes('UserFilePicker')"
      :value="value" />
    <div class="d-flex">
    <b-form-file
      accept="application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,audio/mpeg,image/jpeg,image/png,video/mp4,video/quicktime"
      :disabled="disabled || readOnly"
      :placeholder="''"
      :value="fileValue"
      :class="!validation ? 'form__input--invalid' : ''"
      @input="handleFileInput($event); fileValue=$event"
    />
    <b-btn
      v-if="typeIncludes('UserFilePicker')"
      :disabled="disabled || readOnly"
      variant="light"
      @click="removeFile()"><b-icon-trash/></b-btn>
    </div>
    <label v-show="fileProcessingMessage" class="text--sm text-danger">
      {{ fileProcessingMessage }}
    </label>
    <b-form-invalid-feedback :state="validation">
      {{ $t("general.requiredField") }}
    </b-form-invalid-feedback>
  </div>
    <div v-else-if="type === 'FilePickerCSV'">
    <b-form-file
      accept="text/csv"
      :disabled="disabled || readOnly"
      :placeholder="''"
      :class="!validation ? 'form__input--invalid' : ''"
      @input="handleFileInput($event)"
    />
    <label v-show="fileProcessingMessage" class="text--sm text-danger">
      {{ fileProcessingMessage }}
    </label>
    <b-form-invalid-feedback :state="validation">
      {{ $t("general.requiredField") }}
    </b-form-invalid-feedback>
  </div>
  <div v-else-if="type === 'PermissionsCard'">
    <PermissionsCard
      :value="value"
    >
    </PermissionsCard>
  </div>
  <div v-else-if="type === 'LinkedEventsCard'">
    <LinkedEventsCard
      :rowID="rowID"
      :value="value"
      :serviceName="serviceName"
      @refreshForm="refreshForm"
    >
    </LinkedEventsCard>
  </div>
   <div v-else-if="type === 'LinkedFormsCard'">
    <LinkedFormsCard
      :rowID="rowID"
      :value="value"
      @formAdded="updateValue($event)"
      @updateRowValues="updateValue($event)"
      @dynamicRequiredUpdate="updateRequiredFields"
      :passedFilters="passedParams"
    >
    </LinkedFormsCard>
  </div>
  <div v-else-if="type==='LinkedSiblingsCard'">
    <LinkedFamilyCard
       :rowID="rowID"
       :value="value"
       :serviceName="serviceName"
       :fieldID="fieldID"
       @familyAdded="updateValue($event)"
    ></LinkedFamilyCard>
  </div>
  <div v-else-if="type=='LinkedFamilyCard'">
    <LinkedFamilyMembersCard
       :rowID="rowID"
       :value="value"
       :serviceName="serviceName"
       :fieldID="fieldID"
       @familyMemberAdded="updateValue($event)"
    ></LinkedFamilyMembersCard>
  </div>
  <div v-else-if="type==='Calculation'">
    <span>{{ value }}</span>
  </div>
  <div v-else-if="type==='ReferralMappingCard'">
    <ReferralMappingCard
        :rowID="rowID"
       :value="value"
       :serviceName="serviceName"
       :fieldID="fieldID"
       :rowData="rowData"
    ></ReferralMappingCard>
  </div>
  <div v-else-if="type==='ApprovalCriteriaCard'">
      <ApprovalCriteriaCard
        :rowID="rowID"
        :value="value"
        :serviceName="serviceName"
        :fieldID="fieldID"
        :rowData="rowData"
      ></ApprovalCriteriaCard>
  </div>
  <div v-else-if="type==='OverdueCriteriaCard'">
      <OverdueCriteriaCard
          :rowID="rowID"
        :value="value"
        :serviceName="serviceName"
        :fieldID="fieldID"
        :rowData="rowData"
      ></OverdueCriteriaCard>

  </div>


  <!-- Checkbox Group - Switches -->
  <b-form-checkbox-group
    v-else-if="typeIncludes('Switches')"
    :id="id"
    :options="options"
    switches
    :stacked="stacked"
    :checked="value"
      :disabled="disabled || readOnly"
    @change="$emit('input', $event)"
    @click.native.stop=""
  ></b-form-checkbox-group>

  <b-form-rating v-else-if="type === 'Rating'"
    no-border
    variant="warning"
    :value="value"
    @change="$emit('input', $event)"
  />

  <treeselect v-else-if="type === 'TreeselectSingle'" :value="value" :multiple="false" :options="options" @input="updateValue" />
  
  <treeselect v-else-if="type === 'TreeselectMultiple'" :value="value" :multiple="true" :options="options" @input="updateValue" />

  <ListViewPicker
    v-else-if="type === 'ListUserFileCategory' || type === 'ListUserFileTag'
      || type === 'ListLocationFileCategory' || type === 'ListLocationFileTag'
      || type === 'ListLocationContactCategory' || type === 'ListLocationContactTag'
      || type === 'ListLocationFile' || type === 'ListUserFile' || typeIncludes('ListLocationTxmy')
      || type === 'ListDeploymentCollaborators' || typeIncludes('ListStaff-Badge') || typeIncludes('ListTodo')
      || typeIncludes('ListLocationCollaboration') || typeIncludes('ListLocationHDS')
      || typeIncludes('ListEmergencyMember') || typeIncludes('ListStaff-NoBadge')
      || typeIncludes('ListOrgTxmy') || typeIncludes('ListCollectionFile') || typeIncludes('ListEmailTemplate') || typeIncludes('ListSelect')
      || typeIncludes('ListResultFile')"
    v-bind="lvParams"
    :value="value"
    :localData="localData"
    :key="refreshComponent"
    :rowData="rowData"
    :disabled="disabled || readOnly"
    @input="$emit('input', $event)"
    @tags-reviewed="$emit('tags-reviewed',$event)"
    :fieldID="fieldID">
    <template v-slot:selectionDisplay>
    
      <ListViewPickerBadge v-show="lvParams && !!!lvParams.hideBadges" :value="value" @removeListViewBadgeItem="removeItemByID(null, $event,lvParams,fieldID)" />
    </template>
  </ListViewPicker>

  <ListViewPicker
    v-else-if="(type === 'ListExtReferralContact' || type==='ListLocationEHR' || type==='ListLocationEHRSingle' || type==='AdditionalLocation' || type==='ListStaff' || type==='ListClient' || type==='ListCustomFieldAnswerPicker' || type==='ListLocationAnswerPicker') && !displayOnly"
    v-bind="lvParams"
    :value="value"
    :key="refreshComponent"

    :rowData="rowData"
    :disabled="disabled || readOnly"
    @input="$emit('input', $event)"
        :fieldID="fieldID">

  >
    <template v-slot:selectionDisplay>
      
      <b-col v-if="lvParams && lvParams.showInForm" sm="12">
       <b-row v-for="(item, i) in value" :key="`lv_${i}`">
        
              <ListViewPickerBadge v-show="lvParams && !!!lvParams.hideBadges" :value="value" @removeListViewBadgeItem="removeItemByID(null, $event,lvParams,fieldID)" />

      </b-row>

      </b-col>
     
    </template>
  </ListViewPicker>
  <div v-else-if="(type === 'ListExtReferralContact' || type==='ListLocationEHR' || type==='ListLocationEHRSingle' || type==='AdditionalLocation' || type==='ListStaff' || type==='ListClient' || type==='ListCustomFieldAnswerPicker' || type==='ListLocationAnswerPicker') && displayOnly">
   
    <b-badge
        v-for="(selectedValue, index) in value"

        :key="index"
        v-show="selectedValue.ID.length>0"
        pill
        style="white-space: normal"
        variant="primary"
        :class="[
          'tag',
          tagClass,
          'animation__fadeIn--bottomLeft',
          'animation__duration--fast',
        ]"
      >
        <div class="d-flex align-items-center" >
         
          <span
            :id="`${id}_${index}_ms_${getTagText(selectedValue)}`"
            class="tag__text"
            :class="tagTextClass"
            style="overflow: hidden;text-overflow: ellipsis;"
            >{{ getTagText(selectedValue) }}
          </span>
          <b-tooltip
            v-if="
              type === 'MultiSelectStartEndDate' ||
                type === 'DropdownStartEndDate'
            "
            :target="`${id}_${index}_ms_${getTagText(selectedValue)}`"
            >{{ getTagText(selectedValue) }}</b-tooltip
          >
          <div
            v-if="
              type === 'MultiSelectStartEndDate' ||
                type === 'DropdownStartEndDate'
            "
            class="d-flex"
            style="color: #000"
          >
            <div
              v-for="(tagFormDateName,
              tagFormDateNameIndex) in tagFormDateNames"
              :key="
                `${selectedValue['ID']}_{tagFormDateName}_${tagFormDateNameIndex}`
              "
            >
              <datepicker
                bootstrap-styling
                :format="customDateFormatter"
                :input-class="
                  `form-control-sm m-1 bg-white ${!isValidInput(
                    selectedValue[tagFormDateName],
                    tagFormDateName === 'StartDate'
                  ) && 'form__input--invalid-bg'}`
                "
                :placeholder="tagFormDateName"
                :value="selectedValue[tagFormDateName]"
                @input="
                  fixDateInsideObject($event, tagFormDateName, selectedValue)
                "
              />
            </div>
          </div>
      
        </div>
      </b-badge>
  </div>
  <ListViewPicker
    v-else-if="type==='StaffListWithTag'"
    v-bind="lvParams"
    :value="value"

    :rowData="rowData"
    :disabled="disabled || readOnly"
    @input="$emit('input', $event)"
        :fieldID="fieldID">

  >
    <template v-slot:selectionDisplay>
      
      <b-col v-if="lvParams && lvParams.showInForm" sm="12">
       <b-row v-for="(item, i) in value" :key="`lv_${i}`">
        
        <b-col
        v-for="(text, j) in item.text"
        :key="`lv_${i}_${j}`"
        :sm="lvParams.size?lvParams.size:(Array.isArray(item.text) && item.text.length > 1 ? Math.floor(12 / item.text.length - 1) : 3)"
        >
          {{ text }}
        </b-col>
        <b-col sm="1">
            <b-icon-x
              role="button"
              @click="removeItemByID($event, item,lvParams,fieldID)"/>
        </b-col>
      </b-row>

      </b-col>
     
    </template>
  </ListViewPicker>
  <div v-else-if="type==='CopyToClipboard'">
    <b-row>
      <b-col md="11">
        <b-form-textarea style="border:none; outline:none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;resize:none" readonly="readonly"  rows="5" :id="'copyItemTarget_'+fieldID" :value="value"></b-form-textarea>
      </b-col>
      <b-col md="1">
        <b-button :id="'copyToClipboardBttn_'+fieldID" @click="copyToClipboardClick(fieldID)" ><b-icon icon="clipboard-plus"></b-icon></b-button>
         <b-tooltip  :target="'copyToClipboardBttn_'+fieldID" triggers="hover">
          {{ $t("InputFactory.copy") }} 
          </b-tooltip>
      </b-col>
    </b-row>
  </div>
  <div v-else-if="type==='OccupancyGroupCard'">
      <OccupancyGroupCard
          :fieldID="fieldID"
          :rowData="rowData"
          :value="value"
          :rowID="rowID"
          :serviceName="serviceName"
          :passedFilters="passedParams"
          :key="refreshComponent"
          @input="updateValue($event)"

      ></OccupancyGroupCard>
  </div>
  <div v-else-if="type==='MultiRowField'">
      <MultiRowCard
          :readOnly="readOnly"
          :fieldID="fieldID"
          :rowData="rowData"
          :value="value"
          :rowID="rowID"
          :serviceName="serviceName"
          :passedFilters="passedParams"
          @rowUpdated="updateValueMultiRow($event)"
          :childFields="childFields"
          :buttonText="buttonText"

      ></MultiRowCard>

  </div>
  <div v-else-if="type=='StaffManagementGrid' || type=='ChildLocationsGrid' || type=='CallLogUpdatesGrid'" >
    <GridManagementCard
          :readOnly="readOnly"
          :fieldID="fieldID"
          :rowData="rowData"
          :value="value"
          :rowID="rowID"
          :serviceName="serviceName"
          :passedFilters="passedParams"
          :childFields="childFields"
          :buttonText="buttonText"
          v-bind="gridParams"
          @input="updateValue($event)"

    ></GridManagementCard>
  </div>

  <div v-else-if="type=='StaffContactType'">
      <b-form-checkbox-group
        :id="id"
        :options="options"
        switches
        :checked="value"
         :disabled="disabled || readOnly"
        @change="$emit('input', $event)"
        @click.native.stop=""
      ></b-form-checkbox-group>
  </div>
  <p v-else></p>
</template>
<script>
import { API_URL } from '@/config';
import Datepicker from 'vuejs-datepicker';
import Editor from "@/components/TinyMCE";
import { VueEditor } from 'vue2-editor';
import ImageUploader from 'vue-image-upload-resize';
import PermissionsCard from '@/components/PermissionsCard';
import LinkedEventsCard from '@/components/LinkedEventsCard.vue';
import LinkedFormsCard from '@/components/LinkedFormsCard.vue';
import LinkedFamilyCard from '@/components/LinkedFamilyCard.vue';
import ReferralMappingCard from '@/components/ReferralMappingCard.vue';
import LinkedFamilyMembersCard from '@/components/LinkedFamilyMembers.vue';
import OccupancyGroupCard from '@/components/OccupancyGroupCard.vue';
import moment from 'moment';
import lodash from 'lodash';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { deepCopy, isFile, isObject, isStringAndNotEmpty } from '@/utils/langUtils';
import { compressImage } from '@/utils/imageUtils';
import ListViewPickerBadge from '@/components/ListViewPickerBadge.vue';
import ApprovalCriteriaCard from '@/components/ApprovalCriteriaCard.vue';
import OverdueCriteriaCard from '@/components/ApprovalOverdueCriteriaCard.vue';
import MultiRowCard from '@/components/MultiRowCard.vue';
import FileThumbnailAdapter from '@/components/FileThumbnailAdapter.vue';
import GridManagementCard from '@/components/GridManagementCard.vue';

export default {
  name: 'InputFactory',
  components: { Datepicker, Editor, ImageUploader, VueEditor, PermissionsCard,LinkedEventsCard,LinkedFormsCard, ListViewPicker: () => import('@/components/ListViewPicker.vue'), Treeselect, ListViewPickerBadge,LinkedFamilyCard, ReferralMappingCard, LinkedFamilyMembersCard, OccupancyGroupCard, ApprovalCriteriaCard,OverdueCriteriaCard,MultiRowCard,FileThumbnailAdapter,GridManagementCard},
  watch:{
    value:{
      deep:true,
      handler:function(){
        this.refreshComponent++;
      }
    }
  },
  props: {
    fieldID: String,
    id: String,
    label: String,
    labelClass: { type: String, default: '' },
    options: Array,
    placeholder: { type: String, default: '' },
    required: [Boolean, String],
    numericOnly:[Boolean, String],
    filterCurrentRecord:{type: Boolean, default:false},
    tagClass: { type: String, default: '' },
    tagTextClass: { type: String, default: '' },
    type: String,
    value: [String, Date, Array, Object, File, Boolean, Number],
    tagContainerClass: { type: String, default: '' },
    showCheckHeader: { type: Boolean, default: true },
    showEmptySelect: { type: Boolean, default: true },
    showSelectAll: { type: Boolean, default: true },
    isEditable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    disableSelectFirstSlot: { type: Boolean, default: false },
    stacked: { type: Boolean, default: false },
    lvParams: { type: Object, default: null },
    rowID:{type: String,default:null},
    rowData:{type: Object,default:function(){return {}}},
    serviceName:{type: String, default:null},
    passedParams:{type:Object,default:function(){return {}}},
    children:{type:Array,default:function(){return []}},
    readOnly:{ type: Boolean, default: false },
    cssClasses:{type: String, default: null },
    allowClearButton:{type:Boolean, default:false},
    disabledDates:{type: Object,default:function(){return {}}},
    isVisible:{type: Boolean, default:true },
    childFields:{type:Array, default:function(){return []}},
    buttonText:{type: String, default:null},
    gridParams:[Array, Object],
    refreshValue:{type: String, default:null},
    displayOnly:{type:Boolean,default:false},
    setEmptyNull:{type: Boolean, default:false},
    additionalParams:{type:Object,default:function(){return {}}},



  },
  created() {
    if (this.type === 'TimePicker' && this.value === 'NOW') {
      const date = new Date();
      this.$emit('input', `${date.getHours()}:${date.getMinutes()}`);
    }
    if (this.type === '6' && this.value === 'NOW') {
      this.fixDate(new Date());
    }

    if(this.disabledDates){

     
      if(this.disabledDates.to){
          this.disabledDatesLocal.to=new Date(this.disabledDates.to);
      }

      if(this.disabledDates.from){
          this.disabledDatesLocal.from=new Date(this.disabledDates.from);

      }

     
    }
      


    
  },
  mounted() {

  
      if(this.type=='TimePickerInput' && this.value){
        this.localValue=deepCopy(this.value);
        this.timePickerValue=deepCopy(this.value);
      }

  },
  data() {
    return {
      inputError: '',
      tagFormDateNames: ['StartDate', 'EndDate'],
      apiURL: API_URL,
      showImage: true,
      imagePickerKey: 1,
      fileProcessingMessage: "",
      showEmptySelectVar:false,
      disabledDatesLocal:{},
      refreshComponent:0,
      localValue:null,
      timePickerValue:null,
      fileValue:null,
      localData:[]

    };
  },
  computed: {
    emptyValue(){
        if(this.setEmptyNull){
          return null;
        }else{
          return "";
        }
    },
    availableOptions(){
        if(this.filterCurrentRecord){

          return this.options.filter(option=> option.value !=this.rowID);
        }else{
          return this.options;
        }

    },

    optionText(){
        if(this.options){
          if(Array.isArray(this.value)){

          }else{
              let selectedOption = this.options.find(optionElem =>optionElem.value==this.value);

           
              if(selectedOption){
                return selectedOption.text;
              }else{
                return "";
              }
          }
        }else{

          return "";
        }
    },
    validation() {
      if (
        this.$store.state.isSubmitting &&
        this.required !== undefined &&
        this.required === '1'
      ) {
        if (this.value === undefined || this.value === null) return false;
        if (typeof this.value === 'string' || this.value instanceof String) {
         
        
              return this.value.trim().length > 0;
         
         
        }
        if (Array.isArray(this.value)) {
          return this.value.length > 0;
        }
      }

      return true;
    },
    validateIsNumeric(){
      if(this.$store.state.isSubmitting && this.numericOnly!== undefined && this.numericOnly==='1' ){
        
              if(this.value){

                return !isNaN(this.value);
            }
      }
      return true;
    },
     validateTimeFormat(){

      if(this.$store.state.isSubmitting ){
        
            if(this.value){

                return this.value.match(/(([0-9]):[0-5][0-9] (AM|PM|am|pm))|(0([0-9]):[0-5][0-9] (AM|PM|am|pm))|(1([0-2]):[0-5][0-9] (AM|PM|am|pm))/)?true:false;
            }
      }
      return true;
    },
    chevronState(){
          return elementID =>{ 


            if($('#'+elementID).length>0 && $('#'+elementID).hasClass("not-collapsed")){
                  return 'chevron-down';

            }else{
                  return 'chevron-right';
            }
          }

    }
  },
  methods: {
   

    getCollapsedList(numToShow){
      var returnList=new Array;
      var i=0;

      if(numToShow){
        
       

        

       returnList=this.value.filter((value,index) =>{ return index>=numToShow});
        
        
                


      }else{
          returnList=this.value;
      }
   

      return returnList;

    },
    copyToClipboardClick(fieldID){
         try {
          $("#copyItemTarget_"+fieldID).val(this.value);
          $("#copyItemTarget_"+fieldID).focus();
          $("#copyItemTarget_"+fieldID).select();

          var successful = document.execCommand('copy');
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
    },
    refreshForm(){
      this.$emit('refresh-add-edit');
    },
    isString(value) {
      return lodash.isString(value);
    },
    isValidInput(newValue, required) {
      if (required && !lodash.isNil(newValue)) {
        if (typeof newValue === 'string' || newValue instanceof String)
          return newValue.trim().length > 0;
        if (Array.isArray(newValue)) return newValue.length > 0;
      }
      return true;
    },
    timeFormatter(newValue) {
      if (this.type === 'TimePicker' && isStringAndNotEmpty(newValue)) {
        const time_arr = newValue.split(':');
        this.$emit('input', `${time_arr[0]}:${time_arr[1]}`);
      } else {
        this.$emit('input', $newValue);
      }
    },
    
    fixDate(newValue) {
      const fixedDate = this.fixDateFormat(newValue);
      this.$emit('input', fixedDate);
    },
    fixDateInsideObject(newValue, propertyName, objectValue) {
      objectValue[propertyName] = this.fixDateFormat(newValue);
      const clonedValue = JSON.parse(JSON.stringify(this.value));
      this.$emit('input', clonedValue);
    },
    fixDateFormat(newValue) {
      if (
        !lodash.isNil(newValue) &&
        newValue instanceof Date &&
        typeof newValue.getMonth === 'function'
      ) {
        return moment(newValue).format('MM/DD/YYYY');
      }
      return newValue;
    },
    customDateFormatter(date) {
      return moment(date).format('MM/DD/YYYY');
    },
    processImage(img) {
      const fileName = this.$refs['imagePickerRef'].currentFile.name;
      const ext = this.isString(fileName)
        ? fileName.slice(
            (Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1
          )
        : '';
      if (ext === 'jpeg' || ext === 'jpg' || ext === 'png') {
        this.inputError = '';
        const file = new File([img], fileName);
        this.$emit('input', file);
      } else {
        this.inputError = 'Invalid file. Please select a JPG or PNG file.';
        this.removeImage();
      }
    },
    removeImage(e) {
      this.showImage = false;
      this.$emit('input', null);
      this.imagePickerKey++;
    },
    getTagText(selectedValue) {
      const isValidObj = isObject(selectedValue) && selectedValue.ID;

      
      if (isValidObj) {
      

        if(typeof selectedValue.text !=='undefined' && typeof selectedValue.text.Name !=='undefined'){
          console.log(selectedValue.text.Name);
          return selectedValue.text.Name;
        }else{

          for (let option of this.options) {
            if (
              option.value &&
              option.value.ID &&
              option.value.ID === selectedValue.ID
            )
              return option.text;
          }


        }
        
      } else {
        for (let option of this.options) {
          if (lodash.isEqual(option.value, selectedValue)) return option.text;
        }
      }
      return '';
    },
    calculateTotalItems(numToShow){
      if(numToShow){

        if(this.value.length<=numToShow){
          return this.value.length;
        }else{
          return this.value.length-numToShow;

        }

      }else{
        return this.value.length;
      }

    },
    onTreeselectSingleChange(value, instanceId) {
      this.value=value;
    },
    onTreeselectMultipleChange(value, instanceId) {
      this.value=value;
    },
    onSelectTagChange(event) {

      this.refreshComponent++;
      if (event === '') {
        this.$emit('input', []);
        this.inputError = '';
      } else if (event === '-99') {
        const newValue = this.options.map(option =>
          isObject(option.value) ? deepCopy(option.value) : option.value
        );
        this.$emit('input', newValue);
      } else if (this.type === 'DropdownStartEndDate') {
        for (const val of this.value) {
          if (!val || val.ID !== event.ID) return;
          if (val.ID === event.ID && val.EndDate === '') {
            this.inputError =
              'Item was already selected. Please add an end date to your selection.';
            return;
          }
        }
        const newValue = deepCopy([...this.value, event]);
        this.$emit('input', newValue);
      } else if (this.type === 'MultiSelectStartEndDate') {
        for (const val of this.value) {
          if (!val) return;
          if (val.ID === event.ID && val.EndDate === '') {
            this.inputError =
              'Item was already selected. Please add an end date to your selection.';
            return;
          }
        }
        const newValue = deepCopy([...this.value, event]);
        newValue.sort((a, b) => a.ID - b.ID);
        this.$emit('input', newValue);
        this.inputError = '';
         } else if(event=='all'){
          var vm=this;


          var ItemsToAdd=new Array;
          $.each(this.options,function(key,optionValue){

              if(optionValue.value !='all'){
                  if (vm.value.filter(val => lodash.isEqual(val, optionValue.value)).length == 0) {
                        
                     const  valueToAdd = isObject(optionValue.value)
                        ? deepCopy([...ItemsToAdd, optionValue.value])
                        : [...ItemsToAdd, optionValue.value];

                      ItemsToAdd=valueToAdd;


                       
                  }

              }
          });



        

        const newValue = this.value;

        $.each(ItemsToAdd,function(key, item){
          newValue.push(item);

        });


         

        this.$emit('input', newValue);

         
           

      } else if (
       
          this.value.filter(val => lodash.isEqual(val, event)).length == 0
      ) {
        const newValue = isObject(event)
          ? deepCopy([...this.value, event])
          : [...this.value, event];

        this.$emit('input', newValue);
      }
    },
    isSelected(optionValue) {
      if (Array.isArray(this.value)) {
        const result =
          this.value.filter(val => lodash.isEqual(val, optionValue)).length > 0;
          

        return result;
      } else {

        return lodash.isEqual(this.value, optionValue);
      }
    },
    removeTag(event, selectionToRemove) {
      const newValue = isObject(selectionToRemove)
        ? deepCopy(this.value)
        : this.value;

              this.refreshComponent++;

      this.$emit(
        'input',
        newValue.filter(ele => !lodash.isEqual(ele, selectionToRemove))
      );
    },
    getItemsFromValue() {
      let items = [];
      if (Array.isArray(this.value) && this.value.length > 0) {
        for (const item of this.value) {
          if (isObject(item['text'])) {
            const contents = [];
            for(const content in item['text']) {
              contents.push(item['text'][content]);
            }
            items.push(contents.join('- '));
          }
        }
      }
      return items;
    },
    getTextFromValue() {
      const items = this.getItemsFromValue();
      if (items.length > 0) {
        return items.join(', ');
      }
      return '';
    },
    removeItemByID(event, selectionToRemove,lvParams) {
      const newValue = isObject(selectionToRemove)
        ? deepCopy(this.value)
        : this.value;

          if(lvParams.allowMultipleValues){
              this.refreshComponent++;
          }

      this.$emit(
        'input',
        newValue.filter(ele => !lodash.isEqual(ele.ID, selectionToRemove.ID))
      );
    },
    updateValue(data){

      this.refreshComponent++;

          this.$emit("update-row-data",{data:data,fieldName:this.id});


    },
    updateValueMultiRow(data){
                this.$emit("update-row-data",{data:data,fieldName:this.id});

    },
    updateRequiredFields(data){
            this.refreshComponent++;

       this.$emit("addDynamicRequired",{data:data,fieldName:this.id});
    },

    async handleFileInput(dirtyFile) {
      this.fileProcessingMessage = "Processing... Please wait.";
      let result = null;
      if (isFile(dirtyFile)) {
        // if (dirtyFile.type && /^image/.test(dirtyFile.type)) {
        //   result = await compressImage(dirtyFile);
        // } else {
          result = dirtyFile;
        // }
      }

      if (result instanceof File || !dirtyFile) {
        this.fileProcessingMessage = "";
      }
      this.refreshComponent++;


      if (result) {
        this.$emit('input', result);
      }
    },
    onClickDialogOk() {
      this.showDialog = false;
    },

    onClickDialogCancel() {
      this.showDialog = false;
    },

    onClickChoose() {
      this.showDialog = true;
    },
    typeIncludes(keyword) {
      return isStringAndNotEmpty(this.type) && this.type.includes(keyword);
    },
    typeStartWith(keyword) {
      return isStringAndNotEmpty(this.type) && this.type.startsWith(keyword);
    },
     refreshCollapse(){
      var vm=this;
      setTimeout(function(){
        vm.$forceUpdate();

      },100);
    },
    getFormInputType() {
      let text = "text";
      if (this.type == '9') text = 'number';
      else if (this.type === 'Password') text = 'password';
      return text;
    },
    removeFile() {
      this.fileValue = [];
      this.$emit('input', 'REMOVE');
    }
  },
};
</script>