import auth from '@/router/middleware/auth';
import ModalView from '@/components/ModalView';

const Calendar = r =>
  require.ensure([], () => r(require('./index')), 'calendar-bundle');

export default [
  {
    path: '/upcoming-events/:service',
    name: 'upcoming-events',
    component: Calendar,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: ':componentName/:cservice/:rowID/',
        name: 'upcoming-events-modal',
        component: ModalView,
      },
 
    ],
  },
];
