// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiTechnicalAssistanceDataTracking/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiTechnicalAssistanceDataTracking/getRows', payload).then(getData);

export const getRow = (rowID) => http.get(`apiTechnicalAssistanceDataTracking/getRow&RowID=${rowID}`).then(getData);

export const create = (row) => http.post('apiTechnicalAssistanceDataTracking/addRow', row).then(getData);

export const remove = (row) => http.post('apiTechnicalAssistanceDataTracking/deleteRow',row).then(getData);

export const update = (row) => http.post('apiTechnicalAssistanceDataTracking/editRow',row).then(getData);