<template>
  <p v-if="!isValid()">{{ $t("HtmlTagFactory.error") }}</p>
  <b-icon
    v-else-if="item.type === 'icon'"
    :icon="item.icon"
    :class="item.class"
  />
  <LinkifyText
    v-else-if="item.linkify"
    :cssClass="item.class"
    :htmlTag="getTextType()"
    :text="item.text"
  />
  <component v-else :is="getTextType()" :class="item.class">
    {{ item.text }}
  </component>
</template>
<script>
import LinkifyText from '@/components/LinkifyText.vue';
import { isObject } from '@/utils/langUtils';

export default {
  name: 'HtmlTagFactory',
  components: { LinkifyText },
  props: {
    item: { type: Object, default: null },
  },
  methods: {
    isValid() {
      return isObject(this.item) && !!this.item['type'];
    },
    getTextType() {
      const accept = ['p', 'div', 'span', 'h3'];
      if (accept.includes(this.item.type)) return this.item.type;
      return null;
    },
  },
};
</script>
