import auth from '@/router/middleware/auth';

const printViews = r => require.ensure([], () => r(require('./referralReport')), 'print-views-bundle');

export default [
  {
    path: '/printViews/:modelID/:vacancyRequest?',
    name: 'printViews',
    component: printViews,
    meta: {
      middleware: [auth]
    }
  },
  

]; 
