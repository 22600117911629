<template>
  <div v-if="isChild" class="d-sm-flex align-items-center justify-content-between w-100">
    <h4 class="mb-0">{{ headerText }}</h4>
  </div>
  <div v-else-if="isGridField" class="d-sm-flex align-items-center justify-content-between w-100">
    <h5 class="mb-0">{{ headerText }}</h5>
  </div>
   <div v-else class="d-sm-flex align-items-center justify-content-between mb-2">
      <h1 class="mb-0">{{ headerText }}</h1>
    </div>
</template>

<script>
export default {
  props: {headerText:{type: String},isChild:{type:Boolean,default:false},isGridField:{type:Boolean,default:false}}
};
</script>
