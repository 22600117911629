<template>
  <div :key="UpdateTime">

     <FormCard v-for="(field,index) in fields" :key="'cat_'+index"
        headerClass="global-primary__bg global-primary__bg--no-hover"
      >
        <template v-slot:header>{{ field.title }} </template>
        <template v-slot:body>
          <b-row>
            <b-col
              sm="12"
              md="6"
              :xl="getXl(field.Type, field.BCol)"
              v-for="(field, j) in field.fields"
              :key="`${field.Name}${j}`"
            >
              <b-form-group v-if="field.copyToClipboard" :label="field.text+':'">
                  <b-row>
                    <b-col md="11">
                      <b-form-textarea style="border:none; outline:none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;resize:none" readonly="readonly"  rows="5" :id="'copyItemTarget_'+field.ID" :value="field.value"></b-form-textarea>
                    </b-col>
                    <b-col md="1">
                      <b-button :id="'copyToClipboardBttn_'+field.ID" @click="copyToClipboardClick(field.ID,field.value)" ><b-icon icon="clipboard-plus"></b-icon></b-button>
                      <b-tooltip  :target="'copyToClipboardBttn_'+field.ID" triggers="hover">
                        {{ $t("DataOnlyCard.copy") }} 
                        </b-tooltip>
                        <b-button style="margin-top:20px" v-if="field.generateNewUri" :id="'generateNewBttn_'+field.ID" @click="generateNewClick(index,j)" variant="success"><b-icon icon="pencil-square"></b-icon></b-button>
                         <b-tooltip  :target="'generateNewBttn_'+field.ID" triggers="hover">
                          {{ $t("DataOnlyCard.generate") }}
                        </b-tooltip>
                    </b-col>
                  </b-row>
              </b-form-group>
              <b-row v-else-if="Array.isArray(field.value)">
                      <b-col
                        v-for="(fieldValue,fieldIndex) in field.value" :key="'cards_'+j+'_'+fieldIndex"
                        :cols="4"
                      >
                       <b-card no-body class="card__border shadow mb-3" >
                          <b-card-body>
                             <h5 class="border-bottom pb-3" role="banner">{{ fieldValue.text }}</h5>
                            <div style="overflow:hidden;word-break:break-word" v-html="fieldValue.value"> </div>
                          </b-card-body>
                        </b-card>

                      </b-col>

              </b-row>
              <b-form-group v-else :label="field.text+':'">
                  <div v-html="field.value"></div>
              </b-form-group>
            </b-col>
          </b-row>
        </template>
      </FormCard>
  </div>
</template>
<script>
import FormCard from '@/components/FormCard';
import {API_URL} from '@/config';


import { ORG_ID } from '@/config';
export default {
  name: 'FormFieldsCard',
  components: {
    FormCard
  },
  props:{
    fields:[Array,Object],
    

  },
  data() {
    return {
      ORG_ID: ORG_ID,
      UpdateTime:null
    };
  },
  mounted(){
  },
  methods:{
    
     generateNewClick(catIndex,fieldIndex){


       this.$http.post(API_URL+this.fields[catIndex].fields[fieldIndex].generateNewUri)
       .then(response =>{
         
          this.fields[catIndex].fields[fieldIndex].value=response.data.data.newValue;
          this.fields[catIndex].fields[fieldIndex].generateNewUri=null;

            
            this.UpdateTime+=1;

       });
       




     },
     copyToClipboardClick(fieldID,value){
         try {
          $("#copyItemTarget_"+fieldID).val(value);
          $("#copyItemTarget_"+fieldID).focus();
          $("#copyItemTarget_"+fieldID).select();

          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
    },
    getXl(type, bcol=null) {
      if (bcol)
        return bcol;
      if (type === 'MultiSelectStartEndDate' || type === 'DropdownStartEndDate')
        return '6';
      if (type === '4' || type === 'Editor') return '12';
      return '4';
    }

    
    
    
    
    
  }
};
</script>
