<template>
  <b-modal
    :id="'modal_'+id"
    :size="size"
    visible
    hide-footer
    scrollable
    :centered="centeredModal"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :content-class="contentClass"
    :dialog-class="dialogClass"
    :body-class="bodyClass"
    :modal-class="modalClass"

  >
    <template v-slot:modal-header>
      <Menubar
        :cancelTitle="cancelTitle"
        :okTitle="okTitle"
        :showCancelBtn="showCancelBtn"
        :showOkBtn="showOkBtn"
        :menuBtnsData="menuBtnsData"
        @onClickOk="$emit('onClickOk', $event)"
        @onClickCancel="$emit('onClickCancel', $event)"
        @onClickMenuBtn="$emit('onClickMenuBtn', $event)"
        @onError="$emit('onError', $event)"
      />
    </template>
    <div class="container-fluid bg-white">
      <div>
        <slot name="dialogContent" />
      </div>
    </div>
  </b-modal>
</template>
<script>
import Menubar from '@/components/Menubar.vue';
import { apiPost } from '@/services/generic';
export default {
  name: 'ecenterDialog',
  components: {
    Menubar,
  },
  props: {
    id:{ type: String, default:'0'},
    cancelTitle: { type: String, default: 'Cancel' },
    centeredModal: { type: Boolean, default: false },
    noCloseOnBackdrop: { type: Boolean, default: false },
    okTitle: { type: String, default: 'Save' },
    showCancelBtn: { type: Boolean, default: true },
    showOkBtn: { type: Boolean, default: true },
    size: { type: String, default: 'lg' },
    menuBtnsData: { type: Object, default: null },
    getDialogPropsData: { type: Object, default: null },
    contentClass: {type: String, default :null},
    dialogClass: {type: String, default :null},
    bodyClass: {type: String, default :null},
    modalClass: {type: String, default :null}

  },
  created() {
    this.getExtProps();


  },
  methods: {
    getExtProps: async function() {
      try {
        if (!this.getDialogPropsData) return;
        const res = await apiPost(this.getDialogPropsData.url, this.getDialogPropsData);
        if (res.error) throw Error(response.message);
        if (res.cancelTitle) this.cancelTitle = res.cancelTitle;
        if (res.noCloseOnBackdrop)
          this.noCloseOnBackdrop = res.noCloseOnBackdrop;
        if (res.showCancelBtn) this.showCancelBtn = res.showCancelBtn;
        if (res.showOkBtn) this.showOkBtn = res.showOkBtn;
        if (res.size) this.size = res.size;
        if (res.menuBtnsData) this.menuBtnsData = res.menuBtnsData;
      } catch (error) {
        this.$emit('onError', error);
      }
    },
  },
  data(){
    return {
      value: null
    };
  }
};
</script>
