<template>
  <b-container class="p-5">
    <b-row class="justify-content-center h5 text-center">
      {{ this.mobileCloseDialog }}
    </b-row>
    <b-row class="justify-content-center h5 text-center">
      {{ $t("MobileLogoutPage.done") }}
    </b-row>
  </b-container>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'Logout',
  data() {
    return {};
  },
  created() {
    this.mobileCloseDialog = this.$route.params.mobileCloseDialog;
    this.recursiveLogOut();
  },
  methods: {
    recursiveLogOut() {
      this.logout().then(response => {
        if (response) {
          var vm = this;

          //recursively check to see if actually logged out due to quirk of Yii
          this.$store.dispatch('checkUserToken', true).then(response => {
            if (response !== -1) {
              this.recursiveLogOut();
            }
          });
        }
      });
    },
    ...mapActions(['logout']),
  },
};
</script>
