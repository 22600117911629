// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const get = (RowID='') =>
  http
    .get(
      `apiPermissions/getDashboardPermissions&RowID=${RowID}`
    )
    .then(getData);
