// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiCustomForm/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiCustomForm/getRows', payload).then(getData);

export const getRow = (rowID) => http.get(`apiCustomForm/getRow&ID=${rowID}`).then(getData);

export const create = (row) => http.post('apiCustomForm/create', row).then(getData);

export const update = (row) => http.post('apiCustomForm/edit', row).then(getData);

export const remove = (row) => http.post('apiCustomForm/delete', row).then(getData);

export const getEditQuestionRules = (payload) => http.post(`apiCustomForm/getEditRules`, payload).then(getData);

export const getFormValues = (ID) => http.get(`apiCustomForm/getFormValues&ID=${ID}`).then(getData);

export const submitForm = (form) => http.post(`apiCustomForm/submitForm`, form).then(getData);

export const getFormStatus = (ID) => http.get(`apiCustomForm/getFormStatus&ID=${ID}`).then(getData);