<template>
  <b-dropdown variant="light" class="btn">
    <template v-slot:button-content>
      <span class='mr-2 d-none d-lg-inline text-gray-600 small'>{{ currentUser.name }}</span>
      <img style="width: 60px;" class='img-profile rounded-circle' :src='imagePath'>
    </template>
    <b-dropdown-item to="/profile">{{ $t("general.account") }}</b-dropdown-item>
    <b-dropdown-item href="https://ecenterresearch.zendesk.com/login" target="blank">{{ $t("general.support") }}</b-dropdown-item>
    <b-dropdown-item to="/logout">{{ $t("general.logout") }}</b-dropdown-item>
  </b-dropdown>
</template>
<script>

import { BASE_URL } from '@/config';

export default {
  name: 'Login',
  data () {
    return {
      imagePath:BASE_URL+"/images/default_avatar.png"
    };
  },
  mounted(){
  }
  
};
</script>