/**
 * Color styling class. Modifies CSSOM based on color schema
 */
export default class ColorStyler {
  constructor() {
    /** @private @const */
    this.schema_ = {};
    /** @private @const */
    this.colorTypes_ = ['primary', 'secondary'];
    /** @private @const */
    this.colorProps_ = ['hue', 'sat', 'lig'];

    /*
    this.addColors([
      ['20', '89%', '54%'],
      ['213', '74%', '49%'],
    ]);
    // this.addColors('fcrs', [
    //   ['271', '38%', '43%'],
    //   ['223', '40%', '45%'],
    // ]);
    // FCRS #7170b3 hsl(241, 31%, 57%) / hsl(241, 37%, 53%) / hsl(215, 49%, 56%)
    this.addColors([
      ['241', '37%', '53%'],
      ['215', '49%', '56%'],
    ]);
    */
  }

  /**
   *
   * @param {Array<Array>} colors
   */
  addColors(colors) {
    if (colors.length !== this.colorTypes_.length) {
      return;
    }
    this.schema = {};
    for (let i = 0; i < colors.length; i++) {
      if (colors[i].length !== this.colorProps_.length) continue;
      for (let j = 0; j < colors[i].length; j++) {
        const colorName = `${this.colorTypes_[i]}-${this.colorProps_[j]}`;
        this.schema[colorName] = colors[i][j];
      }
    }
  }

  /**
   * 
   */
  setColors() {
    let bodyStyles = document.documentElement.style;
    for (const colorType of this.colorTypes_) {
      for (let i = 0; i < this.colorProps_.length; i++) {
        const colorName = `${colorType}-${this.colorProps_[i]}`;
        bodyStyles.setProperty(
          `--global-${colorName}`,
          this.schema[colorName]
        );
      }
    }
  }
}
