import { API_URL } from '@/config';
import * as clientService from '@/services/client';

export function init(Survey,vm,live =false) {
    var testEditWidget={
        name:'current-client-location',
        title:'Client\'s Current Location',
        iconName:'',
        responseData:{},
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'current-client-location';
        },
        init(){
          Survey.Serializer.addClass('current-client-location',[],null,'empty');

       
        },
        htmlTemplate:"<div style='padding-left:20px' class='current-client-location'><div class='responseValue'></div></div>",
        afterRender:function(question,el){

          $(el).css('padding-top',"10px");
     

          if(!live && $(el).closest('#surveyContainer').length>0){
            live=true;
          }
          
         if(live || vm.testModel){

          if(vm.testModel && question.TestClientNumber){
            clientService.getClientLocations(null,{ClientNumber:question.TestClientNumber,currentOnly:true})
            .then(response =>{
              testEditWidget.responseData[question.code]=response;

              var locationIDs=new Array;

              if(question.value && !question.value.locationIDs){
                question.value={locationIDs:JSON.parse(question.value)};
              }


              $.each(response,function(key,value){
                $(el).find('.responseValue').append("<div class='locationDetails' style='width:70%;'></div>");
                $(el).find('.responseValue').append("<div class='locationStart' style='padding-top:10px'></div>");

                $(el).find('.responseValue').find('.locationDetails').append("<div><span style='font-weight:bold;color:#000000'>"+value.Name+"</span></div>");
                $(el).find('.responseValue').find('.locationDetails').append("<div><span style='color:#000000'>"+value.Address+"</span></div>");
                $(el).find('.responseValue').find('.locationDetails').append("<div><span style='color:#000000'>"+value.Address2+"</span></div>");
                $(el).find('.responseValue').find('.locationDetails').append("<div><span style='color:#000000'>Email: "+value.Email+" Phone: "+value.Phone+"</span></div>");

                $(el).find('.responseValue').find('.locationStart').append("<div><span style='color:#000000'>"+value.StartDate+" to Present </span></div>");

                locationIDs.push(value.ID);
              });

              question.value={locationIDs:locationIDs,responseData:response};

              
            });

          }else if(vm.$route.params.clientID){
            clientService.getClientLocations(vm.$route.params.clientID,{currentOnly:true,locationIDs:question.value})
            .then(response =>{
              var locationIDs=new Array;
              testEditWidget.responseData[question.code]=response;

              $.each(response,function(key,value){

                $(el).find('.responseValue').append("<div class='locationDetailsContainer'></div>");
                $(el).find('.responseValue').find('.locationDetailsContainer').last().append("<div class='locationDetails' style='width:70%;'></div>");
                $(el).find('.responseValue').find('.locationDetailsContainer').last().append("<div class='locationStart' style='padding-top:10px'></div>");

                $(el).find('.responseValue').find('.locationDetailsContainer').last().find('.locationDetails').append("<div><span style='font-weight:bold;color:#000000'>"+value.Name+"</span></div>");
                $(el).find('.responseValue').find('.locationDetailsContainer').last().find('.locationDetails').append("<div><span style='color:#000000'>"+value.Address+"</span></div>");
                $(el).find('.responseValue').find('.locationDetailsContainer').last().find('.locationDetails').append("<div><span style='color:#000000'>"+value.Address2+"</span></div>");
                $(el).find('.responseValue').find('.locationDetailsContainer').last().find('.locationDetails').append("<div><span style='color:#000000'>Email: "+value.Email+" Phone: "+value.Phone+"</span></div>");

                $(el).find('.responseValue').find('.locationDetailsContainer').last().find('.locationStart').append("<div><span style='color:#000000'>"+value.StartDate+" to Present </span></div>");
                
                locationIDs.push(value.ID);


              });

              question.value={locationIDs:locationIDs,responseData:response};


             
            });


          }
        }else{
          $(el).find('.responseValue').append("<div><span style='font-weight:bold;color:#000000'>TEST MODE</span></div>");
    
        }
          
          


        

          
           
        },
        pdfQuestionType: "empty",
        pdfRender: function (survey, options) {
          if (options.question.getType() === "current-client-location") {
            const loc = new Survey.LocalizableString(survey, true);




            if(options.question.value && options.question.value.responseData){
             
            let response=options.question.value.responseData;

            let displayText="";
            $.each(response,function(key,value){

              displayText+="<div class='locationDetailsContainer'>";
              displayText+="<div class='locationDetails' style='width:70%;'>";

              displayText+="<div><span style='font-weight:bold;color:#000000'>"+value.Name+"</span></div>";
              displayText+="<div><span style='color:#000000'>"+value.Address+"</span></div>";
              displayText+="<div><span style='color:#000000'>"+value.Address2+"</span></div>";
              displayText+="<div><span style='color:#000000'>Email: "+value.Email+" Phone: "+value.Phone+"</span></div>";

              displayText+="</div>";

              displayText+="<div class='locationStart' style='padding-top:10px'>";

              displayText+="<div><span style='color:#000000'>"+value.StartDate+" to Present </span></div>";
              displayText+="</div>";
              displayText+="</div>";


            });
             
              loc.text = displayText;



            }else{
              loc.text ="";
            }
            options.question["locHtml"] = loc;

            if (
              options.question.renderAs === "standard" ||
              options.question.renderAs === "image"
            ) {
              options.question["renderAs"] = options.question.renderAs;
            } else options.question["renderAs"] = "auto";
            const flatHtml = options.repository.create(
              survey,
              options.question,
              options.controller,
              "html"
            );
            return new Promise(function (resolve) {
              flatHtml.generateFlats(options.point).then(function (htmlBricks) {
                options.bricks = htmlBricks;
                resolve();
              });
            });
          }
        }
      };
    //Register our widget in singleton custom widget collection

    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('current-client-location')){
        Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget, "customtype");
    }
    
}