// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

const CNTLR_NAME = "apiUserRequest";

export const getTableSettings = (payload = null) => http.post(`${CNTLR_NAME}/getTableSettings`, payload).then(getData);

export const get = (payload = null) => http.post(`${CNTLR_NAME}/getRows`, payload).then(getData);

export const remove = (row) => http.post(`${CNTLR_NAME}/delete`,row).then(getData);

/**
 * Convert account request into a user account
 *
 * @param payload
 * @returns {Promise<T>}
 */
export const accept = (payload) => http.post(`${CNTLR_NAME}/acceptUserRequest`, payload).then(getData);

/**
 * Deny a user account request
 *
 * @param payload
 * @returns {Promise<T>}
 */
export const deny = (payload) => http.post(`${CNTLR_NAME}/denyUserRequest`, payload).then(getData);
