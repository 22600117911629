import lodash from 'lodash';

export const setToken = (state, value) => {
  state.auth.token = value;
};
export const setUser = (state, value) => {
  state.auth.user = value;
  state.auth.isAuthorized = true;
};
export const setInitialized = state => {
  state.initialized = true;
};

export const setAuthorized = (state, value) => {
  state.auth.isAuthorized = value;
};
export const logout = state => {
  state.auth.user = {};
  state.auth.token = null;
  state.auth.isAuthorized = false;
};

export const TOGGLE_SIDEBAR = state => {
  state.sidebar.isCollapsed = !state.sidebar.isCollapsed;
};

export const RESET_SEARCH_PARAMS = state => {
  state.searchParams={};

};
export const SetTrainingMode = (state,value) => {
  state.auth.trainingMode=value;
};
export const RESET_REPORT_TYPE = state => {
  state.reportType=0;


};

export const RESET_REPORT_FILTER_PARAMS = state => {
  state.selectedReports=new Array;

  state.reportFilter={};


};


export const UPDATE_SEARCH_PARAMS = (state, payload) => {
  // update the state
  let A = payload.param.split('_');


  if (A.length > 1) {
    state[A[0]] [A[1]] = payload.value;
  } else {
    state[payload.param] = payload.value;
  }
  // update search param object
  let obj = {};
  obj[payload.param] = payload.value;
  state.searchParams = {...state.searchParams, ...obj};
};

export const REMOVE_SEARCH_PARAM = (state, payload) => {
  let A = state.searchParams[payload.param];
  let B = payload.param.split('_');

  if (Array.isArray(A)) {
    state.searchParams[payload.param] = state.searchParams[payload.param].filter(e => !lodash.isEqual(e, payload.value));
    state.searchParams = {...state.searchParams};
    if (B.length > 1) {
      state[B[0]][B[1]] = state[B[0]][B[1]].filter(e => !lodash.isEqual(e, payload.value));
    } else {
      state[payload.param] = state[payload.param].filter(e => !lodash.isEqual(e, payload.value));
    }
  } else {
    state.searchParams[payload.param] = '';
    state.searchParams = {...state.searchParams};
    if (B.length > 1) {
      state[B[0]][B[1]] = '';
    } else {
      state[payload['param']] = '';
    }
  }
};
export const REMOVE_REPORT_FILTER_PARAM = (state, payload) => {
  let A = state.reportFilter[payload.param];
  let B = payload.param.split('_');


  if (Array.isArray(A)) {
    state.reportFilter[payload.param] = state.reportFilter[payload.param].filter(e => !lodash.isEqual(e, payload.value));
    state.reportFilter = {...state.reportFilter};
    if (B.length > 1) {
      state.reportFilter[B[0]][B[1]] = state.reportFilter[B[0]][B[1]].filter(e => !lodash.isEqual(e, payload.value));
    } else {
      state.reportFilter[payload.param] = state.reportFilter[payload.param].filter(e => !lodash.isEqual(e, payload.value));
    }
  } else {
    state.reportFilter[payload.param] = '';
    state.reportFilter = {...state.reportFilter};
    if (B.length > 1) {
      state.reportFilter[B[0]][B[1]] = '';
    } else {
      state.reportFilter[payload['param']] = '';
    }
  }
};
export const REMOVE_SELECTED_REPORTS_PARAM = (state, payload) => {
   
    var index=null;
    Object.keys(state.selectedReports).forEach(key=>{
      if(state.selectedReports[key].value==payload.value){
        index=key;
      }             
                      
    });

    if(index){
      state.selectedReports.splice(index,1);
    }

};
export const CLEAR_STATE = (state, payload) => {
  for (let i = 0; i < payload.length; ++i) {
    if (Array.isArray(state[payload[i]])) {
      state[payload[i]] = [];
    } else if (typeof state[payload[i]] === 'string' || state[payload[i]] instanceof String || state[payload[i]] instanceof Date) {
      state[payload[i]] = ''; 
    } else if (!isNaN(state[payload[i]])) {
      state[payload[i]] = 0;
    } else if (typeof state[payload[i]] === 'object') {
      CLEAR_STATE(state[payload[i]], Object.keys(state[payload[i]]));
    }
  }
}

export const updateSelectedContacts = (state, payload) => {
  state.selectedContacts = payload;
}

export const SET_IS_SUBMITTING = (state, payload) => {
  state.isSubmitting = payload;
};

export const UPDATE_MAINTOOLBAR_LINKS = (state, payload) => {
  state.mainToolbarLinks = payload;
};

export const UPDATE_CAN_EMIT_EVENT = (state, payload) => {
  state.canEmitEvent = payload; 
};

export const UPDATE_CLEARING_FILTERS = (state, payload) => {
  state.clearingFilters = payload;
}

export const UPDATE_HAS_NOTIFICATIONS = (state, payload) => {
  state.globalHasNotifications = payload;
}

export const UPDATE_HAS_UPCOMING_EVENTS = (state, payload) => {
  
  state.globalUpcomingEvents = payload;
}

export const RELAUNCH_DEPLOYMENT = (state, payload) => {


  state.relauchDeployment = payload;
}
export const SET_ACCEPT_EULA = (state, payload) => {
  state.auth.acceptedEula= payload;
}

export const HIDE_NAV = (state, payload) => {
  state.auth.hideNav = payload;
}

export const HIDE_TOOLBAR = (state, payload) => {
  state.auth.hideToolbar = payload;
}

export const FROM_MOBILE = (state, payload) => {
  state.auth.fromMobile = payload;
}
export const SET_IS_CLIENT_FLAG = (state, payload) => {
  state.auth.isClient= payload;
}
export const SET_ALLOW_REQUEST_ACCOUNT=(state, payload) =>{
    
   state.allowRequestAccount=payload;
}

export const SET_IS_USINGYII = (state, payload) => {
  state.auth.isUsingYii= payload;
}
export const SET_IS_LOGGED_IN_COLLECTION = (state, payload) => {
  state.auth.collectionLogin= payload;
}

export const SET_IS_ON_COLLECTION_LOGOUT = (state, payload) => {

    state.onCollectionLogout=payload;
    
}


export const SET_IS_ON_LOCATION_LOGOUT = (state, payload) => {


  state.onLocationLogout=payload;
  
}

export const SET_IS_ON_RESULTSET_COLLAB_LOGOUT = (state, payload) => {


  state.onResultCollaborationLogout=payload;
  
}

export const SET_CRITICAL_NOTIFICATIONS_COUNT=(state,payload) =>{
  state.totalCriticalNotifications=payload;

}

export const SET_HIDE_MY_ACCOUNT=(state,payload) =>{
  state.auth.hideMyAccount=payload;

}
export const SET_DEFAULT_FILTERS=(state,payload) =>{
  state.defaultFilters=payload;
}

export const SET_ONLINE_MODE_ONLY=(state,payload) => {
  state.auth.onlineModelOnly=payload;
}

export const SET_SHARED_GRID_ONLY=(state,payload)=>{
  state.auth.sharedGridOnly=payload;

}
export const SET_HIDE_RELOAD_GRID=(state,payload)=>{
  state.hideReloadTable=payload;

}
export const DO_NOT_REFRESH_GRID=(state,payload)=>{
    state.disableReloadGrid=payload
}
export const SET_NO_HOMEPAGE=(state,payload)=>{
  state.noHomePage=payload
}

export const SET_IS_USER_COLLECTION=(state,payload)=>{
  state.auth.isUserCollection=payload
}

export const SET_ROUTE_PARAMS=(state,payload)=>{
  state.routeDetails=payload;
}
