<template>
  <b-nav-item right to="/list/notification" v-if="showNotifications">
    <Notification :key="$route.path" />
  </b-nav-item>
</template>
<script>
import Notification from './Notification.vue';
export default {
  name: 'NotificationContainer',
  components: { Notification },
  data() {
    return {
      showNotifications: false,
    };
  },
  created() {
    this.checkAccess('Notifications (VUE)')
      .then(response => {
        this.showNotifications = response;
      })
      .catch(error => console.log(error));
  },
};
</script>
