import auth from '@/router/middleware/auth';

const ReportModule = r => require.ensure([], () => r(require('./index')), 'report-module-qlik-bundle');
const printReport= r =>require.ensure([], () => r(require('./printReport')), 'report-module-qlik-print-bundle');
export default [
  {
    path: '/report-module-qlik',
    name: 'report-module-qlik',
    component: ReportModule,
    meta: {
      middleware: [auth]
    },
    children:[
      
    ]
  },
  {
    path: '/printQlikReport/:appID/:sheetID',
    name: 'printQlikReport',
    component: printReport,
    meta: {
      middleware: [auth]
    },
  }
];
