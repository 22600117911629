import { API_URL } from '@/config';
import { getData } from '@/utils/get';

export default {
  data: function() {
    return {
      imageSource: "",
    };
  },
  props: {
    pathToFetchSignedURL: { type: String, default: null },
    URL: { type: String, default: null },
    imageURL: { type: String, default: null },
  },
  methods: {
    async getImageSource() {
      try {
        if (this.pathToFetchSignedURL) {
          const response = await this.$http.get(this.pathToFetchSignedURL).then(getData);
          if (response['resourceURL']) {
            this.imageSource = response['resourceURL'];
          }
        } else if (this.URL) {
          this.imageSource = this.URL;
        } else if (this.imageURL) {
          this.imageSource = `${API_URL}${this.imageURL}dummy=${new Date().getTime()}`;
        }
      } catch (error) {
        this.imageSource = '';
      }
    },
  },
};
