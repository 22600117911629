<template>
  <b-modal
    id="custom-dialog"
    :size="form.size"
    :content-class="(form && form.contentClass) || (settings && settings.contentClass)"
    :dialog-class="(form && form.dialogClass)  || (settings && settings.dialogClass)"
    :body-class="['overflow-auto', 'form__body', 'customDialogModalBodyHeight', (form && form.bodyClass) || (settings && settings.bodyClass)]"
    :modal-class="(form && form.modalClass)  || (settings && settings.modalClass)"
    hide-footer
    visible
    no-close-on-backdrop
  >
    <template v-slot:modal-header>
      <div class="d-flex w-100">
        
          <div v-if="form && !form.displayListView && form.headerTitle" :key="refreshTitle" class='mb-0 font-weight-bold mr-auto'><h3>{{ form.headerTitle }}</h3></div>
          <div v-else class='mb-0 font-weight-bold mr-auto'></div>
        <b-btn
          v-if="(form || customFieldCategories) && ((form && form.saveBtnLabel) || (settings && settings.saveBtnLabel))"
          class="ml-2"
          variant="success"
          @click="onClickSubmit"
        >
          {{ (form && form.saveBtnLabel) || (settings && settings.saveBtnLabel) }}
        </b-btn>
        <b-btn
          v-if="form && form.surveyViewer"
          class="ml-2 generatePDF"
          variant="primary"
          @click="onGeneratePDFClicked"
        >
        {{ $t("CustomDialog.print") }} 
        </b-btn>
        <b-btn
          v-if="form && form.surveyViewer && !form.readOnly"
          class="ml-2 finishedButton"
          variant="primary"
          @click="onSurveyFinishedClick"
        >
        {{ $t("general.submit") }}
        </b-btn>
        <b-btn
          v-if="form && form.surveyViewer && !form.readOnly"
          class="ml-2 surveySaveButton"
          variant="success"
          @click="onSurveySaveClick"
        >
        {{ $t('general.save') }}
        </b-btn>
        <b-btn
          :key="refreshCancel"
          class="ml-2 customDialogCancel"
          variant="danger"
          v-show="(form && !form.hideCancel) || !form"
          @click="shareAuditUri?executeCloseAudit():$emit('onCloseCustomDialog',{refreshTable:form && form.reloadTableOnClose?form.reloadTableOnClose:(form && form.params && form.params.refreshTable?form.params.refreshTable:false)})"
          >
          
              
              
                {{ (form && form.cancelButtonText) || (settings && settings.cancelButtonText) || 'Cancel' }}          
          </b-btn>
      </div>
    </template>
    <div class="container-fluid bg-white" :key="updateCustomFieldsForm">
      <div v-if="inTrainingMode==true">
        <b-alert show style="text-align:center" variant="warning">{{ $t("CustomDialog.training") }}</b-alert>
      </div>
      <div v-if="form && form.addEdit && customFieldCategories">
         <div v-if='form.addEdit' style='height:100%'>                                                                                    
            <ChildAddEdit 
              @fetchChildFormValues="fetchChildFormValues($event)"
              @fetchFormOptions="fetchFormOptions($event)"
              @appendToDefault="appendToDefault($event)"
              @showSelectedField="showSelectedField($event)"
              @updateFacilityStatus="updateFacilityStatus($event)"
              @updateToggleRequired="updateToggleRequired($event)"
              @updateFieldText="changeFieldText($event)"
              @showCategory="showCategoryFunc"
              @makeFieldRequired="makeFieldRequired($event)"
              @refresh-add-edit-root="refreshAddEditForm('Add')"
              @hideSelectedField="hideSelectedFieldHandler($event)"
              @refreshField="refreshFieldHandler($event)"
              formType="Add"
              :formFields="formFields"
              :formFieldsHasCategories="false"
              :serverParams="serverParams"
              :rowData="rowData"
              :customFieldCategories="customFieldCategories"
              :key="refreshTable"
              v-bind="{serviceName:form && form.serviceName?form.serviceName:$route.params.service,addParams:passedFilters}"
              >
              
              </ChildAddEdit>
              <CustomDialog
                v-if="renderAfterAddDialog"
                :settings="afterAddDialogSettings"
                :serverParams="passedFilters"
                @onCloseCustomDialog="closeAfterAddDialog"
                @onSuccessCustomDialog="successAfterAddDialog"
                :key="refreshAfterAddDialog"
                ref="customAfterAddDialogRef"
              />

          </div>
      </div>
      <div v-else-if="customFieldCategories"
      :headerText="(settings && settings.headerTitle)?settings.headerTitle:''" 
      :key="refreshCustomFieldContainer">
            <div
            id="customDialogFormErrorDiv"
            v-show="formError"
            class="col text-center text-danger"
            v-html="formError"
          ></div>
          <FormCard
            headerClass="global-primary__bg global-primary__bg--no-hover"
            v-for="(cat, i) in customFieldCategories"
            :key="`${cat.Name}_${i}`"
          >
            <template v-slot:header>{{ cat.Name }}</template>
            <template v-slot:body>
          
              <b-row>
                <b-col
                  sm="12"
                  md="6"
                  :xl="getXl(field.Type, field.BCol)"
                  v-for="(field, j) in cat.customFields"
                  :key="`${cat.Name}_${field.Name}${j}`"
                  v-show="showColumn(field)"
                >
                  <b-form-group>
                    <LabelTooltip
                      v-show="field.Type != 'PermissionsCard' && field.Type!= 'LinkedEventsCard' && field.Type!='LinkedFormsCard' && field.Type!='LinkedFamilyCard'"
                      :iconID="`${field.Name}_tt`"
                      :label="field.Text"
                      :labelID="`${field.Name}_label`"
                      :required="field.Required"
                      :showTooltip="field.HelpText !== ''"
                      :tooltipText="field.HelpText"
                    />

                     
                    <InputFactory
                      :rowID="rowData.ID?rowData.ID:''"
                      :fieldID="field.FieldID"
                      :id="field.Name"
                      :type="field.Type"
                      :showCheckHeader="false"
                      :showSelectAll="false"
                      tagContainerClass="border-0 p-0"
                      :required="field.Required ? '1' : '0'"
                      :options="field.Options"
                      :disabled="settings.formReadOnly || form.formReadOnly || field.Disabled"
                      :isEditable="(settings.formReadOnly || form.formReadOnly || field.Disabled)?false:true"
                      v-model="rowData[field.Name]"
                      :passedParams="serverParams"
                      :serviceName="service"
                      @input="updateFormValues($event, field)"
                      @refresh-add-edit="refreshForm"
                      @update-row-data="updateRowValue"
                      @addDynamicRequired="updateRequiredFields"
                      :lvParams="field.ListParams"
                      :displayOnly="(customDialogParams && customDialogParams.detailViewOnly)?true:false"




                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </FormCard>

      </div>
      <div v-else-if="form && form.surveyBuilder">
         <div v-if='form.surveyBuilder' style='height:100%'>
                                                                                                         
            <SurveyBuilder :assessmentid="form.RowID" :codePrefix="form.codePrefix" :partitionPrefix="form.partitionCodePrefix" :ignoreClientQuestionOptions="form.ignoreClientQuestionOptions" :hdsUserParam="form.hdsUserParam" :questiontypes="form.questionTypes" :currentState="form.isPublished" :serverParams="serverParams" :useAdvancedEditor="form.showFullEditor || ''" :ecenterAdmin="form.ecenterAdmin" :defaultDomain="form.defaultDomain" :assessmentDomains="form.assessmentDomains" :key="refreshParams"></SurveyBuilder>

          </div>
      </div>

      <div v-else-if="form && form.surveyViewer">
         <div v-if='form.surveyViewer' style='height:100%'>
            <SurveyViewer :assessmentid="form.RowID" :assessmentType="form.assessmentType" :params="form.params" :dialogSettings="form" :key="refreshParams" @closeDialog="closeSurveyViewer" @afterFormSubmit="afterSubmitHandler"></SurveyViewer>
          </div>
      </div>
      <div v-else-if="form && form.displayDataOnlyCard">

        <DataOnlyCard :fields="form.fields"></DataOnlyCard>
      </div>
      <div v-else-if="form.displayReadOnlyForm ">
        <FormCard
            headerClass="global-primary__bg global-primary__bg--no-hover"
            v-for="(cat, i) in form.customFieldCategories"
            :key="`${cat.Name}_${i}`"
          >
            <template v-slot:header>{{ cat.title }}</template>
            <template v-slot:body>
          
              <b-row>
                <b-col
                  sm="12"
                  md="6"
                  :xl="getXl(field.Type, field.BCol)"
                  v-for="(field, j) in cat.customFields"
                  :key="`${cat.Name}_${field.Name}${j}`"
                  v-show="showColumn(field)"
                >
                  <b-form-group>
                    <LabelTooltip
                      v-show="field.Type != 'PermissionsCard' && field.Type!= 'LinkedEventsCard' && field.Type!='LinkedFormsCard' && field.Type!='LinkedFamilyCard'"
                      :iconID="`${field.Name}_tt`"
                      :label="field.Text"
                      :labelID="`${field.Name}_label`"
                      :required="field.Required"
                      :showTooltip="field.HelpText !== ''"
                      :tooltipText="field.HelpText"
                    />

                     
                    <InputFactory
                      :rowID="rowData.ID?rowData.ID:''"
                      :fieldID="field.FieldID"
                      :id="field.Name"
                      :type="field.Type"
                      :showCheckHeader="false"
                      :showSelectAll="false"
                      tagContainerClass="border-0 p-0"
                      :required="field.Required ? '1' : '0'"
                      :options="field.Options"
                      :disabled="settings.formReadOnly || form.formReadOnly"
                      :isEditable="(settings.formReadOnly || form.formReadOnly)?false:true"
                      v-model="rowData[field.Name]"
                      :passedParams="serverParams"
                      @input="updateFormValues($event, field)"
                      :lvParams="field.ListParams"




                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </FormCard>

      </div>
      <div v-else-if="form && form.displayListView">


          <Page :isChild="true" :headerText="form.heading || ''" :breadcrumbs="[]">
            <template v-slot:content>

                <ChildListView @collaboratorAdded="collaboratorAdded($event)" @gridValueApplied="gridValueApplied($event)" @shareAuditProvided="shareAuditUriProvided" @customDialogEvent="customDialogEventHandler" @closeCustomDialog="closeCustomDialog" v-if="passedFilters" :key="refreshTable"  v-bind="{isChild:true,passedServiceName:form && form.serviceName?form.serviceName:$route.params.service,rowID:form.RowID,passedParams:passedFilters,addDialogID:'CustomDialog_Add',editDialogID:'CustomDialog_Edit'}"></ChildListView>
                <ChildListView @collaboratorAdded="collaboratorAdded($event)" @gridValueApplied="gridValueApplied($event)" @shareAuditProvided="shareAuditUriProvided" @customDialogEvent="customDialogEventHandler" @closeCustomDialog="closeCustomDialog" v-else :key="refreshTable" v-bind="{isChild:true,passedServiceName:form && form.serviceName?form.serviceName:$route.params.service,addDialogID:'CustomDialog_Add',editDialogID:'CustomDialog_Edit'}"></ChildListView>
            </template>
          </Page>
      </div>
      <Page :headerText="form.heading || ''" v-else-if="form">
        <template v-slot:content>

          <div v-if="formError" class="text-danger font-weight-bold mr-auto">{{ formError }}</div>

          <p style="font-size: 1.15rem" class="font-weight-bold">
            {{ form.subheading || '' }}
          </p>
          <p>{{ form.body }}</p>
          <b-row v-if="Array.isArray(form.questions)">
            <b-col
              sm="12"
              md="6"
              xl="4"
              v-for="(question, i) in form.questions"
              :key="`confirmation_${question.Name}_${i}`"
            >
              <b-form-group>
                <LabelTooltip
                  :iconID="`${question.Name}_tt`"
                  :label="question.Text"
                  :labelID="`${question.Name}_label`"
                  :required="!question.Required"
                  :showTooltip="question.HelpText && question.HelpText !== ''"
                  :tooltipText="question.HelpText"
                />
                 
                <InputFactory
                  :id="question.Name"
                  :type="question.Type"
                  :showCheckHeader="false"
                  :showSelectAll="false"
                  :showEmptySelect="checkShowEmpty(question)"
                  tagContainerClass="border-0 p-0"
                  :options="question.Options"
                  :disabled="question.DisableEdit"
                  v-model="form.answers[`${question.Name}`]"
                  :required="question.Required ? '1' : '0'"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div v-if="Array.isArray(form.list)">
          <div v-for="(row, i) in form.list" :key="`form_list_${i}`" class="row--highlight">
            <ListItem v-if="row && row.columns" :item="row" />
          </div>
          </div>
        </template>
      </Page>
      
    </div>
  </b-modal>
</template>
<script>
import Page from '@/components/page';
import LabelTooltip from '@/components/LabelTooltip';
import ListItem from '@/components/ListItem';
import { apiPost } from '@/services/generic';
import { isEmpty } from '@/utils/isEmpty';
import { ErrorMessages } from '@/utils/errorMessages.js';
import SurveyBuilder from '@/components/SurveyBuilder';
import SurveyViewer from '@/components/SurveyViewer';
import * as customFieldService from '@/services/customField';
import FormCard from '@/components/FormCard';
import DataOnlyCard from  '@/components/DataOnlyCard.vue';
import { deepCopy } from '@/utils/langUtils';
import { API_URL } from '@/config';
import { getData } from '@/utils/get';

export default {
  name: 'CustomDialog',
  components: { Page, InputFactory:()=>import("@/components/InputFactory.vue"), LabelTooltip, ListItem, SurveyBuilder, SurveyViewer, ChildListView:()=>import('@/app/list-view/index.vue'), ChildAddEdit:()=>import('@/app/list-view/form.vue'),FormCard,DataOnlyCard },
  computed:{
    doneLoading(){

    }
  },
  data() {
    return {
      formError: '',
      form: {},
      formType:'',
      refreshParams:null,
      refreshTable:null,
      passedFilters:null,
      storeRequiredFields:[],
       tmpSavedAnswers: null,
       addRowData:{},
       customFieldCategories:null,
       rowData:{},
       renderAfterAddDialog: false,
       refreshAfterAddDialog:1000,
       afterAddDialogSettings:null,
       updateCustomFieldsForm:null,
       refreshCustomFieldContainer:null,
        refreshTitle:0,
        hideCancel:false,
        shareAuditUri:null,
        refreshCancel:0,
        beforeFinishDialog:null
     
    };
  },
  props: ["settings","serverParams","formSettings","questionModel","inPicker","customDialogParams","service","defaultData"],
  mounted(){



      if(this.settings.refreshTable){
        this.form.reloadTableOnClose=this.settings.refreshTable;
        this.refreshCancel=new Date().getTime();
      }


       
  },
  created() {

    this.formFields= new Array();


    if(this.defaultData){
      this.rowData=this.defaultData;
    }

    if (this.settings ) {


      if(this.settings.params){
       

          if(this.settings.params.defaultData){
            this.rowData=this.settings.params.defaultData;
          }


          if(this.settings.params.displayListView){
              this.form=deepCopy(this.settings.params);
          }


          if(this.settings.params.params){
              if(this.settings.params.params && this.serverParams){
                this.passedFilters={...this.settings.params.params,...this.serverParams};
              }else if(this.settings.params.params){
                  this.passedFilters=this.settings.params.params;
              
              }
          }
      }

      if(this.settings.rowData){
        this.rowData=this.settings.rowData;
      }

      if(this.settings.refreshTable){
        this.form.refreshTable=this.settings.refreshTable;
      }

      
      if(this.settings.customFieldSetName){
        if(this.settings.getFormDataUri){
                this.$http.post(this.settings.getFormDataUri,this.passedFilters).then(response => {
                      
                    this.fetchCustomFields(this.settings.customFieldSetName,this.settings.RowID,response.data)
                      
                });
         }else if(this.rowData){
                this.fetchCustomFields(this.settings.customFieldSetName,this.settings.RowID,this.rowData)

        
         }else if(this.settings.rowData){
                this.fetchCustomFields(this.settings.customFieldSetName,this.settings.RowID,this.settings.rowData)

          }else{
               this.fetchCustomFields(this.settings.customFieldSetName,this.settings.RowID)

          }
         
      }else{
          this.getData();

      }
    }else if(this.formSettings){
      this.form=this.formSettings

      if(this.form.params && this.serverParams){
        this.passedFilters={...this.form.params,...this.serverParams};
      }else if(this.form.params){
          this.passedFilters=this.form.params;
      
      }else if(this.serverParams){
        this.passedFilters=this.serverParams;
      }

      
      
    }


  },
  methods: {
  collaboratorAdded(data){
    this.$emit('onCloseCustomDialog',{refreshTable:true});


  },
  gridValueApplied(data){
  },
  afterSubmitHandler(data){

    this.$emit('onCloseCustomDialog',data)

  },
  executeCloseAudit(){
  

      this.$http.get(API_URL+this.shareAuditUri)
      .then(response=>{
          if(response.data.linkShared){


              let reloadTable=false;

              if(this.form){
                reloadTable=this.form.reloadTableOnClose;

              }else if(this.settings){

                  if(this.settings.params){
                    reloadTable=this.settings.params.reloadTableOnClose;

                  }else{
                    reloadTable=this.settings.reloadTableOnClose;
                  }

              }


              this.$emit('onCloseCustomDialog',{refreshTable:true});

          }else{
             this.$bvModal.msgBoxConfirm(response.data.message, {
                hideHeaderClose: true,
                size:"lg",
                centered: true,
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                okVariant:"success",
                cancelVariant:"danger",
                okTitle: 'Yes',
                cancelTitle: 'No',
              })
              .then(value => {
                  if(value){

                  }else{
                       this.$emit('onCloseCustomDialog',{refreshTable:true});

                  }

              });
          }

      });

  },
  shareAuditUriProvided(data){

    this.shareAuditUri=data.shareAuditUri;
  },
  closeSurveyViewer(data){
    this.$emit('onCloseCustomDialog',data)
  },
  fetchCustomFields(fieldSetName,rowID,localRowData){

  
      this.rowData=localRowData;
     if(this.form.params && this.serverParams){
            this.passedFilters={...this.form.params,...this.serverParams};
          }else if(this.form.params){
              this.passedFilters=this.form.params;
          
          }else if(this.serverParams){
            this.passedFilters=this.serverParams;
          }

        
          this.getFields(fieldSetName,rowID);
        



       
  },
  getFields(fieldSetName,rowID){
        //this.tmpSavedAnswers=this.rowData;

        

        if(this.settings.targetFields && this.passedFilters){
            this.passedFilters={...this.passedFilters,targetFields:this.settings.targetFields};
        }else if(this.settings.targetFields){
            this.passedFilters={targetFields:this.settings.targetFields};

        }

        if(this.settings.noRequired){
          this.passedFilters={...this.passedFilters,ignoreRequired:true};
        }

        
        if(this.settings.availableOptions){
          this.passedFilters={...this.passedFilters,availableOptions:this.settings.availableOptions};
        }

        if(this.settings.params){


          this.passedFilters={...this.passedFilters,params:this.settings.params};
        }

        customFieldService
          .get('',fieldSetName, rowID, this.formType,this.passedFilters,this.settings.customFieldCategoryName)
          .then(response => {


            let answers = {};
            
            for (let category of response) {
              


              //if (!Array.isArray(category.customFields)) throw new Error();

                
              for (let customField of category.customFields) {
                if (
                  this.tmpSavedAnswers &&
                  this.tmpSavedAnswers[customField['Name']]
                )
                  answers[customField['Name']] = deepCopy(
                    this.tmpSavedAnswers[customField['Name']]
                  );
                else answers[customField['Name']] = customField['DefaultValue'];

           
                // if(customField['Required']){
                //   this.validationsFromFields[customField['Name']]={ required };
                // }
                 if(!answers[customField['Name']] ){
                    
                      answers[customField['Name']]=null;
                    
                  

                }

                

                if(this.rowData && this.rowData[customField['Name']]){
                   answers[customField['Name']]=this.rowData[customField['Name']];
                }
                if(this.form && this.form.params["defaultValues"] && this.form.params["defaultValues"][customField['Name']]){
                   
                   answers[customField['Name']]=this.form.params["defaultValues"][customField['Name']];
                }
                
        
              }
            }
            

            this.rowData =answers

            this.customFieldCategories = response;

            if(this.form.params && this.form.params.readOnlyValues&&response){
              for (let category of this.customFieldCategories) {
                for (let customField of category.customFields) {
                  if(this.form.params.readOnlyValues.includes(customField.Name)){ 
                    customField.Disabled=true;
                  }
                }
              }
            }
            // Cleanup
            this.formError = '';
            this.tmpSavedAnswers = null;
          
            

            this.updateCustomFieldsForm=new Date().getTime();



          })
          .catch(error => {
            this.addRowData = {};
          });
  },
   isDoneLoading() {
      return (
       
        (this.customFieldCategories.length > 0 &&
        Object.keys(this.rowData).length > 0) 
      );
    },
    onSurveyFinishedClick(){


        if(this.form && this.form.finishedCustomConfirmation){

            this.$bvModal.msgBoxConfirm(this.form.finishedCustomConfirmation.message,{
                hideHeaderClose: true,
                size:"lg",
                centered: true,
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                okTitle: this.form.finishedCustomConfirmation.confirmedText,
                cancelTitle: this.form.finishedCustomConfirmation.cancelText,
                okVariant:'success',
                cancelVariant:'danger'
            }).then(value=>{
              if(value){
                 $('#surveyContainer').find('.sd-navigation__complete-btn').click();

              }
            });
        }else{
            $('#surveyContainer').find('.sd-navigation__complete-btn').click();

        }

    },
    onSurveySaveClick(){

        if(this.form && this.form.hideCancel){
            $('#surveyContainer').find('.sv_save_btn').data('triggerClose',1);
        }

        $('#surveyContainer').find('.sv_save_btn').click();

    },
    onGeneratePDFClicked(){
          $('#surveyContainer').find(".sd-btn[value='Save as PDF']").click();

    },
    customDialogEventHandler(data){

        if(this.form.params && this.form.params.inQuestion){

          if(data.params.tagUser){
              this.$root.$emit('updateResponse',{value:data.params.response,tagUser:data.params.tagUser,questionCode:this.form.params.questionCode,unlinking:data.params.unlinking?data.params.unlinking:null});

          }else{
              this.$root.$emit('updateResponse',{value:data.params.response,questionCode:this.form.params.questionCode,unlinking:data.params.unlinking?data.params.unlinking:null});

          }
        }

        if(data.closeSelectorDialog){
          this.closeCustomDialog(data);

        }else{
          this.$emit(data.eventName,data.params);


        }


    },
    closeCustomDialog(data){
      this.$emit('onCloseCustomDialog',{refreshTable:true});
    },
    async getData(settings) {
      settings = settings ? settings : this.settings;
      if (!settings && !settings.getDataUri) return;
      const getDataUri = settings.getDataUri;
      try {
        var postData = settings.data || {};
        if(this.serverParams){
            postData={...postData,...this.serverParams}
        }

        if(this.settings.params){
          postData={...postData,...this.settings.params}

        }


        if(this.customDialogParams){
          postData={...postData,...this.customDialogParams}

        }





        const response = await apiPost(getDataUri, postData);
        this.form = response;


        
        if(this.form.contentClass || this.form.dialogClass || this.form.bodyClass || this.form.modalClass){
          this.form.size="";
       
        }else if(!this.form.size || this.form.size.length==0){
          this.form.size="xl";
        }
        if(this.form.headerTitle){
          this.refreshTitle+=1;

        }
        if(this.form.formType){
          this.formType=this.form.formType;

        }

        


        if(this.form.params && this.serverParams){
            this.passedFilters={...this.form.params,...this.serverParams}
        }else if(this.serverParams){
            this.passedFilters=this.serverParams;
        }else if(this.form.params){
          this.passedFilters=this.form.params;
        }



        if(this.form.customFieldSetName){
            if(this.form.getFormDataUri){
                this.$http.post(this.form.getFormDataUri,this.passedFilters).then(response => {
                          this.rowData=response.data;

                          this.getFields(this.form.customFieldSetName,this.form.RowID)
                      
              });
            }else{
                  this.fetchCustomFields(this.form.customFieldSetName,this.form.RowID);

            }

        }else if(this.customDialogParams && this.customDialogParams.CustomFieldSetID){



              this.customFieldCategories=response;


        }else{
          this.customFieldCategories=null;
        }

        this.refreshParams=new Date().getTime();
        
        this.formError = '';
      } catch (error) {

        this.formError = error;
      }
    },
    validFormCF(formAnswers){
   
      
      
      for (const category of this.customFieldCategories) {
        for (const customField of category.customFields) {

          if (customField['Required'] === true) {
            if (customField['Type'] === 'FilePicker' && this.formType === 'Edit') {
              continue;
            } else if (customField['Hidden'] === true && this.formType === 'Edit') {
              continue;
            } else if (isEmpty(formAnswers[customField['Name']]) && $('[id="'+customField['Name']+'"]').is(':visible')) {

              this.formError = ErrorMessages.MISSING_FIELDS;
              return false;
            }
          }
        }
      }
      return true;
    },
    async onClickSubmit() {


      if(!this.settings.submitDataUri && this.form.submitDataUri){
          this.settings.submitDataUri=this.form.submitDataUri;

      }

      if (!this.settings && !this.settings.submitDataUri) return;
      const submitDataUri = this.settings.submitDataUri;

      try {
         var answers=[];
         var response=null;
          if(this.customFieldCategories){
             answers = this.getAnswersCustomFields();

              if(this.settings.params){
                answers={...answers,params:this.settings.params}
              }
          
             if (!this.validFormCF(answers)) return;
               response = await apiPost(submitDataUri, answers);


              this.formError = '';
          }
          else if(this.form){
              answers = this.getAnswers(this.form);
             if (!this.isValidForm(answers)) return;
               response = await apiPost(submitDataUri, answers);
              this.formError = '';
          }


        if(response.emitEvent){
          this.$emit(response.emitEvent,response);
          
        }

         if(response.error){
            this.formError=response.error;

             var container = $('#customDialogFormErrorDiv').closest('.modal-body');
            container.scrollTop(0);
         }else if(this.settings.params && this.settings.params.returnData){
            response.returnData=this.settings.params.returnData;
            this.$emit('onSuccessCustomDialog', response);
         }else if(response.afterAddParams){
                this.closeAddRowDialog(
                  true,
                  response.afterAddParams,
                  false,
                  response,
                  "apiClient/getCollectionAfterAdd"
                );
                this.$emit('onSuccessCustomDialog', response);

         }else{
            this.$emit('onSuccessCustomDialog', response);
         }
        
      } catch (error) {
        //to do addedit errors not poping up (so clientid duplicates for example)
        this.formError = error;

             var container = $('#customDialogFormErrorDiv').closest('.modal-body');
            
                container.scrollTop(0);


      }
    },
    checkShowEmpty(field){
      if(field.Type=='Dropdown'){
        if(field.Options[0].value==null){
            return false;
        }else{
            return true;
        }
      }else{
        return true;
      }
    },
    getAnswersCustomFields() {
      if (!this.rowData) return null;
      const answers = {};
      for (let key in this.rowData) {
        if (!isEmpty(this.rowData[key])) {
          answers[key] = this.rowData[key];
        }
      }
      return answers;
    },
    getAnswers(form) {
      if (!form || !form.answers) return null;
      const answers = {};
      for (let key in form.answers) {
        if (!isEmpty(form.answers[key])) {
          answers[key] = form.answers[key];
        }
      }
      return answers;
    },
    isValidForm(answers) {
      for (let question of this.form.questions) {
        if (
          question['Required'] &&
          question['Required'] === true &&
          isEmpty(answers[question['Name']])
        ) {
          this.formError = ErrorMessages.MISSING_FIELDS;
          return false;
        }
      }
      return true;
    },
    getXl(type, bcol=null) {
      if (bcol)
        return bcol;
      if (type === 'MultiSelectStartEndDate' || type === 'DropdownStartEndDate')
        return '6';
      if (type === '4' || type === 'Editor') return '12';
      return '4';
    },
    showColumn(field) {
      // if (this.formType === 'Edit' && field.Type === 'FilePicker') {
      //   return false;
      // }

      return field.Hidden ? false : true;
    },
    refreshForm(){
      this.$emit('refresh-add-edit-root');
    },
    updateFormValues(event, field, isDynamic=false) {
      if (Array.isArray(field.Command)) {
        this.$emit('onTriggerCommand', { field, value: event });
      }


      
     

      if (Array.isArray(field.Children)) {
        for (let child of field.Children) {
          if ((child.Command === 'fetchOptions' || child.Command === 'fetchChildFormValues') && field.Type!=3) {
            
          const payload = { parent: field, child, parentValue: event };
            if (child.Command === 'fetchOptions') {
              this.$emit('fetchFormOptions', payload);
            } else if (child.Command === 'fetchChildFormValues') {
              this.$emit('fetchChildFormValues', payload);
            }
            
          } else if (child.Command === 'appendToDefault') {
            this.$emit('appendToDefault', {
              parent: field,
              child,
              parentValue: event,
              childValue:child.Value

            });
          } else if (child.Command === 'showSelectedField') {
            this.$emit('showSelectedField', {
              parent: field,
              child,
              parentValue: event,
              triggerValue:child.triggerValue
            });
          } else if(child.Command==='updateText'){

           this.$emit('updateFieldText', {
              parent: field,
              child,
              parentValue: event,
              triggerValue:child.triggerValue,
              targetCategory:child.targetCategory
            });
          }else if (child.Command === 'showCategory' ){
           
              
             this.$emit('showCategory', {
              parent: field,
              child,
              parentValue: event,
              triggerValue:child.triggerValue
            });
          }else if (child.Command=='hideSelectedField'){
            this.$emit('hideSelectedField',{
              parent: field,
              child,
              parentValue: event,
              triggerValue: child.triggerValue
            });


          }else if(child.Command=='refreshField'){

                  if(!this.fieldsKeys[child.Name]){
                    this.fieldsKeys[child.Name]=1;
                  }else{
                      this.fieldsKeys[child.Name]++;

                  }

                 /*
                  this.$emit('refreshField',{
                    parent: field,
                    child,
                    parentValue: event,
                  
                  });
                */

          }else if(child.Command=='makeRequired'){
                this.$emit('makeFieldRequired',{
                  parent: field,
                  child,
                  parentValue: event,
                  triggerValue: child.triggerValue
                });


          }
          else if (child.Command === 'updateFacilityStatus') {
            this.$emit('updateFacilityStatus', {
              parent: field,
              child,
              parentValue: event,
              triggerValue:child.triggerValue
            });
          }
          else if (child.Command === 'updateToggleRequired') {
            this.$emit('updateToggleRequired', {
              parent: field,
              child,
              parentValue: event,
              triggerValue:child.triggerValue
            });
          }
        }
      }



  
      this.$forceUpdate();

      

      if(field.refreshWhenAnswered){
        this.updateFormCardTime=new Date().getTime();
      }

      

      if(isDynamic){
        this.checkDynamicNowVisible();
      }
      
    },
    
    //to do figure out how to simply import these 
    async fetchChildFormValues({ parent, child, parentValue }) {
      const childCustomFieldRef = this.findCustomField(
        parent.CustomFieldCategory,
        child.Name
      );

      if (!childCustomFieldRef || !childCustomFieldRef.DataURI) return;
      let response = null;
      let payload = { value: parentValue, rowData: {} };
      //if (isObject(this.editRowData) && this.editRowData.ID !== undefined) {
      //  payload['rowData']['ID'] = this.editRowData.ID;
        // payload['rowData'] = {...this.editRowData };
      //}

       if (Object.keys(this.addRowData)) {
        payload['rowData'] = this.addRowData;
      }

      response = await this.$http.post(
        `${childCustomFieldRef.DataURI.uri}`,
        payload
      );
      let values = getData(response);
      this.updateFormValues({ target: child.Name, targetValue: values });
    },
    async fetchFormOptions({ parent, child, parentValue }) {
      const childCustomFieldRef =
        this.formFields.length > 0
          ? this.findFormField(child.Name)
          : this.findCustomField(
              parent.CustomFieldCategory,
              child.Name,
              child.targetCategory
            );


      if (!childCustomFieldRef || !childCustomFieldRef.DataURI) return;


      let answers={};
        if (Object.keys(this.addRowData).length > 0) {
           answers = this.addRowData;
          }


      var response = null;
      if (childCustomFieldRef.DataURI.method) {
        if (childCustomFieldRef.DataURI.method == 'post') {
          var payload = {};

          if (Object.keys(this.addRowData).length > 0) {
            payload['rowData'] = this.addRowData;
          }



          response = await this.$http.post(
            `${childCustomFieldRef.DataURI.uri}`,
            payload
          );
        } else {
          response = await this.$http.get(
            `${childCustomFieldRef.DataURI}${parentValue}`
          );
        }
      } else {
        response = await this.$http.get(
          `${childCustomFieldRef.DataURI}${parentValue}`
        );
      }

      let options = getData(response);
        

       if(options && options.isCalc){


          if (Object.keys(this.addRowData).length > 0) {
            this.addRowData[childCustomFieldRef.Name]=options.value;
          }




      }else{

         childCustomFieldRef.Options = Array.isArray(options) ? options : [];

      if(childCustomFieldRef.Children){
         if (
                    Array.isArray(childCustomFieldRef.Children) &&
                    childCustomFieldRef.Children.length > 0
                  ) {
                    for (let child of childCustomFieldRef.Children) {
                      let params = {
                        parent: childCustomFieldRef,
                        child,
                        parentValue: answers[childCustomFieldRef.Name],
                        triggerValue: child.triggerValue,
                      };
                      if (child.Command === 'showSelectedField') {
                        this.showSelectedField(params);
                      }
                      if(child.Command=='updateText'){
                          this.changeFieldText(params)
                      }
                      if (child.Command === 'showCategory') {
                        let params = {
                          parent: childCustomFieldRef,
                          child,
                          parentValue: answers[childCustomFieldRef.Name],
                          triggerValue: child.triggerValue,
                          isInit: true,
                          initialValue: answers[childCustomFieldRef.Name],
                        };
                        this.showCategoryFunc(params);
                      }

                      if (child.Command === 'hideSelectedField') {
                        let params = {
                          parent: childCustomFieldRef,
                          child,
                          parentValue: answers[childCustomFieldRef.Name],
                          triggerValue: child.triggerValue,
                        };
                        this.hideSelectedField(params);
                      }

                       if (child.Command === 'makeRequired') {
                        let params = {
                          parent: childCustomFieldRef,
                          child,
                          parentValue: answers[childCustomFieldRef.Name],
                          triggerValue: child.triggerValue,
                        };
                        this.makeFieldRequiredHandler(params);
                      }

                      if (child.Command === 'fetchOptions') {
                        let params = {
                          parent: childCustomFieldRef,
                          child,
                          parentValue: answers[childCustomFieldRef.Name],
                          triggerValue: child.triggerValue,
                        };

                        this.fetchFormOptions(params);
                      }
                    }
                  }
         }


      }



      
     

    },
    appendToDefault({ parent, child, parentValue, childValue }) {

      const childCustomFieldRef = this.findCustomField(
        parent.CustomFieldCategory,
        child.Name
      );


      

      if (child.Name == 'CollectionSeries' || child.Name == 'Location') {


        if (childValue.length == 0) {
          return;
        }

        var vm = this;


        if (Array.isArray(parentValue)) {
          $.each(parentValue, function(key, value) {
            if (value == child.conditionValue) {
              if (vm.addRowData[child.Name]){
                if (!vm.addRowData[child.Name].includes(childValue)) {
                  vm.addRowData[child.Name].push(childValue);
                }
              }else if(vm.addRowData && !vm.addRowData[child.Name]){
                vm.addRowData[child.Name]=[];
                vm.addRowData[child.Name].push(childValue);
             }


              /*if (vm.editRowData[child.Name])
                if (!vm.editRowData[child.Name].includes(childValue)) {
                  vm.editRowData[child.Name].push(childValue);
                }*/
            }
          });
        } else if (parentValue == child.conditionValue && childValue == 'All') {
          $.each(childCustomFieldRef.Options, function(key, value) {
            if (value.text != 'Select All') {
              if (vm.addRowData[child.Name])
                if (!vm.addRowData[child.Name].includes(value.value)) {
                  if (childCustomFieldRef.Type == 'ListLocationEHR') {
                    vm.addRowData[child.Name].push({
                      ID: value.value,
                      text: { Name: value.text },
                    });
                  } else {
                    vm.addRowData[child.Name].push(value.value);
                  }
                }

              /*if (vm.editRowData[child.Name])
                if (!vm.editRowData[child.Name].includes(value.value)) {
                  vm.editRowData[child.Name].push(value.value);
                }*/
            }
          });
        } else {
          if (parentValue == child.conditionValue) {
            if (vm.addRowData[child.Name])
              if (!vm.addRowData[child.Name].includes(childValue)) {
                vm.addRowData[child.Name].push(childValue);
              }

            /*if (vm.editRowData[child.Name])
              if (!vm.editRowData[child.Name].includes(childValue)) {
                vm.editRowData[child.Name].push(childValue);
              }*/
          }
        }
      } else {
        if (this.addRowData[child.Name])
          this.addRowData[child.Name] =
            childCustomFieldRef.DefaultValue +
            (childValue ? childValue : parentValue);
        /*if (this.editRowData[child.Name])
          this.editRowData[child.Name] =
            childCustomFieldRef.DefaultValue +
            (childValue ? childValue : parentValue);*/
      }
    },
    showSelectedField({ parent, child, parentValue, triggerValue}) {
      const childCustomFieldRef =
        Object.keys(this.formFields).length > 0
          ? this.findFormField(child.Name)
          : this.findCustomField(
              parent.CustomFieldCategory,
              child.Name,
              child.TargetCategory
            );




    
 
      if (!childCustomFieldRef) return;

      const parentValueObject =
        parent && Array.isArray(parent.Options)
          ? parent.Options.filter(ele => ele.value === parentValue)
          : null;
  
    
    

   

      
      if (parentValueObject && parentValueObject.length > 0) {
  
       
        if (triggerValue && Array.isArray(triggerValue)) {

          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Hidden = false;
          } else if (triggerValue.includes(parentValueObject[0].index)) {
            childCustomFieldRef.Hidden = false;
            
          } else {
            childCustomFieldRef.Hidden = true;
            childCustomFieldRef.Required=false;
          }
        } else if (
          triggerValue &&
          triggerValue === parentValueObject[0].index
        ) {


          
         
          childCustomFieldRef.Hidden = false;

                  


       } else if (
          typeof triggerValue !=='undefined' &&
          triggerValue === parentValueObject[0].index
        ) {
           

          childCustomFieldRef.Hidden = false;
        
        } else if (childCustomFieldRef.Name === parentValueObject[0].index) {
                      

          childCustomFieldRef.Hidden = false;
        } else if (triggerValue && triggerValue == parentValueObject[0].value) {
                     
          childCustomFieldRef.Hidden = false;
       } else if (typeof triggerValue !=='undefined' && triggerValue == parentValueObject[0].value) {
                   
          childCustomFieldRef.Hidden = false;
        }else if(triggerValue && child.oper){

          switch(child.oper){
            case 'gte':
              if(parentValueObject[0].index>=triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;

              }

              break;
            case 'lte':
               if(parentValueObject[0].index<=triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;

              }
              break;
            case 'gt':
               if(parentValueObject[0].index>triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;

              }
              break;
            case 'lt':
               if(parentValueObject[0].index<triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;
              }
              break;
          }
        } else if (
          triggerValue &&
          triggerValue == 'any' &&
          parentValueObject[0].value
        ) {
             

          childCustomFieldRef.Hidden = false;
        } else {
          childCustomFieldRef.Hidden = true;
          childCustomFieldRef.Required=false;
        }
      } else if (parentValue) {
        
  
        if (triggerValue && Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Hidden = false;
          } else {
            childCustomFieldRef.Hidden = true;
            childCustomFieldRef.Required=false;
          }
        } else if (
          parentValue === triggerValue ||
          (isNaN(parentValue) && parentValue.length > 0 && triggerValue == 'any') ||
          (parentValue && triggerValue == 'any')
        ) {
      
          childCustomFieldRef.Hidden = false;
        }else if(triggerValue && Array.isArray(parentValue) ){
            let showField=false;

            $.each(parentValue,function(key,value){
               let  valueObject=
                      value && Array.isArray(parent.Options)
                        ? parent.Options.filter(ele => ele.value === value)
                        : null;

                  if(valueObject[0].index==triggerValue){
                    showField=true;
                  }
            });


            if(showField){
                  childCustomFieldRef.Hidden=false;
            }else{

                  childCustomFieldRef.Hidden=true;
                  childCustomFieldRef.Required=false;

            }

            
        }else if(triggerValue && child.oper){
           switch(child.oper){
            case 'gte':
              if(parentValue>=triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;

              }

              break;
            case 'lte':
               if(parentValue<=triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;

              }
              break;
            case 'gt':
               if(parentValue>triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;

              }
              break;
            case 'lt':
               if(parentValue<triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;

              }
              break;
          }
        }
      }



    },
    updateFacilityStatus({parent, child, parentValue, triggerValue}){
      const childCustomFieldRef =
        Object.keys(this.formFields).length > 0
          ? this.findFormField(child.Name)
          : this.findCustomField(
              parent.CustomFieldCategory,
              child.Name,
              child.TargetCategory
            );
      if (!childCustomFieldRef) return;

      const parentValueObject =
        parent && Array.isArray(parent.Options)
          ? parent.Options.filter(ele => ele.value === parentValue)
          : null;
          if (!parentValueObject) return;
      if (triggerValue && Array.isArray(triggerValue)) {
        if (triggerValue.includes(parentValue)) {
          //this.editRowData[childCustomFieldRef.Name]= "318";
          this.formToggleRequired(false);         
        }else{
          //this.editRowData[childCustomFieldRef.Name]= "317";
          this.formToggleRequired(true); 
        }
      }
    },
    updateToggleRequired({parent, child, parentValue, triggerValue}){
      if(parentValue==triggerValue){
        this.formToggleRequired(true);        
      }else{
        this.formToggleRequired(false); 
      }
    },
    formToggleRequired(active){
      if(!active){
        for (let category of this.customFieldCategories) {
          for (let customField of category.customFields) {              
            if(customField.Required ==true){
              if(this.storeRequiredFields.length==0){
                this.storeRequiredFields[customField.Name] =customField.Name;
              }           
              customField.Required =false;
            }
          }
        }
      }else{
        for (let category of this.customFieldCategories) {
          if(category.Hidden==false){
            for (let customField of category.customFields) { 
              if(this.storeRequiredFields[customField.Name] && (customField.Hidden !=true)){             
                customField.Required =true;
              }                                 
            }
          }   
        }
      }

    },
  
    changeFieldText({ parent, child, parentValue, triggerValue,targetCategory }) {

   
      const childCustomFieldRef =
        Object.keys(this.formFields).length > 0
          ? this.findFormField(child.Name)
          : this.findCustomField(
              parent.CustomFieldCategory,
              child.Name,
             targetCategory
            );




    
 
      if (!childCustomFieldRef) return;



      const parentValueObject =
        parent && Array.isArray(parent.Options)
          ? parent.Options.filter(ele => ele.value === parentValue)
          : null;


       


    
    


      
      if (parentValueObject && parentValueObject.length > 0) {
  
       
        if (triggerValue && Array.isArray(triggerValue)) {

          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValue);

          } else if (triggerValue.includes(parentValueObject[0].index)) {
            childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValueObject[0].index);
          } 
        } else if (
          triggerValue &&
          triggerValue === parentValueObject[0].index
        ) {

        childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValueObject[0].index);

                  


       } else if (
          typeof triggerValue !=='undefined' &&
          triggerValue === parentValueObject[0].index
        ) {
           

            childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValueObject[0].index);

        
        } else if (childCustomFieldRef.Name === parentValueObject[0].index) {
           childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValueObject[0].index);


        } else if (triggerValue && triggerValue == parentValueObject[0].value) {
            childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValueObject[0].index);
    
       } else if (typeof triggerValue !=='undefined' && triggerValue == parentValueObject[0].value) {
                   
            childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValueObject[0].index);
      } else if (
          triggerValue &&
          triggerValue == 'any' &&
          parentValueObject[0].value
        ) {
             

             childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValueObject[0].index);

        }else{
           childCustomFieldRef.Text=childCustomFieldRef.OrigText;
        }
      } else if (parentValue) {
        
  
        if (triggerValue && Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
             childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValue);

          } 
        } else if (
          parentValue === triggerValue ||
          (isNaN(parentValue) && parentValue.length > 0 && triggerValue == 'any') ||
          (parentValue && triggerValue == 'any')
        ) {
      
             childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValue);
        }else if(triggerValue && Array.isArray(parentValue) ){
              childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValue);


            
        }else{
           childCustomFieldRef.Text=childCustomFieldRef.OrigText;
        }
      }



    },
    showCategoryFunc({
      parent,
      child,
      parentValue,
      triggerValue,
      isInit,
      initialValue,
    }) {
      var childCustomFieldCategoryRef = this.findCustomCategory(child.Name);

      
     
      if (!childCustomFieldCategoryRef) return;


      if(isInit && parent.Hidden) return;

      if (!parentValue || (parentValue && parentValue.length == 0)) {
        childCustomFieldCategoryRef.Hidden = true;
        for (let childCustomField of childCustomFieldCategoryRef.customFields) {
          childCustomField.Required = false;
          this.formToggleRequired(true); 
        }
      } else if (triggerValue && triggerValue == 'any') {
        childCustomFieldCategoryRef.Hidden = false;
        this.formToggleRequired(true); 
      } else if (Array.isArray(parentValue)) {
        if (parentValue.includes(triggerValue)) {
          childCustomFieldCategoryRef.Hidden = false;
          this.formToggleRequired(true); 
        } else {

          childCustomFieldCategoryRef.Hidden = true;
            for (let childCustomField of childCustomFieldCategoryRef.customFields) {
              childCustomField.Required = false;
              this.formToggleRequired(true); 
            }
        }
      } else {
        const parentValueObject =
          parent && Array.isArray(parent.Options)
            ? parent.Options.filter(ele => ele.value === parentValue)
            : null;


       


        if (
          parentValueObject &&
          parentValueObject[0] &&
          parentValueObject[0].index === triggerValue
        ) {
          childCustomFieldCategoryRef.Hidden = false;
          this.formToggleRequired(true); 
        } else if (Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
            childCustomFieldCategoryRef.Hidden = false;
            this.formToggleRequired(true); 
          } else {
            childCustomFieldCategoryRef.Hidden = true;
            for (let childCustomField of childCustomFieldCategoryRef.customFields) {
              childCustomField.Required = false;
            }
          }
        } else if (parentValue === triggerValue) {
          childCustomFieldCategoryRef.Hidden = false;
          this.formToggleRequired(true); 
        } else if (triggerValue == 'any') {
          childCustomFieldCategoryRef.Hidden = false;
          this.formToggleRequired(true); 
        }else if (typeof triggerValue ==='undefined' && parentValue){
           childCustomFieldCategoryRef.Hidden = false;
           this.formToggleRequired(true); 
        } else {
            childCustomFieldCategoryRef.Hidden = true;
            for (let childCustomField of childCustomFieldCategoryRef.customFields) {
              childCustomField.Required = false;
            }
          
        }
      }
    },
    refreshAddEditForm(type, rowData) {
        this.closeAddRowDialog();
        this.openAddRowDialog();
    },
    
    /**
     * Hides add/create modal
     */
    
    closeAddRowDialog(addSuccessful, afterAddParams, cancelAfterAdd, response,afterAddUri=null) {
      this.addRowData = {};
      if (this.defaultRow) {
        this.addRowData = JSON.parse(JSON.stringify(this.defaultRow));
      }
      if (this.showCForm == true) {
        this.closeCFormDialog();
      }
      if (this.showSurveyJsForm == true) {
        this.closeSurveyJsFormDialog();
      }
      
      this.formError = '';
      if (this.validationSettings) {
        this.$v.$reset();
      }


      if(afterAddUri){
       
          if(typeof this.afterAddDialogSettings ==='undefined'|| !this.afterAddDialogSettings){
            this.afterAddDialogSettings={};
          }

        this.afterAddDialogSettings['getDataUri']=afterAddUri;

      }else{
        this.$bvModal.hide(`custom-dialog`);
      }

      if (addSuccessful && this.afterAddDialogSettings && !cancelAfterAdd) {
        this.openAfterAddDialog(afterAddParams);
      }else if(addSuccessful && afterAddParams && response && response.triggerAfterAddDialog){
        this.openAfterAddDialog(afterAddParams);
      }else if(addSuccessful && afterAddParams && afterAddUri){
        this.openAfterAddDialog(afterAddParams);

        
      } else if (addSuccessful && response && response.displaySuccess) {
        this.$bvModal.msgBoxOk(response.message, {
          title: 'Success',
          buttonSize: 'sm',
          centered: true,
          size: 'sm',
          okVariant: 'success',
        });
      }

      if (this.component) {
        //this.getRows();
        this.component = null;
        this.componentParams = {};
      }
      if (afterAddParams && afterAddParams.cFormDialogProps) {
        this.openCFormDialog(afterAddParams.cFormDialogProps)
      }
      if (afterAddParams && afterAddParams.openSurveyJsFormDialog) {
        this.openSurveyJsFormDialog(afterAddParams.surveyJsFormDialogData);
      }
      if (afterAddParams && afterAddParams.refreshTableSettings) {
        this.setup();
      }
    },

    /**
     * Opens add/create modal
     */
    
    openAddRowDialog() {
      this.addRowData = {};

      if (!this.passedFilters) {
        this.passedFilters = this.$refs.filterRow.getSelectedFilters();
      }

      if (this.$route.params) {
        this.passedFilters = { ...this.passedFilters, ...this.$route.params };
      }

      if (this.customServerParams) {
        this.passedFilters = {
          ...this.passedFilters,
          ...this.customServerParams,
        };
      }

      if(this.gridUpdateData){
        this.passedFilters={
            ...this.passedFilters,
            ...this.gridUpdateData
        };
      }


      if (this.defaultRow) {
        this.addRowData = JSON.parse(JSON.stringify(this.defaultRow));
      }


      //if (this.timer) {
      //  clearInterval(this.timer);
      //  this.timer = null;
      //}


      if (this.customFieldSetName) {
        this.fetchCustomFields('Add', this.customFieldSetName);

         this.$bvModal.show(`custom-dialog`);
      } else if (this.service.getAddRowData) {
        this.fetchAddRowData();
      } else if (this.service.getFormConfig) {
        this.fetchFormConfig('Add');
        
      } else {
              this.$bvModal.show(this.addDialogIDProp);
      }

      this.$bvModal.show(`custom-dialog`);


    },

    hideSelectedField({ parent, child, parentValue, triggerValue }) {
      const childCustomFieldRef = this.findCustomField(
        parent.CustomFieldCategory,
        child.Name,
        child.TargetCategory
      );

      if (!childCustomFieldRef) return;
      const parentValueObject =
        parent && Array.isArray(parent.Options)
          ? parent.Options.filter(ele => ele.value === parentValue)
          : null;

      if (parentValueObject && parentValueObject.length > 0) {
        if (triggerValue && Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Hidden = true;
            childCustomFieldRef.Required=false;
          }
        } else if (triggerValue && triggerValue === parentValue) {
          childCustomFieldRef.Hidden = true;
          childCustomFieldRef.Required=false;
        } else if (
          triggerValue &&
          triggerValue === parentValueObject[0].index
        ) {
          childCustomFieldRef.Hidden = true;
          childCustomFieldRef.Required=false;
        } else if (childCustomFieldRef.Name === parentValueObject[0].index) {
          childCustomFieldRef.Hidden = true;
          childCustomFieldRef.Required=false;
        }
      } else if (parentValue) {
        if (parentValue === triggerValue) {
          childCustomFieldRef.Hidden = true;
          childCustomFieldRef.Required=false;
        }
      }
    },
    refreshField({ parent, child, parentValue, triggerValue, categoryToShow }) {
      const childCustomFieldRef = this.findCustomField(
        parent.CustomFieldCategory,
        child.Name,
        child.targetCategory
      );

      if (childCustomFieldCategoryRef) {
      }
    },
    makeFieldRequiredHandler({parent, child, parentValue, triggerValue}){
        const childCustomFieldRef =
        Object.keys(this.formFields).length > 0
          ? this.findFormField(child.Name)
          : this.findCustomField(
              parent.CustomFieldCategory,
              child.Name,
              child.TargetCategory
            );




    
 
      if (!childCustomFieldRef) return;


      
      const parentValueObject =
        parent && Array.isArray(parent.Options)
          ? parent.Options.filter(ele => ele.value === parentValue)
          : null;
       


    
    


      
      if (parentValueObject && parentValueObject.length > 0) {
         
         if(parentValueObject[0].value){
              if (triggerValue && Array.isArray(triggerValue)) {
                  if(triggerValue.includes(parentValueObject[0].value)){
                    childCustomFieldRef.Required=true;
                  }else{
                      childCustomFieldRef.Required=false;

                  }

              }else if(triggerValue){
                  if(triggerValue===parentValueObject[0].value){
                      childCustomFieldRef.Required=true;

                  }else{
                      childCustomFieldRef.Required=false;

                  }

              }else{
                   childCustomFieldRef.Required=true;

              }

         }else if(parentValueObject[0].index){
             if (triggerValue && Array.isArray(triggerValue)) {
                  if(triggerValue.includes(parentValueObject[0].index)){
                    childCustomFieldRef.Required=true;
                  }else{
                      childCustomFieldRef.Required=false;

                  }

              }else if(triggerValue){
                  if(triggerValue===parentValueObject[0].index){
                      childCustomFieldRef.Required=true;

                  }else{
                      childCustomFieldRef.Required=false;

                  }

              }else{
                   childCustomFieldRef.Required=true;

              }


         }
       
      } else if (typeof parentValue !=='undefined' && parentValue) {
             if (triggerValue && Array.isArray(triggerValue)) {
                  if(triggerValue.includes(parentValue)){
                    childCustomFieldRef.Required=true;
                  }else{
                      childCustomFieldRef.Required=false;

                  }

              }else if(triggerValue){
                  if(triggerValue===parentValue){
                      childCustomFieldRef.Required=true;

                  }else{
                      childCustomFieldRef.Required=false;

                  }

              }else{
                   childCustomFieldRef.Required=true;

              }

  
        
      }
      
      
   

    },
    showCategory(cat){
      return cat.Hidden?false:true;

    },
    findCustomCategory(targetName) {
      for (let category of this.customFieldCategories) {
        if (category.Name.trim() === targetName.trim()) {
          return category;
        }
      }
      return null;
    },
    findCustomField(customFieldCategory, targetName, targetCategory = null) {
      if (targetCategory) {
        for (let category of this.customFieldCategories) {
          if (category.Name.trim() === targetCategory) {
            for (let customField of category.customFields) {
              if (customField.Name.trim() === targetName) {
                return customField;
              }
            }
          }
        }
      } else {
        for (let category of this.customFieldCategories) {
          if (category.Name.trim() === customFieldCategory.trim()) {
            for (let customField of category.customFields) {
              if (customField.Name.trim() === targetName.trim()) {
                return customField;
              }
            }
          }
        }
      }

      return null;
    },
    openAfterAddDialog(params) {
      //if (this.timer) {
      //  clearInterval(this.timer);
      //}



      if (this.$route.params.isorg) {
        var orgObject = { isOrg: true };

        this.passedFilters = { ...this.passedFilters, ...orgObject };
      }

      if (params) {
        this.afterAddDialogSettings = {
          ...this.afterAddDialogSettings,
          params,
        };
      }
      this.refreshAfterAddDialog += 1;
      //this.afterAddDialogSettings = data.afterAddDialogSettings;
      this.renderAfterAddDialog = true;
    },
    successAfterAddDialog(data) {
      const cmd = data && data.cmd;
      if (cmd === 'reloadForm') {
        this.$refs['afterAddDialogRef'].getData(data.afterAddDialogSettings);
      }
      
      //this.closeCustomDialog();
    },
    closeAfterAddDialog(data) {
      //if (this.timer) {
      //  clearInterval(this.timer);
     // }


      this.renderAfterAddDialog = false;


      if (data.afterSubmitUri) {
        if (data.afterSubmitNoDialog) {
          this.$http
            .post(API_URL + '/' + data.afterSubmitUri, {})
            .then(response => {
              if (response.data.data.nextUri) {
                if (response.data.data.nextUriDialogMessage) {
                  this.$bvModal
                    .msgBoxOk(response.data.data.nextUriDialogMessage)
                    .then(value => {
                      this.afterCustomDialogSettings.getDataUri =
                        response.data.data.nextUri;
                      this.openAfterCustomDialog();
                    });
                } else {
                  this.afterCustomDialogSettings.getDataUri =
                    response.data.data.nextUri;
                  this.openAfterCustomDialog();
                }
              } else if (response.data.data.surveyViewer) {
                this.$router.replace({
                  name: 'client-collection',
                  params: {
                    id: 'SurveyViewer',
                    assessmentType: response.data.data.assessmentType,
                    assessmentID: response.data.data.assessmentID,
                    clientID: response.data.data.modelID,
                    afterSubmitUri: response.data.data.afterSubmitUri,
                    inAfterSubmit: true,
                  },
                });
              } else if (response.data.data.nextPath) {
                this.$router.push({ path: response.data.data.nextPath });
              } else {
                this.$router.push({
                  name: 'ModalView',
                  params: {
                    componentName: response.data.data.componentName,
                    cservice: response.data.data.cservice,
                    service: response.data.data.service,
                    rowID: response.data.data.rowID,
                    ...response.data.data.params,
                  },
                });
              }
            });
        } else {
          this.afterCustomDialogSettings.getDataUri = data.afterSubmitUri;
          if(data.afterSubmitDialogParams){
            this.afterCustomDialogSettings={...this.afterCustomDialogSettings,...data.afterSubmitDialogParams};

          }
          
          this.openAfterCustomDialog();
        }
      } 
      this.closeCustomDialog();
    },
  },
  

};
</script>

<style>
  .customDialogModalBodyHeight {
    height: 75vh;
  }
</style>