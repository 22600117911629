import { isEmpty } from '@/utils/isEmpty';
import { ErrorMessages } from '@/utils/errorMessages';
import {
  createSetByProp,
  deepCopy,
  isObject,
  isStringAndNotEmpty,
  objectDiff,
} from './langUtils';
import { SurveyPDF } from 'survey-pdf';
import { isEqual, isString } from 'lodash';

/**
 * @param {object} customField
 * @param {object} answers
 * @returns object
 */
export const findCustomFieldAnswer = (customField, answers) => {
  if (answers[customField.Name] !== undefined) return answers[customField.Name];
  const dynPanels = isObject(answers['__dynPanels'], true)
    ? answers['__dynPanels']
    : {};
  for (const panel in dynPanels) {
    const panelAnswers = Array.isArray(dynPanels[panel])
      ? dynPanels[panel]
      : [];
    for (const panelAnswer of panelAnswers) {
      if (panelAnswer[customField.Name] !== undefined) {
        return panelAnswer[customField.Name];
      }
    }
  }
};

export const getAllAnswers = answers => {
  const res = {};
  for (const k in answers) res[k] = answers[k];
  const dynPanels = isObject(answers['__dynPanels'], true)
    ? answers['__dynPanels']
    : {};
  for (const panel in dynPanels) {
    const panelAnswers = Array.isArray(dynPanels[panel])
      ? dynPanels[panel]
      : [];
    for (const panelAnswer of panelAnswers) {
      if (!isObject(panelAnswer, true)) continue;
      for (const k in panelAnswer) res[k] = panelAnswer[k];
    }
  }
  return res;
};

export const processCustomFields = (customFieldCategories, cb) => {
  for (const category of customFieldCategories) {
    for (const customField of category.customFields) {
      cb(customField);
    }
    const dynRows = Array.isArray(category.dynRows) ? category.dynRows : [];
    for (const row of dynRows) {
      const customFields = Array.isArray(row) ? row : [];
      for (const customField of customFields) {
        cb(customField);
      }
    }
  }
};

export const findCustomFieldByName = (form, target) => {
  let match = null;
  processCustomFields(form, customField => {
    if (customField.Name === target) {
      match = customField;
    }
  });
  return match;
};

export const findCustomFieldParents = (customField, customFieldCategories) => {
  const parents = [];
  const lookupFnc = field => {
    const children = Array.isArray(field.Children) ? field.Children : [];
    for (const childData of children) {
      if (childData.Name === customField.Name) {
        const parentField = findCustomFieldByName(customFieldCategories, field.Name);
        if (parentField) parents.push(parentField);
      }
    }
  }
  processCustomFields(customFieldCategories, lookupFnc);
  return parents;
}

export const getAllCustomFields = customFieldCategories => {
  const customFieldList = [];
  const lookupFnc = customField => {
    customFieldList.push(customField);
  };
  processCustomFields(customFieldCategories, lookupFnc);
  return customFieldList;
};

export const validateCfAnswers = (answers, customFieldCategories) => {
  const vLookupFnc = customField => {
    if (customField.Required) {
      const answer = findCustomFieldAnswer(customField, answers);
      if (!validateRequired(customField, answer)) {
        throw Error(ErrorMessages.MISSING_FIELDS);
      }
    }
  };
  try {
    processCustomFields(customFieldCategories, vLookupFnc);
  } catch (error) {
    return { error: true, message: ErrorMessages.MISSING_FIELDS };
  }
  return { error: false, message: 'Validation was successful' };
};

const validateRequired = (customField, answer) => {
  if (isEmpty(answer) && document.querySelector(`#${customField['Name']}`))
    return false;
  return true;
};

export const setDynFieldChildren = (fields, field, suffix) => {
  const setOfNames = createSetByProp(fields, 'Name');
  const children = Array.isArray(field.Children) ? field.Children : [];
  for (const childData of children) {
    if (setOfNames.has(childData.Name)) {
      childData.Name = `${childData.Name}${suffix}`;
    }
  }
};

export const setDynFieldVisibleIf = (field, suffix) => {
  if (isStringAndNotEmpty(field.VisibleIf)) {
    field.VisibleIf = field['VisibleIf'].replace(/\{(.*?)\}/g, function(
      match,
      value
    ) {
      return `{${value}${suffix}}`;
    });
  }
};

export const getDynRowsFromAnswers = (category, rowsOfAnswers) => {
  const dynRows = [];
  const rows = Array.isArray(rowsOfAnswers) ? rowsOfAnswers : [];
  for (const row of rows) {
    if (!isObject(row, true)) continue;
    let suffix = '';
    for (const qcode in row) {
      const suffixIndex = qcode.indexOf('__dynR');
      suffix = qcode.substring(suffixIndex);
      break;
    }
    const dynRow = [];
    for (const template of category.customFields) {
      const templateCopy = deepCopy(template);
      templateCopy.Name += suffix;
      setDynFieldChildren(category.customFields, templateCopy, suffix);
      setDynFieldVisibleIf(templateCopy, suffix);
      dynRow.push(templateCopy);
    }
    if (dynRow.length > 0) dynRows.push(dynRow);
  }
  return dynRows;
};

/**
 * @param { Object.<string, any>[] } form
 * @param { Object.<string, any> } answers
 * @param {{ showTitle: boolean, title: string, renderAsQuestion: boolean }} opts
 * @param {{ fontSize: number, pdfMode: string }} surveyPdfOpt
 */
export const cFieldToPDF = (form, answers, opts = {}, surveyPdfOpt = {}) => {
  surveyPdfOpt = isObject(surveyPdfOpt, true) ? surveyPdfOpt : {};
  opts = isObject(opts, true) ? opts : {};
  // surveyPdfOpt.fontSize = opts.fontSize || 12;
  const jsonObject = cFieldToSurveyJs(deepCopy(form), deepCopy(answers), opts);
  const surveyPdf = new SurveyPDF(jsonObject.form, surveyPdfOpt);
  surveyPdf.haveCommercialLicense = true;
  surveyPdf.data = jsonObject.values;
  surveyPdf.mode = surveyPdfOpt.pdfMode || 'display';
  surveyPdf.save();
};

/**
 * @param { Object.<string, any>[] } cFieldCats
 * @param { Object.<string, any> } answers
 * @param {{ showTitle: boolean, title: string, renderAsQuestion: boolean }} opts
 * @returns object { form: object, values: object }
 */
export const cFieldToSurveyJs = (cFieldCats, answers, opts = {}) => {
  opts = opts || {};
  if (!Array.isArray(cFieldCats))
    throw Error('Invalid argument supplied. Param 1 must be an array.');
  const form = {};
  form.pages = [];
  form.showTitle = opts.showTitle && true;
  form.title = opts.title || '';
  const page = {};
  page.elements = [];
  page.name = 'page1';
  const values = {};
  for (const cFieldCat of cFieldCats) {
    const panel = {};
    panel.elements = [];
    panel.name = cFieldCat.Name;
    panel.title = cFieldCat.Title || cFieldCat.Title || '';
    panel.type = 'panel';
    if (isStringAndNotEmpty(cFieldCat.Instructions)) {
      panel.elements.push({
        type: 'html',
        name: `${cFieldCat.Name}_Desc`,
        html: cFieldCat.Instructions,
      });
    }
    const cFields = Array.isArray(cFieldCat['customFields'])
      ? cFieldCat['customFields']
      : [];
    for (const cField of cFields) {
      if (!isValidPrintType(cField.Type)) continue;
      const element = {};
      element.name = cField.Name;
      element.startWithNewLine = true;
      element.title = cField.Text;
      element.hideNumber = true;
      const answer = findCustomFieldAnswer(cField, answers);
      if (opts.renderAsQuestion) {
        element['type'] = cFieldTypeToSurveyJs(cField, answer);
        element['choices'] = cField.Options;
        if (isListViewType(answer)) {
          values[cField.Name] = getTextValue(cField, answer);
        } else {
          values[cField.Name] = answer;
        }
      } else {
        element['type'] = 'text';
        const textVal = getTextValue(cField, answer);
        values[cField.Name] = textVal;
      }
      panel.elements.push(element);
    }
    page.elements.push(panel);
  }
  form.pages.push(page);
  return { form, values };
};

/**
 * @param {Object.<string, any>} field
 * @param {any} answer
 */
const cFieldTypeToSurveyJs = (field, answer) => {
  let type = 'text';
  if (isChoicesType(field)) {
    type = Array.isArray(answer) ? 'checkbox' : 'radiogroup';
  }
};

/**
 * @param {string} type
 * @returns boolean
 */
const isValidPrintType = type => {
  if (!isString(type)) return false;
  if (type.includes('UserFilePicker')) return false;
  return true;
};

/**
 * @param {Object.<string, any>} field
 * @param {any} answer
 * @returns string
 */
const getTextValue = (field, answer) => {
  let value = '';
  if (isListViewType(answer)) {
    value = getListViewTextValue(answer);
  } else if (isChoicesType(field)) {
    value = getOptionTextValue(field.Options, answer);
  } else {
    value = answer;
  }
  if (Array.isArray(value)) return value.join(', ');
  return value;
};

/**
 * @param {Object.<string, any>} field
 * @returns boolean
 */
const isChoicesType = field => {
  return Array.isArray(field.Options) && field.Options.length > 0;
};

/**
 * @param {any} answer
 * @returns boolean
 */
const isListViewType = answer => {
  return Array.isArray(answer) && isObject(answer[0], true) && answer[0]['ID'];
};

/**
 * @param {{ID: string, text: Object<string,any>}} opts
 * @returns string[]
 */
const getListViewTextValue = opts => {
  const res = [];
  if (!Array.isArray(opts)) return res;
  for (const opt of opts) {
    if (!isObject(opt['text'], true)) continue;
    const arr = [];
    for (const k in opt['text']) {
      arr.push(opt['text'][k]);
    }
    res.push(arr.join('- '));
  }
  return res;
};

/**
 * @param {{ text: string, value: string }[]} opts
 * @param {any} answer
 * @returns string[]
 */
const getOptionTextValue = (opts, answer) => {
  const res = [];
  for (const opt of opts) {
    if (Array.isArray(answer)) {
      for (const val of answer) {
        if (isEqual(val, opt.value)) {
          res.push(opt.text);
        }
      }
    } else if (isEqual(answer, opt.value)) {
      res.push(opt.text);
    }
  }
  return res;
};

export const answersDif = (a, b) => {
  const fields = objectDiff(a, b);
  return fields.filter(e => e.indexOf("__") === 0 && e !== '__dynPanels' ? false : true);
}