<template>
  <article v-if="isInputValid()" class="d-flex">
    <CustomFieldFormDialog
      v-if="showCForm && cFormData"
      :errorMessage="formError"
      :formData="cFormData"
      @onClickFormMenuBtn="onClickFormMenuBtn($event)"
      @onClickCancel="showCForm = false; selected = null"
      @onError="formError = $event"/>
    <b-btn
      v-for="fileThumbnail in fileThumbnails"
      :key="fileThumbnail.ID"
      class="mr-3 p-3"
      variant="light"
      :style="btnStyle(fileThumbnail)"
      @click="onClickThumbnail(fileThumbnail)"
      v-b-tooltip.hover
      :title="fileThumbnail.Name"
    >
      <div :class="thumbnailCss(fileThumbnail)">
        <FileThumbnailDisplay :fileThumbnail="fileThumbnail" />
        <p class="d-block m-0 w-100 text-muted text--sm text-truncate">
          {{ fileThumbnail.Name }}
        </p>
      </div>
    </b-btn>
  </article>
</template>
<script>
import { fileDownloader } from '@/utils/networkUtils';
import FileThumbnailDisplay from '@/components/FileThumbnailDisplay.vue';

export default {
  name: 'FileThumbnail',
  components: {
    FileThumbnailDisplay,
    CustomFieldFormDialog: () => import('@/components/form/CustomFieldFormDialog.vue')
  },
  props: {
    fileThumbnails: { type: Array, default: null },
  },
  data() {
    return {
      formError: null,
      selected: null,
      showCForm: false,
    }
  },
  methods: {
    isInputValid() {
      return (
        Array.isArray(this.fileThumbnails) && this.fileThumbnails.length > 0
      );
    },
    onClickThumbnail(fileThumbnail) {
      if (fileThumbnail.protected) {
        this.showCForm = true;
        this.cFormData = fileThumbnail.cFormData;
        this.selected = fileThumbnail;
      } else {
        fileDownloader(fileThumbnail)
          .then(res => {})
          .catch(error => console.log(error));
        this.selected = null;
      }
    },
    onClickFormMenuBtn(data) {
      if (!data) return;
      const { action } = data;
      if (!data || !data.answers) return;
      const item = { ...data.answers, pathToFetchSignedURL: this.selected.pathToFetchSignedURL };
      fileDownloader(item).then(res => {
          this.selected = null;
          this.showCForm = false;
        }).catch(error => this.formError = error.message ? error.message : error);
    },
    btnStyle(fileThumbnail) {
      const styles = {
        position: 'relative',
        width: fileThumbnail.width ? fileThumbnail.width : '128px',
      };
      return styles;
    },
    thumbnailCss(fileThumbnail) {
      const cssClass = [
        'file-thumbnail',
        fileThumbnail.align === 'column' ? 'file-thumbnail--vertical' : '',
      ];
      return cssClass;
    },
  },
};
</script>
<style lang="scss" scoped>
.file-thumbnail {
  display: flex;
  align-items: center;

  &--vertical {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
}
</style>
