<template>
  <b-dropdown
    variant="link"
    :toggle-class="
      'text-decoration-none font-weight-bold ' + toggleButtonTextVariant()
    "
    id="dropdown-form"
    :text="filter.label"
    ref="dropdown"
    class="my-1 filter-dropdown-button"
    menu-class="filterMenu"
    :key="refreshSelected"
  >
    <b-dropdown-group>
      <b-dropdown-text class="text-small">

        <small class="font-weight-bold">{{ filter.label }}</small>
      </b-dropdown-text>
      <b-dropdown-divider></b-dropdown-divider>

      <b-dropdown-item
        v-for="filterVal in filter.options"
        @click="optionClick(filterVal.value)"
        :key="filterVal.value"
      >
        <div
          v-if="filterVal.value == filter.selected"
          class="bg-primary text-white"
        >
          {{ filterVal.text }}
        </div>
        <div v-else>
          {{ filterVal.text }}
        </div>
      </b-dropdown-item>
    </b-dropdown-group>
  </b-dropdown>
</template>
<style>
.filterMenu {
  width: auto !important;
  max-width: auto !important;
  white-space: nowrap;
}
</style>
<script>
export default {
  name: 'EcenterTableFilterDropdown',
  props: ['filter', 'filterOptions'],
  data() {
    return {
      refreshSelected:null
    };
  },
  mounted() {},
  computed: {


  },
  methods: {
    optionClick(value) {
      if (this.filter.selected == value) {
        this.filter.selected = null;
      } else {
        this.filter.selected = value;
      }
      this.$emit('filterChange');
      this.$forceUpdate();
      
    },
    setSelectedFilter(value){
          this.filter.selected=value;
           this.$emit('filterChange');
             this.$forceUpdate();

    },
    toggleButtonTextVariant() {
      return (
        'text-' +
        this.$store.state.themeDefaults.eCenterTableFilterRowTextVariant
      );
    },
    filterChange() {
      this.$emit('filterChange');
    },
    clearFilter() {
      this.filter.selected = null;
      this.refreshSelected=new Date().getTime();
    },
    getSelectedFilters() {
      var filter = {};

      filter.name = this.filter.name;

      filter.selected = this.filter.selected;
      return filter;
    },
  },
};
</script>
