// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiModelApprovalSettings/getTableSettingsApprovalModels', payload).then(getData);

export const get = (payload = null) => http.post('apiModelApprovalSettings/GetApprovalSettings', payload).then(getData);

export const create = (row) => http.post('apiModelApprovalSettings/createApproval', row).then(getData);

export const update = (row) => http.post('apiModelApprovalSettings/editApproval', row).then(getData);

export const remove = (row) => http.post('apiModelApprovalSettings/deleteApproval', row).then(getData);

export const getRow = (row) => http.post(`apiModelApprovalSettings/getRowModelApproval&RowID=${row}`).then(getData);

export const getOverdueModels = (rowData) => http.post(`apiModelApprovalSettings/getOverdueModels`,rowData).then(getData);

export const getOverdueFields = (payload) => http.post(`apiModelApprovalSettings/getOverdueFields`,payload).then(getData);