import axios from 'axios';
import {
  deepCopy,
  findFile,
  getFileExtension,
  isFile,
  isObject,
} from './langUtils';
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';
import { isNumber } from 'lodash';

/**
 * Mutates obj by appending ('fileData': { name, ext, type })
 * @param object obj
 * @return object
 */
export const appendFileData = obj => {
  const FIELD_NAME = 'fileData';
  if (isObject(obj)) {
    for (const key in obj) {
      if (isFile(obj[key])) {
        const file = obj[key];
        const ext = getFileExtension(file);
        if (ext) {
          const type = file.type ? file.type : null;
          const name = new Date().getTime() + `.${ext}`;
          obj[FIELD_NAME] = { ext, name, type };
        }
      }
    }
  }
};

export const prepareFormData = obj => {
  appendFileData(obj);
  const data = deepCopy(obj);
  return data;
};

/**
 * @param {object[]} presignedData
 * @param {object} formAnswers
 * @param {Vue} vm VueComponent
 * @returns {Promise<object[]>}
 */
export const uploadFormResources = async (presignedData, formAnswers, vm) => {
  const responses = [];
  const plist = Array.isArray(presignedData)
    ? presignedData
    : [{ uploadResourceURL: presignedData }];
  for (let i = 0; i < plist.length; i++) {
    const data = plist[i];
    const progressMsg = `Uploading ${i + 1} / ${plist.length} `;
    if (vm.showProgressBar) vm.showProgressBar(true, progressMsg);
    const config = {
      onUploadProgress: function(progressEvent) {
        const loaded = progressEvent.loaded;
        const total = progressEvent.total;
        if (isNumber(loaded) && isNumber(total) && total > 0) {
          const completed = Math.round((loaded * 100) / total);
          if (vm.updateProgressBar) vm.updateProgressBar(completed);
        }
      },
    };
    const response = await uploadResource(data, formAnswers, config);
    if (response.error !== undefined && vm.showProgressBar) {
      vm.showProgressBar(false);
    }
    if (response.error) throw Error(response.message);
    responses.push(response);
  }
  return responses;
};

export const uploadResource = async (data, form, config = null) => {
  try {
    let file = null;
    if (data['Name']) {
      const fdata = form[data['Name']];
      if (Array.isArray(fdata)) {
        for (const f of fdata) if (isFile(f)) file = f;
      } else if (isFile(fdata)) file = fdata;
    } else file = findFile(form);
    if (!file) return;
    const { uploadResourceURL } = data;
    if (file.type) {
      config['headers'] = { 'Content-Type': file.type };
    }
    const response = await axios.put(uploadResourceURL, file, config);
    if (response && response.status == 200) {
      return { error: false, message: 'Success' };
    }
  } catch (error) {
    return { error: true, message: error };
  }
};

export const downloadObject = async data => {
  if (!isObject(data) || !data.pathToFetchSignedURL) {
    throw Error('Invalid object');
  }
  const response = await http
    .post(data.pathToFetchSignedURL, data)
    .then(getData);
  if (response.error) {
    throw Error(response.message);
  }
  if (response.resourceURL) {
    window.open(response.resourceURL, '_blank');
  }
  return response;
};

/**
 * Finds all files in obj and returns their properties
 * @param { object } obj
 * @returns { object } { fieldName : [ ] }
 */
export const getPropsFromFiles = obj => {
  const props = {};
  for (const key in obj) {
    const list = [];
    if (Array.isArray(obj[key])) {
      for (const ele of obj[key]) {
        if (isFile(ele)) list.push(getFileProps(ele));
      }
    } else {
      if (isFile(obj[key])) list.push(getFileProps(obj[key]));
    }
    if (list.length > 0) props[key] = list;
  }
  return props;
};

/**
 * Gets the necessary properties of a file
 * @param { File } file
 * @returns { object }
 */
export const getFileProps = file => {
  const type = file.type ? file.type : null;
  const ext = getFileExtension(file);
  const name = new Date().getTime() + `.${ext}`;
  const displayName = file.name;
  const size = file.size;
  return { ext, name, type, displayName, size };
};
