// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

const CNTLR_NAME = "apiLocationEHR";

export const getTableSettings = (payload = null) => http.post(`${CNTLR_NAME}/getTableSettings&InPicker=1`, payload).then(getData);

export const get = (payload = null) => http.post(`${CNTLR_NAME}/getLocations&InPicker=1`, payload).then(getData);

export const create = (row) => http.post(`${CNTLR_NAME}/create&InPicker=1`, row).then(getData);

export const getRow = (rowID) => http.get(`${CNTLR_NAME}/getRow&ID=${rowID}&InPicker=1`).then(getData);

export const selectAll = (payload) =>http.post(`${CNTLR_NAME}/getLocations&selectAll=1`,payload).then(getData);
