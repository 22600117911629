// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const get = (CustomFieldSetID, CustomFieldSetType, RowID='', FormType='',filters={},CustomFieldCategoryName='') =>
  http
    .post(
      `apiCustomField/getCustomFieldCategories&CustomFieldSetID=${CustomFieldSetID}&CustomFieldSetType=${CustomFieldSetType}&RowID=${RowID}&FormType=${FormType}&CustomFieldCategoryName=${CustomFieldCategoryName}`,filters
    )
    .then(getData);
export const getFieldValue = (CustomFieldID,ModelID) =>
    http
      .post(
        `apiCustomField/getFieldValue&CustomFieldID=${CustomFieldID}&ModelID=${ModelID}`
      )
      .then(getData);
 export const getFieldValueByResultSet = (CustomFieldID,ResultSetID,collectionType) =>
      http
        .post(
          `apiCustomField/getFieldValue&CustomFieldID=${CustomFieldID}&ResultSetID=${ResultSetID}&assessmentType=${collectionType}`
        )
        .then(getData);
export const getProvincesByCountryID = CountryID =>
  http.get(`apiProvince/getProvinces&CountryID=${CountryID}`).then(getData);
export const getCountiesByProvinceID = ProvinceID =>
  http
    .get(`apiCounty/getCountiesByProvince&ProvinceID=${ProvinceID}`)
    .then(getData);

export const getTinyMcePlaceholders = fieldID => http.post('apiCustomField/getTinyMCEPlaceHolders',{FieldID:fieldID}).then(getData);

export const getModelID = (Name,Model) => http.post(`apiCustomField/getModelIDByName&Model=${Model}&Name=${Name}`).then(getData);

export const getFieldName = (CustomFieldID) => http.get(`apiCustomField/getFieldName&CustomFieldID=${CustomFieldID}`).then(getData);
