<template>
    <div class="text-right">
        <b-button size="sm" @click="$bvModal.show('bv-image-modal')" variant="light" class="mr-1">
          {{ $t("pageActions.optional") }}
        </b-button>
    </div>
</template>

<script>

export default {
  props: ['index', 'row'],
  data () {
    return {
      defaultCssClasses:"mb-2",
    };
  },
  mounted () {
    
  },
  computed: {
      cssClasses: function () {
          var cssClasses = this.defaultCssClasses;

          return cssClasses;
      }
  },
  methods: {
  },
  components: {
  }
};
</script>