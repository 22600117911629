import guest from '@/router/middleware/guest';
import auth from '@/router/middleware/auth';
import Logout from './logout';
import Login from './login';
import LoginPin from './login-pin';
import ForgotPassword from './forgot-password';
import ResetPassword from './reset-password';
import RequestAccount from './request-account';
import ClientCollectionLogin from './clientCollectionLogin';
import ClientCollectionLogout from './clientCollectionLogout';
import UserCollectionLogin from './userCollectionLogin';
import UserCollectionLogout from './userCollectionLogout';
import LocationLogin from './locationLogin';
import LocationLogout from './locationLogout';
import ReferralLogin from './referralLogin';
import SharedGridLogout from './sharedGridLogout';
import MobileUserMessage from './mobileUserMessage';
import OnlineFormLogin from "./onlineFormLogin"
import UserAlreadyLoggedOutMessage from "./userCollectionAlreadyLoggedOut"
import ResultCollabLogin from "./resultCollaborationLogin"
import ResultCollabLogout from "./resultCollaborationLogout"

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/login',
    name: 'loginAfterPasswordReset',
    component: Login,
    props: { alertText: 'Password Reset Successfully' },
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/login/autoLaunchCollection/:autoLaunchRefKey',
    name: 'loginWithAutoLaunch',
    component: Login,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/onlineFormLogin/:loginKey',
    name: 'onlineFormLogin',
    component: OnlineFormLogin,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/user-already-loggedout',
    name: 'user-already-loggedout',
    component: UserAlreadyLoggedOutMessage,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/resetPassword/:UserID/:ResetKey(.*)',
    name: 'resetPassword',
    component: ResetPassword,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/request-account',
    name: 'request-account',
    component: RequestAccount,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/login-pin/:email',
    name: 'login-pin',
    component: LoginPin,
    meta: {
      middleware: [guest]
    }
  },
  {
    path:'/clientCollectionLogin/:email/:username/:collectionType/:collectionID/:loginKey(.*)',
    name:'clientCollectionLogin',
    component:ClientCollectionLogin,
    meta:{
      middleware: [guest]
    }

  },
  {
    path:'/clientCollectionLogout',
    name:'clientCollectionLogout',
    component:ClientCollectionLogout,
    meta:{
      middleware: [auth]
    }

  },
  {
    path:'/userCollectionLogin/:email/:username/:collectionType/:collectionID/:loginKey(.*)',
    name:'userCollectionLogin',
    component:UserCollectionLogin,
    meta:{
      middleware: [guest]
    }

  },
  {
    path:'/userCollectionLoginGeneric/:email/:username/:collectionType/:collectionID/:loginKey(.*)',
    name:'userCollectionLoginGeneric',
    component:UserCollectionLogin,
    meta:{
      middleware: [guest]
    }

  },
  {
    path:'/userCollectionLogout',
    name:'userCollectionLogout',
    component:UserCollectionLogout,
    meta:{
      middleware: [auth]
    }

  },
  {
    path:'/locationLogin/:command/:email/:locationID/:locationKey',
    name:'locationLogin',
    component:LocationLogin,
    meta:{
      middleware:[auth]
    }
  },
  {
    path:'/referralLogin/:tempUsername/:loginKey',
    name:'referralLogin',
    component:ReferralLogin,
    meta:{
      middleware:[auth]
    }
  },
  {
    path:'/collectionCollaborationLogin/:username/:collectionType/:resultSetID/:loginKey',
    name:'resultCollaborationLogin',
    component:ResultCollabLogin,
    meta:{
      middleware:[auth]
    }
  },
  {
    path:'/resultCollaborationLogout',
    name:'resultCollaborationLogout',
    component:ResultCollabLogout,
    meta:{
      middleware:[auth]
    }
  },
  {
    path:'/locationLogout',
    name:'locationLogout',
    component:LocationLogout,
    meta:{
      middleware:[auth]
    }
  },
  {
    path:'/sharedGridLogout',
    name:'sharedgrid-logout',
    component:SharedGridLogout,
    meta:{
      middleware:[auth]
    }
  },
  {
    path:'/mobileUserMessage',
    name:'mobileUserMessage',
    component:MobileUserMessage,
    props: true,
    meta:{
      middleware:[guest]
    }
  }
];
