// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

const CNTLR_NAME = 'apiNetworkingModules';

export const checkNetworkParticipation = () =>
  http
    .get(`${CNTLR_NAME}/checkNetworkParticipation`, { silent: true })
    .then(getData);

export const getForm = payload =>
  http.post(`${CNTLR_NAME}/getForm`, payload, { silent: true }).then(getData);

export const getFormAnswers = payload =>
  http
    .post(`${CNTLR_NAME}/getFormAnswers`, payload, { silent: true })
    .then(getData);

export const submitAnswer = payload =>
  http
    .post(`${CNTLR_NAME}/submitAnswer`, payload)
    .then(getData);
