import auth from '@/router/middleware/auth';

const Reporting = r => require.ensure([], () => r(require('./Reporting')), 'reporting-bundle');

export default [
  {
    path: '/reporting',
    name: 'reporting',
    component: Reporting,
    meta: {
      middleware: [auth]
    }
  },
];
