// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) =>
  http.post('apiLocation/getTableSettings', payload).then(getData);

export const get = (payload = null) =>
  http.post('apiLocation/getLocations', payload).then(getData);

export const create = location =>
  http.post('apiLocation/createLocation', location).then(getData);

export const update = location =>
  http
    .post('apiLocation/editLocation&LocationID=' + location.ID, location)
    .then(getData);

export const remove = location =>
  http.post('apiLocation/deleteLocation', location).then(getData);

export const getRow = (locationID) => http.get(`apiLocation/getRow&RowID=${locationID}`).then(getData);

export const getLocationList =(locationIDs,params)=> http.post('apiLocation/getLocationList',{locationIDs:locationIDs,...params}).then(getData);

export const getMappingFields = (payload) => http.post("apiLocation/getMappingLocations",{},{silent:true}).then(getData);
export const getFieldResponses = (payload) => http.post("apiLocation/getFieldResponses",payload,{silent:true}).then(getData);