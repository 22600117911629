<template>
  <div v-if="isComponentVisible()">
    <div class="w-100">
      <b-progress :value="value" :max="max" />
    </div>
  </div>
</template>
<script>
import { isNumber } from 'lodash';
export default {
  name: 'ProgressBar',
  props: {
    completionIconCss: { type: String, default: null },
    max: { type: Number, default: 100 },
    progressBarCss: { type: String, default: null },
    value: { type: Number, default: 0 },
  },
  methods: {
    isComponentVisible() {
      return isNumber(this.value);
    },
  },
};
</script>
