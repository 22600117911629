import auth from '@/router/middleware/auth';
const Dashboard = r => require.ensure([], () => r(require('./Dashboard')), 'dashboard-bundle');
export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      middleware: [auth]
    }
  }
];