<template>
	
		<discussionboards v-bind:inModal="true"></discussionboards >
</template>
<style>

</style>
<script>
  
  import DiscussionBoard from './DiscussionBoardGrid.vue';


export default {
  
	props: {
		selectRowURL: String,
		selectRowData: Object,
	},
  data() {
      return {
        
      }
  },
  
  created() {

  },
  mounted(){
	
  },
  methods:{
	  
  },
  components:{
	  "discussionboards":DiscussionBoard
  }

 
  
}
</script>