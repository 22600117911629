<template>
<b-container style="max-height:100% !important">
       
        <b-row style="max-height:100% !important;">
            <b-col sm="12" >
                <LinkedStaffGrid v-bind="{isChild:true,passedServiceName:'formCollaboration',passedParams:{AssessmentID:AssessmentID,LinkedStaff:true}}"></LinkedStaffGrid>
             
            </b-col>
        </b-row>
      </b-container>  
</template>
<script>


import { API_URL } from '@/config';

export default {
  name: 'FormCollaboration',
  components:{
    AllStaffGrid:()=>import('@/app/list-view/index.vue'),
    LinkedStaffGrid:()=>import('@/app/list-view/index.vue')
  },
  data() {
    return {
      apiURL: API_URL,
      allStaffData: [],
      linkedStaffData:[],
      collectionData: {},
      showMessage: false,
      message: '',
      textColor: 'black',
      availStaffSearch:null
    };
  },
  mounted(){
    
    if($(".headerTitle").length>0){
      $(".headerTitle").text('Manage Collaborators');
      
    }
   

  },
  props: {
    AssessmentID:{type:String, default:null}
  },
  methods: {
    getAllStaff(silent=false){
      staffService.getStaffCollaboration({assessmentID:this.AssessmentID,searchStaff:this.availStaffSearch},silent)
      .then( response =>{
        this.allStaffData=response;
      });
    },
    getCollaborators(){
        formService.getCollaborators({assessmentID:this.AssessmentID})
        .then(response =>{
          this.linkedStaffData=response;
        });
    },
    addCollaborator(UserID){
        formService.addFormCollaborator({UserID:UserID,AssessmentID:this.AssessmentID})
        .then(response =>{
            this.getAllStaff();
            this.getCollaborators();

        });
    },
    removeCollaborator(UserID){
        formService.removeFormCollaborator({UserID:UserID,AssessmentID:this.AssessmentID})
        .then(response =>{
            this.getAllStaff();
            this.getCollaborators();

        });
    }

  },
};
</script>
