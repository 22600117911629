import auth from '@/router/middleware/auth';

const ListView = r =>
  require.ensure([], () => r(require('@/app/list-view/index')), 'list-view-bundle');

const ListViewAllStaff = r =>
  require.ensure([], () => r(require('@/app/list-view/index')), 'list-view-bundle');

  const ListViewLinkedStaff = r =>
  require.ensure([], () => r(require('@/app/list-view/index')), 'list-view-bundle');
export default [
  {
    path: '/orgforms/:isorg/:service/:prefiltername/:prefilter',
    components:{ 
       default: ListView,
       unlinkedStaff:ListViewAllStaff,
      linkedStaff:ListViewLinkedStaff
    },
    props:{
        default:true,
        unlinkedStaff:{linkedStaffOnly:false},
        linkedStaff:{linkedStaffOnly:true}
    },
    meta: {
      middleware: [auth],
    },
  },
];
