// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) =>
  http.post('apiLocationEHR/getTableSettings', payload).then(getData);

export const get = (payload = null) =>
  http.post('apiLocationEHR/getLocations', payload).then(getData);

export const create = location =>
  http.post('apiLocationEHR/createLocation', location).then(getData);

export const update = location =>
  http
    .post('apiLocationEHR/editLocation&LocationID=' + location.ID, location)
    .then(getData);

export const remove = location =>
  http.post('apiLocationEHR/deleteLocation', location).then(getData);

export const getRow = (locationID) => http.get(`apiLocationEHR/getRow&RowID=${locationID}`).then(getData);

export const getLocationList =(locationIDs,params)=> http.post('apiLocationEHR/getLocationList',{locationIDs:locationIDs,...params}).then(getData);

export const getMappingFields = (payload) => http.post("apiLocationEHR/getMappingLocations",{},{silent:true}).then(getData);
export const getFieldResponses = (payload) => http.post("apiLocationEHR/getFieldResponses",payload,{silent:true}).then(getData);

export const getLocationTypeTags = () => http.get('apiLocationEHR/getLocationTypes').then(getData);
export const getLocationTypeCategories = () => http.get('apiLocationEHR/getLocationCategories').then(getData);

export const getOccupancyGroupFormSettings = (payload) => http.post('apiLocationEHR/getOccupancyGroupForm',payload,{silent:true}).then(getData);

export const getOccupancyDisplay = (payload) => http.post('apiLocationEHR/getOccupancyGroupDisplayValues',payload,{silent:true}).then(getData);

export const locationLogin = (payload) => http.post('apiAuthentication/authenticateLocationLogin', payload).then(getData);

export const confirmedLocationSettings = (payload) => http.post('apiLocationEHR/confirmedSettings',payload).then(getData);

export const getFieldSetName = () =>http.get('apiLocationEHR/getCustomFieldSetName').then(getData);

export const getLogoutMessage = (locationID) =>http.get('apiLocationEHR/getLogoutMessage&LocationID='+locationID).then(getData);

export const optOutAudit = (payload)=>http.post('apiLocationEHR/optOutAudit',payload).then(getData);

export const optOutOfEmails = (payload)=>http.post('apiLocationEHR/processOptOut',payload).then(getData);

export const getCapaityFieldName = (payload)=> http.post("apiLocationEHR/getCapacityFieldName").then(getData);

export const getAuditGridCanShow = (payload) => http.post("apiLocationEHR/auditForOccupancyGrid",payload).then(getData);