import auth from '@/router/middleware/auth';
const SharedGrid = r => require.ensure([], () => r(require('./sharedGridForm')), 'shared-grid-bundle');
export default [
  {
    path: '/sharedGrid/:model/:username/:key',
    name: 'shared-grid',
    component:SharedGrid ,
    meta: {
      middleware: [auth]
    },
  
  }
];