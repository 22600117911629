<template>
  <div class="container">
    <!-- Outer Row -->
    <div class="row justify-content-center">
      <div class="col-xl-10 col-lg-12 col-md-9">
        <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
            <!-- Nested Row within Card Body -->
            <div class="row">
              <div class="col">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">{{ $t('auth.forgot-password.forgot') }}</h1>

                    <p>{{ $t('auth.forgot-password.forgotMessage') }}</p>
                  </div>

                  <b-alert v-show="alertText" variant="success" show v-html="alertText"></b-alert>

                  <b-form @submit.stop.prevent="submit">
                    <b-form-group id="Email" :label="$t('general.emailAddress')" label-for="Email">
                      <b-form-input v-model="form.Email" id="Email" name="Email" type="email" required :placeholder="$t('auth.enterEmail')"></b-form-input>
                    </b-form-group>

                    <div v-show="formError" class="col text-center text-danger">{{ formError }}</div>

                    <div class="row mb-4">
                      <div class="col text-center">
                        <b-button-group>
                          <b-button variant="success" type="submit">{{ $t('general.submit') }}</b-button>
                          <b-button variant="danger" @click="cancel">{{ $t('general.cancel') }}</b-button>
                        </b-button-group>
                      </div>
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import * as userService from '@/services/user';

export default {
  name: 'Login',
  data () {
    return {
      alertText: '',
      form: {},
      formError: '',
    };
  },
  methods: {
    submit () {
      this.alertText = '';
      this.formError = '';

      userService.forgotPassword(this.form)
        .then(response => {
          if(response.success){
            this.alertText = response.message;
          }else{
            this.formError=response.error
          }
        }).catch(error => {
          this.formError = error.data.error;
          // this.submitError(error);
          // this.disableSubmitLoader();
        });
    },
    cancel () {
      this.$router.push({ path: '/login' });
    }
  }
};
</script>
