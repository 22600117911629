<template>
  <div :key="refreshTable">
    <ProgressBarModal
      :header="progressBarModalHeader"
      :modalVisibility="showProgressBarModal"
      :id="`progress-bar-dialog-${componentID}`"
      :value="progressBarCompleted"
    />
    <b-modal
      id="modal-pleaseWait"
      ref="modal-pleaseWait"
      hide-header
      hide-footer
    >
      <div class="d-block text-center">
        <h3>Please Wait</h3>
      </div>
    </b-modal>
    <b-modal
      id="modal-error"
      hide-header
      ok-variant="success"
      cancel-variant="danger"
      @cancel="$router.go(-1)"
      :ok-title="
        tableError && tableError.data && tableError.data.okTitle
          ? tableError.data.okTitle
          : 'Ok'
      "
      @ok="onClickOkTableError"
    >
      <div class="d-block text-center">
        <h3>{{ $t("general.error") }}</h3>
        <hr />
        <div
          v-html="tableError && tableError.message ? tableError.message : ''"
        />
      </div>
    </b-modal>
    <b-row no-gutters class="mb-2" v-if="permissions.showGlobalSearchBox || permissions.allowAdd || permissions.allowSearchButton || permissions.showCustomAction ">
      <b-col :cols="isSubgrid ? 9 : (inPicker?9:5)">
        <EcenterTableSearch
          v-show="permissions.showGlobalSearchBox"
          @ecenterSearch="globalSearch"
          :placeHolderString="globalSearchPlaceHolder"
          :defaultValue="globalSearchDefault"
          :key="globalSearchTime"
        ></EcenterTableSearch>
      </b-col>

      <b-col class="text-right" >
        <EcenterTableActions
          :key="updateActions"
          @openAddRowDialog="openAddRowDialog"
          @openSearchDialog="openSearchDialog"
          @openCustomDialog="openCustomDialog"
          @printClicked="printGrid"
          @shareClicked="openShareGridDialog"
          @autoShareClicked="autoShareGrid"
          @openCFormDialog="openCFormDialog($event)"
          @openSurveyJsFormDialog="openSurveyJsFormDialog($event)"
          v-bind:allowAdd="permissions.allowAdd"
          v-bind:allowSearch="permissions.allowSearchButton"
          v-bind:showCustomAction="permissions.showCustomAction"
          v-bind:addButtonLabel="settings.addButtonLabel"
          v-bind:customActionButtonLabel="settings.customActionButtonLabel"
          v-bind:searchButtonLabel="settings.searchButtonLabel"
          v-bind:tableActions="customTableActions"
          v-bind:printSettings="printSettings"
          v-bind:shareSettings="shareSettings"
        >
        </EcenterTableActions>
        <div style="display:inline-block">
          <slot name="customTableAction"></slot>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="tableInstruction" class="mb-3">
      <b-col class="d-flex">
        <b-button
          v-b-toggle="'collapse-2'"
          variant="light"
          class="m-1 d-flex align-items-center"
        >
          <b-icon-info-circle class="mr-2" /><b-icon-chevron-down
            font-scale="0.8"
            class="ml-2"
          />
        </b-button>
        <b-collapse id="collapse-2" class="p-3" visible>
          <div v-html="tableInstruction"></div>
        </b-collapse>
      </b-col>
    </b-row>

    <b-row v-if="showFilterBreadcrumbs()" :key="updateFilterBreadcrumbs">
      <b-col md="12">
        <span v-for="(filter,filterName) in $refs.filterRow.getSelectedFilters()" :key="'filterBreadcrumb_'+filterName">
              <b-badge v-if="filterValue(filter) && (hasFilterOptions(filterName) || isDateRangeFilter(filterName))" class="ml-2">
              <span  v-html="getFilterDisplay(filterName,filter) "></span>
              <div @click="removeTag($event, filterName, filter)" class="button-remove-tag">×</div>

              </b-badge>
        </span>
      </b-col>
      
    </b-row>

    <EcenterTableFilterRow
      :inPicker="inPicker"
      :filterRowTitle="filterRowTitle"
      @clearSearch="clearGlobalSearch"
      @addFilter="addFilter"
      @applyFilters="applyFilters"
      v-if="((filterOptions && Array.isArray(filterOptions) && filterOptions.length>0  && isGridField) || (filterOptions && Array.isArray(filterOptions) && !isGridField) || sortOptions)"
      ref="filterRow"
      v-bind:filterOptions="filterOptions"
      v-bind:sortOptions="sortOptions"
      v-bind:hasSortGroups="hasSortGroups"
      v-bind:showSelectAllRowsCheckbox="
        permissions.showSelectAllRowsCheckbox || showSelectAll
      "
      @sortOptionSelected="getRows"
      v-bind:globalSearch="globalSearchApplied"
      v-bind:tableService="serviceName"
      @selectAllClicked="applySelectAll"
      @deselectAllClicked="applyDeselectAll"
      v-bind:setSelectAll="setSelectAll"
      v-bind:disableFilterAutoApply="disableAutoApplyFilter"
    ></EcenterTableFilterRow>
    <b-row no-gutters class="border-bottom p-2 pl-4 pr-4" v-else-if="rows && rows.length>0 && isGridField" >
    </b-row>
    <b-row v-else-if="!isGridField" 
        no-gutters
        class="global-primary__bg global-primary__bg--no-hover align-items-center p-2"
        style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem">
            <b-col cols="12">

            </b-col>
        </b-row>

    <b-row v-if="emptyMessage && rows.length==0">
            <b-col md="12">
               <b-alert variant="success"  show>{{ emptyMessage }}</b-alert>
            </b-col>

    </b-row>

    <EcenterTableRow
      v-for="row in rows"
      :key="row.ID"
      v-bind:row="row"
      v-bind:showSelectRowCheckbox="permissions.showSelectRowCheckbox"
      v-bind:showRowActions="permissions.showRowActions"
      v-bind:allowEdit="permissions.allowEdit"
      v-bind:allowDelete="permissions.allowDelete"
      v-bind:allowRename="permissions.allowRename"
      v-bind:allowDownload="permissions.allowDownload"
      v-bind:createdByUserCanEdit="createdByUserCanEdit"
      v-bind:editButtonLabel="editButtonLabel"
      :gridControls="gridControls || localGridControls"
      :gridControlUri="gridControlUri"
      @openEditRowDialog="openEditRowDialog(row)"
      @openDeleteRowDialog="openDeleteRowDialog"
      @onOpenCustomDialog="openCustomDialog($event)"
      @openCFormDialog="openCFormDialog($event)"
      @openSurveyJsDialog="openSurveyJsFormDialog($event)"
      @onOpenCustomConfirmation="openCustomConfirmation($event)"
      @openItemDetailsDialog="openItemDetailsDialog($event)"
      @onRowSelected="onRowSelected(row)"
      @openRowPositionDialog="openRowPositionDialog(row)"
      @onClickActionBtnSubmit="submitActionButton($event)"
      @onClickActionSubmit="submitAction($event)"
      @customEvent="emitCustomEvent"
      @onClickActionGetResourceLink="getResourceLink($event)"
      :inPicker="inPicker"

    >
      <template v-slot:rowContent>
        <slot
          name="rowContent"
          v-bind:row="row"
          v-bind:gridControls="gridControls || localGridControls"
          v-bind:gridControlUri="gridControlUri"
          :permissions="permissions"
          :listType="listType"

        >
          {{ row.Name }}
        </slot>
      </template>

      <template v-slot:customRowActions>
        <slot name="customRowActions" v-bind:row="row"> </slot>
      </template>
    </EcenterTableRow>

    <EcenterTablePagination
      ref="pagination"
      @input="getRows"
      v-show="paginationOptions.enabled"
      v-bind:defaultCurrentPage="paginationOptions.defaultCurrentPage"
      v-bind:perPage="paginationOptions.perPage"
      v-bind:totalRows="totalRows"
    ></EcenterTablePagination>

    <!-- Modals -->
    <ItemDetailsDialog
      v-if="showItemDetails && itemDetailsData"
      :detailsData="itemDetailsData"
      :errorMessage="formError"
      @onClickCancel="onClickCancelItemDetails()"
      @onError="onItemDetailsError($event)"/>
    <CustomFieldFormDialog
      v-if="showCForm && cFormData"
      :key="cFormKey"
      :errorMessage="formError"
      :formData="cFormData"
      @onUploadResources="onUploadResources($event)"
      @onClickCancel="onClickCancelCForm()"
      @onClickFormMenuBtn="onClickCFormMenuBtn($event)"
      @onError="onCFormError($event)"/>
    <SurveyJsFormDialog
      v-if="showSurveyJsForm && surveyJsFormData"
      :errorMessage="formError"
      :formData="surveyJsFormData"
      @onClickCancel="onClickCancelSurveyJsForm()"
      @onClickFormMenuBtn="onClickCFormMenuBtn($event)"
      @onError="onCFormError($event)"/>
    <!-- Modal - ADD -->
    <b-modal
      size="xl"
      v-model="addDialogStatus"
      :id="`add-dialog-${componentID}`"
      visible
      hide-footer
      scrollable
      title="Files"
    >
      <template v-slot:modal-header>
        <div class="w-100 text-right">
          <b-button
            v-if="!hideSave"
            class="ml-2"
            variant="success"
            @click="addRow"
            >{{ saveText }}</b-button
          >
          
          <b-button
            v-if="!hideCancel"
            class="ml-2"
            variant="danger"
            @click="closeAddRowDialog(false)"
            >{{ cancelText }}</b-button
          >
        </div>
      </template>

      <div class="container-fluid bg-white" id="addFormContainer">
        <div>
          <slot
            name="addRowForm"
            v-bind:formFields="formFields"
            v-bind:formFieldsHasCategories="formFieldsHasCategories"
            v-bind:addRowData="addRowData"
            :customFieldCategories="customFieldCategories"
            v-bind:errorMessage="formError"
            v-bind:refreshTime="addUpdate"
            v-bind:validator="$v"
            v-bind:defaultTitle="defaultAddTitle"
            v-bind:serverParams="passedFilters"
            v-bind:component="component"
            v-bind:componentParams="componentParams"
            v-bind:serviceName="serviceName"
            v-bind:addParams="addParams"
          ></slot>
        </div>
      </div>
    </b-modal>

    <!-- Modal - CustomAction -->
    <CustomDialog
      v-if="renderCustomDialog"
      :settings="customDialogSettings"
      :serverParams="passedFilters"
      :customDialogParams="customDialogParams"
      :service="serviceName"
      :defaultData="detailRowData"
      @onCloseCustomDialog="closeCustomDialog"
      @onSuccessCustomDialog="successCustomDialog"
      :key="refreshCustomDialog"
      ref="customDialogRef"
    />

    <CustomDialog
      v-if="renderAfterAddDialog"
      :settings="afterAddDialogSettings"
      :serverParams="passedFilters"
      @onCloseCustomDialog="closeAfterAddDialog"
      @onSuccessCustomDialog="successAfterAddDialog"
      :key="refreshAfterAddDialog"
      ref="customAfterAddDialogRef"
    />

    <CustomDialog
      v-if="renderAfterEditDialog"
      :settings="afterEditDialogSettings"
      :serverParams="passedFilters"
      @onCloseCustomDialog="closeAfterEditDialog"
      @onSuccessCustomDialog="successAfterEditDialog"
      :key="refreshAfterEditDialog"
      ref="customAfterEditDialogRef"
    />

    <CustomDialog
      v-if="renderAfterCustomDialog"
      :settings="afterCustomDialogSettings"
      :serverParams="passedFilters"
      @onCloseCustomDialog="closeAfterCustomDialog"
      @onSuccessCustomDialog="successAfterCustomDialog"
      :key="refreshAfterCustomDialog"
      ref="customAfterEditDialogRef"
    />

    <RowPositionDialog
      v-if="renderRowPositionDialog && selectedRow"
      :row="selectedRow"
      @onCloseRowPositionDialog="closeRowPositionDialog"
      @onClickEditRowPosition="submitRowPosition"
    />

    <CustomConfirmation
      v-if="renderCustomConfirmation"
      :settings="customConfirmationSettings"
      :serverParams="passedFilters"
      @onCloseCustomConfirmation="closeCustomConfirmation"
      :key="refreshCustomConfirmation"
      ref="customCoinfirmationRef"
    />

    <ShareGridDialog
      v-if="renderShareGridDialog"
      :client="$route.params.clientID?$route.params.clientID:customServerParams.rowID"
      :shareGetUri="shareSettings.shareGetUri"
      :shareSetUri="shareSettings.shareSetUri"
      :shareFilters="shareFilterSettings"
      :shareResendUri="shareSettings.shareResendUri"
      @onCloseShareGrid="closeShareGridDialog"
      ref="shareGridDialogRef"
    />

    <!-- Modal - EDIT -->
    <b-modal
      size="xl"
      v-model="editDialogStatus"
      :id="`edit-dialog-${componentID}`"
      visible
      hide-footer
      scrollable
      title="Files"
      ignore-enforce-focus-selector=".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root, .tox-textfield, .tox-dialog"    >
      <template v-slot:modal-header>
        <div class="w-100 text-right">
          <b-button class="ml-2" variant="success" @click="editRow"
            >{{ $t("general.save") }}</b-button
          >
          <b-button class="ml-2" variant="danger" @click="closeEditRowDialog"
            >{{ $t("general.cancel") }}</b-button
          >
        </div>
      </template>

      <div class="container-fluid bg-white">
       
        <div>
          <slot
            name="editRowForm"
            v-bind:editRowData="editRowData"
            :customFieldCategories="customFieldCategories"
            v-bind:formFields="formFields"
            v-bind:formFieldsHasCategories="formFieldsHasCategories"
            v-bind:serverParams="passedFilters"
            v-bind:errorMessage="formError"
            v-bind:refreshTime="editUpdate"
            v-bind:validator="$v"
            v-bind:serviceName="serviceName"
            v-bind:defaultTitle="
              editRowData.formTitle ? editRowData.formTitle : defaultEditTitle
            "
          ></slot>
        </div>
      </div>
    </b-modal>

    <!-- Modal - SEARCH -->
    <b-modal
      size="xl"
      id="search-dialog"
      hide-footer
      scrollable
      title=""
      modal-class="search-modal-size-modal"
      dialog-class="search-modal-size-dialog"
      content-class="search-modal-size-content"
      body-class="search-modal-size"
    >
      <template v-slot:modal-header>
        <div class="w-100 text-right">
          <b-button class="ml-2" variant="danger" @click="closeSearchDialog"
            >Close</b-button
          >
        </div>
      </template>

      <div class="container-fluid bg-white">
        <div>
          <slot name="searchDialogContent"></slot>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<style>
.search-modal-size-modal {
  width: 100% !important;
  max-width: 100% !important;
}
.search-modal-size-dialog {
  width: 98% !important;
  max-width: 98% !important;
  margin: auto !important;
}
.search-modal-size-content {
  width: 100% !important;
  max-width: 100% !important;
}
.search-modal-size {
  width: 100% !important;
  max-width: 100% !important;
}
</style>
<script>
import Vue from 'vue';
import EcenterTableSearch from './ecenter-table-search.vue';
import EcenterTableActions from './ecenter-table-actions.vue';
import EcenterTableFilterRow from './ecenter-table-filter-row.vue';
import EcenterTableRow from './ecenter-table-row.vue';
import EcenterTablePagination from './ecenter-table-pagination.vue';
import CustomDialog from '@/components/CustomDialog';
import CustomConfirmation from '@/components/CustomConfirmation';
import CustomFieldFormDialog from '@/components/form/CustomFieldFormDialog.vue';  
import SurveyJsFormDialog from '@/components/formSurveyJs/SurveyJsFormDialog.vue';
import ItemDetailsDialog from '@/components/itemDetails/ItemDetailsDialog.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import * as customFieldService from '@/services/customField';
import { isEmpty } from '@/utils/isEmpty.js';
import { ErrorMessages } from '@/utils/errorMessages.js';
import { getData } from '@/utils/get';
import { copyAndMerge, deepCopy, getErrorMessage, isObject } from '@/utils/langUtils';
import RowPositionDialog from '@/components/RowPositionDialog.vue';
import ProgressBarModal from '@/components/ProgressBarModal.vue';
import { getPropsFromFiles, uploadResource as s3uploadResource } from '@/utils/s3ResourceUtils';
import { isNumber } from 'lodash';
import { API_URL } from '@/config';
import ShareGridDialog from '@/components/ShareGridDialog.vue';
import { apiPost } from '@/services/generic';
import { cFieldToPDF } from '@/utils/cfUtils';

const timeValidate =(value)=> 
    value.match(/(([0-9]):[0-5][0-9] (AM|PM|am|pm))|(0([0-9]):[0-5][0-9] (AM|PM|am|pm))|(1([0-2]):[0-5][0-9] (AM|PM|am|pm))/)?true:false;

export default {
  name: 'EcenterTable',
  components: {
    EcenterTableSearch,
    EcenterTableActions,
    EcenterTableFilterRow,
    EcenterTableRow,
    EcenterTablePagination,
    CustomDialog,
    RowPositionDialog,
    CustomConfirmation,
    ProgressBarModal,
    ShareGridDialog,
    CustomFieldFormDialog,
    SurveyJsFormDialog,
    ItemDetailsDialog,
  },
  props: {
    createdByUserCanEdit: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    serviceName: String,
    refreshAfter: Number,
    defaultRow: Object,
    customServerParams: Object,
    selectedUsers: Array,
    componentRef: String,
    parentRef: {
      type: String,
      default: null,
    },
    validationSettings: {
      type: Object,
      default: function() {
        return {};
      },
    },
    filterRowTitle: { type: String, default: '' },
    customFieldSetName: { type: String },
    startupCmds: { type: Array, default: null },
    rowID: { type: String, default: null },
    addDialogID: { type: String, default: null },
    editDialogID: { type: String, default: null },
    showSelectAll: { type: Boolean, default: false },
    isSubgrid: { type: Boolean, default: false },
    gridControls:[Array, Object],
    inPicker: {type: Boolean, default: false},
    isGridField:{type: Boolean, default:false},
    emptyMessage:{type: String, default:null },
    propParams:{type: Object, default:function(){return {}}}
  },
  data() {
    return {
      addDialogIDProp: null,
      addDialogStatus: null,
      addRowData: {},
      addUpdate: null,
      afterAddDialogSettings: null,
      afterCustomDialogSettings: {},
      afterEditDialogSettings: null,
      afterSelectUri: null,
      cancelText: 'Cancel',
      checkNotificationsOnGetRows: null,
      component: null,
      componentID: null,
      componentParams: {},
      customConfirmationSettings: null,
      customDialogSettings: null,
      customFieldCategories: [],
      customTableActions: null,
      defaultAddTitle: null,
      defaultEditTitle: null,
      deleteMessage: null,
      detailRowData:{},
      dynamicRequired: {},
      editButtonLabel: null,
      editDialogIDProp: null,
      editDialogStatus: null,
      editErrorFlag: false,
      editRowData: {},
      editUpdate: null,
      filterOptions: [],
      formError: null,
      formFields: [],
      formFieldsHasCategories: false,
      globalSearchApplied: false,
      globalSearchDefault: null,
      globalSearchPlaceHolder: 'Search by Client ID, Name, or Email',
      globalSearchTime: new Date().getTime(),
      hasSortGroups: false,
      hideCancel: false,
      hideSave: false,
      listType: null,
      localSelectedRows: [],
      origCustomDialogSettings: null,
      origRow: {},
      paginationOptions: {
        defaultCurrentPage: 1,
        enabled: true,
        perPage: 20,
      },
      passedFilters: {
        type: Object,
        default: function() {
          return {};
        },
      },
      passedParams: null,
      permissions: {
        showSelectRowCheckbox: false,
        showSelectAllRowsCheckbox: false,
        showRowActions: false,
        allowAdd: false,
        allowEdit: false,
        allowDelete: false,
        allowRename: false,
        showGlobalSearchBox: false,
      },
      printSettings: null,
      progressBarCompleted: null,
      progressBarModalHeader: 'Uploading...',
      refreshAfterAddDialog: 1000,
      refreshAfterCustomDialog: 0,
      refreshAfterEditDialog: 2000,
      refreshCustomConfirmation: 0,
      refreshCustomDialog: 0,
      refreshOptions: 0,
      refreshTable: 0,
      refreshTableAfter: null,
      renderAfterAddDialog: false,
      renderAfterCustomDialog: false,
      renderAfterEditDialog: false,
      renderCustomConfirmation: false,
      renderCustomDialog: false,
      renderRowPositionDialog: false,
      renderShareGridDialog: false,
      rows: {
        data: [],
      },
      saveButtonLabel: null,
      saveText: 'Save',
      selectedRow: null,
      serverParams: {
        customParams: this.customParams,
      },
      service: {},
      setSelectAll: false,
      settings: {
        addButtonLabel: 'Add Record',
        fileUploadOnAdd: false,
      },
      settingsLoaded: false,
      shareFilterSettings: null,
      shareSettings: null,
      silent: true,
      sortOptions: [],
      storeRequiredFields:[],
      tableError: null,
      tableInstruction: null,
      tableIsLoading: false,
      timer: null,
      tmpSavedAnswers: null,
      totalRows: 0,
      validationsFromFields: {
        type: Object,
        default: function() {
          return {};
        },
      },
      reloadTableOnFilterClear:false,
      refreshActions:0,
      cFormData: null,
      showCForm: false,
      surveyJsFormData: null,
      showSurveyJsForm: false,
      showProgressBarModal: false,
      itemDetailsData: null,
      showItemDetails: false,
      cFormKey: null,
      initialRowCount:0,
      disableAutoApplyFilter: false,
      gridUpdateData:{},
      hasGridControls:false,
      updateActions:null,
      updateFilterBreadcrumbs:null,
      editAuditUri:null,
      customDialogParams:{},
      localGridControls:[],
      gridControlUri:null,
      addParams:[]
    
    };
  },
  mixins: [validationMixin],
  validations() {
    if (this.validationsFromFields) {
      return {
        addRowData: this.validationsFromFields,
        editRowData: this.validationsFromFields,
      };
    } else {
      return {
        addRowData: this.validationSettings,
        editRowData: this.validationSettings,
      };
    }
  },
  mounted() {



    this.service = require('@/services/' + this.serviceName);
    this.componentID = `${this.serviceName}_${new Date().getTime()}`;
    if (this.selectRowServiceName) {
      this.selectRowService = require('@/services/' +
        this.selectRowServiceName);
    }

    var vm = this;
    this.$root.$on('addRequiredFields', data => {
      vm.addRequiredDynamic(data);
    });


    /*
    this.$root.$on('updateParams', data => {
        this.passedParams={...this.passedParams,...data};

        this.passedFilters={...this.passedFilters,...data};


        this.addUpdate = new Date().getTime();
        this.editUpdate = new Date().getTime();

    });

*/

    this.$root.$on('reloadGridOnClose', data => {
      let vm = this;

      let waitTillPaginiation = setInterval(function() {
        if (vm.$refs.pagination) {
          clearInterval(waitTillPaginiation);
          vm.getRows();
        }
      }, 100);
    });

    this.addDialogIDProp = this.addDialogID ? this.addDialogID : 'add-dialog';
    this.editDialogIDProp = this.editDialogID
      ? this.editDialogID
      : 'edit-dialog';

    this.setup();
  },
  created() {},
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  watch: {
    selectedUsers: {
      deep: true,
      handler(newVal, old) {
        if (newVal.length == this.initialRowCount) {
          this.setSelectAll = true;
        } else {
          this.setSelectAll = false;
        }
      },
    },
  },

  methods: {

     removeTag(e, filterName, filterValue) {




          let filterToClear=this.$refs.filterRow.$refs.filters.filter(filter => filter.filter.name==filterName);

          if(filterToClear){
            let currentSelections=this.$refs.filterRow.getSelectedFilters();
             this.$refs.filterRow.setPrevFilter(currentSelections);

            filterToClear[0].clearFilter();
             this.updateFilterBreadcrumbs=new Date().getTime();

          }

          this.$refs.filterRow.setFilterCleared(filterName);
          

          this.$refs.filterRow.filterApplied();

          this.$refs.filterRow.displayUndo();


           


           
      },
      filterValue(filterValue){
          if(Array.isArray(filterValue)){
              return Object.keys(filterValue).length>0;
          }else{
            if (filterValue && isObject(filterValue))
              return true;
            return filterValue && filterValue.length>0;
          }
      },
      isDateRangeFilter: function(filterName) {
        const matchingFilter = this.$refs.filterRow.filterOptions.find(filter => filter.name === filterName);
        if (matchingFilter && matchingFilter.type === 'dateRange')
          return true;
        return false;
      },
      hasFilterOptions(filterName){
        let matchingFilter= this.$refs.filterRow.filterOptions.filter(filter =>{
            
            return filter.name==filterName;

       });

       if(matchingFilter.length>0){
          if(typeof matchingFilter[0].options !=='undefined' && matchingFilter[0].options.length>0){
            return true;
          }else{
            return false;
          }

       }else{
        return false;
       }


      },
     getFilterDisplay(filterName,filterValue){
       
       


       let matchingFilter= this.$refs.filterRow.filterOptions.filter(filter =>{
            
            return filter.name==filterName;

       });

       if(matchingFilter.length>0){



        if(Array.isArray(filterValue)){
            let selectedFilterText=[];



            filterValue.forEach(valueSelected=>{
                let matchingValue=matchingFilter[0].options.filter(filterOption => filterOption.value==valueSelected);



                if(matchingValue.length>0){

                  selectedFilterText.push(matchingValue[0].text);
                }else{
                  selectedFilterText.push(filterValue);
                }

            });

            return matchingFilter[0].label+':<br>'+selectedFilterText.join(", ");

        }else{
          // filterValue is a string when it's not multi-select.
          // Need to check if it's an option and get its text value
          if (Array.isArray(matchingFilter[0].options)) {
            for (let option of matchingFilter[0].options) {
              if (option.value == filterValue) {
                return `${matchingFilter[0].label}:<br>${option.text}`;
              }
            }
          } else if (matchingFilter[0].type === 'dateRange') {
            const dateRangeStart = filterValue && filterValue.StartDate || "";
            const dateRangeEnd = filterValue && filterValue.EndDate || "";
            return `${matchingFilter[0].label}:<br/>${dateRangeStart} - ${dateRangeEnd}`;
          }
              return matchingFilter[0].label+':<br>'+filterValue;

        }
       
       }else{


        if(Array.isArray(filterValue)){

            return filterName+':<br>'+filterValue.join(", ");

        }else{
              return filterName+':<br>'+filterValue;

        }
       }




     },
     showFilterBreadcrumbs(){


      

      if(this.$refs.filterRow && this.$refs.filterRow.getSelectedFilters()){
        
        return true;
      }else{

       
        return false;
      }
    },

    printGrid(data) {


      	var url= API_URL+data.printUri;

         var params = this.getAllServerParams();


      if(this.localSelectedRows){
          params= {...params,selectedItems:this.localSelectedRows}
      }

      if (this.passedFilters) {
        params = { ...params, ...this.passedFilters };
      }

      if (this.$route.params) {
        params = { ...params, ...this.$route.params };
      }

      if (this.passedParams) {
        params = { ...params, ...this.passedParams };
      }

      if (this.$route.query) {
        params = { ...params, ...this.$route.query };
      }


        this.$bvModal.show('loading-modal');
        this.$http.post(url,params)
        .then(response =>{


          
           this.$bvModal.hide('loading-modal');

          if(response.data.data.ExportBatchID){
              location.href=response.data.data.DownloadUri;
          }


        });

     



        
    },
    addFilter(value) {

      this.updateFilterBreadcrumbs=new Date().getTime();
      if(!this.disableAutoApplyFilter){
           this.getRows();

      }

      
    },
    applyFilters(){


        this.getRows();

    },
    setup() {

     
      
      var params = this.customServerParams;

      
      if(params && (params.inGridField || (params.params && params.params.inGridField))){
      }else{
        this.$bvModal.show('modal-pleaseWait');
      }

      if (this.$route.params) {
        params = { ...params, ...this.$route.params };
      }

      if (this.$route.query) {
        params = { ...params, ...this.$route.query };
      }

      this.passedParams = params;

      if (this.rowID) {
        params['rowID'] = this.rowID;
      }

      if (this.selectedUsers) {
       
          for (let i = 0; i < this.selectedUsers.length; ++i) {
            if(typeof this.selectedUsers[i].ID ==='undefined'){
              this.localSelectedRows.push({ID:this.selectedUsers[i]});
            }else{
              this.localSelectedRows.push(this.selectedUsers[i]);
            }
          }
      }

      this.service
        .getTableSettings(params,this.inGridField)
        .then(response => {
          if (response.error) {
            this.tableError = response;
            this.$bvModal.hide('modal-pleaseWait');
            this.$bvModal.show('modal-error');
            return;
          }
          this.settings = response.settings;
          this.editAuditUri=response.settings.editAuditUri;
          this.permissions = response.permissions;
          this.filterOptions = response.filterOptions;
          this.sortOptions = response.sortOptions;
          this.paginationOptions = response.paginationOptions;
          this.hasSortGroups = response.hasSortGroups;
          this.listType = response.listType;
          this.customDialogSettings = response.customActionSettings;
          this.origCustomDialogSettings = response.customActionSettings;
          this.afterAddDialogSettings = response.afterAddDialogSettings;
          this.afterEditDialogSettings = response.afterEditDialogSettings;
          this.deleteMessage = response.deleteMessage


            ? response.deleteMessage
            : 'Are you sure you want to delete this row?';


            if(Object.keys(this.gridControls).length==0 && response.gridOptions){

                this.localGridControls=response.gridOptions;

            }

            if(response.gridControlSubmitUrl){
              this.gridControlUri=response.gridControlSubmitUrl;
            }

          

          if (response.saveButtonLabel) {
            this.saveText = response.saveButtonLabel;
          }

          if (response.globalSearchPlaceHolder) {
            this.globalSearchPlaceHolder = response.globalSearchPlaceHolder;
          }
          if (response.globalSearchDefault) {
            this.globalSearchDefault = response.globalSearchDefault;
            this.globalSearchTime = new Date().getTime();
          }

          if (this.settings.editButtonLabel) {
            this.editButtonLabel = this.settings.editButtonLabel;
          }

          this.defaultAddTitle = response.defaultAddTitle;
          this.defaultEditTitle = response.defaultEditTitle;
          this.$bvModal.hide('modal-pleaseWait');

          var thisComp = this;

          Vue.nextTick(function() {
              thisComp.getRows();
          
          });
          if (Array.isArray(this.startupCmds)) {
            for (const cmd of this.startupCmds) {
              this.runStartupCmd(cmd);
            }
          }
          if (response.tableHeader) {
            this.$emit('onSetTableHeader', response.tableHeader);
          }
          if (response.tableReferer) {
            this.$emit('onSetTableReferer', response.tableReferer);
          }
          if (response.tableInstruction) {
            this.tableInstruction = response.tableInstruction;
          }
          if (response.checkNotificationsOnGetRows) {
            this.checkNotificationsOnGetRows =
              response.checkNotificationsOnGetRows;
          }

          if (response.tableActions) {
            this.customTableActions = response.tableActions;
            this.refreshActions++;

          }
          if (response.printSettings) {
            this.printSettings = response.printSettings;
          }
          if (response.shareSettings) {
            this.shareSettings = response.shareSettings;
            if(this.shareSettings.shareAuditUri){
              this.$emit('auditForShare',{shareAuditUri:this.shareSettings.shareAuditUri})
            }
          }

          if (response.autoRefresh) {
            this.refreshTableAfter = response.autoRefresh;
          }

          if (response.afterSelectUri) {
            this.afterSelectUri = response.afterSelectUri;
          }

          if(response.reloadTableOnClear){
            this.reloadTableOnFilterClear=response.reloadTableOnClear;
          }
          if (response.openAddDialogOnStart===true) {
            this.runStartupCmd({type: 'OPEN_ADD_DIALOG'});
          }
          if (response.startupCmdParams) {
            this.runStartupCmd(response.startupCmdParams);
          }

          if(response.addParams){
            this.addParams=response.addParams;
          }

          if(response.disableFilterAutoApply){
            this.disableAutoApplyFilter=response.disableFilterAutoApply;
          }

          this.$bvModal.hide('modal-error');
        })
        .catch(error => {
          this.tableError = { message: error };
          this.$bvModal.hide('modal-pleaseWait');
          this.$bvModal.show('modal-error');
        });
    },

    getAllServerParams() {
      var params = this.serverParams;

      params.customServerParams = this.customServerParams;
      params.pageSize = this.$refs.pagination.perPage;
      params.currentPage = this.$refs.pagination.currentPage;

      if (this.sortOptions) {
        params.sort = [
          {
            type: this.$refs.filterRow.sortType(),
            field: this.$refs.filterRow.sortField(),
          },
        ];
      }

      if (this.$refs.filterRow) {
        params.filters = this.$refs.filterRow.getSelectedFilters();
      }
      return params;
    },
    
    getRows() {
      var params = this.getAllServerParams();


      if(this.localSelectedRows){
          params= {...params,selectedItems:this.localSelectedRows}
      }

      if (this.passedFilters) {
        params = { ...params, ...this.passedFilters };
      }

      if (this.$route.params) {
        params = { ...params, ...this.$route.params };
      }

      if (this.passedParams) {
        params = { ...params, ...this.passedParams };
      }

      if (this.$route.query) {
        params = { ...params, ...this.$route.query };
      }

      if (this.rowID) {
        params['rowID'] = this.rowID;
      }

      if(this.gridControls.length>0){
        params['hasGridControls']=true;
        params['gridControls']=this.gridControls;
      }else if(this.localGridControls.length>0){
        params['hasGridControls']=true;
        params['gridControls']=this.localGridControls;

      }



      this.tableIsLoading = true;

      this.service.get(params, this.silent || this.inGridField).then(response => {
        this.rows = response.rows;

        if (this.localSelectedRows && this.localSelectedRows.length > 0) {
          let ht = {};
          for (let i = 0; i < this.localSelectedRows.length; ++i) {
            ht[this.localSelectedRows[i].ID] = 1;
          }



          for (let i = 0; i < this.rows.length; ++i) {
            this.rows[i]['rowSelected'] = ht[this.rows[i].ID] ? true : false;
          }
        }

        this.totalRows = response.totalRecords;

        if(!this.initialRowCount){
          this.initialRowCount=response.totalRecords;
        }

        if (
          this.localSelectedRows &&
          this.localSelectedRows.length == this.initialRowCount
        ) {
          this.setSelectAll = true;
        }

        this.$emit('tableLoaded', response);
        this.$store.commit('UPDATE_CAN_EMIT_EVENT', true);
        this.$store.commit('UPDATE_CLEARING_FILTERS', false);

        if ((this.refreshAfter || this.refreshTableAfter) && !this.timer) {
          var vm = this;

          this.timer = setInterval(function() {
            vm.getRows();
          }, (this.refreshAfter || this.refreshTableAfter) * 1000);
        }

        this.tableIsLoading = false;
      });
      if (this.checkNotificationsOnGetRows) {
        this.checkNotifications();
      }
    },
    deselectRows(except) {
      const rows_2 = deepCopy(this.rows);
      if (Array.isArray(rows_2)) {
        for (const row of rows_2) {
          if (row.ID === except) continue;
          row['rowSelected'] = false;
        }
      }
      this.rows = rows_2;
    },
    unsetSelectAll(except){
      this.setSelectAll=false;

    },
    onClickOkTableError() {
      if (
        this.tableError &&
        this.tableError.data &&
        this.tableError.data.okAction &&
        this.tableError.data.okAction.type == 'LINK' &&
        this.tableError.data.okAction.payload
      ) {
        this.$router.push(this.tableError.data.okAction.payload);
      }
    },

    // ===== CUSTOM ACTION DIALOG FUNCTIONS ======
    openCustomDialog(data) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.passedFilters = this.$refs.filterRow.getSelectedFilters();

      if (this.$route.params.isorg) {
        var orgObject = { isOrg: true };

        this.passedFilters = { ...this.passedFilters, ...orgObject };
      }

      this.refreshCustomDialog = new Date().getTime();

      if (data && !data.customAction) {
        this.customDialogSettings = data.customDialogSettings;
      
      } else if (data && data.customAction) {
        this.customDialogSettings = this.origCustomDialogSettings;
      }

      if(data && data.componentParams){
        this.customDialogParams=data.componentParams;

      }

      if (data.componentParams && data.componentParams.detailViewOnly) {
        this.service
          .getRow(data.componentParams.RowID, this.passedFilters)
          .then(response => {
            this.detailRowData = response;

            this.renderCustomDialog = true;




    


          })
          .catch(error => console.log(error));
      }else{
        this.renderCustomDialog = true;

      }
      
    },

    closeCustomDialog(data) {
      if (this.timer) {
        clearInterval(this.timer);
      }

      this.renderCustomDialog = false;


      

      if(data && data.afterSubmitUri){
          this.afterCustomDialogSettings.getDataUri=data.afterSubmitUri;

          if(data.afterSubmitDialogParams){

             
            this.afterCustomDialogSettings={...this.afterCustomDialogSettings,...data.afterSubmitDialogParams};

          

          }
          this.openAfterCustomDialog();
      }else if(data && data.nextUri){
      }else if(data && data.refreshTable){
          this.getRows();
      }
    },

    openAfterCustomDialog(params) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.passedFilters = this.$refs.filterRow.getSelectedFilters();

      if (this.$route.params.isorg) {
        var orgObject = { isOrg: true };

        this.passedFilters = { ...this.passedFilters, ...orgObject };
      }


      if (params) {
        this.afterCustomDialogSettings = {
          ...this.afterCustomDialogSettings,
          params,
        };
      }

      

this.refreshAfterCustomDialog += 1;
      //this.afterAddDialogSettings = data.afterAddDialogSettings;
      this.renderAfterCustomDialog = true;
    },

    closeAfterCustomDialog(data) {

      
      if (this.timer) {
        clearInterval(this.timer);
      }



      this.renderAfterCustomDialog = false;


      
      if(data.afterSubmitUri){
        if(data.afterSubmitNoDialog){

          this.$http.post(API_URL +'/'+data.afterSubmitUri,{})
             .then(response => {
                  
                  if(response.data.data.nextUri){
                       this.afterCustomDialogSettings.getDataUri=response.data.data.nextUri;
                      this.afterCustomDialogSettings.refreshTable=true;
                       this.openAfterCustomDialog();

                    
                  }

             });

        }
      }

      if (data && data.refreshTable) {
        this.getRows();
      }
    },

    openAfterAddDialog(params) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.passedFilters = this.$refs.filterRow.getSelectedFilters();

      if (this.$route.params.isorg) {
        var orgObject = { isOrg: true };

        this.passedFilters = { ...this.passedFilters, ...orgObject };
      }

      if (params) {
        this.afterAddDialogSettings = {
          ...this.afterAddDialogSettings,
          params,
        };
      }

      this.refreshAfterAddDialog += 1;
      //this.afterAddDialogSettings = data.afterAddDialogSettings;
      this.renderAfterAddDialog = true;
    },

    closeAfterAddDialog(data) {
      if (this.timer) {
        clearInterval(this.timer);
      }


      this.renderAfterAddDialog = false;


      if (data.afterSubmitUri) {
        if (data.afterSubmitNoDialog) {
          this.$http
            .post(API_URL + '/' + data.afterSubmitUri, {})
            .then(response => {
              if (response.data.data.nextUri) {
                if (response.data.data.nextUriDialogMessage) {
                  this.$bvModal
                    .msgBoxOk(response.data.data.nextUriDialogMessage)
                    .then(value => {
                      this.afterCustomDialogSettings.getDataUri =
                        response.data.data.nextUri;
                      this.openAfterCustomDialog();
                    });
                } else {
                  this.afterCustomDialogSettings.getDataUri =
                    response.data.data.nextUri;
                  this.openAfterCustomDialog();
                }
              } else if (response.data.data.surveyViewer) {
                this.$router.replace({
                  name: 'client-collection',
                  params: {
                    id: 'SurveyViewer',
                    assessmentType: response.data.data.assessmentType,
                    assessmentID: response.data.data.assessmentID,
                    clientID: response.data.data.modelID,
                    afterSubmitUri: response.data.data.afterSubmitUri,
                    inAfterSubmit: true,
                  },
                });
              } else if (response.data.data.nextPath) {
                this.$router.push({ path: response.data.data.nextPath });
              } else {
                this.$router.push({
                  name: 'ModalView',
                  params: {
                    componentName: response.data.data.componentName,
                    cservice: response.data.data.cservice,
                    service: response.data.data.service,
                    rowID: response.data.data.rowID,
                    ...response.data.data.params,
                  },
                });
              }
            });
        } else {
          this.afterCustomDialogSettings.getDataUri = data.afterSubmitUri;
          if(data.afterSubmitDialogParams){
            this.afterCustomDialogSettings={...this.afterCustomDialogSettings,...data.afterSubmitDialogParams};

          }
          
          this.openAfterCustomDialog();
        }
      } else if (data && data.refreshTable) {
        this.getRows();
      }
    },

    openAfterEditDialog(params) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.passedFilters = this.$refs.filterRow.getSelectedFilters();

      if (this.$route.params.isorg) {
        var orgObject = { isOrg: true };

        this.passedFilters = { ...this.passedFilters, ...orgObject };
      }

      if (params) {
        this.passedFilters = { ...this.passedFilters, ...params };
      }

      if (params) {
        this.afterEditDialogSettings = {
          ...this.afterEditDialogSettings,
          params,
        };
      }
      this.refreshAfterEditDialog += 1;

      this.renderAfterEditDialog = true;
    },

    closeAfterEditDialog(data) {
      if (this.timer) {
        clearInterval(this.timer);
      }

      this.renderAfterEditDialog = false;

      if (data && data.refreshTable) {
        this.getRows();
      }
    },

    openCustomConfirmation(data) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.passedFilters = this.$refs.filterRow.getSelectedFilters();

      this.customConfirmationSettings = data.customDialogSettings;
      this.refreshCustomDialog += 1;

      this.renderCustomConfirmation = true;
    },
    closeCustomConfirmation(data) {
      if (this.timer) {
        clearInterval(this.timer);
      }

      this.renderCustomConfirmation = false;

      if (data && data.success && data.afterConfirmationUri) {
        this.$http.post(data.afterConfirmationUri).then(response => {
          if (response.data.data.customDialogSettings) {
            this.customDialogSettings = response.data.data.customDialogSettings;

            this.refreshCustomDialog = new Date().getTime();

            this.renderCustomDialog = true;
          }
        });
      }

      if (data && data.path) {
        if (data.pathParams) {
          this.$router.push({ path: data.path, query: data.pathParams });
        } else {
          this.$router.push({ path: data.path });
        }
      }

      if (data && data.showMessage) {
        this.$bvModal.msgBoxOk(data.message, {
          title: 'Success',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          hideHeaderClose: true,
          centered: true,
        });
      }

      if (data && data.refreshTable) {
        this.getRows();
      }
    },
    autoShareGrid(){
       this.passedFilters = this.$refs.filterRow.getSelectedFilters();

      if (this.$route.params.isorg) {
        var orgObject = { isOrg: true };

        this.passedFilters = { ...this.passedFilters, ...orgObject };
      }

      // set filters variable
      this.shareFilterSettings = this.$refs.filterRow.getSelectedFilters();

       this.$http
            .post(API_URL + '/' + this.shareSettings.shareSetUri, {
              autoShare:true,
              filters: this.shareFilterSettings,
              requestType: 'Save',
            })
            .then(response => {
              if (response.data.data.success) {
                 
                 if(response.data.data.message && response.data.data.message.length>0){
                    this.$bvModal.msgBoxOk(response.data.data.message)
                    .then(value=>{
                        this.shareSettings.autoShare=false;
                        this.shareSettings.shareButtonLabel=this.shareSettings.afterAutoShareText;
                    
                        this.updateActions=new Date().getTime();

                    }); 
                 }
              }
         
            });

    },
    openShareGridDialog(data) {
      if (this.timer) {
        clearInterval(this.timer);
      }

      this.passedFilters = this.$refs.filterRow.getSelectedFilters();

      if (this.$route.params.isorg) {
        var orgObject = { isOrg: true };

        this.passedFilters = { ...this.passedFilters, ...orgObject };
      }

      // set filters variable
      this.shareFilterSettings = this.$refs.filterRow.getSelectedFilters();

      this.renderShareGridDialog = true;
    },
    closeShareGridDialog(data) {
      if (this.timer) {
        clearInterval(this.timer);
      }

      this.renderShareGridDialog = false;
    },

    successAfterCustomDialog(data) {
      const cmd = data && data.cmd;
      if (cmd === 'openAddDialog') {
        let ref = this.$refs['customDialogRef'];
        if (ref && ref.form && ref.form.answers) {
          this.tmpSavedAnswers = deepCopy(ref.form.answers);
        }

        this.closeAfterCustomDialog();

        if (data.component) {
          this.component = data.component;

          if (data.componentParams) {
            this.componentParams = data.componentParams;
            this.passedFilters = {
              ...this.passedFilters,
              ...data.componentParams,
            };
          }
        }

        if (data.hideSave) {
          this.hideSave = true;
        }

        if (data.hideCancel) {
          this.hideCancel = true;
        }

        if (data.saveText) {
          this.saveText = data.saveText;
        }

        if (data.cancelText) {
          this.cancelText = data.cancelText;
        }
        if (data.params) {
          this.passedFilters = { ...this.passedFilters, ...data.params };
        }

        this.openAddRowDialog();
      } else if (cmd === 'reloadForm') {
        this.$refs['customDialogRef'].getData(data.customDialogSettings);
      } else if (data.success) {
        this.closeAfterCustomDialog(data);
      }
    },
    successCustomDialog(data) {
      const cmd = data && data.cmd;
      if (cmd === 'openAddDialog') {
        let ref = this.$refs['customDialogRef'];
        if (ref && ref.form && ref.form.answers) {
          this.tmpSavedAnswers = deepCopy(ref.form.answers);
        }

        this.closeCustomDialog();

        if (data.component) {
          this.component = data.component;

          if (data.componentParams) {
            this.componentParams = data.componentParams;
            this.passedFilters = {
              ...this.passedFilters,
              ...data.componentParams,
            };
          }
        }

        if (data.hideSave) {
          this.hideSave = true;
        }

        if (data.hideCancel) {
          this.hideCancel = true;
        }

        if (data.saveText) {
          this.saveText = data.saveText;
        }

        if (data.cancelText) {
          this.cancelText = data.cancelText;
        }
        if (data.params) {
          this.passedFilters = { ...this.passedFilters, ...data.params };
        }

        this.openAddRowDialog();
      } else if (cmd === 'reloadForm') {
        this.$refs['customDialogRef'].getData(data.customDialogSettings);
      } else if (cmd === 'openCFormDialog') {
        this.openCFormDialog(data.payload);
      } else if (data.success) {
        this.closeCustomDialog(data);
      }
    },

    successAfterAddDialog(data) {
      const cmd = data && data.cmd;
      if (cmd === 'reloadForm') {
        this.$refs['afterAddDialogRef'].getData(data.afterAddDialogSettings);
      }
    },

    successAfterEditDialog(data) {
      const cmd = data && data.cmd;
      if (cmd === 'reloadForm') {
        this.$refs['afterEditDialogRef'].getData(data.afterEditDialogSettings);
      }
    },

    openRowPositionDialog(row) {
      this.selectedRow = row;
      this.renderRowPositionDialog = true;
    },
    closeRowPositionDialog() {
      this.selectedRow = null;
      this.renderRowPositionDialog = false;
    },
    submitRowPosition() {
      if (this.selectedRow && this.service.editSortOrder) {
        this.service
          .editSortOrder(this.selectedRow)
          .then(response => {
            if (response.error) {
              console.log(response.message);
            } else {
              this.closeRowPositionDialog();
              this.getRows();
            }
          })
          .catch(error => console.log(error));
      }
    },
    submitActionButton(row) {
      this.$http
        .post(row.actionButtonSubmitUrl, row)
        .then(response => {
         
          if (response.data && response.data.data && response.data.data.error) {
            console.log(response.data.data.message);
          } else {
            if (row.actionButtonLink) {
              this.$router.push(row.actionButtonLink);
            } else {
              this.getRows();

              if (row.dialogToOpen) {
                if (row.dialogToOpen == 'edit') {
                  this.openEditRowDialog(row);
                }
              } else if(response.data.data.dialogToOpen){
                if(response.data.data.dialogToOpen=='edit'){


                    if(response.data.data.targetRowID){
                      let tempRow={'ID':response.data.data.targetRowID};
                      this.openEditRowDialog(tempRow);

                    }else{
                      this.openEditRowDialog(row);
                    }
                    

                }

              } else if (response.data.data.closeDialog) {
                this.$emit('closeListDialog');
              } else if (response.data.data.eventToEmit) {
                this.$emit('customActionEvent', {
                  eventName: response.data.data.eventToEmit,
                  params: response.data.data.params,
                  inCustomDialog: response.data.data.inCustomDialog,
                });
              } else if (response.data.data.afterActionUri) {
                this.afterCustomDialogSettings.getDataUri =
                  response.data.data.afterActionUri;
                this.openAfterCustomDialog();
              }
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    submitAction(row) {
      this.$http
        .post(row.actionSubmitUrl, row)
        .then(response => {
          if (response.data && response.data.data && response.data.data.error) {
            this.tableError = response.data.data.message;
            this.$bvModal.show('modal-error');
          } else {
            if (row.actionLink) {
              this.$router.push(row.actionLink);
            } else {
              if (response.data.data.refreshAllTable) {
                this.setup();
              } else {
                this.getRows();
              }
            }

             if(response.data.data.dialogToOpen){
                if(response.data.data.dialogToOpen=='edit'){


                    if(response.data.data.targetRowID){
                      let tempRow={'ID':response.data.data.targetRowID};
                      this.openEditRowDialog(tempRow);

                    }else{
                      this.openEditRowDialog(row);
                    }
                    

                }

              }
          }
        })
        .catch(error => {
          this.tableError = error;
          this.$bvModal.show('modal-error');
          console.log(error);
        });
    },

    openItemDetailsDialog(data) {
      this.itemDetailsData = data;
      this.showItemDetails = true;
    },
    openCFormDialog(data) {
      this.cFormData = data;
      this.cFormKey = "cform__" + (new Date().getTime());
      this.showCForm = true;
    },
    openSurveyJsFormDialog: function(data) {
      this.surveyJsFormData = data;
      this.showSurveyJsForm = true;
    },
    closeItemDetails: function() {
      this.showItemDetails = false;
      this.itemDetailsData = null;
      this.getRows();
    },
    closeCFormDialog: function() {
      this.cFormData = null;
      this.showCForm = false;
    },
    closeSurveyJsFormDialog: function() {
      this.surveyJsFormData = null;
      this.showSurveyJsForm = false;
    },
    onClickCancelItemDetails: function() {
      this.closeItemDetails();
    },
    onUploadResources: async function({ data, form }) {
      try {
        if (!data.uploadResourceURL) return;
        const presignedDataList = Array.isArray(data.uploadResourceURL) ? data.uploadResourceURL : [{ uploadResourceURL: data.uploadResourceURL }];
        await this.uploadResourceHandler(presignedDataList, form);
      } catch (error) {
        this.formError = getErrorMessage(error);
      }
    },
    onClickCancelCForm() {
      this.closeCFormDialog();
    },
    onClickCancelSurveyJsForm() {
      this.closeSurveyJsFormDialog();
    },
    onCFormError(data) {
      this.formError = data;
      if (data) this.invalidFormAction();
    },
    onItemDetailsError: function(data) {
      this.formError = data;
      if (data) this.invalidFormAction();
    },
    onClickCFormMenuBtn: function(data) {
      if (!data) return;
      const { action } = data;
      if (action) {
        if (action.type === 'SUBMIT_VIA_SERVICE') {
          this.submitCFormViaService(data);
        } else if (action.type === 'SUBMIT_VIA_URL') {
          this.submitCFormViaUrl(data);
        } else if (action.type === 'EXPORT_TO_PDF') {
          const cToSurveyOpt = action.payload && action.payload.cFieldToSurveyOpt ? action.payload.cFieldToSurveyOpt : {};
          const surveyPdfOpt = action.payload && action.payload.surveyPdfOpt ? action.payload.surveyPdfOpt : {};
          cFieldToPDF(data.form, data.answers, cToSurveyOpt, surveyPdfOpt);
        }
      }
    },
    submitCFormViaService: function(data) {

      if (!data || !data.answers || !data.answers['__form'] || !data.answers['__form']['type']) return;
      const formType = data.answers['__form']['type'];
      if (formType === 'Add') {
        this.addRowData = data.answers;
        this.addRow();
      } else if (formType === 'Edit') {
        this.editRowData = data.answers;
        this.editRow();
      }
    },
    submitCFormViaUrl: async function (data) {
      try {
        if (!data || !data.answers) return;
        const submitUrl = data.action.submitDataUri;
        const response = await apiPost(submitUrl, copyAndMerge(data.answers, getPropsFromFiles(data.answers)));
        if (response.error) throw Error(response.message);
        if (response.pauseToConfirm) {
          const onOk = await this.showMsgBoxConfirmDialog(response);
          if (onOk) {
            data.answers[response.confirmValue] = true;
            this.submitCFormViaUrl(data);
            data.answers[response.confirmValue] = false;
          }
        } else {
          let triggerClose = true;
          let triggerGetRows = true;
          if (Array.isArray(response.selectedRows)) {
            triggerClose = true;
            triggerGetRows = true;
            for (const selection of response.selectedRows) {
              if (isObject(selection) && selection.ID && selection.displayName) {
                if (!this.selectedItems) this.selectedItems = [];
                if (this.selectedItems.indexOf(selection.ID) === -1) this.selectedItems = [...this.selectedItems, selection.ID];
                this.$emit('onRowSelected', {ID: selection.ID, displayName: selection.displayName, rowSelected: true});
              }
            }
          }
          if (response.openCFormDialog && response.openCFormDialog.cFormDialogProps) {
            triggerClose = false;
            triggerGetRows = true;
            this.openCFormDialog(response.openCFormDialog.cFormDialogProps);
          }
          if (response.refreshTableSettings === true) {
            triggerClose = true;
            triggerGetRows = false;
            this.setup();
          }
          if (response.redirectURL) {
            triggerClose = true;
            triggerGetRows = false;
            this.$router.push(response.redirectURL);
          }
          if (response.uploadResourceURL) {
            triggerClose = false;
            triggerGetRows = false;
            const presignedDataList = Array.isArray(response.uploadResourceURL) ? response.uploadResourceURL : [{ uploadResourceURL: response.uploadResourceURL }];
            this.uploadResourceHandler(presignedDataList, data.answers).then(data => {
              this.closeCFormDialog();
              this.getRows();
            });
          }
          if (triggerClose) this.closeCFormDialog();
          if (triggerGetRows) this.getRows();
        }
      } catch (error) {
        this.formError = JSON.stringify(error);
        this.invalidFormAction();
      }
    },

    async showMsgBoxConfirmDialog(data) {
      return this.$bvModal.msgBoxConfirm(data.message, {
        hideHeaderClose: true,
        centered: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        okVariant: 'success',
        okTitle: 'Yes',
        cancelVariant: 'danger',
        cancelTitle: 'No',
      });
    },

    // ===== ADD/CREATE FUNCTIONS ======

    /**
     * Opens add/create modal
     */
    openAddRowDialog() {
      this.addRowData = {};

      if (!this.passedFilters) {
        this.passedFilters = this.$refs.filterRow.getSelectedFilters();
      }

      if (this.$route.params) {
        this.passedFilters = { ...this.passedFilters, ...this.$route.params };
      }

      if (this.customServerParams) {
        this.passedFilters = {
          ...this.passedFilters,
          ...this.customServerParams,
        };
      }

      if(this.gridUpdateData){
        this.passedFilters={
            ...this.passedFilters,
            ...this.gridUpdateData
        };
      }


      if (this.defaultRow) {
        this.addRowData = JSON.parse(JSON.stringify(this.defaultRow));
      }


      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }


      if (this.customFieldSetName) {
        this.fetchCustomFields('Add', this.customFieldSetName);

         this.$bvModal.show(`add-dialog-${this.componentID}`);
      } else if (this.service.getAddRowData) {
        this.fetchAddRowData();
      } else if (this.service.getFormConfig) {
        this.fetchFormConfig('Add');
        
      } else {
              this.$bvModal.show(this.addDialogIDProp);
      }

      this.$bvModal.show(`add-dialog-${this.componentID}`);
      this.$bvModal.hide('loading-modal');

    },

    /**
     * Hides add/create modal
     */
    closeAddRowDialog(addSuccessful, afterAddParams, cancelAfterAdd, response,afterAddUri=null) {
      this.addRowData = {};
      if (this.defaultRow) {
        this.addRowData = JSON.parse(JSON.stringify(this.defaultRow));
      }
      if (this.showCForm == true) {
        this.closeCFormDialog();
      }
      if (this.showSurveyJsForm == true) {
        this.closeSurveyJsFormDialog();
      }
      this.$bvModal.hide(`add-dialog-${this.componentID}`);
      this.formError = '';
      if (this.validationSettings) {
        this.$v.$reset();
      }


      if(afterAddUri){
       
        if(typeof this.afterAddDialogSettings ==='undefined'){
          this.afterAddDialogSettings={};
        }

        this.afterAddDialogSettings['getDataUri']=afterAddUri;
      }


      if (addSuccessful && this.afterAddDialogSettings && !cancelAfterAdd) {
        this.openAfterAddDialog(afterAddParams);
      }else if(addSuccessful && afterAddParams && response && response.triggerAfterAddDialog){
        this.openAfterAddDialog(afterAddParams);
      }else if(addSuccessful && afterAddParams && afterAddUri){
        this.openAfterAddDialog(afterAddParams);

        
      } else if (addSuccessful && response && response.displaySuccess) {
        this.$bvModal.msgBoxOk(response.message, {
          title: 'Success',
          buttonSize: 'sm',
          centered: true,
          size: 'sm',
          okVariant: 'success',
        });
      }

      if (this.component) {
        this.getRows();
        this.component = null;
        this.componentParams = {};
      }
      if (afterAddParams && afterAddParams.cFormDialogProps) {
        this.openCFormDialog(afterAddParams.cFormDialogProps)
      }
      if (afterAddParams && afterAddParams.openSurveyJsFormDialog) {
        this.openSurveyJsFormDialog(afterAddParams.surveyJsFormDialogData);
      }
      if (afterAddParams && afterAddParams.refreshTableSettings) {
        this.setup();
      }
    },

    /**
     * Sends form values to this.service.create endpoint
     */
    addRow() {
      var payload;
      if (this.addRowData.File) {
        payload = new FormData();
        payload.append('file', this.addRowData.File);
        payload.append('Name', this.addRowData.Name);

        $.each(this.addRowData, function(propertyName, propertyValue) {
          if (propertyName != 'File' && propertyName != 'Name') {
            payload.append(propertyName, propertyValue);
          }
        });
      } else {
        payload = this.addRowData;
        payload.customServerParams = this.customServerParams;

        if(this.addParams){
          payload={...payload,...this.addParams};
        }
      }
      this.addErrorFlag = false;

      if (Object.keys(this.validationSettings).length > 0) {
        this.$v.$touch();


        if (this.$v.addRowData.$invalid) {
          this.formError =
            'Your submission contains errors please review and try again';
          this.addUpdate = new Date().getTime();

          var container = $('#formErrorDiv').closest('.modal-body');
          container.scrollTop(0);
          return;
        }
      }
      if (Object.keys(this.validationsFromFields).length > 0) {
        var validationsFromFieldsKeys = Object.keys(this.validationsFromFields);

        var vm = this;
        $.each(validationsFromFieldsKeys, function(key, value) {
          if ($('[id="' + value + '"]').length > 0) {
            if (!$('[id="' + value + '"]').is(':visible')) {
              delete vm.validationsFromFields[value];
            }
          } else if ($("[aria-labelledby='" + value + "']").length > 0) {
            if (!$("[aria-labelledby='" + value + "']").is(':visible')) {
              delete vm.validationsFromFields[value];
            }
          }
        });

        this.$v.$touch();
        if (this.$v.addRowData.$invalid) {
          this.formError =
            'Your submission contains errors please review and try again';
          this.addUpdate = new Date().getTime();

          var container = $('#formErrorDiv').closest('.modal-body');
          container.scrollTop(0);
          return;
        }
      }

      if (typeof this.verifyMethod !== 'undefined') {
        var errors = this.verifyMethod(this.editRowData);

        if (errors.length > 0) {
          this.formError = 'The form contains the following errors:<br><ul>';
          var vm = this;
          $.each(errors, function(key, value) {
            vm.formError = vm.formError.concat('<li>', value, '</li>');
          });
          this.formError = this.formError.concat('</ul>');
          this.addUpdate = new Date().getTime();
          return;
        }
      }

      if (
        this.customFieldSetName &&
        !this.isValidForm(this.addRowData, 'Add')
      ) {
        this.invalidFormAction();
        return;
      }

      if (this.dynamicRequired) {
        if (!this.dynamicIsValid(this.addRowData)) {
          this.invalidFormAction();
          return;
        }
      }

      this.service
        .create(payload)
        .then(response => {


          if (response.error) {
            // this.addUpdate = new Date().getTime();

            this.formError = response.message;
            this.addErrorFlag = true;
            this.invalidFormAction();
          } else if (response.pauseToConfirm) {
            this.$bvModal
              .msgBoxConfirm(response.message, {
                hideHeaderClose: true,
                centered: true,
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                okVariant: 'success',
                okTitle: 'Yes',
                cancelVariant: 'danger',
                cancelTitle: 'No',
              })
              .then(value => {
                if (value) {
                  this.addRowData[response.confirmValue] = true;

                  this.addRow();
                }
              });
          } else {
            this.formError = '';

            if (Array.isArray(response.selectedRows)) {
              for (const selection of response.selectedRows) {
                if (
                  isObject(selection) &&
                  selection.ID &&
                  selection.displayName
                ) {
                  this.$emit('onRowSelected', {
                    ID: selection.ID,
                    displayName: selection.displayName,
                    rowSelected: true,
                  });
                }
              }
            }

            /*if(response.addToSelected){

              
               this.localSelectedRows.push({ID: response.addToSelected});

               this.$emit('onRowSelected', {ID: response.addToSelected, displayName: response.displayName, rowSelected: true});

                  

            }*/

            if (response.redirectURL) {
              this.closeAddRowDialog(true, response.afterAddParams);
              this.$router.push(response.redirectURL);
            } else if (response.uploadResourceURL) {
              const presignedDataList = Array.isArray(response.uploadResourceURL) ? response.uploadResourceURL : [{ uploadResourceURL: response.uploadResourceURL }];
              this.uploadResourceHandler(presignedDataList, payload).then(data => {
                this.closeAddRowDialog(true, response.afterAddParams);
                if (this.$refs && this.$refs.filterRow !== undefined && this.$refs.filterRow.clearFiltersAndSort) {
                  this.$refs.filterRow.clearFiltersAndSort()
                }
                if (this.$refs && this.$refs.pagination !== undefined) {
                  this.getRows();
                } 
              }).catch(error => {
                this.closeAddRowDialog(true, response.afterAddParams);
                this.showTableError(true, error);
              });
            } else if (response.eventToEmit) {

              if(response.eventToEmit=='pickerValueMultiSelect'){
                  this.localSelectedRows.push(response.eventParams);
                  

              }

              var eventData = {
                eventName: response.eventToEmit,
                params: response.eventParams,
                inCustomDialog: response.inCustomDialog,
                closeCustomDialog: response.closeCustomDialog,
              };
              this.$emit('customActionEvent', eventData);
              this.closeAddRowDialog(true, response.afterAddParams);

            } else if (response.afterCreateConfirmationSettings) {
              this.closeAddRowDialog(true, response.afterAddParams);
              this.customConfirmationSettings =
                response.afterCreateConfirmationSettings;

              this.refreshCustomConfirmation = new Date().getTime();

              this.renderCustomConfirmation = true;
            } else if (
              response.afterAddParams &&
              response.afterAddParams.keepCloseOpen
            ) {
              $('.btn-success').hide();

              if (response.afterAddParams.refreshFields) {
                this.addRowData = response.afterAddParams.updatedModel;

                this.addUpdate = new Date().getTime();
              }
            } else {
              if (response.showAfterMessage) {
                this.$bvModal
                  .msgBoxOk(response.message, {
                    title: 'Success',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    hideHeaderClose: true,
                    centered: true,
                  })
                  .then(value => {})
                  .catch(err => {
                    // An error occurred
                  });
              }

              if (response.cancelAfterAdd) {
                this.closeAddRowDialog(true, {}, true, response);
              } else {

                this.closeAddRowDialog(
                  true,
                  response.afterAddParams,
                  false,
                  response,
                  response.afterAddUri
                );
              }

              if (response.expandTool) {
                this.passedParams = {
                  ...this.passedParams,
                  expandTool: response.expandTool,
                };

                this.rows = [];
                this.getRows();
              } else if (response.afterAddParams && response.afterAddParams.refreshTableSettings) {
                this.setup();
              } else {
                this.getRows();
              }
            }
          }
        })
        .catch(error => {
          this.formError = ErrorMessages.NETWORK_SUBMIT_ERROR;
          this.invalidFormAction();
        });
    },

    showProgressBar(isVisible) {
      if (isVisible) {
        this.progressBarCompleted = 0;
        this.showProgressBarModal=true;
      } else {
        this.showProgressBarModal=false;
      }
    },
    showTableError(isVisible, error) {
      if (isVisible) {
        this.tableError = { message: error };
        this.$bvModal.show('modal-error');
      } else {
        this.$bvModal.show('modal-error');
      }
    },
    async uploadResourceHandler(dataList, form) {
      if (!Array.isArray(dataList)) return;
      const responses = [];
      for (let i = 0; i < dataList.length; i++) {
        const data = dataList[i];
        this.showProgressBar(true);
        this.progressBarModalHeader = `Uploading ${i + 1} / ${dataList.length} `;
        const that = this;
        const config = { onUploadProgress: function(progressEvent) {
          const loaded = progressEvent.loaded;
          const total = progressEvent.total;
          if (isNumber(loaded) && isNumber(total) && total > 0) {
            const completed = Math.round((loaded * 100) / total);
            that['progressBarCompleted'] = completed;
          }
        }};
        const response = await s3uploadResource(data, form, config);
        if (response.error !== undefined) { this.showProgressBar(false); }
        if (response.error) throw Error(response.message);
        responses.push(response);
      }
      return responses;
    },
    async getResourceLink(data) {
      try {
        if (isObject(data) && data.pathToFetchSignedURL) {
          const response = await this.$http
            .get(data.pathToFetchSignedURL)
            .then(getData);
          if (response.error) {
            throw new Error(response.message);
          }
          if (response.resourceURL) {
            window.open(response.resourceURL, '_blank');
          }
        }
      } catch (error) {
        this.tableError = { message: error };
        this.$bvModal.show('modal-error');
      }
    },

    /**
     * Populates add row data based on uri
     */
    async fetchAddRowData() {
      try {
        const response = await this.service.getAddRowData();
        if (response) {
          this.addRowData = response;
        } else {
          this.addRowData = {};
        }
      } catch (error) {
        this.addRowData = {};
        console.log(error);
      }
    },

    runStartupCmd(cmd) {
      if (isObject(cmd) && cmd.type) {
        if (cmd.type === 'OPEN_ADD_DIALOG') {
          this.openAddRowDialog();
        }
        if (cmd.type === 'OPEN_CFFORM_DIALOG') {
          this.openCFormDialog(cmd.payload);
        }
      }
    },

    // ===== EDIT FUNCTIONS ======

    /**
     * @param { object } row
     */
    openEditRowDialog(row) {
      if (this.timer) {
        clearInterval(this.timer);
      }

      if (this.$route.params) {
        this.passedFilters = { ...this.passedFilters, ...this.$route.params };
      }

      

      if (this.customServerParams) {
        this.passedFilters = {
          ...this.passedFilters,
          ...this.customServerParams,
        };
      }

      
      if(this.gridUpdateData){
        this.passedFilters={
            ...this.passedFilters,
            ...this.gridUpdateData
        };
      }

      if(this.passedParams){
        this.passedFilters={
           ...this.passedFilters,
            ...this.passedParams

        }
      }



      if (this.customFieldSetName) {
        this.editRowData = row;
        this.fetchCustomFields('Edit', this.customFieldSetName);
        this.$bvModal.show(this.editDialogIDProp);
      } else if (this.service.getRow) {
        this.service
          .getRow(row['ID'], this.passedFilters)
          .then(response => {
            this.editRowData = response;
            this.editUpdate = new Date().getTime();
            this.fetchFormConfig('Edit', row['ID']);
            this.$bvModal.show(this.editDialogIDProp);
          })
          .catch(error => console.log(error));
      } else {
        this.editRowData = row;
        this.origRow = JSON.parse(JSON.stringify(row));
        this.$bvModal.show(this.editDialogIDProp);
      }
      this.$bvModal.show(`edit-dialog-${this.componentID}`);
      this.$bvModal.hide('loading-modal');
    },

    /**
     * Hides edit modal
     */
    closeEditRowDialog(
      onCloseEvent = null,
      showAfterEdit = false,
      afterEditParams = {}
    ) {
      if (this.showCForm == true) {
        this.closeCFormDialog();
      }
      if (this.showSurveyJsForm == true) {
        this.closeSurveyJsFormDialog();
      }
      this.$bvModal.hide(`edit-dialog-${this.componentID}`);

      this.formError = '';
      if (this.validationSettings) {
        this.$v.$reset();
      }

      if (showAfterEdit) {
        this.openAfterEditDialog(afterEditParams);
      }

      if (onCloseEvent) {
        this.editRowData = {};

        this.editRowData = JSON.parse(JSON.stringify(this.origRow));

        // this.getRows();
      }
      if (afterEditParams && afterEditParams.cFormDialogProps) {
        this.openCFormDialog(afterEditParams.cFormDialogProps)
      }
      if (afterEditParams && afterEditParams.openSurveyJsFormDialog) {
        this.openSurveyJsFormDialog(afterEditParams.surveyJsFormDialogData);
      }
      if (afterEditParams && afterEditParams.refreshTableSettings) {
        this.setup();
      }
    },

    /**
     * Sends editRowData using this.service.update
     */
    editRow() {
      if (Object.keys(this.validationSettings).length > 0) {
        this.$v.$touch();

        if (this.$v.editRowData.$invalid) {
          this.formError =
            'Your submission contains errors please review and try again';
          var container = $('#addContainer').closest('.modal-body');
          container.scrollTop(0);
          return;
        }
      }

      if (Object.keys(this.validationsFromFields).length > 0) {
        var validationsFromFieldsKeys = Object.keys(this.validationsFromFields);

        var vm = this;
        $.each(validationsFromFieldsKeys, function(key, value) {
          if (!$('[id="' + value + '"]').is(':visible')) {
            delete vm.validationsFromFields[value];
          }
        });

        this.$v.$touch();

        if (this.$v.editRowData.$invalid) {
          this.formError =
            'Your submission contains errors please review and try again';
          var container = $('#addContainer').closest('.modal-body');
          container.scrollTop(0);
          return;
        }
      }

      if (typeof this.verifyMethod !== 'undefined') {
        var errors = this.verifyMethod(this.addRowData);

        if (errors.length > 0) {
          this.formError = 'The form contains the following errors:<br><ul>';
          var vm = this;
          $.each(errors, function(key, value) {
            vm.formError = vm.formError.concat('<li>', value, '</li>');
          });
          this.formError = this.formError.concat('</ul>');

          this.editUpdate = new Date().getTime();
          return;
        }
      }

      if (this.dynamicRequired) {
        if (!this.dynamicIsValid(this.editRowData)) {
          this.invalidFormAction();
          return;
        }
      }

      if (
        this.customFieldSetName &&
        !this.isValidForm(this.editRowData, 'Edit')
      ) {
        this.invalidFormAction();
        return;
      }

      var payload;
      payload = this.editRowData;
      payload.customServerParams = this.customServerParams;

      if (this.service.auditEditForm) {
        this.service
          .auditEditForm(payload)
          .then(response => {
            this.handleAuditEditForm(response, payload);
          })
          .catch(error => {
            this.formError = ErrorMessages.NETWORK_SUBMIT_ERROR;
            this.invalidFormAction();
          });
      
      } else {
        this.submitUpdateData(payload);
      }
    },

    handleAuditEditForm(response, payload) {
      if (response.error) {

        if(response.showAsConfirmation){
          this.$bvModal
          .msgBoxConfirm(response.message, {
            hideHeaderClose: true,
            okVariant: 'success',
            cancelVariant: 'danger',
            centered: true,
            okTitle:"Yes",
            cancelTitle:"No",
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
          })
          .then(value => {
            if (value) {
                if(response.displaySecondConfirmation){
                   this.$bvModal
                      .msgBoxConfirm(response.secondConfirmationMessage, {
                        hideHeaderClose: true,
                        okVariant: 'success',
                        cancelVariant: 'danger',
                        centered: true,
                        noCloseOnBackdrop: true,
                        noCloseOnEsc: true,
                        okTitle:"Yes",
                        cancelTitle:"No",
                      })
                      .then(value => {
                        if (value) {
                          payload.resendLinks=true;
                        }
                      
                         this.submitUpdateData(payload);


                        
                      });


                }else{
                    this.submitUpdateData(payload);

                }

                  
            }
          });

        }else{
        this.$bvModal
          .msgBoxConfirm(response.message, {
            hideHeaderClose: true,
            title: 'WARNING!',
            okVariant: 'danger',
            okTitle:
              response.dialog && response.dialog.okTitle
                ? response.dialog.okTitle
                : 'Delete',
            centered: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
          })
          .then(value => {
            if (value) {
              this.submitUpdateData(payload);
            }
          });
        }
      } else {
        this.submitUpdateData(payload);
      }
    },

    submitUpdateData(payload) {
      this.service
        .update(payload)
        .then(response => {

          if (response.error) {
            this.formError = response.message;
            this.invalidFormAction();
          } else {
            this.formError = '';
            if (response.uploadResourceURL) {
              const presignedDataList = Array.isArray(response.uploadResourceURL) ? response.uploadResourceURL : [{ uploadResourceURL: response.uploadResourceURL }];
              this.uploadResourceHandler(presignedDataList, payload).then(data => {
                this.closeEditRowDialog(null, response.showAfterEdit, response.afterEditParams);
                this.getRows();
              }).catch(error => {
                this.showTableError(true, error);
              });
            } else if (response.afterUpdateConfirmationSettings) {
              this.closeEditRowDialog(
                null,
                response.showAfterEdit,
                response.afterEditParams
              );
              this.customConfirmationSettings =
                response.afterUpdateConfirmationSettings;

              this.refreshCustomConfirmation = new Date().getTime();

              this.renderCustomConfirmation = true;
           } else if (response.eventToEmit) {

              var eventData = {
                eventName: response.eventToEmit,
                params: response.eventParams,
                inCustomDialog: response.inCustomDialog,
                closeCustomDialog: response.closeCustomDialog,
              };
              this.$emit('customActionEvent', eventData);
              this.closeEditRowDialog(
                null,
                response.showAfterEdit,
                response.afterEditParams
              );

            } else {
              this.closeEditRowDialog(
                null,
                response.showAfterEdit,
                response.afterEditParams
              );
              if (response.afterEditParams && response.afterEditParams.refreshTableSettings) {
                this.setup();
              } else {
                this.getRows();
              }
              this.processAfterAction(response);
            }
          }
        })
        .catch(error => {
          this.formError = ErrorMessages.NETWORK_SUBMIT_ERROR;
          this.invalidFormAction();
        });
    },

    processAfterAction(response) {
      if (response.afterSubmitAction) {
        var origResponse = response;

        if (response.afterSubmitActionMessage) {
          this.$bvModal
            .msgBoxOk(response.afterSubmitActionMessage, {
              title: 'Success',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'success',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true,
            })
            .then(value => {
              this.$http
                .post(API_URL + response.afterSubmitAction)
                .then(response => {
                  if (response.alertMessage) {
                    this.$bvModal
                      .msgBoxOk(response.alertMessage, {
                        title: 'Success',
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true,
                      })
                      .then(value => {
                        if (origResponse.closeAfterAdd) {
                          this.$emit('closeAfterAddCustomDialog');
                        }

                        if (origResponse.closeModalView) {
                          this.$router.go(-1);
                        }
                      });
                  } else {
                    if (origResponse.closeAfterAdd) {
                      this.$emit('closeAfterAddCustomDialog');
                    }

                    if (origResponse.closeModalView) {
                      this.$router.go(-1);
                    }
                  }
                })
                .catch(function(error) {
                  console.log(error);
                });
            });
        } else {
          this.$http
            .post(API_URL + response.afterSubmitAction)
            .then(response => {
              if (response.alertMessage) {
                this.$bvModal
                  .msgBoxOk(response.alertMessage, {
                    title: 'Success',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true,
                  })
                  .then(value => {
                    if (origResponse.closeAfterAdd) {
                      this.$emit('closeAfterAddCustomDialog');
                    }

                    if (origResponse.closeModalView) {
                      this.$router.go(-1);
                    }
                  });
              } else {
                if (origResponse.closeAfterAdd) {
                  this.$emit('closeAfterAddCustomDialog');
                }

                if (origResponse.closeModalView) {
                  this.$router.go(-1);
                }
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      
      } else {
        if (response.closeAfterAdd) {
          this.$emit('closeAfterAddCustomDialog');
        }

        if (response.closeModalView) {
          this.$router.go(-1);
        }
      }
    },
    // ===== DELETE FUNCTIONS ======

    /**
     * Deletes a row by calling this.service.remove
     * @params {} row
     */
    openDeleteRowDialog(row) {
      this.$bvModal
        .msgBoxConfirm(this.deleteMessage, {
          hideHeaderClose: true,
          centered: true,
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          okVariant: 'success',
          okTitle: 'Delete',
          cancelVariant: 'danger',
          cancelTitle: 'Cancel',
        })
        .then(value => {
          if (value) {
            var payload = row;
            payload.customServerParams = this.customServerParams;
            this.service.remove(payload).then(response => {
              if (response) {
                if (response.success) {
                  this.getRows();


                  if(response.eventToEmit){
                      var eventData = {
                        eventName: response.eventToEmit,
                        params: response.eventParams,
                        inCustomDialog: response.inCustomDialog,
                        closeCustomDialog: response.closeCustomDialog,
                      };

                      this.$emit('customActionEvent', eventData);

                  }

                  this.processAfterAction(response);
                } else {
                  this.$bvModal.msgBoxOk(response.message, {
                    title: 'Error Deleteing Row',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true,
                  });
                }
              } else {
                this.getRows();
              }
            });
          }
        });
    },

    // ===== SEARCH FUNCTIONS ======
    /**
     * Shows search modal
     */
    openSearchDialog() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.$bvModal.show('search-dialog');
    },

    /**
     * Hides search modal
     */
    closeSearchDialog() {
      this.$bvModal.hide('search-dialog');
      this.getRows();
    },

    globalSearch(params) {
      this.globalSearchApplied =
        params.searchVal && params.searchVal.length > 0 ? true : false;

      this.updateParams({
        globalFilter:
          params.searchVal && params.searchVal.length > 0
            ? params.searchVal
            : null,
      });

      this.getRows();
    },
    clearGlobalSearch() {
      this.globalSearchDefault = null;

      this.globalSearchTime = new Date().getTime();
      this.globalSearchApplied = false;
      
      if(this.setSelectAll){
        this.localSelectedRows=new Array();
        this.setSelectAll=false;
      }
      this.updateParams({ globalFilter: null });

      if(this.reloadTableOnFilterClear){
          this.setup();
      }else{
          this.getRows();
      }

     
    },
    updatePassedParams(data){
      this.gridUpdateData=data;

    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onRowSelected(row) {
      var params = {
        serverParams: this.serverParams,
        row: row,
      };
      if (this.service.selectRow !== undefined) {
        this.service.selectRow(params).then(response => {
          if (response && response.error) {
            this.formError = response.message;

            this.getRows();
          } else {
            this.formError = '';
            this.getRows();
          }
        });
      } else if (this.afterSelectUri) {
        if (!this.tableIsLoading) {
          this.$http.post(this.afterSelectUri, params).then(response => {
            if (response.data.data.success) {
              this.formError = '';
              this.getRows();
            }
          });
        }
      } else if (row.onSelected) {
        if (
          row.onSelected.submitUri &&
          row.onSelected.submitUri.indexOf &&
          row.onSelected.submitUri.indexOf('api') !== -1
        ) {
          this.$http.post(row.onSelected.submitUri, params).then(response => {
            if (response.data.data.success) {
              this.formError = '';
              this.getRows();
            }
          });
        }
      } else {
        this.$emit('onRowSelected', row);
      }
    },

    // ===== VALIDATION FUNCTIONS ======
    /**
     * Action to display form errors
     */
    invalidFormAction() {
      let container = $('#formErrorDiv').closest('.modal-body');
      container.scrollTop(0);
    },
    /**
     * Validates form
     */
    isValidForm(formAnswers, formType) {
      if (
        !formAnswers ||
        Object.keys(formAnswers).length === 0 ||
        !this.customFieldCategories
      ) {
        this.formError = ErrorMessages.NETWORK_SUBMIT_ERROR;
        return false;
      }

      for (const category of this.customFieldCategories) {
        for (const customField of category.customFields) {
         
          if (customField['Required'] === true) {

        

            if (customField['Type'] === 'FilePicker' && formType === 'Edit') {
              continue;
            } else if (customField['Hidden'] === true && formType === 'Ed   it') {
              continue;
            }else if(customField['ListParams']){



              if(!formAnswers[customField['Name']] || (formAnswers[customField['Name']] && formAnswers[customField['Name']].length==0)){
                if( $('[id="' + customField['Name'] + '"]').is(':visible')){


                  this.formError = ErrorMessages.MISSING_FIELDS;
                   return false;
                }else if(!customField['Hidden']){

                   this.formError = ErrorMessages.MISSING_FIELDS;
                   return false;
                }
              }
            } else if (
              isEmpty(formAnswers[customField['Name']]) &&
              $('[id="' + customField['Name'] + '"]').is(':visible')
            ) {

              this.formError = ErrorMessages.MISSING_FIELDS;
              return false;
            }
          }

          if (customField['NumericOnly'] === true) {
            if (!isEmpty(formAnswers[customField['Name']])) {
              if (isNaN(formAnswers[customField['Name']])) {
                this.formError = ErrorMessages.NUMERIC_ONLY;
                return false;
              }
            }
          }
          
        }
      }

 
      return true;
    },
    dynamicIsValid(formAnswers) {
      if (
        !formAnswers ||
        Object.keys(formAnswers).length === 0 ||
        !this.dynamicRequired
      ) {
        this.formError = ErrorMessages.NETWORK_SUBMIT_ERROR;
        return false;
      }

      var vm = this;

      for (const parentfield in this.dynamicRequired) {
        for (const fieldName of this.dynamicRequired[parentfield]) {
          var fieldNameExploded = fieldName.split('_');

          var errorFound = false;
          formAnswers[parentfield].forEach((value, index) => {
            for (const answerField in value) {
              var lookupVal = answerField + '_' + index;

              if (lookupVal == fieldName) {
                if (
                  isEmpty(
                    formAnswers[parentfield][index][fieldNameExploded[0]]
                  ) &&
                  $('[id="' + fieldName + '"]').is(':visible')
                ) {
                  errorFound = true;
                  return;
                }
              }
            }
          });

          if (errorFound) {

            this.formError = ErrorMessages.MISSING_FIELDS;
            return false;
          }
        }
      }
      return true;
    },
    // ===== CUSTOM FIELD FUNCTIONS ======
    async fetchChildFormValues({ parent, child, parentValue }) {
      const childCustomFieldRef = this.findCustomField(
        parent.CustomFieldCategory,
        child.Name
      );

      if (!childCustomFieldRef || !childCustomFieldRef.DataURI) return;
      let response = null;
      let payload = { value: parentValue, rowData: {} };
      if (isObject(this.editRowData) && this.editRowData.ID !== undefined) {
        payload['rowData']['ID'] = this.editRowData.ID;
        // payload['rowData'] = {...this.editRowData };
      }

      if (Object.keys(this.editRowData) > 0) {
        payload['rowData'] = this.editRowData;
      } else if (Object.keys(this.addRowData)) {
        payload['rowData'] = this.addRowData;
      }

      response = await this.$http.post(
        `${childCustomFieldRef.DataURI.uri}`,
        payload
      );
      let values = getData(response);
      this.updateFormValues({ target: child.Name, targetValue: values });
    },

    updateFormValues(data) {
      const { target, targetValue } = data;
      if (this.addRowData && this.addRowData[target] !== undefined) {
        this.addRowData[target] = targetValue;
      } else if (this.editRowData && this.editRowData[target] !== undefined) {
        this.editRowData[target] = targetValue;
      }
    },
    refreshField({ parent, child, parentValue, triggerValue, categoryToShow }) {
      const childCustomFieldRef = this.findCustomField(
        parent.CustomFieldCategory,
        child.Name,
        child.targetCategory
      );

      if (childCustomFieldCategoryRef) {
      }
    },
    showCategoryFunc({
      parent,
      child,
      parentValue,
      triggerValue,
      isInit,
      initialValue,
    }) {

      var childCustomFieldCategoryRef = this.findCustomCategory(child.Name);

      
     
      if (!childCustomFieldCategoryRef) return;


      if(isInit && parent.Hidden) return;

      if (!parentValue || (parentValue && parentValue.length == 0)) {
        childCustomFieldCategoryRef.Hidden = true;
        for (let childCustomField of childCustomFieldCategoryRef.customFields) {
          childCustomField.Required = false;
          this.formToggleRequired(true); 
        }
      } else if (triggerValue && triggerValue == 'any') {
        childCustomFieldCategoryRef.Hidden = false;
        this.formToggleRequired(true); 
      } else if (Array.isArray(parentValue)) {
        if (parentValue.includes(triggerValue)) {
          childCustomFieldCategoryRef.Hidden = false;
          this.formToggleRequired(true); 
        } else {

          childCustomFieldCategoryRef.Hidden = true;
            for (let childCustomField of childCustomFieldCategoryRef.customFields) {
              childCustomField.Required = false;
              this.formToggleRequired(true); 
            }
        }
      } else {
        const parentValueObject =
          parent && Array.isArray(parent.Options)
            ? parent.Options.filter(ele => ele.value === parentValue)
            : null;


       


        if (
          parentValueObject &&
          parentValueObject[0] &&
          parentValueObject[0].index === triggerValue
        ) {
          childCustomFieldCategoryRef.Hidden = false;
          this.formToggleRequired(true); 
        } else if (Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
            childCustomFieldCategoryRef.Hidden = false;
            this.formToggleRequired(true); 
          } else {
            childCustomFieldCategoryRef.Hidden = true;
            for (let childCustomField of childCustomFieldCategoryRef.customFields) {
              childCustomField.Required = false;
            }
          }
        } else if (parentValue === triggerValue) {
          childCustomFieldCategoryRef.Hidden = false;
          this.formToggleRequired(true); 
        } else if (triggerValue == 'any') {
          childCustomFieldCategoryRef.Hidden = false;
          this.formToggleRequired(true); 
        }else if (typeof triggerValue ==='undefined' && parentValue){
           childCustomFieldCategoryRef.Hidden = false;
           this.formToggleRequired(true); 
        } else {
            childCustomFieldCategoryRef.Hidden = true;
            for (let childCustomField of childCustomFieldCategoryRef.customFields) {
              childCustomField.Required = false;
            }
          
        }
      }
    },

    async setAllPickerOptions({parent,child,parentValue,childValue}){

      const childCustomFieldRef =
        this.formFields.length > 0
          ? this.findFormField(child.Name)
          : this.findCustomField(
              parent.CustomFieldCategory,
              child.Name,
              child.targetCategory
            );

      if (!childCustomFieldRef || !childCustomFieldRef.SetAllDataURI) return;


        var vm = this;

        if (parentValue == child.conditionValue) {

                var response = null;


                if (childCustomFieldRef.SetAllDataURI.method) {
                  if (childCustomFieldRef.SetAllDataURI.method == 'post') {
                    var payload = {};

                    if (Object.keys(this.editRowData).length > 0) {
                      payload['rowData'] = this.editRowData;
                    } else if (Object.keys(this.addRowData).length > 0) {
                      payload['rowData'] = this.addRowData;
                    }




                    if(this.passedFilters){
                      payload={...payload,...this.passedFilters};

                    }



                    response = await this.$http.post(
                      `${childCustomFieldRef.SetAllDataURI.uri}`,
                      payload
                    );
                  } else {
                    response = await this.$http.get(
                      `${childCustomFieldRef.SetAllDataURI}${parentValue}`
                    );
                  }

                  let fieldValue=getData(response);


                      

                      if (vm.addRowData[child.Name]){


                        
                       
                          vm.addRowData[child.Name]=fieldValue.returnData;
                        
                      }else if (vm.editRowData[child.Name]){
                       
                          vm.editRowData[child.Name]=fieldValue.returnData;
                      }


                    
                } else {
                  response = await this.$http.get(
                    `${childCustomFieldRef.SetAllDataURI}${parentValue}`
                  );

                 let fieldValue=getData(response);


                      if (vm.addRowData[child.Name]){


                        
                       
                          vm.addRowData[child.Name]=fieldValue.returnData;
                        
                      }else if (vm.editRowData[child.Name]){
                       
                          vm.editRowData[child.Name]=fieldValue.returnData;
                      }

                 
                }
      

        } 


    
        this.$forceUpdate();




    },

    appendToDefault({ parent, child, parentValue, childValue }) {
      // find child custom field

      const childCustomFieldRef = this.findCustomField(
        parent.CustomFieldCategory,
        child.Name
      );


      

      if (child.Name == 'CollectionSeries' || child.Name == 'Location') {


        if (childValue.length == 0) {
          return;
        }

        var vm = this;




        if (Array.isArray(parentValue)) {
          $.each(parentValue, function(key, value) {
            if (value == child.conditionValue) {

              if (vm.addRowData[child.Name]){
                if (!vm.addRowData[child.Name].includes(childValue)) {
                  vm.addRowData[child.Name].push(childValue);
                }
              }else if(vm.addRowData && !vm.addRowData[child.Name]){
                vm.addRowData[child.Name]=[];
                vm.addRowData[child.Name].push(childValue);
             }


              if (vm.editRowData[child.Name])
                if (!vm.editRowData[child.Name].includes(childValue)) {
                  vm.editRowData[child.Name].push(childValue);
                }
            }
          });
        } else if (parentValue == child.conditionValue && childValue == 'All') {
          $.each(childCustomFieldRef.Options, function(key, value) {
            if (value.text != 'Select All') {
              if (vm.addRowData[child.Name])
                if (!vm.addRowData[child.Name].includes(value.value)) {
                  if (childCustomFieldRef.Type == 'ListLocationEHR') {
                    vm.addRowData[child.Name].push({
                      ID: value.value,
                      text: { Name: value.text },
                    });
                  } else {
                    vm.addRowData[child.Name].push(value.value);
                  }
                }

              if (vm.editRowData[child.Name])
                if (!vm.editRowData[child.Name].includes(value.value)) {
                  vm.editRowData[child.Name].push(value.value);
                }
            }
          });
        } else {
          if (parentValue == child.conditionValue) {
            if (vm.addRowData[child.Name])
              if (!vm.addRowData[child.Name].includes(childValue)) {
                vm.addRowData[child.Name].push(childValue);
              }

            if (vm.editRowData[child.Name])
              if (!vm.editRowData[child.Name].includes(childValue)) {
                vm.editRowData[child.Name].push(childValue);
              }
          }
        }
      } else {
        if (this.addRowData[child.Name])
          this.addRowData[child.Name] =
            childCustomFieldRef.DefaultValue +
            (childValue ? childValue : parentValue);
        if (this.editRowData[child.Name])
          this.editRowData[child.Name] =
            childCustomFieldRef.DefaultValue +
            (childValue ? childValue : parentValue);
      }
    },

  showSelectedField({ parent, child, parentValue, triggerValue }) {

   
      const childCustomFieldRef =
        Object.keys(this.formFields).length > 0
          ? this.findFormField(child.Name)
          : this.findCustomField(
              parent.CustomFieldCategory,
              child.Name,
              child.TargetCategory
            );




    
 
      if (!childCustomFieldRef) return;



      const parentValueObject =
        parent && Array.isArray(parent.Options)
          ? parent.Options.filter(ele => ele.value === parentValue)
          : null;
       


    
    

   

      
      if (parentValueObject && parentValueObject.length > 0) {
  
       
        if (triggerValue && Array.isArray(triggerValue)) {

          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Hidden = false;
          } else if (triggerValue.includes(parentValueObject[0].index)) {
            childCustomFieldRef.Hidden = false;
            
          } else {
            childCustomFieldRef.Hidden = true;
            childCustomFieldRef.Required=false;
          }
        } else if (
          triggerValue &&
          triggerValue === parentValueObject[0].index
        ) {


          
         
          childCustomFieldRef.Hidden = false;

                  


       } else if (
          typeof triggerValue !=='undefined' &&
          triggerValue === parentValueObject[0].index
        ) {
           

          childCustomFieldRef.Hidden = false;
        
        } else if (childCustomFieldRef.Name === parentValueObject[0].index) {
                      

          childCustomFieldRef.Hidden = false;
        } else if (triggerValue && triggerValue == parentValueObject[0].value) {
                     
          childCustomFieldRef.Hidden = false;
       } else if (typeof triggerValue !=='undefined' && triggerValue == parentValueObject[0].value) {
                   
          childCustomFieldRef.Hidden = false;
        }else if(triggerValue && child.oper){

          switch(child.oper){
            case 'gte':
              if(parentValueObject[0].index>=triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;

              }

              break;
            case 'lte':
               if(parentValueObject[0].index<=triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;

              }
              break;
            case 'gt':
               if(parentValueObject[0].index>triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;

              }
              break;
            case 'lt':
               if(parentValueObject[0].index<triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;
              }
              break;
          }
        } else if (
          triggerValue &&
          triggerValue == 'any' &&
          parentValueObject[0].value
        ) {
             

          childCustomFieldRef.Hidden = false;
        } else {
          childCustomFieldRef.Hidden = true;
          childCustomFieldRef.Required=false;
        }
      } else if (parentValue) {
        
  
        if (triggerValue && Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Hidden = false;
          } else {
            childCustomFieldRef.Hidden = true;
            childCustomFieldRef.Required=false;
          }
        } else if (
          parentValue === triggerValue ||
          (isNaN(parentValue) && parentValue.length > 0 && triggerValue == 'any') ||
          (parentValue && triggerValue == 'any')
        ) {
      
          childCustomFieldRef.Hidden = false;
        }else if(triggerValue && Array.isArray(parentValue) ){
            let showField=false;

            $.each(parentValue,function(key,value){
               let  valueObject=
                      value && Array.isArray(parent.Options)
                        ? parent.Options.filter(ele => ele.value === value)
                        : null;

                  if(valueObject[0].index==triggerValue){
                    showField=true;
                  }
            });


            if(showField){
                  childCustomFieldRef.Hidden=false;
            }else{

                  childCustomFieldRef.Hidden=true;
                  childCustomFieldRef.Required=false;

            }

            
        }else if(triggerValue && child.oper){
           switch(child.oper){
            case 'gte':
              if(parentValue>=triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;

              }

              break;
            case 'lte':
               if(parentValue<=triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;

              }
              break;
            case 'gt':
               if(parentValue>triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;

              }
              break;
            case 'lt':
               if(parentValue<triggerValue){
                    childCustomFieldRef.Hidden=false;
              }else{
                    childCustomFieldRef.Hidden=true;
                    childCustomFieldRef.Required=false;

              }
              break;
          }
        }
      }



    },
    updateFacilityStatus({parent, child, parentValue, triggerValue}){
      const childCustomFieldRef =
        Object.keys(this.formFields).length > 0
          ? this.findFormField(child.Name)
          : this.findCustomField(
              parent.CustomFieldCategory,
              child.Name,
              child.TargetCategory
            );
      if (!childCustomFieldRef) return;

      const parentValueObject =
        parent && Array.isArray(parent.Options)
          ? parent.Options.filter(ele => ele.value === parentValue)
          : null;
          if (!parentValueObject) return;
      if (triggerValue && Array.isArray(triggerValue)) {
        if (triggerValue.includes(parentValue)) {
          this.editRowData[childCustomFieldRef.Name]= "318";
          this.formToggleRequired(false);         
        }else{
          this.editRowData[childCustomFieldRef.Name]= "317";
          this.formToggleRequired(true); 
        }
      }
    },
    updateToggleRequired({parent, child, parentValue, triggerValue}){
      if(parentValue==triggerValue){
        this.formToggleRequired(true);        
      }else{
        this.formToggleRequired(false); 
      }
    },
    formToggleRequired(active){
      if(!active){
        for (let category of this.customFieldCategories) {
          for (let customField of category.customFields) {              
            if(customField.Required ==true){
              if(this.storeRequiredFields.length==0){
                this.storeRequiredFields[customField.Name] =customField.Name;
              }           
              customField.Required =false;
            }
          }
        }
      }else{
        for (let category of this.customFieldCategories) {
          if(category.Hidden==false){
            for (let customField of category.customFields) { 
              if(this.storeRequiredFields[customField.Name] && (customField.Hidden !=true)){             
                customField.Required =true;
              }                                 
            }
          }   
        }
      }

    },
    changeFieldText({ parent, child, parentValue, triggerValue,targetCategory }) {

   
      const childCustomFieldRef =
        Object.keys(this.formFields).length > 0
          ? this.findFormField(child.Name)
          : this.findCustomField(
              parent.CustomFieldCategory,
              child.Name,
             targetCategory
            );




    
 
      if (!childCustomFieldRef) return;



      const parentValueObject =
        parent && Array.isArray(parent.Options)
          ? parent.Options.filter(ele => ele.value === parentValue)
          : null;


       


    
    


      
      if (parentValueObject && parentValueObject.length > 0) {
  
       
        if (triggerValue && Array.isArray(triggerValue)) {

          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValue);

          } else if (triggerValue.includes(parentValueObject[0].index)) {
            childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValueObject[0].index);
          } 
        } else if (
          triggerValue &&
          triggerValue === parentValueObject[0].index
        ) {

        childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValueObject[0].index);

                  


       } else if (
          typeof triggerValue !=='undefined' &&
          triggerValue === parentValueObject[0].index
        ) {
           

            childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValueObject[0].index);

        
        } else if (childCustomFieldRef.Name === parentValueObject[0].index) {
           childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValueObject[0].index);


        } else if (triggerValue && triggerValue == parentValueObject[0].value) {
            childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValueObject[0].index);
    
       } else if (typeof triggerValue !=='undefined' && triggerValue == parentValueObject[0].value) {
                   
            childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValueObject[0].index);
      } else if (
          triggerValue &&
          triggerValue == 'any' &&
          parentValueObject[0].value
        ) {
             

             childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValueObject[0].index);

        }else{
           childCustomFieldRef.Text=childCustomFieldRef.OrigText;
        }
      } else if (parentValue) {
        
  
        if (triggerValue && Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
             childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValue);

          } 
        } else if (
          parentValue === triggerValue ||
          (isNaN(parentValue) && parentValue.length > 0 && triggerValue == 'any') ||
          (parentValue && triggerValue == 'any')
        ) {
      
             childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValue);
        }else if(triggerValue && Array.isArray(parentValue) ){
              childCustomFieldRef.Text=this.findAlternateText(childCustomFieldRef,parent,parentValue);


            
        }else{
           childCustomFieldRef.Text=childCustomFieldRef.OrigText;
        }
      }



    },
    findAlternateText(child,parent,parentValue){
        if(child.AlternateTexts && child.AlternateTexts.length>0){
           let returnText=null;
           for(let AltText of child.AlternateTexts){
                  if(AltText.ParentFieldID==parent.FieldID && AltText.TriggerValue==parentValue ){
                      returnText=AltText.AlternateText;
                      break;
                  }

            }

            if(returnText){
              return returnText;
            }else{
              return child.Text;
            }
        }else{
          return child.Text;
        }
    },
    hideSelectedField({ parent, child, parentValue, triggerValue }) {
      const childCustomFieldRef = this.findCustomField(
        parent.CustomFieldCategory,
        child.Name,
        child.TargetCategory
      );

      if (!childCustomFieldRef) return;
      const parentValueObject =
        parent && Array.isArray(parent.Options)
          ? parent.Options.filter(ele => ele.value === parentValue)
          : null;

      if (parentValueObject && parentValueObject.length > 0) {
        if (triggerValue && Array.isArray(triggerValue)) {
          if (triggerValue.includes(parentValue)) {
            childCustomFieldRef.Hidden = true;
            childCustomFieldRef.Required=false;
          }
        } else if (triggerValue && triggerValue === parentValue) {
          childCustomFieldRef.Hidden = true;
          childCustomFieldRef.Required=false;
        } else if (
          triggerValue &&
          triggerValue === parentValueObject[0].index
        ) {
          childCustomFieldRef.Hidden = true;
          childCustomFieldRef.Required=false;
        } else if (childCustomFieldRef.Name === parentValueObject[0].index) {
          childCustomFieldRef.Hidden = true;
          childCustomFieldRef.Required=false;
        }
      } else if (parentValue) {
        if (parentValue === triggerValue) {
          childCustomFieldRef.Hidden = true;
          childCustomFieldRef.Required=false;
        }
      }
    },
    async fetchFormOptions({ parent, child, parentValue }) {
      const childCustomFieldRef =
        this.formFields.length > 0
          ? this.findFormField(child.Name)
          : this.findCustomField(
              parent.CustomFieldCategory,
              child.Name,
              child.targetCategory
            );


      if (!childCustomFieldRef || !childCustomFieldRef.DataURI) return;


      let answers={};
       if (Object.keys(this.editRowData).length > 0) {
           answers = this.editRowData;
          } else if (Object.keys(this.addRowData).length > 0) {
           answers = this.addRowData;
          }


      var response = null;
      if (childCustomFieldRef.DataURI.method) {
        if (childCustomFieldRef.DataURI.method == 'post') {
          var payload = {};

          if (Object.keys(this.editRowData).length > 0) {
            payload['rowData'] = this.editRowData;
          } else if (Object.keys(this.addRowData).length > 0) {
            payload['rowData'] = this.addRowData;
          }



          response = await this.$http.post(
            `${childCustomFieldRef.DataURI.uri}`,
            payload
          );
        } else {
          response = await this.$http.get(
            `${childCustomFieldRef.DataURI}${parentValue}`
          );
        }
      } else {
        response = await this.$http.get(
          `${childCustomFieldRef.DataURI}${parentValue}`
        );
      }
      this.$bvModal.hide('loading-modal');
      let options = getData(response);
        

       if(options && options.isCalc){


          if (Object.keys(this.editRowData).length > 0) {
            this.editRowData[childCustomFieldRef.Name]=options.value;
          } else if (Object.keys(this.addRowData).length > 0) {
            this.addRowData[childCustomFieldRef.Name]=options.value;
          }




      }else{

         childCustomFieldRef.Options = Array.isArray(options) ? options : [];

      if(childCustomFieldRef.Children){
         if (
                    Array.isArray(childCustomFieldRef.Children) &&
                    childCustomFieldRef.Children.length > 0
                  ) {
                    for (let child of childCustomFieldRef.Children) {
                      let params = {
                        parent: childCustomFieldRef,
                        child,
                        parentValue: answers[childCustomFieldRef.Name],
                        triggerValue: child.triggerValue,
                      };
                      if (child.Command === 'showSelectedField') {
                        this.showSelectedField(params);
                      }
                      if(child.Command=='updateText'){
                          this.changeFieldText(params)
                      }
                      if (child.Command === 'showCategory') {
                        let params = {
                          parent: childCustomFieldRef,
                          child,
                          parentValue: answers[childCustomFieldRef.Name],
                          triggerValue: child.triggerValue,
                          isInit: true,
                          initialValue: answers[childCustomFieldRef.Name],
                        };
                        this.showCategoryFunc(params);
                      }

                      if (child.Command === 'hideSelectedField') {
                        let params = {
                          parent: childCustomFieldRef,
                          child,
                          parentValue: answers[childCustomFieldRef.Name],
                          triggerValue: child.triggerValue,
                        };
                        this.hideSelectedField(params);
                      }

                       if (child.Command === 'makeRequired') {
                        let params = {
                          parent: childCustomFieldRef,
                          child,
                          parentValue: answers[childCustomFieldRef.Name],
                          triggerValue: child.triggerValue,
                        };
                        this.makeFieldRequiredHandler(params);
                      }

                      if (child.Command === 'fetchOptions') {
                        let params = {
                          parent: childCustomFieldRef,
                          child,
                          parentValue: answers[childCustomFieldRef.Name],
                          triggerValue: child.triggerValue,
                        };

                        this.fetchFormOptions(params);
                      }
                    }
                  }
         }


      }



      
     

    },

    async fetchLoadFormOptions(customField) {
     

      let answers={};
       if (Object.keys(this.editRowData).length > 0) {
           answers = this.editRowData;
          } else if (Object.keys(this.addRowData).length > 0) {
           answers = this.addRowData;
          }


      var response = null;

      if (customField.DataURI && customField.DataURI.method) {
        if (customField.DataURI.method == 'post') {
          var payload = {};

          if (Object.keys(this.editRowData).length > 0) {
            payload['rowData'] = this.editRowData;
          } else if (Object.keys(this.addRowData).length > 0) {
            payload['rowData'] = this.addRowData;
          }



          response = await this.$http.post(
            `${customField.DataURI.uri}`,
            payload
          );
        } else {
          response = await this.$http.get(
            `${customField.DataURI}${parentValue}`
          );
        }
      } else {
        response = await this.$http.get(
          `${customField.DataURI}${parentValue}`
        );
      }
      this.$bvModal.hide('loading-modal');

      let options = getData(response);
        

       if(options && options.isCalc){


          if (Object.keys(this.editRowData).length > 0) {
            this.editRowData[customField.Name]=options.value;
          } else if (Object.keys(this.addRowData).length > 0) {
            this.addRowData[customField.Name]=options.value;
          }




      }else{

         customField.Options = Array.isArray(options) ? options : [];

      if(customField.Children){
         if (
                    Array.isArray(customField.Children) &&
                    customField.Children.length > 0
                  ) {
                    for (let child of customField.Children) {
                      let params = {
                        parent: customField,
                        child,
                        parentValue: answers[customField.Name],
                        triggerValue: child.triggerValue,
                      };
                      if (child.Command === 'showSelectedField') {
                        this.showSelectedField(params);
                      }
                      if(child.Command=='updateText'){
                          this.changeFieldText(params)
                      }
                      if (child.Command === 'showCategory') {
                        let params = {
                          parent: customField,
                          child,
                          parentValue: answers[customField.Name],
                          triggerValue: child.triggerValue,
                          isInit: true,
                          initialValue: answers[customField.Name],
                        };
                        this.showCategoryFunc(params);
                      }

                      if (child.Command === 'hideSelectedField') {
                        let params = {
                          parent: customField,
                          child,
                          parentValue: answers[customField.Name],
                          triggerValue: child.triggerValue,
                        };
                        this.hideSelectedField(params);
                      }


                       if (child.Command === 'makeRequired') {
                        let params = {
                          parent: customField,
                          child,
                          parentValue: answers[customField.Name],
                          triggerValue: child.triggerValue,
                        };
                        this.makeFieldRequiredHandler(params);
                      }

                      if (child.Command === 'fetchOptions') {
                        let params = {
                          parent: customField,
                          child,
                          parentValue: answers[customField.Name],
                          triggerValue: child.triggerValue,
                        };

                        this.fetchFormOptions(params);
                      }
                    }
                  }
         }


      }



      
     

    },
    reloadTableHandler(){
      this.getRows();
    },
    makeFieldRequiredHandler({parent, child, parentValue, triggerValue}){
        const childCustomFieldRef =
        Object.keys(this.formFields).length > 0
          ? this.findFormField(child.Name)
          : this.findCustomField(
              parent.CustomFieldCategory,
              child.Name,
              child.TargetCategory
            );




    
 
      if (!childCustomFieldRef) return;


      
      const parentValueObject =
        parent && Array.isArray(parent.Options)
          ? parent.Options.filter(ele => ele.value === parentValue)
          : null;
       


    
    


      
      if (parentValueObject && parentValueObject.length > 0) {
         
         if(parentValueObject[0].value){
              if (triggerValue && Array.isArray(triggerValue)) {
                  if(triggerValue.includes(parentValueObject[0].value)){
                    childCustomFieldRef.Required=true;
                  }else{
                      childCustomFieldRef.Required=false;

                  }

              }else if(triggerValue){
                  if(triggerValue===parentValueObject[0].value){
                      childCustomFieldRef.Required=true;

                  }else{
                      childCustomFieldRef.Required=false;

                  }

              }else{
                   childCustomFieldRef.Required=true;

              }

         }else if(parentValueObject[0].index){
             if (triggerValue && Array.isArray(triggerValue)) {
                  if(triggerValue.includes(parentValueObject[0].index)){
                    childCustomFieldRef.Required=true;
                  }else{
                      childCustomFieldRef.Required=false;

                  }

              }else if(triggerValue){
                  if(triggerValue===parentValueObject[0].index){
                      childCustomFieldRef.Required=true;

                  }else{
                      childCustomFieldRef.Required=false;

                  }

              }else{
                   childCustomFieldRef.Required=true;

              }


         }
       
      } else if (typeof parentValue !=='undefined' && parentValue) {
             if (triggerValue && Array.isArray(triggerValue)) {
                  if(triggerValue.includes(parentValue)){
                    childCustomFieldRef.Required=true;
                  }else{
                      childCustomFieldRef.Required=false;

                  }

              }else if(triggerValue){
                  if(triggerValue===parentValue){
                      childCustomFieldRef.Required=true;

                  }else{
                      childCustomFieldRef.Required=false;

                  }

              }else{
                   childCustomFieldRef.Required=true;

              }

  
        
      }
      
   

    },

    findFormField(targetName) {
      for (let fieldCategory of this.formFields) {
        for (let field of fieldCategory.fields) {
          if (field.Name.trim() === targetName.trim()) {
            return field;
          }
        }
      }

      return null;
    },

    findCustomField(customFieldCategory, targetName, targetCategory = null) {
      if (targetCategory) {
        for (let category of this.customFieldCategories) {
          if (category.Name.trim() === targetCategory) {
            for (let customField of category.customFields) {
              if (customField.Name.trim() === targetName) {
                return customField;
              }
            }
          }
        }
      } else {
        for (let category of this.customFieldCategories) {
          if (category.Name.trim() === customFieldCategory.trim()) {
            for (let customField of category.customFields) {
              if (customField.Name.trim() === targetName.trim()) {
                return customField;
              }
            }
          }
        }
      }

      return null;
    },

    findCustomCategory(targetName) {
      for (let category of this.customFieldCategories) {
        if (category.Name.trim() === targetName.trim()) {
          return category;
        }
      }
      return null;
    },

    async updateFormOptions({
      target,
      parentValue,
      customFieldCategory,
      answerType,
    }) {
      if (!target || !parentValue || !customFieldCategory || !answerType)
        return;
      let options = null;
      if (answerType == 'county') {
        options = await customFieldService.getCountiesByProvinceID(parentValue);
      }
      if (options) {
        for (let category of this.customFieldCategories) {
          if (category.Name === customFieldCategory) {
            for (let customField of category.customFields) {
              if (customField.Name === target) {
                customField.Options = options;
              }
            }
          }
        }
      }
    },

    fetchProvinces(countryID, fieldName, delPrevProvince = true) {
      if (!countryID || !fieldName) {
        return;
      }
      customFieldService
        .getProvincesByCountryID(countryID)
        .then(response => {
          for (let category of this.customFieldCategories) {
            for (let cfs of category.customFields) {
              if (cfs['Name'] === fieldName) {
                if (
                  delPrevProvince &&
                  this.editRowData &&
                  this.editRowData[fieldName] !== undefined
                ) {
                  this.editRowData[fieldName] = '';
                } else if (
                  delPrevProvince &&
                  this.addRowData &&
                  this.addRowData[fieldName] !== undefined
                ) {
                  this.addRowData[fieldName] = '';
                }
                cfs['Options'] = response;
              }
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchFormConfig(action, CurrentRow = null) {
      const rowID =
        CurrentRow && CurrentRow.indexOf('_') > -1
          ? CurrentRow
          : this.$route.params.rowID
          ? this.$route.params.rowID
          : CurrentRow;

      if (this.componentParams) {
        this.passedFilters = { ...this.passedFilters, ...this.componentParams };
      }

      this.service
        .getFormConfig(rowID, action, this.passedFilters)
        .then(response => {


          let answers = {};
          for (let field of response.fields) {
            if (field.fields) {
              for (let childField of field.fields) {
                if (
                  this.tmpSavedAnswers &&
                  this.tmpSavedAnswers[childField['Name']]
                )
                  answers[childField['Name']] = deepCopy(
                    this.tmpSavedAnswers[childField['Name']]
                  );
                else if (
                  action == 'Edit' &&
                  this.editRowData[childField['Name']]
                ) {
                  answers[childField['Name']] = deepCopy(
                    this.editRowData[childField['Name']]
                  );
                } else answers[field['Name']] = childField['DefaultValue'];

                if (!answers[childField['Name']]) {
                  if (childField['Type'] == 'MultiSelect') {
                    answers[childField['Name']] = new Array();
                  } else {
                    answers[childField['Name']] = null;
                  }
                }

               if (childField['Required']) {

                
                      this.validationsFromFields[childField['Name']] = { required };

                  
                }
              }

            } else {
              if (this.tmpSavedAnswers && this.tmpSavedAnswers[field['Name']])
                answers[field['Name']] = deepCopy(
                  this.tmpSavedAnswers[field['Name']]
                );
              else answers[field['Name']] = field['DefaultValue'];

              if (!answers[field['Name']]) {
                if (field['Type'] == 'MultiSelect') {
                  answers[field['Name']] = new Array();
                } else {
                  answers[field['Name']] = null;
                }
              }

                if (field['Required']) {
              
                    this.validationsFromFields[field['Name']] = { required };

                
              }else if(field['Type']=='TimePickerInput'){
                      this.validationsFromFields[field['Name']] = { timeValidate };

              }
            }
          }



          this.formFields = response.fields;
          this.formFieldsHasCategories = response.hasCategories;

          if (action == 'Add') {
            if (answers) {
              this.addRowData = answers;
            } else {
              this.addRowData = {};
            }
          }

          if (this.formFields) {
            for (let field of response.fields) {
              if (field.fields) {
                for (let childField of field.fields) {
                  if (
                    Array.isArray(childField.Children) &&
                    childField.Children.length > 0
                  ) {
                    for (let child of childField.Children) {
                      let params = {
                        parent: childField,
                        child,
                        parentValue: answers[childField.Name],
                        triggerValue: child.triggerValue,
                      };
                      if (child.Command === 'showSelectedField') {
                        this.showSelectedField(params);
                      }

                       if (child.Command === 'makeRequired') {
                        this.makeFieldRequiredHandler(params);
                      }


                      if (child.Command === 'appendToDefault') {
                       
                        this.appendToDefault(params);
                    
                    }
                                

                      if(child.Command==='updateText'){
                          this.changeFieldText(params);
                      }

                      if (child.Command === 'showCategory') {
                        let params = {
                          parent: childField,
                          child,
                          parentValue: answers[childField.Name],
                          triggerValue: child.triggerValue,
                          isInit: true,
                          initialValue: answers[childField.Name],
                        };
                        this.showCategoryFunc(params);
                      }

                      if (child.Command === 'hideSelectedField') {
                        let params = {
                          parent: childField,
                          child,
                          parentValue: answers[childField.Name],
                          triggerValue: child.triggerValue,
                        };
                        this.hideSelectedField(params);
                      }

                      if (child.Command === 'fetchOptions') {
                        let params = {
                          parent: childField,
                          child,
                          parentValue: answers[childField.Name],
                          triggerValue: child.triggerValue,
                          
                        };

                        this.fetchFormOptions(params);
                      }
                    }
                  }
                }
              }
            }
          }

          // Cleanup
          this.formError = '';
          this.tmpSavedAnswers = null;
          this.refreshOptions += 1;

          this.$forceUpdate();
          if (action == 'Add') {

              this.$bvModal.show(this.addDialogIDProp);
          }
        })
        .catch(error => {
          this.addRowData = {};
        });
    },
    addRequiredDynamic(data) {
      var vm = this;

      vm.dynamicRequired[data.fieldName] = new Array();
      $.each(data.fields, function(index, field) {
        vm.dynamicRequired[data.fieldName].push(field);
      });
    },
    applySelectAll(data) {
      var params = this.getAllServerParams();

      if (this.passedFilters) {
        params = { ...params, ...this.passedFilters };
      }

      if (this.$route.params) {
        params = { ...params, ...this.$route.params };
      }

      if (this.passedParams) {
        params = { ...params, ...this.passedParams };
      }

      if (this.$route.query) {
        params = { ...params, ...this.$route.query };
      }

      if (this.customServerParams) {
        params = { ...params, ...this.customServerParams };
      }

      if (this.rowID) {
        params['rowID'] = this.rowID;
      }

      this.service.selectAll(params).then(response => {
        var vm = this;
        vm.localSelectedRows = new Array();

        var rows = new Array();
        $.each(response['rows'], function(rowID, rowVal) {
          vm.localSelectedRows.push({ ID: rowVal.ID });

          rows.push(rowVal);
        });

        this.$emit('applySelectAll', rows);

        vm.getRows();
      });
    },
    applyDeselectAll() {
      this.localSelectedRows = new Array();
      this.getRows();

      this.$emit('applyDeselectAll');
    },
    fetchCustomFields(formType, type) {
      let rowID = this.$route.params.rowID || '';
      if (formType === 'Add') {
        // Only fetch custom fields;
        

        if(this.passedFilters && this.passedFilters.cservice=='deployment' && this.passedFilters.rowID){
            rowID=this.passedFilters.rowID;
        }

        let localPassedFilters=this.passedFilters;
        if(this.addParams){

          localPassedFilters={...localPassedFilters,...this.addParams};

        }


        customFieldService
          .get('', type, rowID, formType, localPassedFilters)
          .then(response => {
            let answers = {};

            this.customFieldCategories = response;
            for (let category of response) {
              for (let customField of category.customFields) {
                if(customField['Required']){
                  this.storeRequiredFields[customField['Name']] =customField['Name'];
                }
              }
            }

            for (let category of response) {
              if (!Array.isArray(category.customFields)) throw new Error();
              for (let customField of category.customFields) {


                

                if (
                  this.tmpSavedAnswers &&
                  this.tmpSavedAnswers[customField['Name']]
                ){
                  answers[customField['Name']] = deepCopy(
                    this.tmpSavedAnswers[customField['Name']]
                  );
                }
                else if(answers[customField['Name']]){}
                else{ 
                    answers[customField['Name']] = customField['DefaultValue'];

                }
                // if(customField['Required']){
                //   this.validationsFromFields[customField['Name']]={ required };
                // }

                if (!answers[customField['Name']]) {
                  if (this.$route.params) {
                    if (this.$route.params[customField['Name']]) {
                      answers[customField['Name']] = this.$route.params[
                        customField['Name']
                      ];
                    }
                  }

                  if (this.$route.query) {
                    if (this.$route.query[customField['Name']]) {
                      answers[customField['Name']] = this.$route.query[
                        customField['Name']
                      ];
                    }
                  }

                  if (
                    !answers[customField['Name']] &&
                    customField['DefaultValue'] === null
                  ) {
                    answers[customField['Name']] = null;
                  }
                }

                if (
                  Array.isArray(customField.Children) &&
                  customField.Children.length > 0
                ) {

                  
                  for (let child of customField.Children) {
                    let params = {
                      parent: customField,
                      child,
                      parentValue: answers[customField.Name],
                      triggerValue: child.triggerValue,
                    };
                    if (child.Command === 'showSelectedField') {
                      this.showSelectedField(params);
                    }
                    if (child.Command === 'updateToggleRequired') {
                      this.updateToggleRequired(params);
                    }
                    

                    if (child.Command === 'appendToDefault') {
                      let appendParams={
                        parent: customField,
                        child,
                        parentValue: answers[customField.Name],
                        childValue:child.Value

                      };
                       this.appendToDefault(appendParams);

                       if(this.addRowData[child.Name]){
                       


                          answers[child.Name]=deepCopy(this.addRowData[child.Name]);

                       }
                   
                   }

                      if (child.Command === 'makeRequired') {
                      this.makeFieldRequiredHandler(params);
                    }

                    if(child.Command==='updateText'){
                       let params = {
                          parent: customField,
                          child,
                          parentValue: answers[customField.Name],
                          triggerValue: child.triggerValue,
                          targetCategory: child.targetCategory
                        };


                        this.changeFieldText(params);
                    }

                    if (child.Command === 'showCategory') {
                      let params = {
                        parent: customField,
                        child,
                        parentValue: answers[customField.Name],
                        triggerValue: child.triggerValue,
                        isInit: true,
                        initialValue: answers[customField.Name],
                      };
                      this.showCategoryFunc(params);
                    }

                    if (child.Command === 'hideSelectedField') {
                      let params = {
                        parent: customField,
                        child,
                        parentValue: answers[customField.Name],
                        triggerValue: child.triggerValue,
                      };
                      this.hideSelectedField(params);
                    }

                    if (child.Command === 'fetchOptions') {
                      let params = {
                        parent: customField,
                        child,
                        parentValue: answers[customField.Name],
                        triggerValue: child.triggerValue,
                      };

                      this.addRowData=answers;
                      this.fetchFormOptions(params);
                    }

                    if(child.Command==='setPickerSelectAll'){
                           let params = {
                            parent: customField,
                            child,
                            parentValue: answers[customField.Name],
                          };

                          this.setAllPickerOptions(params);

                        }
                  }
                }

                if(category.FetchOptions && typeof customField.DataURI !=='undefined'){


                      this.addRowData=answers;

                      this.fetchLoadFormOptions(customField);
                
                }
              }

             
            }

            this.addRowData = answers;


            // Cleanup
            this.formError = '';
            this.tmpSavedAnswers = null;
          })
          .catch(error => {
            this.addRowData = {};
          });
      } else if (formType === 'Edit') {
        this.service
          .getRow(this.editRowData.ID, this.passedFilters)
          .then(response => {
            this.editRowData = response;
            this.editUpdate = new Date().getTime();

            var localPassedFilters = { ...this.passedFilters };

            if (response.ignoreFields) {
              localPassedFilters = {
                ...localPassedFilters,
                ignoreFields: response.ignoreFields,
              };
            }

            if (response.readOnlyFields) {
              localPassedFilters = {
                ...localPassedFilters,
                readOnlyFields: response.readOnlyFields,
              };
            }

            customFieldService
              .get('', type, this.editRowData.ID, formType, localPassedFilters)
              .then(res => {
                this.customFieldCategories = res;
                for (let category of res) {
                  if (!Array.isArray(category.customFields)) throw new Error();
                  for (let customField of category.customFields) {

                    if (
                      Array.isArray(customField.Children) &&
                      customField.Children.length > 0
                    ) {

                      for (let child of customField.Children) {
                        
                        let params = {
                          parent: customField,
                          child,
                          parentValue: this.editRowData[customField.Name],
                          triggerValue: child.triggerValue,
                        };
                        if (child.Command === 'showSelectedField') {
                          this.showSelectedField(params);
                        }
                        if (child.Command === 'updateToggleRequired') {
                          this.updateToggleRequired(params);
                        }
                        

                        if (child.Command === 'makeRequired') {
                          this.makeFieldRequiredHandler(params);
                        }

                        if(child.Command === 'updateText'){
                           let params = {
                          parent: customField,
                          child,
                          parentValue: this.editRowData[customField.Name],
                          triggerValue: child.triggerValue,
                          targetCategory:child.targetCategory
                        };
                          this.changeFieldText(params);

                        }

                        if (child.Command === 'showCategory') {
                        let params = {
                          parent: customField,
                          child,
                          parentValue:  this.editRowData[customField.Name],
                          triggerValue: child.triggerValue,
                          isInit:true
                          
                        };

                        this.showCategoryFunc(params);
                      }

                        if (child.Command === 'fetchOptions') {
                          let params = {
                            parent: customField,
                            child,
                            parentValue: this.editRowData[customField.Name],
                            triggerValue: child.triggerValue,
                          };


                          this.fetchFormOptions(params);
                        }
                        if(child.Command==='setPickerSelectAll'){
                           let params = {
                            parent: customField,
                            child,
                            parentValue: this.editRowData[customField.Name],
                          };

                          this.setAllPickerOptions(params);

                        }
                      }
                    }

                    // if(customField['Required']){
                    //   this.validationsFromFields[customField['Name']]={ required }
                    // }
                    if (!this.editRowData[customField['Name']]) {
                      if (customField.DefaultValue !== null) {
                        this.editRowData[customField['Name']] =
                          customField.DefaultValue;
                      } else if (
                        !this.editRowData[customField['Name']] &&
                        customField.DefaultValue === null
                      ) {
                        this.editRowData[customField['Name']] = null;
                      }
                    }

                    if(category.FetchOptions && typeof customField.DataURI !=='undefined'){



                        this.fetchLoadFormOptions(customField);

                    }
                  }
                }

                this.formError = '';
              })
              .catch(err => {
                this.editRowData = {};
              });
          })
          .catch(error => {
            this.editRowData = {};
          });
      }
    },
    emitCustomEvent(data) {
      this.$emit('customActionEvent', data);
    },
  },
};
</script>