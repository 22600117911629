// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiClient/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiClient/getClients', payload).then(getData);

export const getRow = (rowID) => http.get(`apiClient/getRow&RowID=${rowID}`).then(getData);

export const create = (row) => http.post('apiClient/createClient', row).then(getData);

export const update = (row) => http.post('apiClient/editClient&ClientID=' + row.ID, row).then(getData);

export const remove = (row) => http.post('apiClient/deleteClient', row).then(getData);

export const getLinkedFamilyDialogSettings = (payload = null) =>http.post('apiClient/getLinkedFamilyDialog',payload).then(getData);

export const getLinkedFamily = (params) =>http.post('apiClient/getLinkedFamily',params).then(getData);

export const getClientLocations = (ClientID,params) => http.post("apiClient/getLinkedLocations",{ClientID:ClientID,...params}).then(getData);

export const getClientPrograms = (ClientID,params) => http.post("apiClient/getPrograms",{ClientID:ClientID,...params}).then(getData);

export const getClientList = (ClientIDs,params) => http.post("apiClient/getClientList",{ClientIDs:ClientIDs,...params}).then(getData);

export const collectionLogin = (payload) => http.post('apiAuthentication/authenticateClientCollectionLogin', payload).then(getData);

export const getFamilyMemberDialogSettings = (payload = null) =>http.post('apiClient/getFamilyMemberDialogSettings',payload).then(getData);

export const getFamilyRelationshipText= (relationShipID) =>http.get('apiClient/getRelationshipText&relationShipID='+relationShipID).then(getData);


export const getFamilyWillingness= () =>http.get('apiClient/getWillingnessTags').then(getData);

export const getFamilyMembers= (RowID) => http.post('apiClient/getFamilyMembers',{ClientID:RowID}).then(getData);

export const getFamilyMember = (ClientID,PeopleID) => http.post('apiClient/getFamilyMember',{ClientID:ClientID,PeopleID:PeopleID}).then(getData);

export const deleteFamilyMember = (ClientID,PeopleID) => http.post('apiClient/deleteFamilyMember',{ClientID:ClientID,PeopleID:PeopleID}).then(getData);


export const getFamilyList = (FamilyIDs,params) => http.post("apiClient/getFamilyList",{FamilyIDs:FamilyIDs,...params}).then(getData);

export const getClientByNumber = (ClientNumber) => http.post("apiClient/getClientIDByNumber",{number:ClientNumber}).then(getData);

