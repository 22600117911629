// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';


export const getTableSettings = (payload = null) => http.post('apiDashboard/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiDashboard/getRows', payload).then(getData);

export const create = (row) => http.post('apiDashboard/createRow', row).then(getData);

export const update = (row) => http.put('apiDashboard/editRow&RowID=' + row.ID, row).then(getData);

export const remove = (row) => http.post('apiDashboard/deleteRow',row).then(getData);

export const getRow = (rowID) => http.get(`apiDashboard/getRow&RowID=${rowID}`).then(getData);


export const getMyDashboard = () => http.get('apiDashboard/getMyDashboard').then(getData);

export const getOrgDashboard = () => http.get('apiDashboard/getOrgDashboard').then(getData);

export const getECenterAdministrationDashboard = () => http.get('apiDashboard/getECenterAdministrationDashboard').then(getData);

export const getAdministrationDashboard = () => http.get('apiDashboard/getAdministrationDashboard').then(getData);