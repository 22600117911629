// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

const CNTLR_NAME = 'apiPbcFile';

export const getPbcSignedUrl = (payload = null) =>
  http
    .get(
      `${CNTLR_NAME}/getFile&Uid=${payload.uid}&Fid=${payload.fid}&Token=${payload.token}&Type=${payload.type}`
    )
    .then(getData);
