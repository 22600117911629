import store from '../store';
import middlewarePipeline from './middlewarePipeline';
import { mapActions } from 'vuex';
import vm from '@/main';
import {API_URL} from '@/config';
import { http } from '@/plugins/http/index';
import { toNumber } from 'lodash';



const beforeEach = (to, from, next) => {



      
     if(to.name=='logout' || to.name=='resultCollaborationLogout' || from.name=='logout' || to.name=='clientCollectionLogin' || to.name=='userCollectionLogin' || to.name=='userCollectionLoginGeneric'  || to.name=='resultCollaborationLogin' || to.name=='optOut' || to.name=='optOutUser' || to.name=='yiiLandingPage' || to.name=='yiiSureveyJSViewerLanding' || to.name=='yiiClientPage' || to.name=='locationLogin'  || to.name=='locationLogout' || to.name=='referralLogin' || to.name=='sharedgrid-logout' || to.name=='mobileLogoutPage' || to.name=='mobileLandingPage' || to.name=='mobileUserMessage' || to.name=='onlineForm' || to.name=='submittedSuccessfully' || to.name=='onlineFormLogin' || to.name=='requestResponse' || to.name=='pbcFile' ||  to.name=='user-already-loggedout'){

     
       
      
     

        return next();
   
     }else{
      if(to.name=='loginWithAutoLaunch'){
           store.commit('SET_ROUTE_PARAMS',{name:to.name,params:to.params});
      }


      store.dispatch('checkUserToken')
      .then((response) => {

        
        var unauthPages = [
          "login", 
          "loginWithAutoLaunch",
          "loginAfterPasswordReset", 
          "forgot-password", 
          "resetPassword", 
          "request-account", 
          "login-pin",
          'acceptEula',
          "mobileLandingPage",
          "mobileUserMessage",
          'clientCollectionLogin',
          'optOut',
          'onlineForm',
          'requestResponse',
          'pbcFile',

        ];

        var clientOnly=[
          'client-collection',
          'clientCollectionLogout',
          'optOut',
          'survey-start'


        ];

        var sharedGridOnly=[
          'referralLogin',
          'sharedgrid-logout',
          'shared-grid',
          'printViews'
        ];
        

        var emailLinkOnly=[
          'locationinfo',
          'optOutForm',
          'result-collaboration-form',
          'userCollectionLoginGeneric'
        ];
      

       

        if(response.isUserCollection){
          store.commit('SET_ACCEPT_EULA', true);

        }

        if(response.acceptedEula){
            store.commit('SET_ACCEPT_EULA', true);

        }

        if (response.hideNav) {
          store.commit('HIDE_NAV', true);
        }
        

        if(response.hideMyAccount){
          store.commit('SET_HIDE_MY_ACCOUNT', true);
          store.commit('HIDE_TOOLBAR', true);

        }



        if(response.onlineFormOnly && (to.name=='list' && (!to.params || !to.params.service || to.params.service !='locationEHROnlineForm'  ))){
          return next({name:'logout'});
        }else if(response.onlineFormOnly && !to.name){
          return next({name:'logout'});

        }else if(response.sharedGridOnly && !sharedGridOnly.includes(to.name) ){

            return next({name:'logout'});
        }else if(response.emailLinkOnly && !emailLinkOnly.includes(to.name)){


            return next({name:'locationLogout',params:{logoutMessage:"You are not authorized to access this page"}});

        }



        if(response==-1){

          if(sessionStorage.getItem('userCollectionLogoutStatus')){
            return next({name:'user-already-loggedout'});
          }

          if (!unauthPages.includes(to.name)) {


            return next({name:'login'});
          }
        }else if(!response.acceptedEula && !response.isUserCollection){

          if (!unauthPages.includes(to.name)) {
            return next({name:'acceptEula'});
           }

        }else if(vm.$store.getters.getIsClient && !clientOnly.includes(to.name) && !unauthPages.includes(to.name)){
          
          return next({name:'clientCollectionLogout',params:{logoutMessage:'You are not authorized to view this page'}});

        
        }else if(!to.name || unauthPages.includes(to.name)){

          
          if(to.name=="loginWithAutoLaunch"){
            if(response.redirectUrl){
              return next({path:response.redirectUrl});
            }
          }
          
        
          if (!vm.$store.getters.getIsClient && (typeof vm.$refs.mainDashboardToolbar !== 'undefined') && (vm.$refs.mainDashboardToolbar.tabs.length > 0)) {
            return next(vm.$refs.mainDashboardToolbar.tabs[0].path);
          }
        }



        if (!to.meta.middleware) {
          return next();
        }
  
       
        
        // run middleware
        const middleware = to.meta.middleware;
        const context = { to, from, next, store };
        
         return middleware[0]({
          ...context,
          nextMiddleware: middlewarePipeline(context, middleware, 1)

        });
        
        
      })
      .catch(error=>console.log(error));
     }
      
      

 
  /*
  store.dispatch('checkUserToken')
    .then(() => {

      if (!to.meta.middleware) {
        return next();
      }

      // run middleware
      const middleware = to.meta.middleware;
      const context = { to, from, next, store };
      return middleware[0]({
        ...context,
        nextMiddleware: middlewarePipeline(context, middleware, 1)
      });
    }).catch(error=>next({ name: 'auth/login' }));
    */
};

export default beforeEach;
