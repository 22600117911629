<template>
  <div class="container">
    <!-- Outer Row -->
    <div class="row justify-content-center">
      <div class="col-xl-10 col-lg-12 col-md-9">
        <div class="card o-hidden border-0 shadow my-5">
          <div class="card-body p-0">
            <!-- Nested Row within Card Body -->
            <div class="row">
              <div class="col">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">{{this.titleText || "Oops!"}}</h1>
                  </div>

                  <b-card-text class="text-center">
                    {{this.messageText || 'Looks like there was an error, please try again later'}}
                  </b-card-text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
axios.defaults.withCredentials = true;
export default {
  name: 'mobileUserMessage',
  props: ['titleText', 'messageText'],
  data () {
    return {
    };
  },
  methods: {
  }
};
</script>
