<template>

    <b-container>
      <b-row :key="refreshFamily">
        <b-col md="10">
         <div style="height:50px"></div>
          <div v-for="(linkedFamilyData,index) in linkedFamily" :key="'linkedFamilyMmeber_'+index">
              <hr v-if="index>0">
              <b-row>
                  <b-col md="11">
                    <strong>
                    {{linkedFamilyData.FirstName}} {{linkedFamilyData.LastName}}
                    </strong>
                  </b-col>
                  <b-col md="1">
                   
                    <b-dropdown 
                      text="Actions" 
                      class="align-middle m-6"
                      size="sm"
                      right
                      :toggle-class="['btn-simple', 'global-primary__bg']"
                      menu-class="w-max-content"
                    >
                      <b-dropdown-item @click="editFamilyClicked(linkedFamilyData.PeopleID,index)">{{ $t("general.edit") }}</b-dropdown-item>
                      <b-dropdown-item @click="deleteFamilyClicked(index)">{{ $t("general.delete") }}</b-dropdown-item>

                    </b-dropdown>
                  </b-col>

              </b-row>
              <b-row>
                 <b-col md="4" v-html="$t('LinkedFamilyMembers.relation', {RelationshipText:linkedFamilyData.RelationshipText})"></b-col>
                 <b-col md="3" v-html="$t('LinkedFamilyMembers.phone', {Phone:linkedFamilyData.Phone})"></b-col>
                 <b-col md="4" v-html="$t('LinkedFamilyMembers.email', {Email:linkedFamilyData.Email})"></b-col>
               
               

              </b-row>
              <b-row>
                 <b-col md="7">
                  {{ $t("LinkedFamilyMembers.willingness") }} <div style="display:inline-block" v-html="linkedFamilyData.WillingnessText"></div>

                </b-col>
                <b-col md="4">

                  {{ $t("LinkedFamilyMembers.preferred") }} <strong>{{ linkedFamilyData.PreferredContact }}</strong>
                </b-col>
               
              </b-row>


          </div>
           
        </b-col>
        <b-col md="2">
          <b-button size="sm"  class="mr-1 btn-simple global-primary__bg"  @click="openNewFamily">{{ addButtonText }}</b-button>
        </b-col>
      </b-row>
      <AddEditDialog
        v-if="renderCustomDialog"
        :settings="customDialogSettings"
        :serverParams="filterValues"
        @onSuccessCustomDialog="familyMemberAdded"
        @onCloseCustomDialog="closeLinkClientDialog"
        ref="linkedAddFamilyDialogRef"
        :key="refreshParams"

      />
     
    </b-container>
</template>
<script>
import AddEditDialog from '@/components/CustomDialog.vue';





export default {
  name: 'LinkedEventsCard',
  components: {
    AddEditDialog

  },
  data() {
    return {
      addButtonText:"Add",
      service:null,
      linkedFamily:[],
      linkedFamilyDisplay:[],
      renderCustomDialog:false,
      refreshForms:null,
      loaded:false,
      customDialogSettings:{},
      service:null,
      formSettings:{},
      refreshFamily:null,
      refreshParams:null,
      filterValues:{},
      relationShipOptions:[],
      willingnessOptions:[],
      editIndex:null,
      rowData:{}

    };
  },
  props: {
    fieldID:{type: String, default:null},
    value: {type: Array,default:function(){return new Array}},
    rowID:{type: String, default:null},
    serviceName:{type: String, default:null},
    passedFilters:{type:Object, default:function(){return {}}},

    
  },

  created() {
       


  },
  beforeMount(){
  
  },
  mounted(){

      
       this.service = require('@/services/' + this.serviceName);


      this.service.getFamilyMemberDialogSettings({CustomFieldID:this.fieldID,RowID:this.rowID})
      .then(response =>{
        this.customDialogSettings=response.formSettings;


        this.addButtonText=this.customDialogSettings.addButtonText;


      }); 

      this.filterValues=this.passedFilters;
      if(this.rowID){
        this.filterValues.RowID=this.rowID;
        //this.getLinkedFamily(this.rowID);
      }


      this.service.getFamilyMembers(this.rowID)
      .then(response =>{
          this.linkedFamily=response;

      });

          

  },
  methods: {
      deleteFamilyClicked(index){

        this.$bvModal.msgBoxConfirm('Are you sure you want to delete this family member?', {
          hideHeaderClose: true,
          centered: true,
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          okVariant: 'success',
          okTitle: 'Delete',
          cancelVariant: 'danger',
          cancelTitle: 'Cancel',
        })
        .then(value => {

          if(value){
              this.linkedFamily.splice(index,1);
              this.$emit('familyMemberAdded',this.linkedFamily);
          }
        });
       



      },
      editFamilyClicked(RowID,index){
        this.editIndex=index;
       
        this.service.getFamilyMember(this.rowID,RowID)
        .then(response=>{
              this.customDialogSettings={...this.customDialogSettings,...response};
              this.customDialogSettings.rowData.Willing=this.linkedFamily[index].Willingness;
              this.customDialogSettings.rowData.Relationship=this.linkedFamily[index].Relationship;




              
              this.refreshParams=new Date().getTime();

              this.renderCustomDialog=true;

        });

      },
      familyMemberAdded(data){


           if(this.editIndex !==null){
             this.linkedFamily[this.editIndex]={PeopleID:data.newFamilyID,FirstName:data.newFamilyFirstName,LastName:data.newFamilyLastName,Phone:data.newFamilyPhone,Email:data.newFamilyEmail,Relationship:data.newRelationship,RelationshipText:data.newRelationshipText,PreferredContact:data.newFamilyPreferredContact,Willingness:data.newFamilyWillingness,WillingnessText:data.newFamilyWillingnessText};

              this.editIndex=null;
           }else{
              this.linkedFamily.push({PeopleID:data.newFamilyID,FirstName:data.newFamilyFirstName,LastName:data.newFamilyLastName,Phone:data.newFamilyPhone,Email:data.newFamilyEmail,Relationship:data.newRelationship,RelationshipText:data.newRelationshipText,PreferredContact:data.newFamilyPreferredContact,Willingness:data.newFamilyWillingness,WillingnessText:data.newFamilyWillingnessText});
              this.$emit('familyMemberAdded',this.linkedFamily);
           }

           this.closeLinkClientDialog();
           this.refreshFamily=new Date().getTime();


      },
      unlinkFamily(index){
        this.linkedFamily.splice(index,1);
        this.$emit('familyAdded',this.linkedFamily);

        this.getLinkedFamily(null,null,true);



      },
      linkFamilyMember(data){
          this.linkedFamily.push(data.ClientID);
          this.$emit('familyAdded',this.linkedFamily);


          this.getLinkedFamily(null,true);


      },
      openNewFamily(){

         this.service.getFamilyMemberDialogSettings({CustomFieldID:this.fieldID,RowID:this.rowID})
        .then(response =>{
          this.customDialogSettings=response.formSettings;
            this.renderCustomDialog=true;
            this.refreshParams=new Date().getTime();




        }); 
      },
      closeLinkClientDialog(){
        this.renderCustomDialog=false;
      },
      getLinkedFamily(rowID,inAdd,removing){
        if(rowID){
          this.service.getLinkedFamily({RowID:rowID})
          .then(response =>{
            this.linkedFamily=response.linkedClientIDs;
            this.linkedFamilyDisplay=response.linkedClientData;

            this.filterValues.linkedFamily=this.linkedFamily;

            this.refreshFamily=new Date().getTime();
            this.refreshParams=new Date().getTime().toString() +'b';


          });

        }else{
           this.service.getLinkedFamily({clientIDs:this.linkedFamily})
          .then(response =>{
            this.linkedFamily=response.linkedClientIDs;
            this.linkedFamilyDisplay=response.linkedClientData;

             this.refreshFamily=new Date().getTime();

             if(!this.filterValues.linkedFamily){
               this.filterValues.linkedFamily=new Array;
             }

             this.filterValues.linkedFamily=this.linkedFamily;
             this.renderCustomDialog=false;

            
             this.refreshParams=new Date().getTime().toString() +'b';
              if((!inAdd || typeof inAdd==='undefined') && (!removing || typeof removing==='undefined')){
                   this.renderCustomDialog=true;
              }



          });
        }

      }


        

  
  },
};
</script>
