import auth from '@/router/middleware/auth';
import mentorGrid from './MentorGrid.vue';
import mentorGridModal from './MentorGridModal.vue';
import providerGrid from './ProviderGrid';
import providerGridModal from "./ProviderGridModal.vue";

const MentorShipModule = r => require.ensure([], () => r(require('./index')), 'mentor-bundle');

export default [
  {
    path: '/mentorship-module',
    name: 'mentorship-module',
    component: MentorShipModule,
    meta: {
      middleware: [auth]
    }
  },
      {
        path: '/mentor-grid',
        name: 'mentor-grid',
        component: mentorGrid,
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/mentor-grid-modal',
        name: 'mentor-grid-modal',
        component: mentorGridModal,
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/provider-grid-modal',
        name: 'provider-grid-modal',
        component: providerGridModal,
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/provider-grid',
        name: 'provider-grid',
        component: providerGrid,
        meta: {
          middleware: [auth]
        },
      },
    
  
];