// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';
import { getFormData } from '@/utils/networkUtils';

export const getTableSettings = (payload = null) => http.post('apiProvider/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiProvider/getRows', payload).then(getData);

export const getRow = (rowID) => http.get(`apiProvider/getRow&ID=${rowID}`).then(getData);

export const create = row => http.post('apiProvider/create', row).then(getData);

export const update = (row) => http.post('apiProvider/edit', row).then(getData);

export const remove = (row) => http.post('apiProvider/delete', row).then(getData);
