// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiSurveyCreator/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiSurveyCreator/getSurveys', payload).then(getData);

export const getSurvey = (surveyID) => http.get(`apiSurveyCreator/getSurvey&RowID=${surveyID}`).then(getData);

export const create = (row) => http.post('apiSurveyCreator/createSurvey', row).then(getData);

export const update = (rowID, data) => http.post('apiSurveyCreator/editSurvey&RowID=' + rowID, data).then(getData);

export const remove = (row) => http.post('apiSurveyCreator/deleteSurvey', row).then(getData);

export const getCustomProperties = (payload) => http.post('apiSurveyCreator/getAssessmentEditorProperties', payload).then(getData);

export const addDomain = (row) => http.post('apiSurveyCreator/createDomain', row).then(getData);

export const removePropertiesList = () => http.post('apiSurveyCreator/getPropertiesToRemove').then(getData);

export const getCollaborators = (payload = null) => http.post('apiSurveyCreator/getCollaborators', payload).then(getData);

export const addFormCollaborator = (payload) => http.post('apiSurveyCreator/addCollaborator', payload).then(getData);

export const removeFormCollaborator = (payload) => http.post('apiSurveyCreator/removeCollaborator', payload).then(getData);

export const getFormTypes = () => http.get('apiSurveyCreator/getCollectionTypes').then(getData);

export const getDomainForType = (type) => http.get(`apiSurveyCreator/getDomainForType&Type=${type}`).then(getData);

export const resetCodes = (assessmentID) => http.get(`apiSurveyCreator/resetCodes&AssessmentID=${assessmentID}`).then(getData);

export const getDomainTitle = (title) => http.post('apiSurveyCreator/getDomainTitle', title).then(getData);

export const getDomainList = () => http.post('apiSurveyCreator/getDomains').then(getData);

export const getTaxonomies = (assessmentID) => http.post(`apiSurveyCreator/getTaxonomies&assessmentID=${assessmentID}`).then(getData);