// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiSystemSettings/getSettingsTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiSystemSettings/getSettings', payload).then(getData);

export const update = (row) => http.post('apiSystemSettings/edit', row).then(getData);

export const remove = (row) => http.post('apiSystemSettings/delete', row).then(getData);

export const getRow = (row) => http.post(`apiSystemSettings/getRow&RowID=${row}`).then(getData);