<template>
	<div id='posttable'>
		<b-row>
			<b-col :md="newPostsOnly?8:8" style="padding-right:30px">
				<b-row v-if="editTitle">
				   <div style="width:100%;padding-left:20px">
					<b-form-textarea rows="2" max-rows="20" v-model="PageHeading"></b-form-textarea>
				   </div>
				   <div style="width:100%">
					   <div class="text-right" v-bind:style="{marginRight:0}" v-if="editTitle">

																	  
			 								 <b-button-group>
				  						     	<b-button variant="success" @click="updateTitle">{{ $t('general.save') }}</b-button>
				                            	<b-button variant="danger" @click="resetTitle">{{ $t('general.cancel') }}</b-button>
			                                </b-button-group>
					</div>

				   </div>
				</b-row>
				<b-row style="padding-left:20px" v-else>
				
		 				<PageHeader  v-bind:headerText="PageHeading"></PageHeader>
					 
					
				</b-row>
			</b-col>
			<b-col v-if="newPostsOnly" style="width:20%;max-width:20%;padding:0px 5px 0px 5px"></b-col>
			<b-col v-else style="width:6%;max-width:6%;padding:0px 5px 0px 5px"></b-col>
			<b-col :md="newPostsOnly?2:3" :style="{paddingLeft:'5px',maxWidth:'30%'}" class="text-right" >

						<b-row>
							<h4 style="display:inline-block" v-if="!newPostsOnly">
							<b-dropdown text="Actions"  :style="{marginRight:'20px',visibility:setTitleActionsVisible(),height:'95%',fontSize:'14px'}">
								<b-dropdown-item  v-if="isLocked" @click="toggleBoardLock(false)"><b-icon icon="unlock" ></b-icon>{{ $t("discussion-board.DiscussionBoardPosts.unlock") }}</b-dropdown-item>
								<b-dropdown-item  @click="toggleBoardLock(true)" v-else><b-icon icon="lock" ></b-icon>{{ $t("discussion-board.DiscussionBoardPosts.lock") }}</b-dropdown-item>
								<!-- <b-dropdown-item v-if="isLocked==false" @click="toggleEditTitle"><b-icon icon="pencil"></b-icon>Edit </b-dropdown-item> -->
								<!-- <b-dropdown-item @click="deleteBoard"><b-icon icon="trash"></b-icon>Delete</b-dropdown-item> -->

							</b-dropdown>
							</h4>
							<div :style="{display:'inline-block',width:newPostsOnly?'100%':'60%'}" v-if="isLocked==false">                                                   
								<b-button @click="changeVote('discussionBoard','increase',discussionBoardID)" :style="{margin:'0 auto',width:'25%',opacity:(localCastedVote=='+'?'0.2':'1')}" variant='success'  class="fas fa-thumbs-up" :disabled="localCastedVote=='+'" >

								</b-button>
										<h4 style="display:inline-block;width:30%;margin:0 auto; text-align:center">{{ localVotes }}</h4>
								<b-button @click="changeVote('discussionBoard','decrease',discussionBoardID)" :style="{margin:'0 auto',width:'25%',opacity:(localCastedVote=='-'?'0.2':'1')}" variant='danger' class="fas fa-thumbs-down" :disabled="localCastedVote=='-'"></b-button>
							</div>
							<div v-else style="padding-top:0;margin-top:-4px">
								<h3><b-badge><b-badge variant="light">{{ localVotes }}</b-badge> {{ $t("discussion-board.DiscussionBoardPosts.votes") }}</b-badge></h3>
							</div>
						</b-row>
						<b-row>
							
						</b-row>
			</b-col>
		</b-row>
		 <b-row>
			 <b-col md="9" style="padding-left:20px">
					 	<h6 v-html="$t('discussion-board.created', {createdDate:createdDate,createdUser:createdUser})"></h6>
			 </b-col>
		</b-row>
		<b-row v-if="isLocked">
			 <b-col md="9" style="padding-left:20px">
				<h6><b-badge variant="danger"><b-icon icon="lock"></b-icon> {{ $t("discussion-board.DiscussionBoardPosts.locked") }} </b-badge></h6>
			 </b-col>
		</b-row>
		<b-row style="margin-bottom:10px">
		   <b-col md="9">
			   <b-form-group>
					<b-form-input v-model="searchTerm" v-if="(typeof targetThread !=='undefined' && targetThread.length==0) || typeof targetThread ==='undefined'" :placeholder="$t('discussion-board.DiscussionBoardPosts.search')" debounce="700"></b-form-input>
			   </b-form-group>
		   </b-col>
		   <b-col v-if="searchTerm && searchTerm.length>0">
			   <b-button @click="clearSearch">{{ $t("discussion-board.DiscussionBoardPosts.reload") }}<b-icon icon="arrow-clockwise"></b-icon></b-button>
		   </b-col>
		</b-row>
		<b-row>
			
				<Comments :discussionBoardID="discussionBoardID" :titleComments="true" :locked="isLocked" v-if="(typeof targetThread !=='undefined'  && targetThread.length==0) || typeof targetThread ==='undefined'" :searchString="commentsSearch" :key="searchTime" :newPostsOnly="newPostsOnly" ref="discussionboardcomment"></Comments>
	
		
		</b-row>
		<hr v-if="posts.length>0">
		<b-card  no-body v-for="post in posts" :key="post.post.ID" style="margin-bottom:10px">
			<b-card-header :header-bg-variant="$store.state.themeDefaults.cardHeaderVariant" :header-text-variant="$store.state.themeDefaults.cardHeaderTextVariant">
				<b-row>
				  <b-col md="9">
					  <b-row v-if="editThreadID==post.post.ID">
						   <div style="width:100%;padding-left:20px">
								<b-form-textarea rows="2" max-rows="20" v-model="newThread"></b-form-textarea>
							</div>
							<div style="width:100%">
								<div class="text-right" v-bind:style="{marginRight:0}" >

																				
														<b-button-group>
															<b-button variant="success" @click="editThread(post.post)">{{ $t('general.save') }}</b-button>
															<b-button variant="danger" @click="resetThread(post.post)">{{ $t('general.cancel') }}</b-button>
														</b-button-group>
								</div>
							</div>
					  </b-row>
					  <b-row style="padding-left:20px" v-else >
					 	<h5 v-html="$t('discussion-board.DiscussionBoardPosts.posted', {Comment:post.post.Comment,Posted:post.post.Posted,DisplayName:post.post.DisplayName})"></h5>
					  </b-row>
				  </b-col>
				  <b-col md="3" v-if="isLocked==false">
					  <b-dropdown text="Actions" v-if="isLocked==false" :style="{marginRight:'20px',visibility:setThreadActionsVisible(post)}">
								<b-dropdown-item @click="toggleEditThread(post.post)"><b-icon icon="pencil"></b-icon>{{ $t("general.edit") }} </b-dropdown-item>
								<b-dropdown-item @click="deleteThread(post.post.ID)"><b-icon icon="trash" variant="danger"></b-icon>{{ $t("discussion-board.DiscussionBoardPosts.trash") }} </b-dropdown-item>

						</b-dropdown>
					  	<b-button @click="changeVote('post','increase',post.post.ID,post)"  :style="{margin:'0 auto',width:'15%',opacity:(post.castedVote=='+'?'0.2':'1')}" variant="success" class="fas fa-thumbs-up" :disabled="post.castedVote=='+'"></b-button>
									<h5 style="display:inline-block;width:20%;margin:0 auto; text-align:center">{{ post.votes }}</h5>
							<b-button @click="changeVote('post','decrease',post.post.ID,post)" :style="{margin:'0 auto',width:'15%',opacity:(post.castedVote=='-'?'0.2':'1')}" variant="danger" class="fas fa-thumbs-down" :disabled="post.castedVote=='-'"></b-button>

				  </b-col>
				<b-col md="3" v-else-if="isLocked">
					<b-badge><b-badge variant="light">{{ post.votes }}</b-badge> {{ $t("discussion-board.DiscussionBoardPosts.votes") }}</b-badge>
				</b-col>

				</b-row>
			</b-card-header>
			<b-card-body style="padding:0;padding-bottom:20px">
					<Comments :discussionBoardID="discussionBoardID" :parentPost="post.post.ID" :locked="isLocked" :searchString="commentsSearch" :key="searchTime" ref="threadcomment" :newPostsOnly="newPostsOnly"></Comments>
			</b-card-body>
	

		</b-card>
		
		<hr v-if="posts.length>0">
			<b-row>
				
				<b-col md="10" v-if="showAddThread==false && isLocked==false">
					<b-button size="sm" @click="toggleAddThread" v-if="isLocked==false && !newPostsOnly">{{ $t("discussion-board.DiscussionBoardPosts.thread") }}<b-icon icon="plus"></b-icon></b-button>
				</b-col>
			</b-row>
			<b-row>
				
				<b-col md="10" v-if="showAddThread">
						<b-form-textarea rows="5" max-rows="20" v-model="newPost"></b-form-textarea>
				</b-col>
			</b-row>
			<b-row>
				<b-col md="10">
					<div class="text-right" v-bind:style="{marginRight:0}" v-if="showAddThread">

																	  
			 								 <b-button-group>
				  						     	<b-button variant="success" @click="createPost">{{ $t('general.save') }}</b-button>
				                            	<b-button variant="danger" @click="resetPost">{{ $t('general.cancel') }}</b-button>
			                                </b-button-group>
					</div>
				</b-col>
			</b-row>

	

	
    <!-- Main table element -->
  


    
    </div>
  
</template>
<style>
	
</style>
<script>
  import lodash from "lodash";
  import * as discussionBoardService from "@/services/discussion-board";
	import { API_URL } from '@/config';
	import PageHeader from '@/components/header.vue';
    import Comments from "./DiscussionBoardPostComments";
import 'vue-good-table/dist/vue-good-table.css';
	import { VueGoodTable } from 'vue-good-table';		
  export default {
	  props: {
		selectRowURL: String,
		selectRowData: Object,
		discussionBoardID:String,
		discussionBoardSubject:String,
		votes:String,
		parentPost:String,
		targetThread:String,
		createdUser:String,
		createdDate:String,
		castedVote:String,
		newPostsOnly:Boolean

	
	},
    data() {
      return {
		   PageHeading: "",
      		      isLoading: false,
		isFiltered:false,
		userCreated:false,
		isLocked:false,
		localVotes:0,
      searchTerm:'',
        posts:[],
		initialPost:null,
		mostVotedPost:null,
      newPost:null,
       tagSearch: null,
		showButtons:false,
		fields:[
			{field:'post',sortable:false,label:'',filterOptions:{enabled:true,placeholder:"Filter By Post Content"}},
			{field:'votes',sortable:false,width:"30%",label:'',filterOptions:{enabled:false}}
		],
		totalRecords:null,
		showAddThread:false,
		editTitle:false,
		origHeading:null,
		editThreadID:false,
		newThread:null,
		searchTerm:null,
		searchIntervalHandler:null,
		changeOccurred:false,
		searchTime:null,
		localCastedVote:null
       
	  }
     
    },
    computed: { 
		buttonVisible(){
			if(this.showButtons){
				return 'visible';
			}else{
				return 'hidden';
			}
		}

     
    },
  
    mounted() {

		if(this.newPostsOnly){
			$('#newposttable').find('th.vgt-left-align').remove();
			$('#newposttable').find('.vgt-table').find('thead').css('border','none');

			$('#newposttable').find('.vgt-table').find('tbody').css('border','none');
			$('#newposttable').find('.vgt-table').find('td').css('border','none');
			$('#newposttable').find('.vgt-table').find('tr').css('border','none');

			$('#newposttable').find('.vgt-table').css('border','none');

			$('#newposttable').find('th.filter-th').eq('1').css('border',"none").css('background','#ffffff');

		}else{
			$('#posttable').find('th.vgt-left-align').remove();
			$('#posttable').find('.vgt-table').find('thead').css('border','none');

			$('#posttable').find('.vgt-table').find('tbody').css('border','none');
			$('#posttable').find('.vgt-table').find('td').css('border','none');
			$('#posttable').find('.vgt-table').find('tr').css('border','none');

			$('#posttable').find('.vgt-table').css('border','none');

			$('#posttable').find('th.filter-th').eq('1').css('border',"none").css('background','#ffffff');

		}
		
		this.PageHeading=this.discussionBoardSubject;
		this.origHeading=this.PageHeading;
		 

		this.localCastedVote=this.castedVote;
      
    },
    created() {
				this.localVotes=this.votes;
				this.getPosts();	
            		 
	
            		
				
				
		
	},
	watch:{
		searchTerm(newTerm,oldTerm){
			
				

						this.getPosts();

						var date=new Date();
						this.searchTime=date.getTime();

				
			
		}
	},
	computed:{
		commentsSearch(){
			return this.searchTerm;
		}
	},
    methods: {
		setTitleActionsVisible(){
			return this.userCreated && !this.newPostsOnly?'visible':'hidden';
		},
		setThreadActionsVisible(post){
			return post.createdByUser?'visible':'hidden';
		},
		toggleBoardLock(locked){
				discussionBoardService.updateBoardLock({
					lock:locked,
					DiscussionBoardID:this.discussionBoardID
				})
				.then(response => {
					this.isLocked=locked;
				});	
		},
		toggleAddThread(){
			this.showAddThread=true;
		},
		getPosts(titleComment=false){
			discussionBoardService.getPosts({DiscussionBoardID:this.discussionBoardID,targetThreadID:this.targetThread,searchFilter:this.searchTerm,newPostsOnly:this.newPostsOnly})
			.then(response => {
				this.posts=response.items;
				this.initialPost=response.initialPost;
				this.mostVotedPost=response.mostVoted;
				this.totalRecords=response.totalPosts;
				this.userCreated=response.isBoardCreator;
				this.isLocked=response.isLocked;


				
				this.$forceUpdate();
			});

		},
		textChange(){
				if(this.newPost && this.newPost.length>0){
					this.showButtons=true;
				}else{
					this.showButtons=false;
				}
		},
		createPost(){
			discussionBoardService.createPost({
				DiscussionBoardID:this.discussionBoardID,
				Comment:this.newPost,
				ParentPost:this.parentPost
			})
			.then(response => {
				this.showButtons=false;
				this.showAddThread=false;
				this.newPost=null;
				this.getPosts();
			});
		},
		toggleEditTitle(){
			this.editTitle=true;
		},
		updateTitle(){
				discussionBoardService.update({
					DiscussionBoardID:this.discussionBoardID,
					Title:this.PageHeading
				})
				.then(response => {
					this.editTitle=false;
					this.origHeading=this.PageHeading;
					
				});
		},
		resetTitle(){
			this.PageHeading=this.origHeading;
			this.editTitle=false;

		},
		resetPost(){
			this.newPost=null;
			this.getPosts();
		},
		changeVote(voteType,direction,ItemID,post=null){
			discussionBoardService.updateVote({
				type:voteType,
				ID:ItemID,
				increase:direction=="increase"?true:false,
				decrease:direction=="decrease"?true:false

			}).then(response => {
				   if(voteType=="discussionBoard"){
					this.localVotes=response.newVotes;

					this.localCastedVote=response.castedVote;

				   }else{
					   post.votes=response.newVotes;
					   post.castedVote=response.castedVote

				   }

				  
				   this.getPosts();


			});
		},
		toggleEditThread(thread){
			this.newThread=thread.Comment;
			this.editThreadID=thread.ID;
			
		},
		editThread(thread){
				discussionBoardService.editPost({
					PostID:thread.ID,
					Comment:this.newThread
				}).then(response => {
					thread.Comment=this.newThread;

					this.newThread=null;
					this.editThreadID=null;

				});
		},
		resetThread(thread){
			this.newThread=null;
			this.editThreadID=null;
		},
		deleteThread(threadID){
			this.$bvModal.msgBoxConfirm('Deleting this thread will also delete all associated comments are you sure you want to delete this thread?', {
				hideHeaderClose: true,
				centered: true,
				noCloseOnBackdrop: true,
				noCloseOnEsc: true
			})
			.then(value => {
			if (value) {
				discussionBoardService.deletePost({PostID:threadID})
				.then(() => this.getPosts());
			}
			});
		},
		searchPosts(){
			this.getPosts();
		},
		deleteBoard(){
			this.$bvModal.msgBoxConfirm('Are you sure you want to delete this discussion board this cannot be undone?', {
				hideHeaderClose: true,
				centered: true,
				noCloseOnBackdrop: true,
				noCloseOnEsc: true
			})
			.then(value => {
					if (value) {
						// discussionBoardService.deleteBoard({DiscussionBoardID:this.discussionBoardID})
						// .then(() => 
						// 		this.$emit('deleteBoard')

						// );
					}
			});
						
		},
		clearSearch(){
			this.searchTerm=null;
				
			this.getPosts();

			var date=new Date();
			this.searchTime=date.getTime();	
		}
	  
	
	  
	},
	  components:{
		 PageHeader,Comments,VueGoodTable
	  }

	  
    }
  
</script>