// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';
import { getFormData } from '@/utils/networkUtils';

export const getTableSettings = (payload = null) => http.post('apiDirectory/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiDirectory/getRows', payload).then(getData);

export const getRow = (rowID) => http.get(`apiDirectory/getRow&ID=${rowID}`).then(getData);

export const create = row =>
  http
    .post('apiDirectory/create', getFormData(row, ['DirectoryFile']), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(getData);

export const update = (row) => http.post('apiDirectory/edit&ID=' + row.ID, getFormData(row, ['DirectoryFile']), {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})
.then(getData);

export const remove = (row) => http.post('apiDirectory/delete', row).then(getData);