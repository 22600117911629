<template>
  <div class="menubar">
    <div class="menubar__header">
      <p v-if="title" class="menubar__title">{{ title }}</p>
    </div>
    <div v-if="Array.isArray(menuBtns) && menuBtns.length > 0">
      <b-btn
        v-for="(mBtn, i) in menuBtns"
        :key="`mBtn__${i}`"
        :variant="mBtn.variant ? mBtn.variant : ''"
        :class="mBtn.cssClass ? mBtn.cssClass : ''"
        @click="onClickMenuBtn(mBtn)"
      >
        {{ mBtn.name }}
      </b-btn>
    </div>
    <b-btn
      v-if="showOkBtn"
      class="ml-2"
      variant="success"
      @click="$emit('onClickOk')"
    >
      {{ okTitle }}
    </b-btn>
    <b-btn
      v-if="showCancelBtn"
      class="ml-2"
      variant="danger"
      @click="$emit('onClickCancel')"
    >
      {{ cancelTitle }}
    </b-btn>
  </div>
</template>
<script>
import { apiPost } from '@/services/generic';
export default {
  name: 'Menubar',
  data() {
    return {
      menuBtns: null,
    };
  },
  props: {
    cancelTitle: { type: String, default: 'Cancel' },
    okTitle: { type: String, default: 'Save' },
    showCancelBtn: { type: Boolean, default: true },
    showOkBtn: { type: Boolean, default: true },
    title: { type: String, default: '' },
    menuBtnsData: { type: Object, default: null },
  },
  created() {
    this.getMenuBtns();
  },
  methods: {
    async getMenuBtns() {
      try {
        if (this.menuBtnsData && this.menuBtnsData.url) {
          const res = await apiPost(this.menuBtnsData.url, this.menuBtnsData);
          if (res && Array.isArray(res.menuBtns) && res.menuBtns.length > 0)
            this.menuBtns = res.menuBtns;
        }
      } catch (error) {
        this.$emit('onError', error);
      }
    },
    onClickMenuBtn(btn) {
      if (btn.dialog) {
        this.$bvModal
          .msgBoxConfirm(btn.dialog.message, {
            title: btn.dialog.title ? btn.dialog.title : '',
            okVariant: btn.dialog.okVariant ? btn.dialog.okVariant : 'success',
            cancelVariant: btn.dialog.cancelVariant ? btn.dialog.cancelVariant : 'danger',
            okTitle: btn.dialog.okTitle ? btn.dialog.okTitle : 'OK',
            cancelTitle: btn.dialog.cancelTitle
              ? btn.dialog.cancelTitle
              : 'Cancel',
          })
          .then(value => {
            if (value === true) this.$emit('onClickMenuBtn', btn.action);
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.$emit('onClickMenuBtn', btn.action);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.menubar {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  &__header {
    flex-grow: 1;
  }
}
</style>
