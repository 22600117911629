// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiCategory/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiCategory/getRows&Type=event_category', payload).then(getData);

export const getRow = (rowID) => http.get(`apiCategory/getRowEventCategory&ID=${rowID}`).then(getData);

export const create = (row) => http.post('apiCategory/createEventCategory', row).then(getData);

export const update = (row) => http.post('apiCategory/editEventCategory', row).then(getData);

export const remove = (row) => http.post('apiCategory/deleteEventCategory', row).then(getData);
