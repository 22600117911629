<template>
    <b-modal
      :id="id"
      centered
      :visible="modalVisibility"
      hide-footer hide-header
      no-enforce-focus>
      <h5 v-if="header">{{header}}</h5>
      <hr />
      <ProgressBar :value="value" :max="max" />
    </b-modal>
</template>
<script>
import ProgressBar from '@/components/ProgressBar.vue';
export default {
  name: "ProgressBarModal",
  components: {
    ProgressBar
  },
  props: {
    header: { type: String, default: null },
    id: { type: String, default: null },
    max: { type: Number, default: null },
    modalVisibility: { type: Boolean, default: false },
    value: { type: Number, default: 10 },
  },
  methods: { }
}
</script>