<template>
  <FileThumbnail :fileThumbnails="adaptThumbnails(this.value)" />
</template>
<script>
import FileThumbnail from './FileThumbnail.vue';
import { deepCopy, isFile, isObjectAndNotEmpty } from '@/utils/langUtils';
export default {
  name: 'FileThumbnailAdapter',
  components: { FileThumbnail },
  data() {
    return {
      adaptedThumbnails: null,
    };
  },
  props: {
    value: { type: Array|String, default: null },
  },
  methods: {
    adaptThumbnails: function() {
      // [], File, ""
      const list = [];
      const ftns = this.value;
      if (Array.isArray(ftns)) {
        for (const ftn of ftns) {
          if (isFile(ftn)) list.push({ Name: ftn.name });
          else if (isObjectAndNotEmpty(ftn)) list.push(deepCopy(ftn))
        }
      } else if (isFile(ftns)) {
        list.push({Name: ftns.name});
      }
      return list;
    },
  },
};
</script>
