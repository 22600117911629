import auth from '@/router/middleware/auth';
const ListView = r =>
  require.ensure([], () => r(require('@/app/list-view/index')), 'list-view-bundle');

export default [
  {
    path: '/collectionSeries/:service',
    name: 'collection-series',
    component:ListView,
    meta: {
      middleware: [auth]
    }
  }
];