// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';
import { isObject } from '@/utils/langUtils';

const CNTLR_NAME = 'apiCustomField';

export const getTableSettings = (payload = null) =>
  http.post(`${CNTLR_NAME}/getAnswersTableSettings`, payload).then(getData);

export const get = (payload = null) =>
  http.post(`${CNTLR_NAME}/getAnswers`, payload).then(getData);

export const selectAll = (payload) =>http.post(`${CNTLR_NAME}/getAnswers&selectAll=1`,payload).then(getData);



