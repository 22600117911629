<template>
  <b-avatar
    :size="size"
    :class="cssClass"
    :src="imageSource"
    icon="file-earmark-image"
    variant="light"
    :rounded="rounded"
    @img-error="$emit('imgError')"
  />
</template>
<script>
import imageSourceMixin from '@/mixins/imageSourceMixin';
export default {
  name: 'Avatar',
  props: {
    cssClass: { type: String, default: null },
    size: { type: String, default: '3.2rem' },
    rounded: { type: String, default: null },
  },
  mixins: [imageSourceMixin],
  created() {
    this.getImageSource();
  },
};
</script>
