<template>
  <div class="w-100" v-if="isValidItem()">
      <ProfileModal
      :id="item.AuthorID"
      v-if="showModal && item.AuthorID"
      @onClose="showModal = false"
    />
    <b-row>
      <b-col sm ="12">
        <p class="d-block mb-0">
          <span
            v-if="item.Author"
            class="font-weight-bold"
            @click="showModal = true"
            role="button"
            >{{ item.Author }}</span> -
          <span class="text-muted text--sm"> {{ item.Added }}</span>
        </p>
        <p v-if="item.includesFiles" class="mt-1 mb-0 tag--light-green text--semi-bold text--sm">{{ item.includesFiles }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <p>{{ item.Content }}</p>
      </b-col>
    </b-row>
    <b-row v-if="item.image">
      <b-col>
        <b-img :src="`${apiURL}${item.image}`" style="height:200px"/>
      </b-col>
    </b-row>
    <b-row class="text-muted">
      <b-col xl="1" sm="2" v-if="item.Votes">
        <div><b-icon icon="hand-thumbs-up" /> {{ item.Votes }}</div>
      </b-col>
      <b-col xl="1" sm="2" v-if="item.Comments">
        <div><b-icon icon="chat" /> {{ item.Comments }}</div>
      </b-col>
    </b-row>
    <b-row v-if="Array.isArray(item.fileThumbnails)">
      <FileThumbnail :fileThumbnails="item.fileThumbnails" />
    </b-row>
  </div>
</template>
<script>
import {API_URL} from '@/config';
import ProfileModal from '@/components/ProfileModal';
import FileThumbnail from '@/components/FileThumbnail';

export default {
  name: 'DiscussionBoardPostItem',
  components: { ProfileModal, FileThumbnail },
  data() {
    return {
      apiURL: API_URL,
      showModal: false,
    }
  },
  props: {
    item: [Object, Array]
  },
  methods: {
    isValidItem() {
      const res = this.item && typeof this.item === 'object';
      return res;
    }
  },
  created() {}
};
</script>
