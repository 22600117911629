<template>

    <b-dropdown  variant="link"   ref="dropdown"     id="dropdown-form"  :class="dialogShown"  menu-class="dd__menu--xl card__border shadow" toggle-class="text-decoration-none font-weight-bold text-white text-left" :text="filter.label" :key="filterClicked">

      <b-dropdown-text class="text-small">

        <small class="font-weight-bold">{{ filter.label }}</small>
      </b-dropdown-text>
      <b-dropdown-divider></b-dropdown-divider>

     <b-dropdown-form >
            <b-row>
              <b-col md="11">
               </b-col>
               <b-col md="1">
                 <b-button variant="link" size="sm" @click="resetDropDown">{{ $t('general.close') }}</b-button>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-around flex-wrap">
            <div class="d-flex mr-1"  :key="refreshFilter">
              <b-form-group :label="$t('general.start')" class="mr-1">
                 <InputFactory  type="6" @input="startChanged($event)" v-model="localSelected.StartDate" />

              </b-form-group>
              <b-form-group :label="$t('general.end')">
                  <InputFactory  type="6" @input="endChanged($event)"  v-model="localSelected.EndDate"/>


              </b-form-group>
            </div>
      
            
  

    
          </div>
          </b-dropdown-form>
  </b-dropdown>
</template>
<style>
.filterMenu {
  width:300px !important;
  max-width: 300px !important;
  white-space: nowrap;
}
</style>
<script>
import InputFactory from '@/components/InputFactory';

export default {
  
  name: 'EcenterTableFilterDateRange',
  props: ['filter', 'filterOptions'],
  components:{InputFactory},
  data() {
    return {
      dialogOpened:false,
      filterClicked:0,
      canRender:false,
      refreshFilter:null,
      localSelected:{StartDate:null,EndDate:null}
    };
  },
 
  
  mounted() {
    if (this.filter && this.filter.selected && this.filter.selected['StartDate'])
      this.localSelected['StartDate'] = this.filter.selected['StartDate'];
    if (this.filter && this.filter.selected && this.filter.selected['EndDate'])
      this.localSelected['EndDate'] = this.filter.selected['EndDate'];
    
   
  },
  computed: {},
  watch:{
    filter:{
      deep:true,
      handler(val){
        this.filterChange();
      }

    }

  },
  methods: {
    setSelectedFilter(value){
          this.filter.selected=value;
           this.$emit('filterChange');
             this.$forceUpdate();

    },
        resetDropDown(){
      this.dropDownIsOpened=false;
      this.filterClicked++;

      if(this.filter.selected['StartDate'] || this.filter.selected['EndDate']){
        this.filterChange();
      }

            this.$forceUpdate();


    },
          dialogShown(){
          
          return this.dialogOpened?"show":'';
      },
    toggleControl(){
        this.dialogOpened=!this.dialogOpened;
        this.filterClicked++;
    },
    
    startChanged(newValue){
      if(!this.filter.selected){
        this.filter.selected=new Object;
      }

      this.filter.selected['StartDate']=newValue;


    },
    endChanged(newValue){
       if(!this.filter.selected){
        this.filter.selected=new Object;
      }
      this.filter.selected['EndDate']=newValue;

    },
    clearFilter() {
      this.filter.selected = null;
      this.localSelected={StartDate:null,EndDate:null};

       this.$forceUpdate();

    },
    toggleButtonTextVariant() {
      return (
        'text-' +
        this.$store.state.themeDefaults.eCenterTableFilterRowTextVariant
      );
    },
    filterChange() {

      if (this.$store.state.clearingFilters) return;
      if (this.$store.state.canEmitEvent) {
        this.$emit('filterChange');
      }
    },
    getSelectedFilters() {
      var filter = {};

      filter.name = this.filter.name;

      filter.selected = this.filter.selected;
      return filter;
    },
  },
};
</script>
