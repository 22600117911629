import { deepCopy } from './langUtils';

export const getAllQuestions = function(dirtyForm) {
  const form = deepCopy(dirtyForm);
  const questions = [];
  for (const page of form['pages']) {
    const pageElements = Array.isArray(page['elements'])
      ? page['elements']
      : [];
    for (const pageElement of pageElements) {
      const questionsInPage = getQuestionFromElement(pageElement);
      if (!questionsInPage) continue;
      for (const questionInPage of questionsInPage) {
        questions.push(questionInPage);
      }
    }
  }
  return questions;
};

export const getQuestionFromElement = function(element) {
  if (element['elementType'] === 'question') {
    return [element];
  } else if (element['elementType'] === 'panel') {
    const panelElements = Array.isArray(element['elements'])
      ? element['elements']
      : [];
    const questionsInPanel = [];
    for (const panelElement of panelElements) {
      const questions = getQuestionFromElement(panelElement);
      if (!questions) continue;
      for (const question of questions) {
        questionsInPanel.push(question);
      }
    }
    return questionsInPanel;
  }
  return null;
};
