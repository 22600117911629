import { API_URL } from '@/config';
import * as staffService from '@/services/staff';

export function init(Survey,vm,live =false) {
    var testEditWidget={
        name:'current-staff',
        title:'Staff Entering Info',
        renderAs:'image',
        responseData:{},
        iconName:'',
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'current-staff';
        },
        init(){
          Survey.Serializer.addClass('current-staff',[],null,'empty');

       
        },
       
        htmlTemplate:"<div style='padding-left:20px' class='current-staff'><div class='responseValue'></div></div>",
        afterRender:function(question,el){
    

          question.pdfHtml="";
          $(el).css('padding-top',"10px");
     

          if(!live && $(el).closest('#surveyContainer').length>0){
            live=true;
          }

         if(live || vm.testModel){
          if(question.value){


            
            staffService.getRow(question.value.ID?question.value.ID:question.value,{inQuestion:true})
            .then(response =>{
               $(el).find('.responseValue').append("<div><span style='font-weight:bold;color:#000000'>"+response.FirstName+" "+response.LastName+"</span></div>");
               $(el).find('.responseValue').append("<div><span style='color:#000000'>"+response.Email+"</span></div>");
               $(el).find('.responseValue').append("<div><span style='color:#000000'>"+response.LocationName+"</span></div>");


               testEditWidget.responseData[question.code]=response;

               question.value={'ID':response.ID,'responseData':response};



            });
          }else{
            staffService.getCurrentStaff({inQuestion:true})
            .then(response =>{
            
              
              question.value={'ID':response.ID,'responseData':response};
              $(el).find('.responseValue').empty();
             
               $(el).find('.responseValue').append("<div><span style='font-weight:bold;color:#000000'>"+response.FirstName+" "+response.LastName+"</span></div>");
               $(el).find('.responseValue').append("<div><span style='color:#000000'>"+response.Email+"</span></div>");
               $(el).find('.responseValue').append("<div><span style='color:#000000'>"+response.LocationName+"</span></div>");


               testEditWidget.responseData[question.code]=response;


            });
          }
        }else{
          $(el).find('.responseValue').append("<div><span style='font-weight:bold;color:#000000'>TEST MODE</span></div>");
         

        }
          
          




       


      
          
           
        },
        pdfQuestionType: "empty",
        pdfRender: function (survey, options) {

        
        

          if (options.question.getType() === "current-staff") {
            const loc = new Survey.LocalizableString(survey, true);



            if(options.question.value){
             
            let response=options.question.value.responseData;

             let displayText="<div><span style='font-weight:bold;color:#000000'>"+response.FirstName+" "+response.LastName+"</span></div>";
             displayText+="<div><span style='color:#000000'>"+response.Email+"</span></div>";
             displayText+="<div><span style='color:#000000'>"+response.LocationName+"</span></div>";

             
              loc.text = displayText;



            }else{
              loc.text ="";
            }
            options.question["locHtml"] = loc;

            if (
              options.question.renderAs === "standard" ||
              options.question.renderAs === "image"
            ) {
              options.question["renderAs"] = options.question.renderAs;
            } else options.question["renderAs"] = "auto";
            const flatHtml = options.repository.create(
              survey,
              options.question,
              options.controller,
              "html"
            );
            return new Promise(function (resolve) {
              flatHtml.generateFlats(options.point).then(function (htmlBricks) {
                options.bricks = htmlBricks;
                resolve();
              });
            });
          }
        }

      };
    //Register our widget in singleton custom widget collection

    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('current-staff')){


        Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget, "customtype");
    }
    
    
}