// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

const CNTLR_NAME = 'apiNotificationAttrToTool';

export const getTableSettings = (payload = null) => http.post('apiNotificationAttrToTool/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiNotificationAttrToTool/getRows', payload).then(getData);

export const update = (row) => http.post('apiNotificationAttr/edit', row).then(getData);

export const getRow = (rowID) => http.get(`apiNotificationAttr/getRow&ID=${rowID}`).then(getData);

export const getForm = payload =>
  http.post(`${CNTLR_NAME}/getNotificationSetting`, payload, { silent: true }).then(getData);

export const getFormAnswers = payload =>
  http
    .post(`${CNTLR_NAME}/getUserNotificationSetting`, payload, { silent: true })
    .then(getData);

export const submitAnswer = payload =>
  http
    .post(`${CNTLR_NAME}/submitUserNotificationSetting`, payload)
    .then(getData);