// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('ApiCollectionSeriesDeployment/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiCollectionSeriesDeployment/getRows', payload).then(getData);
export const getRow = (rowID) => http.get(`apiCollectionSeriesDeployment/getRow&RowID=${rowID}`).then(getData);


export const create = (row) => http.post('apiCollectionSeriesDeployment/addRow', row).then(getData);

export const update = (data) => http.post('apiCollectionSeriesDeployment/editRow', data).then(getData);

export const remove = (row) => http.post('apiCollectionSeriesDeployment/deleteRow', row).then(getData);

