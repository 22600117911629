// import vm from '@/main';

export const logged = ({ auth }) => auth.isAuthorized;

export const trainingMode = ({ auth }) => auth.trainingMode;

export const isUsingYii = ({ auth }) => auth.isUsingYii;

export const currentUser = ({ auth }) => auth.user;

export const getPrimaryStyle = state => state.primaryStyle;

export const sidebarIsCollapsed = state => state.sidebar.isCollapsed;

export const getSidebarWidth = state => state.sidebar.width;

export const getSearchParams = state => state.searchParams;

export const getAcceptedEula = ({ auth }) => auth.acceptedEula;

export const getHideNav = ({ auth }) => auth.hideNav;

export const getHideMyAccount = ({ auth }) => auth.hideMyAccount;

export const getFromMobile = ({ auth }) => auth.fromMobile;
export const getIsClient = ({auth})=> auth.isClient;
export const getDefaultFilters= state => state.defaultFilters;

export const getIsUserCollection = state => {


    return state.auth.isUserCollection;

};

export const getLoggedInCollection = ({auth}) => auth.collectionLogin;

export const getIsOnCollectionLogout = state => state.onCollectionLogout;

export const getIsOnLocationLogout = state => state.onLocationLogout;

export const getIsOnResultSetCollabLogout = state => state.onResultCollaborationLogout;

export const hideNav= state => {

    if(state.auth.acceptedEula){

        if(state.auth.isClient){

            return true;
        }else{

            return false;
        }
    }else{
        return true;
    }
}

export const hideToolbar = state => state.auth.hideToolbar;

export const getReportFilters = state =>{
    let filters={};

    
    let returnFilters={};

    if(Object.keys(state.reportFilter).length>0){
        filters=state.reportFilter;

        if(filters.startDate){
            returnFilters.startDate=filters.startDate;
        }

        if(filters.endDate){
            returnFilters.endDate=filters.endDate;

        }

        if(filters.sex){
            returnFilters.sex=filters.sex;
        }

        if(filters.clientStatus){
            returnFilters.clientStatus=filters.clientStatus;
        }

        if(filters.eliminateMissingValues){
            returnFilters.eliminateMissingValues=filters.eliminateMissingValues;
        }

        Object.keys(filters).forEach(key=>{
                if(key !='startDate' && key!='endDate' && key!='sex' && key!='clientStatus' && key !='eliminateMissingValues'){
                    returnFilters[key]=filters[key];
                }                     
                                
        });
    }

    
    return returnFilters;

};

export const getHasNotifications = state => state.globalHasNotifications;

export const getHasUpcomingEvents = state => state.globalUpcomingEvents;

export const getCriticalNotificationCount = state => state.totalCriticalNotifications;

export const getAllowRequestAccount = state => state.allowRequestAccount;

export const onlineModeOnly = state => state.auth.onlineModeOnly;

export const getHideReloadGrid = state => state.hideReloadTable;

export const getDisableReloadGrid = state => state.disableReloadGrid;

export const getNoHome = state => state.noHomePage;
export const getRouteParams = state => state.routeDetails;