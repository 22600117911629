import auth from '@/router/middleware/auth';

const Profile = r => require.ensure([], () => r(require('./index')), 'profile-bundle');

export default [
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      middleware: [auth]
    },
   
    
  },
  
];
