import administrationDashboard from './administrationDashboard/routes';
import auth from './auth/routes';
import calendar from './calendar/routes';
import collections from './collections/routes';
import customForms from './customForm/routes';
import contacts from './contacts/routes';
import adminDashboard from './adminDashboard/routes';
import dashboard from './dashboard/routes';
import discussionBoards from './discussion-board/routes';
import fileManager from './file-manager/routes';
import files from './files/routes';
import listView from './list-view/routes';
import images from './images/routes';
import mentorshipModule from './mentorship/routes';
import organization from './organization/routes'
import profile from './profile/routes';
import reporting from './reporting/routes';
import reportingModule from './report-module/routes';
import todoList from './todoList/routes';
import locationColorScheme from './locationColorScheme/routes';
import locationLogo from './locationLogo/routes';
import locationSiteName from './locationSiteName/routes';
import orgForms from './orgForms/routes'
import collectionSeries from './collection-series/routes';
import formCollabpration from './form-collaboration/routes';
import acceptEula from './acceptEula/routes';
import upcomingEvents from './upcomingEvents/routes';
import mobileLandingPage from './mobileLandingPage/routes';
import mobileLogoutPage from './mobileLogoutPage/routes';
import optOut from './anonymousSurveyOptOut/routes';
import userOptOut from './anonymousUserSurveyOptOut/routes';

import qlikReportingModule from './report-module-qlik/routes';
import reportModuleNew from './report-module-new/routes';
import printForms from './printViews/routes';
import messaging from './messaging/routes';
import yiiSurveyJS from './yiiSurveyJS/routes';
import locationInfoForm from './locationEHRInformation/routes';
import sharedGrid from './sharedGrid/routes';
import externalPages from './externalLinkPages/routes';

export default [
  ...administrationDashboard,
  ...auth,
  ...calendar,
  ...collections,
  ...customForms,
  ...contacts,
  ...adminDashboard,
  ...dashboard,
  ...discussionBoards,
  ...listView,
  ...fileManager,
  ...files,
  ...images,
  ...mentorshipModule,
  ...organization,
  ...profile,
  ...reporting,
  ...reportingModule,
  ...todoList,
  ...locationColorScheme,
  ...locationLogo,
  ...locationSiteName,
  ...orgForms,
  ...collectionSeries,
  ...formCollabpration,
  ...acceptEula,
  ...upcomingEvents,
  ...mobileLandingPage,
  ...mobileLogoutPage,
  ...optOut,
  ...userOptOut,
  ...qlikReportingModule,
  ...reportModuleNew,
  ...printForms,
  ...messaging,
  ...yiiSurveyJS,
  ...locationInfoForm,
  ...sharedGrid,
  ...externalPages
];
