<template>
    <div >

      <b-card 
        no-body 
        class="mb-1" 
        >

        <b-card-header
          class="py-3 d-flex flex-row align-items-center justify-content-between"
        >
        
        <h6 class="m-0 font-weight-bold">
          {{ $t("ReferralMappingCard.mapping") }}
        </h6>
      </b-card-header>
        
        <b-card-body>
          <div v-show="mappings.length>0">
                  <b-row>
               
                    <b-col>
                      {{ $t("ReferralMappingCard.priority") }}
                    </b-col>
                 </b-row>
                 <b-row>
                  <b-col cols="12">
                          <ChildInputFactory  
                             id='priorityQuestions'
                            :type="'MultiSelect'"
                            :showCheckHeader="false"
                            :showSelectAll="false"
                            :showEmptySelect="false"
                            tagContainerClass="border-0 p-0"
                            :options="referralPriorityQuestions"
                            :disabled="false"
                            v-model="rowData['ReferralQuestionPriorityCode']"
                            :required="'0'"
                          />
                  </b-col>
              </b-row>

          </div>
          <div class="pt-1 pb-2">
           <b-row>
              <b-col cols="12">
                {{ $t("ReferralMappingCard.responses") }}
              </b-col>

          </b-row>
          </div>
          <div v-show="mappings.length>0" >

            <div v-for="(mapping,index) in mappings" :key="'mapping_'+index" class="border-bottom pt-1 pb-2">
                 <b-row>
                   <b-col cols="11"></b-col>
                  <b-col cols="1">
                    <b-button @click="removeMapping(index)" size="sm" variant="danger" >{{ $t("general.remove") }}</b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    {{ $t("ReferralMappingCard.question") }}
                  </b-col>
                  <b-col>
                    {{ $t("ReferralMappingCard.collectionResponse") }}
                  </b-col>
                 
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-select
                        :key="questionsLoaded[index]"
                        :options="formQuestions[index]"
                        v-model="mappings[index].QuestionCode"
                        @change="questionCodeChanged(index)"

                    ></b-form-select>
                  </b-col>
                  <b-col>
                    <div v-if="showResponses(index)">

                        <ChildInputFactory  
                             :id="'responses_'+mappings[index].QuestionCode+'_'+index"
                            :type="'MultiSelect'"
                            :showCheckHeader="false"
                            :showSelectAll="false"
                            :showEmptySelect="false"
                            tagContainerClass="border-0 p-0"
                            :options="formResponses[index]"
                            :disabled="false"
                            v-model="mappings[index].QuestionResponses"
                            :required="'0'"
                            @input="answerSelected($event,index)"
                          />

                    </div>
              
                  </b-col>
                  
                </b-row>
                <b-row>
                  <b-col cols="12" class='text-center'><h6 style='text-decoration:underline'>{{ $t("ReferralMappingCard.maps") }}</h6></b-col>
                </b-row>
                <b-row>
                   <b-col>
                    {{ $t("ReferralMappingCard.location") }}
                  </b-col>
                  <b-col>
                    {{ $t("ReferralMappingCard.locationResponse") }}
                  </b-col>
                </b-row>
                <b-row>
                    <b-col>
                     <b-form-select
                        :key="questionsLoaded[index]"
                        :options="locationFields[index]"
                        v-model="mappings[index].LocationField"
                        @change="locationFieldChanged(index)"

                    ></b-form-select>
                  </b-col>
                  <b-col>
                      <div v-if="showFieldResponses(index)">

                        <ChildInputFactory  
                             :id="'responses_'+mappings[index].LocationField+'_'+index"
                            :type="'MultiSelect'"
                            :showCheckHeader="false"
                            :showSelectAll="false"
                            :showEmptySelect="false"
                            tagContainerClass="border-0 p-0"
                            :options="locationFieldResponses[index]"
                            :disabled="false"
                            v-model="mappings[index].FieldResponses"
                            :required="'0'"
                            @input="answerSelected($event,index)"
                          />

                    </div>

                  </b-col>

                </b-row>
            
               
            </div>
             <b-row>
                  <b-col cols="10"></b-col>
                  <b-col cols="2">
                    <div style="margin-top:10px" class='float-right'>
                      <b-button size="sm" variant="success" @click="addRow">{{ $t("ReferralMappingCard.add") }}</b-button>
                    </div>
                  </b-col>
                </b-row>
          </div>
         
         
        </b-card-body>
      </b-card>
    </div>
</template>
<script>
import * as collectionSeriesService from '@/services/cseries';
import * as locationService from '@/services/locationEHR';
import lodash from 'lodash';
import { deepCopy, isObject } from '@/utils/langUtils';

export default {
  name: 'ReferralMappingCard',
  components:{ChildInputFactory:()=>import('@/components/InputFactory.vue')},
  data() {
    return {
      mappings:[],
      formQuestions:[],
      formResponses:[],
      locationFields:[],
      locationFieldResponses:[],
      refreshMappings:null,
      questionsLoaded:[],
      allQuestions:[],
      allFields:[],
      referralPriorityQuestions:[],
      refreshPriorityValues:null
    };
  },
  computed:{
   
   
  },
  created(){
  collectionSeriesService.getReferralPriorityQuestions(this.rowData)
                .then(response =>{

                  this.referralPriorityQuestions=response;

                  

                });
  },
  mounted(){



      this.mappings=this.value;

     
      this.rowData['ReferralMapping']=this.mappings;

      
       



      if(!this.mappings || (this.mappings && this.mappings.length==0)){
        if(!this.mappings){
          this.mappings=new Array;
        }
        this.mappings[0]={QuestionCode:null,QuestionResponses:[],LocationField:null,FieldResponses:[]};
           if(this.rowID){
              collectionSeriesService.getMappingQuestions({AssessmentID:this.rowID})
              .then(response =>{


                this.formQuestions[0]=response;

                this.$forceUpdate();

                
              });

              locationService.getMappingFields()
              .then(response =>{
                this.locationFields[0]=response;
                this.$forceUpdate();
              });

            collectionSeriesService.getReferralPriorityQuestions(this.rowData)
                .then(response =>{

                  this.referralPriorityQuestions=response;

                  
                this.$forceUpdate();
 
                });
            }
      }else{
        var vm=this;


                collectionSeriesService.getMappingQuestions({AssessmentID:this.rowID})
              .then(response =>{
                this.allQuestions=response;


                
              });

              locationService.getMappingFields()
              .then(response =>{
                this.allFields=response;
              });


             

              var waitForFeilds=setInterval(function(){
                if(vm.allQuestions.length>0 && vm.allFields.length>0){

                  clearInterval(waitForFeilds);
                  $.each(vm.mappings,function(index,mapping){

                      vm.formQuestions[index]=vm.allQuestions;
                      vm.locationFields[index]=vm.allFields;

                            vm.$forceUpdate();

                      if(mapping.QuestionResponses){

                        collectionSeriesService.getQuestionResponses({questionCode:vm.mappings[index].QuestionCode,AssessmentID:vm.rowData.AssessmentID})
                          .then(response =>{
                              vm.formResponses[index]=response;
                                                vm.$forceUpdate();



                          });
                      }

                      if(mapping.FieldResponses){
                        locationService.getFieldResponses({fieldID:vm.mappings[index].LocationField})
                        .then(response =>{
                            vm.locationFieldResponses[index]=response;
                            vm.$forceUpdate();


                        });
                      }
                  });
                }
              },100);



      }

      
   
      


  
  },
  methods: {

    removeMapping(index){
        this.mappings.splice(index,1);

         var vm=this;




              var waitForFeilds=setInterval(function(){
                if(vm.allQuestions.length>0 && vm.allFields.length>0){

                  clearInterval(waitForFeilds);
                  $.each(vm.mappings,function(index,mapping){

                      vm.formQuestions[index]=vm.allQuestions;
                      vm.locationFields[index]=vm.allFields;

                            vm.$forceUpdate();

                      if(mapping.QuestionResponses){

                        collectionSeriesService.getQuestionResponses({questionCode:vm.mappings[index].QuestionCode,AssessmentID:vm.rowData.AssessmentID})
                          .then(response =>{
                              vm.formResponses[index]=response;
                                                vm.$forceUpdate();



                          });
                      }

                      if(mapping.FieldResponses){
                        locationService.getFieldResponses({fieldID:vm.mappings[index].LocationField})
                        .then(response =>{
                            vm.locationFieldResponses[index]=response;
                            vm.$forceUpdate();


                        });
                      }
                  });
                }
              },100);

    },
    addRow(){
      this.mappings.push({QuestionCode:null,QuestionResponses:[],LocationField:null,FieldResponses:[]});

      collectionSeriesService.getMappingQuestions({AssessmentID:this.rowData.ID})
        .then(response =>{
          this.formQuestions[this.mappings.length-1]=response;

          this.$forceUpdate();

          
        });

        locationService.getMappingFields()
        .then(response =>{
          this.locationFields[this.mappings.length-1]=response;
          this.$forceUpdate();
        });   
    },
    rowLoaded(index){
      var date=new Date().getTime();

      return this.formQuestions[index]?'question_'+index+'_'+date:null;
    },
    showResponses(index){

        if(this.mappings[index].QuestionCode && this.formResponses[index] && Array.isArray(this.formResponses[index])){
          return true;
        }else{
          return false;
        }

    },
    showFieldResponses(index){

        if(this.mappings[index].LocationField && this.locationFieldResponses[index] && Array.isArray(this.locationFieldResponses[index])){
          return true;
        }else{
          return false;
        }

    },
    updateKey(fieldID,index){
      var time=new Date().getTime();
      return fieldID+'_'+index+'_'+time;

    },
    questionCodeChanged(index){


        this.mappings[index].QuestionResponses=[];
        this.updateRowData();


        collectionSeriesService.getQuestionResponses({questionCode:this.mappings[index].QuestionCode,AssessmentID:this.rowData.AssessmentID})
        .then(response =>{
            this.formResponses[index]=response;
            this.$forceUpdate();


        });
    },
    answerSelected(data,index){
            this.updateRowData();

      this.$forceUpdate();

    },
     locationFieldChanged(index){

              this.mappings[index].FieldResponses=[];

             this.updateRowData();

        locationService.getFieldResponses({fieldID:this.mappings[index].LocationField})
        .then(response =>{
            this.locationFieldResponses[index]=response;
            this.$forceUpdate();


        });
    },
    answerSelected(data,index){
      this.updateRowData();
      this.$forceUpdate();

    },
    updateRowData(){
            this.rowData['ReferralMapping']=this.mappings;

    }


      
  },/*
  mounted() {
    permissionsService
    .get()
    .then(response => {
        this.permissions = response;
    })
    .catch(error => {
        this.permissions = {};
    });
  },
  */
  props: {
    rowData:{type:Object,default:function(){return {}}},
    value: {type: Array,default:function(){return []}},
    rowID:{type:String,default:null}


  }
}
</script>
<style>
  .form__card {
    margin-bottom: 2rem;
    background: transparent;
  }
</style>