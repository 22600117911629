<template>
  <b-card header-tag="header" footer-tag="footer" no-body class="card__border shadow mb-3">

    
    <b-card-body :class="['overflow-auto', 'form__body']" >

      <!-- <div class="text-right" style="width: 100%;">
        <b-button v-show="!isPrinting" @click="print" :variant="$store.state.themeDefaults.buttonVariant">
          <b-icon icon="file-earmark-arrow-down" aria-hidden="true"></b-icon>
          Print
        </b-button>
      </div> -->
      <div v-if="!confirmedOptedOut"> 
          <div v-if="optOutOptions">
                <div id='optOutMessage' class="w-40" style='margin:0 auto'>
                  {{ $t('anonymousSurveyOptOut.reason', {collectionTitle:collectionTitle}) }}
                </div>
                  <div>
                      <b-row>
                            <b-col md="5">
                              <b-form-group>
                                <b-form-select v-model="optOutChoosen" :options="optOutOptions" @change="optOutReasonSelected"></b-form-select>
                              </b-form-group>
                            </b-col>
                      </b-row>
                  </div>

                    <div v-show="showConfirm" id='optOutButtons' class="w-40" style='margin:0 auto'>
                        <b-row>
                            <b-col md="10">
                                <b-button @click="optOutClicked"  variant="primary">{{ $t('anonymousSurveyOptOut.confirm') }}</b-button>

                            </b-col>
                            
                        </b-row>
                    </div>


          </div>
          <div v-else>
            <div id='optOutMessage' class="w-40" style='margin:0 auto'>
              {{ $t('anonymousSurveyOptOut.please', {collectionTitle:collectionTitle}) }}
            </div>

                <div id='optOutButtons' class="w-40" style='margin:0 auto'>
                    <b-row>
                        <b-col md="10">
                            <b-button @click="optOutClicked"  variant="primary">{{ $t('anonymousSurveyOptOut.confirm') }}</b-button>

                        </b-col>
                        
                    </b-row>
                </div>
              </div>
        </div>
        <div v-else>
            <div id='optOutMessage' class="w-40" style='margin:0 auto'>
              {{ $t('anonymousSurveyOptOut.successfully', {collectionTitle:collectionTitle}) }}
            </div>
            
        </div>
    


    </b-card-body>
    <!-- <template v-slot:footer>
      <b-row v-show="!isPrinting">
        <b-col sm="2"></b-col>
        <b-col sm="8">
          <b-btn-group class="d-flex justify-content-center align-items-center">
            <b-btn v-if="isEditable" variant="success" :class="'p-3'" @click="submit">
              <div class="d-flex justify-content-center align-items-center">
                <i style="color:#fff" class="fas fa-check" :class="'mr-1'"></i>Save
              </div>
            </b-btn>
            <b-btn v-if="isEditable" variant="primary" :class="'p-3'" @click="submitClose">
              <div class="d-flex justify-content-center align-items-center">
                <i style="color:#fff" class="fas fa-check-double" :class="'mr-1'"></i>Save & Close
              </div></b-btn
            >
            <b-btn variant="danger" :class="'p-3'" @click="backToHome">
              <div class="d-flex justify-content-center align-items-center">
                <i style="color:#fff" class="fas fa-times" :class="'mr-1'"></i>Cancel
              </div></b-btn
            >
          </b-btn-group>
        </b-col>
      </b-row>
    </template> -->
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import {API_URL} from '@/config';
import * as anonCollectionService from '@/services/anonymousClientCollection';


export default {
  data() {
    return {

      collectionTitle:null,
      confirmedOptedOut:false,
      optOutOptions:[],
      showConfirm:false,
      optOutChoosen:null
    };
  },
  components: {},
  computed: {
   
  },
  created() {

    

    
    
  },
  mounted(){
      this.recursiveLogOut();
     
  },
  destroyed() { },
  methods: {
     ...mapActions(['logout']),
    recursiveLogOut(){
      this.logout().then(response => {
       if(response){
         var vm=this;

          //recursively check to see if actually logged out due to quirk of Yii
          this.$store.dispatch('checkUserToken',true)
          .then(response =>{
              if(response !== -1){
                  this.recursiveLogOut();
              }else{
                 anonCollectionService.getCollectionDetails(this.$route.params)
                  .then(response =>{
                        
                        if(response.success){

                            this.collectionTitle=response.collectionTitle;

                            if(response.alreadyOptedOut){
                                this.confirmedOptedOut=true;
                                
                            }
                        }


                  });
                 anonCollectionService.getOptOutOptions()
                  .then(response =>{
                      if(response.optOutOptions){
                          this.optOutOptions=response.optOutOptions;
                          this.$forceUpdate();
                      }
                  });
                  
              }
          });
      
       }

      });
    },
    optOutReasonSelected(){

        this.showConfirm=true;
    },
    optOutClicked(){

          var params={...this.$route.params,reason: this.optOutChoosen}
         anonCollectionService.setOptOut(params)
      .then(response =>{
           if(response.success){
               this.confirmedOptedOut=true;
           }

      });
    }
  },
};
</script>
<style>
#eulaContent li{
  list-style-position: outside;
  margin-bottom:10px;
}
#eulaContent {
      font-size: 12px;
      font-family:Verdana,Arial,sans-serif;


}
#eulaContent li p{
  width:80% !important;
}





</style>
