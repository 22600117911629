<template>
    <div >

      <b-card 
        no-body 
        class="mb-1" 
        v-for="(dashboardObj, i) in value"
        :key="`${dashboardObj.dashboardID}`"
        >

        <b-card-header
          class="py-3 d-flex flex-row align-items-center justify-content-between"
        >
        
        <h6 class="m-0 font-weight-bold">
          {{ dashboardObj.dashboardName}}
        </h6>
      </b-card-header>
        
        <b-card-body>
          <b-form-checkbox class="mb-3" v-model="dashboardObj.enabled" switch >{{$t('PermissionsCard.enable', {dashboardName:value.dashboardName})}}</b-form-checkbox>  

          <b-tabs v-if="dashboardObj.enabled" pills card vertical>
            <b-tab :title="cardObj.cardName" 
                v-for="(cardObj, j) in dashboardObj.cards"
                :key="`${cardObj.cardID}`">

              <div v-if="multipleRootPermissions(cardObj.permission)">
                    <b-form-checkbox v-for="(permission,i) in cardObj.permission" :key="permission.permissionName" class="mb-3" v-model="permission.enabled"  switch>
                      <span v-if="i==0" v-html="$t('PermissionsCard.card', {cardName:cardObj.cardName})"></span>
                      <span v-else v-html="$t('PermissionsCard.enable', {dashboardName:value.dashboardName})"></span>
                    </b-form-checkbox>  
              </div>
              <div v-else>
                 <b-form-checkbox class="mb-3" v-model="cardObj.enabled" switch >{{ $t('PermissionsCard.card', {cardName:cardObj.cardName}) }}</b-form-checkbox>  

              </div>
            

           

              
              <b-card-text v-show="showPermissionCard(cardObj)">
                <b-tabs card>
                  <b-tab :title="toolObj.toolName" 
                      v-for="(toolObj, k) in cardObj.tools"
                      :key="`${toolObj.toolID}`">

                    <b-form-checkbox class="mb-3" v-model="toolObj.enabled" switch>{{ $t('PermissionsCard.button', {toolName:toolObj.toolName}) }}</b-form-checkbox>  
                    
                    <div v-if="toolObj.enabled">
                      <div style="padding-bottom:10px" v-html="$t('PermissionsCard.permissions', {toolName:toolObj.toolName})"></div>

                      <b-card>
                        <b-card-body style="width:100%">
                          <div style="width:30%;display:inline-block" 
                            v-for="(permissionObj, l) in toolObj.permissions"
                            :key="`${permissionObj.permissionName}`">
                        
                            <b-form-checkbox switch v-model="permissionObj.enabled" required>{{ permissionObj.displayName || permissionObj.permissionName }}</b-form-checkbox>
                
                          </div>
                        </b-card-body>
                      </b-card>
                    </div>
                  </b-tab>
                </b-tabs>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-card>
    </div>
</template>
<script>
import * as permissionsService from '@/services/permissions';

export default {
  name: 'PermissionsCard',
  data() {
    return {
      
      
    };
  },
  mounted(){

  
  },
  methods: {
    multipleRootPermissions(permission){
      var is_array = $.isArray(permission);


      return is_array;

    },
    showPermissionCard(card){

      if(this.multipleRootPermissions(card.permission)){
          return card.permission[0].enabled;
      }else{

          return card.enabled;
      }

    }
      
  },/*
  mounted() {
    permissionsService
    .get()
    .then(response => {
        this.permissions = response;
    })
    .catch(error => {
        this.permissions = {};
    });
  },
  */
  props: {
    value: {type: Array},
    headerTextVariant: {type: String, default: '' },
    headerBgVariant: { type: String, default: '' },
    headerClass: { type: String, default: '' },
  }
}
</script>
<style>
  .form__card {
    margin-bottom: 2rem;
    background: transparent;
  }
</style>