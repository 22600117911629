<template>
  <b-row
    no-gutters
    class="global-primary__bg global-primary__bg--no-hover align-items-center p-2"
    style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem"
  >
    <b-col cols="2" v-if="showSelectAllRowsCheckbox">
      <div v-if="showSelectAllRowsCheckbox" class="float-left">
        <b-form-checkbox @change="selectAllChoosen($event)" :checked="setSelectAll"> Select All</b-form-checkbox>
      </div>
    </b-col>
    <b-col v-if="filterRowTitle.length>0 && (!filterOptions || (filterOptions && filterOptions.length<5))" :cols="3" :class="'font-weight-bold ' + textVariant()">
      {{ filterRowTitle }}
    </b-col>
    

    <b-col :cols="showSelectAllRowsCheckbox ? '8' : (hasFiltersApplied || hasSortApplied || globalSearch)?(filterOptions && filterOptions.length>=5)?'10':'9':(filterOptions && filterOptions.length>=5)?'10':'9'" class="text-left">
     
     
      <EcenterTableFilter
        @applyFilter="filterApplied"
        v-for="(filter, index) in filterOptions"
        ref="filters"
        :key="index"
        v-bind:filter="filter"
        v-bind:filterOptions="filterOptions"
      ></EcenterTableFilter>

      <EcenterTableSort
        v-if="sortOptions && sortOptions.length>0"
        v-bind:hasSortGroups="hasSortGroups"
        ref="sort"
        v-bind:sortOptions="sortOptions"
        @sortOptionSelected="sortApplied"
      ></EcenterTableSort>
      
      
    </b-col>
    <b-col :cols="filterRowTitle.length>0  && (!filterOptions || (filterOptions && filterOptions.length<5))?3:((filterOptions && filterOptions.length<5)?(showSelectAllRowsCheckbox?2:3):2)" class="text-right">
         <div
        style="padding-bottom:10px"
      >
        <b-button
          v-if="showUndo"
          variant="primary"
          @click="undoClear"
          :class="'font-weight-bold ' + textVariant()"
          size="sm"
          >Undo Clear<b-icon icon="check"></b-icon
        ></b-button>
      </div>
         
         <div
        style="padding-bottom:10px"
      >
        <b-button
          v-if="showApplyFilter"
          variant="success"
          @click="applyFilterClicked"
          :class="'font-weight-bold ' + textVariant()"
          size="sm"
          >Apply Filters<b-icon icon="check"></b-icon
        ></b-button>
      </div>
       <div
        
      >
      
        <b-button
          v-if="!$store.getters.getHideReloadGrid && filterOptions && filterOptions.length>0"
          variant="danger"
          @click="clearFiltersAndSort"
          :class="'font-weight-bold  ml-2 ' + textVariant()"
          size="sm"
          >Clear Filters<b-icon v-if="!showSelectAllRowsCheckbox" icon="arrow-counterclockwise"></b-icon
        ></b-button>
      </div>


    </b-col>
  </b-row>
</template>

<script>
import EcenterTableFilter from './ecenter-table-filter.vue';
import EcenterTableSort from './ecenter-table-sort.vue';

export default {
  name: 'EcenterTableFilterRow',
  props: {
    showSelectAllRowsCheckbox: Boolean,
    filterOptions: Array,
    sortOptions: Array,
    hasSortGroups: Boolean,
    globalSearch: Boolean,
    filterRowTitle: { type: String, default: '' },
    tableService:{type: String, default: ''},
    setSelectAll:{type: Boolean, default:false},
    disableFilterAutoApply:{type:Boolean, default:false},
    inPicker:{type: Boolean, default:false}
  },
  data() {
    return {
      hasFiltersApplied: false,
      hasSortApplied: false,
      sortClearing: false,
      showApplyFilter:false,
      showUndo:false,
      prevFilters:null,
      filtersCleared:[]
     
    };
  },
  mounted() {

    var testServiceName=this.$route.params.cservice?this.$route.params.cservice:this.$route.params.service;
    if(this.$route.params.prefilter && this.tableService==testServiceName){

        this.hasFiltersApplied=true;
    }
   
  },
  methods: {
    undoClear(){

      for(let FilterKey in this.prevFilters){
        let selectedValue=this.prevFilters[FilterKey];

        if(selectedValue){
            let matchingFilter=this.$refs.filters.filter(filter => filter.filter.name==FilterKey);

            if(matchingFilter){
                matchingFilter[0].$refs.filter.setSelectedFilter(selectedValue);
            }
        }


      }

      this.showUndo=false;

    },
    selectAllChoosen(data){
      if(data){
        this.$emit('selectAllClicked');
      }else{
        this.$emit('deselectAllClicked');

      }
    },
    sortApplied() {
      if (!this.sortClearing) {
        this.hasSortApplied = true;
      }
      this.$emit('sortOptionSelected');
    },
    textVariant() {
      return (
        'text-' +
        this.$store.state.themeDefaults.eCenterTableFilterRowTextVariant
      );
    },
    backgroundVariant() {
      return (
        'bg-' + this.$store.state.themeDefaults.eCenterTableFilterRowVariant
      );
    },
    clearFiltersAndSort() {
      if (this.$refs.filters) {
        this.$store.commit('UPDATE_CLEARING_FILTERS', true);
        this.$refs.filters.forEach(filter => {

          if(this.$route.params && this.$route.params.prefiltername){
                      if(filter.$refs.filter.filter.name==this.$route.params.prefiltername){
                            filter.$refs.filter.setSelectedFilter(this.$route.params.prefilter);

                      }else{
                            filter.clearFilter();


                      }
          }else{
                      filter.clearFilter();


          }

        });

        this.hasFiltersApplied = false;
      }

      if (this.$refs.sort) {
        this.$refs.sort.clearSort();
        this.hasSortApplied = false;
      }

       this.showApplyFilter=false;
       this.showUndo=false;
       this.prevFilters=null;


      if(this.setSelectAll){
         
      }

      this.$emit('clearSearch');
    },
    applyFilterClicked(){
       this.$emit('applyFilters');
       this.showApplyFilter=false;
       this.showUndo=false;
       this.prevFilters=null;
        
    },
    setPrevFilter(selectedFilters){

        if(!this.prevFilters){
          this.prevFilters=selectedFilters;
        }
    },
    setFilterCleared(filterName){
        this.filtersCleared.push(filterName);
    },

    displayUndo(){
      this.showUndo=true;
    },
    filterApplied(data) {
      var localFilterFound = false;
      if (this.$refs.filters) {
        let thisComp=this;
        this.$refs.filters.forEach(filter => {

        

          var selectedFilters = filter.getSelectedFilters();

          if (selectedFilters.selected && selectedFilters.selected.length > 0) {
            localFilterFound = true;
          }

          if(filter.filter.showIf){
                filter.filter.showIf.forEach(showIfObj => {
                 
                    let targetFilter=thisComp.$refs.filters.filter(filterObj =>{
                      return filterObj.filter.name===showIfObj.Name
                    });

                    if(targetFilter){
                      let targetSelected=targetFilter[0].getSelectedFilters();

                      if(targetSelected && targetSelected.selected){
                        if(showIfObj.triggerValue){
                          if(targetSelected.selected.includes(showIfObj.triggerValue)){
                              filter.filter.hidden=false;

                          }else{
                              filter.filter.hidden=true;

                          }

                        }else{
                            filter.filter.hidden=false;

                        }
                      }else{
                        filter.filter.hidden=true;
                      }
                    }

                });

          }
        });

        this.hasFiltersApplied = localFilterFound;
      } else {
        this.hasFiltersApplied = false;
      }

      if(this.disableFilterAutoApply){
          this.showApplyFilter=true;
      }
      if(this.showUndo){
          if(this.filtersCleared.includes(data.filterName)){
            this.showUndo=false;
          }
      }


      this.$emit('addFilter');
    },
    getSelectedFilters() {
      var filters = {};
      var selectedFilters = {};

      if (this.$refs.filters) {
        this.$refs.filters.forEach(filter => {
          selectedFilters = filter.getSelectedFilters();

          filters[selectedFilters.name] = selectedFilters.selected;
        });
      }
      return filters;
    },
    sortType() {
      return this.$refs.sort.sortType;
    },
    sortField() {
      return this.$refs.sort.sortField;
    },
  },
  components: {
    EcenterTableFilter,
    EcenterTableSort,
  },
};
</script>
