export const isEmpty = function (o) {
    if (o === undefined || o === null) {
      return true;
    } else if ((typeof o === 'string' || o instanceof String) && o.trim() === '') {
      return true;
    } else if ((Array.isArray(o) && o.length === 0)) {
      return true;
    } else if(typeof o === 'object' && Object.keys(o).length === 0) {
      return true;
    }
    return false;
}