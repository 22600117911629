<template>
  <b-pagination
    v-model="currentPage"
    :total-rows="totalRows"
    :per-page="perPage"
    aria-controls="my-table"
    align="center"
    class="m-2"
    @input="$emit('input')"
  ></b-pagination>
</template>

<script>
export default {
  name: 'EcenterTablePagination',
  props: ['perPage', 'defaultCurrentPage', 'totalRows'],
  data() {
    return {
      currentPage: this.defaultCurrentPage,
    };
  },
  methods: {},
};
</script>
