// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiPollManager/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiPollManager/getRows', payload).then(getData);

export const getRow = (rowID) => http.get(`apiPollManager/getRow&ID=${rowID}`).then(getData);

export const create = (row) => http.post('apiPollManager/create', row).then(getData);

export const update = (row) => http.post('apiPollManager/edit', row).then(getData);

export const remove = (row) => http.post('apiPollManager/delete', row).then(getData);

export const getAddRowData = () => http.get(`apiPollManager/getAddData`).then(getData);

export const auditEditForm = (row) => http.post(`apiPollManager/auditEditForm`, row).then(getData);

export const editSortOrder = row => http.post('apiPollManager/editSortOrder', row).then(getData);