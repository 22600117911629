import InputFactory from '@/components/InputFactory';
import { isObject, isStringAndNotEmpty } from '@/utils/langUtils';
import { isNumber } from 'lodash';
import Vue from 'vue';

export default function(Survey, vm, props) {

  const component = {};
  component.name = props.name;
  component.title = props.title;
  component.iconName = props.iconName;
  component.widgetIsLoaded = function() {
    return true;
  };
  component.isFit = function(question) {
    const type = props && props.type ? props.type : null;
    return question.getType() === type;
  };
  component.init = function() {
    Survey.Serializer.addClass(props.name, [], null, 'empty');
  };
  component.isDefaultRender =
    props.isDefaultRender !== undefined ? props.isDefaultRender : true;
  component.htmlTemplate =
    props.htmlTemplate !== undefined
      ? props.htmlTemplate
      : '<div>Loading field...</div>';
  component.afterRender = function(question, el) {


    const { propsData } = props;



          while (el && el.lastChild) el.removeChild(el.lastChild);
          const ComponentClass = Vue.extend(InputFactory);
          const instance = new ComponentClass({
            
            propsData: { ...propsData, value: question.value },
            parent: vm,
          });
          instance.$mount();
          instance.$on('input', data => {
           
            question.value = data;

            instance.$props.value = data;

          
          });
          $(el).attr('id',question.id);

          el.append(instance.$el);

   
  };
  component.willUnmount = function(question, el) {
    while (el && el.lastChild) el.removeChild(el.lastChild);
  };
  component.pdfQuestionType = "empty",
  component.pdfRender = function (survey, options) {
    if (options.question.getType() !== type)
      return;
    const answer = options.question.value;
    const localizableStr = new Survey.LocalizableString(survey, true);
    let answerText = "";
    if (isStringAndNotEmpty(answer) || isNumber(answer)) {
      answerText = answer;
    } else if (Array.isArray(answer)) {
      for (answer of ans) {
        const ans = answer[i];
        if (isStringAndNotEmpty(answer) || isNumber(answer)) {
          answerText += `${ans}${i < answer.length - 1 ? ',' : ''}`;
        } else if (isObject(ans, true) && isObject(ans.text, true)) {
          // is list view
          answerText += "<p>";
          for (const col in ans.text) {
            answerText += `<span>${ans.text[col]}</span>`;
          }
          answerText += "</p>";
        }
      }
    }
    localizableStr.text = answerText;
    options.question['locHtml'] = localizableStr;
    if (options.question.renderAs === "standard" || options.question.renderAs === "image") {
      options.question["renderAs"] = options.question.renderAs;
    } else {
      options.question["renderAs"] = "auto";
    }
    const flatHtml = options.repository.create(survey, options.question, options.controller, "html");
    return new Promise(function (resolve) {
      flatHtml.generateFlats(options.point).then(function (htmlBricks) {
        options.bricks = htmlBricks;
        resolve();
      });
    });
  }
  if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName(props.name)) {
    Survey.CustomWidgetCollection.Instance.addCustomWidget(component, "customtype");
  }
}
