<template>

<div :id='getContainerID' style="width:100%">
    	<b-row v-if="mostVotedPost && comments.length>1">
           <b-col md="1"></b-col>
		   <b-col md="8" style="padding-top:5px">
				<h6 style="margin-left:10px">{{ $t("discussion-board.DiscussionBoardPostComments.voted") }}</h6>
				
			</b-col>
		<b-col md="1"></b-col>
		<b-col md="2" style="padding-left:3px">
			
				
		</b-col>
    	</b-row> 
	<b-row v-if="mostVotedPost && comments.length>1">
		<b-col md="1"></b-col>
		<b-col md="8" style="padding-top:5px">
			<b-jumbotron style="padding:3px;margin-left:10px">
				{{ mostVotedPost.Comment }}
			</b-jumbotron>
		</b-col>
		<b-col md="1"></b-col>
		<b-col md="2" style="padding-left:20px">
				<h5><b-badge><b-badge variant="light">{{ mostVotedPost.Votes }}</b-badge> {{ $t("discussion-board.votes") }}</b-badge></h5>
		</b-col>
	</b-row>
	 <b-row>
	<b-col md="1">		 
	</b-col>
	<b-col md="11">
			<vue-good-table v-if="comments.length>0"
						mode="remote"
					:pagination-options="{
							enabled: false
						}"
						
						:totalRows="totalRecords"
						:isLoading.sync="isLoading"
						:select-options="{ enabled: (typeof selectRowURL !== 'undefined') }"
					:columns="fields"
					:rows="comments"
					styleClass="vgt-table"
					>
					<template slot="emptystate">
						<span></span>
					</template>
					<template slot="table-row" slot-scope="props">
								<div style="width:100%"  v-if="props.column.field=='post'">
									<b-row>
		                                    <b-col md="8" style="padding-right:0">
												<p v-html="$t('discussion-board.DiscussionBoardPostComments.posted', {Posted:props.row.post.Posted,DisplayName:props.row.post.DisplayName})"></p>
											</b-col>
											<div style="display:inline-block;width:6%"></div>
											<b-col md="3" style="padding-left:0">
												
											</b-col>
											

									</b-row>
									<b-row>
											<b-col md="8" style="padding-right:0">
														  <b-row v-if="editCommentID==props.row.post.ID">
									`							<div style="width:100%;padding-left:20px">
																		<b-form-textarea rows="2" max-rows="20" v-model="newComment"></b-form-textarea>
																	</div>
																	<div style="width:100%">
																		<div class="text-right" v-bind:style="{marginRight:0}" >

																														
																								<b-button-group>
																									<b-button variant="success" @click="editComment(props.row.post)">{{ $t('general.save') }}</b-button>
																									<b-button variant="danger" @click="resetComment(props.row.post)">{{ $t('general.cancel') }}</b-button>
																								</b-button-group>
																		</div>
																	</div>
															</b-row>
															<b-row style="padding-left:20px" v-else >
																<p>{{ props.row.post.Comment }}</p>

															</b-row>
											</b-col>
											<div style="display:inline-block;width:8%"></div>
											<b-col md="3" style="padding-left:0" v-if="locked==false">
												<b-dropdown text="Actions" v-bind:style="{marginRight:'30px',visibility:setCommentVisible(props.row)}" size="sm" >
													<b-dropdown-item @click="toggleEditComment(props.row.post)"><b-icon icon="pencil"></b-icon>{{ $t("general.edit") }} </b-dropdown-item>
													<b-dropdown-item @click="deleteComment(props.row.post.ID)"><b-icon icon="trash" variant="danger"></b-icon>{{ $t("general.delete") }} </b-dropdown-item>
												</b-dropdown>
													<b-button @click="changeVote('post','increase',props.row.post.ID,props.row)"  :style="{margin:'0 auto',width:'15%',opacity:(props.row.castedVote=='+'?'0.2':'1')}" variant='success' size="sm" class="fas fa-thumbs-up" :disabled="props.row.castedVote=='+'"></b-button>
														<div style="display:inline-block;width:20%; text-align:center;font-weight:bold">{{ props.row.votes }}</div>
													<b-button @click="changeVote('post','decrease',props.row.post.ID,props.row)" :style="{margin:'0 auto',width:'15%',opacity:(props.row.castedVote=='-'?'0.2':'1')}" variant="danger" size="sm"  class="fas fa-thumbs-down" :disabled="props.row.castedVote=='-'"></b-button>
											</b-col>
											<b-col md="3" style="padding-left:0" v-else-if="locked">
												<div style="width:1%;display:inline-block"></div>
												<b-dropdown text="Actions" v-bind:style="{marginRight:'30px',visibility:'hidden'}" size="sm" >
													<b-dropdown-item @click="toggleEditComment(props.row.post)"><b-icon icon="pencil"></b-icon>{{ $t("general.edit") }} </b-dropdown-item>
													<b-dropdown-item @click="deleteComment(props.row.post.ID)"><b-icon icon="trash" variant="danger"></b-icon>{{ $t("general.delete") }} </b-dropdown-item>
												</b-dropdown>
												<h6 style="display:inline-block"><b-badge><b-badge variant="light">{{ props.row.votes }}</b-badge> {{ $t("discussion-board.votes") }}</b-badge></h6>
											</b-col>

									</b-row>
								
								</div>
								
						
					</template>


			</vue-good-table>
	   </b-col>
	 </b-row>
    <!-- Main table element -->
  
	<hr>
			<b-row  >
				<b-col md="1">
				</b-col>
				<b-col md="11" v-if="showAddComment==false && locked==false && !newPostsOnly">
					<b-button size="sm" @click="toggleAddComment">{{ $t("discussion-board.DiscussionBoardPostComments.add") }}<b-icon icon="plus"></b-icon></b-button>
				</b-col>
			</b-row>
			<b-row>
				<b-col md="1"></b-col>
				<b-col md="11"  v-if="showAddComment">
						<b-form-textarea rows="5" max-rows="20" v-model="newComment"></b-form-textarea>
				</b-col>
			</b-row>
			<b-row>
				<b-col md="1">
				</b-col>
				<b-col md="11" >
					<div class="text-right" v-bind:style="{marginRight:0}" v-if="showAddComment">

																	  
			 								 <b-button-group>
				  						     	<b-button variant="success" @click="createPost">{{ $t('general.save') }}</b-button>
				                            	<b-button variant="danger" @click="resetPost">{{ $t('general.cancel') }}</b-button>
			                                </b-button-group>
					</div>
				</b-col>
			</b-row>
    

</div>
</template>

<script>
  import lodash from "lodash";
  import * as discussionBoardService from "@/services/discussion-board";
	import { API_URL } from '@/config';
	import nextComment from './DiscussionBoardPostComments'
	import { VueGoodTable } from 'vue-good-table';		

  export default {
	  name:"comments",
	  props: {
		selectRowURL: String,
		selectRowData: Object,
		discussionBoardID:String,
		discussionBoardSubject:String,
		votes:String,
		parentPost:String,
		indent:String,
		titleComments:Boolean,
		locked:Boolean,
		searchString:String,
		newPostsOnly:Boolean

	
	},
    data() {
      return {
		   PageHeading: "",
      		      isLoading: false,
		newComment:null,
		comments:[],
		showAddComment:false,
		showButtons:false,
		mostVotedPost:null,
		editCommentID:null,
        fields:[
			{field:'post',sortable:false,label:'',filterOptions:{enabled:false,placeholder:"Filter By Post Content"}},
		],
				totalRecords:null,
				hasComments:false

	  }
     
    },
    computed: { 

		getContainerID(){
				if(this.titleComments && this.newPostsOnly){
					return "commentTable"+this.discussionBoardID;
					

				}else if(this.titleComments){
					return "commentTable";
				}else{
					return "commentTable"+this.parentPost;
				}
		},
		buttonVisible(){
			if(this.showButtons){
				return 'visible';
			}else{
				return 'hidden';
			}
		},
		indentVal(){
			return this.indent+1;
		}

     
    },
  
    mounted() {


		var observer = new MutationObserver(this.newSpanAdded);

		var targetNode=null;

			if(this.titleComments && this.newPostsOnly){
				targetNode = $('#commentTable'+this.discussionBoardID)[0];
			}else if(this.titleComments){
				targetNode = $('#commentTable')[0];
			}else{
				targetNode = $('#commentTable'+this.parentPost)[0];

			}
			observer.observe(targetNode, { childList: true, subtree: true });
		
      
    },
    created() {
				
            		 

            	this.getPosts();			
				
				
		
	},
	
			


    methods: {
		setCommentVisible(comment){
			if(comment.createdByUser==true){
				return 'visible';
			}else{
				return 'hidden';
			}

		},
		 newSpanAdded(records){
		
			 		if(this.titleComments && this.newPostsOnly){
						$('#commentTable'+this.discussionBoardID).find('th.vgt-left-align').remove();
						$('#commentTable'+this.discussionBoardID).find('.vgt-table').find('thead').css('border','none');

						$('#commentTable'+this.discussionBoardID).find('.vgt-table').find('tbody').css('border','none');
						$('#commentTable'+this.discussionBoardID).find('.vgt-table').find('td').css('border','none');
						$('#commentTable'+this.discussionBoardID).find('.vgt-table').find('tr').css('border','none');

						$('#commentTable'+this.discussionBoardID).find('.vgt-table').css('border','none');

						
					 }else if(this.titleComments){
						$('#commentTable').find('th.vgt-left-align').remove();
						$('#commentTable').find('.vgt-table').find('thead').css('border','none');

						$('#commentTable').find('.vgt-table').find('tbody').css('border','none');
						$('#commentTable').find('.vgt-table').find('td').css('border','none');
						$('#commentTable').find('.vgt-table').find('tr').css('border','none');

						$('#commentTable').find('.vgt-table').css('border','none');

						$('#commentTable').find('th.filter-th').eq('1').css('border',"none").css('background','#ffffff');
					
					}else{
						$('#commentTable'+this.parentPost).find('th.vgt-left-align').remove();
						$('#commentTable'+this.parentPost).find('.vgt-table').find('thead').css('border','none');

						$('#commentTable'+this.parentPost).find('.vgt-table').find('tbody').css('border','none');
						$('#commentTable'+this.parentPost).find('.vgt-table').find('td').css('border','none');
						$('#commentTable'+this.parentPost).find('.vgt-table').find('tr').css('border','none');

						$('#commentTable'+this.parentPost).find('.vgt-table').css('border','none');

						$('#commentTable'+this.parentPost).find('th.filter-th').eq('1').css('border',"none").css('background','#ffffff');
					 }
		},
		getPosts(){

			
			discussionBoardService.getPosts({DiscussionBoardID:this.discussionBoardID,ParentPost:this.parentPost,titleComments:this.titleComments,searchFilter:this.searchString,newPostsOnly:this.newPostsOnly})
			.then(response => {

				this.comments=response.items;
				this.totalRecords=response.totalPosts;
				this.hasComments=response.hasComments;

				this.mostVotedPost=response.mostVoted;

				
			});
			

		},
		textChange(){
				if(this.newComment && this.newComment.length>0){
					this.showButtons=true;
				}else{
					this.showButtons=false;
				}
		},
		createPost(){
			discussionBoardService.createPost({
				DiscussionBoardID:this.discussionBoardID,
				Comment:this.newComment,
				ParentPost:this.parentPost,
				titleComments:this.titleComments
			})
			.then(response => {
				this.showButtons=false;
				this.showAddComment=false;
				this.newComment=null;
				this.getPosts();
			});
		},
		resetPost(){
			this.newComment=null;
			this.showAddComment=false;
			this.showButtons=false;
			this.getPosts();
		},
		changeVote(voteType,direction,ItemID,post=null){
			discussionBoardService.updateVote({
				type:voteType,
				ID:ItemID,
				increase:direction=="increase"?true:false,
				decrease:direction=="decrease"?true:false

			}).then(response => {
				   if(voteType=="discussionBoard"){
					this.localVotes=response.newVotes;
				   }else{
					   post.votes=response.newVotes;

					   post.castedVote=response.castedVote;

				   }



					this.getPosts();

			});
		},
		toggleAddComment(){
			this.showAddComment=true;
		},
		toggleEditComment(comment){
			this.newComment=comment.Comment;
			this.editCommentID=comment.ID;


		},
		editComment(comment){
			discussionBoardService.editPost({
				PostID:comment.ID,
				Comment:this.newComment
			})
			.then(response => {
				 comment.Comment=this.newComment;

				this.newComment=null;
				this.editCommentID=null;

				this.getPosts();
			});
		},
		resetComment(comment){
			this.newComment=null;
			this.editCommentID=null;

		},
		deleteComment(commentID){
			this.$bvModal.msgBoxConfirm('Are you sure you want to delete this comment?', {
				hideHeaderClose: true,
				centered: true,
				noCloseOnBackdrop: true,
				noCloseOnEsc: true
			})
			.then(value => {
			if (value) {
				discussionBoardService.deletePost({PostID:commentID})
				.then(() => this.getPosts());
			}
			});
		}
		
	  
	  
	
	  
	},
	  components:{
		  'comments':nextComment,
		  VueGoodTable
	  }

	  
    }
  
</script>