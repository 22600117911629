import auth from '@/router/middleware/auth';

const Contacts = r => require.ensure([], () => r(require('./index')), 'contacts-bundle');

export default [
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
    meta: {
      middleware: [auth]
    }
  }
];
