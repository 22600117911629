import { API_URL } from '@/config';
import Vue from 'vue'
import FormInstructionsPane from '@/components/FormInstructionsPane'


export function init(Survey,vm,live =false) {
    var testEditWidget={
        name:'custom-collection-instructions',
        title:'Collection Instructions',
        iconName:'',
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'custom-collection-instructions';
        },
        init(){
          Survey.Serializer.addClass('custom-collection-instructions',[],null,'empty');

       
        },
        htmlTemplate:"<div style='padding-left:20px' class='custom-collection-instructions'></div></div>",
        afterRender:function(question,el){

               $(el).css('padding-top',"10px");
               
     

            setTimeout(function(){

               var ComponentClass = Vue.extend(FormInstructionsPane)
              var instance = new ComponentClass({
                 propsData:{
                   instructions:question.Instructions,
                   buttonText:question.ButtonText?question.ButtonText:'Instructions'
                 }
              });

               var ref='instructions'+question.code
                $(el).attr('ref',ref);
    
                
                
                
                instance.$mount();
        
         

             
 
             $(el).append(instance.$el);

               question.titleLocation='hidden'
              question.domain=vm.instructionsDomain;
              


              question.registerFunctionOnPropertyValueChanged("Instructions",
              function () {
    
                  instance.instructions=question.Instructions;



    
            
              });
              question.registerFunctionOnPropertyValueChanged("ButtonText",
              function () {
    
                instance.buttonText=question.ButtonText;

    
            
              });

          
            },100);
        }
      };
    //Register our widget in singleton custom widget collection

    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('custom-collection-instructions')){
        Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget, "customtype");
    }
    
}