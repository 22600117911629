<template>

    <div  class="container-fluid bg-white">



         <b-row :key="refreshForms" v-if="doneLoading()">
             <b-col  md="9" >
                <b-row  v-for="(linkedForm,index) in linkedFormRows" :key="'dynamicRow_'+index">
                
                  <b-col 
                   v-for="(field,fieldIndex) in linkedForm.fields" :key="'dynamicRow_'+index+'_field_'+fieldIndex" 
                   :md="field.BCol?field.BCol:''"              
                   v-show="showColumn(field) && checkifvisible(field,index)">
                        <ChildLabelTooltip
                          :iconID="`${field.Name}_tt`"
                          :label="field.Text"
                          :labelID="`${field.Name}_label`"
                          :required="field.Required"
                          :showTooltip="field.HelpText && field.HelpText !== ''"
                          :tooltipText="field.HelpText"
                        />

                        <ChildInputFactory
                          :id="field.Name+'_'+index"
                          :type="field.Type"
                          :showCheckHeader="false"
                          :showSelectAll="false"
                          tagContainerClass="border-0 p-0"
                          :required="field.Required ? '1' : '0'"
                          :options="field.Options"
                          :disabled="field.DisableEdit"
                          v-model="linkedForms[index][field.Name]"
                          @input="rowValueUpdated"
                          :lvParams ="field.ListParams"
                        />
                  </b-col>
                  <b-col md="12">
                    <b-button size="sm" @click="removeRow(index)" variant="danger">{{ $t("general.remove") }}</b-button>
                  </b-col>

                </b-row>
             </b-col>
             <b-col  md="3">
               <b-dropdown text="Select Form Type To Add" size="sm">
                  <b-dropdown-item  v-for="(type,index) in assessmentTypes" :key="'assessmentType_'+index" @click="addForm(type.value)">{{ type.text }}</b-dropdown-item>
               </b-dropdown>
             </b-col>

         </b-row>
         <b-row v-else>
           <b-col md="9">
           </b-col>
           <b-col md="3">
              <b-dropdown text="Select Form Type To Add" size="sm">
                  <b-dropdown-item  v-for="(type,index) in assessmentTypes" :key="'assessmentType_'+index" @click="addForm(type.value)">{{ type.text }}</b-dropdown-item>
               </b-dropdown>
           </b-col>
         </b-row>
 
       
    </div>
</template>
<script>

import { BCollapse } from 'bootstrap-vue';
import Label from './Label.vue';
import { ErrorMessages } from '@/utils/errorMessages.js';
import { isEmpty } from '@/utils/isEmpty.js';
import FormCard from '@/components/FormCard';
import * as deploymentService from '@/services/deployment';



export default {
  name: 'LinkedEventsCard',
  components: {
    FormCard,
    ChildInputFactory:()=>import('@/components/InputFactory.vue'),
    ChildLabelTooltip:()=>import('@/components/LabelTooltip.vue')

  },
  data() {
    return {
      
      service:null,
      linkedForms:[],
      linkedFormRows:[],
      assessmentTypes:[],
      refreshForms:null,
      loaded:false

    };
  },
  props: {
    value: {type: Array,default:function(){return new Array}},
    rowID:{type: String, default:null},
    serviceName:{type: String, default:null},
    passedFilters:{type:Object, default:function(){return {}}}
  

    
  },
  created() {
       

  },
  beforeMount(){
        this.linkedForms=new Array
        this.linkedFormRows=new Array;
  },
  mounted(){

   


              
            deploymentService.getAvailableFormTypes(this.$route.params)
              .then(response =>{
                  this.assessmentTypes=response;

              });


              if(this.rowID){
                  deploymentService.getDynamicRows(this.rowID,this.$route.params)
                  .then(response =>{
                    var vm=this;

                      var dynamicRequired=new Array;
                      
                      $.each(response,function(rowIndex,row){

                          vm.linkedFormRows.push(row);
                          if(typeof vm.value[rowIndex] !=='undefined'){
                            vm.linkedForms[rowIndex]=vm.value[rowIndex];

                          }else{
                            vm.linkedForms[rowIndex]=new Object;
                          }

                          $.each(row.fields,function(index,field){
                            if(field.Required){
                              dynamicRequired.push((field.Name+'_'+rowIndex));
                            }
                          });

                         

                      });


                      this.loaded=true;

                      if(dynamicRequired.length>0){
                          this.$emit('dynamicRequiredUpdate',dynamicRequired);

                      }


                  });
              }else{
                if(this.passedFilters && this.passedFilters.AssessmentID){
                    deploymentService.getInitialForms(this.passedFilters)
                    .then(response =>{
                                   var vm=this;

                      var dynamicRequired=new Array;
                      
                      $.each(response,function(rowIndex,row){

                          vm.linkedFormRows.push(row);
                            vm.linkedForms[rowIndex]=new Object;
                          

                          $.each(row.fields,function(index,field){
                            if(field.Required){
                              dynamicRequired.push((field.Name+'_'+rowIndex));
                            }

                            if(field.DefaultValue){
                               vm.linkedForms[rowIndex][field.Name]=field.DefaultValue;
                            }else{
                                 vm.linkedForms[rowIndex][field.Name]=null;

                            }
                          });

                         

                      });

                       this.loaded=true;

                        
                        this.$emit('formAdded',{data:this.linkedForms,requiredFields:dynamicRequired});

                    });
                }else{
                    this.loaded=true;
                }
                
              }

      
     //this.getFields();

  },
  methods: {

    doneLoading(){
        return  this.linkedFormRows.length>0 && this.loaded;
    },
    removeRow(rowIndex){
        this.linkedFormRows.splice(rowIndex,1);
        this.linkedForms.splice(rowIndex,1);

        this.refreshForms=new Date().getDate();
        this.$emit('updateRowValues',this.linkedForms);


    },
    rowValueUpdated(){

        var vm=this;
        $.each(this.linkedFormRows,function(rowID,row){
            $.each(row.fields,function(index,field){
                vm.checkifvisible(field,rowID);
            });
        });


        this.refreshForms=new Date().getTime();

        this.$emit('updateRowValues',this.linkedForms);
    },
    addForm(type){

      var params=this.$route.params;

      if(this.passedFilters){
          params={...params,...this.passedFilters};

      }

      deploymentService.addDynamicRow(this.rowID,type,'add',params)
      .then(response =>{
           
           var currentIndex=Object.keys(this.linkedForms).length>0?Object.keys(this.linkedForms).length:0;
           var requiredFieldsToAdd=new Array();
            var vm=this;
              this.linkedForms[currentIndex]=new Object;

            $.each(response.fields,function(key,value){
              
                vm.linkedForms[currentIndex][value.Name]=null;

                if(value.DefaultValue){
                    vm.linkedForms[currentIndex][value.Name]=value.DefaultValue;
                }

                if(value.Required){
                  requiredFieldsToAdd.push((value.Name+"_"+currentIndex));
                }
            })


            this.linkedFormRows.push(response);
           this.refreshForms=new Date().getDate();

          
           this.$emit('formAdded',{data:this.linkedForms,requiredFields:requiredFieldsToAdd});

      });
    },
     showColumn(field) {
      // if (this.formType === 'Edit' && field.Type === 'FilePicker') {
      //   return false;
      // }

      return field.Hidden ? false : true;
    },
    checkifvisible(field,rowIndex){
      var vm=this;

      if(field.DisplayFieldCondition){
        if(field.DisplayFieldCondition['value']){


            if(Array.isArray(field.DisplayFieldCondition['value'])){
           
            
              var hasValue=false;
              $.each(JSON.parse(JSON.stringify(field.DisplayFieldCondition['value'])),function(key,value){
                 
                  if(String(value).valueOf()==String(vm.linkedForms[rowIndex][field.DisplayFieldCondition['field']]).valueOf()){
                      hasValue=true;
                  }
                 
              });

              return hasValue;

            }else{
              return this.linkedForms[rowIndex][field.DisplayFieldCondition['field']]==field.DisplayFieldCondition['value'];
            }
        }else{

          return this.linkedForms[rowIndex][field.DisplayFieldCondition['field']];
        }
      }else{
                     

        return true;
      }
    },
     

  
  },
};
</script>
