<!--based of of SurveyBuilder.vue-->
<template>
   <div id="surveyContainer" class="m0 p0" >
        <div class="d-flex w-100">
          <div
            :class="'d-flex w-40'"
            :style="{ marginLeft: '90%' }"
          >
            <b-button
              class="ml-2 global-primary__bg btn-secondary btn-md"
              @click="launchCollaborator"
              v-if="(dialogSettings && dialogSettings.showCollaborators) || showCollaborators"
              >Collaborators</b-button
            >
          </div>
        </div>
        <b-alert fade v-if="errorMessage" variant="danger" show>{{errorMessage}}</b-alert>
        <!-- <div id="surveyContainer" class="m0 p0"></div> -->
        <ResultsCollaborationModal
              class="m0 p0"
              v-if="renderCollaboratorDialog"
              :ResultSetID="resultSetID"
              :ResultSetType="assessmentType"
              ref="resultsCollaborationRef"
              :key="refreshCollabParams"
              @resultCollaboratorsClosed="collaboratorClosed"
        ></ResultsCollaborationModal>
        <SurveyComp id="" v-if="survey" :survey="survey"></SurveyComp>


          <QuestionCustomDialog
              class="m0 p0"
              v-if="renderCustomDialog"
              :formSettings="customDialogSettings"
              @linkQuestionValue="closeQuestionDialog"
              @unlinkQuestionValue="closeQuestionDialog"
              @onCloseCustomDialog="closeQuestionDialog"
              ref="questionCustomDialogRef"
              :key="refreshParams"
        ></QuestionCustomDialog>

   </div>            
</template>

<script>

//import * as SurveyCore from 'survey-knockout';
import * as SurveyCore from "survey-core";
import  * as Survey from "survey-vue";



import 'survey-core/defaultV2.min.css';


import * as assessmentService from '@/services/assessment.js';
import * as formService from '@/services/forms';
import * as sessionService from '@/services/session.js'
import { init as customDateWidget} from "@/components/customCollectionWidgets/customdatepicker"
import { init as customRateWidget} from "@/components/customCollectionWidgets/customratingquestion"
import { init as customStaffListWidget} from "@/components/customCollectionWidgets/customstafflistquestion"
import { init as customCurrentUserWidget} from '@/components/customCollectionWidgets/customCurrentStaff'
import { init as customClientLocationCurrent} from '@/components/customCollectionWidgets/customClientCurrentLocation'
import { init as customClientLocationHistory} from '@/components/customCollectionWidgets/customClientLocationHistory'
import { init as customClientProgramCurrent} from '@/components/customCollectionWidgets/customClientPrograms'
import { init as customLocationList} from '@/components/customCollectionWidgets/customlocationlistquestion'
import { init as customClientList} from '@/components/customCollectionWidgets/customclientlistquestion'
import { init as customClientReferralDenialList} from '@/components/customCollectionWidgets/customClientReferralDenial'
import { init as customCollectionInstructions} from '@/components/customCollectionWidgets/customCollectionInstructions'
import { init as customFamilyWidget} from '@/components/customCollectionWidgets/customfamilylistquestion';
import { init as customStaffListWidgetMul} from '@/components/customCollectionWidgets/customstafflistquestionmultiple';
import { init as customFieldQuestion} from '@/components/customCollectionWidgets/customFieldQuestion';

import sanitizeHtml from 'sanitize-html'

import * as SurveyPDF from 'survey-pdf';

import {API_URL} from '@/config';
import { isUsingYii } from '../store/getters';
import customInputFactory from './customCollectionWidgets/customInputFactory';
import ResultsCollaborationModal from '@/components/ResultsCollaborationModal.vue';

Survey.StylesManager.applyTheme("defaultV2");
var sessionTimer;

function doMarkdown(survey,options){
  

        sanitizeHtml(options.text,{
        allowedTags:sanitizeHtml.defaults.allowedTags,
        allowedAttributes:{...sanitizeHtml.defaults.allowedAttributes,'div':['style']},
        allowedStyles:{
          'div':{
            'height':[/^[0-9]{1,}px|[0-9]{1,}%/]
          }

        }
      });

        options.html=options.text;
}

function checkStaffRelationship(sender,options){

  if(options.question.customWidgetValue && options.question.customWidgetValue.name=='staff-list-multiple'){
          
      if(typeof options.question.value !== 'undefined'){
      


          
          
          var questionParsed=null;
          
          if(options.question.value.value){

                questionParsed=options.question.value.value;
          }else{
                if(Array.isArray(options.question.value)){
                    questionParsed=options.question.value;
                }else{
                    questionParsed=JSON.parse(options.question.value);


                }

          }



          var missingRelationship=false;
          $.each(questionParsed,function(index,value){
                if(!value.Relationship || value.Relationship=='null'){
                  missingRelationship=true;
                }

          });



          if(missingRelationship){
            options.error="Missing relationship for staff. One or more of the staff below does not have a relationship to client specified. Please review and try again";
          }
      }
       

  }else  if(options.question.getType()=="list-resultfile"){
              console.log(options.question.isRequired);
   }
}

Survey.Serializer.addProperty('matrixdropdowncolumn', { name: 'makeControlVisibleColumn',displayName:'Make Control Visible Based On Column',type:'Text'});
Survey.Serializer.addProperty('matrixdropdowncolumn', { name: 'makeControlVisibleColumnValue',displayName:'Trigger Value',type:'Text'});
Survey.Serializer.addProperty('matrixdropdowncolumn', { name: 'requiredIfVisible',displayName:'Required If Visible',type:'boolean'});
Survey.Serializer.addProperty('matrixdropdowncolumn', { name: 'hideChoiceLabel',displayName:'Hide Choice Label',type:'boolean'});
Survey.Serializer.addProperty('matrixdropdowncolumn', { name: 'textAlignment',displayName:'Text Alignment',default:'left',type:'dropdown', choices:[
  {text:'Left',value:'left'},
  {text:'Center',value:'center'},
  {text:'Right',value:'right'},



]});

SurveyCore.Serializer.addProperty('matrixdropdowncolumn', { name: 'makeControlVisibleColumn',displayName:'Make Control Visible Based On Column',type:'Text'});
SurveyCore.Serializer.addProperty('matrixdropdowncolumn', { name: 'makeControlVisibleColumnValue',displayName:'Trigger Value',type:'Text'});
SurveyCore.Serializer.addProperty('matrixdropdowncolumn', { name: 'requiredIfVisible',displayName:'Required If Visible',type:'boolean'});
SurveyCore.Serializer.addProperty('matrixdropdowncolumn', { name: 'textAlignment',displayName:'Text Alignment',default:'left',type:'dropdown', choices:[
  {text:'Left',value:'left'},
  {text:'Center',value:'center'},
  {text:'Right',value:'right'},



]});

SurveyCore.Serializer.addProperty('matrixdropdowncolumn', { name: 'hideChoiceLabel',displayName:'Hide Choice Label',type:'boolean'});




let SurveyComp=Survey.Survey;



export default {
  name: 'survey_viewer',
  props:{
    dialogSettings:{type:Object, default:function(){return {}}},
    params:{type:Object,default:function(){return {}}},
    makeReadOnly:{type:Boolean, default:false}
  },
  components: {
    QuestionCustomDialog: () => import('@/components/CustomDialog.vue'),
    SurveyComp,
    ResultsCollaborationModal,
},
  data () {
 

    return {
        assessmentType: null,//this.$route.params.assessmentType,
        assessmentID: null, //this.$route.params.assessmentID,
        model: null, // this.$route.params.model,
        modelID: null, // this.$route.params.modelID,
        survey:null,
        readOnly:false,
       renderCustomDialog:false,
       customDialogSettings:{},
       refreshParams:null,
        fromMobile: false,
        afterSubmitUri:null,
        approvalTriggerValues:[],
        isRestricted:false,
        restrictedMessage:null,
               domainList:[],
            completionDate:null,
            waitToClearinterval:null,
            treatmentID:null,
            errorMessage:null,
            renderCollaboratorDialog:false,
            refreshCollabParams:null,
            resultSetID:null,
            showCollaborators:false,
            inCollab:false,
            fromLogin:0,
            hasCollaborators:false


    }
  },
  beforeCreate() {
    
    
  },
  created(){


  
 if(this.$route.query.readOnly){
              this.readOnly=this.$route.query.readOnly;
              

  }

  if(this.dialogSettings && this.dialogSettings.readOnly){
      this.readOnly=this.dialogSettings.readOnly;
  }

          formService.getDomainList().then(response =>{
                    this.domainList=response;

              });
       SurveyCore.CustomWidgetCollection.Instance.widgetsValues=new Array;
       Survey.CustomWidgetCollection.Instance.clear();


       
       
         customDateWidget(Survey,this);


    customRateWidget(Survey,this,this.readOnly);
       customStaffListWidget(Survey,this,true);
        customCurrentUserWidget(Survey,this,true);
        customClientLocationCurrent(Survey,this,true);
        customClientLocationHistory(Survey,this,true);
        customClientProgramCurrent(Survey,this,true);
        customLocationList(Survey,this,true);
        customClientList(Survey,this,true);
        customClientReferralDenialList(Survey,this);
        customCollectionInstructions(Survey,this);
        customFamilyWidget(Survey,this,true);
        customStaffListWidgetMul(Survey,this,true);
        customFieldQuestion(Survey,this);


         customDateWidget(SurveyCore,this);

        customRateWidget(SurveyCore,this,this.readOnly);
       customStaffListWidget(SurveyCore,this,true);
        customCurrentUserWidget(SurveyCore,this,true);
        customClientLocationCurrent(SurveyCore,this,true);
        customClientLocationHistory(SurveyCore,this,true);
        customClientProgramCurrent(SurveyCore,this,true);
        customLocationList(SurveyCore,this,true);
        customClientList(SurveyCore,this,true);
        customClientReferralDenialList(SurveyCore,this);
        customCollectionInstructions(SurveyCore,this);
        customFamilyWidget(SurveyCore,this,true);
        customStaffListWidgetMul(SurveyCore,this,true);
        customFieldQuestion(SurveyCore,this);

        let customWidgetParams=this.$route.params;


        if(this.dialogSettings){
          customWidgetParams={...customWidgetParams,...this.dialogSettings};

        }



        formService.getSurveyCustomWidgets(customWidgetParams).then(widgetsRes => {
          if (!Array.isArray(widgetsRes) || widgetsRes.length === 0)
            return;
          for (const widgetData of widgetsRes) {
            customInputFactory(Survey, this, widgetData);
            customInputFactory(SurveyCore, this, widgetData);
          }
        }).catch(erro => {
          console.log(erro);
        });

        
        
        

        
               // customCurrentUserWidget(Survey,this,true);



               if(this.$store.getters.getIsUserCollection){
                  this.needsPrompt=true;
               }
      
      
   
  },
  computed:{
     checkIsClient(){
        return this.$store.getters.getIsClient;

    }
  },
  mounted(){

            let vm=this;

if (localStorage.getItem('fromMobile') ) {

      if(typeof localStorage.getItem('fromMobile')!=='undefined' &&  localStorage.getItem('fromMobile')!=='undefined' ){
          this.$store.commit('HIDE_NAV', true);
          this.$store.commit('FROM_MOBILE', true);
      }
      
    }
    if (this.fromMobile) {
      localStorage.setItem('fromMobile', this.fromMobile);
    } else {

      localStorage.setItem('fromMobile', this.$route.query.fromMobile);
    }


      if(!this.readOnly && this.dialogSettings.readOnly){
        this.readOnly=this.dialogSettings.readOnly;
      }

      if(!this.readOnly && this.dialogSettings.makeReadOnly){
        this.readOnly=this.dialogSettings.makeReadOnly;

      }
      
    
      this.assessmentType=this.dialogSettings.assessmentType?this.dialogSettings.assessmentType:this.$route.params.assessmentType;
      this.assessmentID=this.dialogSettings.assessmentID?this.dialogSettings.assessmentID:this.$route.params.assessmentID;
      this.model=this.dialogSettings.model?this.dialogSettings.model:this.$route.params.model;
      this.modelID=this.dialogSettings.modelID?this.dialogSettings.modelID:this.$route.params.modelID;
      this.fromMobile=this.$route.query.fromMobile ? this.$route.query.fromMobile : localStorage.getItem('fromMobile');
      this.treatmentID=this.dialogSettings.treatmentID?this.dialogSettings.treatmentID:this.$route.params.treatmentID;
      this.resultSetID=this.dialogSettings.resultSetId?this.dialogSettings.resultSetId:this.$route.params.resultSetId;
      this.inCollab=this.dialogSettings.inCollab?this.dialogSettings.inCollab:this.$route.params.inCollab;
      this.fromLogin=this.dialogSettings.fromLogin?this.dialogSettings.fromLogin:0;

      if(typeof this.fromMobile ==='undefined' || this.fromMobile==='undefined'){

        this.fromMobile=false;

      }


let thiscomp=this;
function useStaffCompletedMessage(){

   return thiscomp.dialogSettings.inStaffMode?true:false
}

Survey.FunctionFactory.Instance.register('useStaffCompleteMessage',useStaffCompletedMessage);
Survey.matrixDropdownColumnTypes['list-resultfile']={};
SurveyCore.matrixDropdownColumnTypes['list-resultfile']={};

    
assessmentService.GetSurveyJSON({
            assessmentType: this.assessmentType, // this.$route.params.assessmentType,
            assessmentID: this.assessmentID, // this.$route.params.assessmentID,
            model: this.model,
            resultSetID: this.resultSetID?this.resultSetID:this.dialogSettings.RowID,
            modelID:this.modelID,
            treatmentID:this.treatmentID,
            inCollab:this.inCollab,
            passedParams:this.dialogSettings
    
      })
      .then(response =>{
        //console.log('getSurveyJSON response',response)

        
        customDateWidget(Survey,this);


        customRateWidget(Survey,this,this.readOnly);
          customStaffListWidget(Survey,this,true);
            customCurrentUserWidget(Survey,this,true);
            customClientLocationCurrent(Survey,this,true);
            customClientLocationHistory(Survey,this,true);
            customClientProgramCurrent(Survey,this,true);
            customLocationList(Survey,this,true);
            customClientList(Survey,this,true);
            customClientReferralDenialList(Survey,this);
            customCollectionInstructions(Survey,this);
            customFamilyWidget(Survey,this,true);
            customStaffListWidgetMul(Survey,this,true);
            customFieldQuestion(Survey,this);


            customDateWidget(SurveyCore,this);

            customRateWidget(SurveyCore,this,this.readOnly);
          customStaffListWidget(SurveyCore,this,true);
            customCurrentUserWidget(SurveyCore,this,true);
            customClientLocationCurrent(SurveyCore,this,true);
            customClientLocationHistory(SurveyCore,this,true);
            customClientProgramCurrent(SurveyCore,this,true);
            customLocationList(SurveyCore,this,true);
            customClientList(SurveyCore,this,true);
            customClientReferralDenialList(SurveyCore,this);
            customCollectionInstructions(SurveyCore,this);
            customFamilyWidget(SurveyCore,this,true);
            customStaffListWidgetMul(SurveyCore,this,true);
            customFieldQuestion(SurveyCore,this);


            let customWidgetParams=this.$route.params;

              if(this.dialogSettings){
                customWidgetParams={...customWidgetParams,...this.dialogSettings};

              }

            
            
              formService.getSurveyCustomWidgets(customWidgetParams).then(widgetsRes => {
              if (!Array.isArray(widgetsRes) || widgetsRes.length === 0)
                return;
              for (const widgetData of widgetsRes) {
                customInputFactory(Survey, this, widgetData);
                customInputFactory(SurveyCore, this, widgetData);
              }
            }).catch(erro => {
              console.log(erro);
            });
        
        var vm=this;
        if (response.error && response.message) {
          this.errorMessage = response.message;
          return;
        }
        sessionTimer = setTimeout( ()=>vm.timeoutPopup(response.timeoutLength), response.timeoutLength * 1000 - 180000)


       if(response.readOnly){
         this.readOnly=true;
       }

       if(response.restricted){
          this.isRestricted=true;
          this.restrictedMessage=response.restrictionMessage;
       }


   
       if(response.afterSubmitUri && (!this.dialogSettings || (this.dialogSettings && !this.dialogSettings.afterSubmitUri))){
          this.afterSubmitUri=response.afterSubmitUri;

          
       }


       if(response.completionDate){
         this.completionDate=response.completionDate;
       }

      if(response.approvalTriggerValues){
        this.approvalTriggerValues=response.approvalTriggerValues;
      }
      
      if(response.ResultSetID){
        this.resultSetID=response.ResultSetID;
      }
      
      if(response.showCollaborators){
        this.showCollaborators=response.showCollaborators;
      }

      if(response.hasCollaborators){
        this.hasCollaborators=response.hasCollaborators;
      }

       formService.getCustomProperties()
    .then(returnedProperties =>{

  var propertyDefaults=new Object;
          var selfObj=this;
        
        
    $.each(returnedProperties,function(elementname,propertiesArray){
       if(propertiesArray['parent']){
                                             
                                                        
                                        


                                                          
                                    
                                    
                                    

                                      if(propertiesArray['properties']['getChoicesUrl']){
                                        propertiesArray['properties']['choices']=function(obj,choicesCallback){

                                                  choicesCallback(selfObj.domainList);
                                                  



                                        
                                        }
                                      }
                                      SurveyCore.JsonObject.metaData.addClass(
                                            elementname, [{
                                              ...propertiesArray['properties']
                                            }],
                                              null,
                                              "itemvalue"
                                            );
                                            var choicesProp = SurveyCore.JsonObject.metaData.findProperty(
                                              propertiesArray['parent'],
                                              propertiesArray['property']
                                            );
                                            choicesProp.className = elementname;



            }else{
        
              propertyDefaults[elementname]=new Object;
            $.each(propertiesArray,function(propertyKey,propertyValues){
                  if(propertyValues['getChoicesUrl']){
                      propertyValues['choices']=function(obj,choicesCallback){
                          var res=new Array;
                          $.ajax({
                            url:propertyValues['getChoicesUrl'],
                            dataType:'JSON',
                            async:false,
                            success:function(data){
                              $.each(data,function(key,value){
                                res.push(value);
                              });
                              choicesCallback(res);
                            }
                          });
                      }
                  }
                  
                 Survey
                  .Serializer
                .addProperty(elementname,propertyValues);
                 
            
              
            });
            }      
          });

         let tempSurvey=new Survey.Model(response.formJSON); 

          let tempSurvey2=new SurveyCore.Model(response.formJSON);  

         
      
            const exportToPdfOptions = {
              fontSize: 12,
               haveCommercialLicense: true, // license
            };

          
            //window.survey=this.survey;


              
            
            const savePdf = function (surveyData) {

              let jsonToRender=JSON.parse(response.formJSON);


              jsonToRender.pages.forEach((p) => {
                if (p.elements) {
                   vm.recursivelyChangeDropdown(p.elements,surveyData);

                }
            });

              
              const surveyPdf = new SurveyPDF.SurveyPDF(jsonToRender, exportToPdfOptions);
            

             surveyPdf.data = surveyData;
              surveyPdf.mode='display';
         

               surveyPdf.onRenderQuestion.add(function(sender,options){


                  if(typeof options.bricks[0] !=='undefined'){
                          var plainBricks = options.bricks[0].unfold();


                          $.each(plainBricks,function(key,brick){

                              if(brick.constructor.name=='TextBoldBrick' || brick.constructor.name=='DescriptionBrick'  || brick.constructor.name=='t'){
                                    if(plainBricks[key].text){

                                      plainBricks[key].text= plainBricks[key].text.replace("<em>",'').replace("</em>",'').replace("<p>","").replace("</p>","\n").replace("<strong>","").replace("</strong>","").replace("<h6>","").replace("</h6>","").replace(/<span.*>/g,"").replace("</span>","");

                                    }
                              }

                          });

                    }

                   return new Promise(function (resolve) {
                      resolve();
                    });

               });

                surveyPdf.onRenderPanel.add(function(sender,options){


                  var plainBricks = options.bricks[0].unfold();



                  $.each(plainBricks,function(key,brick){


                      if(brick.constructor.name=='TextBoldBrick' || brick.constructor.name=='DescriptionBrick' ||    brick.constructor.name=='t' || brick.constructor.name=='TitlePanelBrick'){
                          if(plainBricks[key].text){
                            plainBricks[key].text= plainBricks[key].text.replace("<em>",'').replace("</em>",'').replace("<p>","").replace("</p>","\n").replace("<strong>","").replace("</strong>","").replace("<h6>","").replace("</h6>","").replace(/<span.*>/g,"").replace("</span>","").replace("<ul>","").replace("</ul>",'');
                         
                         }
                      }

                  });


                   return new Promise(function (resolve) {
                      resolve();
                    });

               });

              surveyPdf.save(response.fileName+".pdf");

            };
        
           
       
              tempSurvey.addNavigationItem({
                        id: "pdf-export",
                        title: "Save as PDF",
                        action: () => savePdf(this.survey.data)
                        
                }
              );


       
        //check to see if you can find the message in the properties if so then set message to null
      //console.log("this.survey = ", this.survey);
      if(this.$store.getters.isUsingYii){
        tempSurvey.showCompletedPage = false;
      }

      if(this.inCollab){
        tempSurvey.showCompletedPage=true;
      }

       var matrixCss={
          matrix:{
              root:'table sv_q_matrix shadow'
          }
        } 

        tempSurvey.css=matrixCss;
        tempSurvey.css.completedPage="surveyCompletedPage"
        
        

         tempSurvey.clearInvisibleValues="onHidden";

  

         tempSurvey.onMatrixCellValidate.add(function(sender,options){

          let targetQuestion=null;
              if(options.getCellQuestion(options.columnName).getType()=="list-resultfile"){

                  let targetQuestion=options.getCellQuestion(options.columnName);
                  let showError=false;

                  if(targetQuestion.requiredIfVisible){
                      if(targetQuestion.makeControlVisibleColumn){
                        for(let cellObject of options.row.cells){
                            if(cellObject.column.name===targetQuestion.makeControlVisibleColumn){
                              if(cellObject.questionValue.value){
                  
                                  if(Array.isArray(cellObject.questionValue.value)){
                                    if(cellObject.questionValue.value.includes(targetQuestion.makeControlVisibleColumnValue)){
                                      
                                        if(!targetQuestion.value){
                                          showError=true;
                                        }
                                    }

                                  }else{
                                    if(cellObject.questionValue.value==options.column.makeControlVisibleColumnValue){
                                      if(!targetQuestion.value){
                                          showError=true;
                                        }
                                    }
                                  }
                              }
                            }
                        }
                        
                      }
                    }

                 if(showError){
                    options.error="No files selected";

                    let container= $('#surveyContainer').closest('.card-body');

                    setTimeout(function(){

                      if($(container).find('.sd-question__erbox').length=0){
                         container.scrollTop(
                          $('#'+targetQuestion.id+'_errors').offset().top - container.offset().top + container.scrollTop()
                        );
                      }
                    
                    },1000);
                   
                  }

                 
              }

            

         });

         tempSurvey.onValueChanged.add(function(sender,options){
          
            if(vm.approvalTriggerValues){
              let createAlert=false;
              $.each(vm.approvalTriggerValues,function(key,value){
                  if(value.code===options.question.name){
                    if(Array.isArray(value.triggerValue)){
                      if(!value.triggerValue.includes(options.value)){
                             createAlert=true;

                      }

                    }else{
                      if(option.value !=value.triggerValue){
                            createAlert=true;

                      }
                    }
                  }
              });

              if(createAlert){
                vm.$bvModal.msgBoxOk("This form is currently linked to a pending approval. Changing the response for this question will cause the current approval to be cancelled once form is submitted.")
                .then(value =>{

                })
              }
            }

            if(options.question.getType()=='matrix'){

                  if(options.value && Object.keys(options.value).length>0){
                    
                    $("#"+options.question.id).find(".clearSelection_bttn").hide();

                    

                    for(let code in options.value ){


                      $("#"+options.question.id).find("#bttn_clear_"+code).show();

                    }

                  }else{
                    $("#"+options.question.id).find(".clearSelection_bttn").hide();
                  }

                  }


            sessionService.keepAlive().then(data=>{
              }).catch(err=>{
                console.log("error")
              });                  

          clearTimeout(sessionTimer)
          sessionTimer = setTimeout(()=>vm.timeoutPopup(response.timeoutLength), response.timeoutLength * 1000 - 180000);
        });

        tempSurvey.onMatrixAfterCellRender.add(function(sender,options){

                  if(typeof options.column.makeControlVisibleColumn !=='undefined'){
                     let hideDom=true;
                     for(let cellObject of options.row.cells){
                        if(cellObject.column.name===options.column.makeControlVisibleColumn){
                          if(cellObject.questionValue.value){
              
                              if(Array.isArray(cellObject.questionValue.value)){
                                if(cellObject.questionValue.value.includes(options.column.makeControlVisibleColumnValue)){
                                  
                                    hideDom=false;
                                }

                              }else{
                                if(cellObject.questionValue.value==options.column.makeControlVisibleColumnValue){
                                  hideDom=false;
                                }
                              }
                          }
                        }
                     }

                     if(hideDom){
                        $(options.htmlElement).hide();
                     }
                  }

                  if(options.column.hideChoiceLabel){
                      $(options.htmlElement).find('.sd-item__control-label').hide();
              }


                  if(options.column.textAlignment){
                        if(options.column.textAlignment=='left'){

                        }else if(options.column.textAlignment=='center'){
                          $(options.htmlElement).find('div').first().css('width','fit-content');
                          $(options.htmlElement).find('div').first().css('margin','auto');

                        }else if(options.column.textAlignment=='right'){
                          $(options.htmlElement).find('div').first().css('width','fit-content');
                          $(options.htmlElement).find('div').first().css('margin-left','auto');

                        }
                    
                  }

          });

          tempSurvey.onMatrixCellValueChanged.add(function(sender,options){
                for(let cellObject of options.row.cells){
                    if(cellObject.column.makeControlVisibleColumn){
                      if(cellObject.column.makeControlVisibleColumn===options.columnName){
                            if(Array.isArray(options.value)){

                                if(options.value.includes(cellObject.column.makeControlVisibleColumnValue)){
                                  $('#'+cellObject.questionValue.id).closest('td').show();

                                }else{
                                  $('#'+cellObject.questionValue.id).closest('td').hide();

                                }

                            }else{
                              if(cellObject.column.makeControlVisibleColumnValue==options.value){
                                  $('#'+cellObject.questionValue.id).closest('td').show();
                              }else{
                                  $('#'+cellObject.questionValue.id).closest('td').hide();

                              }
                            }

                      }
                    }
                }

          });

           tempSurvey.onAfterRenderQuestion.add(function(sender,options){
                    
                      


                  
                       
                        

                        if($(options.htmlElement).find('input[type=time]')){
                        

                                $(options.htmlElement).find('input[type=time]').on("keydown",function(){
                                      
                                    if($(this).val().length==0){
                                      $(this).val("00:00:00")
                                    }
                                    
                                });

                                
                        }


                        if(options.question.getType()=='matrix'){

                              if($(options.htmlElement).find("input[type='radio']").length>0 && $(options.htmlElement).find("tr.sd-table__row").length>0){
                                let index=0;
                                $(options.htmlElement).find("tr.sd-table__row").each(function(){



                                  $(this).append("<td style='vertical-align:middle'><button id='bttn_clear_"+options.question.rows[index].value+"' data-rowIndex="+options.question.rows[index].value+" type='button' style='float:right' class='clearSelection_bttn sd-action sd-action--icon sd-matrixdynamic__btn sd-action--negative sd-matrixdynamic__remove-btn' > <svg class='sv-svg-icon sd-action__icon' role='img' aria-label='Remove' style='width: 24px; height: 24px;'><use xlink:href='#icon-delete'></use><title>Clear</title></svg></button><td>");


                                    $('#bttn_clear_'+options.question.rows[index].value).hide();
                                  $(this).find('.clearSelection_bttn').click(function(){

                                    
                                  
                                      if(typeof options.question.value !=='undefined'){


                                    

                                          let currentResponses=JSON.parse(JSON.stringify(options.question.value));





                                          delete currentResponses[$(this).data('rowindex')];





                                          

                                          options.question.clearValue();

                                          options.question.value=currentResponses;

                                          

                                          
                                      

                                        

                                      }
                                    
                                  });
                                  
                                  index++;
                                });

                                
                                
                              }
                              }


                      


                    



            });



            tempSurvey.onCompleting.add(function(sender,options){
              let routeParams=vm.$route.params;
            
                if(sender.AllowResultSetCollaboration && sender.RequireResultSetCollaborators && vm.showCollaborators){
                
                  if(!vm.hasCollaborators){
                    vm.errorMessage="No collaborators selected please select at least one collaborator";
                    options.allow=false;
                    options.allowComplete=false;

                  }


                }


            });
            tempSurvey.onValidatedErrorsOnCurrentPage.add(function(sender,options){
             

              let container= $('#surveyContainer').closest('.card-body');

              

              setTimeout(function(){


                console.log($('#surveyContainer').find('.alert'));

                if( $(container).find('.alert').first().length>0){
                  container.scrollTop(

                  $(container).find('.alert').first().offset().top - container.offset().top + container.scrollTop()
                  );
                }else  if( $('#surveyContainer').find('.alert').first().length>0){
                    let container2=$('#surveyContainer').closest('.surveyCreatorBody');



                    container2.scrollTop(

                        $('#surveyContainer').find('.alert').first().offset().top - container2.offset().top + container2.scrollTop()
                    );

                }else if( $(container).find('.sd-question__erbox').first().length>0){
                  container.scrollTop(

                      $(container).find('.sd-question__erbox').first().offset().top - container.offset().top + container.scrollTop()
                  );

                }
      

              },100);
                    
                   
                    

                 
            });

         tempSurvey.onComplete.add(function(sender){
          $('.headerTitle').hide();
          $('#surveyContainer').find('.panel-heading').hide();

          
            let routeParams=vm.$route.params;
           assessmentService.saveResults(vm.dialogSettings.assessmentID,vm.dialogSettings.assessmentType,vm.dialogSettings.model,vm.dialogSettings.modelID,vm.dialogSettings.resultSetID?vm.dialogSettings.resultSetID:(vm.resultSetID?vm.resultSetID:0),vm.treatmentID,{answers: sender.data,completed:1,routeParams:routeParams,passedParams:vm.params,inCollab:vm.inCollab,fromLogin:vm.fromLogin})
           .then(response => {

              if (response.error && response.message) {
                this.errorMessage = response.message;
                return;
              }


              if(response.redirectUrl){

                vm.$router.push({path:response.redirectUrl})
                
                return;
              }
                
                  
                  $('.finishedButton').hide();
                  $('.surveySaveButton').hide();

                  $('.surveyFinished').hide();
                  $('.surveySaved').hide();
                  
              
                  
                  if(!sender.showCompletedPage && !vm.$store.getters.isUsingYii){

                 

                      
                      if (vm.fromMobile) {
                        vm.$router.push({
                          name: 'mobileLogoutPage',
                          params: {
                            mobileCloseDialog: "Thank you for completing the form."
                          }
                        }).catch(() => {});
                      }else if(vm.$store.getters.getIsUserCollection){

                        sessionStorage.setItem('userCollectionLogoutStatus',1);

                        vm.$router.push({
                                name:'userCollectionLogout',
                                params:{
                                  logoutMessage:response.logoutMessage
                                }
                              });

                      } else if(vm.dialogSettings && vm.dialogSettings.closeDialog){
                          // console.log('onComplete SurveyViewer response: ', response);
                         

                          vm.$emit('closeDialog',{afterSubmitUri:vm.dialogSettings.afterSubmitUri,afterSubmitNoDialog:vm.dialogSettings.afterSubmitNoDialog,nextUri:vm.dialogSettings.afterSubmitNoDialog,afterSubmitDialogParams:vm.dialogSettings.afterSubmitDialogParams});

                      }else if(vm.dialogSettings.afterSubmitUri){

                           vm.$emit('afterFormSubmit',{afterSubmitUri:vm.dialogSettings.afterSubmitUri,afterSubmitDialogParams:vm.dialogSettings.afterSubmitDialogParams});

                      }else if(vm.afterSubmitUri){


                           vm.$emit('afterFormSubmit',{afterSubmitUri:vm.afterSubmitUri});
                      }else if(vm.fromLogin==1){

                         
                            vm.$router.push({path:response.homePage});


                          


                      }else{

                          if(response.returnRouterStep){
                            let returnstep=response.returnRouterStep*-1;
                            vm.$router.go(returnstep);

                          }else{
                            vm.$router.go(-1);


                          }
                        
                         
                      }
                    

                  }else if(!sender.showCompletedPage && vm.$store.getters.isUsingYii){//else !sender.showCompletedPage and using yii close dialog
                    if(vm.checkIsClient){
              
                      window.parent.$(window.parent.document).find('[name = collectionHolder]').parent().parent().find('[name=exit]').trigger('click');
                    }else{
                       window.parent.$(window.parent.document).find('[name = collectionHolder]').closest('[role = dialog]').find('.ui-dialog-content').dialog('close');
                    }
                  }else if(vm.$store.getters.getIsUserCollection){

                    sessionStorage.setItem('userCollectionLogoutStatus',1);

                        vm.$router.push({
                                name:'userCollectionLogout',
                                params:{
                                  logoutMessage:response.logoutMessage
                                }
                              });

                   } 
                  else{




                    if (vm.fromMobile) {
                        vm.$router.push({
                          name: 'mobileLogoutPage',
                          params: {
                            mobileCloseDialog: sender.completedHtml
                          }
                        }).catch(() => {});

                    } else if(vm.checkIsClient){
                      
                       if(!vm.$store.getters.isUsingYii){
                              
                              vm.$router.push({
                                name:'clientCollectionLogout',
                                params:{
                                  logoutMessage:response.logoutMessage
                                }
                              });
                       }else{
                          window.parent.$(window.parent.document).find('[name = collectionHolder]').parent().parent().find('[name=exit]').trigger('click');
                       }
                    } else if(vm.$store.getters.isUsingYii){
                      window.parent.$(window.parent.document).find('[name = collectionHolder]').closest('[role = dialog]').find('.ui-dialog-content').dialog('close');
                    }else if(response.redirectUrl){
                      vm.$router.push({path: response.redirectUrl});
                    }else if(vm.fromLogin==1){
                      vm.$router.push({path:response.homePage});
                    }else if(vm.dialogSettings.closeDialog){
                      vm.$emit('closeDialog',{afterSubmitUri:vm.dialogSettings.afterSubmitUri,afterSubmitNoDialog:vm.dialogSettings.afterSubmitNoDialog,nextUri:vm.dialogSettings.afterSubmitNoDialog,afterSubmitDialogParams:vm.dialogSettings.afterSubmitDialogParams});

                      
                    }

                  }

                  
                 //vm.$router.push({name: 'dashboard'});

                 
           });
           
        });

        
        

        tempSurvey.onUpdatePageCssClasses.add(function(sender,options){
         
                       options.cssClasses.page.description +=" pageDescription";


         });

        tempSurvey.onUpdatePanelCssClasses.add(function(sender,options){
           
                                options.cssClasses.panel.content=" panel-content"

    
                        
                              options.cssClasses.panel.container += ' card shadow border-top panelBorder ';
                              // p.css.row="card-body";
                              options.cssClasses.panel.title =
                                'card-header py-3 align-items-center justify-content-between global-primary__bg global-primary__bg--no-hover panelTitle';



           
             

        });

         tempSurvey.onUpdateQuestionCssClasses.add(function(sender,options){
           
                           
                            options.cssClasses.number="";


           
             

        });



          tempSurvey.onTextMarkdown.add(doMarkdown);

        
         tempSurvey.onValidateQuestion.add(checkStaffRelationship);
        
        tempSurvey.onAfterRenderSurvey.add(function(sender,options){

              $('.sd-navigation__complete-btn').css('visibility','hidden');

              
                

                    $.each(options.survey.pages,function(pageIndex,value){

                              $.each(value.questions,function(questionIndex,questionProperties){

                                  if(questionProperties.apiEndpoint){
                                 
                                    options.survey.pages[pageIndex].questions[questionIndex].choices=new Array;

                                    var data={};

                                    if(questionProperties.apiEndpointParams){
                                        var paramsCouples=questionProperties.apiEndpointParams.split(',');
                                        $.each(paramsCouples,function(key,value){
                                          var couplesExploded=value.split(':');

                                          data[couplesExploded[0]]=couplesExploded[1];
                                        });
                                    }

                                    $.ajax({
                                      url:API_URL+questionProperties.apiEndpoint,
                                      method:'POST',
                                      dataType:'json',
                                      data:data,
                                      success:function(response){
                                          options.survey.pages[pageIndex].questions[questionIndex].choices=response;

                                      }
                                    });

                                  }

                              });
                        
                      
                      });
              



              let waitForSurveyCompleteBttn=setInterval(function(){
                    if($('#surveyContainer').find('.sd-navigation__complete-btn').length>0){
                        clearInterval(waitForSurveyCompleteBttn);

                        $('#surveyContainer').find('.sd-navigation__complete-btn').css('visibility','hidden');
                        $('#surveyContainer').find('.sd-navigation__complete-btn').parent().prepend("<input type='button' class='btn ml-2 btn-success sv_save_btn' style='visibility:hidden' value='Save'></input>");
                         $(".sd-btn[value='Save as PDF']").css('visibility','hidden');


                    }
              },100);

              $("#surveyContainer").on('click','.sv_save_btn',function(){
                  var target=$(this);
                 
                             let routeParams=vm.$route.params;

                   assessmentService.saveResults(vm.dialogSettings.assessmentID,vm.dialogSettings.assessmentType,vm.dialogSettings.model,vm.dialogSettings.modelID,vm.dialogSettings.resultSetID?vm.dialogSettings.resultSetID:0,vm.treatmentID,{answers:vm.survey.data,routeParams:routeParams,passedParams:vm.params})
                      .then(response => {
                          // console.log('SurveyViewer response: ', response)

                          if (vm.fromMobile) {
                           

                            vm.$router.push({
                              name: 'mobileLogoutPage',
                              params: {
                                mobileCloseDialog: "Saved! You can complete the form at a later time."
                              }
                            }).catch(() => {})
                          }else if(vm.$store.getters.getIsUserCollection){

                             sessionStorage.setItem('userCollectionLogoutStatus',2);

                              vm.$router.push({
                                      name:'userCollectionLogout',
                                      params:{
                                        logoutMessage:response.logoutMessage
                                      }
                                    });
                          } else if(vm.checkIsClient){
                              
                            if(!vm.$store.getters.isUsingYii){
                              
                              vm.$router.push({
                                name:'clientCollectionLogout',
                                params:{
                                  logoutMessage:response.logoutMessage
                                }
                              });
       
                            }else{
                              vm.$bvModal.msgBoxOk(response.message, {
                                            title: 'Success',
                                            size: 'sm',
                                            buttonSize: 'sm',
                                            okVariant: 'success',
                                            headerClass: 'p-2 border-bottom-0',
                                            footerClass: 'p-2 border-top-0',
                                             hideHeaderClose: true,
                                            centered: true
                                          })
                                            .then(value => {
                                                  
                                                  if($(target).data('triggerClose')){
                                                    vm.$emit('closeDialog',{refreshTable:true});
                                                  }
                                            })
                                            .catch(err => {
                                              // An error occurred
                                            });
                            }
                            
                          }else if( $("#surveyContainer").data('launchingCollaborators')){

                              $("#surveyContainer").data('launchingCollaborators',0);


                              vm.resultSetID=response.resultSetID;

                              vm.refreshCollabParams=new Date().getTime();



                              vm.renderCollaboratorDialog=true;


                          }else{
                                      vm.$bvModal.msgBoxOk(response.message, {
                                            title: 'Success',
                                            size: 'sm',
                                            buttonSize: 'sm',
                                            okVariant: 'success',
                                            headerClass: 'p-2 border-bottom-0',
                                            footerClass: 'p-2 border-top-0',
                                             hideHeaderClose: true,
                                            centered: true
                                          })
                                            .then(value => {
                                                  
                                                  if($(target).data('triggerClose')){
                                                    vm.$emit('closeDialog',{refreshTable:true});
                                                  }
                                            })
                                            .catch(err => {
                                              // An error occurred
                                            });
                          }
                             
                            //vm.$router.push({name: 'dashboard'});
                      });
                 $('.sv_exit_btn').click(function(){
                   
                    if(vm.$store.getters.isUsingYii){
                      window.parent.$(window.parent.document).find('[name = collectionHolder]').closest('[role = dialog]').find('.ui-dialog-content').dialog('close');
                              
                    }
                  });
              
              });

        });
        

       
  
        if(response.prevAnswers){
         
             tempSurvey.data=JSON.parse(response.prevAnswers);



          
        }
      
            this.$root.$on('openRequestor',(data) =>{
              
              
                this.customDialogSettings={
                  serviceName:'staff',
                  displayListView:true,
                  size:'xl',
                  params:{
                    inQuestion:true,
                    questionCode:data.questionCode,
                     defaultRole:data.defaultRole,
                    staffTagCat:data.staffTagCat,
                    tagUser:data.tagUser,
                    filterUser:data.filterUser,
                    selectedUsers:data.currentUsersSelected,
                    locationFilterField:data.locationAuditField




                  },
                };
                

                this.refreshParams=new Date().getTime();


                this.renderCustomDialog=true;
            });


            this.$root.$on('openLocation',(data) =>{
                this.customDialogSettings={
                  serviceName:'locationEHR',
                  displayListView:true,
                  size:'xl',
                  params:{
                    inQuestion:true,
                    questionCode:data.questionCode,
                    LocationType:data.LocationType
                  },
                };

                        this.refreshParams=new Date().getTime();


                          this.renderCustomDialog=true;
              });
                
                
                this.$root.$on('openClientList',(data) =>{
                    this.customDialogSettings={
                      serviceName:'client',
                      displayListView:true,
                      size:'xl',
                      params:{
                        inQuestion:true,
                        questionCode:data.questionCode
                      },
                };
                 this.refreshParams=new Date().getTime();


                          this.renderCustomDialog=true;
              });

               this.$root.$on('openFamilyList',(data) =>{
                    vm.customDialogSettings={
                      serviceName:'familyMembers',
                      displayListView:true,
                      size:'xl',
                      params:{
                        inQuestion:true,
                        questionCode:data.questionCode,
                        getFamily:true,
                        ClientID:data.params.ClientID,
                        ClientNumber:data.params.ClientNumber,
                        selectedFamily:data.params.selectedFamily
                      },
                };

                         this.refreshParams=new Date().getTime();


                          this.renderCustomDialog=true;

               });

              vm.$root.$on('openStaffListMul',(data) =>{
                    
                    vm.customDialogSettings={
                      serviceName:'staff',
                      displayListView:true,
                      size:'xl',
                      params:{
                        inQuestion:true,
                        questionCode:data.questionCode,
                        ClientID:data.params.ClientID,
                        ClientNumber:data.params.ClientNumber,
                        selectedStaff:data.params.selectedStaff,
                        defaultRole:data.params.defaultRole,
                        tagUser:data.params.tagUser,
                         selectedUsers:data.currentUsersSelected,
                         locationFilterField:data.locationAuditField


                      },
                };

                

                
                 vm.refreshParams=new Date().getTime();


                          vm.renderCustomDialog=true;
              });


              

              
        if(vm.readOnly){
          
            $('.surveyFinished').hide();
            $('.surveySaved').hide();
             tempSurvey.mode='display';

        }
        // this.survey.render('surveyContainer',{model: this.survey});
        vm.survey=tempSurvey;


        setTimeout(function(){
            $('#pdf-export').css('visibility','hidden');
        },100);
         vm.waitToClearinterval=setInterval(function(){
             $('.sv-list__filter').remove();

        },100);   

         });
        
       
          
       
        
      });
          
      
       



    

          
      
       



  },
   beforeDestroy() {
    if (this.waitToClearinterval) {
      clearInterval(this.waitToClearinterval);
    }
},
  methods:{   
    async checkForCollaborators(assessmentID,assessmentType,model,modelID,resultSetID,treatmentID,params){
      const collab=await assessmentService.checkForResultCollaborators(assessmentID,assessmentType,model,modelID,resultSetID,treatmentID,params).then(response=>{
        if(response.error){
          this.hasCollaborators=false;
        }else{
          this.hasCollaborators=true;
        }
      });
                

      return collab;

    },
    collaboratorClosed(){

          let routeParams=this.$route.params;

          this.checkForCollaborators(this.dialogSettings.assessmentID,this.dialogSettings.assessmentType,this.dialogSettings.model,this.dialogSettings.modelID,this.dialogSettings.resultSetID?this.dialogSettings.resultSetID:(this.resultSetID?this.resultSetID:0),this.treatmentID,{answers: this.survey.data,completed:1,routeParams:routeParams,passedParams:this.params,inCollab:this.inCollab,fromLogin:this.fromLogin});

         // this.renderCollaboratorDialog=false;

    },
    launchCollaborator(){




      $('#surveyContainer').data('launchingCollaborators',1);

      $("#surveyContainer").find('.sv_save_btn').click();


    },
    recursivelyChangeDropdown(elements,surveyData){
        elements.forEach((e) => {

            if(e.elements){
                this.recursivelyChangeDropdown(e.elements,surveyData);

            }else{
                if (e.type === 'dropdown') {
                         

                            e.type = 'comment';

                            if(typeof surveyData[e.code] !=='undefined'){
                                let selectedChoice=e.choices.filter(el=>{return el.value===surveyData[e.code] });

                                surveyData[e.code]=selectedChoice[0].text;


                            }

                }

            }
                      
        });


    },
    validateMultiStaff(sender,options){
      

    },
    closeQuestionDialog(){
      this.renderCustomDialog=false;
    },
      getFormStatus(){
        return   assessmentService.checkIfReadOnly({
            assessmentType: this.$route.params.assessmentType, // this.$route.params.assessmentType,
            assessmentID: this.$route.params.assessmentID, // this.$route.params.assessmentID,
            model: this.$route.params.model,
            modelID:this.$route.params.modelID,
            treatmentID:this.$route.params.treatmentID?this.$route.params.treatmentID:null
        });
      },
    timeoutPopup(sessionTime){
          var selfObj = this;

          var sessionEnd = setTimeout(function() {
            //save results 
            //console.log("method ", selfObj);
            assessmentService.saveResults(selfObj.dialogSettings.assessmentID,selfObj.dialogSettings.assessmentType,selfObj.dialogSettings.model,selfObj.dialogSettings.modelID,selfObj.dialogSettings.resultSetID?selfObj.dialogSettings.resultSetID:0,selfObj.treatmentID,{answers:selfObj.survey.data})
      
            
            if(!selfObj.$store.getters.isUsingYii){
            selfObj.$router.push({
              name:'logout'
            });
            }
            else{
              //console.log('else');
              window.parent.$(window.parent.document).find('[name = collectionHolder]').parent().find('#sessionTimeLimit').trigger('click');
            }
            
        }, 170000); 

          selfObj.$bvModal.msgBoxOk('Your session will expire in 3 minutes. Press OK to continue your session')
            .then(value => {
              //silent call to server reseting session 
              //console.log("here");
              sessionService.keepAlive().then(data=>{

              }).catch(err=>{
                console.log("error")
              });

              
              clearTimeout(sessionEnd)
              sessionTimer = setTimeout(() => selfObj.timeoutPopup(sessionTime), sessionTime * 1000 - 180000)
              //console.log(sessionTimer);
            })
            .catch(err => {
            // An error occurred
            });
    }
  }
}
</script>

<style scoped>
.sd-navigation__complete-btn{
  visibility: hidden !important;
}
</style>