<template>
  <div>
      <b-modal v-if="renderJsonCopyDialog" visible hide-footer hide-header-close>
        <template #modal-title>
          JSON Copy
        </template>
        <div class="d-block text-center">
          <b-textarea :id="jsonCopyJsonTextId" v-model="jsonCopyJsonText"></b-textarea>
        </div>
        <b-button class="mt-3" variant="success" @click="handleClickOkJsonCopyDialog">Copy to Clipboard</b-button>
        <b-button class="mt-3" variant="danger" @click="handleClickCancelJsonCopyDialog">Cancel</b-button>
      </b-modal>
    <div class="d-flex w-100">
      <div
        :class="isEcenter && assessmentid ? 'd-flex w-50' : 'd-flex w-40'"
        :style="{ marginLeft: isEcenter && assessmentid ? '69%' : '80%' }"
      >
        <b-button
          class="ml-2 global-primary__bg btn-secondary btn-md"
          @click="resetCodesClicked"
          v-if="isEcenter && assessmentid"
          >{{ $t("SurveyBuilder.reset") }}</b-button
        >
        <b-button
          class="ml-2 global-primary__bg btn-secondary btn-md"
          @click="openCollaborationForm"
          v-html="$t('SurveyBuilder.collaborators', {totalCollaborators:totalCollaborators})">
        </b-button>
        <b-button
          class="ml-2 global-primary__bg btn-secondary btn-md"
          @click="openJsonCopyDialog"
          v-if="isEcenter && assessmentid"
          >Copy JSON - Shallow</b-button
        >
      </div>
    </div>
         <b-alert
      :show="showError"
      dismissible
      variant="danger"
      @dismissed="showError=false"
    >
      <div v-html="formError"></div>
     
      </b-alert>
     <div id="surveyCreatorContainer" style="height: 800px"></div>
    <AddDomainDialog
      :questionObject="questionAdded"
      v-if="renderAddDomainDialog"
      @onCloseAddDomain="closeAddDomainDialog"
      @onClickAddDomain="closeAddDomainDialog"
    ></AddDomainDialog>

    <AddNewFormDialog
      v-if="showAddNewForm"
      :assessmentType="
        serverParams.AssessmentType.length == 1
          ? serverParams.AssessmentType[0]
          : null
      "
      @onClickSaveAssessmentName="assessmentTitleCreated"
      @onCloseAddAssessmentTitle="closeAssessmentTitleDialog"
    >
    </AddNewFormDialog>

 

   <FormCollaborationModal
      @collaboratorsClosed="collaboratorClosed"
      v-if="assessmentidParams"
      :AssessmentID="assessmentidParams"
      :key="refreshCollabParams"
      ref="formCollaborationDef"
    ></FormCollaborationModal>

        <QuestionCustomDialog
      v-if="renderCustomDialog"
      :formSettings="customDialogSettings"
      @linkQuestionValue="closeQuestionDialog"
      @onCloseCustomDialog="closeQuestionDialog"
      ref="questionCustomDialogRef"
      :key="refreshParams"
    ></QuestionCustomDialog>

    <TaxonomyDialog
      v-if="renderTaxonomyDialog"
      :lvCustomFieldSetName="taxonomyFieldSet"
      :lvServiceName="taxonomyService"
      :okTitle="taxonomyBtnName"
      :showOkBtn="true"
      :showCancelBtn="false"
      @onClickOk="closeTaxonomyDialog"
    >
      <template v-slot:dialogContent>
        <ListView
          :serviceNameProp="taxonomyService"
          :customFieldSetNameProp="taxonomyFieldSet"
          @onRowSelected="handleRowSelected($event)"
          :selectedUsers="value"
          :showSelectAll="false"
          :passedParams="passedParams"
          ref="listViewInstance"
            :inPicker="true"
        ></ListView>
      </template>
    </TaxonomyDialog>

     <CustomFieldPickerDialog
      v-if="renderCustomFieldDialog"
      lvServiceName="customFieldPicker"
      cancelTitle="Close"
      :showOkBtn="false"
      :showCancelBtn="true"
      @onClickOk="closeCustomFieldPicker"
      @onClickCancel="closeCustomFieldPickerNoUpdate"
    >
      <template v-slot:dialogContent>
        <ListView
          :key="refreshCustomFieldDialogParams"

          serviceNameProp="customFieldPicker"
          @onRowSelected="handleCustomFieldRowSelected($event)"
          :selectedUsers="value"
          :showSelectAll="false"
          :passedParams="customFieldPickerParams"
          ref="listViewInstance"
          :inPicker="true"
        ></ListView>
      </template>
    </CustomFieldPickerDialog>


     <CustomFieldSetPickerDialog
      v-if="rederCustomFieldSetDialog"

      lvServiceName="customFieldPicker"
      okTitle="Select"
      :showOkBtn="true"
      :showCancelBtn="false"
      @onClickOk="closeCustomFieldSetPicker"
    >
      <template v-slot:dialogContent>
        <ListView
              :key="refreshCustomFieldDialogParams"

          serviceNameProp="customFieldPicker"
          @onRowSelected="handleCustomFieldSetRowSelected($event)"
          :selectedUsers="value"
          :showSelectAll="false"
          :passedParams="customFieldPickerParams"
          ref="listViewInstance"
        ></ListView>
      </template>
    </CustomFieldSetPickerDialog>

    

  </div>
</template>

<script>
import * as SurveyCreator from "survey-creator-knockout";
import * as SurveyCore  from "survey-core";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { localization } from "survey-creator-core";

import AddDomainDialog from '@/components/AddDomainDialog.vue';
import AddNewFormDialog from '@/components/AddNewFormDialog.vue';
import TaxonomyDialog from '@/components/ecenterDialog.vue';
import CustomFieldSetPickerDialog from '@/components/ecenterDialog.vue';

import CustomFieldPickerDialog from '@/components/ecenterDialog.vue';

import FormCollaborationModal from '@/components/FormCollaborationModal.vue';
import ListViewPickerBadge from '@/components/ListViewPickerBadge.vue';

import { init as customClientLocationCurrent } from '@/components/customCollectionWidgets/customClientCurrentLocation';
import { init as customClientList } from '@/components/customCollectionWidgets/customclientlistquestion';
import { init as customClientLocationHistory } from '@/components/customCollectionWidgets/customClientLocationHistory';
import { init as customClientProgramCurrent } from '@/components/customCollectionWidgets/customClientPrograms';
import { init as customClientReferralDenialList } from '@/components/customCollectionWidgets/customClientReferralDenial';
import { init as customCollectionInstructions } from '@/components/customCollectionWidgets/customCollectionInstructions';
import { init as customCurrentUserWidget } from '@/components/customCollectionWidgets/customCurrentStaff';
import { init as customDateWidget } from '@/components/customCollectionWidgets/customdatepicker';
import { init as customFamilyWidget } from '@/components/customCollectionWidgets/customfamilylistquestion';
import { init as customLocationList } from '@/components/customCollectionWidgets/customlocationlistquestion';
import { init as customRateWidget } from '@/components/customCollectionWidgets/customratingquestion';
import { init as customStaffListWidget } from '@/components/customCollectionWidgets/customstafflistquestion';
import { init as customStaffListWidgetMul } from '@/components/customCollectionWidgets/customstafflistquestionmultiple';
import { init as customWidgetTinyMCEPropEditor } from '@/components/customCollectionWidgets/customwidgettinyMCEPropEditor';
import { init as customwidgetBadges } from '@/components/customCollectionWidgets/customwidgetBadges';
import { init as customFieldQuestion} from '@/components/customCollectionWidgets/customFieldQuestion';

import { API_URL } from '@/config';
import * as formService from '@/services/forms';
import { apiPost } from '@/services/generic';
import { deepCopy, isObject } from '@/utils/langUtils';

import sanitizeHtml from 'sanitize-html';
import lodash from 'lodash';
import { getData } from '@/utils/get';

import { VueEditor } from 'vue2-editor';
import customInputFactory from './customCollectionWidgets/customInputFactory';
import { surveyShallowCopy } from '@/utils/survejsShallowCopy';





SurveyCore.CustomWidgetCollection.Instance.widgetsValues=new Array;


localization.getLocale('').ed.designer = 'Collection Designer';
localization.getLocale('').ed.testSurvey = 'Test Collection';
localization.getLocale('').ed.logic = 'Survey Logic';

localization.getLocale('').ed.testSurveyAgain =
  'Test Collection Again';
localization.getLocale('').ed.testSurveyWidth =
  'Collection Width: ';
localization.getLocale('').ed.surveyResults =
  'Collection Results';
localization.getLocale('').ed.surveyResults =
  'Collection Results';
localization.getLocale('').ed.survey = 'Collection';
localization.getLocale('').ed.settingsTooltip =
  'Open General Settings';
localization.getLocale('').ed.settings = 'General Settings';
localization.getLocale('').ed.saveSurveyTooltip =
  'Save Collection';
localization.getLocale('').ed.saveSurvey = 'Save Collection';
localization.getLocale('').ed.surveyTypeName = 'Collection';

const currentQurstionSettings = localization.getLocale('').qt;
currentQurstionSettings.panel = 'Question Group';
currentQurstionSettings.text = 'Text Line';
currentQurstionSettings.comment = 'Text Box';
currentQurstionSettings.radiogroup = 'Single Choice List';


//SurveyCreator.StylesManager.applyTheme('bootstrap');
SurveyCore.StylesManager.applyTheme('defaultV2');

SurveyCore.matrixDropdownColumnTypes['datepicker']={};
SurveyCreator.SurveyQuestionEditorDefinition.definition["matrixdropdowncolumn@datepicker"] = {
            properties: ["DateFormat","Override Completion Date","Default"],
            
};

SurveyCore.Serializer.addProperty('matrixdropdowncolumn', { name: 'makeControlVisibleColumn',displayName:'Make Control Visible Based On Column',type:'Text'});
SurveyCore.Serializer.addProperty('matrixdropdowncolumn', { name: 'makeControlVisibleColumnValue',displayName:'Trigger Value',type:'Text'});
SurveyCore.Serializer.addProperty('matrixdropdowncolumn', { name: 'requiredIfVisible',displayName:'Required If Visible',type:'boolean'});
SurveyCore.Serializer.addProperty('matrixdropdowncolumn', { name: 'textAlignment',displayName:'Text Alignment',default:'left',type:'dropdown', choices:[
  {text:'Left',value:'left'},
  {text:'Center',value:'center'},
  {text:'Right',value:'right'},



]});
SurveyCore.Serializer.addProperty('matrixdropdowncolumn', { name: 'hideChoiceLabel',displayName:'Hide Choice Label',type:'boolean'});

SurveyCreator.SurveyQuestionEditorDefinition.definition["matrixdropdowncolumn@checkbox"].properties.push('textAlignment');
SurveyCreator.SurveyQuestionEditorDefinition.definition["matrixdropdowncolumn@checkbox"].properties.push('hideChoiceLabel');

                
        

            SurveyCore.matrixDropdownColumnTypes['list-resultfile']={};
                  SurveyCreator.SurveyQuestionEditorDefinition.definition["matrixdropdowncolumn@list-resultfile"] = {
                  properties:['makeControlVisibleColumn','makeControlVisibleColumnValue','requiredIfVisible'],
                    tabs:[
                        {name:'test',index:100}

                    ]
                  };



export default {
  name: 'survey-creator-component',
  data() {
    return {
       allowMultipleValues: false,
      assessmentidParams: this.assessmentid,
      category: [],
      changingPublished: false,
      choicesIsLoading: false,
      customDialogSettings: {},
      defaultDomainID: null,
      refreshCustomFieldDialogParams:null,
      domainList: [],
      domainRefreshUrl: null,
      extraParams: {},
      formError: null,
      instructionsDomain: null,
      isCreator: false,
      isEcenter: false,
      isPublished: false,
      lastCode: null,
      new_id: null,
      passedParams: null,
      questionAdded: null,
      refreshCollabParams: null,
      refreshParams: null,
      removedPropertyList: {},
      renderAddDomainDialog: false,
      renderCustomDialog: false,
      renderFormCollaboration: false,
      renderTaxonomyDialog: false,
      requiredProperties: {},
      showAddNewForm: false,
      surveyCreator: null,
      tags: [],
      taxonomyBtnName: 'Continue',
      taxonomyFieldSet: '',
      taxonomyService: '',
      testModel: false,
      totalCollaborators: 0,
      value: [],
      hideSuccess:false,
      questionProp:null,
      isPublishing:false,
      showError:false,
      waitToClearinterval:null,
      changeToolboxIcons:null,
      renderCustomFieldDialog:false,
      rederCustomFieldSetDialog:false,
      tempQuestion:null,
      customFieldPickerParams:{},
      propertiesToHide:[],
      renderJsonCopyDialog:false,
      jsonCopyJsonTextId:null,
      jsonCopyJsonText:'',
      inputFactoryWidgets:[],
      widgetParams:{}
      
    };
  },
  components: {
    SurveyCreator,
    AddDomainDialog,
    AddNewFormDialog,
    TaxonomyDialog,
    FormCollaborationModal,
    ListViewPickerBadge,
     ListView: () => import('@/app/list-view/index.vue'),
     QuestionCustomDialog: () => import('@/components/CustomDialog.vue'),
    CustomFieldPickerDialog,
    CustomFieldSetPickerDialog


   
  },
  props: [
    'assessmentid',
    'codePrefix',
    'partitionPrefix',
    'questiontypes',
    'currentState',
    'serverParams',
    'useAdvancedEditor',
    'defaultDomain',
    'assessmentDomains',
    'ignoreClientQuestionOptions',
    'hdsUserParam',
    "ecenterAdmin"

  ],

  created() {

   
     formService.getDomainList().then(response => {
      this.domainList = response;
    });




    if (!this.useAdvancedEditor) {
      this.removePropertyList();
    }else if(this.hdsUserParam){
      this.removePropertyList(this.hdsUserParam);

      this.getHiddenPropertiesList();

    }
  },
  mounted() {

      let vm=this;

      this.isPublished = this.currentState;
      this.defaultDomainID = this.defaultDomain;



      this.widgetParams={...this.$route.params,assessmentID:this.assessmentid,inCreator:true};


      
      customDateWidget(SurveyCore, this);

       customRateWidget(SurveyCore, this);

    if (this.useAdvancedEditor) {

      if(!this.ignoreClientQuestionOptions){
              customCurrentUserWidget(SurveyCore, this);

              customStaffListWidget(SurveyCore, this);

             customClientLocationCurrent(SurveyCore, this);
              customClientLocationHistory(SurveyCore, this);
              customClientProgramCurrent(SurveyCore, this);
              customLocationList(SurveyCore, this);
              customStaffListWidgetMul(SurveyCore, this);
              customStaffListWidgetMul(SurveyCore, this);

      }
 

      if(!this.ignoreClientQuestionOptions){

            customClientList(SurveyCore, this);
            customClientReferralDenialList(SurveyCore, this);
      }

      customFieldQuestion(SurveyCore,this);
      customCollectionInstructions(SurveyCore, this);
      formService.getSurveyCustomWidgets(this.widgetParams).then(widgetsRes => {
          if (!Array.isArray(widgetsRes) || widgetsRes.length === 0)
            return;
          for (const widgetData of widgetsRes) {
            customInputFactory(SurveyCore, this, widgetData);

            this.inputFactoryWidgets.push(widgetData);
          }


        }).catch(erro => {
          console.log(erro);
        });
       if(!this.ignoreClientQuestionOptions){
            customFamilyWidget(SurveyCore, this);
       }

    
      customWidgetTinyMCEPropEditor(SurveyCore,this);
      customwidgetBadges(SurveyCore,this);
    }

   



    //if coming from yiiside
    //then: call getTheDomain() pass in $assessmentID and in API function, tweak it to tell if assessmentID in then look upassessment model first and create domain with assessmentID

    if (this.$store.getters.isUsingYii) {
      formService
        .getDomainTitle({ assessmentID: this.assessmentid })
        .then(response => {
          this.defaultDomainID = response.DomainID;
        });
    }
  
     const creatorOptions = {
      haveCommercialLicense: true, // license
      designerHeight: '',
      isAutoSave: false,
      questionTypes: this.questiontypes,
      showJSONEditorTab: this.ecenterAdmin,
      showEmbeddedSurveyTab: false,
      showLogicTab:this.useAdvancedEditor,


    };


   // SurveyCreator.settings.questionConvertMode=1;
    
    delete SurveyCore.matrixDropdownColumnTypes.boolean;
    delete SurveyCore.matrixDropdownColumnTypes.expression;
    delete SurveyCore.matrixDropdownColumnTypes.tagbox;



    if (!vm.currentState) {
      var surveyProperties = SurveyCore.Serializer.getProperties('survey');
      vm.disableProprties('survey', surveyProperties, false);
      var questionProperties = SurveyCore.Serializer.getProperties('question');
      vm.disableProprties('question', questionProperties, false);
      var panelProperties = SurveyCore.Serializer.getProperties('panel');
      vm.disableProprties('panel', panelProperties, false);
      var panelProperties = SurveyCore.Serializer.getProperties('itemvalue');
      vm.disableProprties('itemvalue', panelProperties, false);
    }

    vm.requiredProperties['survey'] = [
      {
        elementName: 'survey',
        field: 'title',
        requiredMessage: 'Survey Title Is Required',
      },
    ];

     var htmlDisplayLink = {
      fit:function(prop){
         return prop.type === "htmllink";

      },
      getJSON:function (obj, prop, options){


         if (prop.displayName == 'Domain') {
      
            return {type:'dropdown',choices:vm.domainList}
        }else{
            
            return {type:'badges'}
        }
      },
      onAfterRenderQuestion:function(obj,prop,options){

          let htmlElement=options.htmlElement;



        


          if (prop.displayName == 'Domain') {
            $(htmlElement).append(
                "<a class='clickLink '>Add New Domain </a>"
              );
          }else if (prop.displayName == 'Field To Use') {
            $(htmlElement).append(
                "<a class='clickLink'>Select Custom Field </a>"
              );

          }else{
             $(htmlElement).append(
                "<a class='clickLink'>" + prop.displayName + '</a>'
              );


                $(htmlElement).find("input").parent().parent().find('.spg-question__title').hide();

                $(htmlElement).find("input").hide();

          }
           



        $(htmlElement).click(function(){
            $(htmlElement).find('.sv-list__filter').hide();

            $(htmlElement).find('.sv-dropdown_clean-button-svg').remove();

        });


        if(!vm.waitToClearinterval){
            
              vm.waitToClearinterval=setInterval(function(){
                      $(htmlElement).find('.sv-dropdown_clean-button-svg').remove();
                      $('.sv-list__filter').remove();

                  },100);        
          

        }
   
        $(htmlElement).on('click', '.clickLink', function() {

          

          if (prop.displayName == 'Domain') {
            vm.questionProp=options.question;
            vm.questionAdded =obj;
            vm.openAddDomainDialog();
          }else if(prop.displayName=='Field To Use'){
            vm.questionAdded=obj;
            vm.openCustomFieldPicker();
          }else if(prop.displayName=='Custom Field Set'){
            vm.openCustomFieldSetPicker();

          } else if (prop.displayName == 'Edit Category') {
            vm.openTaxonomyDialog('category');
          } else if (prop.displayName == 'Edit Tags') {
            vm.openTaxonomyDialog('tag');
          }
        });


         $(htmlElement).on('change', '.clickLink', function() {



          if (prop.displayName == 'Domain') {
            vm.questionAdded =obj;
            vm.openAddDomainDialog();
          }else if(prop.displayName=='Custom Field'){
            vm.questionAdded=obj;
            vm.openCustomFieldPicker();
         }else if(prop.displayName=='Custom Field Set'){
            vm.openCustomFieldSetPicker();

          } else if (prop.displayName == 'Edit Category') {
            vm.openTaxonomyDialog('category');
          } else if (prop.displayName == 'Edit Tags') {
            vm.openTaxonomyDialog('tag');
          }
        });

        $(htmlElement)
          .parent()
          .find('label')
          .hide();
      
      },
      /*
      render: function(editor, htmlElement) {
        $(htmlElement).append(
          "<a class='clickLink'>" + editor.displayName + '</a>'
        );

        $(htmlElement).on('click', '.clickLink', function() {
          if (editor.displayName == 'Add New Domain') {
            vm.questionAdded = editor.objectValue;
            vm.openAddDomainDialog(editor.objectValue);
          } else if (editor.displayName == 'Edit Category') {
            vm.openTaxonomyDialog('category');
          } else if (editor.displayName == 'Edit Tags') {
            vm.openTaxonomyDialog('tag');
          }
        });

        $(htmlElement)
          .parent()
          .find('label')
          .hide();
      },
      */
    };


 
  $('body').on('mouseenter',"[title='Add Question']",function(){
    $('.sv-list__filter').remove();
              $('.svc-matrix-cell__question-controls').remove();


     
          
            
              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Date Picker')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();

                $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Date Picker')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-calendar');
            ;


              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Staff')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();
            
            $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Staff')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-users');
            ;
            

              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Client')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();

              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Client')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-child');
            ;


            $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Collection')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();


              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Collection')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-file-invoice');
            ;

             $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Location')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();

              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Location')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-building');

            
             $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Custom Field')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();

              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Custom Field')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-list');
  });






                                           
    $('body').on('click',".sv-dots__item",function(){

            $('.sv-list__filter').remove();
              $('.svc-matrix-cell__question-controls').remove();


     
          
            
              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Date Picker')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();

                $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Date Picker')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-calendar');
            ;


              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Staff')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();
            
            $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Staff')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-users');
            ;
            

              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Client')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();

              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Client')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-child');
            ;


            $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Collection')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();


              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Collection')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-file-invoice');
            ;

             $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Location')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();

              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Location')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-building');

            
             $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Custom Field')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();

              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Custom Field')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-list');

    });

  /*
   this.changeToolboxIcons= setInterval(function(){
             $('.sv-list__filter').remove();
              $('.svc-matrix-cell__question-controls').remove();


        
               $('.sv-list__container').find("li[role=option]")
            .find('.sv-svg-icon')
            .remove();

          
            
              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Date Picker')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();

                $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Date Picker')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-calendar');
            ;


              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Staff')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();
            
            $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Staff')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-users');
            ;
            

              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Client')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();

              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Client')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-child');
            ;


            $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Collection')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();


              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Collection')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-file-invoice');
            ;

             $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Location')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();

              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Location')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-building');

            
             $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Custom Field')")
            .closest('li')
             .find('.sv-svg-icon')
            .remove();

              $('.sv-list__container').find("li[role=option]")
            .find("span:contains('Custom Field')")
            .closest('li')
            .find('.sv-list__item-icon')
             .addClass('fas')
            .addClass('fa-list');

        },100);   
        */
 
    SurveyCreator.PropertyGridEditorCollection.register(
      htmlDisplayLink
    );
    
 
   

      



    /*
      
      SurveyCore.Serializer.addProperty("question", {
            name: "domain",
            type:'htmllink',
            displayName:'Add New Domain',
            isRequired: true,
            category: "general",
            visibleIndex: 3,
          });
      */
      var htmlDisplayTaxonomy = {
      render: function(editor, htmlElement) {
        if (editor.displayName == 'Category') {
          htmlElement.id = 'categoryDisplay';
          vm.updateTaxonomyProperty('category');
        } else if (editor.displayName == 'Tags') {
          htmlElement.id = 'tagDisplay';
          vm.updateTaxonomyProperty('tags');
        }
      },
    };

    function doMarkdown(survey, options) {

     

      sanitizeHtml(options.text, {
        allowedTags: sanitizeHtml.defaults.allowedTags,
        allowedAttributes: {
          ...sanitizeHtml.defaults.allowedAttributes,
          div: ['style'],
        },
        allowedStyles: {
          div: {
            height: [/^[0-9]{1,}px|[0-9]{1,}%/],
          },
        },
      });


      /*
      if(options.element.startWithNewLine){
        options.element.startWithNewLine=1;

      }
      */

      options.html = options.text;
    }
     

      function checkStaffRelationship(sender, options) {
      if (
        options.question.customWidgetValue &&
        options.question.customWidgetValue.name == 'staff-list-multiple'
      ) {
        var questionParsed = JSON.parse(options.question.value);
        var missingRelationship = false;
        $.each(questionParsed, function(index, value) {
          if (!value.Relationship || value.Relationship == 'null') {
            missingRelationship = true;
          }
        });
        if (missingRelationship) {
          options.error =
            'Missing relationship for staff. One or more of the staff below does not have a relationship to client specified. Please review and try again';
        }
      }
    }


    
     SurveyCreator.PropertyGridEditorCollection.register({
        fit: function (prop) {

          
          return prop.type == "html" || prop.type=='editor-prop';
        },
        getJSON: function (obj, prop, options) {
          return {type: "editor-prop"};
        }
      });
    
    /*
    if (vm.useAdvancedEditor) {
      SurveyCore.SurveyPropertyModalEditor.registerCustomWidget(
        'text',
        vue_editor_modaleditor
      );
      SurveyCore.SurveyPropertyModalEditor.registerCustomWidget(
        'html',
        vue_editor_modaleditor
      );
    }
    */
    
   

   
   /*
       SurveyCore.Serializer.addClass("ordertableitem", [
       
      ],
      // We create a standard Survey.ItemValue instance.
      // The third parameter said that the actual type is "ordertableitem"
      // SurveyJS will use properties definition from "ordertableitem" class
      // and it will define "price" property for every new instance
        function () {
        return new SurveyCore.QuestionHtmlModel(ordertableitem);
      }, "itemvalue");

     SurveyCore.Serializer.addProperty("text", {
        name: "DomainID",
        type:'datepicker',
        category: "general",
        visibleIndex: 3
      });
*/
    
    /*
    SurveyCreator.PropertyGridEditorCollection.register(
      'displayTaxonomy',
      htmlDisplayTaxonomy
    );
    */
   

     formService
      .getCustomProperties({ serverParams: this.serverParams })
      .then(response => {
        var propertyDefaults = new Object()

        let selfTest=vm;
        $.each(response, function(elementname, propertiesArray) {
          if (propertiesArray['parent']) {
            if (propertiesArray['properties']['visible'] && propertiesArray['properties']['isDomain']) {
                
                
                propertiesArray['properties']['isDynamicChoices']=true;
                 propertiesArray['properties']['choices']= function(obj) { 
                
                let list=null;
                $.ajax({
                  url:API_URL+"/apiSurveyCreator/getDomains",
                  async:false,
                  dataType: 'json',

                  success:function(data){
                    list=data.data;
                  }
                });

                return list;
              }
              
              
              //propertiesArray['properties']['choices'] = vm.domainList
            }
            SurveyCore.JsonObject.metaData.addClass(
              elementname,
              [
                {
                  ...propertiesArray['properties'],
                },
              ],
              null,
              'itemvalue'
            );
            var choicesProp = SurveyCore.JsonObject.metaData.findProperty(
              propertiesArray['parent'],
              propertiesArray['property']
            );
            choicesProp.className = elementname;
          } else {
            propertyDefaults[elementname] = new Object();

            $.each(propertiesArray, function(propertyKey, propertyValues) {
              if (propertyValues['isRequired']) {
                propertyValues['isRequired'] = true;
                if (typeof vm.requiredProperties[elementname] === 'undefined') {
                  vm.requiredProperties[elementname] = new Array();
                }
                vm.requiredProperties[elementname].push({
                  elementName: elementname,
                  field: propertyValues.name,
                  requiredMessage: propertyValues.requiredMessage,
                });
              }

            
              if (propertyValues['getChoicesUrl']) {
                /*
               
               propertyValues['choices'] = function(obj, choicesCallback) {
                  vm.choicesFromUrl(
                    propertyValues['getChoicesUrl'],
                    choicesCallback
                  );
                };
                SurveyCore.Serializer.addProperty(elementname, {
                  name: 'getChoicesUrl_' + propertyValues.name,
                  visible: false,
                  default: propertyValues['getChoicesUrl'],
                });

                propertyDefaults[elementname][
                  'getChoicesUrl_' + propertyValues.name
                ] = propertyValues['getChoicesUrl'];
                */
              }
              SurveyCore.Serializer.addProperty(elementname, propertyValues);

              if (propertyValues['initialValue']) {
                propertyDefaults[elementname][propertyValues['name']] =
                  propertyValues['initialValue'];
              }
            });


            SurveyCore.Serializer.addProperty('datepicker', {
              name: 'date format',
              type: 'text',
            });
          }
        });


    const creator = new SurveyCreator.SurveyCreator(creatorOptions);
        creator.survey.onTextMarkdown.add(doMarkdown);
        creator.onNotify.add(vm.showErrorMsg);


       creator.onSurveyInstanceCreated.add(function(sender, options) {
            if(options.reason == "designer") {
             
                       vm.testModel = false;

                    
                      options.survey.onUpdatePanelCssClasses.add(function(sender,options){

                           options.cssClasses.panel.content=" panel-content"

    
                        
                              options.cssClasses.panel.container += ' card shadow border-top panelBorder panelContainer';
                              // p.css.row="card-body";
                              options.cssClasses.panel.title =
                                'card-header py-3 align-items-center justify-content-between global-primary__bg global-primary__bg--no-hover panelTitle';
                      });

                      options.survey.onTextMarkdown.add(doMarkdown);
                    
                      
                      options.survey.onAfterRenderQuestion.add(function(sender,options){
                           if(  options.question.startWithNewLine){
                            options.question.startWithNewLine=1;
                           }



                           if(!options.question.domain){
                            options.question.domain=vm.defaultDomainID;
                           }
                      });

            }
          });

      vm.surveyCreator=creator;


          if (parseInt(vm.currentState) == 1) {
            /*
          setTimeout(function() {
            $('.svd_save_btn').hide();
            $('.svd_save_btn')
              .closest('span')
              .hide();
            $('#unpublish-survey').show();
            $('#publish-survey').hide();
            $('.svd-designer-container')
              .find('[aria-label=Toolbox]')
              .hide();
            var surveyProperties = SurveyKo.Serializer.getProperties('survey');
            vm.disableProprties('survey', surveyProperties, true);
            var questionProperties = SurveyKo.Serializer.getProperties(
              'question'
            );
            vm.disableProprties('question', questionProperties, true);
            var panelProperties = SurveyKo.Serializer.getProperties('panel');
            vm.disableProprties('panel', panelProperties, true);
            var panelProperties = SurveyKo.Serializer.getProperties(
              'itemvalue'
            );
            vm.disableProprties('itemvalue', panelProperties, true);
          }, 100);
          */
        }
        if (propertyDefaults['survey']) {
          var inst = vm.surveyCreator;



          $.each(propertyDefaults['survey'], function(elementKey, value) {
            inst.survey[elementKey] = value;
          });
          inst.survey['showCompletedPage'] = false;

        

        }

        if (vm.assessmentid) {
          formService.getSurvey(vm.assessmentid).then(response => {
            if (response) {
              vm.surveyCreator.text = response.jsonData;
              vm.isCreator = response.isCreator;
              vm.isEcenter = response.isEcenter;
              vm.totalCollaborators = response.totalCollaborators;
              vm.lastCode = response.lastQuestionCode;
              vm.instructionsDomain = response.instructionsDomain;
                                            
              vm.defaultDomainID = response.DefaultDomainID;
              
            }
          });

          formService.getTaxonomies(vm.assessmentid).then(response => {
            vm.category = response.Category;
            vm.tags = response.Tags;
            vm.updateTaxonomyProperty('category');
            vm.updateTaxonomyProperty('tags');
          });
        } else {
          vm.showAddNewForm = true;
        }



        
        

   
        creator.toolbarItems.push(new SurveyCore.Action(
          {
            id: 'publish-survey',
            enabled:true,
            visible: true,
            title: parseInt(vm.isPublished)==1?'Unpublish':'Publish',
            action: function() {

              if(vm.extraParams['publish'] || parseInt(vm.isPublished)==1){
                  vm.extraParams['publish'] = 0;
                  vm.extraParams['unpublishing'] = 1;
                  
                  vm.changingPublished=true;

                  this.title='Publish'



              }else{
                  vm.extraParams['publish'] = 1;
                  vm.extraParams['unpublishing'] = 0;
                  this.title='Unpublish'
                  vm.isPublishing=true;

                   

              }
            

             

              $('[aria-label*=Save]').closest('button').attr("disabled",false);

              $('[aria-label*=Save]').closest('button').trigger('click');
             
              $('[aria-label*=Save]').closest('button').attr("disabled","disabled");


              if(vm.extraParams['publish']){
                 vm.surveyCreator.readOnly=true;
                 vm.isPublished=1;
              }else{
                vm.surveyCreator.readOnly=false;
                 vm.isPublished=0;

              }

             
            },
          },
          
        ));
       
        

        
        vm.surveyCreator.onShowingProperty.add(function(sender, options) {

                 if(vm.propertiesToHide &&  vm.propertiesToHide.indexOf(options.property.name)>-1){
                    options.canShow=false;
                    options.visible=false;
               }else if (options.obj.constructor.name.indexOf('QuestionMatrix') != -1) {
                  if (!vm.removedPropertyList['matrix']) {
                    vm.removedPropertyList['matrix'] = [];
                  }
                  options.canShow =
                    vm.removedPropertyList['matrix'].indexOf(options.property.name) ==
                    -1;
                }else if (options.obj.constructor.name.indexOf('MatrixDropdownColumn') != -1) {

                   if (!vm.removedPropertyList['matrix']) {
                    vm.removedPropertyList['matrix'] = [];
                  }
                  options.canShow =
                    vm.removedPropertyList['matrix'].indexOf(options.property.name) ==
                    -1;

                } else if (options.obj.constructor.name.indexOf('Question') != -1) {
                  if (!vm.removedPropertyList['question']) {
                    vm.removedPropertyList['question'] = [];
                  }
                  options.canShow =
                    vm.removedPropertyList['question'].indexOf(
                      options.property.name
                    ) == -1;
                
                } else {
                  if (
                    vm.removedPropertyList[options.obj.constructor.name.toLowerCase()]
                  ) {
                    options.canShow =
                      vm.removedPropertyList[
                        options.obj.constructor.name.toLowerCase()
                      ].indexOf(options.property.name) == -1;
                  }
                }
              });



        
        
    


     


    

   
    vm.isPublished = this.currentState;
    vm.defaultDomainID = this.defaultDomain;

  
     if(parseInt(vm.isPublished)==1){
        creator.readOnly=true;

        let waitForSave=setInterval(() => {
            if( $('[aria-label*=Save]').length>0){
                  clearInterval(waitForSave);
                  $('[aria-label*=Save]').closest('button').attr("disabled","disabled");

            }
        }, 100);

     }


 

    creator.render("surveyCreatorContainer");


    let waitForTab=setInterval(function(){
      if($('.svc-tabbed-menu-item-container').length>0){
          clearInterval(waitForTab);
          creator.makeNewViewActive('test');
          creator.makeNewViewActive('designer');





      }
    });


       
       
vm.surveyCreator.saveSurveyFunc = vm.saveSurvey;
vm.surveyCreator.toolbox.orderedQuestions=[
  "radiogroup",
  "checkbox",
  "dropdown",
  "text",
  "comment",
  "panel",
  "matrix",
  "matrixdropdown",
  "matrixdynamic",
  "html"

];

vm.surveyCreator.toolbox.forceCompact=true;


vm.surveyCreator.onQuestionAdded.add((sender, options) => {
          var q = options.question;


         
          q.elementType = 'question';

             q.code = vm.generateCode(sender.text, vm.codePrefix);

         
            q.title = vm.generateTitle(q.code, vm.codePrefix);
          


        
         




        


         q.titleLocation = 'top';
         q.descriptionLocation = 'underTitle';
          
          q.name = q.code;
          formService.getDomainList().then(response => {
            vm.domainList = response;
          });

          if(q.columns){
            $.each(q.columns,function(columnIndex,column){
                if(column.cellType && column.cellType=='default'){
                  column.cellType='dropdown';
                }
            });

          }


          
              q.domain = vm.defaultDomainID;
          
          
          q.css.question.title += ' questionTitle';


          
        if (q.constructor.name !='QuestionComment' && q.rows) {
            q.css.panel.container = 'card shadow border-top form__card';

            q.css.panel.title =
              'card-header py-3 d-flex flex-row align-items-center justify-content-between global-primary__bg global-primary__bg--no-hover';

            q.css.matrix.root = 'table sv_q_matrix shadow';
            $.each(q.rows, function(key, itemValue) {
              var index = key + 1;
              itemValue.value = q.code + '_' + index;
            });
          } else if (q.choices) {
            $.each(q.choices, function(index, item) {
              if (item.value.toString().indexOf('item') > -1) {
                q.choices[index].value = index + 1;
              }
            });
          }

          


          
          if (vm.useAdvancedEditor) {
            var siblingQuestion = vm.recursivelyFindQuestionInJson(
              sender.text,
              q.code
            );
            if (siblingQuestion) {
              var done = false;
              var domainToUse = null;
              $.each(sender.survey.pages, function(key, page) {
                $.each(page.questions, function(key, question) {
                  if (question.code == siblingQuestion) {
                    if (question.domain) {
                      domainToUse = question.domain;
                    }
                    done = true;
                    return false;
                  }
                });
                if (done) {
                  return false;
                }
              });
              if (domainToUse) {
                q.domain = domainToUse;
              }
            }
          } 

         
            q.startWithNewLine='true';
         
        
        });

        vm.surveyCreator.onPanelAdded.add((sender, options) => {
          let p = options.panel;
          p.elementType = 'panel';

          
           p.css.panel.content=" panel-content"


           
    
                        

          
           p.css.panel.container += ' card shadow border-top panelBorder panelContainer ';
          // p.css.row="card-body";
          p.css.panel.title =
                   'card-header py-3 align-items-center justify-content-between global-primary__bg global-primary__bg--no-hover panelTitle';
          //p.css.panel.description += ' panelDescription';

            let code=vm.generatePartitionCode(vm.surveyCreator.text,'PC');

          p.code=code;

            if(p.questionsValue){

              for(let question of p.questionsValue){
              

                     question.code = vm.generateCode(sender.text, vm.codePrefix);

         
                      question.name= question.code;
                     question.title = vm.generateTitle(question.code, vm.codePrefix);
          


              }
            }

        });

/*
        vm.surveyCreator.onEditorTabRendered.add((sender, options) => {
          vm.testModel = false;
        });
        */


      /*
      creator.onDesignerSurveyCreated.add((sender, options) => {
           
           options.survey.css.page.description += ' pageDescription';
            //options.survey.css.question.title += " questionTitle"
            options.survey.css.panel.container =
              ' card shadow  panelBorder ';
            //options.survey.css.row = "card-body";
            options.survey.css.panel.title =
              ' card-header py-3  align-items-center justify-content-between global-primary__bg global-primary__bg--no-hover panelTitle';
            //options.survey.css.panel.description += ' panelDescription';
            options.survey.css.matrix.root = 'table sv_q_matrix shadow';

            options.survey.css.question.number="";
            
          


            options.survey.css.panel.content=" panel-content"
            
               options.survey.onTextMarkdown.add(doMarkdown);
                  options.survey.onValidateQuestion.add(checkStaffRelationship);
               

                      $(".sd-panel__content").addClass('panel-content');



      });
      */

      vm.surveyCreator.onDesignerSurveyCreated.add((sender, options) => {
        options.survey.onMatrixAfterCellRender.add(function(sender,options){
             if(options.column.hideChoiceLabel){
                      $(options.htmlElement).find('.sd-item__control-label').hide();
              }

              if(options.column.textAlignment){
                        if(options.column.textAlignment=='left'){

                        }else if(options.column.textAlignment=='center'){
                          $(options.htmlElement).find('div').first().css('width','fit-content');
                          $(options.htmlElement).find('div').first().css('margin','auto');

                        }else if(options.column.textAlignment=='right'){
                          $(options.htmlElement).find('div').first().css('width','fit-content');
                          $(options.htmlElement).find('div').first().css('margin-left','auto');

                        }
                    
                  }
        });
      });

        vm.surveyCreator.onTestSurveyCreated.add((sender, options) => {
          vm.testModel = true;



         
       
          options.survey.onMatrixAfterCellRender.add(function(sender,options){
                  if(typeof options.column.makeControlVisibleColumn !=='undefined'){

                     let hideDom=true;
                     for(let cellObject of options.row.cells){
                        if(cellObject.column.name===options.column.makeControlVisibleColumn){
                          if(cellObject.questionValue.value){
                              if(Array.isArray(cellObject.questionValue.value)){
                                if(cellObject.questionValue.value.includes(options.column.makeControlVisibleColumnValue)){
                                    hideDom=false;
                                }

                              }else{
                                if(cellObject.questionValue.value==options.column.makeControlVisibleColumnValue){
                                  hideDom=false;
                                }
                              }
                          }
                        }
                     }

                     if(hideDom){
                        $(options.htmlElement).hide();
                     }

                    

                  }

                  if(options.column.hideChoiceLabel){
                      $(options.htmlElement).find('.sd-item__control-label').hide();
                  }


                  if(options.column.textAlignment){
                        if(options.column.textAlignment=='left'){

                        }else if(options.column.textAlignment=='center'){
                          $(options.htmlElement).find('div').first().css('width','fit-content');
                          $(options.htmlElement).find('div').first().css('margin','auto');

                        }else if(options.column.textAlignment=='right'){
                          $(options.htmlElement).find('div').first().css('width','fit-content');
                          $(options.htmlElement).find('div').first().css('margin-left','auto');

                        }
                    
                  }

          });

          options.survey.onMatrixCellValueChanged.add(function(sender,options){
                for(let cellObject of options.row.cells){
                    if(cellObject.column.makeControlVisibleColumn){
                      if(cellObject.column.makeControlVisibleColumn===options.columnName){
                            if(Array.isArray(options.value)){

                                if(options.value.includes(cellObject.column.makeControlVisibleColumnValue)){
                                  $('#'+cellObject.questionValue.id).closest('td').show();

                                }else{
                                  $('#'+cellObject.questionValue.id).closest('td').hide();

                                }

                            }else{
                              if(cellObject.column.makeControlVisibleColumnValue==options.value){
                                  $('#'+cellObject.questionValue.id).closest('td').show();
                              }else{
                                  $('#'+cellObject.questionValue.id).closest('td').hide();

                              }
                            }

                      }
                    }
                }

          });



          options.survey.onValueChanged.add((sender,options)=>{
            

      
             if(options.question.getType()=='matrix'){

                if(options.value && Object.keys(options.value).length>0){
                  
                  $("#"+options.question.id).find(".clearSelection_bttn").hide();

                  

                  for(let code in options.value ){


                    $("#"+options.question.id).find("#bttn_clear_"+code).show();

                  }
                

                }else{
                  $("#"+options.question.id).find(".clearSelection_bttn").hide();
                }

              }


          });



          options.survey.onAfterRenderQuestion.add((sender,options)=>{



           
            if(options.question.getType()=='matrix'){

              if($(options.htmlElement).find("input[type='radio']").length>0 && $(options.htmlElement).find("tr.sd-table__row").length>0){
                let index=0;
                $(options.htmlElement).find("tr.sd-table__row").each(function(){



                  $(this).append("<td style='vertical-align:middle'><button id='bttn_clear_"+options.question.rows[index].value+"' data-rowIndex="+options.question.rows[index].value+" type='button' style='float:right' class='clearSelection_bttn sd-action sd-action--icon sd-matrixdynamic__btn sd-action--negative sd-matrixdynamic__remove-btn' > <svg class='sv-svg-icon sd-action__icon' role='img' aria-label='Remove' style='width: 24px; height: 24px;'><use xlink:href='#icon-delete'></use><title>Clear</title></svg></button><td>");


                    $('#bttn_clear_'+options.question.rows[index].value).hide();
                  $(this).find('.clearSelection_bttn').click(function(){

                    
                  
                      if(typeof options.question.value !=='undefined'){


                     

                          let currentResponses=JSON.parse(JSON.stringify(options.question.value));





                          delete currentResponses[$(this).data('rowindex')];





                          

                          options.question.clearValue();

                          options.question.value=currentResponses;

                          

                          
                      

                        

                      }
                    
                  });
                  
                  index++;
                });
              
                 
                
              }
            }
          });
       
          options.survey.focusFirstQuestionAutomatic=false;

          options.survey.css.page.description += ' pageDescription';
          //options.survey.css.question.title += " questionTitle"
          options.survey.css.panel.container =
            ' card shadow  panelBorder ';
          //options.survey.css.row = "card-body";
          options.survey.css.panel.title =
            ' card-header py-3  align-items-center justify-content-between global-primary__bg global-primary__bg--no-hover panelTitle';
          //options.survey.css.panel.description += ' panelDescription';
          options.survey.css.matrix.root = 'table sv_q_matrix shadow';

           options.survey.css.question.number="";
          /*options.survey.css.navigationButton =
            'btn ml-2 btn-success float-right sv_complete_btn';
          */

          options.survey.css.panel.content+=" panel-content"
          options.survey.onTextMarkdown.add(doMarkdown);
          options.survey.onValidateQuestion.add(checkStaffRelationship);
          options.survey.clearInvisibleValues = 'onHidden';

          $.each(options.survey.pages, function(pageIndex, value) {
            $.each(value.questions, function(
              questionIndex,
              questionProperties
            ) {
              if (
                questionProperties.apiEndpoint &&
                (questionProperties.constructor.name.indexOf(
                  'QuestionDropdown'
                ) != -1 ||
                  questionProperties.constructor.name.indexOf(
                    'QuestionCheckbox'
                  ) != -1 ||
                  questionProperties.constructor.name.indexOf(
                    'QuestionRadiogroup'
                  ) != -1)
              ) {
                options.survey.pages[pageIndex].questions[
                  questionIndex
                ].choices = new Array();
                var data = {};
                if (questionProperties.apiEndpointParams) {
                  var paramsCouples = questionProperties.apiEndpointParams.split(
                    ','
                  );
                  $.each(paramsCouples, function(key, value) {
                    var couplesExploded = value.split(':');
                    data[couplesExploded[0]] = couplesExploded[1];
                  });
                }
                $.ajax({
                  url: API_URL + questionProperties.apiEndpoint,
                  method: 'POST',
                  dataType: 'json',
                  data: data,
                  success: function(response) {
                    options.survey.pages[pageIndex].questions[
                      questionIndex
                    ].choices = response;
                  },
                });
              }
            });
          });
        });

  



        vm.surveyCreator.onPropertyValueChanging.add((sender, options) => {

          if (
            options.obj.elementType &&
            options.obj.elementType == 'question'
          ) {
            if (options.propertyName == 'code') {
              setTimeout(function() {

                options.obj.name = options.newValue;
              }, 700);
            }
          }
        });


        vm.surveyCreator.onItemValueAdded.add((sender, options) => {
         /*
         if (options.propertyName == 'rows') {
            var code = options.obj.code;



            var lastItem = options.itemValues[options.itemValues.length - 1].value;
            var itemExploded = lastItem.split('_');
            var newValue = parseInt(itemExploded[1]);

            console.log(lastItem);
            newValue += 1;
            options.newItem.value = code + '_' + newValue;
            options.newItem.text = options.newItem.value;

          }
          */
        });
        vm.$root.$on('openRequestor', data => {
          vm.customDialogSettings = {
            serviceName: 'staff',
            displayListView: true,
            size: 'xl',
            params: {
              inQuestion: true,
              questionCode: data.questionCode,
              defaultRole: data.defaultRole,
              staffTagCat: data.staffTagCat,
              tagUser: data.tagUser,
              filterUser: data.filterUser,
              selectedUsers:data.currentUsersSelected,
              locationFilterField:data.locationAuditField

            },
          };

          vm.refreshParams = new Date().getTime();
          vm.renderCustomDialog = true;
        });
        vm.$root.$on('openLocation', data => {
          vm.customDialogSettings = {
            serviceName: 'location',
            displayListView: true,
            size: 'xl',
            params: {
              inQuestion: true,
              questionCode: data.questionCode,
            },
          };
          vm.refreshParams = new Date().getTime();
          vm.renderCustomDialog = true;
        });

        vm.$root.$on('openClientList', data => {
          vm.customDialogSettings = {
            serviceName: 'client',
            displayListView: true,
            size: 'xl',
            params: {
              inQuestion: true,
              questionCode: data.questionCode,
            },
          };

          vm.refreshParams = new Date().getTime();
          vm.renderCustomDialog = true;
        });
        vm.$root.$on('openStaffListMul', data => {
          vm.customDialogSettings = {
            serviceName: 'staff',
            displayListView: true,
            size: 'xl',
            params: {
              inQuestion: true,
              questionCode: data.questionCode,
              ClientID: data.params.ClientID,
              ClientNumber: data.params.ClientNumber,
              selectedStaff: data.params.selectedStaff,
              defaultRole: data.params.defaultRole,
              tagUser: data.params.tagUser,
              selectedUsers:data.currentUsersSelected,
              locationFilterField:data.locationAuditField


            },
          };

          vm.refreshParams = new Date().getTime();
          vm.renderCustomDialog = true;
        });
        vm.$root.$on('openFamilyList', data => {
          vm.customDialogSettings = {
            serviceName: 'familyMembers',
            displayListView: true,
            size: 'xl',
            params: {
              inQuestion: true,
              questionCode: data.questionCode,
              getFamily: true,
              ClientID: data.params.ClientID,
              ClientNumber: data.params.ClientNumber,
              selectedFamily: data.params.selectedFamily,
            },
          };

          vm.refreshParams = new Date().getTime();
          vm.renderCustomDialog = true;
        });
       


 setTimeout(function() {
        
          $('#surveyCreatorContainer')
            .find("[role=button][aria-label*='Date Picker']")
            .find('sv-svg-icon')
            .remove()

           $('#surveyCreatorContainer')
            .find("[role=button][aria-label*='Date Picker']")
            .find(' > span')
            .addClass('fas')
            .addClass('fa-calendar');
           
        
               $('#surveyCreatorContainer')
            .find("[role=button][aria-label*='Staff']")
            .find('sv-svg-icon')
            .remove();

             $('#surveyCreatorContainer')
            .find("[role=button][aria-label*='Client']")
            .find('sv-svg-icon')
            .remove();

              $('#surveyCreatorContainer')
            .find("[role=button][aria-label*='Client']")
            .find('sv-svg-icon')
            .remove();

            


              $('#surveyCreatorContainer')
            .find("[role=button][aria-label*='Location']")
            .find('sv-svg-icon')
            .remove()

           $('#surveyCreatorContainer')
            .find("[role=button][aria-label*='Staff']")
            .find(' > span')
            .addClass('fas')
            .addClass('fa-users');


           $('#surveyCreatorContainer')
            .find("li[role=option]")
            .find(" > span:contains('Staff')")
            .addClass('fas')
            .addClass('fa-users');

             $('#surveyCreatorContainer')
            .find("[role=button][aria-label*='Client']")
            .find(' > span')
            .addClass('fas')
            .addClass('fa-child');


              $('#surveyCreatorContainer')
            .find("[role=button][aria-label*='Collection']")
            .find('sv-svg-icon')
            .remove()
           
                 $('#surveyCreatorContainer')
            .find("[role=button][aria-label*='Collection']")
            .find(' > span')
            .addClass('fas')
            .addClass('fa-file-invoice');

                  $('#surveyCreatorContainer')
            .find("[role=button][aria-label*='Location']")
            .find(' > span')
            .addClass('fas')
            .addClass('fa-building');


           

              $('#surveyCreatorContainer')
              .find("[role=button][aria-label*='Custom Field Response']")
              .find('sv-svg-icon')
              .remove()

          $('#surveyCreatorContainer')
              .find("[role=button][aria-label*='Custom Field Response']")
         .find(' > span')
            .addClass('fas')
            .addClass('fa-list');
          
        }, 100);
        

        $('body').on('click','.sv-dots__item, button > .svc-page__question-type-selector',function(){
         setTimeout(function(){
            $('.sv-list__filter').hide();

            
            
            ;
          
            

     
             
         },200);
      });
      });
       

 

   
  },
 beforeDestroy() {
    if (this.waitToClearinterval) {
      clearInterval(this.waitToClearinterval);
    }

    if(this.changeToolboxIcons){
            clearInterval(this.changeToolboxIcons);

    }
},
methods: {
    getHiddenPropertiesList(){
        let selfObj =this;
        formService.getHiddenProperties(this.isHdsUser)
        .then(response =>{
          this.propertiesToHide=response;

              $.each(response, function(index, propertyName) {
              
                  var property = SurveyCore.Serializer.findProperty(
                    'survey',
                    propertyName
                  );
                  if (property) {
                    property.visible = false;
                  }
                
              });

         

        });
    },
    showErrorMsg(sender,options){


      if($(options.message).find(".errorMsg").length>0 || $(options.message).hasClass("errorMsg") ){
        
        this.formError=options.message;
        this.showError=true;

      }
                   

    },
    collaboratorClosed(){
      formService.getCollaboratorCount(this.assessmentidParams)
      .then(response =>{
          this.totalCollaborators=response.numCollaborators;
          
      });
    },
    resetCodesClicked() {
      this.$bvModal
        .msgBoxConfirm(
          'Are you sure you want to reset the question codes for this form?',
          {
            hideHeaderClose: true,
            centered: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            okVariant: 'success',
            okTitle: 'Yes',
            cancelVariant: 'danger',
            cancelTitle: 'Cancel',
          }
        )
        .then(value => {
          if (value) {
            formService.resetCodes(this.assessmentid).then(response => {
              if (response.success) {
                this.surveyCreator.text = response.updatedJSON;
              }
            });
          }
        });
      ``;
    },
    closeJsonCopyDialog: function() {
      this.jsonCopyJsonText = '';
      this.renderJsonCopyDialog = false;
    },
    handleClickCancelJsonCopyDialog: function() {
      this.closeJsonCopyDialog();
    },
    handleClickOkJsonCopyDialog: function() {
      const element = document.querySelector(`#${this.jsonCopyJsonTextId}`);
      if (!element) return;
      element.focus();
      element.select();
      document.execCommand('copy');
      this.closeJsonCopyDialog();
    },
    openJsonCopyDialog: function() {
      const json = this.surveyCreator.getSurveyJSON();
      const jsonCopy = surveyShallowCopy(json);
      this.jsonCopyJsonTextId="modal-jsoncpy-text-" + new Date().getTime();
      this.jsonCopyJsonText=JSON.stringify(jsonCopy);
      this.renderJsonCopyDialog = true;
    },
    openDeploymentModal(dateStr) {
      this.$router.push({
        name: 'DeploymentModalView',
        params: {
          assessmentID: this.assessmentidParams,
          componentName: 'ListView',
          cservice: 'cseries',
        },
      });
    },
    assessmentTitleCreated(data) {
      this.surveyCreator.survey.title = data.assessmentTitle;

      if (data.assessmentType) {
        this.surveyCreator.survey.Type = data.assessmentType;
      }

      this.category = data.assessmentCategory;
      this.tags = data.assessmentTags;

      this.updateTaxonomyProperty('category');
      this.updateTaxonomyProperty('tags');

      //add call to the getDomainTitel() and set the default domain to the domain id it sent back and show add form
      formService
        .getDomainTitle({ title: this.surveyCreator.survey.title })
        .then(response => {
          this.defaultDomainID = response.DomainID;
          this.instructionsDomain=response.instructionDomain

           formService.getDomainList().then(response => {
              this.domainList = response;
               this.showAddNewForm = false;

          });

         
        });
    },
    closeAssessmentTitleDialog() {
      this.showAddNewForm = false;
    
      $('.customDialogCancel').click();
    },
    closeQuestionDialog() {
      this.renderCustomDialog = false;
    },
    async choicesFromUrl(callUrl, callback) {
      var res = new Array();
      const response = await apiPost(callUrl);
      callback(response);

      /*$.ajax({
        url:callUrl,
        dataType:'JSON',
        async:false,
        success:function(data){
          $.each(data,function(key,value){
            res.push(value);
          });
        }
      });*/
    },
    openCollaborationForm() {

      if(!this.assessmentidParams){

         $('[aria-label*=Save]').closest('button').attr("disabled",false);

        $('[aria-label*=Save]').closest('button').trigger('click');
             
         $('[aria-label*=Save]').closest('button').attr("disabled","disabled");


          this.hideSuccess=true;
          let vm=this;
          let holdTillSave=setInterval(function(){

              if(vm.assessmentidParams){
                  clearInterval(holdTillSave);
                  

                    vm.$bvModal.show('form-collaboration-modal');
                    vm.hideSuccess=false;



              }


          },100);


      }else{
         this.$bvModal.show('form-collaboration-modal');

      }
    },
    removePropertyList(hdsUser=false) {
      var selfObj = this;


      formService.removePropertiesList({isHdsUser:hdsUser}).then(response => {
        $.each(response, function(elementName, properties) {
          $.each(properties, function(index, propertyName) {
            if (!selfObj.removedPropertyList[elementName]) {
              selfObj.removedPropertyList[elementName] = new Array();
            }

            selfObj.removedPropertyList[elementName].push(propertyName);
            if (elementName == 'survey') {
              var property = SurveyCore.Serializer.findProperty(
                elementName,
                propertyName
              );
              if (property) {
                property.visible = false;
              }
            }
          });
        });
      });
    },
    saveSurvey(saveNo, callback) {
      var surveyCreatorInst = this.surveyCreator;

      
    
      var selfObj = this;
      var formJSON = JSON.parse(surveyCreatorInst.text);
      var isValid = new Array();

      
      
      if(!selfObj.changingPublished){
          if (selfObj.requiredProperties['survey']) {
            $.each(selfObj.requiredProperties['survey'], function(
              value,
              propertyName
            ) {
              if (!formJSON[propertyName.field]) {
                isValid.push(propertyName);
              }
            });
          }

        if(formJSON['pages']){
          $.each(formJSON['pages'], function(value, page) {
            selfObj.recursivelyValidateJson(page, isValid);
          });
        }
      }

      if (isValid.length == 0) {
        selfObj.formError = null;
        let sendObj = $.extend(
          { surveyData: surveyCreatorInst.getSurveyJSON() },
          selfObj.extraParams
        );
        if (this.serverParams) {
          sendObj = { ...sendObj, ...selfObj.serverParams };
        }
        if (selfObj.assessmentid || selfObj.new_id) {
          formService
            .update(
              selfObj.assessmentid ? selfObj.assessmentid : selfObj.new_id,
              sendObj
            )
            .then(response => {
              if (response.success) {

                
               surveyCreatorInst.JSON = response.updatedJSON;
                if (!selfObj.changingPublished) {
                  selfObj.$bvModal
                    .msgBoxOk(response.message, {
                      title: 'Success',
                      size: 'sm',
                      buttonSize: 'sm',
                      okVariant: 'success',
                      headerClass: 'p-2 border-bottom-0',
                      footerClass: 'p-2 border-top-0',
                      hideHeaderClose: true,
                      centered: true,
                    })
                    .then(value => {
                         selfObj.defaultDomainID=response.DefaultDomainID;


                    })
                    .catch(err => {
                      // An error occurred
                    });
                } else {
                  selfObj.defaultDomainID=response.DefaultDomainID;

                  selfObj.changingPublished = false;
                }

                
              } else {
                if (response.showConfirmation) {
                  selfObj.$bvModal
                    .msgBoxConfirm(response.message, {
                      title: 'Please Confirm',
                      size: 'md',
                      buttonSize: 'sm',
                      okVariant: 'danger',
                      okTitle: 'Confirm',
                      cancelTitle: 'Cancel',
                      footerClass: 'p-2',
                      hideHeaderClose: true,
                      centered: true,
                    })
                    .then(value => {
                      if (value && response.returnItem) {
                        selfObj.extraParams[response.returnItem] = true;
                        selfObj.defaultDomainID=response.DefaultDomainID;

                        selfObj.saveSurvey(saveNo, callback);
                      }
                    });
                } else {
                  selfObj.$bvModal
                    .msgBoxOk(response.message, {
                      title: 'Error Occurred',
                      size: 'sm',
                      buttonSize: 'sm',
                      okVariant: 'success',
                      headerClass: 'p-2 border-bottom-0',
                      footerClass: 'p-2 border-top-0',
                      hideHeaderClose: true,
                      centered: true,
                    })
                    .then(value => {
                      this.boxTwo = value;
                    })
                    .catch(err => {
                      // An error occurred
                    });
                }
              }
            });
        } else {
          formService.create(sendObj).then(response => {
            if (response.success) {

              surveyCreatorInst.JSON = response.updatedJSON;
              selfObj.new_id = response.new_id;
              selfObj.assessmentidParams = selfObj.new_id;
              selfObj.refreshCollabParams = new Date().getTime();
              selfObj.defaultDomainID=response.DefaultDomainID;

              if(!selfObj.hideSuccess){
                  selfObj.$bvModal
                    .msgBoxOk(response.message, {
                      title: 'Success',
                      size: 'sm',
                      buttonSize: 'sm',
                      okVariant: 'success',
                      headerClass: 'p-2 border-bottom-0',
                      footerClass: 'p-2 border-top-0',
                      hideHeaderClose: true,
                      centered: true,
                    })
                    .then(value => {
                      
                    })
                    .catch(err => {
                      // An error occurred
                    });
              }
            } else {
              selfObj.$bvModal
                .msgBoxOk(response.message, {
                  title: 'Error Occurred',
                  size: 'sm',
                  buttonSize: 'sm',
                  okVariant: 'success',
                  headerClass: 'p-2 border-bottom-0',
                  footerClass: 'p-2 border-top-0',
                  hideHeaderClose: true,
                  centered: true,
                })
                .then(value => {
                  this.boxTwo = value;
                })
                .catch(err => {
                  // An error occurred
                });
            }
          });
        }
      } else {

        if(selfObj.isPublishing){
            selfObj.extraParams=new Array();
            $("button[title=Unpublish]").find('span').text('Publish');
        }
        let locFormError = "<div class='errorMsg'> <p>The Following Elements Have Errors:</p><ul>";
        $.each(isValid, function(key, errorValue) {
          locFormError+= '<li>';
          switch (errorValue.elementName) {
            case 'survey':
             locFormError += 'Survey';
              break;
            case 'question':
             locFormError += errorValue.formName.replace("<p>","").replace("</p>","");
              break;
            case 'panel':
             locFormError += errorValue.formName;
              break;
          }
         locFormError += ': ';
          if (errorValue.requiredMessage) {
           locFormError += errorValue.requiredMessage;
          }
         locFormError += '</li>';
        });
       locFormError += '</ul></div>';

       selfObj.surveyCreator.notify(locFormError,"error");

        setTimeout(function() {
          $('.svd_save_btn').show();
          $('.svd_save_btn')
            .closest('span')
            .show();
          $('.svd-designer-container')
            .find('[aria-label=Toolbox]')
            .show();
          var surveyProperties = SurveyCore.Serializer.getProperties('survey');
          selfObj.disableProprties('survey', surveyProperties, false);
          var questionProperties = SurveyCore.Serializer.getProperties(
            'question'
          );
          selfObj.disableProprties('question', questionProperties, false);
          var panelProperties = SurveyCore.Serializer.getProperties('panel');
          selfObj.disableProprties('panel', panelProperties, false);
          var panelProperties = SurveyCore.Serializer.getProperties('itemvalue');
          selfObj.disableProprties('itemvalue', panelProperties, false);
          $('#unpublish-survey').hide();
          $('#publish-survey').show();
        }, 200);
      }
      
    },
    generateTitle(codeVal, prefix) {
      if (isNaN(codeVal)) {
        if (codeVal.substring(0, 2) === prefix) {
          var intval = parseInt(codeVal.substring(2));
          return 'Question ' + intval;
        } else {
          return 'New Question';
        }
      } else {
        var intval = parseInt(codeVal);
        return 'Question ' + intval;
      }
    },
    generateCode(surveyJSON,prefix){
      if(this.lastCode) {
        if(isNaN(this.lastCode)) {
          var lastCodeCharacter=this.lastCode.match(/\d+/);
          
          if(lastCodeCharacter[0] && !isNaN(lastCodeCharacter[0])) {
            var intval=parseInt(lastCodeCharacter[0]);
            intval=intval+1;
            this.lastCode= prefix+intval;
          } else {
            return this.lastCode;
          }
        } else {
          var intval = parseInt(this.lastCode);
          intval = intval + 1;
          this.lastCode = intval.toString();
        }
      } else {
        this.lastCode = prefix + '1';
      }
      return this.lastCode;
    },
    recurivelyGetQuestions(elements,currentlyLast){
      var lastCode=null;
      var selfObj=this;

      $.each(elements,function(value,element){
        if(element.elementType=='panel'){
          if(element.elements) {
            lastCode=selfObj.recurivelyGetQuestions(element.elements,currentlyLast);
          }

        } else if (element.elementType=='question') {
          if (element.code) {
            if (currentlyLast) {
              var currentintval=currentlyLast.match(/\d+/);
              var codeintval=element.code.match(/\d+/);
              
              if (currentintval[0] && codeintval[0]) {
                if (currentintval[0]>codeintval[0]) {
                  lastCode=currentlyLast;
                } else {
                  lastCode=element.code;
                }
              } else {
                lastCode=element.code;
              }

            } else {
              lastCode=element.code;
            }
          } else {
            lastCode = element.code;
          }
        }
      });
      return lastCode;
    },
    generatePartitionCode(surveyJSON, prefix) {
      if(this.lastPartitionCode) {
        if(isNaN(this.lastPartitionCode)) {
          var lastCodeCharacter=this.lastPartitionCode.match(/\d+/);
          
          if(lastCodeCharacter[0] && !isNaN(lastCodeCharacter[0])) {
            var intval=parseInt(lastCodeCharacter[0]);
            intval=intval+1;
            this.lastPartitionCode= prefix+intval;
          } else {
            return this.lastPartitionCode;
          }
        } else {
          var intval = parseInt(this.lastPartitionCode);
          intval = intval + 1;
          this.lastPartitionCode = intval.toString();
        }
      } else {
        this.lastPartitionCode = prefix + '1';
      }
      return this.lastPartitionCode;
    },
    recurivelyGetPanels(elements) {
      var lastCode = null;
      var selfObj = this;
      if (elements) {
        $.each(elements, function(value, element) {
          if (element.elementType == 'panel') {
            var foundCode = selfObj.recurivelyGetPanels(element.elements);
            if (foundCode) {
              lastCode = foundCode;
            }
          }
        });
      }
      return lastCode;
    },
    openAddDomainDialog() {
      this.renderAddDomainDialog = true;
    },
    closeAddDomainDialog(data) {


          formService.getDomainList().then(response => {
              this.domainList = response;

                this.questionProp.choices= this.domainList;

                this.renderAddDomainDialog = false;
          });
    
      
     
    },
    openTaxonomyDialog(type) {
      if (type == 'category') {
        this.taxonomyFieldSet = 'Location Category Information';
        this.taxonomyService = 'assessmentCategoryPicker';
        this.allowMultipleValues = false;
        this.value = this.category;
        this.passedParams = {
          allowMultipleValues: false,
          lvCustomFieldSetName: 'Location Category Information',
          lvServiceName: 'assessmentCategoryPicker',
          params: {
            taxonomyGroupName: 'Assessment',
            showInForm: true,
          },
        };
      } else {
        this.taxonomyFieldSet = 'Location Tag Information';
        this.taxonomyService = 'assessmentTagPicker';
        this.allowMultipleValues = true;
        this.value = this.tags;
        this.passedParams = {
          allowMultipleValues: true,
          lvCustomFieldSetName: 'Location Tag Information',
          lvServiceName: 'assessmentTagPicker',
          params: {
            taxonomyGroupName: 'Assessment',
            showInForm: true,
          },
        };
      }
      this.renderTaxonomyDialog = true;
    },
    closeTaxonomyDialog() {
      this.renderTaxonomyDialog = false;

      if (this.allowMultipleValues) {
        this.tags = this.value;
        this.updateTaxonomyProperty('tag');
      } else {
        this.category = this.value;
        this.updateTaxonomyProperty('category');
      }

      $("[aria-label='Category & Tags']").parent().trigger('click');
      $("[aria-label='Category & Tags']").parent().trigger('click');

    },
    updateTaxonomyProperty(type) {
      var array;
      var property = [];
      var containerNode;

      if (type == 'category') {
        array = this.category;
        //containerNode = document.getElementById('categoryDisplay');
      } else {
        array = this.tags;
       // containerNode = document.getElementById('tagDisplay');
      }

      /*
      if (containerNode) {
        for (var i = containerNode.children.length - 1; i >= 0; i--) {
          containerNode.removeChild(containerNode.children[i]);
        }
      }
      */
      if (array) {
        for (var j = 0; j < array.length; j++) {
          property.push(array[j].ID);

          /*
          if (containerNode) {
            var newNode = document.createElement('div');
            newNode.className = 'tag--light-green';
            newNode.setAttribute('style', 'margin-bottom: 10px');
            if (Array.isArray(array[j].text)) {
              newNode.innerHTML = array[j].text[0];
            } else if (isObject(array[j].text)) {
              newNode.innerHTML = array[j].text.Name;
            } else {
              newNode.innerHTML = array[j].text;
            }
            $(containerNode).append(newNode);
          }
          */
        }
      }

      if (type == 'category') {
        this.surveyCreator.survey.Category = property;
      } else {
        this.surveyCreator.survey.Tags = property;
      }
    },
     getDomainListAsync(choicesCallback) {

      formService.getDomainList()
      .then(response =>{
          choicesCallback(response);
      });


     
    },
    disableProprties(element, properties, makeReadOnly) {
      $.each(properties, function(key, property) {
        SurveyCore.Serializer.findProperty(
          element,
          property.name
        ).readOnly = makeReadOnly;
      });
    },
    recursivelyFindQuestion(elements, code) {
      var found = null;
      var codeBefore = null;
      var vm = this;
      $.each(elements, function(key, element) {
        if (element.elementType == 'panel') {
          found = vm.recursivelyFindQuestion(element.elements, code);
        } else if (element.elementType == 'question') {
          if (element.code == code) {
            found = true;
            return false;
          } else {
            codeBefore = element.code;
          }
        }
      });
      return { codeFound: found, prevCode: codeBefore };
    },
    recursivelyFindQuestionInJson(json, code) {
      var parsedJSON = $.parseJSON(json);
      var selfObj = this;
      var codeBefore = null;
      var done = false;

      $.each(parsedJSON.pages, function(key, pageElements) {
        $.each(pageElements.elements, function(key, element) {
          if (element.elementType == 'panel') {
            var returnedCodeBefore = selfObj.recursivelyFindQuestion(
              element.elements,
              code
            );
            if (returnedCodeBefore.prevCode) {
              codeBefore = returnedCodeBefore.prevCode;
            }
            if (returnedCodeBefore.codeFound) {
              done = true;
              return false;
            }
          } else if (element.elementType == 'question') {
            if (element.code == code) {
              done = true;
              return false;
            } else {
              codeBefore = element.code;
            }
          }
        });
        if (done) {
          return false;
        }
      });
      return codeBefore;
    },
    recursivelyValidateJson(json, validArray) {
      var selfObj = this;
      if (json['elements']) {
        $.each(json['elements'], function(elementindex, element) {
          if (element['elementType'] == 'panel') {
            if (selfObj.requiredProperties[element['elementType']]) {
              $.each(
                selfObj.requiredProperties[element['elementType']],
                function(elementName, property) {
                  if (!element[property.field]) {
                    var displayProperty = JSON.parse(JSON.stringify(property));
                    displayProperty.formName = element['title'];
                    validArray.push(displayProperty);
                  }
                }
              );
            }
            selfObj.recursivelyValidateJson(element, validArray);
          } else if (element['elementType'] == 'question') {
            if (selfObj.requiredProperties[element['elementType']]) {
              $.each(
                selfObj.requiredProperties[element['elementType']],
                function(elementName, property) {
                  if (!element[property.field]) {
                    var displayProperty = JSON.parse(JSON.stringify(property));
                    displayProperty.formName = element['title'];
                    validArray.push(displayProperty);
                  }
                }
              );
            }
          }
        });
      }
    },
    openCustomFieldPicker(){

      if(!this.questionAdded.CustomFieldID){
           this.questionAdded.CustomFieldID="";

      }


      this.customFieldPickerParams={...this.passedParams,...this.customFieldPickerParams,getFields:true}

      this.refreshCustomFieldDialogParams=new Date().getTime(); 
      this.renderCustomFieldDialog=true;




    },
    openCustomFieldSetPicker(){
     
     this.customFieldPickerParams={...this.passedParams}


      this.rederCustomFieldSetDialog=true;


    },
    closeCustomFieldPicker(){
      
      this.renderCustomFieldDialog=false;


      if(this.value[0]){
        this.questionAdded.CustomFieldID=this.value[0].ID;

      }else{
          this.questionAdded.CustomFieldID="";
      }


      $("[aria-label='Field']").parent().trigger('click');
      $("[aria-label='Field']").parent().trigger('click');

    },
    closeCustomFieldPickerNoUpdate(){
            this.renderCustomFieldDialog=false;

    },
    closeCustomFieldSetPicker(){
      
      this.rederCustomFieldSetDialog=false;

     this.customFieldPickerParams={...this.passedParams,CustomFieldSetID:this.value[0].ID};

    },

     handleCustomFieldSetRowSelected(row){
      let closeDialog=this.handleRowSelected(row,true);

      if(closeDialog){
        this.closeCustomFieldSetPicker();

      }

    },
    handleCustomFieldRowSelected(row){
      let closeDialog=this.handleRowSelected(row,true);

      if(closeDialog){
        this.closeCustomFieldPicker();

      }

    },
    handleRowSelected(row,inOtherDialog=false) {
      if (!isObject(row) || row.ID === undefined) {
        return;
      }
      const payload = { text: deepCopy(row.displayName), ID: row.ID };

      if (this.allowMultipleValues) {
        if (row.rowSelected === true) {
         if(!this.value){
            this.value=[];
         }

          this.value.push(payload);
        } else {
          var valuesToReturn = [];
          for(var i = 0; i < this.value.length; i++) {
            if (this.value[i].ID != payload.ID) {
              valuesToReturn.push(this.value[i]);
            }
          }
          this.value = valuesToReturn;


        }

        if (inOtherDialog){
            return false;
        }


      } else {
        if (row.rowSelected === true) {
          this.value = [payload];
          this.$refs['listViewInstance'].deselectAllRows(row.ID);
         
          if(inOtherDialog){
            return true;

          }else{
            this.closeTaxonomyDialog();

          }


        } else {
          this.value = [];
          if (inOtherDialog){
            return false;
          }
        }
      }
    }
  }
 
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#surveyCreator {
  height: 100vh;
  width: 100vw;
}
.sv-dropdown_clean-button-svg{
  display:none !important
}
.panel-content{
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 10%) !important;
    border: 1px solid #f2f2f2;
    padding: 0px 10px 20px 10px;
    border-radius: 0px 0px 5px 5px;

}
</style>
