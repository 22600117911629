import auth from '@/router/middleware/auth';

const LandingPage = r =>
  require.ensure(
    [],
    () => r(require('@/app/yiiSurveyJS/yiiLandingPage')),
    'yiiSurveyJS-bundle'
  );

const BuildingPage = r =>
  require.ensure(
    [],
    () => r(require('@/app/yiiSurveyJS/yiiSurveyBuilderPage')),
    'yiiSurveyJSBuilder-bundle'
  );

const  ViewerLanding = r =>
  require.ensure(
    [],
    () => r(require('@/app/yiiSurveyJS/yiiViewerLanding')),
    'yiiSurveyJSViewer-bundle'
  );

  const ClientLanding = r =>
    require.ensure(
      [],
      () => r(require('@/app/yiiSurveyJS/yiiClientLandingPage')),
      'yiiClient-bundle'
    )
export default [
//from collection libraries
  {
    path: '/yiiSurveyJS/:isorg/landingPage/:assessmentID/',
    name: 'yiiLandingPage',
    component: LandingPage,
    meta: {
      middleware: [auth],
    },
  },
//from collection libraries
  {
    path: '/yiiSurveyJS/:isorg/surveyBuilder/:assessmentID/',//changed landingPage to surveyBuilder
    name: 'yiiBuildingPage',
    component: BuildingPage,
    meta: {
      middleware: [auth],
    },
  },

//stuff for the Viewer page
  {
    path: '/yiiSurveyJS/yiiViewerLanding/:collectionType/:collectionID/:clientID',
    name: 'yiiSureveyJSViewerLanding',
    component: ViewerLanding,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: '/yiiSurveyJS/yiiClientLandingPage/:collectionType/:collectionID/:clientID',
    name: 'yiiClientPage',
    component: ClientLanding,
    meta:{
      middleware: [auth],
    },
  },
];

