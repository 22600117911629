<template>
	<b-modal @close="close" size="xl" id="mentor-modal" visible no-close-on-esc no-close-on-backdrop hide-footer scrollable title="Search for Mentors" no-enforce-focus>
		<MentorGrid :linkedOnly="linkedOnly"></MentorGrid>
	</b-modal>
</template>

<script>
  import MentorGrid from './MentorGrid.vue';


export default {
	props: {
		selectRowURL: String,
		selectRowData: Object,
		linkedOnly:Boolean
	},
  data() {
      return {
        
      }
  },
  
  created() {

  },
  mounted(){
	
  },
  methods:{
	  close(){
    		this.$router.push({path:'/mentorship-module'});
    	
   	 	},
  },
  components:{
	  MentorGrid
  }

 
  
}
</script>