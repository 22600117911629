// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) =>
  http.post('apiTool/getTableSettings', payload).then(getData);

export const get = (payload = null) =>
  http.post('apiTool/getRows', payload).then(getData);

export const getRow = (RowID) => 
  http.get(`apiTool/getRow&RowID=${RowID}`).then(getData);