// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiStaff/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiStaff/getUsers', payload).then(getData);

export const create = (row) => http.post('apiStaff/createUser', row).then(getData);

export const update = (row) => http.put('apiStaff/editUser&UserID=' + row.ID, row).then(getData);

export const remove = (row) => http.post('apiStaff/deleteUser',row).then(getData);

export const getRow = (rowID,params) => http.post(`apiStaff/getSelectedUser&UserID=${rowID}`,params).then(getData);

export const getStaffCollaboration = (payload = null,silentVal=false) => http.post('apiStaff/getUsersCollaboration',payload,{silent:silentVal}).then(getData);

export const getCurrentStaff = (payload) => http.post('apiStaff/getUserProfile',payload).then(getData);
