// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiQlikReports/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiQlikReports/getReports', payload).then(getData);

export const getRow = (payload = null) => http.post('apiQlikReports/getQlikReport', payload).then(getData);


export const create = (row) => http.post('apiQlikReports/createQlikReport', row).then(getData);

export const update = (data) => http.post('apiQlikReports/editQlikReport', data).then(getData);

export const remove = (row) => http.post('apiQlikReports/deleteQlikReport', row).then(getData);

export const getFormConfig = (rowID,action) => http.post('apiQlikReports/getFormConfig',{RowID:rowID,Action:action}).then(getData);

