// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiRole/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiRole/getRows', payload).then(getData);

export const create = (row) => http.post('apiRole/create', row).then(getData);

export const update = (row) => http.put('apiRole/update&OldRoleName=' + row.id, row).then(getData);

export const remove = (row) => http.post('apiRole/deleteRow', row).then(getData);

export const getRow = (row) => http.get(`apiRole/getRow&Role=${row}`).then(getData);
