// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiModuleGroup/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiModuleGroup/getProviderRows', payload).then(getData);

export const getRow = (rowID) => http.get(`apiModuleGroup/getRow&ID=${rowID}`).then(getData);

export const create = (row) => http.post('apiModuleGroup/createProviderGroup', row).then(getData);

export const update = (row) => http.post('apiModuleGroup/edit', row).then(getData);

export const remove = (row) => http.post('apiModuleGroup/delete', row).then(getData);
