// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiCustomField/getTableSettings', payload).then(getData);

export const get = (payload) =>
  http
    .post(
      `apiCustomField/getFields`,payload
    )
    .then(getData);


