<template>

    <b-container>

    
      <b-row v-if="localValue">

        <b-col md="12">
            <b-row v-for="(fieldValue,index) in localValue" :key='"multiCard_"+index'>
              <b-col md="12" class="py-0">
               <b-row>
                  <b-col md="12" class="py-0">
                    <b-row>
                        <b-col md="10" v-if="index>0"></b-col>
                        <b-col md="2" v-if="index>0 && !readOnly " class='text-right'><b-button variant="danger" @click="removeRow(index)">{{ $t("general.remove") }}</b-button></b-col>
                    </b-row>
                    <hr v-if="index>0"/>
                    <b-row v-for="(childField,index2) in childFields" :key="'ChildField_'+index2">
                      <b-col
                            sm="12"
                            md="6"
                            :xl="getXl(childField.Type, childField.BCol)"
                            v-show=" showColumn(childField,localValue[index]['isNew'])"
                            :style="childField.Visibility?{visibility:'hidden'}:{}"
                          >

                          <b-form-group>
                                <ChildLabelTooltip
              
                                  :iconID="`${childField.Name}_tt`"
                                  :label="childField.Text"
                                  :labelID="`${childField.Name}_label`"
                                  :required="childField.Required"
                                  :showTooltip="childField.HelpText !== ''"
                                  :tooltipText="childField.HelpText"
                                />
                                <ChildInputFactory
                                  :fieldID="childField.FieldID"
                                  :id="childField.Name"
                                  :type="childField.Type"
                                  :showCheckHeader="false"
                                  :showSelectAll="false"
                                  tagContainerClass="border-0 p-0"
                                  :required="childField.Required ? '1' : '0'"
                                  :numericOnly="childField.NumericOnly?'1' :'0'"
                                  :options="childField.Options"
                                  v-model="localValue[index][childField.Name]"
                                  :readOnly="makeReadOnly(childField,localValue[index]['isNew'])"
                                  :cssClasses="childField.CssClasses"
                                  @input="updateFormValues($event,childField,index)"
                                  :allowClearButton="childField.AddClearDateButton"
                                  :disabledDates="childField.disabledDates || {}"
                                  :placeholder="childField.Placeholder || ''"
                                  :rowData="rowData"


                                />
                          </b-form-group>

                      </b-col>
                    </b-row>
                     
                  </b-col>
               </b-row>
               
               </b-col>
            </b-row>

          
            
        </b-col>
       
      </b-row>
      <b-row>
        <b-col md="10"></b-col>
        <b-col md="2"><b-button variant="success" @click="addRow">{{ buttonText }}</b-button></b-col>
      </b-row>
    
       
    </b-container>
</template>
<script>

import { BCollapse } from 'bootstrap-vue';
import Label from './Label.vue';
import { ErrorMessages } from '@/utils/errorMessages.js';
import { isEmpty } from '@/utils/isEmpty.js';
import FormCard from '@/components/FormCard';
import CustomDialog from '@/components/CustomDialog.vue';
import { deepCopy } from '@/utils/langUtils';
import Vue from 'vue';



export default {
  name: 'LinkedEventsCard',
  components: {
    FormCard,
    ChildInputFactory:()=>import('@/components/InputFactory.vue'),
    ChildLabelTooltip:()=>import('@/components/LabelTooltip.vue'),
    CustomDialog

  },
  data() {
    return {
      addButtonText:"Add",
      service:null,
      occupancyDetails:[],
      renderCustomDialog:false,
      refreshForms:null,
      loaded:false,
      customDialogSettings:{},
      service:null,
      formSettings:{},
      refreshFamily:null,
      refreshParams:null,
      filterValues:{},
      hasCapacity:false,
      refreshFields:null,
      localValue:[],
      showRemove:true

    };
  },
  props: {
    readOnly:{type: Boolean,default:false},
    fieldID:{type: String, default:null},
    value: {type: Array,default:function(){return new Array}},
    rowID:{type: String, default:null},
    serviceName:{type: String, default:null},
    passedFilters:{type:Object, default:function(){return {}}},
    rowData:{type:Object, default:function(){return {}}},
    childFields:{type: Array, default:function(){return new Array}},
    buttonText:{type: String, default:'Add' }
  },

  created() {
       


  },
  beforeMount(){
  
  },
  mounted(){


      if(this.value){
          this.localValue=deepCopy(this.value);

          let vm=this;
          $.each(this.localValue,function(index,fields){
            if(fields['isNew']){
              vm.localValue[index]['isNew']=false;
            }

          });
      }else{

          this.addRow();
      }



     
          

  },
  methods: {
    submitForm(){
    },
     getXl(type, bcol=null) {
      if (bcol)
        return bcol;
      if (type === 'MultiSelectStartEndDate' || type === 'DropdownStartEndDate')
        return '6';
      if (type === '4' || type === 'Editor') return '12';
      return '4';
    },

    showColumn(field,isNewFlag=false) {
      // if (this.formType === 'Edit' && field.Type === 'FilePicker') {
      //   return false;
      // }

      if(isNewFlag && field.hideNewRow){
          return false;


      }else{
              return field.Hidden && field.Hidden!=4? false : true;


      }

    },
    
    makeReadOnly(field,newRowFlag=false){


      if(newRowFlag){
        return false;
      }else{
        return field.ReadOnly;
      }

    },
     addRow(){
          var newValue=new Array;


          if(this.localValue){
            newValue=deepCopy(this.localValue);
          }
          

          let newRow={};

      
          $.each(this.childFields,function(index,field){
              newRow[field.Name] = field.DefaultValue;
              // newRow[field.Name]=null;
          })

          newRow['isNew']=true;


          

          this.localValue.push(newRow);


          this.refreshFields=new Date().getTime();

     },
     removeRow(index){
        this.localValue.splice(index,1);

         let newValue=new Array;

          if(this.localValue){
              newValue=deepCopy(this.localValue);

          }


      
        this.$emit('rowUpdated',newValue);

     },
     updateFormValues(data,field,index){
      
       let newValue=new Array;

       if(this.localValue){
          newValue=deepCopy(this.localValue);

          newValue[index][field.Name]=data;
       }


      
        this.$emit('rowUpdated',newValue);
     }

        

  
  },
};
</script>
