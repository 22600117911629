// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

/**
 * Send login data and retrieve a new token
 *
 * @param payload
 * @returns {Promise<T>}
 */

 
export const getCategories = (payload=null) => http.post('outcomeReportNew/reportTypeSelect&admin=0&returnDataOnly=1&vueReporting=1',payload).then(getData);

export const getReports = (reportCategoryID) => http.get('outcomeReportNew/reportCategoryChosen&ReportCategoryID='+reportCategoryID+'&admin=0'+'&returnDataOnly=1').then(getData);


export const getReportData = (payload) => http.post('outcomeReportNew/results&vueReporting=1',payload).then(getData);

export const getCustomFilterDisplay= (reportID) => http.get('outcomeReportNew/getVueFilters&vueReporting=1&ReportID='+reportID,{silent:true}).then(getData);

