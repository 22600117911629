<template>
	<div id='newposttable'>
		
	<b-card v-for="board in boards" :key="board.ID" style="margin-bottom:20px">
				<Posts :discussionBoardSubject="board.title" :discussionBoardID="board.ID" :votes="board.Votes" :createdUser="board.CreatedUser" :createdDate="board.Created" :castedVote="board.castedVote" :newPostsOnly="true"></Posts>
	</b-card>
	

	
    <!-- Main table element -->
  


    
    </div>
  
</template>
<style>
	
</style>
<script>
  import lodash from "lodash";
  import * as discussionBoardService from "@/services/discussion-board";
	import { API_URL } from '@/config';
	import Comments from "./DiscussionBoardPostComments";
	import Posts from "./DiscussionBoardPosts";
import 'vue-good-table/dist/vue-good-table.css';
	import { VueGoodTable } from 'vue-good-table';		
  export default {
	  props: {
		  searchTerm:Object,
		  filterType:String

	
	},
    data() {
      return {
		boards:[]
       
	  }
     
    },
    computed: { 
		

     
    },
  
    mounted() {
		$('#newposttable').find('th.vgt-left-align').remove();
		$('#newposttable').find('.vgt-table').find('thead').css('border','none');

		$('#newposttable').find('.vgt-table').find('tbody').css('border','none');
		$('#newposttable').find('.vgt-table').find('td').css('border','none');
		$('#newposttable').find('.vgt-table').find('tr').css('border','none');

		$('#newposttable').find('.vgt-table').css('border','none');

		$('#newposttable').find('th.filter-th').eq('1').css('border',"none").css('background','#ffffff');

		
	
      
    },
    created() {
				
				this.getBoardsWithPosts();	
            		 
	
            		
				
				
		
	},
	methods: {

	   getBoardsWithPosts(){
				discussionBoardService.get({
						linkedOnly:true,
						filterBoards:this.filterType,
						searchFilters:this.searchTerm,
						newPostsOnly:true


				})
				.then(response =>{
						this.boards=response.rows

						

							



				});
	   }
	
	  
	},
	  components:{
	 		Posts
	  }

	  
    }
  
</script>