import moment from 'moment';

const serviceMap = {
  anonymousClientBatchErrorRow: { header: 'Batch Rows With Errors', referer: 'organization', customFieldSetName: null },
  anonymousClientBatchUpload: { header: 'Batch Upload', referer: 'organization', customFieldSetName: 'Anonymous Batch Upload' },
  anonymousClientCollection: { header: 'Surveys', referer: 'organization', customFieldSetName: 'Anonymous Survey Form' },
  anonymousStaffCollection: { header: 'Surveys', referer: 'organization', customFieldSetName: 'Anonymous Staff Survey Form' },

  approvalCategory: { header: 'Approval Settings', referer: 'eCenter Administrator', customFieldSetName: 'Approval Settings Category' },
  approvalSettings: { header: 'Approval Settings', referer: 'eCenter Administrator', customFieldSetName: 'Approval Settings' },
  callLog: { header: 'Call Logs', referer: 'organization', customFieldSetName: 'Call Log' },
  client: { header: 'Clients', referer: 'dashboard', customFieldSetName: 'Client Information' },
  clientCollection: { header: 'Collections', referer: 'dashboard', customFieldSetName: null },
  clientReferral: { header: 'Referral Management', referer: 'dashboard', customFieldSetName: null },
  cseries: { header: 'Collection Series', referer: 'administrationDashboard', refererText: 'Administration', customFieldSetName: null },
  collectionSeriesDeployment: { header: 'Collection Series Deployments', referer: 'administrationDashboard', refererText: 'Administration', customFieldSetName: "Collection Series Deployment" },

  customForm: { header: 'Survey Manager', referer: 'organization', customFieldSetName: 'Custom Form Information' },
  dashboard: { header: 'Dashboards', referer: 'eCenter Administration', customFieldSetName: 'Dashboards' },
  dashboardCard: { header: 'Cards', referer: 'eCenter Administration', customFieldSetName: 'Dashboard Cards' },
  dashboardCardTool: { header: 'Tools', referer: 'eCenter Administration', customFieldSetName: 'Dashboard Card Tools' },
  dashboardCardToolL10n: { header: 'Tool Glossary', referer: 'organization', customFieldSetName: 'Dashboard Card Tool L10n Information' },
  dboard: { header: 'Discussion Boards', referer: 'dashboard', customFieldSetName: 'Discussion Board Information' },
  dboardCollaboration: { header: 'Discussion Collaboration', referer: 'Administration', refererPath: 'administrationDashboard', customFieldSetName: null },
  dboardGroup: { header: 'Discussion Groups', referer: 'organization', customFieldSetName: 'Module Group Information' },
  dboardPost: { header: 'Comments', referer: 'dashboard', customFieldSetName: 'Discussion Board Post Information' },
  directory: { header: 'Directory', referer: 'organization', customFieldSetName: 'Directory Information' },
  document: { header: 'Resources', referer: 'organization', customFieldSetName: 'Document Information' },
  documentCategory: { header: 'Resource Categories', referer: 'Administration', refererPath: 'administrationDashboard', customFieldSetName: 'Document Category Information' },
  documentFolder: { header: 'Resources', referer: 'dashboard', customFieldSetName: 'Document Folder Information' },
  ecenterAdminStaff: { header: 'Staff', referer: 'eCenter Administration', customFieldSetName: 'Staff Information' },
  event: { header: '', referer: 'organization', customFieldSetName: 'Event Information' },
  eventCategory: { header: 'Event Categories', referer: 'Administration', refererPath: 'administrationDashboard', customFieldSetName: 'Event Category Information' },
  eventList: { header: 'Events', referer: 'organization', customFieldSetName: 'Event Information' },
  exportData: { header: 'Export Data', referer: 'organization', customFieldSetName: 'Org Data Export Form' },
  externalReferralContact: { header: 'Provider Contacts', referer: 'dashboard', customFieldSetName: 'External Referral Contact Information' },
  familyMembers: { header: 'Family Members', referer: 'dashboard', customFieldSetName: 'Family Member Information' },
  formResults: { header: 'Collection Results', referer: 'organization', customFieldSetName: 'Collection Information' },
  forms: { header: 'Collections', referer: 'eCenter Administrator', customFieldSetName: 'Collection Information' },
  importOrgData: { header: 'Import Org Data', referer: 'administrationDashboard', customFieldSetName: 'Org Data Import Form' },
  location: { header: 'Locations', referer: 'organizaton', customFieldSetName: 'Location Information' },
  locationAudio: { header: 'Files', referer: 'organization', customFieldSetName: 'Location File Information' },
  locationCategory: { header: 'Shared Categories', referer: 'administrationDashboard', customFieldSetName: 'Location Category Information' },
  locationContacts: { header: 'Shared People', referer: 'organization', customFieldSetName: 'Shared Contacts' },
  locationEHR: { header: 'Locations', referer: 'organizaton', customFieldSetName: 'Location Information' },
  locationFile: { header: 'Files', referer: 'organization', customFieldSetName: 'Location File Information' },
  locationFilePicker: { header: 'Files', referer: 'organization', customFieldSetName: 'Location File Information' },
  locationImage: { header: 'Files', referer: 'organization', customFieldSetName: 'Location File Information' },
  locationTag: { header: 'Shared Tags', referer: 'administrationDashboard', customFieldSetName: 'Location Tag Information' },
  locationVideo: { header: 'Files', referer: 'organization', customFieldSetName: 'Location File Information' },
  mentorCollaboration: { header: 'Mentor Collaboration', referer: 'Administration', refererPath: 'administrationDashboard', customFieldSetName: null },
  mentorExpertise: { header: 'Mentor Experience', referer: 'dashboard', customFieldSetName: 'Mentor Expertise Information' },
  mentorGroup: { header: 'Mentor Groups', referer: 'organization', customFieldSetName: 'Module Group Information' },
  notification: { header: 'Notifications', referer: 'dashboard', customFieldSetName: null },
  notificationAttr: { header: 'Notification Attributes', referer: 'eCenter Administration', customFieldSetName: 'Notification Attribute Information' },
  notificationAttrToTool: { header: 'Notification Settings', referer: 'eCenter Administration', customFieldSetName: 'Notification Attribute Information' },
  orgSurveysTable: { header: 'Surveys', referer: 'organization', customFieldSetName: null },
  orgTag: { header: 'System Tags', referer: 'eCenter Administration', customFieldSetName: 'Organization Tag Information' },
  pendingApproval: { header: 'Pending Approvals', referer: 'dashboard', customFieldSetName: null },
  poll: { header: 'Polls', referer: 'organization', customFieldSetName: null },
  pollManager: { header: 'Poll Manager', referer: 'organization', customFieldSetName: null, customForm: 'QuestionBuilderForm' },
  post: { header: 'Community Updates', referer: 'organization', customFieldSetName: 'Post Information' },
  postCategory: { header: 'Community Updates Categories', referer: 'Administration', refererPath: 'administrationDashboard', customFieldSetName: 'Post Category Information' },
  provider: { header: 'Providers', referer: 'dashboard', customFieldSetName: 'Provider Information' },
  providerCollaboration: { header: 'Provider Collaboration', referer: 'Administration', refererPath: 'administrationDashboard', customFieldSetName: null },
  providerGroup: { header: 'Provider Groups', referer: 'organization', customFieldSetName: 'Module Group Information' },
  providerReview: { header: 'Provider Reviews', referer: 'dashboard', customFieldSetName: 'Provider Review Information' },
  reportEmergency: { header: 'Community Alerts', referer: 'organization', customFieldSetName: 'Report Emergency Information' },
  requestAccountUsers: { header: 'Account Requests', referer: 'Administration', customFieldSetName: 'Account Requests' },
  role: { header: 'Roles', referer: 'eCenter Administration', customFieldSetName: 'Role Information' },
  settings: { header: 'Tool Settings', referer: 'eCenter Administrator', customFieldSetName: 'System Settings' },
  staff: { header: 'Staff', referer: 'Administration', customFieldSetName: 'Staff Information' },
  taxonomyGroup: { header: 'Groups', referer: 'eCenter Administration', customFieldSetName: 'Taxonomy Group Information' },
  tools: { header: 'Tools', referer: 'eCenter Administration', customFieldSetName: '' },
  toolSettings: { header: 'Tool Settings', referer: 'eCenter Administrator', customFieldSetName: 'System Settings' },
  upcomingEvents: { header: 'Upcoming Events', referer: 'dashboard', customFieldSetName: 'Event Information' },
  userAudio: { header: 'Files', referer: 'dashboard', customFieldSetName: 'User File Information' },
  userCategory: { header: 'My Categories', referer: 'dashboard', customFieldSetName: 'User Category Information' },
  userFile: { header: 'Files', referer: 'dashboard', customFieldSetName: 'User File Information' },
  userGroup: { header: 'Collaboration Groups', referer: 'eCenter Administration', customFieldSetName: 'User Group Information' },
  userGroupToUser: { header: 'Users In Group', referer: 'eCenter Administration', customFieldSetName: null },
  userImage: { header: 'Files', referer: 'dashboard', customFieldSetName: 'User File Information' },
  userTag: { header: 'My Tags', referer: 'dashboard', customFieldSetName: 'User Tag Information' },
  userVideo: { header: 'Files', referer: 'dashboard', customFieldSetName: 'User File Information' },
  treaty: { header: '', referer:'dashboard', customFieldSetName:null },
  treatyDep: { header: '', referer:'dashboard', customFieldSetName:null },
  importTranslations: { header: 'Import Translation Data', referer: 'eCenter Administration', customFieldSetName: 'Translation Management' },
  locationEHRVacancy: { header: 'Locations', referer: 'dashboard', customFieldSetName: 'Location Information' },
  resultsCollaboration: { header: 'Collaborators', referer: 'dashboard', customFieldSetName: 'Staff Information' },
  userCollections: { header: 'Staff Collections', referer: 'dashboard', customFieldSetName: null },


};

export const getTableHeading = (serviceName, rowID, routeID = null) => {
  const tableHeading = serviceMap[serviceName]
    ? serviceMap[serviceName]
    : { header: '', referer: '', customFieldSetName: null };

  if (serviceName === 'event') {
    const date = moment(rowID, 'YYYY-MM-DD');
    if (date.isValid()) {
      tableHeading.header = date.format('MMMM DD, YYYY');
    }
  } else if (serviceName == 'forms') {
    if (routeID == 'orgForms') {
      tableHeading.referer = 'administrationDashboard';
      tableHeading.refererText = 'Administrator';
    } else {
      tableHeading.referer = 'eCenter Administrator';
      tableHeading.refererText = null;
    }
  }
  return tableHeading;
};
