import axios from 'axios';
import interceptors from './interceptors';
import { API_URL } from '@/config';

export const http = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
});

/**
 * Helper method to set the header with the token
 */
export function setToken (token) {
  http.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export default function install (Vue, { store, router }) {
  interceptors(http, store, router);
  Object.defineProperty(Vue.prototype, '$http', {
    get () {
      return http;
    }
  });
}
