import auth from '@/router/middleware/auth';


const ReportModule = r => require.ensure([], () => r(require('./index')), 'report-module-new-bundle');
export default [
  {
    path: '/report-module-new',
    name: 'report-module-new',
    component: ReportModule,
    meta: {
      middleware: [auth]
    },
    children:[
      
    ]
  },

];
