<template>
  <CustomFieldFormPage />
  <!-- <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-10 col-lg-12 col-md-9">
        <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
            <div class="row">
              <div class="col">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Request Account</h1>

                    <p>To request an account, fill out the form below. You will receive an email when you account has been enabled.</p>
                  </div>

                  <b-alert v-show="alertText" variant="success" show v-html="alertText"></b-alert>

                  <b-form @submit.stop.prevent="submit">
                    <b-row>
                      <b-col md="4">
                        <b-form-group id="FirstName" label="First Name" label-for="FirstName">
                          <b-form-input v-model="form.FirstName" id="FirstName" name="FirstName" type="text" required placeholder="Enter First Name"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="4">
                        <b-form-group id="LastName" label="Last Name" label-for="LastName">
                          <b-form-input v-model="form.LastName" id="LastName" name="LastName" type="text" required placeholder="Enter Last Name"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="4">
                        <b-form-group id="Province" label="Province" label-for="Province">
                          <b-form-select v-model="form.ProvinceID" :options="provinces" id="Province" name="Province" required></b-form-select>                    </b-form-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="4">
                        <b-form-group id="Location" label="Nation" label-for="Location">
                          <b-form-select v-model="form.LocationID" :options="filteredLocations" id="Location" name="Location" required></b-form-select>                    </b-form-group>
                        </b-form-group>
                      </b-col>

                      <b-col md="4">
                        <b-form-group id="Email" label="Email Address" label-for="Email">
                          <b-form-input v-model="form.Email" id="Email" name="Email" type="email" required placeholder="Enter Email Address"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="4">
                        <b-form-group id="EmailConfirm" label="Re-enter Email Address" label-for="EmailConfirm">
                          <b-form-input v-model="form.EmailConfirm" id="EmailConfirm" name="EmailConfirm" type="email" required placeholder="Re-enter Email Address"></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <div v-show="formError" class="col text-center text-danger">{{ formError }}</div>

                    <div class="row mb-4">
                      <div class="col text-center">
                        <b-button-group>
                          <b-button variant="success" type="submit">Submit</b-button>
                          <b-button variant="danger" @click="cancel">Cancel</b-button>
                        </b-button-group>
                      </div>
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>

import * as requestAccountService from '@/services/requestAccount';
import CustomFieldFormPage from '@/components/form/CustomFieldFormPage.vue';

export default {
  name: 'Login',
  components: {
    CustomFieldFormPage,
  },
  data () {
    return {
      alertText: '',
      form: {},
      formError: '',
      provinces: [{ value: 1, text: 'BC Demo Location' },],
      locations: [{ value: 1, text: 'BC Demo Location' },]
    };
  },
  created:function(){
    this.$route.query.svc='requestAccount';
  },
  mounted() {
    this.getProvinces();
    this.getLocations();
  },

  computed: {
    filteredLocations: function () {
      if (this.form.ProvinceID) {
        var result = this.locations.filter(obj => {
          return obj.ProvinceID === this.form.ProvinceID
        })

        return result;
      }
      else {
        return this.locations
      }      
    }
  },
  
  methods: {
    async getProvinces() {
      this.provinces = await requestAccountService.getProvinces();
    },

    async getLocations() {
      this.locations = await requestAccountService.getLocations();
    },

    submit () {
      this.alertText = '';
      this.formError = '';

      requestAccountService.requestAccount(this.form)
        .then(response => {
          if(response.success){
            this.$router.push({ name: 'login', params: {alertText: response.message } });
          }else{
            this.formError=response.message
          }
        }).catch(error => {
          this.formError = error.data.error;
          // this.submitError(error);
          // this.disableSubmitLoader();
        });
    },
    cancel () {
      this.$router.push({ path: '/login' });
    }
  }
};
</script>
