<template>
  <ecenterDialog
    v-bind="dialogProps"
    size="xl"
    :showOkBtn="false"
    :noCloseOnBackdrop="true"
    @onClickCancel="onClickCancel()"
    @onClickMenuBtn="onClickMenuBtn($event)"
    @onError="$emit('onError', $event)"
  >
    <template v-slot:dialogContent>
      <div
        id="formErrorDiv"
        v-if="errorMessage"
        class="col text-center text-danger"
        v-html="errorMessage"
      ></div>
      <SurveyJsForm
        :formServiceName="formData ? formData.formServiceName : null"
        :serverParams="formData ? formData.serverParams : null"
        :getFormUrl="formData ? formData.getFormUrl : null"
        :getAnswersUrl="formData ? formData.getAnswersUrl : null"
        @onError="$emit('onError', $event)"
        ref="formInstance"
      />
    </template>
  </ecenterDialog>
</template>
<script>
import ecenterDialog from '@/components/ecenterDialog';
import SurveyJsForm from '@/components/formSurveyJs/SurveyJsForm.vue';
import cformDialogMixin from '@/mixins/cformDialog.mixin';

export default {
  name: 'SurveyJsFormDialog',
  components: {
    SurveyJsForm,
    ecenterDialog,
  },
  mixins: [cformDialogMixin],
  methods: {
    getFormData() {
      const answers = this.$refs.formInstance.getAnswersObj();
      return { answers };
    },
    validateForm: function() {
      return this.$refs.formInstance.triggerCompleteLastPage();
    },
  },
};
</script>
