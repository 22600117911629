<template>
	<b-form-group :id="id"> 

		<div class='ratingCircle' data-index='0' style='display:inline-block; width:15px;hight:5px'><b-icon :icon="rating[0]"></b-icon></div>
		<div class='ratingCircle' data-index='1' style='display:inline-block; width:15px;hight:5px'><b-icon :icon="rating[1]"></b-icon></div>
		<div class='ratingCircle' data-index='2' style='display:inline-block; width:15px;hight:5px'><b-icon :icon="rating[2]"></b-icon></div>
		<div class='ratingCircle' data-index='3' style='display:inline-block; width:15px;hight:5px'><b-icon :icon="rating[3]"></b-icon></div>
		<div class='ratingCircle' data-index='4' style='display:inline-block; width:15px;hight:5px'><b-icon :icon="rating[4]"></b-icon></div>

	</b-form-group>
</template>

<script>


export default {
  data() {
      return {
       	rating:['star','star','star','star','star'],
        
      }
  },
  
  created() {

  },
  mounted(){
	  var vm=this;
	


	if(this.ratingValue){
		for(var i=0;i<this.ratingValue;i++){
  		  	   if(vm.rating[i]=='star'){
  		  	   		vm.rating[i]='star-fill';
  		  	   }else{
  		  	 		vm.rating[i]='star-fill';
  		  	  }
			}
			vm.$forceUpdate();
	}

	if(typeof this.disableClick ==='undefined'){
  	  $("#"+this.id).find(".ratingCircle").click(function(){
  		  if(vm.rating[$(this).data('index')]=='star'){
  			vm.rating[$(this).data('index')]='star-fill';
  			
  		 
  		  }else{
  		  	vm.rating[$(this).data('index')]='star';
  		  }
  		  
  		  
  		  var index=$(this).data('index')
  		  
  		  if(index<4){
  		  	for(var i=index+1;i<5;i++){
  		  	  
  		  	 		vm.rating[i]='star';
  		  	  
  		    }
  		  }
  		  
  		  for(var i=0;i<index;i++){
  		  	   if(vm.rating[$(this).data('index')]=='star'){
  		  	   		vm.rating[i]='star-fill';
  		  	   }else{
  		  	 		vm.rating[i]='star-fill';
  		  	  }
  		  }
			
		
  		  vm.$forceUpdate();
  		  vm.$emit('ratingUpdate',{UserID:vm.UserID,Value:index});

	  });
	}
  },
  computed:{
  	
  },
  methods:{
  	
  },
  props:["ratingValue","UserID","disableClick","id"]
 
  
}
</script>
