<template>
  <div>
      <div  v-if="hasCategories"> 
      <ChildFormFieldsCard v-for="(cat,index) in fields" :key="index"
          :fields="cat.fields"
          :defaultTitle="cat.title"
          :rowData="rowData"
          :rowID="rowID"
          :formType="formType"
          :serviceName="serviceName"
          :serverParams="serverParams"
          :isChild="true"
          @refresh-add-edit-child="refreshForm"
          v-show="showCategory(cat)"
          @inputTriggered="childUpdateFormValues"


      >
      </ChildFormFieldsCard>
    </div>
     <FormCard v-else
        headerClass="global-primary__bg global-primary__bg--no-hover"
      >
        <template v-slot:header>{{ defaultTitle }} </template>
        <template v-slot:body>
          <b-row>
            <b-col
              sm="12"
              md="6"
              :xl="getXl(field.Type, field.BCol)"
              v-for="(field, j) in fields"
              :key="`${field.Name}${j}`"
              v-show="showColumn(field) && checkifvisible(field)"
            >
              <b-form-group>
                <LabelTooltip
                  v-show="field.Type != 'PermissionsCard' && field.Type!= 'LinkedEventsCard'  && field.Type!='LinkedFormsCard'"
                  :iconID="`${field.Name}_tt`"
                  :label="field.Text"
                  :labelID="`${field.Name}_label`"
                  :required="field.Required"
                  :showTooltip="false"
                  :tooltipText="field.HelpText"
                />
                <InputFactory
                  :rowID="rowID?rowID:''"
                  :id="field.Name"
                  :type="field.Type"
                  :showCheckHeader="false"
                  :showSelectAll="false"
                  tagContainerClass="border-0 p-0"
                  :required="field.Required ? '1' : '0'"
                  :options="field.Options"
                  :disabled="formType === 'Edit' ? field.DisableEdit : false"
                  :lvParams="field.ListParams"
                  v-model="rowData[field.Name]"
                  @input="updateFormValues($event, field)"
                  @refresh-add-edit="refreshForm"
                  @update-row-data="updateRowValue"
                  @addDynamicRequired="updateRequiredFields"
                  :serviceName="serviceName"
                  :passedParams="serverParams"
                  :rowData="rowData"
                  :cssClasses="field.CssClasses"
                  :filterCurrentRecord="field.filterCurrentRecord"
                  :showEmptySelect="field.showEmptySelect"
                  :setEmptyNull="field.setEmptyNull"



                />
              </b-form-group>
            </b-col>
          </b-row>
        </template>
      </FormCard>
  </div>
</template>
<script>
import FormCard from '@/components/FormCard';
import InputFactory from '@/components/InputFactory';
import LabelTooltip from '@/components/LabelTooltip';


import { ORG_ID } from '@/config';
export default {
  name: 'FormFieldsCard',
  components: {
    FormCard,
    InputFactory,
    LabelTooltip,
    ChildFormFieldsCard:()=>import('@/components/FormFieldsCard.vue') 
  },
  props:{
    fields:{type:Array,default:function(){return []}},
    defaultTitle:{type:String,default:null},
    hasCategories:{type:Boolean,default:false},
    rowID:{type:String,default:null},
    rowData:{type:Object,default:function(){return []}},
    formType:{type:String,default:null},
    serviceName:{type:String,default:null},
    serverParams:{type:Object,default:function(){return {}}},
    isChild:false
  },
  data() {
    return {
      ORG_ID: ORG_ID
    };
  },
  mounted(){
   
  },
  methods:{

      childUpdateFormValues(event,field){
     
        this.$emit("childInputTriggered",event,field)

      },
      updateFormValues(event, field, isDynamic=false) {


        this.$emit("inputTriggered",event,field)
      /*

      if (Array.isArray(field.Children)) {
        for (let child of field.Children) {
          if (child.Command === 'fetchOptions' || child.Command === 'fetchChildFormValues') {
            const payload = { parent: field, child, parentValue: event };
            if (child.Command === 'fetchOptions') {
              this.$emit('fetchFormOptions', payload);
            } else if (child.Command === 'fetchChildFormValues') {
              this.$emit('fetchChildFormValues', payload);
            }
          } else if (child.Command === 'appendToDefault') {
            this.$emit('appendToDefault', {
              parent: field,
              child,
              parentValue: event,
            });
          } else if (child.Command === 'showSelectedField') {
            this.$emit('showSelectedField', {
              parent: field,
              child,
              parentValue: event,
            });
          }
        }
      }
      */

      if(isDynamic){
        this.checkDynamicNowVisible();
      }
      
    },
     showCategory(cat){
       var vm=this;


      if(cat.DisplayFieldCondition){

        if(cat.DisplayFieldCondition['value']){


            if(Array.isArray(cat.DisplayFieldCondition['value'])){
           
            
              var hasValue=false;
              $.each(JSON.parse(JSON.stringify(cat.DisplayFieldCondition['value'])),function(key,value){
                 

                  if(Array.isArray(vm.rowData[cat.DisplayFieldCondition['field']])){

                      if(vm.rowData[cat.DisplayFieldCondition['field']].includes(value)){
                                                hasValue=true;


                      }

                  }else if(String(value).valueOf()==String(vm.rowData[cat.DisplayFieldCondition['field']]).valueOf()){
                      hasValue=true;
                  }
                 
              });

              return hasValue;

            }else{

               if(Array.isArray(this.rowData[cat.DisplayFieldCondition['field']])){
                    return this.rowData[cat.DisplayFieldCondition['field']].includes(cat.DisplayFieldCondition['value']);


               }else{

                    return this.rowData[cat.DisplayFieldCondition['field']]==cat.DisplayFieldCondition['value'];

               }
            }
        }else{

          return this.rowData[cat.DisplayFieldCondition['field']];
        }
      }else{
                     

        return true;
      }

     },
     showColumn(field) {
      // if (this.formType === 'Edit' && field.Type === 'FilePicker') {
      //   return false;
      // }

      return field.Hidden ? false : true;
    },
    getXl(type, bcol=null) {
      if (bcol)
        return bcol;
      if (type === 'MultiSelectStartEndDate' || type === 'DropdownStartEndDate')
        return '6';
      if (type === '4' || type === 'Editor') return '12';
      return '4';
    },
    checkifvisible(field){
      var vm=this;

      if(field.DisplayFieldCondition){
        if(field.DisplayFieldCondition['value']){


            if(Array.isArray(field.DisplayFieldCondition['value'])){
           
            
              var hasValue=false;
              $.each(JSON.parse(JSON.stringify(field.DisplayFieldCondition['value'])),function(key,value){
                 
                  if(String(value).valueOf()==String(vm.rowData[field.DisplayFieldCondition['field']]).valueOf()){
                      hasValue=true;
                  }
                 
              });

              return hasValue;

            }else{
              return this.rowData[field.DisplayFieldCondition['field']]==field.DisplayFieldCondition['value'];
            }
        }else{

          return this.rowData[field.DisplayFieldCondition['field']];
        }
      }else{
                     

        return true;
      }
    },

    getDynamicFormRows(getRowUri,categoryID){
          var data={};

          if(this.rowData.ID){
              data['ID']=this.rowData.ID;
          }

          data['routerParams']=this.$route.params;


        this.$http
        .post(getRowUri, data)
        .then(response => {
        
          

          var vm=this;
          $.each(response.data.data.rows,function(index,row){
            $.each(row.fields,function(fieldIndex,field){
                  if(field.Required){
                            
                            vm.$root.$emit('addedDynamicFieldRequired',{fieldAdded:field,categoryID:categoryID});

                          }


                         


                          if(response.data.data.answers[field.Name]){
                              vm.rowData[field.Name]=response.data.data.answers[field.Name];
                          }else if(field.DefaultValue){
                              vm.rowData[field.Name]=field.DefaultValue;

                          }else{
                              vm.rowData[field.Name]=null;

                          }
            });

             vm.deynamicFields.push(row);
       
          });


            vm.dynamicFieldIndex=response.data.data.fieldIndex;



         
        })
        .catch(error => {
   
        });

    },
    dynamicFormOptionClicked(value,submitUri,categoryID){
      
      var data={};

      if(this.rowData.ID){
          data['ID']=this.rowData.ID;
      }

      data['optionChoosen']=value;
      data['currentIndex']=this.dynamicFieldIndex+1;
      data['routerParams']=this.$route.params;

       this.$http
        .post(submitUri, data)
        .then(response => {
          this.dynamicFieldIndex+=1;
          

          var vm=this;
          $.each(response.data.data,function(index,row){
            $.each(row.fields,function(rowIndex,field){
                if(field.Required){
                    
                    vm.$root.$emit('addedDynamicFieldRequired',{fieldAdded:field,categoryID:categoryID});

                  }



                  if(field.DefaultValue){
                      vm.rowData[field.Name]=field.DefaultValue;

                  }else{
                      vm.rowData[field.Name]=null;

                  }
            });
             vm.deynamicFields.push(row);

        
          });


         
        })
        .catch(error => {
   
        });

    },
    removeDynamicRow(index){

      var field=this.deynamicFields[index];

      if(field){
          var vm=this;
          $.each(field.fields,function(index,value){

            if(vm.rowData[value.Name]){
               delete vm.rowData[value.Name];
            }
          });

      }

      this.deynamicFields.splice(index,1);
    },
    refreshForm(){
      if(this.isChild){
           this.$emit('refresh-add-edit-child');

      }else{
           this.$emit('refresh-add-edit-form-fields');


      }
    },
    updateRowValue(data){

        if(data.data.requiredFields){

            var passedData=data.data.data;

            this.rowData[data.fieldName]=passedData;
            this.$root.$emit("addRequiredFields",{fields:data.data.requiredFields,fieldName:data.fieldName});

        }else{
            this.rowData[data.fieldName]=data.data;

        }

        

    },
    updateRequiredFields(data){
                this.$root.$emit("addRequiredFields",{fields:data.data,fieldName:data.fieldName});

    }
  }
};
</script>
