<template>
  <b-navbar-brand
    v-if="homePage"
    class="align-middle text-dark d-inline-block"
    :to="$store.getters.getIsOnCollectionLogout || $route.name=='optOut'  || $route.name=='optOutUser' ||  $store.getters.getIsOnLocationLogout || $store.getters.getNoHome?'':homePage"
  >
    <div class="text-wrap" style="display:table;width:100%;height:60px;">
      <b-img
        height="60"
        :src="logoURL"
        :alt="siteName"
        class="d-inline-block"
      ></b-img>
      <div
        class="align-middle text-wrap font-weight-bold pl-2"
        style="display:table-cell;height:30px;"
      >
        {{ siteName }}
      </div>
    </div>
  </b-navbar-brand>
</template>
<script>
import BrandModel from '../models/Brand';
import { API_URL, BASE_URL, ORG_ID } from '@/config';
import ColorStyler from '@/models/ColorStyler';

export default {
  name: 'Brand',
  data() {
    return {
      logoURL: '',
      siteName: '',
      homePage: '',
    };
  },
  created() {
    this.getLogo();
    /*if (this.isLogged) {
      this.getColor();
    }*/

    this.getColor();


  },
  mounted(){

  },
  methods: {
    async getLogo() {
      try {
        const response = await this.$http.get(
          API_URL + `apiBrand/getBrandInfo`
        );
        if (response && response.data && response.data.logo) {
          this.logoURL = API_URL + response.data.logo;
        } else {
          this.logoURL = `${BASE_URL}${process.env.VUE_APP_PUBLIC_PATH}images/${ORG_ID}_logo.png`;
        }
        this.siteName = response.data.siteName;
        this.homePage = response.data.homePage;
    
      } catch (error) {
        console.log(error);
      }
    },

    async getColor() {
      try {
        const response = await this.$http.get(
          API_URL + `apiBrand/getBrandInfo`
        );
        const colorStyler = new ColorStyler();
        if (response && response.data && response.data.colorScheme) {
          colorStyler.addColors(response.data.colorScheme);
          colorStyler.setColors();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
