import auth from '@/router/middleware/auth';
import MobileLogoutPage from './MobileLogoutPage'

export default [
  {
    path: '/mobileLogoutPage',
    name: 'mobileLogoutPage',
    component: MobileLogoutPage,
    meta: {
      middleware: [auth]
    }
  },
];