<template>
  <span></span>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'Logout',
  data () {
    return {

    };
  },
  created () {
    
   
      this.recursiveLogOut();

    
  },
  methods: {
    recursiveLogOut(){
      this.logout().then(response => {
       if(response){
         var vm=this;

          //recursively check to see if actually logged out due to quirk of Yii
          this.$store.dispatch('checkUserToken',true)
          .then(response =>{
              if(response !== -1){

                  this.recursiveLogOut();
              }else{
                  this.$router.push('/login');
              }
          });
      
       }

      });
    },
    ...mapActions(['logout'])
  }
};
</script>
