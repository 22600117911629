<template>
  <div id='logoutMessageContainer' v-show="logoutMessage" style='width:100%'>
    <div style="width:60%;margin:0 auto" class="surveyCompletedPage" v-html="logoutMessage"></div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'Logout',
  data () {
    return {
        logoutMessage:null
    };
  },
  created () {
    
        this.$store.commit('SET_IS_ON_COLLECTION_LOGOUT',true);
        
      this.recursiveLogOut();

    
  },
  methods: {
    recursiveLogOut(){
      this.logout().then(response => {
       if(response){
         var vm=this;

          //recursively check to see if actually logged out due to quirk of Yii
          this.$store.dispatch('checkUserToken',true)
          .then(response =>{
              if(response !== -1){
                  this.recursiveLogOut();
              }else{

                  if(this.$route.params.logoutMessage){
                    this.logoutMessage=this.$route.params.logoutMessage;
                  }
              }
          });
      
       }

      });
    },
    ...mapActions(['logout'])
  }
};
</script>
