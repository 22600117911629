<template>
  <div>
    <div>
      {{ $t("mobileLandingPage.wait") }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';
axios.defaults.withCredentials = true;

export default {
  name: 'mobileLandingPage',
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.recursiveLogOut();
  },
  methods: {
    ...mapActions(['logout', 'mobileCollectionLogin']),
    recursiveLogOut() {
      this.logout().then((response) => {
        if (response) {
          //recursively check to see if actually logged out due to quirk of Yii
          this.$store.dispatch('checkUserToken', true).then(response => {
            if (response !== -1) {
              this.recursiveLogOut();
            } else {
              const params = {
                username: this.$route.query.username,
                assessmentID: this.$route.query.assessmentID,
                loginKey: this.$route.query.loginKey,
              };
              const formUrl = this.$route.query.formUrl;
              this.mobileCollectionLogin(params)
                .then(response => {
                  if (response.success) {
                    if (formUrl) {
                      this.$router
                        .push({
                          path: formUrl,
                          query: { fromMobile: true },
                        })
                        .catch((err) => {
                          console.log('Router Push Error: ', err)
                          });
                    }
                  } else {
                    this.$router.push({ name: 'mobileLogoutPage' });
                  }
                })
                .catch(err => {
                    console.log('mobileCollectionLogin Error: ', err)
                    this.$router.push({ name: 'mobileLogoutPage', params: {mobileCloseDialog: `Error: ${err.message}. Please close and try again.`} });
                });
            }
          });
        }
      });
    },
  },
};
</script>
