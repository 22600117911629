import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import lodash from 'lodash';
import Vue from 'vue'



export function init(Survey,vm) {
    var testEditWidget={
        name:'datepicker',
        title:'Date Picker',
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'datepicker';
        },
        init(){
          Survey.Serializer.addClass('datepicker',[],null,'empty');
        },
        htmlTemplate:"<div><input type='text'/></div>",
        afterRender:function(question,el){


          $(el).empty();
          var ComponentClass = Vue.extend(Datepicker)
          var instance = new ComponentClass()
           var ref='datePicker_1'
            $(el).attr('ref',ref);

            
            
            $(el).css("white-space","normal");

            instance.$mount();


            var format='MM/dd/yyyy';

            if(question.DateFormat){
      
              var delimiter=null;
              var formatExploded=null;
              if(question.DateFormat.indexOf('/')>-1){
                  delimiter='/';
                  formatExploded=question.DateFormat.split('/');
              }else if(question.DateFormat.indexOf('-')>-1){
                  delimiter='-';
                  formatExploded=question.DateFormat.split('/');
      
              }
      
              var reformattedStringElements=new Array;
              $.each(formatExploded,function(key,formatvalue){
                  switch(formatvalue){
                    case 'm':
                      reformattedStringElements.push('MM');
                      break;
                    case 'd':
                      reformattedStringElements.push('dd');
                      break;
                    case 'y':
                      reformattedStringElements.push('yyyy');
                      break;
                  }
              });
      
      
              var reformattedString=reformattedStringElements.join(delimiter);

              format=reformattedString;
              
            

      
            
            }
            instance.format=format;

            if(vm.readOnly){
              instance.disabled=true;
            }

            if(question.value){
              instance.value=question.value;

            }else if(question.Default){
              if(question.Default=='today'){
                
                  let today=new Date();
                  let month=today.getMonth()+1;
                  let year=today.getFullYear();
                  let day=today.getDate();

                  instance.value=[month,day,year].join('/');
                  question.value=[month,day,year].join('/');
              }else if(question.Default=='CompletionDate'){
                if(vm.completionDate){
                  
                  let date=new Date(vm.completionDate);
                  let month=date.getMonth()+1;
                  let year=date.getFullYear();
                  let day=date.getDate();

                  instance.value=[month,day,year].join('/');
                  question.value=[month,day,year].join('/');


                }else{
                  let today=new Date();
                  let month=today.getMonth()+1;
                  let year=today.getFullYear();
                  let day=today.getDate();

                  instance.value=[month,day,year].join('/');
                  question.value=[month,day,year].join('/');

                }
              }
            }

            instance.$on('input',(data) =>{
                var date=new Date(data);

                var momentFormat=format.toUpperCase();
                date=moment(date).format(momentFormat);

                question.value=date;




            });



            $(el).append(instance.$el);


     
      
        },
        willUnmount: function (question, el) {
          $(el).empty();
          

        },
        pdfQuestionType: "empty",
        pdfRender: function (survey, options) {
          if (options.question.getType() === "datepicker") {
            const loc = new Survey.LocalizableString(survey, true);




          
              loc.text = options.question.value || "";



            options.question["locHtml"] = loc;

            if (
              options.question.renderAs === "standard" ||
              options.question.renderAs === "image"
            ) {
              options.question["renderAs"] = options.question.renderAs;
            } else options.question["renderAs"] = "auto";
            const flatHtml = options.repository.create(
              survey,
              options.question,
              options.controller,
              "html"
            );
            return new Promise(function (resolve) {
              flatHtml.generateFlats(options.point).then(function (htmlBricks) {
                options.bricks = htmlBricks;
                resolve();
              });
            });
          
          }

        }
      

      };
    //Register our widget in singleton custom widget collection

   


    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('datepicker')){
      Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget, "customtype");

    

     }
    
    
}