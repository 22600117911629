<template>

    <b-container :key="updateCard">
     
      <div v-if="canShowGrid">
               <ListView
                    serviceNameProp="occupancyGroup"
                    :isChild="true"
                    :isGridField="true"
                    :passedParams="{...params,rowData:rowData,groups:localValue}"
                    :ref="'listViewInstance_'+fieldID"
                    @groupAdded="addGroup($event)"
                    @groupEdited="editGroup($event)"
                    @deleteGroup="deleteGroup($event)"
                    
                  >
                    
                  </ListView>

      </div>
      <div v-else>
          <b-alert show variant="success">{{ hiddenMessage }}</b-alert>
      </div>
                  
     
    </b-container>
</template>
<script>

import { BCollapse } from 'bootstrap-vue';
import Label from './Label.vue';
import { ErrorMessages } from '@/utils/errorMessages.js';
import { isEmpty } from '@/utils/isEmpty.js';
import FormCard from '@/components/FormCard';
import CustomDialog from '@/components/CustomDialog.vue';
import { deepCopy } from '@/utils/langUtils';
import Vue from 'vue';



export default {
  name: 'LinkedEventsCard',
  components: {
      ListView: () => import('@/app/list-view/index.vue'),
      ecenterDialog: () => import('@/components/ecenterDialog.vue')

  },
  data() {
    return {
      addButtonText:"Add",
      service:null,
      occupancyDetails:[],
      renderCustomDialog:false,
      refreshForms:null,
      loaded:false,
      customDialogSettings:{},
      service:null,
      formSettings:{},
      refreshFamily:null,
      refreshParams:null,
      filterValues:{},
      hasCapacity:false,
      capacityName:null,
      updateCard:null,
      localValue:[],
      canShowGrid:false,
      hiddenMessage:null

    };
  },
  props: {
    fieldID:{type: String, default:null},
    value: {type: Array,default:function(){return new Array}},
    rowID:{type: String, default:null},
    serviceName:{type: String, default:null},
    passedFilters:{type:Object, default:function(){return {}}},
    rowData:{type:Object, default:function(){return {}}},
    params:{type:Object, default:function(){ return {} }},


    
  },

  created() {
      this.service = require('@/services/' + this.serviceName);

      this.service.getCapaityFieldName()
      .then(response=>{
          this.capacityName=response.capacityName;
      });
       


  },
  beforeMount(){
  
  },
  mounted(){

      this.service.getAuditGridCanShow(this.rowData)
      .then(response =>{

            this.canShowGrid=response.canShow;
            this.hiddenMessage=response.message;
           if(this.value && this.value.length>0){
          
                this.localValue=deepCopy(this.value);


                this.updateCard=new Date().getTime();
            }


      });


   
      
      /*

      this.service.getOccupancyGroupFormSettings({CustomFieldID:this.fieldID,RowID:this.rowID,rowData:this.rowData})
      .then(response =>{
        this.formSettings=response.formSettings;

        this.addButtonText=response.formSettings.addButtonText;

        this.hasCapacity=response.formSettings.hasCapacity;
        this.$forceUpdate();

        if(this.value){
          this.getOccupancyGroupDetails();
        }

      }); 

        */  

  },
  methods: {
      
      addGroup(data){
        
         var newValue=new Array;


           if(this.value){
              newValue=deepCopy(this.value);
           }
              
           
              newValue.push(data);
            

               



            

     

         

          this.$emit('input',newValue);
          this.updateCard=new Date().getDate();

      },
      editGroup(data){

          var newValue=new Array;


          
              newValue=deepCopy(this.value);
           

               

               if(newValue[data.ID]){
                 newValue[data.ID]=data;
               }

           
            

               this.$emit('input',newValue);
                 this.updateCard=new Date().getDate();


      },
      deleteGroup(data){

         var newValue=new Array;


          
              newValue=deepCopy(this.value);


              if(newValue[data.groupID]){
                newValue.splice(data.groupID,1);
              }


               this.$emit('input',newValue);
                this.updateCard=new Date().getDate();
      

      }



     


        

  
  },
};
</script>
