
<style>
		
	</style>
<template>

 <b-container fluid id='reviewContainer'>
	 
	 <div class="w-100 text-right" style="padding-bottom:30px" v-if="!addMode && !addOnly">
		 <b-button size="sm" @click="addReview()">{{ $t("mentorship.ProviderReviews.add") }} <b-icon icon="plus"></b-icon></b-button>
	 </div>
	 <div v-else class="w-100 text-left" style="padding-bottom:30px">
		 <b-card no-body>
			 <b-card-header>
				 <div class="w-75 text-left" style="display:inline-block"> 
					<h5>{{ $t("mentorship.ProviderReviews.new") }}</h5>
				</div>
				<div class="w-25 text-right" style="display:inline-block">
					<b-button size="sm" @click="saveReview()" variant="success" style="padding-right:5px">{{ $t('general.save') }}</b-button>
					<b-button size="sm" @click="cancelReview()" variant="danger">{{ $t('general.cancel') }}</b-button> 
				</div>
			 </b-card-header>
			 <b-card-body>
				 		  <table style="border:none;margin-bottom:20px">
							<tr>
							<td style="padding-left:0;border:none">
								<p style="margin-top:0;font-size:14px;margin-bottom:3px">{{ $t("mentorship.ProviderReviews.service") }}<b-form-rating style="width:5%" size="sm" inline no-border variant="warning" v-model="newReview.Rating"></b-form-rating> </p>
								
							</td>
							<td style="border:none">
								<p style="margin-top:0;font-size:14px;margin-bottom:3px"> {{ $t("mentorship.ProviderReviews.works") }}<b-form-rating style="width:5%" size="sm" inline no-border variant="warning" v-model="newReview.RespectRating"></b-form-rating></p>
							</td>
							</tr>
							<tr v-if="validationError.Rating">
								<td>
									<div v-if="validationError.Rating" class="text-danger">
										{{ $t("mentorship.ProviderReviews.required") }} 
									</div>	
								</td>
							</tr>
						</table>
						<b-row v-if="addOnly">
									<div v-if="newReview.services">
											<div v-for="(service,index) in newReview.services" :key="service.TagID" style="padding-bottom:20px;padding-left:20px;display:inline-block" cols="10">
														<b-badge tag="div" style='font-size:10pt' variant="info"><div style='display:inline-block;padding:10px'>{{ getOptionName(servicesForProvider,service) }}</div><b-button style="max-height:20px !important;font-size:10px;margin-top:-5px" size="sm" variant="info" @click="removeService(index)"><b-icon icon="x"></b-icon></b-button></b-badge>
								
											</div>
									</div>
						</b-row>
						<b-row v-if="addOnly">
							<div style="padding-left:20px">
						      <b-dropdown size="sm" variant="outline-secondary"  block>
													<template v-slot:button-content>
														{{ $t("mentorship.ProviderReviews.select") }}
													</template>
												
													<b-dropdown-item-button
													v-for="option in availableServices"

													:key="option.value"
													@click="addService(option)"
													>
													{{ option.text }}
													</b-dropdown-item-button>
													<b-dropdown-text v-if="availableServices.length === 0">
														{{ $t("mentorship.ProviderReviews.available") }}
													</b-dropdown-text>
											</b-dropdown>
							</div>
							
						</b-row>
						<b-row v-if="validationError.services">
							<div v-if="validationError.services" class="text-danger">
								{{ $t("mentorship.ProviderReviews.reviewed") }} 
							</div>
						</b-row>
						<hr>
						<b-form-textarea
							v-model="newReview.Review"
							max-rows="20"
							rows="5"
							:placeholder="$t('mentorship.ProviderReviews.review')"
						>
						</b-form-textarea>
			 </b-card-body>
		 </b-card>
		 
	
	 </div>
	<div v-if="!addOnly">
	 <h4>{{ $t("mentorship.ProviderReviews.reviews") }}</h4>
	 <b-row>
       <b-col  style="padding-right:0; max-width:40%">
        <b-form-input v-model="searchTerm" size="sm" debounce="700" :placeholder="$t('mentorship.ProviderReviews.search')">

        </b-form-input>
       </b-col>
        
    </b-row>
	 <vue-good-table
        :columns="reviewField"
        :rows="reviews"
		:key="refresh"
		
	>

     <template slot="emptystate">
								<div style="text-align:center;width:100%;height:200px">
									{{ $t("mentorship.ProviderReviews.found") }}
								</div>
	</template>
	 <template slot="table-column" slot-scope="props">
		 <div class='text-left w-25' style="display:inline-block">
			  <div v-if="hasFilter || hasSort">
             	 <b-button variant="link"  @click="clearFiltersAndSort" :class="'font-weight-bold '+textVariant()" size="sm">{{ $t("mentorship.ProviderReviews.reload") }}<b-icon icon="arrow-counterclockwise"></b-icon></b-button>
            </div>
		</div>
		 <div class='text-right w-75' style="display:inline-block">
			   <b-dropdown variant="link" :toggle-class="'text-decoration-none font-weight-bold text-'+$store.state.themeDefaults.eCenterTableFilterRowTextVariant " id="dropdown-form" text="Service Rating" ref="dropdown" class="my-1 filter-dropdown-button" menu-class="filterMenu">
			
			 	 <b-dropdown-group>
						<b-dropdown-text class="text-small">
							<small class="font-weight-bold">{{ $t("mentorship.ProviderReviews.rating") }}</small>
						</b-dropdown-text>

						<b-dropdown-divider></b-dropdown-divider>
			 			<b-dropdown-item v-if="!RatingVal || RatingVal==5"  @click="filterClicked('serviceRating',5)"><div :class="filters.serviceRating && filters.serviceRating==5?'bg-primary text-white':''">5</div></b-dropdown-item>
						<b-dropdown-item v-if="!RatingVal || RatingVal==4"  @click="filterClicked('serviceRating',4)"><div :class="filters.serviceRating && filters.serviceRating==4?'bg-primary text-white':''">4+</div></b-dropdown-item>
						<b-dropdown-item v-if="!RatingVal || RatingVal==3"  @click="filterClicked('serviceRating',3)"><div :class="filters.serviceRating && filters.serviceRating==3?'bg-primary text-white':''">3+</div></b-dropdown-item>
						<b-dropdown-item v-if="!RatingVal || RatingVal==2"  @click="filterClicked('serviceRating',2)"><div :class="filters.serviceRating && filters.serviceRating==2?'bg-primary text-white':''">2+</div></b-dropdown-item>
						<b-dropdown-item v-if="!RatingVal || RatingVal==1"  @click="filterClicked('serviceRating',1)"><div :class="filters.serviceRating && filters.serviceRating==1?'bg-primary text-white':''">1+</div></b-dropdown-item>
			 	 </b-dropdown-group>
			 </b-dropdown>
			  <b-dropdown variant="link" :toggle-class="'text-decoration-none font-weight-bold text-'+$store.state.themeDefaults.eCenterTableFilterRowTextVariant " id="dropdown-form" text="Works Well With First Nations Rating" ref="dropdown" class="my-1 filter-dropdown-button" menu-class="filterMenu">
			
			 	 <b-dropdown-group>
						<b-dropdown-text class="text-small">
							<small class="font-weight-bold">{{ $t("mentorship.ProviderReviews.worksRating") }}</small>
						</b-dropdown-text>

						<b-dropdown-divider></b-dropdown-divider>
			 			<b-dropdown-item  @click="filterClicked('respectRating',5)"><div :class="filters.respectRating && filters.respectRating==5?'bg-primary text-white':''" >5</div></b-dropdown-item>
						<b-dropdown-item  @click="filterClicked('respectRating',4)"><div :class="filters.respectRating && filters.respectRating==4?'bg-primary text-white':''" >4+</div></b-dropdown-item>
						<b-dropdown-item  @click="filterClicked('respectRating',3)"><div :class="filters.respectRating && filters.respectRating==3?'bg-primary text-white':''" >3+</div></b-dropdown-item>
						<b-dropdown-item  @click="filterClicked('respectRating',2)"><div :class="filters.respectRating && filters.respectRating==2?'bg-primary text-white':''" >2+</div></b-dropdown-item>
						<b-dropdown-item  @click="filterClicked('respectRating',1)"><div :class="filters.respectRating && filters.respectRating==1?'bg-primary text-white':''" >1+</div></b-dropdown-item>
			 	 </b-dropdown-group>
			 </b-dropdown>
 			<b-dropdown variant="link" :toggle-class="'text-decoration-none font-weight-bold text-'+$store.state.themeDefaults.eCenterTableFilterRowTextVariant " id="dropdown-form" text="Sort" ref="dropdown" class="my-1 filter-dropdown-button" menu-class="filterMenu">
			
			 	 <b-dropdown-group>
						<b-dropdown-text class="text-small">
							<small class="font-weight-bold">{{ $t("mentorship.ProviderReviews.sort") }}</small>
						</b-dropdown-text>

						<b-dropdown-divider></b-dropdown-divider>
			 			<b-dropdown-item  @click="sortClicked('CreatedDate','desc')">{{ $t("mentorship.ProviderReviews.submittedRecent") }}</b-dropdown-item>
						<b-dropdown-item @click="sortClicked('CreatedDate','asc')">{{ $t("mentorship.ProviderReviews.submittedOldest") }}</b-dropdown-item>
						<b-dropdown-item @click="sortClicked('Rating','desc')">{{ $t("mentorship.ProviderReviews.ratingHigh") }}</b-dropdown-item>
						<b-dropdown-item @click="sortClicked('Rating','asc')">{{ $t("mentorship.ProviderReviews.ratingLow") }}</b-dropdown-item>
						<b-dropdown-item @click="sortClicked('RespectsFN','desc')">{{ $t("mentorship.ProviderReviews.worksHigh") }}</b-dropdown-item>
			 	 		<b-dropdown-item @click="sortClicked('RespectsFN','asc')">{{ $t("mentorship.ProviderReviews.workLow") }}</b-dropdown-item>

				  </b-dropdown-group>
			 </b-dropdown>

		 </div>
	 
	 </template>
		<template slot="table-row" slot-scope="props">
			<b-card no-body>
				<b-card-header>
						<p style="margin-bottom:0;font-size:14px" >Submitted by: {{ props.row.Review.SubmittedBy }} on {{ props.row.Review.CreatedDate }} </p>
                                     
				</b-card-header>
				<b-card-body style="padding-top:0px">
					 <table style="border:none">
                                       <tr>
                                         <td style="padding-left:0;border:none;width:25%">
                                            <p style="margin-top:0;font-size:14px;margin-bottom:3px">{{ $t("mentorship.ProviderReviews.service") }}<b-form-rating style="width:5%" size="sm" inline no-border :readonly="editReviewID==null || editReviewID!=props.row.Review.ID" variant="warning" v-model="props.row.Review.ServiceRating"></b-form-rating> </p>
                                         </td>
                                         <td style="border:none;width:25%">
											 <p style="margin-top:0;font-size:14px;margin-bottom:3px">{{ $t("mentorship.ProviderReviews.works") }}<b-form-rating style="width:5%" size="sm" inline no-border :readonly="editReviewID==null || editReviewID!=props.row.Review.ID" variant="warning" v-model="props.row.Review.RespectsFNRating"></b-form-rating> </p>

                                         </td>
										  <td v-if="props.row.Review.ID==editReviewID" style="border:none;width:40%" class='text-right'>
											  <b-button @click="saveEdit(props.row)" variant="success">{{ $t("general.save") }}</b-button>
											  <b-button @click="cancelEdit()" variant="danger">{{ $t("general.cancel") }}</b-button>
										  </td>
										 <td v-else style="border:none;width:40%" class='text-right'>
											  <b-dropdown v-if="props.row.Review.CreatedByUser || canEditDelete" class="align-middle" size="sm" :variant="$store.state.themeDefaults.buttonVariant" text="Actions">
												     <b-dropdown-item @click="editReview(props.row)"><b-icon-pencil></b-icon-pencil> {{ $t("general.edit") }}</b-dropdown-item>
             										 <b-dropdown-item @click="deleteReview(props.row)"><b-icon-trash></b-icon-trash> {{ $t("general.delete") }}</b-dropdown-item>

											   </b-dropdown>
										 </td>
                                       </tr>
                                     </table>
									 <b-row>
										 
										 	<b-col v-if="props.row.Review.ID==editReviewID" style="width:100%;padding-left:18px">
												 <b-form-textarea v-model="props.row.Review.Review" rows="5" max-rows="20">

												 </b-form-textarea>
											</b-col>
											<b-col v-else style="font-size:14px;padding-left:18px">
											{{ props.row.Review.Review }}
											</b-col>
           										
									 </b-row>
				</b-card-body>
			 </b-card>
		</template>
	 </vue-good-table>
	</div>
 </b-container>

</template>


<script>

import lodash from "lodash";
import * as providerService from "@/services/Mentorship Module/providers";
	import { VueGoodTable } from 'vue-good-table';		

export default {
	props:{
		ProviderID:String,
		ReviewID:String,
		ServiceTagID:String,
		RatingVal:String,
		addOnly:Boolean
  },
  data() {
      return {
		hasSort:false,
		hasFilter:false,
		refresh:new Date().getTime(),
		editReviewID:null,
		newReview:{services:[]},
		addMode:false,  
		editMode:false,
		respectFN:[],
		providerName:null,
		reviews:[],
		canEditDelete:false,
		 reviewField:[
        	
          {field:"Review",label:"Reviews",sortable:false},
   

        	
        	
        	
		],
		filters:{},
		sortFields:{},
		searchTerm:null,
		servicesForProvider:[],
		validationError:{}
      }
  },
  
  mounted() {

	  

	  				$("#reviewContainer").find(".vgt-table").find('th').addClass('bg-'+this.$store.state.themeDefaults.eCenterTableFilterRowVariant);
					$("#reviewContainer").find(".vgt-table").find('th').addClass('text-'+this.$store.state.themeDefaults.eCenterTableFilterRowTextVariant);

					
					providerService.checkCanEditReviews()
					.then( response =>{
						this.canEditDelete=response.hasPermission;

					});

					if(!this.addOnly){
						this.getReviews();
					}


					 providerService.getProviderTags({
						 targetCategory:"Respect for First Nation",
						 tagDefinitionOnly:true
					 })
					 .then(response =>{
						 this.respectFN=response.tags



					   
									


					 });

					  this.newReview.services=new Array;

					 if(this.addOnly){
						
						providerService.getProviderTags({
							targetCategory:"Services Provided",
							ProviderID:this.ProviderID
						})
						.then(response => {
							this.servicesForProvider=response.tags;
						});
					 }




			

					
            		 
       			 
		
  },
 
  watch:{
	newReview:{
		deep:true,
		handler:function(newVal){
			if(this.validationError){
				if(this.validationError.Rating && newVal.Rating>0){
					this.validationError.Rating=null;
				}

				if(this.validationError.services && newVal.services){
					this.validationError.services=null;
				}

				this.$forceUpdate();

			}
		}
	},
	searchTerm:function(newVal,oldVal){
				this.filters.Review=newVal;

				if(newVal && newVal.length>0){
					this.hasFilter=true;
				}else{

					var filterExists=false;
					Object.keys(this.filters).forEach(filter =>{
						if(this.filters[filter] && this.filters[filter].length>0){
							filterExists=true;

						}
					});

					this.hasFilter=filterExists;

				}
				
				this.getReviews();
	},
	filters:{
		deep:true,
		handler:function(newVal,oldval){


				if(!oldval.Review && newVal.Review){
					this.getReviews();
				}else if(oldval.Review && !newVal.Review){
					this.getReviews();
				}else if(oldval.Review && newVal.Review && oldval.Review !=newVal.Review){
					this.getReviews();

				}

		}
	},
	

  },
  computed:{
	 cssVars(){
		 return {
			 '--header-color':"#ff9999"
		 }
	 },
	 availableServices(){
		 var vm=this;
        const options = this.servicesForProvider.filter(function(opt){ 

			  if(vm.newReview.services && vm.newReview.services.some(element =>{  return element.TagID==opt.TagID })){
				 
				  return false;
			  }else{
				  return true;
			  }
			  
        });
        
       
        // Show all options available
        
        
        return options
	 }
	   
      
  },
  methods:{
	  		getOptionName (fieldOptions, answerValue) {

				

				

				    var field = fieldOptions.find(element => element.TagID == answerValue.TagID);
					

					if (field) {
				
						return field.text;
					}
				

			
			  
		},
	  addService(value){
		  		
					this.newReview.services=[];
				
				this.newReview.services.push({TagID:value.TagID,Rating:0,RatingDesc:null,CreatedByUser:-1});
				this.$forceUpdate();

		},
	  clearFiltersAndSort(){
		   this.sortFields={};
		   this.filters={};
		   this.searchTerm=null;

		   this.hasFilter=false;
		   this.hasSort=false;
		   
			this.getReviews();
      },
	textVariant(){
        return 'text-'+this.$store.state.themeDefaults.eCenterTableFilterRowTextVariant
  
      },
      backgroundVariant() {
        return 'bg-' + this.$store.state.themeDefaults.eCenterTableFilterRowVariant
      },
	  clearSearch(){
		  this.filters.Review=null;
		this.searchTerm=null;

		  this.getReviews();
	  },
	  sortClicked(field,dir){
		this.sortFields={field:field,type:dir};
		this.hasSort=true;
		
		this.getReviews();
	},
	filterClicked(filterName,value){
		if(this.filters[filterName] && this.filters[filterName]==value){
			this.filters[filterName]=null;
		}else{
			this.filters[filterName]=value;
		}

		var filterExists=false;
		Object.keys(this.filters).forEach(filter =>{
			if(this.filters[filter]){
							filterExists=true;

						}
		});



		this.hasFilter=(filterExists || (this.searchTerm && this.searchTerm.length>0))?true:false;

		
		this.getReviews();
	},
	deleteReview(row){
		this.$bvModal.msgBoxConfirm('Are you sure you want to delete this review?', {
        hideHeaderClose: true,
        centered: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        okVariant: 'success',
        okTitle: 'Delete',
        cancelVariant: 'danger',
        cancelTitle: 'Cancel',
      })
        .then(value => {
          if (value) {
            providerService.deleteProviderReview({
				Review:row.Review,
				ProviderID:this.ProviderID,
				ServiceID:this.ServiceTagID
			})
			.then(response => {

				this.getReviews();
			})
			.catch(error => {
				console.log(error);
			});
          }
        });
			
	},  
	saveEdit(row){
		providerService.editProviderReview({
			Review:row.Review,
			ProviderID:this.ProviderID,
			ServiceID:this.ServiceTagID
		})
		.then(response => {
			this.editReviewID=null;

			this.getReviews();
		})
		.catch(error => {
			 console.log(error);
		});
	},
	editReview(row){
		this.editReviewID=row.Review.ID;
	},
	cancelEdit(){
			this.editReviewID=null;
	},
	addReview(){
		this.newReview={};
		this.addMode=true;
	},
	cancelReview(){
		this.newReview={};
		this.addMode=false;

		if(this.addOnly){
			this.$emit("close-add-only");
		}
	},
	saveReview(){

		var error=false;

		if(this.addOnly){
			if(this.newReview.services.length==0){
				this.validationError.services=true;
				error=true;

			}
		}

		if(!this.newReview.Rating){
			this.validationError.Rating=true;
			error=true;

		}

		if(!error){
			providerService.addProviderReview({
				Review:this.newReview,
				ProviderID:this.ProviderID,
				ServiceID:this.ServiceTagID
			})
			.then(response => {
				this.newReview={};
				this.addMode=false;

				if(this.addOnly){
					this.$emit("close-add-only");
				}else{
					this.getReviews();
				}
			})
			.catch(error => {
				console.log(error);
			});
		}else{
			this.$forceUpdate();
		}
	},
	removeService(index){
			this.newReview.services.splice(index,1);

			this.$forceUpdate();


		},
	getReviews(){

					providerService.getProviderReviews({
						ProviderID:this.ProviderID,
						ServiceTagID:this.ServiceTagID,
						RatingVal:this.RatingVal,
						filters:this.filters,
						sortField:this.sortFields
					})
           		    .then(response => {
           		    
						    
									this.reviews=response;
									

							
           		    	
           		    		
           		    		
           		    
               				
            		 })
            		 .catch(error => {
               				 console.log(error);
            		 });

	}
  },
  components: {
	  VueGoodTable
  }
}
</script>
