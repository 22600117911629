// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';
import { getFormData } from '@/utils/networkUtils';

export const getTableSettings = (payload = null) => http.post('apiProviderReview/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiProviderReview/getRows', payload).then(getData);

export const getRow = (rowID) => http.get(`apiProviderReview/getRow&ID=${rowID}`).then(getData);

export const create = row => http.post('apiProviderReview/create', row).then(getData);

export const update = (row) => http.post('apiProviderReview/edit', row).then(getData);

export const remove = (row) => http.post('apiProviderReview/delete', row).then(getData);

export const providerMigration = () => http.post('apiProviderReview/migrateRatingData', {}).then(getData);
