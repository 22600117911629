<template>
  <div style="width:100%;" id="content-wrapper">
    <toolbar v-if="!$store.getters.getHideNav"></toolbar>
    <div v-if="inTrainingMode==true">
      <b-alert show style="text-align:center" variant="warning">{{ $t("App.training") }}</b-alert>
    </div>
    <div v-if="criticalNotifications">
            <b-alert show style="text-align:center;font-weight:bold" variant="danger" v-html="$t('App.unread', {getCriticalNotificationCount:$store.getters.getCriticalNotificationCount,plural:($store.getters.getCriticalNotificationCount>1?'s':'')})"></b-alert>

    </div>
    <main id="content" :style="`min-height: 82vh;`">
      <b-sidebar id="main-sidebar" shadow v-if="isLogged" no-header body-class="bg-white" backdrop>
        <template v-slot:default="{hide}">
          <div class="d-flex p-3 justify-content-center align-items-center border-bottom">
            <Brand />
            <b-icon-x @click="hide" class="close__btn ml-3" font-scale="2"></b-icon-x>
          </div>
          <sidebar></sidebar>
        </template>
      </b-sidebar>
      <div v-if="!this.$route.query.fromMobile" style="margin-top: 1rem; padding-right: 1rem; padding-left: 1rem;">
        <router-view></router-view>
      </div>
      <div v-if="this.$route.query.fromMobile" style="margin-top: 1rem; padding-right: 0rem; padding-left: 0rem;">
        <router-view></router-view>
      </div>

    </main>

    <b-modal id="loading-modal" centered no-close-on-esc no-close-on-backdrop hide-footer hide-header no-enforce-focus>
      <Loading></Loading>
    </b-modal>
    <footer class="footer">
      <app-footer></app-footer>
    </footer>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Brand from '@/components/Brand';
import Sidebar from '@/components/sidebar';
import Toolbar from '@/components/toolbar';
import AppFooter from '@/components/app-footer';
import Loading from '@/components/Loading';
import ColorStyler from '@/models/ColorStyler';
import { ORG_ID } from './config';
import {API_URL} from '@/config';

export default {
  components: {
    Brand,
    Sidebar,
    Toolbar,
    AppFooter,
    Loading,
  },
  beforeCreate(){
      
  },
  created () {
   
    // this.$http
    //   .get(API_URL + `apiBrand/getBrandInfo`)
    //   .then(response => {
    //     const colorStyler = new ColorStyler();
    //     colorStyler.addColors(response.data.colorScheme);
    //     colorStyler.setColors();
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //   });
  },
};
</script>
<style lang="scss">
:root {
  // --global-primary: #f26822;
  --global-primary-hue: 20;
  --global-primary-sat: 89%;
  --global-primary-lig: 54%;
  --global-primary: hsl(var(--global-primary-hue), var(--global-primary-sat), var(--global-primary-lig));
  --global-primary-darker: hsl(var(--global-primary-hue), var(--global-primary-sat), calc(var(--global-primary-lig) - 10%));
  --global-primary-lighter: hsl(var(--global-primary-hue), var(--global-primary-sat), calc(var(--global-primary-lig) + 30%));

  // --global-secondary: #2073d9;
  --global-secondary-hue: 213;
  --global-secondary-sat: 74%;
  --global-secondary-lig: 49%;
  --global-secondary: hsl(var(--global-secondary-hue), var(--global-secondary-sat), var(--global-secondary-lig));
  --global-secondary-darker: hsl(var(--global-secondary-hue), var(--global-secondary-sat), calc(var(--global-secondary-lig) - 10%));
  --global-secondary-lighter: hsl(var(--global-secondary-hue), var(--global-secondary-sat), calc(var(--global-secondary-lig) + 30%));

  --global-neutral: #221E41;

  --global--font-size-base: 1rem;
  --global--font-size-sm: 0.875rem;
  --global--font-size-md: 1.5rem;
  --global--font-size-lg: 2rem;
  --global--font-size-xl: 3rem;
  --global--font-size-xxl: 4rem;
  --global--font-size-xxxl: 5rem;

  --nmtc-primary: #2b54a3;
  --nmtc-primary-darker: #25478b;
  --nmtc-tertiary: #4490b5;
  --nmtc-tertiary-darker: #3e7fa0;

  --global--input--border-radius: 0.55rem;
  --global--row-highlight: rgba(51,51,51,0.035);
  --global-action-button-light:rgba(235, 230, 230, 0.877);

}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--global-neutral);
}
a,
a:hover {
  text-decoration: none;
}
li {
  list-style-position: inside;
}
.svd-accordion-tab-content .form-group{
  margin-left:5px !important;
  margin-right:5px !important

}

 .sv_qstn{
        height:auto !important;
        overflow: visible !important;
      }

  .sv_qstn p{
        margin:0;       

  }
      .vdp-datepicker__calendar{
        z-index: 500 !important;
        position: absolute;
        width:200px
      }
      .fullScreenContent{
        width:100%;
        max-width:100%;
      }
       .fullScreenDialog{
        width:100%;
        max-width:100%;
      }

       .fullScreenBody{
        width:100%;
        max-width:100%;
      }
      .fullScreenModal{
        width:100%;
        max-width:100%;

      }
      .medScreenContent{
        width:100%;
        max-width:100%;
      }
       .medScreenDialog{
        width:60%;
        max-width:60%;
        margin: 1.75rem auto !important;
      }

       .medScreenBody{
         width:100%;
        max-width:100%;
      }
      .medScreenModal{
        width:100%;
        max-width:100%;

      }
      .surveyCreatorContent{
        width:100%;
        max-width:100%;
        height:100%;
        max-height:100%;
      }
       .surveyCreatorDialog{
        width:100%;
        max-width:100%;
        height:100%;
        max-height:100%;
      }

       .surveyCreatorBody{
        width:100%;
        max-width:100%;
        height:100%;
        max-height:100%;
      }
      .surveyCreatorModal{
        width:100%;
        max-width:100%;
        height:100%;
        max-height:100%;

      }
      .ui-datepicker {    
        width: 300px !important;
      }

      .ui-datepicker-header{
        height:40px !important;
      }

      .float-right{
        float:right
      }
      #surveyContainer .card-footer{
        height:60px !important;
      }

      .sv_save_btn{
        width:100px;
      }

      .sv_complete_btn{
        width:100px;
      }

      .svd-accordion-tab-content .form-group{
        margin-left:10px !important
      }
     
.pageDescription{
        font-size: 10pt !important;
      }

      .panelDescription{
        padding-top: 5px;
        font-size: 10pt !important;
      }
      .questionTitle, .sv_qstn span {
        font-size: 10pt !important;
      }

      .sv-string-viewer p {
        display: inline !important;
      }

      .panelBorder{
        border:1px solid #32C2B0 !important;
      }

      .panelContainer{
        width:inherit !important;
      }
      
       #surveyContainer .surveyCompletedPage{
         width:100%;
         text-align: center !important;
      }
      #surveyContainer .surveyCompletedPage h4 {
         text-align: center !important;
      }
      #logoutMessageContainer .surveyCompletedPage{
         width:100%;
         text-align: center !important;
      }
      #logoutMessageContainer .surveyCompletedPage h4 {
         text-align: center !important;
      }
       #successMessage .formSubmitted {
         width:100%;
         text-align: center !important;
         font-weight:bold;
         font-size:18pt;
      }

     
@function color($color-name) {
  @return var(--color-#{$color-name});
}

// Buttons
.btn-simple,
.btn-simple:hover,
.btn-simple:focus,
.btn-simple:not(:disabled):not(.disabled):active:focus {
  background: none;
  border: none;
  box-shadow: none;
}

// Borders
.border-bottom {
  border-bottom: 1px solid #efefee;
}
.card__border {
  border: 1px solid #00000010;
}

.btn{
  &-global-action-button-light{
    background:#efefeeb7,
    

  }
}
.global-primary {
  &__bg {
    background: var(--global-primary);
    background-color: var(--global-primary);
    color: #fff;

    &:hover {
      background-color: var(--global-primary-darker);
    }

    &:focus, &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
      background: var(--global-primary);
      background-color: var(--global-primary);
    }

    &--no-hover:hover {
      background-color: var(--global-primary);
    }

    &-outline {
      background: #fff;
      background-color: #fff;
      color: var(--global-primary);
      border: 1px solid var(--global-primary);
    }
  }
}

.show > .global-primary__bg.dropdown-toggle {
  background-color: var(--global-primary);
}

.global-secondary {
  &__bg {
    background: var(--global-secondary);
    background-color: var(--global-secondary);
    color: #fff;

    &--no-hover:hover {
      background-color: var(--global-secondary);
    }

    &:hover {
      background-color: var(--global-secondary-darker);
    }

    &:focus, &:not(:disabled):not(.disabled):active:focus {
      background: var(--global-secondary);
      background-color: var(--global-secondary);
    }
  }
}

// NMTC -- PRIMARY
.nmtc-primary__bg {
  background: var(--nmtc-primary);
  background-color: var(--nmtc-primary);
  color: #fff;
}
.nmtc-primary__bg:hover {
  background-color: var(--nmtc-primary-darker);
}
.nmtc-primary__bg--no-hover:hover {
  background-color: var(--nmtc-primary);
}
.nmtc-primary__bg:focus,
.nmtc-primary__bg:not(:disabled):not(.disabled):active:focus {
  background: var(--nmtc-primary);
  background-color: var(--nmtc-primary);
}

// NMTC -- TERTIARY
.nmtc-tertiary__bg {
  background: var(--nmtc-tertiary);
  background-color: var(--nmtc-tertiary);
  color: #fff;
}
.nmtc-tertiary__bg:hover {
  background-color: var(--nmtc-tertiary-darker);
}
.nmtc-tertiary__bg--no-hover:hover {
  background-color: var(--nmtc-tertiary);
}
.nmtc-tertiary__bg:focus,
.nmtc-tertiary__bg:not(:disabled):not(.disabled):active:focus {
  background: var(--nmtc-tertiary);
  background-color: var(--nmtc-tertiary);
}

.btn {
  border-radius: 0.5rem;
  &-to-link, &-to-link:focus, &-to-link:hover {
    color: #2073d9;
    font-weight: 600;
    padding: 0;
  }
  &-sm {
    border-radius: 0.5rem;
  }
  &-light__outline {
    border: 1px solid #e7e7e9;
  }
}

.scrollable {
  &-y {
    max-height: 200px;
    overflow-y: auto;
  }
}

// Calendar
.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
  &-day {
    cursor: pointer;
    &:hover {
      background-color: var(--global--row-highlight);
    }
  }
}

// Dropdown
.dd {
  &__text {
    &--btn {
      cursor: pointer;
      font-weight: normal;
    }
    &--hoverable {
      text-decoration: none;
      &:hover {
        background: #f8f9fa linear-gradient(180deg, #f9fafb, #f8f9fa) repeat-x;
      }
    }
  }
  &__menu {
    &--xl {
      padding: 1rem;
      max-width: 80vw;
      min-width: 50vw;
    }
    &__content--xl {
      display: flex;
      justify-content: space-between;
    }
  }
}

// Footer
.footer {
  padding: 1rem;
}
.notification_p{
    display:block;
    width:98%;
    white-space: normal !important;
}

.form__input {
  &--invalid {
    border: 1px solid #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    border-radius: var(--global--input--border-radius);
  }
  &--invalid-bg {
    border: none;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    background-color: #f5d3d7 !important; 
  }
}

// Navbar
.nav {
  &__wrapper {
    padding: 0rem 1rem;
    align-items: flex-end;
    &--outline {
      border-bottom: 1px solid var(--global-primary-lighter);
    }
  }
  &__link {
    cursor: pointer;
    padding: 1rem;
    display: inline-block;
    &--active {
      font-weight: bold;
      border-radius: 0.5rem 0.5rem 0 0;
      box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.1);
    }
    &--active:hover {
      color: #fff;
    }
  }
}

.burger__btn {
  width: 100%;
  background: transparent;
  background-color: transparent;
}

.row--highlight:hover {
  background-color: var(--global--row-highlight);
}

.row--selected {
  background-color: rgba(51,51,51,0.1);
}

// Text
.text {
  &--primary {
    color: var(--global-primary);
  }
  &--secondary {
    color: var(--global-secondary);
  }
  &--semi-bold {
    font-weight: 500;
  }
  &--sm {
    font-size: var(--global--font-size-sm);
  }
  &--md {
    font-size: var(--global--font-size-md);
  }
  &-decoration {
    &-overline {
      text-decoration: overline;
    }
    &-line-through {
      text-decoration: line-through;
    }
    &-underline{
      text-decoration: underline;
    }
  }
}


.tag {
  padding: 0;
  margin: 0 0.25rem 0.25rem 0;
  border-radius: 5px;
  font-weight: normal;
  font-size: 0.87rem;
  background-color: #455fa1;

  &__container {
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  &__text {
    color: inherit;
    margin: 0.3rem;
    height: 100%;
    display: inline-block;
  }

  &--success-variant {
    padding: 0 0.75rem;
    width: fit-content;
    border-radius: 5px;
    background-color: #28a7461e;
    color: #07d838;
    font-size: 0.87rem;
  }

  &--awaiting-variant {
    padding: 0 0.75rem;
    width: fit-content;
    border-radius: 5px;
    background-color: #076ee4d8;
    color: #f3f7f4;
    font-size: 0.87rem;
  }

  &--light-green {
    padding: 0 0.75rem;
    width: fit-content;
    border-radius: 5px;
    background-color: #28a7461e;
    color: #098b28;
    font-size: 0.87rem;
  }
    &--light-blue {
    padding: 0 0.75rem;
    width: fit-content;
    border-radius: 5px;
    background-color: #50d9eb1e;
    color: #0a77aa;
    font-size: 0.87rem;
  }
  &--list-badge-variant{
    padding: 0 0.75rem;
    width: fit-content;
    border-radius: 5px;
    background-color: rgba(231, 228, 227, 0.945);
    color: rgb(100, 98, 98);
    font-size: 0.87rem;
  }
   &--list-badge-variant-display{
    padding: 0.3rem;
    font-weight:bold !important;
    border-radius: 5px;
    background-color: rgba(231, 228, 227, 0.945);
    color: rgb(100, 98, 98);
    font-size: 0.87rem;
    margin-bottom:5px !important;
    white-space: normal !important;
    height:auto !important;
  }
  &--light-red {
    padding: 0 0.75rem;
    width: fit-content;
    border-radius: 5px;
    background-color: #dc35451e;
    color: #9c1c29;
    font-size: 0.87rem;
  }
  
     &--primary-variant {
    padding: 0 0.75rem;
    width: fit-content;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    font-size: 0.87rem;
  }
}
.tag-padding {
  padding:10px 5px 10px 5px !important
}

.info-tag {
  padding: 0;
  margin: 0 0.25rem 0.25rem 0;
  border-radius: 5px;
  font-weight: normal;
  font-size: inherit;
  background-color: #17a2b8;
}
.filter-tag {
  padding: 0;
  margin: 0 0.25rem 0.25rem 0;
  border-radius: 5px;
  font-weight: normal;
  font-size: inherit;
  background-color: #6c757d;
}

.button-remove-tag {
  cursor: pointer;
  margin-left: 0;
  height: 100%;
  color: inherit;
  font-size: 125%;
  display: inline-block;
  padding: 0.3rem;
  opacity: 0.75;
  border-radius: 0px 5px 5px 0px;
}


.btn {
  &--light-grey {
    background-color: rgba(51,51,51,0.05);
  }
  &__outline--light-grey {
    border: 1px solid rgba(51,51,51,0.05);
    &:hover {
      background-color: rgba(51,51,51,0.05);
    }
  }
}

.button-remove-tag:hover {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}

.button-remove-tag:focus {
  border: none;
  box-shadow: none;
}
// Animation
.animation__fadeIn--bottomLeft {
  animation-name: fadeIn-bottomLeft;
  animation-duration: 1s;
}
.animation__fadeIn--left {
  animation-name: fadeIn-left;
  animation-duration: 1s;
}

.animation__duration--slow {
  animation-duration: 2s;
}
.animation__duration--slower {
  animation-duration: 3s;
}
.animation__duration--fast {
  animation-duration: 0.75s;
}
.animation__duration--faster {
  animation-duration: 0.5s;
}
@keyframes fadeIn-left {
  from {
    opacity: 0;
    transform: translateX(-1%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes fadeIn-bottomLeft {
  from {
    opacity: 0;
    transform: translate(-10%, 50%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding: 12px;
}

.sv-string-viewer p{
  display:inline-block
}
.form-control, .custom-select {
  border-radius: var(--global--input--border-radius);
}
.modal-content {
  border-radius: var(--global--input--border-radius);
  border: none;
}

.card {
  border-radius: var(--global--input--border-radius);
}

.page-item.active .page-link {
  background-color: var(--global-primary);
  border-color: var(--global-primary);
}

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;
}

.img-preview {
  display: none;
}

.w-max-content {
  width: max-content;
}
.panel-content{
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 10%) !important;
    border: 1px solid #f2f2f2;
    padding: 0px 10px 20px 10px;
    border-radius: 0px 0px 5px 5px;

}
.sd-panel__header{
    padding-bottom:0 !important;
}
.panelTitle{
    font-weight: bold;
    font-size:12pt !important;
    font-family: Arial, Helvetica, sans-serif;
}
.sd-element__header--location-top:after{
  content:none !important;
}
.sd-description{
  padding:5px !important;
}
.sd-radio__decorator{
  height:20px !important;
  width:20px !important;
}

.sd-item__control-label{
  font-size:10pt !important
}

.sd-table__cell--row-text{
    font-size:10pt !important

}

.sd-question--table>.sd-question__content{
  width:100% !important;
  min-width:100% !important;
  display: block !important;
  margin:0 !important;
}
.sd-table__cell--header{
  white-space: normal !important;


}
.sd-row__question{
    white-space: normal !important;

}

.sd-panel__header {
  font-weight:700 !important;
}

.sd-panel__header > h1,h2,h3,h4,h5,h6 {
  font-weight:700 !important;
}

.sd-description{
  color:#000 !important;
}




</style>
