<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    variant="light"
    :class="['nav__wrapper', showOutline ? 'nav__wrapper--outline' : '']"
  >
    <div class="w-25 text-wrap">
      <Brand :key="isLogged" />
    </div>
    <div v-if="isLogged && !hideNavigation && !$store.getters.getHideNav && !$store.getters.hideToolbar " class="w-75 d-flex">
      <ToolbarTabs ref="mainDashboardToolbar" @selected="selectedTab = $event" />
    </div>
    <b-collapse id="nav-collapse" is-nav v-if="!$store.getters.getHideNav && !$store.getters.hideMyAccount">
      <b-navbar-nav class="ml-auto" v-if="isLogged && !isClient && !hideNavigation && !hideMyAccount">
        <NotificationContainer />
        <b-nav-item-dropdown v-if="!hideMyAccount" right toggle-class="text-dark">
          <template v-slot:button-content>
            <b-avatar
              variant="light"
              :src="
                apiURL +
                  'apiUserFileFetch/viewAvatar&dummy=' +
                  new Date().getTime()
              "
            ></b-avatar>
            {{ $t("general.account") }} 
          </template>
          <b-dropdown-item v-if="$store.getters.getAcceptedEula && !hideNavigation && !$store.getters.getHideNav && !$store.getters.hideMyAccount" to="/profile">{{ $t("toolbar.settings") }}</b-dropdown-item>
          <b-dropdown-item v-if="$store.getters.getAcceptedEula && !hideNavigation && !$store.getters.getHideNav && !$store.getters.hideMyAccount"
            href="https://ecenterresearch.zendesk.com/login"
            target="blank"
            >{{ $t("general.support") }} </b-dropdown-item
          >
          <ActiveLocationMenu />
          <b-dropdown-item to="/logout">{{ $t("general.logout") }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import Brand from '@/components/Brand';
import ToolbarTabs from '@/components/ToolbarTabs';
import NotificationContainer from '@/components/notification/NotificationContainer';
import UserInfo from '@/components/user-info';
import { API_URL } from '@/config';
import ActiveLocationMenu from '@/components/ActiveLocationMenu';

export default {
  name: 'toolbar',
  components: { UserInfo, Brand, ToolbarTabs, NotificationContainer, ActiveLocationMenu },
  data() {
    return {
      apiURL: API_URL,
      selectedTab: '',
    };
  },
  beforeCreate(){
   

  },
  mounted(){
   
  },
 
  computed: {
    hideMyAccount(){

        return this.$store.getters.getHideMyAccount;

    },
    hideNavigation(){


      return this.$store.getters.hideNav || this.$store.getters.hideMyAccount;
    },
    showOutline() {
      return (
        this.$route &&
        this.$route.path &&
        this.$route.path.includes(this.selectedTab)
      );
    },
  },
};
</script>
