// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiIntake/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiIntake/getIntakes', payload).then(getData);
export const getRow = (payload = null) => http.post('apiIntake/getIntake', payload).then(getData);


export const create = (row) => http.post('apiIntake/createIntake', row).then(getData);

export const update = (data) => http.post('apiIntake/editIntake', data).then(getData);

export const remove = (row) => http.post('apiIntake/deleteIntake', row).then(getData);

export const getAddRowData = () => http.post('apiIntake/getAddRowData').then(getData);

export const getFormConfig = (rowID) => http.post('apiIntake/getFormConfig',{RowID:rowID}).then(getData);
