// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getEnrollmentTag = () => http.get('apiUserTag/getUserTag&targetTag=Mentor').then(getData);

export const getUserTags= (payload=null) => http.post("apiUserTag/getUserTags",payload).then(getData);

export const setSharedFields = (payload) => http.post("apiUser/setUserSharedFields",payload).then(getData);

export const setUserTag= (payload) => http.post("apiUserTag/setUserTag",payload).then(getData);

export const getUserFiles= (payload=null,silentQuery=false) => http.post("apiUserFile/getUserFiles",payload,{
    silent:silentQuery
}).then(getData);

export const setSharedFiles= (payload) => http.post("apiUserFile/setSharedFiles",payload).then(getData);

export const tagFiles = (payload) => http.post('apiUserFile/tagFile',payload,{

    silent:true
}).then(getData);