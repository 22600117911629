<template>
  <footer class="sticky-footer">
    <div class="container my-auto">
      <div v-if="showCopyright" class="copyright text-center my-auto">
        <!-- <span>Copyright &copy; Naut'sa mawt Tribal Council 2020</span> -->
      </div>
    </div>
  </footer>
</template>
<script>
import { ORG_ID } from '../config';
export default {
  name: 'AppFooter',
  data() {
    return {
      showCopyright: true,
    }
  },
  created() {
    switch(ORG_ID) {
      case 'fcrs':
        this.showCopyright = false;
        break;
      default:
        this.showCopyright = true;
    }
  }
}
</script>