// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiModelApprovalSettings/getTableSettingsCategory', payload).then(getData);

export const get = (payload = null) => http.post('apiModelApprovalSettings/getRowsCategories', payload).then(getData);

export const create = (row) => http.post('apiModelApprovalSettings/createCategory', row).then(getData);

export const update = (row) => http.post('apiModelApprovalSettings/editCategory', row).then(getData);

export const remove = (row) => http.post('apiModelApprovalSettings/deleteCategory', row).then(getData);

export const getRow = (row) => http.post(`apiModelApprovalSettings/getRowCategory&RowID=${row}`).then(getData);