import { API_URL } from '@/config';
import * as customFieldService from '@/services/customField';
import * as clientService from '@/services/client';

export function init(Survey,vm,live =false) {
    var testEditWidget={
        name:'custom-field-question',
        title:'Custom Field Response',
        iconName:'',
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'custom-field-question';
        },
        init(){
          Survey.Serializer.addClass('custom-field-question',[],null,'empty');

       
        },
       
        htmlTemplate:"<div style='padding-left:20px' class='custom-field-question'><div class='responseValue'></div></div>",
        afterRender:function(question,el){

          $(el).css('padding-top',"10px");
     

          if(!live && $(el).closest('#surveyContainer').length>0){
            live=true;
          }

          

         if(live || vm.testModel){



          if(question.value){

            if(vm.$route.params.clientID){
              customFieldService.getFieldValue(question.CustomFieldID,vm.$route.params.clientID)
              .then(response =>{
                 $(el).find('.responseValue').append("<div>"+response.fieldValue+"</div>");
  
                 question.value=response.fieldValue;

              });
            }else if(vm.$route.params.modelID){
              customFieldService.getFieldValue(question.CustomFieldID,vm.$route.params.modelID)
              .then(response =>{
                 $(el).find('.responseValue').append("<div>"+response.fieldValue+"</div>");
  
                 question.value=response.fieldValue;

              });
            }else if(vm.resultSetID){
              customFieldService.getFieldValueByResultSet(question.CustomFieldID,vm.resultSetID,vm.assessmentType)
              .then(response =>{
                 $(el).find('.responseValue').append("<div>"+response.fieldValue+"</div>");
  
                 question.value=response.fieldValue;

              });

            }
         


            
          }else{

            if(vm.testModel){
              $(el).find('.responseValue').append("<div> FIELD RESPONSE WILL APPEAR HERE</div>");

                
           

            }else if(vm.$route.params.clientID){
              customFieldService.getFieldValue(question.CustomFieldID,vm.$route.params.clientID)
              .then(response =>{
                 $(el).find('.responseValue').append("<div>"+response.fieldValue+"</div>");
  
                 question.value=response.fieldValue;

              });
            }else if(vm.$route.params.modelID){
              customFieldService.getFieldValue(question.CustomFieldID,vm.$route.params.modelID)
              .then(response =>{
                 $(el).find('.responseValue').append("<div>"+response.fieldValue+"</div>");
  
                 question.value=response.fieldValue;

              });
            }else if(vm.model=='Client' && vm.modelID){
              customFieldService.getFieldValue(question.CustomFieldID,vm.modelID)
              .then(response =>{
                 $(el).find('.responseValue').append("<div>"+response.fieldValue+"</div>");
  
                 question.value=response.fieldValue;

              });
            }else if(vm.resultSetID){
              customFieldService.getFieldValueByResultSet(question.CustomFieldID,vm.resultSetID,vm.assessmentType)
              .then(response =>{
                 $(el).find('.responseValue').append("<div>"+response.fieldValue+"</div>");
  
                 question.value=response.fieldValue;

              });

            }
          }
        }else{
          $(el).find('.responseValue').append("<div><span style='font-weight:bold;color:#000000'>TEST MODE</span></div>");
    
        }
          
          


        

          
           
        },
        pdfQuestionType: "empty",
        pdfRender: function (survey, options) {
          if (options.question.getType() === "custom-field-question") {
            const loc = new Survey.LocalizableString(survey, true);




          
              loc.text = options.question.value || "";



            options.question["locHtml"] = loc;

            if (
              options.question.renderAs === "standard" ||
              options.question.renderAs === "image"
            ) {
              options.question["renderAs"] = options.question.renderAs;
            } else options.question["renderAs"] = "auto";
            const flatHtml = options.repository.create(
              survey,
              options.question,
              options.controller,
              "html"
            );
            return new Promise(function (resolve) {
              flatHtml.generateFlats(options.point).then(function (htmlBricks) {
                options.bricks = htmlBricks;
                resolve();
              });
            });
          
          }

        }


      };
    //Register our widget in singleton custom widget collection

    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('custom-field-question')){


        Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget, "customtype");
    
    }
}