<template>
  <div>
    
       
             <b-btn
              :class="'btn-simple global-primary__bg mr-2'"
              v-b-toggle="`instructions_collapse`"
              class="d-flex align-items-center btn-simple font-weight-bold"
              @click="instructionsClicked"
            >
              <span style="padding-right:10px">
                <b-icon  icon="info-circle"></b-icon>
              </span>
              <span v-html="cleanButtonText"></span>
              <b-icon-chevron-right v-if="currentState %2==0" font-scale="0.8" class="ml-2" />
              <b-icon-chevron-down v-else font-scale="0.8" class="ml-2" />
            </b-btn>
            <b-collapse :id="`instructions_collapse`" v-html="instructions">
            </b-collapse>

  </div>
</template>
<script>

export default {
  name: 'FormInstructionsCard',
  data() {
    return {
      currentState:0
    };
  },
  props:{
    instructions:{type:String,default:null},
    buttonText:{type:String,default:null}
  },
  computed:{
      cleanButtonText(){
          let cleaned=this.buttonText.replace("<p>",'').replace("</p>","");

          return cleaned;
      }
  },
  created() {

  },
  mounted(){
  },
  methods: {
    instructionsClicked(){
      this.currentState+=1;

     
    }
  },
};
</script>
