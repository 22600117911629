<template>

    <b-container>
      <b-row :key="refreshFamily">
        <b-col md="10">
            <div v-for="(familyData,index) in linkedFamilyDisplay" :key="'familyMember_'+index" :style="{paddingBottom:'20px',paddingTop:index>0?'20px !important':'0px !important'}" class="border-bottom pt-1 pb-2">
                <div style="width:70%;display:inline-block">
                  <span style="font-weight:bold">{{ familyData.Number }}</span>
                </div>
                 <div style="display:inline-block">
                  <b-button size="sm" @click="unlinkFamily(index)" variant="danger">{{ $t("general.remove") }}</b-button>
                </div>
               <div >
                  {{ familyData.FirstName }} {{ familyData.LastName }}
                </div>
               
            </div>
        </b-col>
        <b-col md="2">
          <b-button size="sm"  class="mr-1 btn-simple global-primary__bg"  @click="openLinkClientDialog">{{ addButtonText }}</b-button>
        </b-col>
      </b-row>
        <CustomDialog
          v-if="renderCustomDialog"
          :serverParams="filterValues"
          :formSettings="formSettings"
          @onCloseCustomDialog="closeLinkClientDialog"
          @linkFamily="linkFamilyMember"
          ref="linkedCustomDialogRef"
          :key="refreshParams"
        />
    </b-container>
</template>
<script>

import { BCollapse } from 'bootstrap-vue';
import Label from './Label.vue';
import { ErrorMessages } from '@/utils/errorMessages.js';
import { isEmpty } from '@/utils/isEmpty.js';
import FormCard from '@/components/FormCard';
import CustomDialog from '@/components/CustomDialog.vue';




export default {
  name: 'LinkedEventsCard',
  components: {
    FormCard,
    ChildInputFactory:()=>import('@/components/InputFactory.vue'),
    ChildLabelTooltip:()=>import('@/components/LabelTooltip.vue'),
    CustomDialog

  },
  data() {
    return {
      addButtonText:"Add",
      service:null,
      linkedFamily:[],
      linkedFamilyDisplay:[],
      renderCustomDialog:false,
      refreshForms:null,
      loaded:false,
      customDialogSettings:{},
      service:null,
      formSettings:{},
      refreshFamily:null,
      refreshParams:null,
      filterValues:{}

    };
  },
  props: {
    fieldID:{type: String, default:null},
    value: {type: Array,default:function(){return new Array}},
    rowID:{type: String, default:null},
    serviceName:{type: String, default:null},
    passedFilters:{type:Object, default:function(){return {}}},

    
  },

  created() {
       


  },
  beforeMount(){
  
  },
  mounted(){

      
       this.service = require('@/services/' + this.serviceName);

      this.service.getLinkedFamilyDialogSettings({CustomFieldID:this.fieldID,RowID:this.rowID})
      .then(response =>{
        this.formSettings=response;
        this.addButtonText=this.formSettings.addLinkButtonText;
      }); 

      this.filterValues=this.passedFilters;
      if(this.rowID){
        this.filterValues.RowID=this.rowID;
        this.getLinkedFamily(this.rowID);
      }

          

  },
  methods: {
      unlinkFamily(index){
        this.linkedFamily.splice(index,1);
        this.$emit('familyAdded',this.linkedFamily);

        this.getLinkedFamily(null,null,true);



      },
      linkFamilyMember(data){
          this.linkedFamily.push(data.ClientID);
          this.$emit('familyAdded',this.linkedFamily);


          this.getLinkedFamily(null,true);


      },
      openLinkClientDialog(){
          this.renderCustomDialog=true;
      },
      closeLinkClientDialog(){
        this.renderCustomDialog=false;
      },
      getLinkedFamily(rowID,inAdd,removing){
        if(rowID){
          this.service.getLinkedFamily({RowID:rowID})
          .then(response =>{
            this.linkedFamily=response.linkedClientIDs;
            this.linkedFamilyDisplay=response.linkedClientData;

            this.filterValues.linkedFamily=this.linkedFamily;

            this.refreshFamily=new Date().getTime();
            this.refreshParams=new Date().getTime().toString() +'b';


          });

        }else{
           this.service.getLinkedFamily({clientIDs:this.linkedFamily})
          .then(response =>{
            this.linkedFamily=response.linkedClientIDs;
            this.linkedFamilyDisplay=response.linkedClientData;

             this.refreshFamily=new Date().getTime();

             if(!this.filterValues.linkedFamily){
               this.filterValues.linkedFamily=new Array;
             }

             this.filterValues.linkedFamily=this.linkedFamily;
             this.renderCustomDialog=false;

            
             this.refreshParams=new Date().getTime().toString() +'b';
              if((!inAdd || typeof inAdd==='undefined') && (!removing || typeof removing==='undefined')){
                   this.renderCustomDialog=true;
              }



          });
        }

      }


        

  
  },
};
</script>
