<template>
  <b-row v-if="answers && fields">
    <b-col
      v-for="(field, j) in filterFields(fields)"
      :key="`col_${field.Name}${j}`"
      sm="12"
      md="6"
      :xl="field.BCol || '4'"
    >
      <div :class="field.CssClasses || ''" v-if="field && field.Type==='HtmlText'" v-html="field.Text"></div>
      <FormControl
        v-else
        :answers="answers"
        :fieldKeys="fieldKeys"
        :field="field"
        :formType="formType"
        @tags-reviewed-event="$emit('tags-reviewed-event',{event:$event,field})"
        @onInputFormControl="
          $emit('onInputFormControl', { event: $event, field })
        "
      />
    </b-col>
  </b-row>
</template>
<script>
import FormCard from '@/components/FormCard.vue';
import FormControl from '@/components/form/FormControl.vue';

export default {
  name: 'FormControlRow',
  components: { FormCard, FormControl },
  props: {
    answers: { type: Object, default: null },
    fields: { type: Array, default: null },
    fieldKeys: { type: Object },
    formType: { type: String, default: '' },
  },
  methods: {
    filterFields(fields) {
      if (!Array.isArray(fields) || fields.length == 0) return [];
      const list = [];
      for (const field of fields) {
        const hidden = field.Hidden && field.Hidden != 4 ? true : false;
        if (!hidden) list.push(field);
      }
      return list;
    }
  }
};
</script>
