
<style>
	.removePadding{
	height:auto !important;
	}
	.removePadding > td {
		padding:0px !important;
		height:auto !important;
		min-height:60px !important;
	}
	.removePadding > td > div{
		padding:5px !important;
		min-height:60px !important;
	}

</style>
<template>		
		<b-form @submit="submit">
		<div id="formErrorDiv" v-show="errorMessage" class="col text-center text-danger" v-html="errorMessage"></div>
		<FormCard headerClass="global-primary__bg global-primary__bg--no-hover">
			<template v-slot:header>{{ $t("mentorship.AddProvider.provider") }}</template>
			<template v-slot:body>
				<b-row>
					<b-col md="6">
						<b-form-group :label="$t('mentorship.AddProvider.providerName')">
							<b-form-input @input="onTextChange" v-model="form.Name" :class="(validator.$error && ((!rowData.ID && validator.addRowData.Name.$invalid) || (rowData.ID && validator.editRowData.Name.$invalid))) ? 'form__input--invalid' : ''"></b-form-input>
							<div v-if="validator.$error && ((!rowData.ID && validator.addRowData.Name.$invalid) || (rowData.ID && validator.editRowData.Name.$invalid))" class="text-danger">
								{{ $t("mentorship.AddProvider.name") }}
							</div>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group :label="$t('mentorship.AddProvider.group')">
							<InputFactory id="ProviderGroup" type="MultiSelect" required="1" :showCheckHeader="false" :showSelectAll="false" tagContainerClass="border-0 p-0" :options="providerGroupOptions" v-model="form.ProviderGroup"/>
						</b-form-group>
					</b-col>
				</b-row>
				<!-- <b-row>
					<b-col md="10">
						<b-form-group label="Address *">
							<b-form-input @input="onTextChange" v-model="rowData.Address.StreetAddress" :class="(validator.$error && ((!rowData.ID && validator.addRowData.Address.StreetAddress.$invalid) || (rowData.ID && validator.editRowData.Address.StreetAddress.$invalid))) ? 'form__input--invalid' : ''" ></b-form-input>
							<div v-if="validator.$error && ((!rowData.ID && validator.addRowData.Address.StreetAddress.$invalid) || (rowData.ID && validator.editRowData.Address.StreetAddress.$invalid))" class="text-danger">
								Address is required
							</div>	
						</b-form-group>
					</b-col>
				</b-row> -->
				<b-row>
					<b-col sm="12" md="6" xl="4">
						<b-form-group :label="$t('mentorship.AddProvider.city')">
							<b-form-input @input="onTextChange"  :class="(validator.$error &&  ((!rowData.ID && validator.addRowData.Address.City.$invalid) || (rowData.ID && validator.editRowData.Address.City.$invalid))) ? 'form__input--invalid' : ''" v-model="rowData.Address.City" required></b-form-input>
							<div v-if="validator.$error &&  ((!rowData.ID && validator.addRowData.Address.City.$invalid) || (rowData.ID && validator.editRowData.Address.City.$invalid))" class="text-danger">
								{{ $t("mentorship.AddProvider.cityRequired") }}
							</div>	
						</b-form-group>
					</b-col>
					<!-- <b-col md="4">
						<b-form-group label="Postal/Zip *">
							<b-form-input @input="onTextChange" v-model="rowData.Address.Postal"  :class="(validator.$error &&  ((!rowData.ID && validator.addRowData.Address.Postal.$invalid) || (rowData.ID && validator.editRowData.Address.Postal.$invalid))) ? 'form__input--invalid' : ''" required></b-form-input>
							<div v-if="validator.$error &&  ((!rowData.ID && validator.addRowData.Address.Postal.$invalid) || (rowData.ID && validator.editRowData.Address.Postal.$invalid))" class="text-danger">
								Postal/Zip is required
							</div>	
						</b-form-group>
					</b-col>				 -->
					<b-col sm="12" md="6" xl="4">
						<b-form-group :label="$t('mentorship.AddProvider.country')">
							<b-form-select  :options="countryList" v-model="rowData.Address.CountryID"></b-form-select>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="6" xl="4">
						<b-form-group :label="$t('mentorship.AddProvider.province')">
							<b-form-select :options="availableProvices" v-model="rowData.Address.ProvinceID"></b-form-select>
						</b-form-group>
					</b-col>
				</b-row>
				
				<b-row>
					<b-col sm="12" md="6" xl="4">
						<b-form-group :label="$t('mentorship.AddProvider.phone')">
							<b-form-input @input="onTextChange" v-model="rowData.Contact.Phone"  :class="(validator.$error && ((!rowData.ID && !validator.addRowData.Contact.Phone.phoneMask) || (rowData.ID && !validator.editRowData.Contact.Phone.phoneMask))) ? 'form__input--invalid' : ''" required></b-form-input>
							<div v-if="validator.$error && ((!rowData.ID && !validator.addRowData.Contact.Phone.phoneMask) || (rowData.ID && !validator.editRowData.Contact.Phone.phoneMask))" class="text-danger">
								{{ $t("mentorship.AddProvider.phoneForm") }}
							</div>	
						</b-form-group>
					</b-col>
					<b-col sm="12" md="6" xl="4">
						<b-form-group :label="$t('mentorship.AddProvider.fax')">
							<b-form-input @input="onTextChange" v-model="rowData.Contact.Fax" required></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="6" xl="4">
						<b-form-group :label="$t('mentorship.AddProvider.email')">
							<b-form-input @input="onTextChange" v-model="rowData.Contact.Email"  :class="(validator.$error && ((!rowData.ID && validator.addRowData.Contact.Email.$invalid) || (rowData.ID && validator.editRowData.Contact.Email.$invalid))) ? 'form__input--invalid' : ''" required></b-form-input>
							<div v-if="validator.$error && ((!rowData.ID && validator.addRowData.Contact.Email.$invalid) || (rowData.ID && validator.editRowData.Contact.Email.$invalid))" class="text-danger">
								{{ $t("mentorship.AddProvider.emailFormat") }}
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="6" xl="4">
						<b-form-group :label="$t('mentorship.AddProvider.website')">
							<b-form-input @input="onTextChange" v-model="rowData.Contact.Website" required></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
			</template>
		</FormCard>

		<FormCard headerClass="global-primary__bg global-primary__bg--no-hover">
			<template v-slot:header>{{ $t("mentorship.AddProvider.services") }}</template>
			<template v-slot:body>
						<b-row>
							<b-col md="10" style="padding-left:20px">	
							
											<div v-if="validator.$error && ((!rowData.ID && validator.addRowData.services.$invalid) || (rowData.ID && validator.editRowData.services.$invalid))" class="text-danger">
												{{ $t("mentorship.AddProvider.providersNeed") }}
											</div>
											<div v-for="(service,index) in rowData.services" :key="service.TagID" style="padding-bottom:20px;padding-left:20px" cols="10">
												
														<b-badge tag="div" style='font-size:10pt' variant="info"><div style='display:inline-block;padding:10px'>{{ getOptionName(services,service.TagID) }}</div><b-button style="max-height:20px !important;font-size:10px;margin-top:-5px" size="sm" variant="info" @click="removeService(index)"><b-icon icon="x"></b-icon></b-button></b-badge>
													<b-row>
														<b-col md="5" style="padding-bottom:0px">
															{{ $t("mentorship.AddProvider.review") }}
														</b-col>
														<b-col md="7" style="padding-bottom:0px">
															{{ $t("mentorship.AddProvider.rating") }}

														</b-col>
													</b-row>
													<b-row>
														<b-col md="5" style="padding-top:3px">
															<b-form-rating :readonly="service.CreatedByUser==false && service.CreatedByUser!=-1" style="width:15%" v-model="service.Rating" block no-border show-value show-value-max variant="warning"></b-form-rating>
														</b-col>
														<b-col md="7" style="padding-top:3px">
															<b-form-rating :readonly="service.CreatedByUser==false && service.CreatedByUser!=-1" style="width:15%" v-model="service.RespectFNRating" block no-border show-value show-value-max variant="warning"></b-form-rating>
														</b-col>
						
													</b-row>
														<b-form-textarea
															:placeholder="$t('mentorship.AddProvider.comments')"
															v-model="service.RatingDesc"
															rows="3"
															max-rows="20"
															:readonly="service.CreatedByUser==false && service.CreatedByUser!=-1" 
														>

														</b-form-textarea>
											</div>
											<b-col md="3" style="padding-left:0px">
											<b-dropdown size="md" variant="outline-secondary" :class="(validator.$error && ((!rowData.ID && validator.addRowData.services.$invalid) || (rowData.ID && validator.editRowData.services.$invalid)))?'form__input--invalid':''" block>
													<template v-slot:button-content>
														{{ $t("mentorship.AddProvider.select") }}
													</template>
												
													<b-dropdown-item-button
													v-for="option in availableServices"

													:key="option.value"
													@click="addService(option.value)"
													>
													{{ option.text }}
													</b-dropdown-item-button>
													<b-dropdown-text v-if="availableServices.length === 0">
														{{ $t("mentorship.AddProvider.available") }}
													</b-dropdown-text>
											</b-dropdown>
										</b-col>
									
								
							</b-col>
						</b-row>
			</template>
		</FormCard>

		<FormCard headerClass="global-primary__bg global-primary__bg--no-hover">
			<template v-slot:header>Provider Details</template>
			<template v-slot:body>
						<b-row>
						  <b-col md="12" class="text-right" style="padding-top:0;padding-bottom:0">
							<div v-if="!addContactFlag && selectedRowIndex===null">
								<div style="width:auto;padding-right:5px ">
									<b-button  size="sm" variant="success" @click="addContact">
									Add Contact 
									</b-button>
								</div>
							</div>
							<div v-else-if="!addContactFlag && selectedRowIndex!==null">
								<div style="width:auto;padding-right:5px ">
									<b-button size="sm"  variant="success" @click="editContact" >
										{{ $t("general.edit") }} 
									</b-button>
									<b-button size="sm"  variant="danger" @click="deleteConact" >
										{{ $t("general.delete") }} 
									</b-button>
									<b-button size="sm"  variant="secondary" @click="cancelSelect" >
										{{ $t("general.cancel") }} 
									</b-button>
								</div>
							</div>
							<div v-else>
								<div style="width:auto;padding-right:5px ">
									<b-button size="sm"  variant="success" @click="confirmContact" >
										{{ $t("general.done") }}
									</b-button>
									<b-button size="sm"  variant="danger" @click="cancelContact" >
										{{ $t("general.cancel") }} 
									</b-button>
								</div>
							</div>
						   </b-col>
						</b-row>
						<b-row>
						<b-col md="12">
						<vue-good-table
							:key="addContactFlag"
							 @on-row-dblclick="rowSelected"
							@on-row-click="rowSelected"
                            :columns="contactFields"
                            :rows="rowData.contacts"
                            styleClass="vgt-table condensed"
							row-style-class="removePadding"
                            >
							<template slot="emptystate">
								<div style="text-align:center;width:100%">
   									 No Contacts Created 
								</div>
							  </template>
                              <template slot="table-row" slot-scope="props">
								 <div v-if="props.row.newContact && props.column.field!='ContactServices'">
									<b-form-input size="sm" :id="props.column.field" v-model="newContact[props.column.field]"></b-form-input>
									<div v-if="newContact.validationErrors && newContact.validationErrors[props.column.field]" class="text-danger">
										{{ newContact.validationErrors[props.column.field] }}
									</div>
								</div>
								<div v-else-if="props.row.newContact && props.column.field=='ContactServices'">
									  <b-form-tags v-model="newContact.ContactServices" size="lg" add-on-change no-outer-focus class="mb-2" style="border:none;padding:0">
												<template v-slot="{ tags, inputAttrs, inputHandlers, disabled, addTag,removeTag }">
												<b-dropdown size="sm" variant="outline-secondary" block>
													<template v-slot:button-content>
														Select Service Area 
													</template>
												
													<b-dropdown-item-button
													v-for="option in availableContactServices"

													:key="option.value"
													@click="onOptionClick({ option, addTag })"
													>
													{{ getOptionName(services,option.TagID) }}
													</b-dropdown-item-button>
													<b-dropdown-text v-if="availableContactServices.length === 0">
													There are no services to select
													</b-dropdown-text>
												</b-dropdown>
											
												<div
												id="contact-services-list"
												aria-live="polite"
												aria-atomic="false"
												aria-relevant="additions removals"
												>
												
												<div
													v-for="tag in tags"
													:key="tag"
													:id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
													style="margin-right:5px;display:block"
												>
													
														
														<b-form-tag
															@remove="removeTag(tag)"
															:title="tag"
															:disabled="disabled"
															variant="info"
															>{{ getOptionName(services,tag) }}</b-form-tag>
													
													
												</div>
												</div>
												
												</template>
										</b-form-tags>

								</div>
								<div v-else-if="props.column.field=='ContactServices'" :style="{background:colorRow(props.row.originalIndex)}">
                                   <div v-for="serviceArea in props.row.ContactServices" :key="serviceArea">
									   <b-badge variant="info">{{ getOptionName(services, serviceArea) }}</b-badge>

								   </div>
                                </div>
                                <div v-else :style="{background:colorRow(props.row.originalIndex)}">
                                    {{props.formattedRow[props.column.field]}}
                                </div>
                            </template>
                            </vue-good-table>
								<div  v-show="contactsUpdated" class="col text-center text-danger">
									Contacts have been updated save record to commit updates
								</div>
							</b-col>
						</b-row>
							
			</template>
		</FormCard>
	</b-form>
</template>


<script>

import lodash from "lodash";
import Rating from "./Rating.vue";
import { validationMixin } from 'vuelidate';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import * as providerService from "@/services/Mentorship Module/providers";
import { VueGoodTable } from 'vue-good-table';
import InputFactory from '@/components/InputFactory.vue';
import FormCard from '@/components/FormCard.vue';

export default {
	props:{
		ProviderID:String,
		errorMessage:String,
		validator:Object,
		rowData:{
			type:Object,
			default:{CountryID:null,ProvinceID:null,Contact:{},services:[],Address:{}}
		}
	},
  data() {
      return {
		  form:this.rowData,
		  contactsUpdated:false,
		  newContact:{},

		provinceList:[],
		countryList:[],
		formError:null,
		showButtons:false,
		origProvinceID:null,
		origCountryID:null,
		services:[],
		respectFN:[],
		addContactFlag:false,
		selectedRowIndex:null,
		 contactFields:[
        	
          {field:"ID",label:"ID",hidden:true,sortable:false},
          {field:"ContactName",label:"Contact Name",sortable:false},
		  {field:"Phone",label:"Phone",sortable:false,width:"18%"},
		  {field:"Ext",label:"Ext",sortable:false,width:"10%"},
		  {field:"Email",label:"Email",sortable:false},
		  {field:"ContactServices",label:"Service Area",sortable:false}


        	
        	
        	
        ],
				providerGroupOptions: [],



      }
  },
 
  created() {
	
	  			
				if(this.rowData.ID){
				
									
									this.origProvinceID=this.rowData.Address.ProvinceID;
									this.origCountryID=this.rowData.Address.CountryID;
									
									

						
           		    	
           		    		
           		    		
           		    
               		

					}
					



					providerService.getCountries()
            		  .then(response => {
           		    
						    
							   this.countryList=response;
							   
							   if((response.length-1)==1){
									   this.rowData.Address.CountryID=this.countryList[1].value;
									   this.$forceUpdate();
							   }

							   if(!this.origCountryID){
								   //this.form.CountryID=null;
							   }
           		    		
           		    
               				
            		 })
            		 .catch(error => {
               				 console.log(error);
					 });

					 providerService.getProvinces()
            		  .then(response => {
           		    
           		    
           		    		this.provinceList=response;
							   if(!this.origProvinceID){
								   //this.form.ProvinceID=null;
							   }
							   
           		    
               				
            		 })
            		 .catch(error => {
               				 console.log(error);
					 });
					 
					 providerService.getProviderTags({
						 targetCategory:"Services Provided",
						 tagDefinitionOnly:true
					 })
					 .then(response =>{
						 this.services=response.tags

					 });

					 providerService.getProviderGroups().then(response => { this.providerGroupOptions = response; }).catch(error => console.log(error));
  },
 
  watch:{
	  rowData:{
		  deep:true,
		  handler:function(newVal){
			  if(this.formError && newVal.OrganizationName && newVal.Address){
				  this.formError=null;
			  }

			


			  this.$forceUpdate();

		  }
	  },
	  

  },
  computed:{
	  
	  
	  availableProvices(){
		  const options = this.provinceList;
			
		 if(this.rowData && this.rowData.Address.CountryID){

			 var vm=this;
			  var  returnOptions = options.filter(function(opt){ 
				  	if(opt.value){
						return opt.value.CountryID==vm.rowData.Address.CountryID;
					}else{
						return true;
					}
				});
				
				if(this.rowData.Address.CountryID==this.origCountryID){
					this.rowData.Address.ProvinceID=this.origProvinceID;
				}

			return returnOptions;
		 }
		
		 return options;

	  },
	  criteria() {
        // Compute the search criteria
        
        if(this.tagSearch){
        	return this.tagSearch.trim().toLowerCase();
        }else{
        	return null;
        }
	  },
	  availableContactServices(){
		  if(this.rowData.services){
			  var vm=this;
        	 const options = this.rowData.services.filter(function(opt){ 
			
			
			
			  if(vm.newContact.ContactServices && vm.newContact.ContactServices.some(element =>{ return element==opt.TagID })){
				  return false;
			  }else{
				  return true;
			  }
			  
		});
		
		return options;
		  }else{
			  return [];
		  }
	  },
      availableServices() {
        const criteria = this.criteria
        // Filter out already selected options
       
       	var vm=this;
        const options = this.services.filter(function(opt){ 
			
			
			
			  if(vm.rowData.services.some(element =>{ return element.TagID==opt.value })){
				  return false;
			  }else{
				  return true;
			  }
			  
        });
        
       
        // Show all options available
        
        
        return options
	  },
	   
      
  },
  methods:{
	    onOptionClick({ option, addTag }) {
			
			addTag(option.TagID);
			
			console.log(this.newContact);
			
		},
	  	cancelSelect(){
			  this.selectedRowIndex=null;
		  },
	    validateContact(){
			this.newContact.validationErrors=new Object;
			var invalid=false;
			if(typeof this.newContact.ContactName==='undefined' || this.newContact.ContactName===null || this.newContact.ContactName.trim().length==0){
				this.newContact.validationErrors.ContactName="Contact name is required";
				invalid=true;
			}
			
			if(typeof this.newContact.Phone !=='undefined' && this.newContact.Phone && this.newContact.Phone.trim().length>0){

				// var validPhone=/^[1-9]-[0-9]{3}-[0-9]{3}-[0-9]{4}|(?<![0-9])(?<!-)[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(this.newContact.Phone);

			// 	if(!validPhone){
			// 		this.newContact.validationErrors.Phone="Phone Must be format of x-xxx-xxx-xxxx or xxx-xxx-xxxx";
			// 		invalid=true;
			// 	}
			}


			
			return Object.keys(this.newContact.validationErrors).length>0?false:true;

		},
	  	deleteConact(){
					this.rowData.contacts.splice(this.selectedRowIndex,1);
					this.selectedRowIndex=null;
		  },
		  editContact(){

			   this.addContactFlag=true;
			  this.rowData.contacts[this.selectedRowIndex].newContact=true;
			   Object.assign(this.newContact,this.rowData.contacts[this.selectedRowIndex]);

					setTimeout(function(){
						  $('#ContactName').focus();
					},100);
		  },
	  	colorRow(index){
			  return index==this.selectedRowIndex?"#ffff00":"#ffffff";
		  },
	  	rowSelected(params){
			  if(!params.row.newContact){
			
				  if(this.selectedRowIndex!==null && this.selectedRowIndex==params.pageIndex){
					  this.selectedRowIndex=null;
				  }else{
					  this.selectedRowIndex=params.pageIndex;
				  }
			  }

		  },
	  	cancelContact(){

			  if(this.selectedRowIndex !==null){
 					this.addContactFlag=false;
					  this.newContact={};
					  this.rowData.contacts[this.selectedRowIndex].newContact=false;
					  this.selectedRowIndex=null;

			  }else{
				  this.addContactFlag=false;
 				var index=this.rowData.contacts.length-1;
				  this.rowData.contacts.splice(index,1);
				  
			
			  }
			 
			
		},
		confirmContact(){
			this.newContact.validationErrors={};
			var isValid=this.validateContact();
	
		  if(isValid){
			if(this.selectedRowIndex===null){
			  var index=this.rowData.contacts.length-1;
			  this.rowData.contacts[index].newContact=false;
			   Object.assign(this.rowData.contacts[index],this.newContact);
			}else{
				this.newContact.newContact=false;
				this.rowData.contacts[this.selectedRowIndex].newContact=false;
			   Object.assign(this.rowData.contacts[this.selectedRowIndex],this.newContact);
			   this.selectedRowIndex=null;
			}
			   this.newContact={};

				this.contactsUpdated=true;
	
				this.addContactFlag=false;

				this.$forceUpdate();
		  }else{
			  
			 
			  this.$forceUpdate();

		  }
		},
	  	addContact(){
			  	  
				  this.rowData.contacts.push({ContactName:null,Phone:null,Ext:null,Email:null,ContactServices:[],newContact:true});
				  this.addContactFlag=true;
					setTimeout(function(){
						  $('#ContactName').focus();
					},100);

		},
		validateState(name) {
			const { $dirty, $error } = this.$v.form[name];


			return $dirty ? !$error : null;
		},
  		submit(){

			this.$v.form.$touch();
			if (this.$v.form.$anyError) {
				this.formError="Please correct the errors below";
						var container = $("#addContainer").closest(".modal-body");
						container.scrollTop(0);	



				return;
			}

			if(this.ProviderID){
				// providerService.updateProvider({formData:this.form,ProviderID:this.ProviderID})
				// .then(response=>{
				// 		if(response.success){
				// 			this.$emit('editClose');
				// 		}else{
				// 			this.formError=response.message;
				// 		}
				// }).catch(error=>{
				// 		this.formError="Error saving record";
				// 		console.log(error);

				// });
			}else{
				// providerService.addProvider(this.form)
				// .then(response=>{
				// 		if(response.success){
				// 			this.$emit('addClose');
				// 		}else{
				// 			this.formError=response.message;
				// 		}
				// }).catch(error=>{
				// 		this.formError="Error saving record";
				// 		console.log(error);

				// });

			}
			
			
  			
		},
		onTextChange(){
 			//this.rowData=this.form;
		},
		cancel(){

			if(this.ProviderID){
				

				this.$emit('editClose');
			}else{
				this.$emit('addClose');
			}
			

		},
		onCountryChange(evt){
			  this.$forceUpdate();
		},
		getOptionName (fieldOptions, answerValue) {

				

				if(answerValue.indexOf('TagID')>-1){
					answerValue=JSON.parse(answerValue);

				    var field = fieldOptions.find(element => element.value == answerValue.TagID);
					

					if (field) {
					return field.text;
					}
				}else{
							// eslint-disable-next-line eqeqeq
					var field = fieldOptions.find(element => element.value == answerValue);
					if (field) {
					return field.text;
					}
				}

			
			  
		},
		addService(value){
				this.rowData.services.push({TagID:value,Rating:0,RatingDesc:null,CreatedByUser:-1});
				this.$forceUpdate();

		},
		removeService(index){
			this.rowData.services.splice(index,1);


		}
  },
  components: {
  	Rating,VueGoodTable,InputFactory,FormCard
  }
}
</script>
