<template>
  <b-form-group v-if="field && answers" :class="['animation__fadeIn--left','animation__duration--fast']">
    <LabelTooltip
      :iconID="`${field.Name}_tt`"
      :label="field.Text"
      :labelID="`${field.Name}_label`"
      :required="field.Required"
      :showTooltip="
        typeof field.HelpText === 'string' && field.HelpText.length > 0
      "
      :tooltipText="field.HelpText"
    />
    <InputFactory
      :key="fieldKeys && fieldKeys[field.Name] ? fieldKeys[field.Name] : undefined"
      :fieldID="field.FieldID"
      :children="field.Children"
      :id="field.Name"
      :type="field.Type"
      :showCheckHeader="false"
      :showSelectAll="false"
      tagContainerClass="border-0 p-0"
      :required="field.Required ? '1' : '0'"
      :options="field.Options"
      :disabled="field.DisableEdit"
      :isEditable="!field.DisableEdit"
      v-model="answers[field.Name]"
      :rowData="answers"
      @input="$emit('onInputFormControl', $event)"
      @tags-reviewed="$emit('tags-reviewed-event',$event)"
      :lvParams="field.ListParams"
      :readOnly="field.ReadOnly"
      :cssClasses="field.CssClasses"
      :allowClearButton="field.AddClearDateButton"
      :disabledDates="field.disabledDates || {}"
      :placeholder="field.Placeholder || ''"
    />
  </b-form-group>
</template>
<script>
import InputFactory from '@/components/InputFactory.vue';
import LabelTooltip from '@/components/LabelTooltip.vue';

export default {
  name: 'FormControl',
  components: {
    InputFactory,
    LabelTooltip,
  },
  props: {
    field: { type: Object, default: null },
    answers: { type: Object, default: null },
    fieldKeys: { type: Object },
    formType: { type: String, default: '' },
  },
};
</script>
