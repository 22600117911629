import auth from '@/router/middleware/auth';
import ModalView from '@/components/ModalView';

const Collections = r => require.ensure([], () => r(require('./index')), 'collections-bundle');
const Form = r => require.ensure([], () => r(require('./form')), 'collection-form-bundle');
const ClientForm = r => require.ensure([], () => r(require('./clientForm')), 'client-collection-form-bundle');
const UserForm = r => require.ensure([], () => r(require('./userForm')), 'user-collection-form-bundle');

const FormPrint = r => require.ensure([], () => r(require('./form-print')), 'collection-form-print-bundle');
const SurveyStart = r => require.ensure([], () => r(require('./surveyStartForm')), 'collection-surveystart-bundle');
const UserSurveyStart = r => require.ensure([], () => r(require('./userSurveyStartForm')), 'user-collection-surveystart-bundle');

const SurveyAnalytic = r => require.ensure([], () => r(require('../../components/SurveyAnalytics')), 'collection-surveyAnalytics-bundle');
const ResultCollabForm = r => require.ensure([], () => r(require('./resultsCollaborationForm')), 'result-collection-form-bundle');

export default [
  {
    path: '/collections',
    name: 'collections',
    component: Collections,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/client-collection/:id/:assessmentType?/:assessmentID?/:clientID?/:treatmentID?',
    name: 'client-collection',
    component: ClientForm,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/client-collection-with-result/:id/:assessmentType/:resultSetID?/:fromLogin?',
    name: 'client-collection-with-result',
    component: ClientForm,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/user-collection/:id/:assessmentType?/:assessmentID?/:userID?/:treatmentID?',
    name: 'user-collection',
    component: UserForm,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/view-user-collection/:id/:assessmentType?/:assessmentID?/:userID?/:treatmentID?',
    name: 'view-user-collection',
    component: UserForm,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/client-collection/:id/:assessmentType/:assessmentID/:clientID/:treatmentID/:pendingApprovalID',
    name: 'client-collection-pending-approval',
    component: ClientForm,
    meta: {
      middleware: [auth]
    }
  },
  {
    path:'/clientCollectionLogin/:username/:collectionType/:collectionID/:loginKey(.*)',
    name: 'client-collection-with-key',
    component: ClientForm,
    meta: {
      middleware: [auth]
    }
  },
  {
    path:'/survey-start/:collectionType?/:collectionID/:clientID/:resultSetID?',
    name: 'survey-start',
    component: SurveyStart,
    meta: {
      middleware: [auth]
    }
  },
  {
    path:'/user-survey-start/:collectionType?/:collectionID/:userID/:resultSetID?',
    name: 'user-survey-start',
    component: UserSurveyStart,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/collection-form/:id/:assessmentType?/:assessmentID?/:model?/:modelID?',
    name: 'collection-form',
    component: Form,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/collection-form-print',
    name: 'collection-form-print',
    component: FormPrint,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/collection-analytics/:assessmentType?/:seriesID',
    name: 'collection-analytics',
    component: SurveyAnalytic,
    meta: {
      middleware: [auth]
    },
    children: [
      {
        path: ':componentName/:cservice/:rowID/',
        name: 'AnalyticModalView',
        component: ModalView
      }
    ]
  },
  {
    path: '/result-collaboration-form/:id/:email/:resultSetID/:resultType',
    name: 'result-collaboration-form',
    component: ResultCollabForm,
    meta: {
      middleware: [auth]
    }
  },
];
