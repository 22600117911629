import auth from '@/router/middleware/auth';
const LocationColorScheme = r => require.ensure([], () => r(require('./LocationColorScheme')), 'location-color-scheme-bundle');
export default [
  {
    path: '/locationColorScheme',
    name: 'locationColorScheme',
    component: LocationColorScheme,
    meta: {
      middleware: [auth]
    }
  }
];