<template>
  <div class="d-flex justify-content-end">
    <b-button
      v-if="allowAdd"
      size="md"
      @click="$emit('openAddRowDialog')"
      class="mr-1 btn-simple global-primary__bg"
    >
      {{ addButtonLabel }}
      <!-- <b-icon icon="plus" /> -->
    </b-button>

    <b-button
      v-if="showCustomAction"
      size="md"
      @click="$emit('openCustomDialog',{customAction:true})"
      class="mr-1 btn-simple global-primary__bg"
    >
      {{ customActionButtonLabel }}
      <!-- <b-icon icon="plus" /> -->
    </b-button>
    <div v-if="Array.isArray(tableActions) && tableActions.length > 0" class="d-flex">
      <div
        v-for="(action, i) in tableActions"
        :key="`table-action-${i}`"
        class="mr-1">
        <b-btn
          v-if="action.type === 'LINK_INT'"
          size="md"
          class="mr-1 btn-simple global-primary__bg"
          :to="action.url">
          {{ action.name }}
        </b-btn>
        <b-btn
          v-if="action.type === 'CF_FORM'"
          size="md"
          class="mr-1 btn-simple global-primary__bg"
          @click="$emit('openCFormDialog', action.payload)">
          {{ action.name }}
        </b-btn>
        <b-btn
          v-if="action.type === 'SURVEYJS_FORM'"
          size="md"
          class="mr-1 btn-simple global-primary__bg"
          @click="$emit('openSurveyJsFormDialog', action.payload)">
          {{ action.name }}
        </b-btn>
      </div>
    </div>
    <div v-if="printSettings">
       <b-button
          size="md"
          @click="$emit('printClicked',{printUri:printSettings.printUri})"
          class="mr-1 btn-simple global-primary__bg"
        >
         {{ printSettings.printButtonLabel }}
      <!-- <b-icon icon="plus" /> -->
    </b-button>

    </div>
     <div v-if="shareSettings">
       <b-button
          size="md"
          @click="shareSettings.autoShare?promptShare():$emit('shareClicked')"
          class="mr-1 btn-simple global-primary__bg"
        >
        {{ shareSettings.shareButtonLabel }}
    </b-button>
    </div>

    <b-button
      v-if="allowSearch"
      size="sm"
      @click="$emit('openSearchDialog')"
      class="mr-1 btn-simple global-primary__bg"
    >
      {{ searchButtonLabel }}<b-icon icon="search"></b-icon>
    </b-button>
  </div>
</template>

<script>
import { API_URL } from '@/config';
export default {
  name: 'EcenterTableActions',
  props: ['allowAdd', 'showCustomAction', 'allowSearch', 'addButtonLabel', 'customActionButtonLabel', 'searchButtonLabel', 'tableActions',"printSettings", "shareSettings"],
  data() {
    return {};
  },
  mounted() {
    
  },
  computed: {
    cssClasses: function() {
      var cssClasses = this.defaultCssClasses;

      return cssClasses;
    },
  },
  methods: {
    promptShare(){
       this.$bvModal.msgBoxConfirm(this.shareSettings.autoShareMessage, {
        hideHeaderClose: true,
        centered: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
            okTitle:'Yes',
                cancelTitle:'No',
                okVariant:'success',
                cancelVariant:'danger'
      })
        .then(value => {
          if(value){


              this.$emit('autoShareClicked');


          }

        });

    }
  },
  components: {},
};
</script>
