import Vue from 'vue';
import { VueEditor } from 'vue2-editor';
import * as locationCatService from '@/services/locationCategoryPicker'
import * as locationTagService from '@/services/locationTagPicker'
import * as customFieldService from '@/services/customField'
export function init(Survey,vm) {
    var testEditWidget={
        name:'badges',
        title:'Property Badges',
        showInToolbox:false,
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'badges';
        },
        init(){
          Survey.Serializer.addClass('badges',[],null,'empty');

         
        },
        htmlTemplate:"<div class='badges'></div>",
        afterRender: function(question,el) {

          
          

          if(question.title=='Edit Tags'){
           
            if(vm.surveyCreator.JSON && vm.surveyCreator.JSON.Tags){
              $.each(vm.surveyCreator.JSON.Tags,function(key,tag){
                locationTagService.getRow(tag)
                .then(response =>{

                    $(el).append("<div class='tag--light-green' style='display:inline-block;padding:5px;margin:5px'>"+response.Name+"</div>");

                  });
               });
            }

      

          }else if(question.title=='Edit Category'){


            if(vm.surveyCreator.JSON && vm.surveyCreator.JSON.Category){
              $.each(vm.surveyCreator.JSON.Category,function(key,category){
                locationCatService.getRow(category)
                .then(response =>{

                    $(el).append("<div class='tag--light-green' style='display:inline-block;padding:5px;margin:5px'>"+response.Name+"</div>");

                });
              });
            }
          }else if(question.title=='Field To Use'){



            if(question.value){
              customFieldService.getFieldName(question.value)
              .then(response=>{
                    $(el).append("<div class='tag--light-green' style='display:inline-block;padding:5px;margin:5px'>"+response.fieldName+"</div>");

              });

            }else{
               $(el).append("<div class='tag--light-green' style='display:inline-block;padding:5px;margin:5px'></div>");

            }
            

          }

          
        },
        destroy: function(question, htmlElement) {
          $(htmlElement).destroy();
        },

        

          
           
        
      };
    //Register our widget in singleton custom widget collection

    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('badges')){
        Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget, "customtype");
    }
    
}