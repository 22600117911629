<template>
  <b-container>
    <ProgressBarModal
      v-if="progressBarVisibility"
      :modalVisibility="true"
      :header="progressBarModalHeader"
      :value="progressBarCompleted"
    />
    <Menubar
      :showCancelBtn="true"
      :showOkBtn="false"
      :menuBtnsData="menuBtnsData"
      @onClickCancel="onClickCancel($event)"
      @onClickMenuBtn="onClickMenuBtn($event)"
      @onError="onError($event)"
    />
    <hr />
    <b-alert id="formErrorDiv" v-show="errorMessage" variant="danger" show v-html="errorMessage"></b-alert>
    <b-alert id="formSuccessDiv" v-show="successMessage" variant="success" show v-html="successMessage"></b-alert>
    <CustomFieldForm
      :formServiceName="formServiceName"
      :serverParams="serverParams"
      @onError="onError($event)"
      ref="formInstance"
    />
  </b-container>
</template>
<script>
import CustomFieldForm from '@/components/form/CustomFieldForm.vue';
import Menubar from '@/components/Menubar';
import ProgressBarModal from '@/components/ProgressBarModal.vue';
import cformPageMixin from '@/mixins/cformPage.mixin';
import progressBarMixin from '@/mixins/progressbar.mixin';
import { validateCfAnswers } from '@/utils/cfUtils';

export default {
  name: 'CustomFieldFormPage',
  components: {
    CustomFieldForm,
    Menubar,
    ProgressBarModal,
  },
  mixins: [cformPageMixin, progressBarMixin],
  methods: {
    getFormData: function() {
      const form = this.$refs.formInstance.getCustomCategoriesProp();

      const answers = this.$refs.formInstance.getAnswersProp();
      return { form, answers };
    },
    validateForm: function() {
      const formData = this.getFormData();
      const validation = validateCfAnswers(formData.answers, formData.form);
      if (validation.error) return false;
      return true;
    },
  },
};
</script>
