import auth from '@/router/middleware/auth';
const LocationSiteName = r => require.ensure([], () => r(require('./LocationSiteName')), 'location-site-name-bundle');
export default [
  {
    path: '/locationSiteName',
    name: 'locationSiteName',
    component: LocationSiteName,
    meta: {
      middleware: [auth]
    }
  }
];