import auth from '@/router/middleware/auth';
const LocationInfo = r => require.ensure([], () => r(require('./LocationInfoForm')), 'location-info-form-bundle');
const optOutForm = r => require.ensure([], () => r(require('./OptOutForm')), 'location-optout-form-bundle');


export default [
  {
    path: '/locationinfo/:LocationID',
    name: 'locationinfo',
    component: LocationInfo,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/optOutForm/:LocationID/:email',
    name: 'optOutForm',
    component: optOutForm,
    meta: {
      middleware: [auth]
    }

  }
];