import { apiPost } from '@/services/generic';
import { ErrorMessages } from '@/utils/errorMessages';
import { uploadFormResources } from '@/utils/s3ResourceUtils';

export default {
  data: function() {
    return {
      errorMessage: null,
      successMessage: null,
      formServiceName: null,
      menuBtnsData: null,
      service: null,
      serverParams: null,
    };
  },
  created() {
    const serverParams = { __form: { inPage: true } };

    const rQuery = this.$route.query;
    const qMap = {
      mid: 'modelID',
      asmtype: 'assessmentType',
      fid: 'formID',
    };
    for (const key in rQuery) {
      if (qMap[key]) serverParams['__form'][qMap[key]] = rQuery[key];
      else serverParams['__form'][key] = rQuery[key];
    }
    this.serverParams = serverParams;
    this.formServiceName = this.$route.query.svc;
    this.getService();
    this.getHeader();
  },
  methods: {
    onClickCancel: function() {
      this.$router.go(-1);
    },
    onClickMenuBtn: function(btnAction) {
      try {
        const formData = this.getFormData();
        if (btnAction.validate && !this.validateForm()) {
          throw Error(ErrorMessages.MISSING_FIELDS);
        }
        const payload = { ...formData, action: btnAction };
        payload.answers['__btnAction'] = btnAction;
        if (btnAction.type === 'SUBMIT_VIA_URL') {
          this.submitAnswers(payload.answers, btnAction.submitDataUri);
        } else if (btnAction.type === 'SUBMIT_VIA_SERVICE') {
          this.submitAnswers(payload.answers);
        } else if (btnAction.type == 'PRINT') {
          this.printForm();
        }
      } catch (error) {
        this.onError(error);
      }
    },
    onError: function(data) {
      this.successMessage = null;
      this.errorMessage = data;
    },
    onSuccess: function(data) {
      if (data.error===false && data.message) {
        this.successMessage = data.message;
      }
      this.errorMessage="";
    },
    printForm: function() {
      return;
    },
    submitAnswers: async function(answers, submitUrl = null) {
      try {
        let response = null;
        if (submitUrl) {
          response = await apiPost(submitUrl, answers);
        } else {
          response = await this.service.submitForm(answers);
        }
        if (response.error) throw Error(response.message);
        this.onSuccess(response);
        if (response.toast) {
          this.addGlobalToast(response.toast);
        }
        await this.onSubmittedAsyncTasks(response);
        this.onSubmittedSyncTasks(response);
      } catch (error) {
        this.onError(error);
      }
    },
    onSubmittedAsyncTasks: async function(response) {
      const { uploadResourceURL } = response;
      const { answers } = this.getFormData();
      if (uploadResourceURL) {
        await uploadFormResources(uploadResourceURL, answers, this);
      }
    },
    onSubmittedSyncTasks: function(response) {
      const { redirectURL } = response;
      if (redirectURL) {
        this.$router.push(redirectURL);
      }
    },
    getHeader: function() {
      const menuBtnsData = {
        ...this.serverParams,
        url: this.service['getFormHeaderUrl'],
      };
      this.menuBtnsData = menuBtnsData;
    },
    getService: function() {
      if (this.formServiceName) {
        this.service = require(`@/services/${this.formServiceName}`);
      }
    },
    validateForm: function() {
      return true;
    },
  },
};
