import auth from '@/router/middleware/auth';
import ModalView from '@/components/ModalView';

import FormCollaboration from '@/components/FormCollaborationModal'

const ListView = r =>
  require.ensure([], () => r(require('./index')), 'list-view-bundle');

export default [
 
  {
    path: '/list/:service/',
    name: 'list-view',
    component: ListView,
    meta: {
      middleware: [auth],
    },
    children: [

      {
        path: ':componentName/:cservice/:rowID/callLog/:clientID',
        name: 'ModalViewCallLog',
        component: ModalView,
        
      },
      {
        path: ':componentName/:cservice/:rowID/',
        name: 'ModalView',
        component: ModalView,
        
      },
    
      {
        path: ':componentName/:cservice/:rowID/inprogressReferral/:inprogressFlag',
        name: 'ModalViewInprogressReferral',
        component: ModalView,
        
      },
      {
        path: ':componentName/:cservice/:rowID/referral/:referralHistoryID',
        name: 'ModalViewReferral',
        component: ModalView,
        
      },
       {
        path: ':componentName/:cservice/:rowID/:pendingApprovalID',
        name: 'ModalViewPendingApproval',
        component: ModalView,
        
      },

   
     
     
    ],
  },
  

  {
    path: '/list/:service/:prefiltername/:prefilter',
    name: 'list-view-prefiltered',
    component: ListView,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: ':componentName/:cservice/:rowID/',
        name: 'ModalViewPrefiltered',
        component: ModalView,

      },
      {
        path: ':componentName/:assessmentID/',
        name: 'DeploymentManagementModal',
        component: ModalView,
      },
     
    ],
  },

  {
    path: '/list/:service/:prefiltername/:prefilter',
    name: 'list-view',
    component: ListView,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: ':componentName/:cservice/:rowID/',
        name: 'ModalView',
        component: ModalView,
      },
      {
        path: ':componentName/:cservice/:rowID/referral/:referralHistoryID',
        name: 'ModalViewReferralFiltered',
        component: ModalView,
        
      },
     
    ],
  },
  




];
