import { API_URL } from '@/config';
import * as clientService from '@/services/client';

export function init(Survey,vm,live=false) {
    var testEditWidget={
        name:'client-list',
        title:'Client List With Contact',
        iconName:'',
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'client-list';
        },
        init(){
          Survey.Serializer.addClass('client-list',[],null,'empty');

       
        },
        htmlTemplate:"<div style='padding-left:20px' class='client-list-container'><div class='responseValue'></div><div class='clientBttn btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Add</div></div>",
        afterRender:function(question,el){

          $(el).css('padding-top',"10px");
     
          if(!live && $(el).closest('#surveyContainer').length>0){
            live=true;
          }

          if(question.ButtonText){
            $(el).find('.clientBttn').html(question.ButtonText.replace("<p>","").replace("</p>",""));

            

          }
          
          if(vm.readOnly){
            $(el).find('.clientBttn').hide();
            
          }
          $(el).find('.clientBttn').click(function(){
            vm.$root.$emit('openClientList',{questionCode:question.code});
          });
       
          vm.$root.$on('updateResponse',(data)=>{

                  
                  
            if($(el).closest('.svd-simulator-main').length>0 || live){
               if(data.questionCode==question.code){
                var responses;
                if(!question.value){
                 responses=new Array;
                }else{

                  if(!$.isArray(question.value)){

                        if(question.value.value){
                          responses=JSON.parse(question.value.value);


                        }else{
                          responses=JSON.parse(question.value);


                        }

                  }
                }


                   if($.inArray(data.value,responses)==-1){
                     responses.push(data.value);
     
                     question.value=JSON.stringify(responses);
                       $(el).find('.responseValue').empty();

                       clientService.getClientList(responses,{inQuestion:true})
                       .then(response =>{
     
                             $.each(response,function(key,value){
                               $(el).find('.responseValue').append("<div class='clientDetails' style='width:70%;display:inline-block;padding-top:20px'></div>");
                               $(el).find('.responseValue').append("<div class='clientRemove' style='display:inline-block;padding-top:20px'></div>");


                               $(el).find('.responseValue').find('.clientDetails').last().append("<div><span style='font-weight:bold;color:#000000'>"+value.FirstName+" "+value.LastName+"</span></div>");
                               $(el).find('.responseValue').find('.clientDetails').last().append("<div><span style='color:#000000'>Email: "+value.Email+" </span></div>");
     
     
                               $(el).find('.responseValue').find('.clientRemove').last().append("<div style='margin-top:20px;background-color:#dc3545;color:#ffffff' data-clientid='"+value.ID+"' class='clientBttnRemove btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Remove</div>");

                             
                             });


                             

                                question.value={value:responses,responseData:response};

                             
     
     
                       });
                   }
               
               }
             }

      
           
         });

          if(question.value){

            var responses=JSON.parse(question.value);

            clientService.getClientList(responses,{inQuestion:true})
            .then(response =>{
              $.each(response,function(key,value){
                $(el).find('.responseValue').append("<div class='clientDetails' style='width:70%;display:inline-block;padding-top:20px'></div>");
                $(el).find('.responseValue').append("<div class='clientRemove' style='display:inline-block;padding-top:20px'></div>");


                $(el).find('.responseValue').find('.clientDetails').last().append("<div><span style='font-weight:bold;color:#000000'>"+value.FirstName+" "+value.LastName+"</span></div>");
                $(el).find('.responseValue').find('.clientDetails').last().append("<div><span style='color:#000000'>Email: "+value.Email+" </span></div>");


                $(el).find('.responseValue').find('.clientRemove').last().append("<div style='margin-top:20px;background-color:#dc3545;color:#ffffff' data-clientid='"+value.ID+"' class='clientBttnRemove btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Remove</div>");

              
              });

              question.value={value:responses,responseData:response};


            });
          }

          $(el).on('click','.clientBttnRemove',function(){
            var indexToRemove=null;

            var clientID=$(this).data('clientid');
            
            var responses=JSON.parse(question.value);

            $.each(responses,function(key,value){
                  
                  if(parseInt(value)==parseInt(clientID)){


                    indexToRemove=key;
                    return;
                  }


            });

            if(indexToRemove !==null){
              responses.splice(indexToRemove,1);



              if(responses.length>0){
                clientService.getClientList(responses,{inQuestion:true})
                .then(response =>{
                      $(el).find('.responseValue').empty();

                      $.each(response,function(key,value){
                        $(el).find('.responseValue').append("<div class='clientDetails' style='width:70%;display:inline-block;padding-top:20px'></div>");
                        $(el).find('.responseValue').append("<div class='clientRemove' style='display:inline-block;padding-top:20px'></div>");


                        $(el).find('.responseValue').find('.clientDetails').last().append("<div><span style='font-weight:bold;color:#000000'>"+value.FirstName+" "+value.LastName+"</span></div>");
                        $(el).find('.responseValue').find('.clientDetails').last().append("<div><span style='color:#000000'>Email: "+value.Email+" </span></div>");


                        $(el).find('.responseValue').find('.clientRemove').last().append("<div style='margin-top:20px;background-color:#dc3545;color:#ffffff' data-clientid='"+value.ID+"' class='clientBttnRemove btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Remove</div>");

                          question.value={value:responses,responseData:response};
                        
                      
                      });
                    });


                }else{
                  $(el).find('.responseValue').empty();
                 
                    question.value=null;
                    
                  

                }

               
              

            }
        });
          
          
          question.registerFunctionOnPropertyValueChanged("ButtonText",
          function () {

            $(el).find('.clientBttn').html(question.ButtonText).replace("<p>","").replace("</p>","");
        
          });


        

          
           
        }
      };
    //Register our widget in singleton custom widget collection

    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('client-list')){
        Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget, "customtype");
    }
    
}