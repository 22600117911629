// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) =>
  http.post('apiClientReferral/getTableSettings', payload).then(getData);

export const get = (payload = null) =>

  http.post((payload.rowID && !payload.HistoryView && !payload.inprogressFlag)?'apiClientReferral/getLocations&inReferral=1':'apiClientReferral/getReferrals', payload).then(getData);

export const getDeclinedReferrals = (clientID,params) =>
  http.post('apiClientReferral/getDeclinedReferralHistory',{ClientID:clientID,...params}).then(getData);


export const getReferralPrintView = (payload) =>
  http.post('apiClientReferral/getReferralReportData',payload).then(getData);

export const referralLogin = (payload) => http.post('apiAuthentication/authenticateReferralLogin', payload).then(getData);
