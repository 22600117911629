// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) =>
  http.post('apiLocationEHR/getTableSettings', payload).then(getData);

export const get = (payload = null) =>
  http.post('apiLocationEHR/getLocations', payload).then(getData);



export const getRow = (locationID) => http.get(`apiLocationEHR/getRow&RowID=${locationID}`).then(getData);

