import { API_URL } from '@/config';
import * as locationService from '@/services/locationEHR';

export function init(Survey,vm,live =false) {
    var testEditWidget={
        name:'location-list',
        title:'Location List With Contact',
        iconName:'',
        responseData:{},
        widgetIsLoaded:function(){
          return true;
        },
        isFit:function(question){
          return question.getType() == 'location-list';
        },
        init(){
          Survey.Serializer.addClass('location-list',[],null,'empty');

       
        },
        htmlTemplate:"<div style='padding-left:20px' class='location-list-container'><div class='responseValue'></div><div style='margin-top:20px;' class='locationBttn btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Add</div></div>",
        afterRender:function(question,el){


          $(el).css('padding-top',"10px");
     
          if(!live && $(el).closest('#surveyContainer').length>0){
            live=true;
          }

          if(question.ButtonText){
            setTimeout(function(){

                $(el).find('.locationBttn').html(question.ButtonText.replace("<p>","").replace("</p>",""));
            },500);
          }
          
          if(vm.readOnly){
            $(el).find('.locationBttn').hide();
            
          }


          $(el).find('.locationBttn').click(function(){
            vm.$root.$emit('openLocation',{questionCode:question.code, LocationType:question.LocationType});
          });
       
                vm.$root.$on('updateResponse',(data)=>{


                  
                   if($(el).closest('.svd-simulator-main').length>0 || live){
                      if(data.questionCode==question.code){
                        var responses;
                        if(!question.value){
                         responses=new Array;
                        }else{

                          if(question.value.value){
                            if(!$.isArray(question.value.value)){
                              responses=JSON.parse(question.value.value);

                             }else{
                                responses=question.value.value;
                             }

                          }else{
                            if(!$.isArray(question.value)){
                              responses=JSON.parse(question.value);

                             }else{
                              responses=question.value;

                             }

                          }
                      
                        }


                          if($.inArray(data.value,responses)==-1){

                           

                              responses.push(parseInt(data.value));


                              question.value={value:responses};

                 

                              $(el).find('.responseValue').empty();

                              locationService.getLocationList(responses,{inQuestion:true})
                              .then(response =>{
            

                                question.value={value:responses,responseData:response};

                                testEditWidget.responseData[question.code]=response;

                                    $.each(response,function(key,value){
                                      $(el).find('.responseValue').append("<div class='locationDetails' style='width:70%;padding-top:20px'></div>");
                                      $(el).find('.responseValue').append("<div class='locationRemove' style='display:inline-block;padding-top:20px'></div>");

                                      $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='font-weight:bold;color:#000000'>"+value.Name+"</span></div>");
                                      $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='color:#000000'>"+value.Address+"</span></div>");
                                      $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='color:#000000'>"+value.Address2+"</span></div>");
                                      $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='color:#000000'>Email: "+value.Email+" Phone: "+value.Phone+"</span></div>");
            
                                      if(!vm.readOnly){
                                        $(el).find('.responseValue').find('.locationRemove').last().append("<div style='margin-top:20px;background-color:#dc3545;color:#ffffff' data-locationid='"+value.ID+"'  class='locationBttnRemove btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Remove</div>");
                                      }
                                    
                                    });

                                    $(el).on('click','.locationBttnRemove',function(){
                                        var indexToRemove=null;

                                        var responses=[];

                                        if(question.value.value){
                                          responses=question.value.value;

                                        }else{
                                          responses=JSON.parse(question.value);

                                        }
                                     


                                        var locationID=$(this).data('locationid')                           
                                        $.each(responses,function(key,value){
                                              
                                              if(parseInt(value)==parseInt(locationID)){


                                                indexToRemove=key;
                                                return;
                                              }


                                        });

                                        if(indexToRemove !==null){
                                          

                                          responses.splice(indexToRemove,1);



                                          if(responses.length>0){
                                            locationService.getLocationList(responses,{inQuestion:true})
                                            .then(response =>{
                                                  $(el).find('.responseValue').empty();

                                                  $.each(response,function(key,value){
                                                    $(el).find('.responseValue').append("<div class='locationDetails' style='width:70%;padding-top:20px'></div>");
                                                    $(el).find('.responseValue').append("<div class='locationRemove' style='display:inline-block;padding-top:20px'></div>");
              
                                                    $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='font-weight:bold;color:#000000'>"+value.Name+"</span></div>");
                                                    $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='color:#000000'>"+value.Address+"</span></div>");
                                                    $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='color:#000000'>"+value.Address2+"</span></div>");
                                                    $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='color:#000000'>Email: "+value.Email+" Phone: "+value.Phone+"</span></div>");
                          
                          
                                                    if(!vm.readOnly){
                                                      $(el).find('.responseValue').find('.locationRemove').last().append("<div style='margin-top:20px;background-color:#dc3545;color:#ffffff' data-locationid='"+value.ID+"' class='locationBttnRemove btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Remove</div>");
                                                    }
                                                  
                                                  });
                                                });
                                            }else{
                                              $(el).find('.responseValue').empty();
                                                question.value=null;

                                            }

                                            if(responses.length>0){
                                                question.value=JSON.stringify(responses);
                                            }else{
                                              question.value=null;
                                            }

                                          

                                        }
                                    });
            
            
                              });


                          }
                      
                      }
                    }
                  
                });
          
          if(question.value){

            
            var responses=[]
            
            if(question.value.value){
              responses=question.value.value;
            }

            locationService.getLocationList(responses,{inQuestion:true})
            .then(response =>{

              question.value={value:responses,responseData:response};

              $.each(response,function(key,value){
                  $(el).find('.responseValue').append("<div class='locationDetails' style='width:70%;padding-top:20px'></div>");
                  $(el).find('.responseValue').append("<div class='locationRemove' style='display:inline-block;padding-top:20px'></div>");

                  $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='font-weight:bold;color:#000000'>"+value.Name+"</span></div>");
                  $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='color:#000000'>"+value.Address+"</span></div>");
                  $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='color:#000000'>"+value.Address2+"</span></div>");
                  $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='color:#000000'>Email: "+value.Email+" Phone: "+value.Phone+"</span></div>");

                  if(!vm.readOnly){

                     $(el).find('.responseValue').find('.locationRemove').last().append("<div style='margin-top:20px;background-color:#dc3545;color:#ffffff' data-locationid='"+value.ID+"' class='locationBttnRemove btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Remove</div>");

                  }
               
              });


              $(el).on('click','.locationBttnRemove',function(){
                var indexToRemove=null;

                var responses=[];

                if(question.value.value){
                  responses=question.value.value;

                }else{
                  responses=JSON.parse(question.value);

                }
             


                var locationID=$(this).data('locationid')                           
                $.each(responses,function(key,value){
                      
                      if(parseInt(value)==parseInt(locationID)){


                        indexToRemove=key;
                        return;
                      }


                });

                if(indexToRemove !==null){
                  

                  responses.splice(indexToRemove,1);



                  if(responses.length>0){
                    locationService.getLocationList(responses,{inQuestion:true})
                    .then(response =>{
                          $(el).find('.responseValue').empty();

                          $.each(response,function(key,value){
                            $(el).find('.responseValue').append("<div class='locationDetails' style='width:70%;padding-top:20px'></div>");
                            $(el).find('.responseValue').append("<div class='locationRemove' style='display:inline-block;padding-top:20px'></div>");

                            $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='font-weight:bold;color:#000000'>"+value.Name+"</span></div>");
                            $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='color:#000000'>"+value.Address+"</span></div>");
                            $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='color:#000000'>"+value.Address2+"</span></div>");
                            $(el).find('.responseValue').find('.locationDetails').last().append("<div><span style='color:#000000'>Email: "+value.Email+" Phone: "+value.Phone+"</span></div>");
  
  
                            if(!vm.readOnly){
                              $(el).find('.responseValue').find('.locationRemove').last().append("<div style='margin-top:20px;background-color:#dc3545;color:#ffffff' data-locationid='"+value.ID+"' class='locationBttnRemove btn mr-1 btn-simple global-primary__bg btn-secondary btn-md'>Remove</div>");
                            }
                          
                          });

                          question.value={value:responses,responseData:response};

                        });
                    }else{
                      $(el).find('.responseValue').empty();
                        question.value=null;

                    }

                   

                  

                }
            });

              

            });
          }
          
          
          question.registerFunctionOnPropertyValueChanged("ButtonText",
          function () {

            $(el).find('.locationBttn').html(question.ButtonText.replace("<p>","").replace("</p>",""));
        
          });


        

          
           
        },
        pdfQuestionType: "empty",
        pdfRender: function (survey, options) {
          if (options.question.getType() === "location-list") {
            const loc = new Survey.LocalizableString(survey, true);




            if(options.question.customWidgetValue.widgetJson.responseData[options.question.code]){
             
            let response=options.question.customWidgetValue.widgetJson.responseData[options.question.code];

            let displayText="";
            $.each(response,function(key,value){
              displayText+="<div class='locationDetails' style='width:70%;display:inline-block;padding-top:20px'>";

              displayText+="<div><span style='font-weight:bold;color:#000000'>"+value.Name+"</span></div>";
              displayText+="<div><span style='color:#000000'>"+value.Address+"</span></div>";
              displayText+="<div><span style='color:#000000'>"+value.Address2+"</span></div>";
              displayText+="<div><span style='color:#000000'>Email: "+value.Email+" Phone: "+value.Phone+"</span></div>";

              displayText+="</div>";
           
          });
             
              loc.text = displayText;



            }else{
              loc.text ="";
            }
            options.question["locHtml"] = loc;

            if (
              options.question.renderAs === "standard" ||
              options.question.renderAs === "image"
            ) {
              options.question["renderAs"] = options.question.renderAs;
            } else options.question["renderAs"] = "auto";
            const flatHtml = options.repository.create(
              survey,
              options.question,
              options.controller,
              "html"
            );
            return new Promise(function (resolve) {
              flatHtml.generateFlats(options.point).then(function (htmlBricks) {
                options.bricks = htmlBricks;
                resolve();
              });
            });
          }
        }
    }
    //Register our widget in singleton custom widget collection

    if(!Survey.CustomWidgetCollection.Instance.getCustomWidgetByName('location-list')){
        Survey.CustomWidgetCollection.Instance.addCustomWidget(testEditWidget, "customtype");
    }
    
}