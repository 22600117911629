// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getFormHeaderUrl = `apiAuthentication/getFormHeader`;

export const requestAccount = (payload) => http.post('apiAuthentication/requestAccount', payload).then(getData);

export const getProvinces = (payload) => http.post('apiAuthentication/getRequestAccountProvinces', payload).then(getData);

export const getLocations = (payload) => http.post('apiAuthentication/getRequestAccountLocations', payload).then(getData);

export const getForm = (payload) => http.post(`apiAuthentication/getForm`, payload).then(getData);

export const getFormAnswers = (payload) => http.post(`apiAuthentication/getFormAnswers`, payload).then(getData);

export const submitForm = (payload) => http.post(`apiAuthentication/submitForm`, copyAndMerge(payload, getPropsFromFiles(payload))).then(getData);