import { apiPost } from '@/services/generic';

export default function CustomQuestionType(props) {
  const component = {};
  component.name = props.name;
  component.title = props.title;
  // if (props.questionJSON && props.questionJSON.choicesByUrl && props.questionJSON.choicesByUrl.url) {
  //   props.questionJSON.choicesByUrl.url = API_URL + props.questionJSON.choicesByUrl.url;
  // }
  component.questionJSON = props.questionJSON;
  component.onLoaded = (question) => {
    // if (props.getChoicesUrl) {
    //   apiPost(props.getChoicesUrl).then(response => {
    //     question.questionWrapper.choices = response;
    //   });
    // }
  }
  return component;
}
