// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiCollectionSeries/getCollaboratorTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiCollectionSeries/getCollaborators', payload).then(getData);

export const add = (payload) => http.post('apiCollectionSeries/addCollaborator', payload).then(getData);

export const remove = (payload) => http.post('apiCollectionSeries/removeCollaborator', payload).then(getData);
