<template>
  <div v-if="panel && panel.Name && Array.isArray(panel.customFields)">
    <FormCard
      headerClass="global-primary__bg global-primary__bg--no-hover"
      :collapsed="panel.Collapsed"
      :isCollapsible="true"
    >
      <template v-slot:header>
        {{ panel.Title ? panel.Title : panel.Name }}
      </template>
      <template v-slot:body>
        <fieldset :disabled="panel.Disabled">
        <div v-html="panel.Instructions" class="text--sm mb-3" v-if="panel.Instructions"></div>
        <div v-if="panel.IsDynamic && Array.isArray(panel.dynRows)">
          <div v-if="panel.dynRows.length === 0">
            <p class="text-muted font-italic text-center">
              {{ $t("form.add") }}
            </p>
          </div>
          <div
            v-for="(row, ri) in panel.dynRows"
            :key="`${panel.Name}DynRow__${ri}`"
            class="animation__fadeIn--left animation__duration--fast"
          >
            <FormControlRow
              v-if="
                answers['__dynPanels'] &&
                  answers['__dynPanels'][panel.Name] &&
                  answers['__dynPanels'][panel.Name][ri]
              "
              :answers="answers['__dynPanels'][panel.Name][ri]"
              :fields="row"
              :formType="formType"
              @onInputFormControl="$emit('onInputFormControl', $event)"
              @tags-reviewed-event="$emit('tags-reviewed-event',$event)"
            />
            <div class="d-flex justify-content-end">
              <b-btn
                class="btn-simple text-danger font-weight-bold"
                @click="
                  $emit('onClickRemoveRow', { row, rowIndex: ri, panelIndex })
                "
              >
                <b-icon-dash-circle-fill /> {{ $t("general.remove") }}
              </b-btn>
            </div>
            <hr />
          </div>
          <div>
            <b-btn @click="handleClickAddRow(panel, answers)" class="btn-simple text-success font-weight-bold">
              <b-icon-plus-circle-fill /> {{ $t("general.add") }}
            </b-btn>
          </div>
        </div>
        <FormControlRow
          v-else
          :answers="answers"
          :fieldKeys="fieldKeys"
          :fields="panel.customFields"
          :formType="formType"
          @onInputFormControl="$emit('onInputFormControl', $event)"
          @tags-reviewed-event="$emit('tags-reviewed-event',$event)"
        />
        </fieldset>
      </template>
    </FormCard>
  </div>
</template>
<script>
import FormCard from '@/components/FormCard.vue';
import FormControlRow from '@/components/form/FormControlRow.vue';
import {
  createSetByProp,
  deepCopy,
  isStringAndNotEmpty,
} from '@/utils/langUtils';
import { setDynFieldChildren, setDynFieldVisibleIf } from '@/utils/cfUtils';

export default {
  name: 'FormPanel',
  components: { FormCard, FormControlRow },
  mixins: [],
  props: {
    answers: { type: Object, default: null },
    panel: { type: Object, default: null },
    panelIndex: { type: Number, default: null },
    formType: { type: String, default: '' },
    fieldKeys: { type: Object }
  },
  methods: {
    getAnswerPanelIndex(index) {
      if (
        this.answers['__dynPanels'] &&
        this.answers['__dynPanels'][this.panel.Name] &&
        this.answers['__dynPanels'][this.panel.Name]
      ) {
        return this.answers['__dynPanels'][this.panel.Name][index];
      } else {
        throw Error('Panel answer not found');
      }
    },
    handleClickAddRow() {
      const { destFields, destAnswers } = this.addRow(
        this.panel.customFields,
        this.answers
      );
      this.$emit('onClickAddRow', {
        fields: destFields,
        answers: destAnswers,
        panelIndex: this.panelIndex,
      });
    },
    /**
     * @param {Array<object>} rows
     * @param {Array<object>} srcFields
     * @param {object} srcAnswers
     * @return { destFields: Array<object>, destAnswers: object }
     */
    addRow(srcFields, srcAnswers) {
      const rowNum = this.getUniqueRowIndex();
      const destFields = deepCopy(srcFields);
      const setOfNames = createSetByProp(srcFields, 'Name');
      const destAnswers = {};
      for (const field of destFields) {
        const destFieldName = `${field.Name}__dynR${rowNum}`;
        const srcFieldName = field.Name;
        field.Name = destFieldName;
        destAnswers[destFieldName] = Array.isArray(srcAnswers[srcFieldName])
          ? []
          : '';
        setDynFieldChildren(srcFields, field, `__dynR${rowNum}`);
        setDynFieldVisibleIf(field, `__dynR${rowNum}`);
      }
      return { destFields, destAnswers };
    },
    getUniqueRowIndex: function() {
      let i = 0;
      let uniqueIndexFound = false;
      const rows =
        this.panel && Array.isArray(this.panel.dynRows)
          ? this.panel.dynRows
          : [];
      for (; i < 100 && !uniqueIndexFound; i++) {
        let matchFound = false;
        for (let j = 0; j < rows.length; j++) {
          const row = Array.isArray(rows[j]) ? rows[j] : [];
          for (const customField of row) {
            const cname =
              customField && isStringAndNotEmpty(customField.Name)
                ? customField.Name
                : '';
            if (cname.includes(`__dynR${i}`)) {
              matchFound = true;
              break;
            }
          }
        }
        if (!matchFound) {
          uniqueIndexFound = true;
          break;
        }
      }
      return i;
    },
  },
};
</script>
