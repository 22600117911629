// plugins is a alias. see client/build/webpack.base.conf.js
// import http client
import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

export const getTableSettings = (payload = null) => http.post('apiAnonymousStaffCollectionTable/getTableSettings', payload).then(getData);

export const get = (payload = null) => http.post('apiAnonymousStaffCollectionTable/getRows', payload).then(getData);

export const getRow = (rowID) => http.get(`apiAnonymousStaffCollectionTable/getRow&RowID=${rowID}`).then(getData);

export const create = (row) => http.post('apiAnonymousStaffCollectionTable/addRow', row).then(getData);

export const update = (row) => http.post('apiAnonymousStaffCollectionTable/editRow&RowID=' + row.ID, row).then(getData);

export const remove = (row) => http.post('apiAnonymousStaffCollectionTable/deleteRow', row).then(getData);

export const getCollectionDetails = (payload) => http.post('apiAnonymousStaffCollectionTable/getCollectionDetails',payload).then(getData);

export const setOptOut = (payload) => http.post('apiAnonymousStaffCollectionTable/optOutOfSurvey',payload).then(getData);

export const getOptOutOptions = (payload) => http.get('apiAnonymousStaffCollectionTable/getOptOutOptions').then(getData);

